import styled from 'styled-components';

export const DiaryStudyResearchStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 991px) {
    gap: 25px;
    padding: 40x 0;
  }

  .content-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 50px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }

    .content-img {
      max-width: 770px;
      width: 100%;
      box-sizing: border-box;
      background: linear-gradient(
          273.29deg,
          rgba(38, 240, 166, 0.2) 0%,
          rgba(18, 190, 228, 0.2) 100%
        ),
        #ffffff;
      box-shadow: 0px 0px 16.8px rgba(0, 72, 55, 0.25);
      border-radius: 5px;
      padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      max-height: 450px;

      @media (max-width: 991px) {
        padding: 32px;
      }

      h3 {
        font-weight: 700;
        font-size: 50px;
        line-height: 125%;
        text-align: center;
        color: #0f0e0c;

        @media (max-width: 991px) {
          font-size: 32px;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .content-links {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .content-link {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
      transition: all 0.3s ease-in-out;
      height: 40px;
      cursor: pointer;

      span {
        font-weight: 400;
        font-size: 22px;
        line-height: 125%;
        color: #0f0e0c;
        transition: all 0.3s ease-in-out;

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }

      &.active {
        transform: translateX(25px);

        span {
          font-weight: 700;
        }
      }
    }
  }
`;
