import React, { useEffect } from 'react';
import { CatiStyles } from './styles';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import { useAppSelector } from '../../state/hooks';
import {
  CatiAbout,
  CatiBenefit,
  CatiContactUs,
  CatiExperts,
  CatiIntro,
  CatiPartners,
  CatiQuality,
  CatiRating,
  CatiSolves,
  CatiTechnology,
  CatiWhom,
  CatiWhy,
} from '../../components';

export const Cati = () => {
  useEffect(() => {}, []);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CatiStyles>
      <CatiIntro />
      <CatiAbout />
      <CatiSolves />
      <CatiBenefit />
      <CatiWhom />
      <CatiExperts />
      <CatiRating />
      <CatiQuality />
      <CatiTechnology />
      <CatiContactUs />
      <CatiWhy />
      <CatiPartners />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </CatiStyles>
  );
};
