import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { SupportStyles } from './styles';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';
import CircleCheckbox from '../../components/form/circleCheckbox/CircleCheckbox';
import InputField from '../../components/form/inputField/InputField';
import TextAreaField from '../../components/form/textAreaField/TextAreaField';
import { Api } from '../../api';
import SelectField from '../../components/form/inputField copy/SelectField';

interface FormValues {
  email: string;
  full_name: string;
  login: string;
  phone: string;
  problem: string;
  details: string;
  terms: boolean;
}

const initial: FormValues = {
  email: '',
  full_name: '',
  login: '',
  phone: '',
  problem: '',
  details: '',
  terms: false,
};

export const Support = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues>(initial);

  const formRef = useRef<any>();

  const validationSchema = useMemo(
    () => Yup.object({
      email: Yup.string()
        .email(
          getTranslationsByLangOrEng(interfaceLanguage, 'validation_email'),
        )
        .required(
          getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
        ),
      terms: Yup.boolean().isTrue(
        getTranslationsByLangOrEng(
          interfaceLanguage,
          'validation_privacy_policy',
        ),
      ),
      full_name: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
      login: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
      problem: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
      phone: Yup.string().required(
        getTranslationsByLangOrEng(interfaceLanguage, 'validation_required'),
      ),
    }),
    [interfaceLanguage],
  );

  const options: string[] = useMemo(() => ([getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_80'), getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_81')]), [interfaceLanguage]);

  function getErrorMessage(key: string) {
    return typeof errorMessage === 'object'
      ? errorMessage[key]
          && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
            key
          ].substring(1)}`
      : undefined;
  }

  function handleKeyUp(key: string, error: string | undefined) {
    setErrorMessage(
      typeof errorMessage === 'object'
        ? { ...errorMessage, [key]: error }
        : errorMessage,
    );
  }

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setLoading(true);
    const data = {
      full_name: values.full_name,
      email: values.email,
      login: values.login,
      phone: values.phone,
      problem: values.problem,
      details: values.details,
    };

    Api.sendSupportIssues(data)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setLoading(false);
          navigate(`/${interfaceLanguage}/thank-you`);
        } else {
          setErrorMessage(getTranslationsByLangOrEng(interfaceLanguage, 'submit_error'));

          setTimeout(() => {
            setErrorMessage({});
          }, 3000);
        }
      })
      .finally(() => setLoading(false));

    setSubmitting(false);
  }

  useEffect(() => {
    setInitialValues({ ...initial, problem: options[0] });
  }, []);

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="fields">
        <InputField
          name="full_name"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('full_name', errors?.full_name)}
          placeholder={`${getTranslationsByLangOrEng(
            interfaceLanguage,
            'shoppers_76',
          )} *`}
          value={values.full_name}
          error={
            typeof errorMessage === 'object' && getErrorMessage('name')
              ? getErrorMessage('full_name')
              : errors.full_name
          }
          required
          withFlickerHoverAnim
          colorTheme="darkTheme"
          showError={false}
          className="fields-item"
        />
        <SelectField
          name="problem"
          onChange={(value) => {
            setFieldValue('problem', value);
          }}
          placeholder={`${getTranslationsByLangOrEng(
            interfaceLanguage,
            'shoppers_77',
          )} *`}
          value={values.problem}
          options={options}
          required
          colorTheme="darkTheme"
          // className="fields-item"
        />
        <InputField
          name="login"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('login', errors?.login)}
          placeholder={`${getTranslationsByLangOrEng(
            interfaceLanguage,
            'shoppers_78',
          )} *`}
          value={values.login}
          error={
            typeof errorMessage === 'object' && getErrorMessage('name')
              ? getErrorMessage('login')
              : errors.login
          }
          required
          withFlickerHoverAnim
          colorTheme="darkTheme"
          showError={false}
          className="fields-item"
        />
        <TextAreaField
          name="details"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('details', errors?.details)}
          placeholder={`${getTranslationsByLangOrEng(
            interfaceLanguage,
            'request_details',
          )}`}
          value={values.details}
          error={
            typeof errorMessage === 'object' && getErrorMessage('details')
              ? getErrorMessage('details')
              : errors.details
          }
          withFlickerHoverAnim
          colorTheme="darkTheme"
          showError={false}
          className="fields-big"
        />
        <InputField
          name="email"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('email', errors?.email)}
          placeholder={`${getTranslationsByLangOrEng(
            interfaceLanguage,
            'form_email_placeholder',
          )} *`}
          value={values.email}
          error={
            typeof errorMessage === 'object' && getErrorMessage('email')
              ? getErrorMessage('email')
              : errors.email
          }
          required
          withFlickerHoverAnim
          colorTheme="darkTheme"
          showError={false}
          className="fields-item"
        />
        <InputField
          name="phone"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('phone', errors?.phone)}
          placeholder={`${getTranslationsByLangOrEng(
            interfaceLanguage,
            'shoppers_79',
          )} *`}
          value={values.phone}
          error={
            typeof errorMessage === 'object' && getErrorMessage('phone')
              ? getErrorMessage('phone')
              : errors.phone
          }
          required
          withFlickerHoverAnim
          colorTheme="darkTheme"
          showError={false}
          className="fields-item"
        />
      </div>
      <div className="utils">
        <div className="utils-terms">
          <CircleCheckbox
            colorTheme="darkTheme"
            name="terms"
            value={values.terms}
            onChange={() => setFieldValue('terms', !values.terms)}
            error={
              typeof errorMessage === 'object' && getErrorMessage('terms')
                ? getErrorMessage('terms')
                : touched.terms && errors.terms
                  ? errors.terms
                  : undefined
            }
            showError
          >
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_75')}{' '}
              <Link
                target="_blank"
                to={`/${interfaceLanguage}/privacy-policy`}
              >
                {getTranslationsByLangOrEng(interfaceLanguage, 'form_2')}
              </Link>
            </p>
          </CircleCheckbox>
        </div>
        <button
          type="submit"
          onClick={() => {}}
          className="utils-submit"
        >
          <span>
            {getTranslationsByLangOrEng(interfaceLanguage, 'form_submit')}
          </span>
          <ArrowRight color="#000" />
        </button>
      </div>
    </Form>
  );

  return (
    <SupportStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_73')}</h1>
        <div className="form-wrapper">
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_74')}</h2>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {renderForm}
          </Formik>
        </div>
      </div>
    </SupportStyles>
  );
};
