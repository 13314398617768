import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiWhyStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import ok from '../../../assets/images/cati/ok.svg';
import why from '../../../assets/images/cati/why.png';

export const CatiWhy = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiWhyStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cati_49')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_50')}
          </h2>
          <p className="description">{getTranslationsByLangOrEng(interfaceLanguage, 'cati_51')}</p>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_52')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_53')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_54')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_55')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_56')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_57')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_58')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_59')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_60')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_61')}</p>
            <img src={why} alt="CATI why" className="cards-img" />
          </div>
        </div>
      </div>

      <div className="btn-container">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#000"
          borderOnHover="#000"
          background="#000"
          className="btn"
          hoverBackground="transparent"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'cati_3',
          )}
        />
      </div>
    </CatiWhyStyles>
  );
};
