import styled from 'styled-components';

export const CatiSolvesStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 991px) {
    gap: 30px;
    padding: 40x 0;
  }

  .cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 28px 28px;
    grid-template-areas:
      '. one two'
      'three four .'
      'five . .';

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 22px 20px;
      gap: 24px;
      border-width: 2px 0px 0px 2px;
      border-style: solid;
      border-color: #000000;
      max-width: 240px;
      width: 100%;
      height: 230px;
      position: relative;

      @media (max-width: 768px) {
        max-width: none;
        width: 100%;
        height: 170px;
      }

      &:nth-child(1) {
        grid-area: one;
      }
      &:nth-child(2) {
        grid-area: two;
      }
      &:nth-child(3) {
        grid-area: three;
      }
      &:nth-child(4) {
        grid-area: four;
      }
      &:nth-child(5) {
        grid-area: five;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 115%;
        color: #000000;

        span {
          color: #9510b2;
        }
      }
    }

    &-gradient {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 86px;
      height: 15px;
      border-radius: 100px;
      position: relative;

      background: linear-gradient(90deg, #571ec1 0%, #ba08a8 100%);
      padding: 3px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: 768px) {
      position: static;
    }
  }
`;
