import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersTeamStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import team1 from '../../../assets/images/shoppers/team-1.png';
import team2 from '../../../assets/images/shoppers/team-2.png';
import team3 from '../../../assets/images/shoppers/team-3.png';
import team4 from '../../../assets/images/shoppers/team-4.png';
import team5 from '../../../assets/images/shoppers/team-5.png';
import team6 from '../../../assets/images/shoppers/team-6.png';
import team7 from '../../../assets/images/shoppers/team-7.png';
import team8 from '../../../assets/images/shoppers/team-8.png';

type TTeam = {
  img: string;
  name: string;
  description: string;
}

const teams: (land: string) => TTeam[] = (land: string) => ([
  {
    img: team1,
    name: getTranslationsByLangOrEng(land, 'shoppers_10'),
    description: getTranslationsByLangOrEng(land, 'shoppers_11'),
  },
  {
    img: team2,
    name: getTranslationsByLangOrEng(land, 'shoppers_12'),
    description: getTranslationsByLangOrEng(land, 'shoppers_13'),
  },
  {
    img: team3,
    name: getTranslationsByLangOrEng(land, 'shoppers_14'),
    description: getTranslationsByLangOrEng(land, 'shoppers_15'),
  },
  {
    img: team4,
    name: getTranslationsByLangOrEng(land, 'shoppers_16'),
    description: getTranslationsByLangOrEng(land, 'shoppers_17'),
  },
  {
    img: team5,
    name: getTranslationsByLangOrEng(land, 'shoppers_18'),
    description: getTranslationsByLangOrEng(land, 'shoppers_19'),
  },
  {
    img: team6,
    name: getTranslationsByLangOrEng(land, 'shoppers_20'),
    description: getTranslationsByLangOrEng(land, 'shoppers_21'),
  },
  {
    img: team7,
    name: getTranslationsByLangOrEng(land, 'shoppers_22'),
    description: getTranslationsByLangOrEng(land, 'shoppers_23'),
  },
  {
    img: team8,
    name: getTranslationsByLangOrEng(land, 'shoppers_24'),
    description: getTranslationsByLangOrEng(land, 'shoppers_25'),
  },
]);

const FlipCard: FC<TTeam> = ({ img, name, description }) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [flipped, setFlipped] = useState<boolean>(false);

  return (
    <div className={`flip-container ${flipped ? 'clicked' : ''}`} onClick={() => setFlipped(!flipped)}>
      <div className="flipper">
        <div className="front">
          <img src={img} alt={name} />
          <div className="front-text">
            <h6>{name}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_9')}</p>
          </div>
        </div>
        <div className="back">
          <div className="back-text">
            <h6>{name}</h6>
            <p>{description}</p>
          </div>
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_9')}</p>
        </div>
      </div>
    </div>
  );
};

export const ShoppersTeam = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersTeamStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_8')}</h2>
        <div className="cards">
          {teams(interfaceLanguage).map((item) => (
            <FlipCard
              key={item.img}
              img={item.img}
              name={item.name}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </ShoppersTeamStyles>
  );
};
