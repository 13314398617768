import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersProjectsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import logo1 from '../../../assets/images/shoppers/logo-1.png';
import logo2 from '../../../assets/images/shoppers/logo-2.png';
import logo3 from '../../../assets/images/shoppers/logo-3.png';
import logo4 from '../../../assets/images/shoppers/logo-4.png';
import logo5 from '../../../assets/images/shoppers/logo-5.png';
import logo6 from '../../../assets/images/shoppers/logo-6.png';
import logo7 from '../../../assets/images/shoppers/logo-7.png';
import logo8 from '../../../assets/images/shoppers/logo-8.png';
import logo9 from '../../../assets/images/shoppers/logo-9.png';
import logo10 from '../../../assets/images/shoppers/logo-10.png';
import logo11 from '../../../assets/images/shoppers/logo-11.png';
import logo12 from '../../../assets/images/shoppers/logo-12.png';
import logo13 from '../../../assets/images/shoppers/logo-13.png';
import logo14 from '../../../assets/images/shoppers/logo-14.png';
import logo15 from '../../../assets/images/shoppers/logo-15.png';
import logo16 from '../../../assets/images/shoppers/logo-16.png';
import logo17 from '../../../assets/images/shoppers/logo-17.png';
import logo18 from '../../../assets/images/shoppers/logo-18.png';
import logo19 from '../../../assets/images/shoppers/logo-19.png';
import logo20 from '../../../assets/images/shoppers/logo-20.png';
import logo21 from '../../../assets/images/shoppers/logo-21.png';
import logo22 from '../../../assets/images/shoppers/logo-22.png';
import logo23 from '../../../assets/images/shoppers/logo-23.png';
import logo24 from '../../../assets/images/shoppers/logo-24.png';
import logo25 from '../../../assets/images/shoppers/logo-25.png';
import logo26 from '../../../assets/images/shoppers/logo-26.png';
import NavigationButtons from '../../navigationButtons/NavigationButtons';

const logos: string[] = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
  logo25,
  logo26,
];

export const ShoppersProjects = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const logoWrapperRef = useRef<HTMLDivElement>(null);

  const [isAtStart, setIsAtStart] = useState<boolean>(true);
  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (logoWrapperRef.current) {
        setIsAtStart(logoWrapperRef.current.scrollLeft === 0);
        setIsAtEnd(
          logoWrapperRef.current.scrollLeft + logoWrapperRef.current.clientWidth
          >= logoWrapperRef.current.scrollWidth,
        );
      }
    };
    checkScrollPosition();
    logoWrapperRef.current?.addEventListener('scroll', checkScrollPosition);
    return () => logoWrapperRef.current?.removeEventListener('scroll', checkScrollPosition);
  }, []);

  const handlePrev = () => {
    if (logoWrapperRef.current) {
      logoWrapperRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  };

  const handleNext = () => {
    if (logoWrapperRef.current) {
      logoWrapperRef.current.scrollTo({ left: logoWrapperRef.current.scrollWidth, behavior: 'smooth' });
    }
  };

  return (
    <ShoppersProjectsStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_6')}</h2>
        <div ref={logoWrapperRef} className="logo-wrapper">
          <div className="logo-swiper">
            {logos.map((logo) => (
              <img src={logo} key={logo} alt="" className="logo-item" />
            ))}
          </div>
        </div>
        <div className="utils">
          <ButtonWithArrowOnHover
            link="https://4serviceua.shopmetrics.com/auth/index.asp#signup"
            arrowColor="#000"
            textColor="#fff"
            borderColor="transparent"
            borderOnHover="#fff"
            background="linear-gradient(2.66deg, #0855E2 9.08%, #1076C7 90.92%)"
            className="btn"
            hoverBackground="#fff"
            hoverTextColor="#000"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'shoppers_7',
            )}
          />

          <NavigationButtons
            onClickToNextButton={handleNext}
            onClickToPrevButton={handlePrev}
            buttonsIconsColor="#fff"
            borderColor="#fff"
            disabledPrev={isAtStart}
            disabledNext={isAtEnd}
          />
        </div>
      </div>
    </ShoppersProjectsStyles>
  );
};
