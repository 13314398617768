import styled from 'styled-components';

export const ShoppersInstructionStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;
    position: relative;

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0 auto;
      font-weight: 600;
      font-size: 40px;
      line-height: 115%;
      color: #ffffff !important;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
  }

  .cards {
    display: flex;
    width: 100%;
    gap: 30px;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      gap: 20px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      max-width: 270px;
      width: 100%;
      height: 310px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 8px;
        padding: 2px;
        background: linear-gradient(
          45deg,
          #8640f9 0%,
          #0029ff 50%,
          #002050 100%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        pointer-events: none;
      }

      img {
        height: 50%;
      }
    }

    &-text {
      padding: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin: 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 120%;
        color: #ffffff;
      }

      h6 {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #ffffff;
      }
    }
  }
`;
