import styled from 'styled-components';

export const DiaryStudyStyles = styled.div`
  font-family: 'Montserrat';
  position: relative;
  overflow: hidden;
  width: 100%;

  .container {
    max-width: 1190px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
      gap: 15px;
    }

    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      background: linear-gradient(273.29deg, #26f0a6 0%, #12bee4 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
      color: #000000;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    .content {
      max-width: 770px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      gap: 25px;
      z-index: 2;

      @media (max-width: 991px) {
        max-width: none;
      }
    }
  }

  .btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
