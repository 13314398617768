import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiExpertsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import experts1 from '../../../assets/images/cati/experts-1.png';
import experts2 from '../../../assets/images/cati/experts-2.png';
import experts3 from '../../../assets/images/cati/experts-3.png';
import experts4 from '../../../assets/images/cati/experts-4.png';
import experts5 from '../../../assets/images/cati/experts-5.png';
import experts6 from '../../../assets/images/cati/experts-6.png';
import experts7 from '../../../assets/images/cati/experts-7.png';
import experts8 from '../../../assets/images/cati/experts-8.png';
import experts9 from '../../../assets/images/cati/experts-9.png';
import experts10 from '../../../assets/images/cati/experts-10.png';
import experts11 from '../../../assets/images/cati/experts-11.png';
import experts12 from '../../../assets/images/cati/experts-12.png';
import experts13 from '../../../assets/images/cati/experts-13.png';
import arrow1 from '../../../assets/images/cati/arrow-1.png';
import arrow2 from '../../../assets/images/cati/arrow2.png';
import arrow3 from '../../../assets/images/cati/arrow3.png';
import arrow4 from '../../../assets/images/cati/arrow4.png';

export const CatiExperts = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiExpertsStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cati_84')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_85')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards-wrapper">
          <div className="cards">
            <div className="cards-item">
              <img src={experts1} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_86')}</p>
              <img src={arrow4} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts2} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_87')}</p>
              <img src={arrow3} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts3} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_88')}</p>
              <img src={arrow1} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts4} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_89')}</p>
              <img src={arrow1} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts5} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_90')}</p>
            </div>
            <div className="cards-item">
              <img src={experts6} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_91')}</p>
              <img src={arrow1} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts7} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_92')}</p>
              <img src={arrow1} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts8} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_93')}</p>
              <img src={arrow2} alt="arrow" className="cards-arrow" />
            </div>
            <div className="cards-item">
              <img src={experts9} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_94')}</p>
            </div>
            <div className="cards-item">
              <img src={experts10} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_95')}</p>
            </div>
            <div className="cards-item">
              <img src={experts11} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_96')}</p>
            </div>
            <div className="cards-item">
              <img src={experts12} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_97')}</p>
            </div>
            <div className="cards-item">
              <img src={experts13} alt="avatar" className="cards-avatar" />
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_98')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-container">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#000"
          borderOnHover="#000"
          background="#000"
          className="btn"
          hoverBackground="transparent"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'cati_3',
          )}
        />
      </div>
    </CatiExpertsStyles>
  );
};
