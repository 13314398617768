import IApi from './IApi';
import ApiResponse from './entities/ApiResponse';

export default class Api implements IApi {
  private baseUrl: string = process.env.REACT_APP_API_HOST!;

  private async fetchData(path: string, requestOptions: any): Promise<any> {
    try {
      // console.log(`${this.getBaseUrl(path)}${path}`, { ...requestOptions });
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });
      const statusCode = response.status;
      const data = await response.json();
      return {
        data,
        statusCode,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('FETCH ERROR:', e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  private async getData(path: string, tokenRequired = true): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  private async postData(
    path: string,
    data?: any,
    formData?: any,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    // if (tokenRequired) {
    //   const accessToken = localStorage.getItem('access_token');
    //   myHeaders.Authorization = `Bearer ${accessToken}`;
    // }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async putData(path: string, data: any, formData?: boolean) {
    const accessToken = localStorage.getItem('token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    // if (accessToken) {
    //   myHeaders.append('Authorization', `Bearer ${accessToken}`);
    // }

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  public async sendForm(data: {
    company_name: string;
    email: string;
    name: string;
    phone_number: string;
    web_site: string;
    details?: string;
  }): Promise<ApiResponse<any>> {
    return this.postData('/contact', data);
  }

  public async sendPartnerForm(data: {
    company_name: string;
    email: string;
    name: string;
    phone_number: string;
    web_site: string;
    details?: string;
  }): Promise<ApiResponse<any>> {
    return this.postData('/contacts/partner', data);
  }

  public async sendEmailForm(data: {
    email: string;
    language: string;
    web_site: string;
  }): Promise<ApiResponse<any>> {
    return this.postData('/contacts/subscriber', data);
  }

  public async sendSupportIssues(data: {
    email: string;
    full_name: string;
    login: string;
    phone: string;
    problem: string;
    details: string;
  }): Promise<ApiResponse<any>> {
    return this.postData('/contacts/question', data);
  }
}
