import styled from 'styled-components';
import banner from '../../../assets/images/employeeExperience/banner.png';

export const StoryEmployeeExperienceStyles = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    content: '';
    box-sizing: border-box;
    width: 521px;
    height: 521px;
    border: 2px solid rgba(0, 0, 255, 0.4);
    filter: drop-shadow(0px 0px 40px #0000ff);
    border-radius: 50%;
    right: -50px;
    top: -40px;
  }

  .container {
    padding: 100px 10px !important;
    margin: 50px auto !important;
    display: flex;
    flex-direction: column;
    border-top: 2px solid #0000ff;
    border-bottom: 2px solid #0000ff;
    position: relative;
    z-index: 5;

    @media (max-width: 768px) {
      padding: 40px 10px !important;
      gap: 20px;
    }

    img {
      position: absolute;
      right: 0;
      top: 100px;
      max-width: 100px;

      @media (max-width: 768px) {
        position: static;
      }
    }

    h2 {
      font-weight: 400;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 700px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }

    h4 {
      font-weight: 400;
      font-size: 32px;
      line-height: 120%;
      color: #ffffff;
      max-width: 700px;
      margin: 0;
      margin-top: 20px;

      @media (max-width: 768px) {
        font-size: 24px;
        margin-top: 0;
      }

      &.last {
        margin-top: 50px;

        @media (max-width: 768px) {
          margin-top: 0;
        }
      }
    }

    .btn {
      position: absolute;
      right: 0;
      bottom: 100px;

      @media (max-width: 768px) {
        position: static;
      }
    }
  }
`;
