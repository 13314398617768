export const it: { [key: string]: string } = {
  subscribe_with_click: 'Iscriviti con un clic',
  minimum_number_characters: 'numero minimo di 5 caratteri',
  request_details: 'Richiedi dettagli',
  form_title: 'La contatteremo a breve',
  form_required: 'I campi contrassegnati con * sono obbligatori',
  form_name_placeholder: 'Nome',
  form_email_placeholder: 'Email',
  form_phone_placeholder: 'Numero di telefono',
  form_company_placeholder: 'Società',
  form_submit: 'Invia',
  form_1:
    'Acconsento al trattamento dei dati personali secondo l`informativa sulla Privacy',
  form_2: 'Politica sulla privacy',
  validation_privacy_policy:
    'Si prega di accettare l`informativa sulla privacy',
  validation_phone: 'Numero di telefono non valido',
  validation_email: 'Formato email non valido',
  validation_required: 'I campi obbligatori non sono compilati',
  submit_error: 'Si è verificato un errore durante il salvataggio del modulo',
  footer_1: 'I nostri partner:',
  footer_2: 'Metodi',
  footer_3: 'Prodotti',
  footer_4: 'Contatti',
  footer_5: 'Impronta',
  footer_6: 'Politica sulla privacy',
  about_us_1: '4Service Responsabilità sociale d`impresa',
  about_us_2:
    'Facciamo del nostro meglio per mantenere una scintilla di gentilezza in ciascuno dei nostri dipendenti e rendere il mondo che ci circonda un posto migliore',
  about_us_3: '4Service Responsabilità sociale d`impresa',
  about_us_4:
    'Facciamo del nostro meglio per mantenere una scintilla di gentilezza in ciascuno dei nostri dipendenti e rendere il mondo che ci circonda un posto migliore',
  about_us_5: 'Gioco di affari di Service Mania',
  about_us_6:
    'Le nostre iniziative di sensibilizzazione per bambini e studenti',
  about_us_7: 'Beneficenza / Volontariato',
  about_us_8:
    'Orfanotrofi, case di cura per anziani e volontariato per l`esercito Ucraino. La parte più commovente delle nostre attività',
  about_us_9: 'Benessere degli animali',
  about_us_10:
    'Chiunque può entrare nel nostro ufficio con un animale domestico. Diamo una mano ai rifugi, raccogliamo fondi per il cibo e talvolta cerchiamo anche di trovare una casa per gli animali senza casa',
  about_us_11: '4Service Group.',
  about_us_12:
    'E` una Società di ricerca internazionale che gestisce l`esperienza del cliente (customer experience)',
  about_us_13:
    'Realizziamo progetti complessi con l`obiettivo di aumentare le vendite e sviluppare il business di aziende orientate al cliente.',
  about_us_14:
    '4Service Group è una holding di ricerca internazionale con sede a Vienna, Austria. Il 2001 segna l`inizio delle attività dell`azienda volte a migliorare la qualità della vita dei propri clienti e delle persone che interagiscono con l`azienda, acquistano i suoi servizi e consumano i suoi prodotti. Del resto non è un caso che sia stato scelto il nome «Forservice» per un`azienda, che rappresenta il servizio al cliente e la buona qualità.',
  about_us_15:
    'Questo è stato l`inizio della storia di 4Service a Kiev, in Ucraina, come fornitore leader di servizi di Mystery Shopping. Ha continuato a operare con successo come società di ricerca in 52 paesi con nuove opportunità in un approccio olistico all`analisi e al miglioramento dell`intera catena dell`esperienza del cliente (Customer experience CX).',
  about_us_16: 'nato in nome del servizio',
  about_us_17: 'primo ufficio situato al di fuori dell`Ucraina',
  about_us_18: 'trasferimento della sede centrale a Vienna',
  about_us_19: 'Membro d`élite MSPA, Top-10 agenzia in Europa',
  about_us_20: 'fondazione di uffici in 9 paesi',
  about_us_21: 'primi progetti digitali, Voicer',
  about_us_22: 'partner di DesignThinkers Academy',
  about_us_23: 'primi progetti globali di CХ',
  about_us_24: 'Missione',
  about_us_25:
    'La nostra missione è migliorare il servizio dei clienti in tutto il mondo. La nostra ispirazione sono i cambiamenti qualitativi che vediamo nelle persone e nelle aziende con cui lavoriamo.',
  about_us_26: '20+ anni di Customer Experience',
  about_us_27:
    '95 1 500 000+ valutazioni della qualità del servizio. 400+ progetti attivi.',
  about_us_28:
    'Accumuliamo e condividiamo la migliore esperienza del settore da tutto il mondo',
  about_us_29: 'Sede centrale a Vienna',
  about_us_30:
    'Raccogliamo e condividiamo la migliore esperienza del settore da tutto il mondo',
  about_us_31: 'valutazioni della qualità del servizio',
  about_us_32: 'Top-10 Agenzie Europee CX',
  about_us_33: '7 uffici di rappresentanza / sede centrale in Austria',
  about_us_34: 'progetti attivi',
  about_us_35: 'Il tuo posto di lavoro futuro',
  about_us_36: 'I nostri uffici.',
  about_us_37:
    '4Service ™ si impegna a creare uno spazio di lavoro confortevole per i propri dipendenti. I nostri dipendenti lavorano in ufficio, da remoto o possono unirsi per garantire la massima comodità e produttività di ciascun dipendente. Camere per il relax, supporto psicologico gratuito e un ambiente accogliente. Siamo amici degli umani e degli animali domestici.',
  about_us_38: 'Una squadra che si illumina.',
  about_us_39:
    'Il nostro team multinazionale si impegna ad ascoltare, ad appoggiarsi ai feedback dei nostri clienti e alla crescita reciproca. La mappa del percorso del cliente con i nostri clienti è importante per la nostra attività quanto il lancio di nuovi prodotti o il raggiungimento dei nostri obiettivi finanziari. Le nostre attività sono incentrate sull`avere un impatto positivo sulle comunità che ci circondano e sulla fornitura di innovazione e soluzioni incentrate sulle persone per i nostri clienti.',
  about_us_40: 'Tutto',
  about_us_41: 'Gestione',
  about_us_42: 'Il nostro Team Globale',
  about_us_43: 'Proprietari del prodotto',
  about_us_44: 'I nostri partner',
  about_us_45:
    'Realizziamo progetti complessi per aumentare le vendite e sviluppare il business delle aziende orientate al cliente.',
  about_us_46:
    'Istituti di formazione professionale che sono rappresentati in 25 paesi e formano, sviluppano e facilitano team e comunità multidisciplinari creativi per guidare un cambiamento positivo. Il Design Thinking ti consente di sviluppare e fornire idee innovative, modifiche e soluzioni a problemi complicati.',
  about_us_47:
    'La loro missione è fornire spazio e tempo ai team e agli individui per essere ispirati e sfidati, per «imparare facendo» e rafforzare la loro capacità di risoluzione creativa dei problemi e aiutare le organizzazioni a costruire l`ambiente e la mentalità necessari per dare il via a un`innovazione audace.',
  about_us_48:
    'è l`Associazione di categoria rappresentativa per le aziende coinvolte nel settore del Mystery Shopping. L`Associazione opera su base regionale in tutto il mondo, con sedi situate in America, Europa/Africa e Asia/Pacifico. Le aziende associate si uniscono come un organismo comune allo scopo di rafforzare l`industria del Mystery Shopping attraverso sforzi e azioni combinati.',
  about_us_49:
    'è un`organizzazione associativa senza scopo di lucro. I membri formano una comunità convinta del potenziale dell`analisi dei dati, della ricerca e degli approfondimenti per aiutare a migliorare le società, le organizzazioni e la vita di tutti.',
  about_us_50:
    'ESOMAR ha sede ad Amsterdam, nei Paesi Bassi. ESOMAR è presente in oltre 130 paesi attraverso i suoi membri dal 1947.',
  about_us_51:
    '(UCCA) è una comunità professionale di esperti privati e aziende unite con l`obiettivo di sviluppo e interazioni nell`area del contact center e del servizio al cliente. La filosofia dell`UCCA è quella di elevare la cultura del settore dei contact center, basata su tecnologie moderne e standard elevate.',
  about_us_52:
    'L`Associazione ucraina dei contact center è stata fondata nel 2010.',
  about_us_53:
    'studia la migliore esperienza mondiale nel campo dell`istruzione per adattarla e utilizzarla sul mercato Ucraino. Ecco perché Academy DTEK collabora con le principali business school internazionali: INSEAD, IE Business School e Stanford University.',
  about_us_54:
    'Academy DTEK è focalizzata sullo sviluppo di una cultura orientata al cliente e innovativa in D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School digital program, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Responsabile delle vendite internazionali',
  bht_1: 'Monitoraggio della salute del marchio',
  bht_2: 'Tracciamento',
  bht_3: 'I marchi sono incredibilmente potenti',
  bht_4: 'Scopri le prestazioni complessive del tuo marchio',
  bht_5: 'Circa',
  bht_6: 'Che cos`è il monitoraggio della salute del marchio?',
  bht_7:
    'Si tratta di un tipo di ricerca di mercato che viene condotta con una certa regolarità (ogni due settimane, ogni mese ecc.) al fine di ottenere informazioni ',
  bht_8:
    'sullo stato di un marchio sul mercato sulla base di indicatori quali la preminenza, la domanda, l`immagine, le caratteristiche dei suoi consumatori, salute del marchio, ecc.',
  bht_9: 'Acquista il marchio più spesso',
  bht_10: 'Leale',
  bht_11: 'Acquistato il marcio ultimi 3 mesi',
  bht_12: 'Clienti abituali/ripetuti',
  bht_13: 'Acquistato il marchio negli ultimi 6 mesi',
  bht_14: 'Clienti a breve termine',
  bht_15: 'Acquistato il marchio nell`ultimo anno',
  bht_16: 'Clienti a lungo termine',
  bht_17: 'Conosci il marchio? ma non l`ho mai usato/comprato',
  bht_18: 'Consapevoli, ma non consumatori',
  bht_19: 'Inconsapevole: non hai mai sentito parlare del marchio',
  bht_20: 'Inconsapevole',
  bht_21: 'Profilo degli acquirenti del marchio',
  bht_22:
    'Valutare la coerenza degli sforzi compiuti a lungo termine per costruire un marchio',
  bht_23: 'Misurare il livello di conoscenza del marchio da parte del cliente',
  bht_24: 'Mostrare l`efficacia delle campagne pubblicitarie e informative.',
  bht_25:
    'Determinare quale impatto ha un periodo di alta pubblicità sulle attività di vendita/marketing dei concorrenti.',
  bht_26: 'Fornendo un controllo costante all`imprenditore nei periodi di',
  bht_27: 'Quali compiti possono essere risolti da BHT:',
  bht_28: 'Richiedi una ricerca',
  bht_29: 'Compito da risolvere',
  bht_30: 'Ad esempio,',
  bht_31:
    'se un`azienda ha bisogno di una sola sessione di BHT a trimestre durante la misurazione della quota di mercato, un sondaggio tra gli intervistati dovrebbe essere condotto più spesso quando si misura la consapevolezza del marchio del cliente dalla pubblicità.',
  bht_32: 'CATI',
  bht_33: 'Personale (faccia a faccia)',
  bht_53: 'Interviste/Colloqui',
  bht_34: 'CAWIComputer Assisted Web Interviewing)',
  bht_35: 'Compito da risolvere',
  bht_36: 'By collecting information',
  bht_37:
    'Raccogliendo informazioni attraverso vari metodi, il tracciamento del marchio è solitamente suddiviso in tre fasi a seconda del pubblico di destinazione e della difficoltà di raggiungerlo:',
  bht_38:
    'Usiamo sia metodi tradizionali che contemporanei per collezionare dati:',
  bht_39:
    'Lavoriamo utilizzando due modelli, uno con un semplice insieme di indicatori, mentre l`altro si rivolge a settori più complessi come l`industria alimentare e dei servizi. Un modello più complesso prevede uno studio approfondito che include fino a 10 indici stimati.',
  bht_40: 'Offriamo il',
  bht_41: 'controllo sui dati iniziali',
  bht_42: 'Copriamo',
  bht_43: 'tutto il paese',
  bht_44: 'Forniamo una soluzione ',
  bht_45:
    'per mercati non dinamici/mercati con un numero limitato di attori (Brand Point Analyzer)',
  bht_46: 'Perché noi?',
  bht_47: 'Perché scegliere noi?',
  bht_50: 'Risultato negativo',
  bht_51: 'Buon risultato',
  bht_52: 'Risultato medio',
  cases_1:
    'Sfida: come migliorare l`esperienza di acquisto di veicoli elettrici dei clienti negli showroom Hyundai?',
  cases_2: 'Mappatura del percorso del cliente',
  cases_3: 'Ideazione',
  cases_4: 'Ricerca qualitativa',
  cases_5:
    'Sfida: cosa dovrebbe essere cambiato nei negozi (sia nel processo di servizio, negli interni, nella percezione del marchio) per attirare l`interesse dei clienti per il marchio Oppo?',
  cases_6: 'Online CJM',
  cases_7: 'Test UI',
  cases_8: 'Interviste',
  cases_9:
    'Sfida: cosa dovrebbe essere cambiato nei negozi per attirare l`interesse dei clienti verso il marchio Oppo?',
  cases_10: 'Parte qualitativa',
  cases_11: 'Ideazione',
  cases_12: 'Parte quantitativa',
  cases_13: 'Mappa del percorso del cliente /Customer Journey Map',
  cases_14: 'Casi',
  cases_15: 'Tutto ',
  cases_16: 'Creiamo insieme il vostro caso eccezionale',
  csi_1: 'Cliente',
  csi_2: 'indice di soddisfazione',
  csi_3: 'Determinare i motivi ',
  csi_4: 'della soddisfazione e dell`insoddisfazione dei consumatori',
  csi_5: 'La ricerca sulla soddisfazione del cliente ',
  csi_6:
    'è la determinazione dei fattori, delle cause e dei motivi che influenzano la decisione di acquisto di un cliente, nonché il comportamento del pubblico target.',
  csi_7: 'Un sondaggio sulla soddisfazione del cliente ',
  csi_8:
    'mostra quanto è soddisfatto o insoddisfatto un cliente rispetto a un particolare aspetto del servizio o del prodotto in generale.',
  csi_9: 'Il sondaggio sulla soddisfazione ',
  csi_10:
    'viene condotto tra i clienti esistenti di un`azienda e gli utenti di determinati prodotti/servizi.',
  csi_11: 'Che cos`è la CSI?',
  csi_12:
    'La soddisfazione del cliente misura l’entità in cui le aspettative di un cliente sono state soddisfatte per quanto riguarda l`acquisto di un prodotto o servizio fornito dalla tua azienda.',
  csi_13: 'Happy man',
  csi_14: 'Circa',
  csi_15: 'Identificare eventuali fattori nascosti ',
  csi_16: 'che possono influenzare la soddisfazione del cliente',
  csi_17: 'Determinare le ragioni',
  csi_18:
    'alla base di qualsiasi aumento/diminuzione della domanda di beni e servizi',
  csi_19: 'Ottimizzare l`azienda',
  csi_20: 'processi di business',
  csi_21: 'Adattandoli',
  csi_22: ' alle esigenze del consumatore moderno',
  csi_23: 'Formare un messaggio pubblicitario efficace',
  csi_24: ' e posizionare un prodotto sotto la richiesta del cliente',
  csi_25: 'Compiti da risolvere',
  csi_26:
    'Quali compiti possono risolvere i sondaggi sulla soddisfazione dei clienti',
  csi_27: 'Uomo con il telefono',
  csi_28: 'Costruiamo un modello matematico ',
  csi_29:
    'dei fattori che possono influenzare la soddisfazione/fidelizzazione del cliente (analisi di regressione)',
  csi_30: 'Ottimizzare',
  csi_31: ' i processi di business di un`azienda',
  csi_32: 'Costruiamo un indice di soddisfazione del cliente calcolato',
  csi_33: '(CSI) e confrontarlo con quello dichiarativo',
  csi_34: 'Conduciamo ricerche',
  csi_35: ' per entrambi i segmenti B2B e B2C.',
  csi_36: 'I nostri questionari sono sviluppati da un team ',
  csi_37:
    'di analisti specialmente per i progetti individuali dei nostri clienti',
  csi_38: 'Perche noi',
  csi_39: 'Perché scegliere noi?',
  csi_40: 'Methods of collecting information:',
  main_1:
    'migliorare la propria performance finanziaria imparando dalle esperienze dei propri clienti',
  main_2: 'Consulenza e servizi СX una visione a 360° del tuo business',
  main_3:
    'Scopri la nostra gamma di soluzioni e strumenti che utilizziamo per risolvere le tue sfide aziendali',
  main_4: 'Prodotti',
  main_5:
    'La ricerca qualitativa è la raccolta e l`analisi di dati non numerici per comprendere concetti, opinioni o esperienze',
  main_6:
    'I metodi di ricerca quantitativi sono la misurazione oggettiva e l`analisi numerica dei dati raccolti attraverso sondaggi, questionari e indagini.',
  main_7:
    'Il mystery shopping è una tecnica di ricerca di mercato per misurare e monitorare le interazioni tra un cliente e un`azienda o un`organizzazione durante uno scenario predefinito',
  main_8:
    'Sistema di gestione del feedback. La voce del tuo cliente in un unico sistema - Sistema di gestione del feedback dei clienti',
  main_9:
    'Una piattaforma di content intelligence basata sull`intelligenza artificiale che fornisce approfondimenti dal pubblico',
  main_10:
    'Software di gestione delle prestazioni. Applicazione gamificata per aumentare le vendite',
  main_11:
    'Il monitoraggio dei prezzi è il processo di raccolta, elaborazione e analisi dei prezzi dei concorrenti',
  main_12:
    'Il service design è come comprendere i bisogni e i desideri delle persone che utilizzeranno un prodotto o un servizio trascorrendo del tempo con loro',
  main_13:
    'Il nostro team analitico applica gli approcci più aggiornati e fondamentali che abbiamo dimostrato di essere efficaci con molti clienti',
  main_14: 'Metodologie',
  main_15: 'Mappa di percorso del cliente',
  main_16:
    'Customer Journey Mapping (CJM) riguarda la definizione di un pubblico target, la comprensione delle sue esigenze e dell`ambiente e quindi la creazione di un piano per offrire un`esperienza coinvolgente',
  main_17: 'CJM',
  main_18: 'Punteggio promotore internet',
  main_19:
    'Il Net Promoter Score (NPS) è il gold standard delle metriche relative all`esperienza del cliente, basato su una domanda: con quale probabilità consiglieresti organizzazione/prodotto/servizio?',
  main_20: 'NPS',
  main_21: 'Monitoraggio della salute del marchio',
  main_22:
    'La ricerca Brand Health Tracking (BHT) /Monitoraggio della salute del marchio/ può misurare le prestazioni del tuo marchio in termini di consapevolezza e utilizzo, posizionamento del marchio e prestazioni del marchio',
  main_23: 'BHT',
  main_24: 'Ricerca sulle risorse umane',
  main_25:
    'La ricerca sulle risorse umane viene utilizzata per valutare le pratiche e le prestazioni delle risorse umane e offre un`analisi dettagliata sullo sviluppo e sulla gestione attuali',
  main_26: 'Ricerca sulle risorse umane',
  main_27: 'Indice di soddisfazione del cliente',
  main_28:
    'La ricerca sulle risorse umane viene utilizzata per valutare le pratiche e le prestazioni delle risorse umane e offre un`analisi dettagliata sullo sviluppo e sulla gestione attuali',
  main_29: 'CSI',
  main_30: 'Test UX',
  main_31:
    'Il test di usabilità (UX) è il modo in cui le persone reali interagiscono con un sito Web, un`app o un altro prodotto e ne osservano il comportamento e le reazioni',
  main_32: 'Perche noi',
  main_33:
    'La nostra missione è aiutare le aziende a fornire un`esperienza cliente altamente efficace',
  main_34: 'Puntare sulla qualità',
  main_35: 'Controlliamo la qualità ',
  main_36:
    'quando si tratta di dati di ricerca. Mettiamo a disposizione dei clienti tutti i set di dati',
  main_37: 'Conveniente',
  main_38: 'La nostra esperienza, le risorse e i processi ',
  main_39:
    'aziendali semplificati ci consentono di farti risparmiare tempo e denaro',
  main_40: 'Soluzioni smart',
  main_41: 'Utilizziamo una varietà di tecniche ',
  main_42: 'e toolkit per risolvere i tuoi problemi particolari',
  main_43: 'Puntare sulla qualità',
  main_44: 'We quality-controlled',
  main_45:
    'when it comes to research data. We make all data sets available to clients',
  main_46: 'Conveniente',
  main_47: 'La nostra esperienza, le risorse e i processi ',
  main_48:
    'aziendali semplificati ci consentono di farti risparmiare tempo e denaro',
  main_49: 'Soluzioni smart',
  main_50: 'Utilizziamo una varietà di tecniche ',
  main_51: 'e toolkit per risolvere i tuoi problemi particolari',
  main_52: 'Cash & Carry',
  main_53: 'Elettronica ',
  main_54: 'Lusso/& Premium',
  main_55: 'Ristorazione & Ospitalità',
  main_56: 'Vendita al dettaglio',
  main_57: 'Settore automobilistico',
  main_58: 'Moda',
  main_59: 'Banche',
  main_60: 'Viaggi & tempo libero',
  main_61: 'Orologi & Gioielli',
  main_62: 'Casalinghi & elettrodomestici',
  main_63: 'Blog',
  main_64: 'Vai al blog per leggere altri post interessanti',
  main_65: 'Retail audit',
  main_66:
    'Raccogliere dati dettagliati sul vostro negozio o marchio, analizzare gli ostacoli alla crescita e creare strategie efficaci per superarli',
  cjm_1: 'Clientela',
  cjm_2: 'Mappa del viaggio/mappa del viaggio',
  cjm_3: 'Entra nel mondo dei clienti e condividi la loro esperienza',
  cjm_4:
    'Parlate con un esperto di cosa è la Customer Journey Map (percorso del cliente)?',
  cjm_5:
    'CJM fornisce una cronologia visualizzata dell`interazione del consumatore con un prodotto, servizio, azienda o marchio attraverso vari canali e durante un certo periodo di tempo.',
  cjm_6:
    'Ciò consente alle aziende di analizzare in modo oggettivo l`esperienza di interazione con il cliente, di correggere ed eliminare eventuali ostacoli e di offrire raccomandazioni per il miglioramento del prodotto.',
  cjm_7: 'Determinazione dell`AT, segmentazione',
  cjm_8: 'Individuazione delle fasi di interazione con il cliente',
  cjm_9: 'Identificazione dei principali canali di interazione con il cliente',
  cjm_10: 'Ricerca sull`esperienza del cliente',
  cjm_11: 'Analisi delle barriere e ipotesi di ottimizzazione',
  cjm_12: 'Esecuzione e verifica di ipotesi',
  cjm_13:
    'Progettazione di una mappa del percorso( Customer Journey Map) del cliente consiste nei seguenti passaggi:',
  cjm_14: 'Mappa del percorso/Jorney Map',
  cjm_15:
    'CJM fornisce una cronologia visualizzata dell`interazione del consumatore con un prodotto, servizio, azienda o marchio attraverso vari canali e durante un certo periodo di tempo.',
  cjm_16: 'Garantiamo il lavoro di squadra',
  cjm_17: ' tra analisti e designer',
  cjm_18: 'Offriamo un approccio individuale per ogni cliente',
  cjm_19: 'approach to each client',
  cjm_20:
    'Possiamo aumentare la soddisfazione complessiva del cliente con il servizio/prodotto e, in ultima analisi, aumentare la loro fedeltà all`azienda',
  cjm_21:
    'customer satisfaction with the service/product and ultimately increase their loyalty to the company',
  cjm_22:
    'Garantiamo l`ottimizzazione del percorso del consumatore e prendiamo in considerazione i processi di business dell`azienda',
  cjm_23:
    'optimization of the consumer’s path and take into account the company’s business processes',
  cjm_24:
    'Offriamo ai nostri clienti la possibilità di utilizzare approcci combinati: CATI, CAWI, focus group, interviste in profondità, interviste agli esperti, etnografia, ecc.',
  cjm_25:
    'the opportunity to use combined approaches: CATI, CAWI, focus groups, in-depth interviews, expert interviews, ethnography, etc.',
  cjm_26: 'Perché scegliere noi?',
  methods_hr_1: 'Ricerca Risorse Umane',
  methods_hr_2:
    'Ottieni informazioni obiettive sul tuo team e scopri come migliorare le performance HR',
  methods_hr_3:
    'Alcuni motivi per cui potresti aver bisogno di una ricerca sulle risorse umane',
  methods_hr_4: ' del personale intervistato',
  methods_hr_5: ' per i progetti delle risorse umane implementati',
  methods_hr_6: 'sono state organizzate sessioni di formazione del personale',
  methods_hr_7:
    'Parla con un esperto di Holding Leader in Europa per la customer experience e la ricerca dei dipendenti.',
  methods_hr_8: '4service HR ricerche in numeri',
  methods_hr_9:
    'Per identificare i problemi e le debolezze in ogni fase della vita dei dipendenti della tua azienda: ricerca/reclutamento/Onboarding/CarrieraSviluppo/Ricertificazioni',
  methods_hr_10: 'Per sapere quali occasioni meritano aumenti e bonus',
  methods_hr_11:
    'È necessario prevedere o fornire soluzioni per l`insoddisfazione del lavoro',
  methods_hr_12:
    'Attraverso la ricerca sulle HR ( risorse umane) sono possibili nuovi e attuali metodi di valutazione delle politiche, dei programmi e delle pratiche delle HR(risorse umane)',
  methods_hr_13: 'Per migliorare la salute mentale dei dipendenti dell`azienda',
  methods_hr_14:
    'Se vuoi migliorare la produttività attraverso un approccio orientato agli obiettivi',
  methods_hr_15:
    'Se hai un team internazionale e / o remoto e hai bisogno di gestirlo',
  methods_hr_16:
    'Per capire quanto è attraente il tuo Employer Brand per i potenziali dipendenti',
  methods_hr_17: 'Soddisfazione degli impiegati',
  methods_hr_18: 'Ricerca sul marchio delle risorse umane',
  methods_hr_19: 'Diversità culturale',
  methods_hr_20: 'Piani retributivi e salariali',
  methods_hr_21: 'Coinvolgimento e lealtà del team',
  methods_hr_22: 'Classe protetta',
  methods_hr_23: 'Aree di ricerca',
  methods_hr_24: 'Soluzioni 1',
  methods_hr_25: 'eNPS, soddisfazione dei dipendenti, fedeltà dei dipendenti',
  methods_hr_26: 'Cosa misuriamo:',
  methods_hr_27: 'Lealtà:',
  methods_hr_28:
    'lealtà dei dipendenti, previsione del comportamento futuro, determinazione del peso dei fattori che influenzano la lealtà',
  methods_hr_29: 'Coinvolgimento:',
  methods_hr_30:
    'coinvolgimento dei dipendenti nel lavoro, interesse per i risultati e le realizzazioni',
  methods_hr_31: 'Fidanzamento:',
  methods_hr_32:
    'identificazione dei valori dei dipendenti e dei valori aziendali',
  methods_hr_33: 'Fidati di 360:',
  methods_hr_34:
    'fiducia nella direzione, nei colleghi, nei subordinati, fiducia nelle strategie e nelle politiche del datore di lavoro',
  methods_hr_35: 'Soddisfazione:',
  methods_hr_36:
    'definizione dei fattori unici per ogni azienda, valutazione della soddisfazione su ogni fattore chiave che influenza la fidelizzazione',
  methods_hr_37: 'Soluzioni 2',
  methods_hr_38: 'Marchio del datore di lavoro',
  methods_hr_39:
    'Analisi interna dell`employer brand (personale, stakeholder, reclutatori interni e indagine sugli specialisti delle risorse umane)',
  methods_hr_40:
    'brand analysis (staff, stakeholder, internal recruiters and HR specialists survey)',
  methods_hr_41:
    'Analisi del datore di lavoro esterno (analisi EVP della concorrenza, sondaggio sui reclutatori esterni, sondaggio sulle persone in cerca di lavoro)',
  methods_hr_42:
    '(competitor EVP analysis, external recruiter survey, job seeker survey)',
  methods_hr_43: 'Analisi della brand awareness e della reputazione',
  methods_hr_44: 'analysis',
  methods_hr_45: 'Presenza del datore di lavoro',
  methods_hr_47: 'Raccolta delle referenze del datore di lavoro',
  methods_hr_48: 'collection',
  methods_hr_49: 'Benchmarking competitivo ',
  methods_hr_50: 'su fattori chiave come ',
  methods_hr_51: 'stipendio,',
  methods_hr_52: ' ambiente di lavoro, atmosfera, natura del lavoro',
  methods_hr_53: 'Analisi dei bisogni e delle aspettative',
  methods_hr_54: ' delle persone in cerca di lavoro',
  methods_hr_55: 'Interviste approfondite,',
  methods_hr_56:
    'vengono utilizzate ricerche documentali e ricerche quantitative',
  methods_hr_57: 'Soluzione 3',
  methods_hr_58: 'Mappa del viaggio dei dipendenti/impiegato misterioso',
  methods_hr_59:
    'costruire l`identificazione del percorso di ricerca del lavoro',
  methods_hr_60:
    'le principali fasi della ricerca, i canali di comunicazione, i criteri di selezione e valutazione delle persone in cerca di lavoro',
  methods_hr_61: 'monitoraggio di tutte le fasi',
  methods_hr_62:
    'e canali di comunicazione con le persone in cerca di lavoro mystery/misterioso/ (basta fissare un appuntamento per un colloquio, sostenere un colloquio, uno stage in azienda)',
  methods_hr_63: 'Identificazione ',
  methods_hr_64: 'dei punti deboli nei processi di reclutamento e onboarding',
  methods_hr_65: 'Raccomandazioni per il miglioramento,',
  methods_hr_66: ' benchmarking con i leader del settore',
  mystery_shopping_1: 'Mystery',
  mystery_shopping_2: 'Shopping',
  mystery_shopping_3: 'Valuta il tuo servizio con clienti reali',
  mystery_shopping_4:
    'Verifica gli standard valuta il tuo personale avvicinati al tuo cliente',
  mystery_shopping_5:
    'Siamo membri d`élite dell`associazione professionale leader di fornitori di servizi di Mystery Shopping MSPA Europe',
  mystery_shopping_6: 'di esperienza ',
  mystery_shopping_7: 'visite mensili',
  mystery_shopping_8: 'mystery shopper in tutto il mondo',
  mystery_shopping_9: 'Progetti attivi',
  mystery_shopping_10: 'uffici, quartier generale in Austria',
  mystery_shopping_11: 'Fornitori europei di Mystery Shopping',
  mystery_shopping_12: 'valutazioni della qualità del servizio',
  mystery_shopping_13: 'Questionario',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: 'Profilo dell`acquirente',
  mystery_shopping_16: 'Convalida e obiezione',
  mystery_shopping_17: 'Analitica',
  mystery_shopping_18:
    'Con questi 5 passaggi la nostra azienda 4Service garantisce la qualità del servizio di mystery shopper',
  mystery_shopping_19: 'Programma',
  mystery_shopping_20:
    'I 5 elementi del programma di Mystery Shopping di 4Service',
  mystery_shopping_21: 'Questionario',
  mystery_shopping_22: 'l`essenza del vostra programma',
  mystery_shopping_23:
    'Questa parte del programma è visualizzabile da tutti i soggetti interessati',
  mystery_shopping_24: 'Per i top manager.',
  mystery_shopping_25:
    ' Mostra tutte le fasi e i dettagli importanti del processo',
  mystery_shopping_26: 'per gli impiegati.',
  mystery_shopping_27:
    'Rifletti su ciò che i datori di lavoro si aspettano da loro',
  mystery_shopping_28: 'Per mystery shoppers',
  mystery_shopping_29:
    'Chiaro e strutturato per evitare confusione e pregiudizi',
  mystery_shopping_30: 'Software Shopmetrics:',
  mystery_shopping_31: 'facile da usare e innovativo',
  mystery_shopping_32: 'Benefici:',
  mystery_shopping_33: 'Integrazione ',
  mystery_shopping_34: 'con i processi aziendali e il software',
  mystery_shopping_35: 'Guidati dal GDPR',
  mystery_shopping_36: 'Toolkit per la gestione della privacy',
  mystery_shopping_37: 'Dettagliato',
  mystery_shopping_38: 'Pacchetto di conformità legale',
  mystery_shopping_39: 'relazione',
  mystery_shopping_40: 'costruttore',
  mystery_shopping_41: 'Gestione',
  mystery_shopping_42: ' dei livelli di accesso',
  mystery_shopping_43: 'La maggior parte dei questionari',
  mystery_shopping_44: 'viene completata entro un`ora',
  mystery_shopping_45: 'profilo degli acquirenti:',
  mystery_shopping_46: 'Oltre 200.000 mystery shopper in tutto il mondo',
  mystery_shopping_47:
    'Criteri principali: massima vicinanza al tuo target di riferimento, con precedenti esperienze con la tua azienda o concorrenti',
  mystery_shopping_48: 'Formazione degli acquirenti:',
  mystery_shopping_49: 'Prova di introduzione alla formazione',
  mystery_shopping_50: 'Skype/chiamata telefonica',
  mystery_shopping_51: 'Test di valutazione (facoltativo)',
  mystery_shopping_52: 'Convalida e obiezioni',
  mystery_shopping_53: 'Validazione ',
  mystery_shopping_54: 'Sistema',
  mystery_shopping_55: 'Il 100% dei report',
  mystery_shopping_56: ' viene esaminato dal team di convalida',
  mystery_shopping_57: 'Ulteriori richieste ',
  mystery_shopping_58: 'di chiarimenti',
  mystery_shopping_59: 'Registrazioni audio ',
  mystery_shopping_60:
    'dopo la visita per annotare le questioni più importanti',
  mystery_shopping_61: 'Sistema di prevenzione delle frodi dei dipendenti',
  mystery_shopping_62: 'prevention system',
  mystery_shopping_63: 'Obiezione',
  mystery_shopping_64: 'Sistema',
  mystery_shopping_65: 'La valutazione di qualsiasi acquirente ',
  mystery_shopping_66:
    'può essere contestata dal dipendente direttamente sul sito web',
  mystery_shopping_67: 'Ogni obiezione ',
  mystery_shopping_68:
    'viene considerata e risolta dal team di convalida locale',
  mystery_shopping_69: 'Puoi vedere la statistica ',
  mystery_shopping_70: 'delle obiezioni',
  mystery_shopping_71: 'Analitica',
  mystery_shopping_72: 'La relazione viene presentata di persona e contiene:',
  mystery_shopping_73: 'I punti deboli e le preoccupazioni dell`azienda',
  mystery_shopping_74: 'pain points and concerns',
  mystery_shopping_75: 'Dinamiche ',
  mystery_shopping_76: 'per divisioni e sezioni',
  mystery_shopping_77: 'Correlazione ',
  mystery_shopping_78:
    'tra il rispetto degli standard e la soddisfazione del cliente',
  mystery_shopping_79: 'Benchmarking',
  mystery_shopping_80: ' contro concorrenti',
  mystery_shopping_81:
    'Raccomandazioni pratiche per il miglioramento del servizio',
  mystery_shopping_82: 'recommendations on service improvements',
  mystery_shopping_83: 'Cosa ci rende diversi?',
  mystery_shopping_84: 'Realizziamo sessioni di ideazione',
  mystery_shopping_85:
    'Non solo consigliamo cosa fare, ma insieme a voi cerchiamo modi pratici per risolvere i problemi di servizio.',
  mystery_shopping_86:
    'Le sessioni di ideazione sono un potente strumento che ci aiuta:',
  mystery_shopping_87:
    'Sviluppare indicazioni per il miglioramento di prodotti o servizi',
  mystery_shopping_88:
    'Per esplorare nuove opportunità di flusso di entrate e strategie di business',
  mystery_shopping_89:
    'Per trovare soluzioni a complesse sfide incentrate sul cliente',
  mystery_shopping_90: 'Per trasformare i punti deboli dei clienti in delizie',
  mystery_shopping_91: 'Realizziamo video interviste',
  mystery_shopping_92:
    'Non raccogliamo solo questionari, ma riceviamo anche commenti in tempo reale dagli acquirenti',
  mystery_shopping_93: 'Lo facciamo per:',
  mystery_shopping_94:
    'I nostri facilitatori condurranno interviste approfondite e forniranno un feedback efficace',
  mystery_shopping_95: 'Guarda l`esperienza dalla prospettiva del cliente',
  mystery_shopping_96: 'Migliora la tua strategia di marketing dei contenuti',
  mystery_shopping_97: 'prevedere il comportamento del cliente',
  mystery_shopping_98: 'Progettiamo la Customer Journey Map',
  mystery_shopping_99:
    'La mappatura del percorso del cliente ci aiuta a calarci nei panni dei clienti per comprenderne le esigenze e le sfide.',
  mystery_shopping_100: 'Creare un customer journey è il modo migliore per:',
  mystery_shopping_101: 'Guarda l`esperienza dalla prospettiva del cliente',
  mystery_shopping_102: 'Migliora la tua strategia di marketing dei contenuti',
  mystery_shopping_103: 'Prevedere il comportamento del cliente',
  mystery_shopping_104:
    'Identificare le lacune nel servizio o nelle comunicazioni',
  mystery_shopping_105: 'Forniamo diversi tipi di Mystery Shopping',
  mystery_shopping_106: 'Tipologie di Mystery Shopping',
  mystery_shopping_107: 'Mystery chiamante',
  mystery_shopping_108:
    'Chiama i tuoi manager e/o il call center per determinare la qualità del servizio fornito al telefono',
  mystery_shopping_109: 'Lusso mystery shopping',
  mystery_shopping_110:
    'Servizio di controllo in location di lusso da parte di una categoria speciale di mystery shopper',
  mystery_shopping_111: 'Visite competitive',
  mystery_shopping_112:
    'Il modo più semplice per capire come opera il tuo concorrente',
  mystery_shopping_113: 'Impiegato misterioso/Dipendente Mystery ',
  mystery_shopping_114:
    'Ti aiuterà a comprendere l`intero percorso del tuo dipendente (assunzione, adattamento, processi d`ufficio, ecc.)',
  mystery_shopping_115: 'Visite motivazionali',
  mystery_shopping_116:
    'Uno strumento utile per stimolare l`offerta di servizi e/o prodotti aggiuntivi, la vendita di prodotti specifici',
  mystery_shopping_117: 'Mystery Shopper Online',
  mystery_shopping_118:
    'Simula e misura l`esperienza del cliente nel tuo negozio online, sito Web o app',
  mystery_shopping_119: 'Controllo delle vendite e delle promozioni',
  mystery_shopping_120:
    'Aiuta a identificare come vengono visualizzate le promozioni in tutte le località e quanto efficacemente funzionano',
  mystery_shopping_121: 'Acquisto e visite di ritorno',
  mystery_shopping_122:
    'Aiuta a riflettere l`intero processo dall`acquisto al reso per migliorare l`esperienza del cliente',
  mystery_shopping_123: '> 20 anni',
  nps_1: 'Net Promoter',
  nps_2: 'Score',
  nps_3: 'Misura, monitora e migliora la fidelizzazione dei clienti',
  nps_4: 'Rispondono con un punteggio da 0 a 6. ',
  nps_5:
    'Potrebbero aver avuto un`esperienza negativa ed è improbabile che comprino di nuovo da te, o potrebbero persino scoraggiare gli altri dal comprando da te. ',
  nps_6: 'Questi sono gli intervistati che ottengono un punteggio tra 7 e 8. ',
  nps_7:
    'Sono soddisfatti del tuo servizio ma non abbastanza felici da essere considerati promotori.',
  nps_8:
    'Questi sono gli intervistati che valutano il tuo business  come un 9 o un 10. ',
  nps_9:
    'Ciò significa che ti consiglierebbero a un amico o a un collega e quindi stanno difendendo per tuo conto.',
  nps_10: 'Che cos’è NPS',
  nps_11: 'It measures customer perception based on one simple question:',
  nps_12:
    'How likely is it that you would recommend [Organization/Product /Service] to a friend or colleague?',
  nps_13: 'La mia azienda ne ha bisogno?',
  nps_14: 'NPS è la tua crescita sostenibile',
  nps_15: 'Understand the Dynamics of Customer Loyalty',
  nps_16: 'Motiva il tuo personale',
  nps_17: 'Comprendere l`atteggiamento nei confronti dei diversi prodotti',
  nps_18: 'Confronta il modo in cui i clienti trattano te e i tuoi concorrenti',
  nps_19: 'Cosa posso fare con NPS? ',
  nps_20: 'Per quale tipo di attività è rilevante NPS?',
  nps_21: 'Information gathering methods:',
  nps_22: 'I nostri consigli quando si lavora con NPS',
  nps_23:
    'Ciò significa che sviluppiamo linee guida per aiutare a trasformare i detrattori in promotori.',
  nps_24: 'Lavoriamo con un metodo “a circuito chiuso” (closed loop).',
  nps_25:
    'NPS è una ricerca di monitoraggio in cui monitoriamo i cambiamenti nella fedeltà dei clienti',
  nps_26: 'Monitoriamo le dinamiche ',
  nps_27:
    'Offriamo di valutare sempre i concorrenti nella tua nicchia per un analisi più approfondite.',
  nps_28: 'Valutazione dei concorrenti',
  price_monitoring_1: 'Monitoraggio dei prezzi',
  price_monitoring_2: 'Monitoraggio della vendita al dettaglio.',
  price_monitoring_3:
    'Aumentare il potenziale di vendita e ottimizzare la  politica dei prezzi monitorando i prezzi della concorrenza.',
  price_monitoring_4:
    '4Service fornisce servizi di monitoraggio dei prezzi in tutto il mondo da oltre 20 anni.',
  price_monitoring_5:
    'I prezzi sono monitorati in breve tempo dal nostro team di revisori interni qualificati, permettendoci di rispondere rapidamente alle variazioni dei prezzi dei concorrenti',
  price_monitoring_6:
    'SFIDE che la nostra azienda può aiutarti a risolvere con il monitoraggio dei prezzi',
  price_monitoring_7: 'Valutazione dei prezzi dei concorrenti',
  price_monitoring_8: 'e politiche di prodotto',
  price_monitoring_9: 'Monitoraggio di promozioni',
  price_monitoring_10: 'e offerte speciale',
  price_monitoring_11: 'Monitoraggio delle condizioni di mercato',
  price_monitoring_12: 'e del contesto di mercato',
  price_monitoring_13: 'Verifica e controllo punti di vendita',
  price_monitoring_14: 'per attrezzature e merci speciali',
  price_monitoring_15: 'Prodotto di gestione',
  price_monitoring_16: 'flusso all`interno del commercio',
  price_monitoring_17: 'Ottimizzare le condizioni',
  price_monitoring_18:
    'per una migliore collaborazione con fornitori, rivenditori',
  price_monitoring_19: 'Misurazione dello spazio sugli scaffali',
  price_monitoring_20:
    'valutazione del posizionamento delle merci, disponibilità di magazzino',
  price_monitoring_21: ' chiave indicatori e criteri di monitoraggio',
  price_monitoring_22:
    'Tutti i dati sono raccolti in un`unica interfaccia che include:',
  price_monitoring_23: 'Nome',
  price_monitoring_24: ' del prodotto e categoria del marchio',
  price_monitoring_25: 'disponibilità',
  price_monitoring_26: ' di materiale promozionale',
  price_monitoring_27: 'prezzo ',
  price_monitoring_28: 'e la disponibilità di eventuali offerte promozionali',
  price_monitoring_29: 'scaffale',
  price_monitoring_30: 'Misura',
  price_monitoring_31: 'Prodotto ',
  price_monitoring_32: 'Schermo',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'di fronte',
  price_monitoring_35: 'Tipo',
  price_monitoring_36: 'di confezionamento',
  price_monitoring_37: 'qualsiasi altra ',
  price_monitoring_38: ' informazione generale',
  price_monitoring_39: 'Analisi pre-progetto:',
  price_monitoring_40: 'Studiamo ',
  price_monitoring_41:
    ' la politica dei prezzi e dei prodotti della tua azienda, le pratiche commerciali e del settore',
  price_monitoring_42: 'Noi forniamo',
  price_monitoring_43:
    'formazione, test e certificazione tempestivi per gli auditor',
  price_monitoring_44: 'Personalizziamo',
  price_monitoring_45: ' e installiamo il software',
  price_monitoring_46: 'Lavoro sul campo:',
  price_monitoring_47: 'Visite alla vendita al dettaglio',
  price_monitoring_48: 'punti vendita',
  price_monitoring_49: 'Segnalazione:',
  price_monitoring_50: 'Il nostro team analitico',
  price_monitoring_51:
    'predispone la reportistica online nella contabilità personale dell`azienda',
  price_monitoring_52: 'Forniamo',
  price_monitoring_53:
    ' un rapporto fotografico completo, un rapporto PPT, un rapporto Excel in termini di tipo di località, nome della catena, indirizzo del punto vendita',
  price_monitoring_54: 'Come ',
  price_monitoring_55: 'funziona',
  price_monitoring_56: 'la dimensione ',
  price_monitoring_57: 'del calcolo del costo ',
  price_monitoring_58: 'degli sconti individuali',
  price_monitoring_59: 'programmi fedeltà',
  price_monitoring_60: 'eccetera',
  price_monitoring_61: 'Utilizziamo software specializzati:',
  price_monitoring_62:
    'PRADATA, Shopmetrics e offriamo un supporto tecnico completo',
  price_monitoring_63: 'Distribuzione rapida/',
  price_monitoring_64: 'Distribuzione e scalabilità rapide (dal giorno 1)',
  price_monitoring_65: 'Tutti i dati confermati',
  price_monitoring_66: 'da foto e tagging GPS',
  price_monitoring_67: 'Abbiamo il numero necessario ',
  price_monitoring_68: 'di agenti sul campo in qualsiasi paese',
  price_monitoring_69: 'Possiamo lavorare con grande',
  price_monitoring_70: 'quantità di dati',
  price_monitoring_71: 'Monitoraggio dei singoli prezzi',
  price_monitoring_72:
    'Se hai prodotti complessi, possiamo aiutarti a definire la tua strategia di prezzo',
  price_monitoring_73:
    'Siamo in grado di monitorare i prezzi dei singoli concorrenti, ad esempio:',
  price_monitoring_74: 'Basso',
  price_monitoring_75: 'Costo',
  price_monitoring_76: 'alta efficienza',
  price_monitoring_77: 'alta efficienza',
  price_monitoring_78: 'Rapporto Excel',
  price_monitoring_79: 'Rapporto PPT',
  price_monitoring_80: 'Rapporto BI',
  price_monitoring_81:
    'I dati sui prezzi della concorrenza ti aiutano a prendere le migliori decisioni sui prezzi.',
  price_monitoring_82:
    'Forniamo un rapporto fotografico completo, un rapporto PPT, un rapporto Excel in termini di tipo di località.',
  qualitative_research_1: 'qualitative',
  qualitative_research_2: 'Ricerche',
  qualitative_research_3:
    'Raccogliere, analizzare e interpretare i dati osservando le azioni e le parole dei tuoi clienti',
  qualitative_research_4: 'valori',
  qualitative_research_5: 'il comportamento',
  qualitative_research_6: 'sfondo',
  qualitative_research_7: 'Le aspettative',
  qualitative_research_8: 'L`ambiente',
  qualitative_research_9: 'Paure/Barriere',
  qualitative_research_10: 'Qual è lo scopo della scelta del cliente?',
  qualitative_research_11: 'uomo con il telefono',
  qualitative_research_12: 'marchio',
  qualitative_research_13: 'categoria',
  qualitative_research_14: 'pubblicità',
  qualitative_research_15: 'Prodotto',
  qualitative_research_16:
    'L`unicità dei metodi qualitativi è che ci consentono di comprendere le cause e le motivazioni del comportamento del consumatore',
  qualitative_research_17:
    'Analisi dell`atteggiamento del consumatore nei confronti di un fenomeno particolare ',
  qualitative_research_18: 'Prospettive ed esperienze individuali',
  qualitative_research_19:
    'Argomenti che non possono essere approfonditi attraverso i sondaggi',
  qualitative_research_20: 'Argomenti delicati',
  qualitative_research_21: 'Pubblico difficile da raggiungere',
  qualitative_research_22: 'Geografia complessa',
  qualitative_research_23: 'Interviste in profondità utili per:',
  qualitative_research_24: 'Interviste approfondite',
  qualitative_research_25:
    'Un’intervista approfondita è una tecnica di ricerca qualitativa che prevede lo svolgimento di interviste individuali per esplorare le loro prospettive su una particolare idea, programma o situazione. Un’intervista approfondita esplora in dettaglio le esperienze /percezioni/pratiche individuali.',
  qualitative_research_26: 'Obiettivi:',
  qualitative_research_27: 'Caratterizzazione delle norme sociali e culturali',
  qualitative_research_28: 'Pubblico difficile da raggiungere',
  qualitative_research_29:
    'Condivisione e confronto (Morgan) indagato attraverso sondaggi',
  qualitative_research_30:
    'Argomenti che non possono essere approfonditi attraverso i sondaggi',
  qualitative_research_31: 'Focus group di approfondimento utili per:',
  qualitative_research_32: 'Focus group',
  qualitative_research_33:
    'Esplorazione profonda di incontri individuali, discussioni emergenti, discussioni tra manifestazioni sotto l`influenza del moderatore del gruppo.',
  qualitative_research_34:
    'Generare approfondimenti su esperienze condivise e norme sociali attraverso discussioni di gruppo, dati narrativi in una discussione mirata.',
  qualitative_research_36: 'Benefits:',
  qualitative_research_37:
    'Studiare lo stile di vita e il comportamento del consumatore in diverse condizioni reali (a casa, nel punto vendita, al lavoro, ecc.)',
  qualitative_research_38:
    'Ricerca di approfondimenti per lo sviluppo di nuovi prodotti, packaging, miglioramento del servizio, ecc.',
  qualitative_research_39: 'In-depth ethnography research useful for:',
  qualitative_research_40: 'Ricerca etnografica',
  qualitative_research_41:
    'L`etnografia è un tipo di ricerca qualitativa che comporta il coinvolgimento in una particolare comunità o organizzazione per osservare da vicino il loro comportamento e le loro interazioni. ',
  qualitative_research_42:
    'L`etnografia è un metodo di ricerca flessibile che consente di acquisire una profonda comprensione della cultura, delle convenzioni e delle dinamiche sociali condivise di un gruppo. Tuttavia, comporta anche alcune sfide pratiche ed etiche.',
  qualitative_research_43: 'Metodi:',
  qualitative_research_44:
    'L`etnografia è uno studio del consumatore in condizioni di vita reale e della sua interazione con un prodotto/servizio.',
  qualitative_research_45: 'Vantaggi di lavorare con noi',
  qualitative_research_46:
    'Possiamo trovare intervistati da qualsiasi pubblico di destinazione. E controlliamo la qualità del reclutamento',
  qualitative_research_47: 'Reclutamento di qualsiasi tipo di complessità',
  qualitative_research_48:
    'I nostri moderatori sono multilingue e hanno capacità di agevolazioni',
  qualitative_research_49: 'Moderatori esperti',
  qualitative_research_50: 'Lavorare online/offline',
  qualitative_research_51: 'Forniamo registrazioni audio/video',
  qualitative_research_52: 'recordings',
  qualitative_research_53:
    'Siamo in grado di condurre ricerche qualitative in qualsiasi località',
  quantitative_research_1: 'Studio della soddisfazione del cliente',
  quantitative_research_2: 'con un particolare prodotto o servizio (SCI, NPS)',
  quantitative_research_3: 'Condurre la segmentazione',
  quantitative_research_4: 'sondaggi su diversi mercati',
  quantitative_research_5: 'Ricerca di mercato',
  quantitative_research_6: 'Gestione dell`NPS',
  quantitative_research_7: 'Analisi dei concorrenti',
  quantitative_research_8: 'Quantitative',
  quantitative_research_9: 'ricerche',
  quantitative_research_10:
    'Metodologia di ricerca che utilizza per testare le teorie sugli atteggiamenti e i comportamenti delle persone sulla base di dati oggettivi, numerici e statistici',
  quantitative_research_11: 'anni',
  quantitative_research_12: 'di esperienza CX in tutto il mondo',
  quantitative_research_13: 'pannello',
  quantitative_research_14: 'degli intervistati',
  quantitative_research_15: 'Paesi',
  quantitative_research_16: 'gestiamo progetti in tutto il mondo',
  quantitative_research_17: 'Chi siamo',
  quantitative_research_18:
    '4Service è una CX holding globale di ricerche di marketing. Offriamo servizi di raccolta dati esperti per una grande varietà di aziende',
  quantitative_research_19: '4service By Numbers',
  quantitative_research_20: 'Assistita da computer',
  quantitative_research_21: 'Intervista web',
  quantitative_research_22:
    'Una tecnica di questionario basata su Internet. Il rispondente completa un questionario elettronico senza intervistatore. Il questionario può essere inviato elettronicamente o pubblicato sul sito web.',
  quantitative_research_23: 'Computer Assisted Web Interviewing',
  quantitative_research_24:
    'Nella maggior parte dei casi questo metodo è il più conveniente in termini finanziari e costi di tempo.',
  quantitative_research_25:
    'this method is the most cost-effective in terms of material and time costs',
  quantitative_research_26: 'Questo sondaggio offre',
  quantitative_research_27:
    ' l`opportunità di intervistare un pubblico difficile da raggiungere',
  quantitative_research_28: ' Offre ampie possibilità',
  quantitative_research_29:
    ' di dimostrazione di materiale video e audio e immagini',
  quantitative_research_30: 'Gli intervistati possono ',
  quantitative_research_31: 'essere localizzati ovunque nel mondo.',
  quantitative_research_32:
    'Permette di indagare il comportamento specifico del pubblico di Internet.',
  quantitative_research_33:
    'Le I principali risultati sono accessibili dal cliente in tempo reale tramite un`interfaccia web.',
  quantitative_research_34:
    'Idoneo per qualsiasi argomento o problema confidenziale, ',
  quantitative_research_35:
    'sensibile e personale a cui gli intervistati potrebbero essere stati riluttanti a rispondere quando parlavano con un intervistatore',
  quantitative_research_36: '150 000+ pannello',
  quantitative_research_37:
    'Abbiamo il nostro pannello di Intervistati in tutto il mondo.',
  quantitative_research_38: 'Lavoriamo con richieste impegnative.',
  quantitative_research_39:
    'Utilizzando strumenti complementari (social media, pubblicità), possiamo trovare un pubblico specifico e a profilo sofisticato del rispondente.',
  quantitative_research_40: 'We motivate our respondents',
  quantitative_research_41:
    'Abbiamo progettato un framework per incoraggiare il completamento di lungo sondaggi fornendo materiale incentive.',
  quantitative_research_42: 'Le interviste faccia a faccia',
  quantitative_research_43:
    'Condurre conversazioni personali utilizzando un tablet.',
  quantitative_research_44:
    'Questo è uno dei principali metodi di raccolta di dati quantitativi, durante che l`intervistatore comunica con il rispondente direttamente a seguito di un questionario rigorosamente strutturato.',
  quantitative_research_45:
    'Le interviste faccia a faccia/face-to-face, possono essere condotte presso il luogo degli intervistati di lavoro o residenza, in strada o presso sedi di vendita designate utilizzando un tablet.',
  quantitative_research_46:
    'Interviste faccia a faccia condotte utilizzando il tablet',
  quantitative_research_47: 'Determinazione del livello ',
  quantitative_research_48: 'di popolarità e riconoscimento del marchio',
  quantitative_research_49: 'Segmentazione dei consumatori',
  quantitative_research_50: ' in base al loro comportamento di acquisto',
  quantitative_research_51:
    'Analisi dell`efficacia di una campagna pubblicitaria',
  quantitative_research_52: 'of the advertising campaign',
  quantitative_research_53: 'Misurazione della soddisfazione del cliente',
  quantitative_research_54: 'measurement',
  quantitative_research_55:
    'Determinazione del miglior prezzo per il prodotto o il servizio',
  quantitative_research_56: 'for the product or service',
  quantitative_research_57: 'Analisi delle preferenze dei consumatori',
  quantitative_research_58: 'of consumer preferences',
  quantitative_research_59: 'Studio ',
  quantitative_research_60: 'di come i consumatori acquistano',
  quantitative_research_61: 'Valutazione ',
  quantitative_research_62: 'della capacità di mercato',
  quantitative_research_63: 'Software DigSi',
  quantitative_research_64:
    'Usiamo un software speciale con registrazione audio e GPS',
  quantitative_research_65: 'Intervistatori e supervisori',
  quantitative_research_66:
    'Possiamo condurre interviste in qualsiasi lingua e tradurre nella lingua preferita del cliente.',
  quantitative_research_67: 'Campionamento',
  quantitative_research_68:
    'Il dipartimento analitico sviluppa una sistema per il reclutamento degli intervistati per ottenere i migliori risultati possibili.',
  quantitative_research_69: 'Assistita da computer',
  quantitative_research_70: 'Intervista telefonica',
  quantitative_research_71:
    'Metodologia utilizzata per la raccolta di informazioni quantitative attraverso interviste telefoniche utilizzando un questionario chiaramente strutturato.',
  quantitative_research_72: 'Intervista telefonica assistita da computer',
  quantitative_research_73: 'Abbiamo i nostri call center',
  quantitative_research_74:
    'Utilizziamo un software speciale con registrazione audio e GPS',
  quantitative_research_75: 'Operatori qualificati',
  quantitative_research_76:
    'I nostri operatori parlano lingue diverse, sono formati e ben collaudati, e migliorano continuamente le loro capacità(skills).',
  quantitative_research_77: 'Banche dati',
  quantitative_research_78:
    'Lavoriamo sia con il nostro databases che con la tua base di clienti',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Garantiamo la qualità e firmiamo uno SLA (Service Level Agreement)',
  quantitative_research_81: 'Definizione degli obiettivi',
  quantitative_research_82: 'Definizione della metodologia',
  quantitative_research_83: 'Segmentazione e campionamento',
  quantitative_research_84: 'Definizione dei canali di raccolta dei dati',
  quantitative_research_85:
    'Progettazione un questionario logico per il rispondente',
  quantitative_research_86: 'Processo di raccolta dei dati',
  quantitative_research_87: 'Garanzia di qualità',
  quantitative_research_88: 'Tecniche di analisi',
  quantitative_research_89: 'Elaborazione dati',
  quantitative_research_90: 'Progettazione del sondaggio',
  quantitative_research_91: 'Localizzazione GPS di intervistatore',
  quantitative_research_92: 'Verifica dei dati',
  quantitative_research_93: 'Programmazione e test del questionario',
  quantitative_research_94: 'Convalida dei dati',
  quantitative_research_95: 'Registrazioni audio mentre segui Requisiti GDPR',
  quantitative_research_96: 'Formazione e competenza di personale di ricerca',
  quantitative_research_97: 'Garanzia di qualità',
  quantitative_research_98: 'Analisi fattoriale',
  quantitative_research_99: 'Segmentazione',
  quantitative_research_100: 'Mappatura percettiva',
  quantitative_research_101: 'Correlazione',
  quantitative_research_102: 'Modello di conversione',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Analisi di gruppo',
  quantitative_research_105: 'etc.',
  quantitative_research_106: 'Relazioni analitiche',
  quantitative_research_107:
    'Utilizziamo SPSS ® e altri strumenti per costruire report analitici che utilizzano diverse metodologie',
  quantitative_research_108: 'What business challenges сan be solved:',
  quantitative_research_109: 'What business challenges сan be solved:',
  social_responsibility_1: '4Service Responsabilità sociale d`impresa.',
  social_responsibility_2:
    'Con il cuore e l`anima aperti di ogni membro del nostro team. cCerchiamo di mantenere una scintilla di gentilezza in ciascuno dei nostri dipendenti e di rendere il mondo un po` migliore.',
  social_responsibility_3: 'Attività educative/Sostegno ai giovani',
  social_responsibility_4:
    'Le nostre iniziative di sensibilizzazione per bambini e studenti',
  social_responsibility_5: 'Gioco d`affari Service Mania',
  social_responsibility_6:
    'Una parte importante delle nostre attività di formazione è il business game Service Mania.',
  social_responsibility_7:
    'Service Mania ti sfida a pensare strategicamente a come affrontare situazioni insolite.',
  social_responsibility_8:
    'Presentazione della ricerca / Diiya. Attività commerciale(Business)',
  social_responsibility_9:
    'Una prospettiva aziendale e del cliente sulla situazione del servizio: una presentazione dei risultati della  4Servizio i risultati della ricerca da parte di  Victoria Skorbota, responsabile dello sviluppo  di 4Service in Ucraina.',
  social_responsibility_10: 'Sessioni di Service Design',
  social_responsibility_11:
    'La metodologia del Service Design è una parte piu importante di 4Service. Un paio di anni fa siamo diventati partner della DesignThinkers Academy. Non solo applichiamo le migliori pratiche nel nostro lavoro, ma forniamo anche corsi di formazione con migliori aziende',
  social_responsibility_12: 'Beneficenza / Volontariato',
  social_responsibility_13:
    'Orfanotrofi, case di cura per anziani e volontariato per l`esercito ucraino. La parte più toccante delle nostre attività',
  social_responsibility_14: 'Gruppo #HelpEasyWith4Service',
  social_responsibility_15:
    'Lavoriamo in collaborazione con il  Fondo di beneficenza Blagomai e cerchiamo di portare  festa e aiuto a coloro che hanno   bisogno',
  social_responsibility_16: 'Raccolta fondi per orfanotrofi e case di riposo.',
  social_responsibility_17:
    'Abbiamo avviato diverse raccolte fondi per case di riposo e orfanotrofi per l`acquisto di beni di prima necessità.',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'La guerra ha colpito molti membri del nostro staff e amici intimi. Ognuno di noi aiuta come può.',
  social_responsibility_20: 'Benessere degli animali.',
  social_responsibility_21:
    'Chiunque può venire nel nostro ufficio con il proprio animale domestico. Aiutiamo rifugi, raccogliamo fondi per il cibo e talvolta cerchiamo anche case per animali senzatetto.',
  social_responsibility_22: 'Sostenere i rifugi per animali.',
  social_responsibility_23:
    'Gli animali senza casa hanno bisogno di aiuto  come tutti i membri vulnerabili della nostra  società.',
  social_responsibility_24:
    'La nostra azienda ha preso in cura il Sirius Stray Animal Shelter (regione di Kiev, Ucraina)',
  social_responsibility_25:
    'Iniziative creative per aiutare gli animali randagi.',
  social_responsibility_26:
    'Come possiamo portare l`urgente questione dei rifugi per animali all`attenzione dei nostri clienti e partner?',
  social_responsibility_27:
    'Ufficio adatto agli animali domestici/Pet -friendly office',
  social_responsibility_28:
    'Molti membri del nostro team hanno animali domestici. Abbiamo creato un ambiente sano in cui tutti possono venire in ufficio con i propri animali domestici e sentirsi a proprio agio.',
  ux_testing_1: 'Test UX',
  ux_testing_2:
    'Approccio umano e basato sull`intelligenza artificiale per testare e migliorare i vostri siti web e le vostre applicazioni',
  ux_testing_3: 'Cos`è il test UX/Cos`è il test dell`esperienza utente',
  ux_testing_4:
    'Il test UX ti aiuta a capire come le persone interagiscono con il tuo prodotto, app o sito web',
  ux_testing_5: 'La navigazione non è molto chiara',
  ux_testing_6: 'Non ho idea di come fare un ordine',
  ux_testing_7: 'Non ci sono abbastanza foto nel catalogo',
  ux_testing_8: 'Lo farai:',
  ux_testing_9: 'Scopri le opportunità per migliorare',
  ux_testing_10:
    'Identificare i problemi nella progettazione e nell`interazione con il cliente',
  ux_testing_11:
    'Scopri quali difficoltà incontra l`utente durante l`interazione con il sito',
  ux_testing_12: 'Osserva il percorso del cliente',
  ux_testing_13:
    'Riconosci quando e perché il tuo pubblico di destinazione lascia il sito',
  ux_testing_14:
    'Definisci quali sezioni suscitano il minimo e il massimo interesse ed emozione',
  ux_testing_15: 'Test UX basato sull`utente',
  ux_testing_16:
    '4Service ti fornisce le vere intuizioni umane di cui hai bisogno per creare siti Web e app incentrati sul cliente utilizzando metodi qualitativi e quantitativi',
  ux_testing_17: 'Non capisco come usare il sito. Dove è nascosto il menu?',
  ux_testing_18:
    'Penso che ci sia troppo spazio tra le lettere, il testo è illeggibile',
  ux_testing_19: 'Le immagini si sovrappongono. È così che dovrebbe essere?',
  ux_testing_20:
    'Il test UX ti aiuta a capire come le persone interagiscono con il tuo prodotto, app o sito web',
  ux_testing_21: 'Progettazione della ricerca di test UX basata sull`utente:',
  ux_testing_22: 'Scopri le opportunità per migliorare',
  ux_testing_23:
    'Determinazione del tipo e del metodo di controllo (qualitativo/quantitativo)',
  ux_testing_24: 'Elaborazione di ipotesi e scenari utente',
  ux_testing_25: 'Analisi e refertazione dei risultati dei test',
  ux_testing_26: 'Due soluzioni',
  ux_testing_27: 'Offriamo due approcci per testare UX con successo',
  ux_testing_28: 'Test UX basato sull`utente',
  ux_testing_29: 'AI + test UX basati su persone',
  ux_testing_30: 'I nostri punti di forza:',
  ux_testing_31: 'Possiamo analizzare il panorama della concorrenza',
  ux_testing_32:
    'Possiamo coinvolgere esperti ristretti per condurre e partecipare alle interviste',
  ux_testing_33:
    'Possiamo condurre interviste in diverse lingue e tradurle nella lingua richiesta',
  ux_testing_34: 'con l`usabilità esistente',
  ux_testing_35: 'metriche o standard',
  ux_testing_36: 'la tua performance',
  ux_testing_37: 'contro un concorrente',
  ux_testing_38: 'Versione',
  ux_testing_39: 'lo stesso prodotto',
  ux_testing_40: 'Grado di soddisfazione',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Tassi di successo',
  ux_testing_43: 'Tasso di errore',
  ux_testing_44: 'Tempo di completamento dell`attività',
  ux_testing_45: 'Test UX basato sull`utente',
  ux_testing_46: 'metodo quantitativo',
  ux_testing_47:
    'Utilizziamo almeno 100 persone per partecipare ai test UX per questa fase',
  ux_testing_48:
    'Rilevante quando hai bisogno di un confronto del tuo sito web o della tua app:',
  ux_testing_49: 'Misura metodo quantitativo:',
  ux_testing_50: 'Osservazione',
  ux_testing_51:
    'Trascorrere del tempo con un utente o un gruppo di utenti e osservare il loro comportamento con il prodotto mentre lo usano nella vita quotidiana',
  ux_testing_52: 'intervista approfondita',
  ux_testing_53:
    'Consentono di apprendere gli atteggiamenti, le convinzioni, i desideri e le esperienze degli utenti che visitano il tuo sito. Si consiglia di condurre 15-20 interviste',
  ux_testing_54: 'Ricerca etnografica',
  ux_testing_55:
    'Condotto nell`ambiente degli intervistati in cui utilizzeranno il prodotto. Osservando, inizi ad avere una buona comprensione della psicologia dell`utente, delle sfide emotive che deve affrontare.',
  ux_testing_56: 'Test UX basato sull`utente',
  ux_testing_57: 'Metodo qualitativo',
  ux_testing_58:
    'Questo metodo aiuta a comprendere le motivazioni e la logica del comportamento degli utenti',
  ux_testing_59: 'Noi usiamo:',
  ux_testing_60:
    'Stiamo sviluppando una mappa del percorso del cliente per illustrare i risultati della nostra ricerca',
  ux_testing_61: '30 partecipanti (maschi/femmine)',
  ux_testing_62:
    'Gli intervistati effettuano un pre-screening e li invitano a partecipare al sondaggio',
  ux_testing_63: 'Colloqui online a distanza sulla piattaforma Wantant',
  ux_testing_64: 'AI + basato sull`essere umano',
  ux_testing_65: 'AI + test UX basati su persone',
  ux_testing_66:
    'Una combinazione di tecnologia unica di intelligenza artificiale Wantant e utenti reali',
  ux_testing_67:
    'Il carattere di grandi dimensioni nel titolo ha attirato l`attenzione',
  ux_testing_68:
    'Gli utenti guardano i numeri «ad alta voce» ma non leggono le informazioni accanto «г»',
  ux_testing_69:
    'Il test UX ti aiuta a capire come le persone interagiscono con il tuo prodotto, app o sito web',
  ux_testing_70: 'AI + progettazione della ricerca sui test basati sull`uomo',
  ux_testing_71: 'Come funziona',
  ux_testing_72:
    'Wantent è una soluzione adatta al mercato dei contenuti basata sull`intelligenza artificiale.',
  ux_testing_73:
    'Wantant stima l`efficienza di siti Web e app applicando tecnologie ML per analizzare le reazioni emotive e il coinvolgimento del pubblico.',
  ux_testing_74:
    'Per interpretare i risultati, utilizziamo tecniche di storytelling, diversità ed empatia, per guidare le prestazioni e la crescita.',
  ux_testing_75:
    'L`attenzione e le emozioni dell`utente durante le transizioni attraverso le sezioni',
  ux_testing_76:
    'Statistiche per gruppo di partecipanti (attenzione e concentrazione durante la sessione)',
  ux_testing_77: 'Analisi delle conversioni di pagina',
  ux_testing_78: 'Attenzione ed emozioni durante l`esecuzione dei compiti',
  ux_testing_79:
    'Risultati sulla percezione del sito Web da parte di diversi gruppi di partecipanti',
  ux_testing_80: 'of the website by different groups of participants',
  ux_testing_81:
    'Analisi della facilità di navigazione tra le pagine e della facilità di reperimento di elementi importanti',
  ux_testing_82:
    'of navigating through pages and the ease of finding important elements',
  ux_testing_83:
    'Un`analisi della percezione delle informazioni fornite sul sito web',
  ux_testing_84: 'of the information provided on the website',
  ux_testing_85:
    'Definizione aree di miglioramento all`interno delle singole pagine (navigazione, blocchi informativi, ecc.)',
  ux_testing_86:
    'of improvement within individual pages (navigation, information blocks, etc.)',
  ux_testing_87: 'Analizziamo nel dettaglio',
  ux_testing_88: 'Risultati chiave della ricerca',
  ux_testing_89:
    'Raccomandazioni per migliorare la struttura del sito web e delle singole pagine',
  ux_testing_90:
    'Selezioniamo attentamente il pubblico di destinazione per i test di usabilità.',
  ux_testing_91:
    'Conduciamo ricerche in diversi paesi, diverse lingue e tra diversi gruppi di utenti',
  ux_testing_92: 'Possiamo eseguire test nelle applicazioni',
  ux_testing_93:
    ' e sui siti Web della concorrenza e fornire analisi comparative',
  ux_testing_94: 'Combiniamo diversi metodi di ricerca.',
  ux_testing_95:
    'I nostri facilitatori qualificati guidano il partecipante attraverso il processo di test.',
  ux_testing_96:
    'Conduciamo test su una varietà di dispositivi e versioni software',
  ux_testing_97: 'on a variety of devices and software versions',
  ux_testing_98: 'Perché scegliere noi?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Unico progetto dei partner 4Setice e Wantent © Un`unica combinazione di intelligenza    artificiale e applicazione umana per l`analisi dei contenuti.',
  wantent_4: 'Misurare il successo prima che il contenuto venga trasmesso',
  wantent_5: 'Mitigare i rischi dei problemi di diversità',
  wantent_6: 'Approfondimenti e raccomandazioni per migliorare il contenuto',
  wantent_7: 'Decisioni oggettive sui contenuti e sulla strategia di marketing',
  wantent_8:
    'Wantent è una soluzione di content-market fit basata sull`intelligenza artificiale.',
  wantent_9:
    'Wantent è una soluzione basata sull`intelligenza artificiale per abbinare i contenuti al mercato. Wantent valuta l`efficacia dei contenuti video applicando tecnologie di ML per analizzare la risposta emotiva e il coinvolgimento del pubblico.',
  wantent_10:
    'Wantent aiuta a definire l`adattamento al mercato dei contenuti e a massimizzare l`efficienza dei contenuti mediante l`impostazione dello scenario e il reclutamento dei partecipanti',
  wantent_11: 'Scenario setup & participant recruitment',
  wantent_12: 'design personalizzato del progetto e pubblico in tutto il mondo',
  wantent_13: 'Raccogliere le reazioni degli spettatori',
  wantent_14: 'feedback dettagliato e chiaro da parte del pubblico',
  wantent_15: 'Analisi della percezione del contenuto',
  wantent_16:
    'analisi del comportamento e della risposta emotiva da parte dell`intelligenza artificiale',
  wantent_17: 'Fornitura di approfondimenti per il riepilogo finale',
  wantent_18: 'raccomandazioni per miglioramenti',
  wantent_19: 'Wantent massimizza l`efficienza dei contenuti',
  wantent_20: 'Pre-test del concetto di annuncio',
  wantent_21: 'Test del concetto creativo',
  wantent_22:
    'Verifica della coerenza della creatività futura con la strategia sviluppata e comprensione delle reazioni del pubblico target per eventuali aggiustamenti dei concetti/messaggi creativi.',
  wantent_23: 'Test di viewability dei brandelements (post-produzione)',
  wantent_24:
    'Conferma dell`efficacia dell`investimento pubblicitario/sponsorizzante',
  wantent_25: 'Test degli annunci',
  wantent_26: 'Test A/B',
  wantent_27:
    'Confrontare l`efficacia dei creativi e aumentare l`impegno e l`attenzione durante la fase di produzione',
  wantent_28: 'Avvio del test degli annunci',
  wantent_29:
    'Definizione delle prestazioni dei messaggi e creazione di raccomandazioni per migliorare l`audio e le immagini ',
  wantent_30: 'Soluzioni per la TV',
  wantent_31: 'Piloti, promo, nuovi format, test di presentatori televisivi',
  wantent_32:
    'Conduzione di un`analisi comparativa per individuare quello più coinvolgente e rafforzarlo',
  wantent_33: 'Test su programmi televisivi (TV show) e lungometraggi',
  wantent_34:
    'Analizzare il coinvolgimento, il livello di attenzione e la risposta emotiva degli spettatori durante la visione di contenuti lunghi in condizioni naturali. Individuiamo automaticamente i picchi di ascolto e i momenti di distrazione per valutare e migliorare il montaggio del video.',
  wantent_35: 'Test UI/UX',
  wantent_36: ' Prototipi di siti web e test di applicazioni mobili.',
  wantent_37:
    'Analisi della reazione e delle emozioni degli utenti utilizzando la tecnologia Wantant:',
  wantent_38: 'Livello di attenzione',
  wantent_39: 'Reazioni emotive',
  wantent_40: 'Heatmap della direzione dello sguardo dei partecipanti',
  wantent_41:
    'Analisi dell`usabilità di siti web e applicazioni sulla base del feedback   dei partecipanti:',
  wantent_42: 'Scala di usabilità del sistema (SUS)',
  wantent_43:
    ' Punteggio dello sforzo del cliente (facilità di    interazione)',
  wantent_44: 'Net Promotion Score (NPS)',
  wantent_45: 'Conformità al GDPR e privacy degli utenti',
  wantent_46:
    'Wantent è pienamente conforme alle politiche GDPR e CCPA e soddisfa i principi di raccolta, archiviazione, elaborazione e protezione dei dati personali dei partecipanti',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (Media e Intrattenimento)',
  wantent_49:
    'Ogni giorno sperimentiamo tonnellate di nuove idee di contenuto con tecnologie di apprendimento automatico. Wantent ci aiuta a comprendere e valutare rischi e vantaggi, scoprire il feedback, il comportamento e la percezione degli utenti per ottenere la causalità dei diversi aspetti delle nostre soluzioni.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'comproprietario TABASCO (Pubblicità)',
  wantent_52:
    'Wantent offre un servizio davvero unico che ci aiuta a conoscere dettagli molto piccoli su ciò che piace e non piace ai consumatori. Non c`è modo di imbrogliare, non c`è modo di mentire - Wantent conosce sempre la verità, e con la presente noi esperti di marketing otteniamo le linee guida inestimabili (hahaha - conosciamo il prezzo esatto ed è conveniente) su come migliorare le nostre comunicazioni.',
  wantent_53: 'Reviews',
  terms_1: 'Terms & conditions',
  terms_2: 'Accordo sui termini di utilizzo',
  terms_3:
    'Si prega di leggere attentamente le presenti condizioni d`uso («condizioni d`uso», «accordo») prima di utilizzare il sito web',
  terms_4:
    '(«sito web») gestito da 4Service Holdings GmbH («4Service», «noi»,«noi», «nostro»). ',
  terms_5:
    'Condizioni d`uso Utilizzando questo sito Web, l`utente certifica di aver letto e rivisto il presente Accordo e di accettare di rispettarne i termini. Se non si desidera essere vincolati dai termini del presente Accordo, si consiglia di lasciare il sito Web di conseguenza. 4Service concede l`uso e l`accesso a questo sito Web, ai suoi prodotti e ai suoi servizi solo a coloro che ne hanno accettato i termini.',
  terms_6: 'Politica sulla privacy',
  terms_7:
    'Prima di continuare a utilizzare il nostro sito Web, ti consigliamo di leggere la nostra Informativa sulla privacy relativa alla raccolta dei dati dell`utente. Ti aiuterà a comprendere meglio le nostre pratiche.',
  terms_8: 'Limite di età',
  terms_9:
    'Devi avere almeno 16 (sedici) anni prima di poter utilizzare questo sito web. Utilizzando questo sito Web, garantisci di avere almeno 16 (sedici) anni e di poter aderire legalmente al presente Accordo. 4Service non si assume alcuna responsabilità per responsabilità relative alla falsa dichiarazione di età.',
  terms_10: 'La proprietà intellettuale',
  terms_11:
    'Accetti che tutti i materiali, i prodotti e i servizi forniti su questo sito Web siano di proprietà di 4Service, dei suoi affiliati, direttori, funzionari, dipendenti, agenti, fornitori o licenziatari, inclusi tutti i diritti d`autore, i segreti commerciali, i marchi, i brevetti e altre proprietà intellettuali.  Accetti inoltre di non riprodurre o ridistribuire la proprietà intellettuale di 4Service in alcun modo, incluse registrazioni elettroniche, digitali o di nuovi marchi.',
  terms_12: 'Accesso al nostro sito web',
  terms_13:
    'L`accesso al nostro sito è consentito su base temporanea e ci riserviamo il diritto di ritirare o modificare il servizio che forniamo sul nostro sito senza preavviso. Non saremo responsabili se per qualsiasi motivo il nostro sito non è disponibile in qualsiasi momento o per qualsiasi periodo. Di volta in volta, potremmo limitare l`accesso ad alcune parti del nostro sito, o al nostro intero sito, agli utenti che si sono registrati con noi. Sei responsabile di prendere tutte le disposizioni necessarie per avere accesso al nostro sito, incluso l`uso di apparecchiature compatibili con il nostro sito. Sei inoltre responsabile di garantire che tutte le persone che accedono al nostro sito tramite la tua connessione Internet siano a conoscenza di questi termini e che li rispettino.',
  terms_14: 'Legge applicabile',
  terms_15:
    'Visitando questo sito Web, accetti che le leggi della Repubblica d`Austria, senza riguardo ai principi delle leggi sui conflitti, regoleranno questi termini e condizioni, o qualsiasi controversia di qualsiasi tipo che potrebbe sorgere tra 4Service e te.',
  terms_16: 'Controversie',
  terms_17:
    'Qualsiasi controversia relativa in qualsiasi modo alla tua visita a questo sito Web sarà arbitrata dal tribunale commerciale di Vienna.',
  terms_18: 'Indennizzo',
  terms_19:
    'Accetti di indennizzare 4Service e le sue affiliate e di tenere 4Service indenne da rivendicazioni e richieste legali che potrebbero derivare dal tuo uso o uso improprio del nostro sito web. Ci riserviamo il diritto di selezionare il nostro consulente legale.',
  terms_20: 'Il periodo di conservazione',
  terms_21:
    'Tenuto conto delle finalità del trattamento, il periodo di conservazione dei dati personali degli Utenti (periodo di conservazione) è di 24 mesi dalla data in cui è stato regolarmente ottenuto il consenso al trattamento dei dati da parte dell`Utente.',
  terms_22: 'Limitazione di responsabilità',
  terms_23:
    '4Service non è responsabile per eventuali danni che potrebbero verificarsi a seguito dell`uso improprio del nostro sito Web. 4Service si riserva il diritto, senza preavviso, di modificare, modificare e cambiare il presente Accordo in qualsiasi momento aggiornando questa pubblicazione. L`uso continuato del sito Web ora, o dopo la pubblicazione di eventuali cambiamenti o modifiche, indicherà l`accettazione da parte dell`utente di tali cambiamenti o modifiche. Se qualsiasi parte di questo accordo è dichiarata illegale, nulla o inapplicabile, quella parte sarà considerata separabile e non pregiudicherà la validità e l`applicabilità delle restanti disposizioni. Eventuali prove dell`uso del sito Web per scopi illegali saranno fornite alle autorità preposte all`applicazione della legge. Il presente Accordo costituisce un`intesa tra 4Service e l`utente.',
  terms_24:
    'Si prega di indirizzare tutte le domande e i dubbi relativi alla privacy/utilizzo al seguente indirizzo:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Vienna',
  terms_28: 'Austria',
  terms_29: 'Recensione',
  terms_30:
    'Quando i dati personali che ci fornisci non sono inesatti, hai il diritto di chiederci di correggerli (articolo 16 del GDPR).',
  thank_you_1: 'Grazie!',
  thank_you_2: 'Ti ricontatteremo entro 24 ore',
  thank_you_3:
    'I nostri responsabili stanno già esaminando la tua richiesta per mettere insieme un`offerta migliore',
  thank_you_4: 'Menu',
  thank_you_5: 'Woman typing',
  privacy_policy_1:
    'Modulo di informativa privacy per gli interessati (utenti del sito)',
  privacy_policy_2:
    'Il presente Modulo di informativa sulla privacy (Informativa sulla privacy) è destinato alle persone fisiche (Interessati) i cui dati personali sono raccolti da 4Service Holdings GmbH, in linea con i requisiti del GDPR in occasione della visita del sito web',
  privacy_policy_3:
    '(di seguito, rispettivamente, “Sito” e “Utenti/Utenti del Sito”).',
  privacy_policy_4:
    'Il modulo di informativa sulla privacy per gli interessati (utenti del sito Web) di 4Service Holdings GmbH è applicato in tutte le società del gruppo 4Service, che sono sotto l`autorità legale sotto la supervisione o il controllo di 4Service Holdings GmbH.',
  privacy_policy_5: 'Contatti',
  privacy_policy_6: '4Service Holding GmbH',
  privacy_policy_7: 'azienda/noi/noi),',
  privacy_policy_8: 'Indirizzo',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_10: 'E- mail :',
  privacy_policy_12: 'Chi siamo',
  privacy_policy_13:
    'Un individuo (Lei) che visita il sito Web per qualsiasi scopo. Ai fini del presente Documento Lei sarà indicato anche come Utente.',
  privacy_policy_14: 'Dati personali che raccogliamo da te',
  privacy_policy_15:
    'Quando accedi al nostro sito Web, ti inviamo un`informativa sulla privacy, in cui ti offriamo di chiederti di fornirci un consenso per il trattamento delle seguenti informazioni:',
  privacy_policy_16:
    'Indirizzo IP, nome utente, cognome, indirizzo, numero di telefono (fisso o mobile), indirizzo e-mail, indirizzo, ragione sociale, nazione, indirizzo e-mail, nome, cognome, numero di telefono, provincia, stato e CAP , dati di utilizzo, dati di interazione con social network o piattaforme esterne, informazioni sulla registrazione e autenticazione sul sito',
  privacy_policy_17: 'la posizione geografica',
  privacy_policy_18:
    'Nel caso in cui l`utente esprima la propria volontà e ci fornisca tale consenso, inizieremo a trattare tali informazioni da parte dell`utente.',
  privacy_policy_19: 'Liceità (consenso)',
  privacy_policy_20:
    'Quando accedi al nostro sito Web, ti inviamo un`informativa sulla privacy, in cui ti chiediamo di fornirci un consenso per il trattamento delle seguenti informazioni:',
  privacy_policy_21:
    'La base giuridica per il trattamento dei dati personali è il consenso, che otteniamo da Lei attraverso la compilazione del modulo di consenso al seguente link:',
  privacy_policy_22:
    'Se compili il modulo di consenso, significa che comprendi e accetti tutte le condizioni specificate nella presente Informativa sulla privacy.',
  privacy_policy_23: 'Revoca del consenso',
  privacy_policy_24:
    'L`utente ha il diritto di revocare in qualsiasi momento il consenso che ci ha fornito in precedenza. La revoca del consenso non pregiudica la liceità del trattamento basato sul consenso prima della sua revoca. Potete revocare il vostro consenso inviandoci l`apposita richiesta al seguente indirizzo e-mail withdraw@4service-group.com, il cui modulo è disponibile al seguente link:',
  privacy_policy_25: 'Modulo di recesso per l`utente',
  privacy_policy_26: 'Finalità del trattamento',
  privacy_policy_27: 'Elaboriamo i tuoi dati personali per i seguenti scopi:',
  privacy_policy_28:
    '– migliorare i servizi ai clienti (Permette una risposta più efficace alle richieste dei clienti); – personalizzazione dell`esperienza degli Utenti. Permette di determinare chi è più interessante nei servizi); – migliorare il Sito. (Permette di migliorare la qualità dei prodotti e dei servizi, la comodità del loro utilizzo, sviluppare nuovi Servizi migliorare i nostri prodotti e servizi); – comunicare con l`Utente con newsletter, materiale di marketing o promozionale e altre informazioni che includono le nostre notizie, aggiornamenti, informazioni sui servizi con il commento sull`istruzione su come rifiutare di ricevere e-mail di follow-up. – effettuare ricerche e analisi statistiche e di altro genere sulla base di dati anonimizzati. – fornire servizi personalizzati all`Utente ed eseguire accordi e contratti; – partecipare dell`Utente a vari progetti da noi implementati attraverso il Sito, rispondendo alle domande rivolte dall`Utente attraverso il Sito, ricerca, mantenimento di conti e registri e promozione di servizi.',
  privacy_policy_29:
    'Soggetti a cui possono essere comunicati i dati personali',
  privacy_policy_30:
    'Durante l`elaborazione dei tuoi dati personali, trasferiamo i tuoi dati personali alle entità che agiscono come responsabili del trattamento della Società. I responsabili del trattamento della Società agiscono esclusivamente sulla base delle istruzioni della Società. Le specifiche delle azioni condotte dai responsabili del trattamento della Società e l`elenco di tali responsabili sono disponibili nella Politica sulla privacy e sulla protezione dei dati al seguente collegamento: Politica sulla privacy e sulla protezione dei dati',
  privacy_policy_31:
    'Paesi verso i quali i dati personali possono essere trasferiti',
  privacy_policy_32:
    'La Società trasferisce i tuoi dati personali sulla base di una decisione di adeguatezza come previsto dal GDPR e dalla Commissione Europea. Ulteriori informazioni sul trasferimento dei dati personali negli Stati Uniti sono disponibili nella Privacy and Data Protection Policy al seguente link: Privacy and Data Protection Policy.',
  privacy_policy_33: 'Il periodo di conservazione',
  privacy_policy_34:
    'Tenuto conto delle finalità del trattamento, il periodo di conservazione dei dati personali degli Utenti (periodo di conservazione) è di 24 mesi dalla data in cui è stato regolarmente ottenuto il consenso al trattamento dei dati da parte dell`Utente.',
  privacy_policy_35: 'Il diritto di accesso',
  privacy_policy_36:
    'Hai il diritto di sapere se i dati personali che ti riguardano sono in fase di elaborazione e 2) in tal caso, accedere a tali dati con un sacco di disposizioni aggiuntive stabilite nell`articolo 15 del GDPR.',
  privacy_policy_37: 'Diritto alla rettifica',
  privacy_policy_38:
    'Se i dati personali forniti dall`utente non sono inesatti, l`utente ha il diritto di chiederne la correzione (articolo 16 del GDPR).',
  privacy_policy_39: 'Diritto alla cancellazione (diritto all`oblio)',
  privacy_policy_40:
    'Avete il diritto di ottenere da noi la cancellazione dei vostri dati personali senza ritardi ingiustificati e noi avremo l`obbligo di cancellare i vostri dati personali senza ritardi ingiustificati se si applicano i motivi di cui all`articolo 17 del GDPR.',
  privacy_policy_41: 'Diritto alla limitazione del trattamento',
  privacy_policy_42:
    'Avete il diritto di limitare il trattamento dei vostri dati personali con diverse eccezioni nell`ambito del GDPR, in particolare ai sensi dell`articolo 18 del GDPR.',
  privacy_policy_43:
    'Siamo obbligati a informarti su quali dati vengono raccolti, come vengono utilizzati, per quanto tempo saranno conservati e se saranno condivisi con terze parti. Tali informazioni devono essere comunicate in modo conciso e con un linguaggio semplice.',
  privacy_policy_44: 'Diritto alla portabilità dei dati',
  privacy_policy_45:
    'L`utente ha il diritto di ottenere e riutilizzare i propri dati personali per i propri scopi in diversi servizi.',
  privacy_policy_46: 'Diritto di opposizione',
  privacy_policy_47:
    'Avete il diritto di opporvi al trattamento dei dati personali che vengono elaborati dalla Società. Dobbiamo interrompere l`elaborazione dei dati personali a meno che non dimostriamo motivi legittimi impellenti per l`elaborazione che prevalgono sugli interessi, i diritti e le libertà dell`individuo o se l`elaborazione è per l`istituzione o l`esercizio della difesa di rivendicazioni legali.',
  privacy_policy_48:
    'Diritto di non essere sottoposto a una decisione basata esclusivamente sul trattamento automatizzato',
  privacy_policy_49:
    'Avete il diritto di opporti a qualsiasi profilazione automatizzata che si verifica senza consenso. Con la presente, l`utente ha il diritto che i propri dati personali siano trattati con il coinvolgimento umano.',
  privacy_policy_50: 'Denunce, contestazioni',
  privacy_policy_51:
    'Nel caso in cui desideriate presentare un reclamo in merito al trattamento dei vostri dati personali da parte della Società (o dei responsabili del trattamento, descritti in precedenza), o al modo in cui il vostro reclamo è stato gestito, avete il diritto di presentare un reclamo direttamente all`autorità di controllo e alla Società.',
  privacy_policy_52: 'I dettagli di ognuno di questi contatti sono:',
  privacy_policy_53: 'Autorità di vigilanza:',
  privacy_policy_54:
    'L`Autorità austriaca per la protezione dei dati personali',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Vienna',
  privacy_policy_58: 'Austria / Europe',
  privacy_policy_59: 'Azienda:',
  privacy_policy_60: '4Service Holdings GmbH (Società/noi/noi),',
  privacy_policy_61: 'Indirizzo: Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Politica sulla privacy e protezione dei dati',
  privacy_policy_64:
    'Maggiori informazioni su come e perché utilizziamo i tuoi dati qui: Informativa sulla privacy e sulla protezione dei dati',
  privacy_policy_65: 'Approvazione',
  privacy_policy_66:
    'La Società ha sviluppato tutti i documenti interni per definire i ruoli tra il personale in merito al trattamento dei dati personali all`interno della Società, in particolare responsabile dell`approvazione e del riesame della legittimità di questo documento è l`Amministratore Delegato.',
  contacts_1: 'Per i clienti',
  contacts_2:
    'Per tutti i tipi di aziende, agenzie di ricerche di mercato e grandi aziende, partner commerciali e media.',
  contacts_3: 'Tegetthoffstrasse 7, Vienna, Austria',
  contacts_4: 'Per gli acquirenti',
  contacts_5:
    'Gente del posto e viaggiatori, autisti e studenti, mystery shopper professionisti e homebodies.',
  contacts_6: 'Contattaci dal tuo paese:',
  contacts_7: 'Italia',
  contacts_8: 'Stati Uniti d`America',
  contacts_9: 'UK',
  contacts_10: 'Svizzera',
  contacts_11: 'Paesi Bassi/Olanda',
  contacts_12: 'Slovacchia',
  contacts_13: 'Slovenia',
  contacts_14: 'Romania',
  contacts_15: 'AZ',
  contacts_16: 'Ucraina',
  contacts_17: 'Kazakistan',
  contacts_18: 'Resto del mondo',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'Test dell`esperienza utente',
  menu_6: 'Ricerca sulle risorse umane',
  menu_7: 'Ricerca qualitativa',
  menu_8: 'Ricerca quantitativa',
  menu_9: 'Mystery shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Monitoraggio dei prezzi',
  menu_14: 'Chi siamo',
  menu_15: 'Contatti',
  menu_16: 'Blog',
  menu_17: 'Metodi',
  menu_18: 'Prodotti',
  menu_19: 'Piattaforma per gli acquirenti',
  menu_20: 'CSR',
  menu_21: 'Analis del Prezzo nel mondo Automotive',
  nps_passives: 'Passivi',
  nps_detractors: 'Detrattori',
  nps_promoters: 'Promotori',
  nps_a_score: 'un punteggio',
  area: 'Area',
  products_tabs_1:
    'Al fine di ottenere la massima efficienza, eseguiamo anche in complesso:',
  products_tabs_2: 'Prodotti',
  products_tabs_3:
    'La ricerca qualitativa raccoglie e analizza dati non numerici per comprendere concetti, opinioni o esperienze',
  products_tabs_4:
    'I metodi di ricerca quantitativi sono una misurazione oggettiva e un`analisi numerica dei dati raccolti attraverso sondaggi, questionari e sondaggi',
  our_clients_1: 'I nostri clienti',
  preview_cases_section_1: 'Casi',
  preview_cases_section_2: 'Vai ai casi per leggere post più interessanti',
  blog_1: 'Blog con contenuti interessanti',
  blog_2: 'Nessun post disponibile per la tua richiesta.',
  about: 'Circa',
  why_us: 'Perche noi',
  about_us: 'Chi siamo',
  key_features: 'Funzionalità chiave',
  solutions: 'Soluzione ',
  monitoring: 'Monitoraggio ',
  how: 'Come ',
  how_it_works: 'Come funziona',
  when_to_use: 'Quando usare',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Voce del cliente',
  wantent: 'Testare i contenuti video',
  play4sales: 'Play4Sales',
  price_monitoring: 'Monitoraggio dei prezzi',
  service_design: 'Progettazione del servizio',
  qualitative_research: 'Ricerca qualitativa',
  quantitative_research: 'Ricerca quantitativa',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Settore automobilistico',
  hashtag_retayl: 'Vendita al dettaglio,',
  hashtag_all_posts: '#Tutti_i post',
  hashtag_feedback: '#feedback',
  hashtag_4Service_csr: '#4Servizio_CSR',
  hashtag_cases: '#Casi',
  hashtag_market_research: '#ricerca di mercato',
  more: 'Di più',
  speak_to_an_expert: 'Parlare con un esperto',
  book_consultancy: 'Ordina una consulenza',
  read_all: 'Leggi tutto',
  find_out_more: 'Scopri di più',
  address: 'Indirizzo ',
  mon_fri: 'dal lunedì al venerdì/lun-ven',
  phone_number: 'Numero di telefono',
  flag: 'bandiera',
  scroll_to_left: 'scorrere verso sinistra',
  online: 'online',
  tel: 'Telefono',
  email: 'Email',
  light_it_up: 'Accendo',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Direttore dello sviluppo commerciale',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Senior Project Manager',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Responsabile dello sviluppo aziendale',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Direttore delle operazioni',
  about_us_roles_1: 'Gestione',
  about_us_roles_2: 'Squadra Globale',
  about_us_roles_3: 'Proprietari del prodotto',
  ad_testing_1: 'Test degli annunci',
  ad_testing_2:
    'Una campagna pubblicitaria è un enorme investimento.Assicurati che i tuoi annunci siano efficaci e forniscano il massimo ROI ',
  ad_testing_3: 'bloccò ',
  ad_testing_4: 'Industrie che serviamo',
  ad_testing_5:
    '4Service è una società globale di ricerca sulla customer experience',
  ad_testing_6:
    '4Service è ricerca di mercato globale e holding CX. Offriamo servizi di raccolta dati esperti a un`ampia varietà di aziende',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Vendita al dettaglio',
  ad_testing_9: 'Elettronica ',
  ad_testing_10: 'Lusso/& Premium',
  ad_testing_11: 'Ristorazione e ospitalità',
  ad_testing_12: 'Settore automobilistico',
  ad_testing_13: 'Viaggi & Tempo libero',
  ad_testing_14: 'Moda',
  ad_testing_15: 'Banche',
  ad_testing_16: 'Orologi e gioielli',
  ad_testing_17: 'Casalinghi ed elettrodomestici',
  ad_testing_18: 'Membro di Esomar',
  ad_testing_19:
    'Abbiamo condotto ricerche di Ad Testing sulla pubblicità per varie aziende in diverse aree',
  ad_testing_20: 'Hai bisogno di Ad Testing( test degli annunci) se sei',
  ad_testing_21: 'Promuovere un nuovo prodotto o servizio',
  ad_testing_22: 'Toccando un argomento delicato',
  ad_testing_23:
    'Cercando di raggiungere un nuovo target demografico o di mercato',
  ad_testing_24: 'Discutere diverse opzioni di design',
  ad_testing_25:
    'Hai bisogno di una prova di concetto per i team di gestione o gli investitori',
  ad_testing_26: 'Riceverai risposte alle domande',
  ad_testing_27: 'Quali canali pubblicitari sono più adatti a questi annunci?',
  ad_testing_28:
    'Stiamo usando gli elementi visivi giusti per trasmettere il messaggio?',
  ad_testing_29: 'I tuoi testi e messaggi pubblicitari risuonano?',
  ad_testing_30: 'Quali canali pubblicitari sono più adatti a questi annunci?',
  ad_testing_31: 'Che cosa attira la loro attenzione in primo luogo?',
  ad_testing_32: 'Is the placement and layout of the ad easy to understand?',
  ad_testing_33: 'Valutiamo',
  ad_testing_34: 'Marchiò ',
  ad_testing_35:
    'come il tuo marchio è legato alla pubblicità e quanto verrà ricordato',
  ad_testing_36: 'Chiave metrica',
  ad_testing_37: 'Integrazione',
  ad_testing_38: 'Riconoscimento',
  ad_testing_39: 'Adatto',
  ad_testing_40: 'Creativo',
  ad_testing_41:
    'In che modo la tua pubblicità si distingue e attira l`attenzione delle persone',
  ad_testing_42: 'Chiave metrica',
  ad_testing_43: 'Riconoscimento',
  ad_testing_44: 'MI piace',
  ad_testing_45: 'MTO',
  ad_testing_46: 'Motivazioni ',
  ad_testing_47: 'Esperienza del cliente, coinvolgimento emotivo',
  ad_testing_48:
    'Con l`uso della tecnologia dell`intelligenza artificiale e di analisti esperti, analizziamo le reali emozioni delle persone e otteniamo approfondimenti sulle loro percezioni della pubblicità',
  ad_testing_49: 'Valutazione',
  ad_testing_50: 'Maschili ',
  ad_testing_51: 'Femminile ',
  ad_testing_52: 'Coinvolgimento alto',
  ad_testing_53: 'Attenzioni ',
  ad_testing_54: 'Cosa è piaciuto e cosa ricorda il pubblico?',
  ad_testing_55: 'Attrice',
  ad_testing_56: 'Uguaglianza',
  ad_testing_57: 'sfondo musicale',
  ad_testing_58: 'Emozioni positive',
  ad_testing_59: 'Persone di diverse razze e aspetto',
  ad_testing_60: 'Scatti all`aperto',
  ad_testing_61: 'What did the audienceне NOT like and recall?',
  ad_testing_62:
    'La voce di chi parla non corrispondeva a ciò che stava accadendo sullo schermo',
  ad_testing_63: 'Scarsa attenzione al prodotto',
  ad_testing_64: 'troppo dinamico',
  ad_testing_65: 'Difficile scendere a compromessi',
  ad_testing_66: 'TRP',
  ad_testing_67: 'impressioni',
  ad_testing_68: 'Spettacoli',
  ad_testing_69: 'Conversione',
  ad_testing_70: 'Media',
  ad_testing_71:
    'Misuriamo l`efficacia di una campagna pubblicitaria in concomitanza con l`attività mediatica, calcolando l`impatto degli investimenti mediatici sui diversi canali sui risultati monitorati.',
  ad_testing_72:
    'Conduciamo ricerche pubblicitarie in qualsiasi formato, piattaforma o canale',
  ad_testing_73: 'contenuti video',
  ad_testing_74: 'esterno',
  ad_testing_75: 'annunci televisivi(TV)',
  ad_testing_76: 'stampa',
  ad_testing_77: 'digitale',
  ad_testing_78: 'campagne pubblicitarie',
  ad_testing_79: 'Esposizione e pubblicità POS',
  ad_testing_80: 'animazioni',
  ad_testing_81:
    'Con il nostro aiuto, puoi testare la tua pubblicità in qualsiasi momento',
  ad_testing_82: 'Concetto',
  ad_testing_83: 'quali idee hanno una capacità maggiore?',
  ad_testing_84: 'Produzione in fase iniziale',
  ad_testing_85: 'quali animazioni andranno in produzione?',
  ad_testing_86: 'Produzione ',
  ad_testing_87: 'come modificare un video in modo che mantenga l`attenzione?',
  ad_testing_88: 'Pre-media',
  ad_testing_89:
    'quali canali di comunicazione funzioneranno e quale risultato ci si può aspettare?',
  ad_testing_90: 'Post-test dell`annuncio',
  ad_testing_91: 'Produzione ',
  ad_testing_92:
    'la mia pubblicità è accattivante? L`efficacia della creatività diminuisce nel tempo?',
  ad_testing_93: 'Pre-media',
  ad_testing_94: 'come si confronta con la concorrenza?',
  ad_testing_95: 'Pre-test degli annunci',
  ad_testing_96: 'Per fornirti i dati di alta qualità, utilizziamo',
  ad_testing_97: 'Metodologia di ricerca quantitativa',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Metodologia della ricerca qualitativa',
  ad_testing_100: 'Focus Groups',
  ad_testing_101: 'intelligenza artificiale(AI)',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Rapporti analitici che ricevi',
  ad_testing_104:
    'Il nostro team di analisti, esperti di media, esperti di marketing preparerà e presenterà un rapporto su misura con consigli dettagliati',
  ad_testing_age: 'Eta',
  ad_testing_ad: 'Pubblicità',
  automotive_industry_price_analysis_1:
    'Analisi del prezzo per il settore Automotive',
  automotive_industry_price_analysis_2:
    'L`analisi dei prezzi competitivi può aiutare a prevenire la perdita di profitti, ad aumentare la quota di mercato e a monitorare la tua posizione di mercato. Permettici di assisterti nella scelta di una strategia competitiva di successo',
  automotive_industry_price_analysis_3: 'Richiedi un preventivo',
  automotive_industry_price_analysis_4: 'La nostra metodologia',
  automotive_industry_price_analysis_5: 'Salone d`auto per persone eleganti',
  automotive_industry_price_analysis_6:
    'Il metodo Mystery Shopping è l’unico approccio che riflette accuratamente l’esperienza reale del cliente e fornisce una panoramica credibile.',
  automotive_industry_price_analysis_7:
    'Offriamo cifre precise e dati multilaterali affidabili',
  automotive_industry_price_analysis_8:
    'Abbiamo una copertura globale che copre tutti i paesi del mondo',
  automotive_industry_price_analysis_9:
    'Le nostre risorse interne includono mystery shopper e revisori dei conti',
  automotive_industry_price_analysis_10:
    'Convalidiamo la qualità dei nostri dati',
  automotive_industry_price_analysis_11: 'I nostri vantaggi:',
  automotive_industry_price_analysis_12:
    'Monitoraggio dei prezzi per modelli competitivi specifici (B2B e B2C)',
  automotive_industry_price_analysis_13:
    'Monitoraggio dell`andamento delle offerte',
  automotive_industry_price_analysis_14:
    'Monitoraggio delle scorte dei rivenditori per la tua azienda e dei tuoi concorrenti',
  automotive_industry_price_analysis_15:
    'Monitoraggio prezzo automotive e-commerce',
  automotive_industry_price_analysis_16:
    'Analisi della comunicazione dei prezzi',
  automotive_industry_price_analysis_17:
    'Traccia delle spese di spedizione del rivenditore',
  automotive_industry_price_analysis_18:
    'Monitoraggio promozioni e vendite presso i rivenditori',
  automotive_industry_price_analysis_19:
    'Determinazione degli importi dei pagamenti mensili',
  automotive_industry_price_analysis_20: 'Calcolo dei canoni di locazione',
  automotive_industry_price_analysis_21:
    'Analisi di considerazioni specifiche sui prezzi per i veicoli elettrici (EVs)',
  automotive_industry_price_analysis_22:
    'Confronto tra i prezzi diretti al consumatore e quelli al dettaglio',
  automotive_industry_price_analysis_23:
    'Sviluppo di modelli di prezzo di abbonamento',
  automotive_industry_price_analysis_24:
    '4Service è una holding globale di customer experience (CX) con oltre 20 anni di esperienza',
  automotive_industry_price_analysis_25:
    'Utilizzerai i dati sulla disponibilità dei consumatori a pagare per diverse funzionalità per ottimizzare la tua offerta e identificare le funzionalità più importanti per i tuoi clienti target.',
  automotive_industry_price_analysis_26:
    'L`analisi dei prezzi nel settore automobilistico effettuata da 4Service comprende:',
  automotive_industry_price_analysis_27:
    'I prezzi più bassi non sono sempre la chiave della competitività per le case automobilistiche. Lascia che ti aiutiamo a creare una strategia di prezzo a lungo termine',
  automotive_industry_price_analysis_28:
    'dei clienti comprende che i prezzi stanno aumentando per tutti i marchi',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'dei clienti ha affermato che acquisterebbe comunque l`auto che gli piace, anche se il marchio li informasse di un imminente aumento di prezzo',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'delle persone ritiene che il leasing e le rate mensili siano il modello preferito per l`acquisto di un`auto',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'dei rivenditori ha menzionato la possibilità di aumenti di prezzo durante il processo di comunicazione/vendita',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Figure chiave',
  automotive_industry_price_analysis_37:
    'Scoprirai come valutare il tuo prodotto rispetto ad altri veicoli della gamma.',
  automotive_industry_price_analysis_38:
    'Utilizzerai i dati sulla disponibilità dei consumatori a pagare per diverse funzionalità per ottimizzare la tua offerta e identificare le funzionalità più importanti per i tuoi clienti target.',
  automotive_industry_price_analysis_39:
    'dei clienti ha affermato che acquisterebbe comunque l`auto che gli piace, anche se il marchio li informasse di un imminente aumento di prezzo',
  automotive_industry_price_analysis_40:
    'Sarai in grado di determinare il pagamento mensile appropriato e le tariffe di leasing.',
  automotive_industry_price_analysis_41:
    'Quali vantaggi otterrai per il tuo marchio o rivenditore:',
  automotive_industry_price_analysis_42: 'Invia una richiesta',
  automotive_industry_price_analysis_43:
    'Il nostro manager ti contatterà per determinare gli obiettivi principali',
  automotive_industry_price_analysis_44: 'Conduciamo una ricerca di mercato',
  automotive_industry_price_analysis_45:
    'Ti forniamo regolarmente rapporti e raccomandazioni in formato online',
  automotive_industry_price_analysis_46: 'Auto con adesivo in vendita',
  automotive_industry_price_analysis_47: 'Lanceremo il progetto in 1 giorno',
  automotive_industry_price_analysis_48:
    'Offriamo una serie completa di report tra cui report fotografici, report PPT e report Excel, segmentati in base al tipo di località',
  retail_audit_1: 'VERIFICA DI MARKETING SUL CAMPO/verifica sul dettaglio',
  retail_audit_2:
    'Raccogli dati dettagliati sul tuo negozio o marchio, analizza le barriere alla crescita e crea strategie efficaci per superarle',
  retail_audit_3: 'Richiedi un preventivo',
  retail_audit_4:
    '4Service conduce audit dei negozi in loco per conto dei marchi per valutare l`efficacia delle loro strategie promozionali, dei team di esecuzione sul campo e delle tattiche di marketing nel raggiungere il mercato target previsto',
  retail_audit_5:
    'Osserviamo meticolosamente l`esecuzione delle campagne promozionali e garantiamo il rispetto delle linee guida promozionali.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'CX agenzie in Europa',
  retail_audit_8: 'anni di esperienza',
  retail_audit_9: 'Copertura delle Nazioni',
  retail_audit_10: '1,6 milioni',
  retail_audit_11: 'Valutazioni per anno',
  retail_audit_12: 'Che cosa tracciamo?',
  retail_audit_13: 'Presenza di materiali per punti vendita (POS)',
  retail_audit_14:
    'Presenza e attivazione di ambasciatori del marchio, garantendo il rispetto del programma pianificato',
  retail_audit_15:
    'Valutazione del coinvolgimento degli ambasciatori del marchio nelle campagne di Patrocinio',
  retail_audit_16:
    'Valutazione dell`interazione del consumatore, garantendo il rispetto delle linee guida promozionali e dei messaggi del marchio',
  retail_audit_17: 'Misure di prevenzione delle frodi',
  retail_audit_18:
    'La tua azienda può utilizzare i risultati di una verifica di marketing sul campo per:',
  retail_audit_19: 'Valutare il rispetto del budget e dei processi',
  retail_audit_20: 'Confronta i risultati effettivi con gli obiettivi previsti',
  retail_audit_21: 'Migliorare la performance delle campagne di marketing ',
  retail_audit_22: 'Piano strategico per il futuro',
  retail_audit_23: 'Identifica le opportunità per la riduzione dei costi',
  retail_audit_24: 'Migliora le vendite e i tassi di conversione',
  retail_audit_25: 'Ottimizzione del Ritorno dell`investimento (ROI)',
  retail_audit_26:
    'HANNO SCELTO 4SERVICE COME PARTNER PER GLI INVESTIMENTI COMMERCIALI',
  retail_audit_27:
    'Un produttore leader internazionale di prodotti del tabacco ha collaborato con 4Service per valorizzare i propri marchi e migliorare le prestazioni nei punti vendita. I nostri team hanno fornito audit di marketing sul campo e report in tempo reale sull`esperienza del consumatore.',
  retail_audit_28:
    'aumento dell’accuratezza della pianificazione dell’agenzia di marketing sul campo (field marketing)',
  retail_audit_29: 'aumento della trasmissione del messaggio del marchio',
  retail_audit_30: 'incremento dell`impegno BA (attivazione del consumatore)',
  retail_audit_31:
    'aumento dell’accuratezza della raccolta dati e della conformità al GDPR',
  retail_audit_32: 'Quali preziose intuizioini tendiamo a trovare:',
  retail_audit_33:
    'Una mancanza di coerenza o addirittura un conflitto tra i termini dello SLA porta a notevoli addebiti non approvati al cliente',
  retail_audit_34:
    'Addebito errato delle tariffe dovuto alla confusione tra le tipologie di servizi forniti',
  retail_audit_35:
    'Servizio sul campo non eseguito, compreso il personale non assegnato al luogo previsto | POS',
  retail_audit_36:
    'Addebiti effettuati su una stima anziché su quelli effettivi e non riconciliati',
  retail_audit_37:
    'Applicazione errata delle linee guida promozionali che forniscono dati fraudolenti',
  retail_audit_38: 'Come lavoriamo?',
  retail_audit_39:
    'Il nostro revisore visita i punti vendita (POS) designati in base al piano di attivazione dell`agenzia.',
  retail_audit_40:
    'Valutano gli indicatori chiave di prestazione (KPI) come delineato nel riassunto, come la presenza del punto vendita, il coinvolgimento degli ambasciatori del marchio, il rispetto delle linee guida promozionali e le misure di prevenzione delle frodi.',
  retail_audit_41: 'Convalida:',
  retail_audit_42: 'Raccogliamo e consolidiamo i dati ottenuti.',
  retail_audit_43: 'Un rapporto comprensivo è redatto.',
  retail_audit_44:
    'Il nostro team di convalida esamina attentamente tutti i sondaggi per verificarne l`accuratezza, inclusa la conformità con il riassunto del progetto ed eventuali commenti aggiuntivi.',
  retail_audit_45: 'Sistema di Report Online:',
  retail_audit_46:
    'Questionari completi e convalidati sono scaricabili sul nostro portale online in 36 ore.',
  retail_audit_47:
    'I clienti hanno access ad un Profilo Personale, che dà la possibilità di monitorare i progressi costantemente.',
  retail_audit_48:
    'Un rapporto finale contenente i risultati e le nostre raccomandazioni può essere recapitato.',
  retail_audit_49: 'Contatta un esperto',
  retail_audit_50: 'I nostri punti di forza:',
  facility_check_audit_1: 'Facilità',
  facility_check_audit_2: 'Verifica Audit',
  facility_check_audit_3:
    'Migliora l`esperienza del tuo marchio in ogni dettaglio',
  facility_check_audit_4: 'Esplora il nostro approccio',
  facility_check_audit_5: 'Di',
  facility_check_audit_6: 'Chi siamo',
  facility_check_audit_7:
    'In qualità di holding globale del servizio clienti, 4Service offre una gamma completa di servizi per aiutare i marchi a creare e offrire ai propri clienti un`esperienza eccezionale. ',
  facility_check_audit_8: 'Il nostro sistema dell`audit del servizio',
  facility_check_audit_9:
    'è progettato per aiutarti a garantire che le sedi fisiche e la presenza del marchio nei negozi al dettaglio soddisfino gli standard del tuo marchio e creino un`impressione positiva per i consumatori.',
  facility_check_audit_10: 'Soluzioni 1',
  facility_check_audit_11: 'L`audit della struttura',
  facility_check_audit_12:
    'è un esame meticoloso che valuta l`allineamento dei tuoi punti vendita o dei tuoi stand agli standard del tuo brand. Non solo verifichiamo gli attributi fisici, ma valutiamo anche come questi elementi influenzano il modo in cui il tuo marchio viene percepito dai tuoi stimati clienti.',
  facility_check_audit_13: 'Due soluzioni',
  facility_check_audit_14: 'Due metodi di implementazione',
  facility_check_audit_15: 'Metodo',
  facility_check_audit_16: 'La verifica della struttura dagli auditori esperti',
  facility_check_audit_17: 'Convalida della verifica della struttura',
  facility_check_audit_18: ' Rapporti',
  facility_check_audit_19:
    'Riceverai rapporti, completi di prove visive e statistiche, accessibili entro 48 ore',
  facility_check_audit_20:
    'Un audit della struttura ben eseguito può fornire una serie di vantaggi per il tuo marchio, tra cui:',
  facility_check_audit_21: 'Miglioramento della conversione dei clienti:',
  facility_check_audit_22:
    'Un`esperienza cliente positiva può portare a un aumento delle vendite e alla ripetizione degli affari. I nostri audit possono aiutarti a identificare e risolvere eventuali aree che potrebbero influire sulla soddisfazione del cliente.',
  facility_check_audit_23: 'Immagine del marchio migliorata:',
  facility_check_audit_24:
    'Le tue sedi fisiche sono un punto di contatto chiave per i clienti e possono svolgere un ruolo significativo nel plasmare l`immagine del tuo marchio. Le nostre verifiche possono aiutarti a garantire che le tue sedi siano coerenti con i valori del tuo marchio e creino un`impressione positiva per i clienti.',
  facility_check_audit_25: 'Perché noi?',
  facility_check_audit_26: 'Perché è importante l’audit della struttura?',
  facility_check_audit_27:
    'Un audit della struttura ben eseguito può fornire una serie di vantaggi per il tuo marchio, tra cui:',
  facility_check_audit_28: 'Immagine del marchio migliorata:',
  facility_check_audit_29:
    'Le tue sedi fisiche sono un punto di contatto chiave per i clienti e possono svolgere un ruolo significativo nel plasmare l`immagine del tuo marchio. Le nostre verifiche possono aiutarti a garantire che le tue sedi siano coerenti con i valori del tuo marchio e creino un`impressione positiva per i clienti.',
  facility_check_audit_30: 'Miglioramento della conversione dei clienti:',
  facility_check_audit_31:
    'Un`esperienza cliente positiva può portare a un aumento delle vendite e alla ripetizione degli affari. I nostri audit possono aiutarti a identificare e risolvere eventuali aree che potrebbero influire sulla soddisfazione del cliente.',
  facility_check_audit_32: 'Chi ha bisogno',
  facility_check_audit_33: 'Chi ha bisogno di un audit della struttura?',
  facility_check_audit_34:
    'L`audit della struttura è un servizio prezioso per qualsiasi brand con sedi fisiche e presenza fisica. È particolarmente importante per i marchi che desiderano:',
  facility_check_audit_35: 'Garantire la conformità agli standard del marchio:',
  facility_check_audit_36:
    'I nostri audit possono aiutarti a identificare eventuali aree in cui le tue sedi potrebbero discostarsi dagli standard del tuo marchio.',
  facility_check_audit_37: 'Migliorare l`esperienza del cliente:',
  facility_check_audit_38:
    'I nostri audit possono aiutarti a identificare le opportunità per migliorare l`esperienza del cliente nelle tue sedi.',
  facility_check_audit_39: 'Creare un marchio più forte:',
  facility_check_audit_40:
    'I nostri audit possono aiutarti a garantire che le tue sedi fisiche creino un`impressione positiva per i clienti.',
  facility_check_audit_41:
    'Il mystery shopper scatta tutte le foto necessarie in un luogo specificato.',
  facility_check_audit_42:
    'Quindi, il mystery shopper compila un questionario e allega le foto.',
  facility_check_audit_43:
    'Il validatore riceve le foto e ne controlla la conformità agli standard.',
  facility_check_audit_44: 'La verifica della struttura dagli auditori esperti',
  facility_check_audit_45: 'Fase di preparazione:',
  facility_check_audit_46: 'Approvazione congiunta del programma di audit',
  facility_check_audit_47:
    'I rivenditori vengono preparati e informati sull`imminente audit',
  facility_check_audit_48: 'Formazione degli auditor:',
  facility_check_audit_49:
    'I nostri formatori esperti di 4Service forniscono agli auditor le linee guida del marchio',
  facility_check_audit_50: 'Fase di audit della struttura:',
  facility_check_audit_51:
    'I revisori, dotati di speciali lettere di autorizzazione, visitano i luoghi',
  facility_check_audit_52:
    'Un esame approfondito di ogni punto, seguendo meticolosamente le linee guida',
  facility_check_audit_53:
    'Documentazione visiva, insieme a valutazioni rispetto ai prerequisiti HQ',
  facility_check_audit_54: 'Rapporto PPT conclusivo',
  facility_check_audit_55:
    'Un rapporto PowerPoint completo che spiega i risultati',
  facility_check_audit_56:
    'Immersiva presentazione online / offline dei risultati dell`audit',
  facility_check_audit_57: 'Convalida dell`audit della struttura',
  facility_check_audit_58: 'Passi:',
  facility_check_audit_59: 'Fase di audit della struttura:',
  facility_check_audit_60:
    'I rappresentanti del marchio sfruttano l`app 4Service per acquisire immagini sul posto',
  facility_check_audit_61: 'Aderenza di rigorosi criteri visivi e descrittivi',
  facility_check_audit_62: 'Fase di convalida:',
  facility_check_audit_63:
    'Validatori diligenti esaminano meticolosamente ogni rapporto',
  facility_check_audit_64:
    'Confronto completo delle foto con gli standard del marchio',
  facility_check_audit_65:
    'Annotazioni e approfondimenti sono integrati nel report per una maggiore chiarezza',
  facility_check_audit_66:
    'Comunicazione diretta con gli auditor per chiarimenti, se necessario',
  facility_check_audit_67: 'Fase di rendicontazione:',
  facility_check_audit_68:
    'Report, completi di prove visive e statistiche, accessibili entro due giorni',
  facility_check_audit_69: 'Contattaci oggi',
  cx_solution_1: 'Non solo troviamo idee,',
  cx_solution_2: 'Porteremo il tuo business a nuovi traguardi',
  cx_solution_3:
    'Esperti con oltre 20 anni di esperienza al servizio dei clienti trovano soluzioni complesse e ti forniscono un piano d`azione concreto che contribuisce alla tua crescita',
  cx_solution_4:
    'Scopri una gamma unica di servizi in un`unica soluzione lungimirante',
  cx_solution_5: 'Soluzione universale CX',
  cx_solution_6: 'Test degli annunci',
  cx_solution_7: 'IA e Neuromarketing',
  cx_solution_8: 'La voce del cliente',
  cx_solution_9: 'Ricerca documentaria',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Mystery shopping',
  cx_solution_12: 'Consulenza CX',
  cx_solution_13: 'Ascolto sui Social Media',
  cx_solution_14: 'Ricerca quantitativa e qualitativa',
  cx_solution_15: 'Progettazione del servizio',
  cx_solution_16: 'Strategia di marketing',
  cx_solution_17: 'Test UX/UI',
  cx_solution_18: 'Statistiche sul pubblico',
  cx_solution_19: 'Siamo onorati di contribuire al tuo successo',
  cx_solution_20:
    'Personalizziamo e combiniamo strumenti e servizi per adattarli ai tuoi scopi e obiettivi aziendali per ottenere risultati personalizzati',
  cx_solution_21: 'Ricerca completa',
  cx_solution_22:
    'Espandi le tue capacità decisionali, fornendo un vantaggio competitivo in un mercato incentrato sui dati.',
  cx_solution_23:
    'La ricerca qualitativa implica la raccolta e l’analisi di dati non numerici per comprendere concetti, opinioni o esperienze',
  cx_solution_24:
    'I metodi di ricerca quantitativa sono una misurazione oggettiva e un`analisi numerica dei dati raccolti attraverso sondaggi, questionari e indagini',
  cx_solution_25:
    'Utilizziamo un set distinto di strumenti in base alle tue esigenze specifiche',
  cx_solution_26: 'Strumenti',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'L`intervista web assistita da computer è uno strumento vitale nel nostro arsenale, che ci consente di condurre sondaggi e raccogliere dati attraverso piattaforme online con l`assistenza di software specializzato, garantendo una raccolta dati efficiente e accurata per le esigenze di ricerca di mercato dei nostri clienti',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'I colloqui telefonici assistiti da computer sono un metodo che utilizziamo per condurre sondaggi e raccogliere dati preziosi al telefono, impiegando software specializzati per semplificare il processo e garantire l`accuratezza nelle nostre attività di ricerca di mercato',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'La comunicazione personale fa parte integrante del nostro approccio, facilitando l`interazione diretta e le interazioni personalizzate con clienti e consumatori, costruendo così la fiducia, la comprensione e la comunicazione efficace necessarie per costruire relazioni a lungo termine e raggiungere il successo aziendale',
  cx_solution_33: 'Ricerca documentaria',
  cx_solution_34:
    'La ricerca documentaria prevede la raccolta e l`analisi completa dei dati, che costituiscono un passaggio fondamentale nel nostro processo di ricerca per raccogliere approfondimenti e informare il processo decisionale strategico per i nostri clienti',
  cx_solution_35: 'Analisi SWOT',
  cx_solution_36:
    'L`analisi SWOT è uno strumento di valutazione strategica che aiuta i nostri clienti a identificare punti di forza interni, punti deboli, opportunità e minacce esterne, fornendo informazioni preziose per il processo decisionale e lo sviluppo di strategie aziendali efficaci',
  cx_solution_37: 'Ricerca etnografica',
  cx_solution_38:
    'Lo studio di persone e culture attraverso l`immersione e l`osservazione nei loro ambienti naturali, che spesso porta a una profonda comprensione del comportamento, delle credenze e delle dinamiche sociali',
  cx_solution_39: 'Interviste approfondite',
  cx_solution_40:
    'Le interviste approfondite sono un metodo di ricerca qualitativa che utilizziamo che prevede discussioni approfondite e individuali con i partecipanti per esplorare ulteriormente i loro punti di vista, esperienze e opinioni, fornendo una comprensione approfondita degli obiettivi di ricerca e dei processi decisionali dei nostri clienti',
  cx_solution_41: 'Sessioni di ideazione',
  cx_solution_42:
    'Le sessioni di ideazione sono sessioni di brainstorming collaborative che facciamo, riunendo diverse prospettive ed esperienze per sviluppare idee creative e soluzioni ai problemi o alle opportunità dei nostri clienti, promuovendo l`innovazione e portando avanti iniziative strategiche',
  cx_solution_43: 'Identità del consumatore',
  cx_solution_44:
    'Le identità dei consumatori sono profili dettagliati che sviluppiamo, che rappresentano i consumatori target dei nostri clienti sulla base di ricerche e analisi dei dati, consentendoci di comprendere meglio le loro esigenze, preferenze e comportamenti e di personalizzare le nostre strategie e comunicazioni per coinvolgerli in modo efficace ed entrare in risonanza con loro, che in definitiva, è la chiave del successo aziendale',
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping è un servizio strategico che offriamo, misurando e monitorando l`interazione tra un cliente e un`azienda in uno scenario predeterminato',
  cx_solution_47: 'Accompagnare la verifica',
  cx_solution_48:
    'Accompagnare la verifica è una forma specializzata di mystery shopping in cui i nostri valutatori accompagnano i consumatori durante l`intera esperienza del servizio, fornendo approfondimenti completi su ogni aspetto del percorso del consumatore, consentendo ai nostri clienti di acquisire una profonda comprensione della fornitura del servizio e apportare miglioramenti informati per aumentare la soddisfazione e la fedeltà complessive del cliente',
  cx_solution_49: 'Analisi raccolta feedback',
  cx_solution_50:
    'L`analisi della raccolta dei feedback è un processo cruciale che intraprendiamo, che prevede la raccolta e l`analisi sistematica delle recensioni degli acquirenti attraverso vari canali, come sondaggi, recensioni e social media, che ci consente di estrarre informazioni preziose, identificare le tendenze e formulare raccomandazioni basate sui dati per i nostri clienti per migliorare i loro prodotti, servizi e qualità complessiva del esperienza dell`acquirente',
  cx_solution_51: 'I Focus group',
  cx_solution_52:
    'I focus group sono sessioni di ricerca dinamica che facilitiamo, riunendo un gruppo eterogeneo di partecipanti per discutere in modo approfondito argomenti o prodotti specifici, permettendoci di raccogliere intuizioni qualitative, scoprire atteggiamenti, preferenze e percezioni ed esplorare idee e concetti, informando in ultima analisi i nostri clienti sui processi decisionali e di sviluppo strategico',
  cx_solution_53:
    '20 anni di esperienza nel creare esperienze cliente eccezionali',
  cx_solution_54:
    'Assicurati che il tuo messaggio affascini e risuoni con il tuo pubblico.',
  cx_solution_55:
    'Ci impegniamo a fornire risultati che superano le tue aspettative',
  cx_solution_56:
    'Troviamo soluzioni complete che contribuiscono alla tua crescita',
  cx_solution_57:
    'Migliora la tua esperienza cliente con la nostra metodologia ',
  cx_solution_58:
    'La mappatura del percorso del cliente riguarda la definizione di un pubblico target, la comprensione delle sue esigenze e dell`ambiente e quindi la creazione di un piano per offrire un`esperienza coinvolgente',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'La ricerca sul monitoraggio della salute del marchio può misurare le prestazioni del tuo marchio in termini di notorietà e utilizzo, posizionamento del marchio e prestazioni del marchio',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Il Customer Satisfaction Index è un punteggio che indica quanto sono soddisfatti i tuoi clienti rispetto ai prodotti/servizi dell`azienda.',
  cx_solution_63:
    'Il monitoraggio dei prezzi è il processo di raccolta, elaborazione e analisi dei prezzi dei concorrenti',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Il Net Promoter Score è il gold standard dei parametri relativi all`esperienza del cliente, basato su una domanda: con quale probabilità consiglieresti l`organizzazione/prodotto/servizio?',
  cx_solution_66: 'HR ricerca',
  cx_solution_67:
    'La ricerca sulle risorse umane viene utilizzata per valutare le pratiche e le prestazioni delle risorse umane e offre un`analisi dettagliata dello sviluppo e della gestione attuali',
  cx_solution_68:
    'I test di usabilità prevedono l`osservazione di persone reali che interagiscono con un sito Web, un`app o un altro prodotto e l`osservazione del loro comportamento e delle loro reazioni ad esso',
  cx_solution_69: 'Altro',
  cx_solution_70:
    'Gli esperti selezionano la metodologia migliore per la tua attività',
  cx_solution_71: 'Iniziamo',
  cx_solution_72: '1 - RIUNIONE INIZIALE',
  cx_solution_73:
    'Durante la riunione iniziale, identifichiamo e ci concentriamo sugli obiettivi chiave. Risultato: comprensione dello scopo, degli obiettivi dello studio, dei tempi, dei metodi e degli ambiti di responsabilità.',
  cx_solution_74: '2 - SVILUPPO DEL CONCETTO',
  cx_solution_75:
    'Il dipartimento di analisi di 4Service aggrega i dati e coordina la struttura del report. Presentiamo quindi i risultati al cliente, completi di idee e raccomandazioni per l`implementazione.',
  cx_solution_76: '3 - LAVORO SUL CAMPO',
  cx_solution_77:
    'Per condurre la ricerca, reclutiamo partecipanti, conduciamo interviste e altre fasi di ricerca di base. Il cliente riceve interviste audio-video trascritte, nonché riassunti concisi.',
  cx_solution_78: '4 - ANALISI',
  cx_solution_79:
    'Il dipartimento di analisi di 4Service aggrega i dati, coordina la struttura dei report. Presentiamo quindi i risultati al cliente, integrati con approfondimenti e raccomandazioni per l`implementazione.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81:
    'Condurre una discussione con il cliente in merito al piano di implementazione',
  cx_solution_82: 'Scopri di più sulle nostre migliori pratiche',
  cx_solution_83:
    'Una mappa del percorso del cliente consente di esaminare rapidamente tutti i canali di comunicazione, identificare potenziali lacune nei punti di contatto critici e acquisire una comprensione completa delle aree che potrebbero deviare dal percorso pianificato.',
  cx_solution_84: 'Quando usarlo?',
  cx_solution_85:
    'Costruisci una campagna pubblicitaria personalizzata basata su dati reali sui gruppi di pubblico',
  cx_solution_86:
    'Scopri i fattori scatenanti dei diversi segmenti di pubblico target e soddisfa le loro esigenze',
  cx_solution_87:
    'Scegli come target ciascun gruppo e ottieni la massima conversione',
  cx_solution_88:
    'La tecnica del Checking Accompagnato consente di valutare le possibilità di modi ottimali per raggiungere i propri obiettivi.',
  cx_solution_89: 'Testare un nuovo sito o prodotto prima del lancio',
  cx_solution_90:
    'Controllo dei processi sviluppati prima di implementare le modifiche',
  cx_solution_91:
    'Valutazione di un sito web o di un prodotto in condizioni di calo delle vendite',
  cx_solution_92:
    'Lancio di un nuovo prodotto su misura per le preferenze del cliente',
  cx_solution_93:
    'Studio delle aziende concorrenti per consolidare posizioni di leadership nel mercato',
  cx_solution_94:
    'Offriamo un approccio completo con visione a 360° per creare un modello di servizio orientato al cliente.',
  cx_solution_95: 'Formazione e sviluppo del personale',
  cx_solution_96: 'Miglioramento degli standard di servizio',
  cx_solution_97: 'Aumento del flusso di clienti e delle vendite',
  cx_solution_98: 'Definizione di KPI e sistemi di motivazione del personale',
  cx_solution_99: 'Scopri le nostre storie di successo',
  cx_solution_100: 'Settore automobilistico',
  cx_solution_101: 'Tabacco',
  cx_solution_102: 'Commercio al dettaglio',
  cx_solution_103: 'Fase qualitativa',
  cx_solution_104: 'Fase quantitativa (segmentazione)',
  cx_solution_105: 'I Focus group',
  cx_solution_106: 'Copertura del pubblico online in tutto il mondo',
  cx_solution_107: 'Combinazione di tecniche durante le interviste',
  cx_solution_108: 'Solo domande aperte',
  cx_solution_109: 'Supporto per gli intervistati 24/7',
  cx_solution_110:
    'Conservazione dei dettagli importanti e un`immagine reale del percorso',
  cx_solution_111: 'Ricerca di mercato',
  cx_solution_112: 'Diagnostica dell`azienda e dei concorrenti',
  cx_solution_113: 'Modelli di servizio aggiornati e nuovi processi aziendali',
  cx_solution_114: 'Promozione del servizio orientato al cliente',
  cx_solution_115:
    'Implementazione dei cambiamenti e controllo sullo sviluppo delle strategie di servizio',
  usage_attitude_1: 'Utilizzo e  Atteggiamento (U&A)',
  usage_attitude_2:
    'Ottieni informazioni complete su come il pubblico target  percepisce, utilizza e interagisce con il tuo marchio.',
  usage_attitude_3: 'Parla con un esperto',
  usage_attitude_4: 'Di',
  usage_attitude_5:
    '<b>Esplora il consumatore</b> abitudini per scoprire preferenze e influenze in profondità',
  usage_attitude_6:
    '<b>Scopri il `perché`</b> dietro le scelte dei consumatori con la nostra analisi dettagliata',
  usage_attitude_7:
    '<b>Capire come</b> i clienti interagiscono con i tuoi prodotti per ottenere informazioni strategiche',
  usage_attitude_8:
    '<b>Ottieni approfondimenti su</b> comportamento, abitudini e preferenze dei consumatori per decisioni informate',
  usage_attitude_9: 'Esperienza',
  usage_attitude_10:
    'Gli esperti di 4Service con oltre 20 anni di esperienza con i clienti creano soluzioni uniche che aiutano a far crescere la tua attività',
  usage_attitude_11: 'Contattaci',
  usage_attitude_12: 'Per chi',
  usage_attitude_13:
    'Chi trae vantaggio dall’utilizzo e dall’atteggiamento del marchio?',
  usage_attitude_14:
    'Varie industrie e settori dei servizi sono interessati a:',
  usage_attitude_15:
    '<b>Riconoscere</b> le richieste dei clienti e i punti critici',
  usage_attitude_16:
    '<b>Identificare</b> opportunità per aumentare il consumo dei clienti',
  usage_attitude_17:
    '<b>Sviluppo</b> di nuove categorie di prodotti o strategie di marketing',
  usage_attitude_18:
    '<b>Acquisire</b> una profonda comprensione del panorama competitivo',
  usage_attitude_19:
    'La metodologia viene selezionata individualmente in base alla richiesta del cliente',
  usage_attitude_20:
    '<b>La ricerca qualitativa</b> implica la raccolta e l`analisi di dati non numerici per comprendere concetti, opinioni o esperienze.',
  usage_attitude_21:
    '<b>I metodi di ricerca quantitativa</b> implicano la misurazione oggettiva e l`analisi numerica dei dati ottenuti attraverso sondaggi, questionari e indagini.',
  usage_attitude_22:
    '<b>Dopo aver raccolto i dati</b>, gli esperti analizzano le informazioni e traggono conclusioni per fornirti i migliori consigli.',
  usage_attitude_23: 'Chi ha bisogno',
  usage_attitude_24: 'La tua storia di successo inizia qui',
  usage_attitude_25:
    '<b>Riceverai una ricerca</b> con approfondimenti preziosi che potenziano il processo decisionale strategico, garantendo che le azioni siano basate su una profonda comprensione del comportamento dei consumatori.',
  usage_attitude_26:
    '<b>La ricerca fornisce una solida base per perfezionare</b> e ottimizzare le strategie del marchio, allineandole più strettamente alle preferenze e agli atteggiamenti dei consumatori.',
  usage_attitude_27: 'Soluzioni',
  usage_attitude_28: 'Esplora numerose opportunità con le nostre soluzioni',
  usage_attitude_29: 'Preferenza del prodotto all`interno della categoria',
  usage_attitude_30:
    'Identifica le principali preferenze dei consumatori in varie categorie, tra cui cibo, bevande, abbigliamento, elettronica e altro ancora.',
  usage_attitude_31: 'Comportamento di consumo / utilizzo',
  usage_attitude_32:
    'Analizzare la frequenza di consumo o utilizzo di specifici prodotti/servizi e identificare le occasioni e i momenti associati al loro utilizzo.',
  usage_attitude_33: 'Comportamento d`acquisto',
  usage_attitude_34:
    'Esaminare i canali che i clienti utilizzano per i loro acquisti e identificare le fonti di informazione su cui fanno affidamento.',
  usage_attitude_35: 'Motivazioni e bisogni dei consumatori',
  usage_attitude_36:
    'Esaminare le motivazioni e le barriere che influenzano il consumo. Valutare i fattori che influenzano le decisioni di acquisto dei consumatori. Esplorare la percezione della categoria di prodotto e analizzare come viene percepito il prezzo.',
  usage_attitude_37: 'Il nostro approccio',
  usage_attitude_38: 'Studio sull`utilizzo e l`atteggiamento del marchio',
  usage_attitude_39: 'Informazione',
  usage_attitude_40:
    'Conduzione di indagini utilizzando metodi sia quantitativi che qualitativi',
  usage_attitude_41: 'Cominciare',
  usage_attitude_42:
    'Stabilire obiettivi e determinare metodi in base alle richieste individuali',
  usage_attitude_43: 'Analisi',
  usage_attitude_44: 'Tecnologie sofisticate utilizzate dai nostri esperti',
  usage_attitude_45:
    'Utilizziamo un insieme distinto di strumenti in base alle tue esigenze specifiche',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'L`intervista telefonica assistita da computer è un metodo che utilizziamo per condurre sondaggi e raccogliere dati preziosi tramite telefono, utilizzando software specializzati per semplificare il processo e garantire l`accuratezza nelle nostre attività di ricerca di mercato.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'L`intervista web assistita da computer è uno strumento vitale nel nostro arsenale, che ci consente di condurre sondaggi e raccogliere dati attraverso piattaforme online con l`assistenza di software specializzato, garantendo una raccolta dati efficiente e accurata per le esigenze di ricerca di mercato dei nostri clienti.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'L`intervista personale assistita da computer è un metodo che utilizziamo per ottimizzare i processi di raccolta dei dati, rendere le interviste più fluide e garantire risultati accurati e perspicaci per le esigenze aziendali.',
  usage_attitude_52: 'I Focus group',
  usage_attitude_53:
    'I focus group sono sessioni di ricerca dinamiche che facilitiamo, riunendo un gruppo eterogeneo di partecipanti per discutere in modo approfondito argomenti o prodotti specifici, permettendoci di raccogliere intuizioni qualitative, scoprire atteggiamenti, preferenze e percezioni ed esplorare idee e concetti,  informando in ultima analisi i nostri clienti sui processi decisionali e di sviluppo strategico.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Il faccia a faccia è parte integrante del nostro approccio, facilitando le interazioni dirette e gli impegni personalizzati con clienti e consumatori, promuovendo così la fiducia, la comprensione e la comunicazione efficace, essenziali per costruire relazioni durature e guidare i focus group sul successo aziendale.',
  usage_attitude_56: 'Ricerca etnografica',
  usage_attitude_57:
    'Studiare le persone e le culture attraverso l`immersione e l`osservazione nei loro ambienti naturali spesso porta a intuizioni profonde su comportamenti, credenze e dinamiche sociali.',
  parthership: 'Associazione',
  subscribe: 'Subscribe with a click',
  footer_7: 'Ricerca di mercato',
  ux_testing_1_1: 'UX Testing',
  menu_22: 'Facility Check Audit',
  menu_23: 'Service Design',
  menu_24: 'CX',
  thank_you_page:
    'Grazie per il vostro tempo! Il nostro manager vi contatterà al più presto.',
  back_main: 'Torna alla pagina principale',
  opportunities_1: 'Per saperne di più sui nostri casi',
  opportunities_2: 'Discover',
  opportunities_3: 'per te stesso',
  opportunities_4: 'opportunities',
  opportunities_5:
    "del business internazionale insieme all'azienda di ricerca 4Service",
  opportunities_6:
    'Con quali <span>prodotti</span> possiamo rafforzare la vostra attività?',
  opportunities_7: 'CX',
  opportunities_8: 'Soluzioni complete e personalizzate per le aziende',
  opportunities_9: 'Tutti i tipi di Mystery Shopping',
  opportunities_10:
    'Implementiamo mystery employee, mystery caller, mystery shopper del lusso, mystery shopper del settore automobilistico e altri tip',
  opportunities_11: 'Soluzioni IT personalizzate per le aziende',
  opportunities_12:
    "Possedere soluzioni IT con intelligenza artificiale, piattaforme per l'analisi dei feedback e la trascrizione delle chiamate",
  opportunities_13: 'Market research',
  opportunities_14:
    'Per qualsiasi richiesta aziendale (monitoraggio della salute del marchio, segmentazione, NPS, eNPS, CJM, test di prodotti e pubblicità, ecc.)',
  opportunities_15: 'Audits',
  opportunities_16:
    'Realizziamo diversi audit dei servizi, nonché il monitoraggio dei prezzi (offline, per nicchie specifiche, ecc.)',
  opportunities_17: 'Service Design',
  opportunities_18:
    '4Service - partner certificato di DesignThinkers Academy, che offre formazione aziendale per i team e conduce regolarmente workshop per le aziende',
  opportunities_19:
    'Condividiamo la nostra esperienza, ma <span>ispettare e non disturbare</span> il vostro processo aziendale.',
  opportunities_20:
    "Conosciamo le difficoltà che le imprese incontrano nel loro percorso di sviluppo e sappiamo come superare queste barriere grazie all'esperienza internazionale di successo. Non solo esperienza, ma anche risorse.",
  opportunities_21: 'Vi invitiamo a collaborare',
  opportunities_22:
    'Offriamo un <span>programma di partnership</span> per la vostra attività',
  opportunities_23:
    "Con il marchio 4Service, riconosciuto a livello internazionale, potete influenzare positivamente l'attrazione di nuovi clienti.",
  opportunities_24:
    'I nostri partner ricevono un supporto completo, che comprende formazione, materiale di marketing e assistenza operativa.',
  opportunities_25:
    'La partnership con noi realizzerà i vostri piani senza interrompere il vostro attuale processo aziendale.',
  opportunities_26: 'Il nostro <span>uffici</span>',
  opportunities_27: 'I nostri clienti',
  opportunities_28: 'Abbiamo oltre 20 anni di esperienza nella CX',
  opportunities_29: 'Paesi coperti',
  opportunities_30: 'mille',
  opportunities_31: 'Progetti unici',
  opportunities_32: 'milioni',
  opportunities_33: 'Visite di mystery shopper',
  opportunities_34: 'è inclusa nella TOP 10 delle agenzie CX europee',
  opportunities_35: 'Le vostre opportunità con 4Service',
  opportunities_36: "Aumento dell'utile",
  opportunities_37: 'Potrete accedere agli ordini internazionali',
  opportunities_38: 'Approccio unico alla CX',
  opportunities_39: 'Assistenza da parte di esperti con 20 anni di esperienza',
  opportunities_40: 'Supporto',
  opportunities_41: 'Il nostro team vi aiuterà a raggiungere i risultati',
  opportunities_42: 'Vendite',
  opportunities_43: 'Vi aiuteremo a creare un sistema di vendita',
  opportunities_44: 'Accesso al software',
  opportunities_45:
    'Avrete accesso a tutti gli strumenti informatici certificati',
  opportunities_46: 'Formazione',
  opportunities_47:
    'Non ci limiteremo a formare il vostro personale, ma continueremo a fornirvi servizi di consulenza',
  opportunities_48: 'Chi è adatto alla partnership?',
  opportunities_49: 'La vostra attività è legata alla ricerca di marketing?',
  opportunities_50:
    'Oppure state pensando di mettervi alla prova in un nuovo campo',
  opportunities_51: 'Avete intenzione di:',
  opportunities_52: 'Implementare le innovazioni',
  opportunities_53: 'Lavorare con marchi internazionali',
  opportunities_54: 'Ampliare il portafoglio prodotti',
  opportunities_55: 'Rafforzare la propria posizione di mercato?',
  opportunities_56: 'Formare i dipendenti',
  opportunities_57: 'Scalare la vostra attività',
  opportunities_58: 'Le nostre risorse = le vostre risorse',
  opportunities_59:
    'Data la nostra vasta esperienza, siamo pronti a condividerla con voi già nella prima fase della collaborazione',
  opportunities_60: 'Analisi dei dati:',
  opportunities_61: 'Team internazionale di analisi',
  opportunities_62: 'Metodi di elaborazione dei dati - SPSS, Power BI',
  opportunities_63: "Metodologie innovative e un'ampia gamma di metodologie",
  opportunities_64: 'Reporting in qualsiasi formato',
  opportunities_65: 'Software:',
  opportunities_66: 'Sviluppi IT personalizzati propri',
  opportunities_67: 'DigSee Sure for CAPI',
  opportunities_68: 'SurveyMonkey for CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (call center)',
  opportunities_71: 'Supporto al marketing:',
  opportunities_72: 'Supporto PR',
  opportunities_73: 'Supporto nella promozione e nella creazione di contenuti',
  opportunities_74: 'Impostazione dei processi di lead generation',
  opportunities_75: 'Supporto IT:',
  opportunities_76:
    "Assistenza tecnica per l'installazione, la configurazione e l'utilizzo del nostro software.",
  opportunities_77: 'Assistenza remota',
  opportunities_78: 'Controllo di sicurezza',
  opportunities_79: 'Supporto operativo:',
  opportunities_80: 'Supporto nella creazione di processi sul campo',
  opportunities_81: 'Sistema antifrode',
  opportunities_82: 'Reclutamento di qualsiasi complessità',
  opportunities_83:
    'Gestione operativa del team di progetto e dei reparti sul campo',
  opportunities_84: 'Controllo di qualità del reclutamento',
  opportunities_85: 'UpWork',
  opportunities_86: 'Quali bonus otterrete?',
  opportunities_87: 'Aumento della base clienti',
  opportunities_88: "Rafforzare l'immagine del mercato",
  opportunities_89: 'Diversificazione dei rischi di perdita dei clienti',
  opportunities_90: 'Ottimizzazione dei processi tecnologici',
  opportunities_91: 'Esperienza internazionale',
  opportunities_92: 'Più vendite ai clienti attuali',
  main_67:
    "L'analisi dei prezzi della concorrenza ottimizza le offerte, incrementa i profitti e aumenta la quota di mercato sfruttando i dati sulla disponibilità a pagare dei consumatori per identificare le caratteristiche principali",
  main_68:
    'Assicuratevi che i vostri punti vendita siano in linea con gli standard del marchio, valutando gli attributi fisici e il loro impatto sulla percezione dei clienti',
  main_69: 'Soluzione CX',
  main_70:
    'Scoprite la nostra soluzione unica di CX a 360 gradi: personalizziamo strumenti e servizi in base ai vostri obiettivi per guidare il vostro business',
  main_71: 'Analisi del parlato',
  main_72:
    "Il nostro strumento avanzato di analisi vocale trascrive il linguaggio parlato in testo, fornendo preziose informazioni alle aziende per migliorare l'esperienza dei clienti, monitorare le prestazioni, garantire la conformità e guidare la crescita",
  banner_1: 'Consulenza e servizi СX una visione a 360° del vostro business',
  banner_2: 'Rimanere aggiornati sulle ultime ricerche!',
  banner_3:
    "Acconsento al trattamento dei dati personali secondo l'Informativa sulla Privacy",
  banner_4: '*Selezionare il mercato',
  banner_5: 'Grazie per esservi iscritti!',
  banner_6: 'Condivideremo con voi solo i contenuti più interessanti!',
  banner_7: 'Invia',
  banner_8: 'Ucraino',
  banner_9: 'Kazakistan',
  brand_awareness_1: 'Consapevolezza del marchio',
  brand_awareness_2: 'e uso',
  brand_awareness_3:
    "Sbloccare il potenziale della consapevolezza e dell'utilizzo del vostro marchio per il successo aziendale",
  brand_awareness_4: 'Parlare con un esperto',
  brand_awareness_5: 'Circa',
  brand_awareness_6:
    '<b>Valutare il grado di</b> riconoscimento e familiarità del vostro marchio',
  brand_awareness_7:
    '<b>Determinare il livello del marchio </b> consapevolezza nel vostro mercato di riferimento',
  brand_awareness_8:
    '<b>Tenere sotto controllo</b> i tassi di fidelizzazione e di fedeltà',
  brand_awareness_9:
    '<b>Selezionare la strategia ottimale</b> per migliorare gli indicatori necessari',
  brand_awareness_10: 'Esplorate numerose opportunità con le nostre soluzioni',
  brand_awareness_11: 'Consapevolezza del marchio',
  brand_awareness_12: 'Uso del marchio',
  brand_awareness_13:
    '<b>Scoprire</b> il riconoscimento del proprio marchio tra i segmenti di consumatori',
  brand_awareness_14:
    '<b>Monitorare</b> il livello di consapevolezza del proprio pubblico',
  brand_awareness_15:
    '<b>Studiare</b> i pensieri spontanei dei consumatori in procinto di effettuare un acquisto',
  brand_awareness_16:
    '<b>Comprendere</b> le fonti primarie che contribuiscono alla notorietà del vostro marchio',
  brand_awareness_17:
    '<b>Considerare</b> il numero di consumatori che hanno sperimentato i prodotti o i servizi del vostro marchio',
  brand_awareness_18:
    '<b>Identificate</b> i consumatori che sono rimasti fedeli al vostro marchio dopo averlo provato inizialmente',
  brand_awareness_19:
    '<b>Sbloccare</b> la probabilità che il vostro marchio venga raccomandato',
  brand_awareness_20:
    '<b>Scoprire</b> la percentuale di consumatori che considerano il vostro marchio per un uso futuro',
  brand_awareness_21:
    "Chi beneficia della consapevolezza e dell'utilizzo del marchio?",
  brand_awareness_22: 'Aziende che lanciano un nuovo prodotto',
  brand_awareness_23: 'Imprese che rivitalizzano un marchio consolidato',
  brand_awareness_24:
    'Qualsiasi azienda che voglia capire e migliorare il modo in cui il proprio marchio viene riconosciuto e utilizzato dai consumatori',
  brand_awareness_25:
    "Le nostre metodologie forniscono informazioni preziose per ottimizzare la consapevolezza e l'utilizzo del marchio",
  brand_awareness_26:
    'La metodologia viene selezionata individualmente in base alla richiesta del cliente',
  brand_awareness_27: "Metodologia dell'imbuto di utilizzo del marchio",
  brand_awareness_28:
    'Il tasso di prova indica la percentuale di pubblico che conosce il marchio e che ha provato i suoi prodotti o servizi.',
  brand_awareness_29:
    'Il tasso di ripetizione indica la percentuale di pubblico che conosce il marchio e ha provato i suoi prodotti o servizi e che ha continuato a fare acquisti o a utilizzare le offerte negli ultimi 12 mesi.',
  brand_awareness_30:
    'Il tasso di fidelizzazione rivela la percentuale di individui che utilizzano costantemente i prodotti o i servizi del marchio, indicando la percentuale di persone che si sono impegnate con queste offerte negli ultimi 6 mesi.',
  brand_awareness_31:
    'Il tasso di fidelizzazione indica la percentuale di clienti che, avendo utilizzato i prodotti/servizi del marchio negli ultimi 6 mesi, continuano a scegliere e a dimostrare fedeltà al marchio nel tempo.',
  brand_awareness_32: 'Metodologia di brandawareness',
  brand_awareness_33:
    "Top of mind (TOM) rappresenta il primo marchio citato spontaneamente dall'intervistato.",
  brand_awareness_34:
    'La consapevolezza spontanea totale rappresenta il totale delle menzioni spontanee, compreso il TOM.',
  brand_awareness_35:
    "L'Aided Awareness rivela il grado di riconoscimento del vostro marchio da parte degli utenti quando viene loro richiesto.",
  brand_awareness_36: 'Mappa delle prestazioni degli attributi del marchio',
  brand_awareness_37:
    "Questo strumento valuta e rappresenta visivamente l'importanza dei diversi attributi del marchio, fornendo indicazioni su come ciascuno di essi contribuisca alla performance complessiva del vostro marchio e alla percezione dei consumatori.",
  brand_awareness_38:
    "Scoprite le chiavi per migliorare l'impatto del vostro marchio con la nostra analisi completa.",
  brand_awareness_39: 'Mappa percettiva dei marchi',
  brand_awareness_40:
    'Questo strumento offre una rappresentazione visiva delle percezioni dei consumatori in un panorama di mercato competitivo. Questo approccio è fondamentale per delineare chiaramente le differenze tra i marchi, enfatizzando gli attributi cruciali del mercato.',
  brand_awareness_41:
    "Semplifica l'osservazione e l'interpretazione delle relazioni tra i marchi e le caratteristiche che li contraddistinguono.",
  brand_awareness_42:
    "La vicinanza di un marchio specifico a determinati attributi indica la sua forte associazione con quelle caratteristiche. Allo stesso modo, la vicinanza tra due marchi indica le loro somiglianze, mostrando immagini condivise e, di conseguenza, un'appartenenza allo stesso segmento di mercato.",
  consumer_journey_map_1: 'Mappa del percorso del consumatore',
  consumer_journey_map_2:
    'Scoprite e superate le barriere nella vostra attività con la nostra Consumer Journey Map',
  consumer_journey_map_3: 'FIDATI DA',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Sfruttare il potenziale della CJM per ottimizzare le interazioni tra i consumatori e il vostro marchio',
  consumer_journey_map_6:
    'Determinare quali sono i passaggi che il consumatore compie prima di acquistare un prodotto',
  consumer_journey_map_7:
    'Ottenere informazioni dai vostri clienti reali, non da quelli immaginari',
  consumer_journey_map_8:
    'Ottenere una comprensione completa delle esperienze dei clienti',
  consumer_journey_map_9:
    'Identificare le opportunità mancate e fare benchmarking con la concorrenza',
  consumer_journey_map_10: 'Scoprite il nostro approccio CJM',
  consumer_journey_map_11:
    "Creiamo un percorso di interazione del consumatore con un prodotto, un servizio, un'azienda o un marchio attraverso vari canali e per un certo periodo di tempo.",
  consumer_journey_map_12:
    'Descriviamo le fasi che i vostri consumatori attraversano prima di completare un acquisto, le aspettative, le emozioni e le motivazioni del cliente in ciascuna fase.',
  consumer_journey_map_13: 'Approfittate di noi',
  consumer_journey_map_14:
    'Mettiamo a disposizione un team di professionisti esperti che conoscono le complessità del successo commerciale globale',
  consumer_journey_map_15:
    'Ci impegniamo a fornire risultati che superino le vostre aspettative',
  consumer_journey_map_16:
    'Scopriamo soluzioni complesse che contribuiscono alla vostra crescita',
  consumer_journey_map_17:
    'Siamo specializzati nella fornitura di soluzioni CJM personalizzate',
  consumer_journey_map_18: 'Prenota una consulenza',
  consumer_journey_map_19: 'Decisioni basate sui dati',
  consumer_journey_map_20:
    'Basate le vostre decisioni su dati reali piuttosto che su supposizioni',
  consumer_journey_map_21: 'Ottimizzate il vostro CJM',
  consumer_journey_map_22:
    'Costruire relazioni più solide con i clienti rispondendo alle loro esigenze e aspettative in ogni fase del loro percorso',
  consumer_journey_map_23: 'Fare strada',
  consumer_journey_map_24:
    'Confrontatevi con i vostri concorrenti e rimanete al passo con il mercato',
  consumer_journey_map_25: 'Raccomandazioni',
  consumer_journey_map_26:
    'Implementare le nostre raccomandazioni CJM per sbloccare il successo, promuovere la fedeltà dei clienti, guidare le vendite e massimizzare il potenziale della vostra azienda',
  consumer_journey_map_27:
    'Scoprite il vero viaggio del consumatore con la nostra Consumer Journey Map',
  consumer_journey_map_28:
    'Esperti con più di 20 anni di esperienza offrono una ricerca completa',
  consumer_journey_map_29:
    "La ricerca qualitativa prevede la raccolta e l'analisi di dati non numerici per cogliere concetti, opinioni o esperienze.",
  consumer_journey_map_30:
    "I metodi di ricerca quantitativi prevedono la misurazione oggettiva e l'analisi numerica dei dati ottenuti attraverso sondaggi, questionari e inchieste.",
  consumer_journey_map_31:
    'Dopo aver raccolto i dati, gli esperti analizzano le informazioni e traggono le conclusioni per fornirvi le migliori raccomandazioni.',
  boarding_1: 'Mappa del percorso del cliente',
  boarding_2:
    "La mappatura del percorso del cliente (CJM) consiste nel definire un pubblico target, comprendere le sue esigenze e il suo ambiente e quindi creare un piano per offrire un'esperienza coinvolgente",
  boarding_3: 'Punteggio netto di promozione',
  boarding_4:
    "Il Net Promoter Score (NPS) è il gold standard delle metriche di customer experience, basato su una domanda: Con quale probabilità consiglieresti l'organizzazione/prodotto/servizio?",
  boarding_5: 'Monitoraggio della salute del marchio',
  boarding_6:
    "La ricerca BHT (Brand Health Tracking) può misurare l'andamento del vostro marchio in termini di consapevolezza e utilizzo, posizionamento del marchio e performance del marchio",
  boarding_7: 'Ricerca sulle risorse umane',
  boarding_8:
    "La ricerca sulle risorse umane viene utilizzata per valutare le pratiche e le prestazioni delle risorse umane e offre un'analisi dettagliata dello sviluppo e della gestione attuali",
  boarding_9: 'Test UX',
  boarding_10:
    "I test di usabilità (UX) sono il modo in cui le persone reali interagiscono con un sito web, un'applicazione o un altro prodotto e osservano il loro comportamento e le loro reazioni",
  boarding_11: 'Indice di soddisfazione del cliente',
  boarding_12:
    "La soddisfazione del cliente misura la misura in cui le sue aspettative sono state soddisfatte in merito all'acquisto di un prodotto o di un servizio fornito dalla vostra azienda",
  boarding_13: 'Test degli annunci',
  boarding_14:
    'Una campagna pubblicitaria è un investimento enorme. Assicuratevi che i vostri annunci siano potenti e garantiscano il massimo ROI',
  boarding_15: 'Audit sul campo/Audit al dettaglio',
  boarding_16:
    'Raccogliere dati dettagliati sul vostro negozio o marchio, analizzare gli ostacoli alla crescita e creare strategie efficaci per superarli',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Software di gestione delle prestazioni. Applicazione gamificata per aumentare le vendite',
  boarding_19: 'Voicer',
  boarding_20:
    'Sistema di gestione dei feedback. La voce dei vostri clienti in un unico sistema. Sistema di gestione dei feedback dei clienti',
  boarding_21: 'Ricerca qualitativa',
  boarding_22:
    'La ricerca qualitativa consiste nel raccogliere e analizzare dati non numerici per comprendere concetti, opinioni o esperienze',
  boarding_23: 'Ricerca quantitativa',
  boarding_24:
    "I metodi di ricerca quantitativi sono la misurazione oggettiva e l'analisi numerica dei dati raccolti attraverso sondaggi, questionari e indagini",
  boarding_25: 'Mystery Shopping',
  boarding_26:
    "Il mystery shopping è una tecnica di ricerca di mercato per misurare e monitorare le interazioni tra un cliente e un'azienda o un'organizzazione durante uno scenario predefinito",
  boarding_27: 'Wantent',
  boarding_28:
    "Una piattaforma di intelligence dei contenuti alimentata dall'intelligenza artificiale che fornisce approfondimenti sul pubblico",
  boarding_29: 'Monitoraggio dei prezzi ',
  boarding_30:
    'Il monitoraggio dei prezzi è il processo di raccolta, elaborazione e analisi dei prezzi dei concorrenti',
  boarding_31: 'Design del servizio',
  boarding_32:
    'La progettazione del servizio consiste nel comprendere le esigenze e i desideri delle persone che utilizzeranno un prodotto o un servizio trascorrendo del tempo con loro',
  boarding_33: "Analisi dei prezzi dell'industria automobilistica",
  boarding_34:
    "L'analisi dei prezzi della concorrenza ottimizza le offerte, incrementa i profitti e aumenta la quota di mercato sfruttando i dati sulla disponibilità a pagare dei consumatori per identificare le caratteristiche principali",
  boarding_35: 'Audit di controllo della struttura',
  boarding_36:
    'Assicuratevi che i vostri punti vendita siano in linea con gli standard del marchio, valutando gli attributi fisici e il loro impatto sulla percezione dei clienti',
  boarding_37: 'Soluzione CX',
  boarding_38:
    'Scoprite la nostra soluzione unica di CX a 360 gradi: personalizziamo strumenti e servizi in base ai vostri obiettivi per guidare il vostro business',
  boarding_39: 'Analisi del parlato',
  boarding_40:
    "Il nostro strumento avanzato di analisi vocale trascrive il linguaggio parlato in testo, fornendo preziose informazioni alle aziende per migliorare l'esperienza dei clienti, monitorare le prestazioni, garantire la conformità e guidare la crescita",
  boarding_41: 'Uso e atteggiamento',
  boarding_42:
    'Usage and Attitude è il nostro approccio per capire come il pubblico si impegna con il vostro marchio analizzando i modelli di utilizzo pratico e gli atteggiamenti psicologici, consentendo strategie di marketing e di prodotto su misura',
  boarding_43: 'Consapevolezza e utilizzo del marchio',
  boarding_44:
    "La consapevolezza e l'utilizzo del marchio comportano la valutazione del riconoscimento, della familiarità, della frequenza di interazione e della fidelizzazione all'interno del mercato di riferimento per migliorare la visibilità e il coinvolgimento del marchio",
  boarding_45: 'Identità e posizionamento del marchio',
  boarding_46:
    "L'identità e il posizionamento del marchio sono un'analisi completa che scopre le percezioni dei consumatori, i diversi punti di vista del pubblico e le intuizioni su come viene percepito il vostro marchio",
  boarding_47: 'Profilazione e segmentazione dei clienti',
  boarding_48:
    'La profilazione e la segmentazione dei clienti comporta la suddivisione del mercato di riferimento in gruppi distinti sulla base di caratteristiche condivise, consentendo la creazione di strategie personalizzate in grado di entrare in risonanza con segmenti di pubblico specifici',
  boarding_49: 'Mappa del percorso del consumatore',
  boarding_50:
    'Una mappa del viaggio del consumatore è una rappresentazione visiva delle fasi, dei punti di contatto, delle emozioni e delle motivazioni provate da un consumatore durante la sua interazione con il vostro prodotto o marchio',
  boarding_51:
    "Imparate a conoscere le tecniche innovative che sono alla base del nostro impegno a fornire approfondimenti praticabili e a ottenere risultati d'impatto per la vostra azienda",
  boarding_52:
    "Esplorate la nostra suite di prodotti con una ricchezza di informazioni guidate dai dati e personalizzate per il vostro settore, che vi consentono di prendere decisioni informate e di essere sempre all'avanguardia",
  boarding_53:
    'Approfondite le tendenze del mercato, il comportamento dei consumatori e i paesaggi competitivi con le nostre soluzioni complete per le ricerche di mercato',
  boarding_54: 'Ricerca ad hoc',
  boarding_55:
    'La ricerca ad hoc è un approccio personalizzato progettato per affrontare un problema specifico, utilizzando un mix di metodologie esistenti o un nuovo approccio per creare una soluzione su misura.',
  boarding_56: 'Ricerca sociologica',
  boarding_57:
    'La ricerca sociologica scopre i modelli di comportamento umano e le interazioni sociali, fornendo preziose indicazioni per guidare strategie innovative e creare connessioni significative con il pubblico di riferimento.',
  not_found_1: 'Ops! Non riusciamo a trovare quella pagina.',
  not_found_2: "Perché non dare un'occhiata ai nostri prodotti?",
  not_found_3: 'Book consultancy',
  customer_profiling_segmentation_1: 'Profilazione e segmentazione dei clienti',
  customer_profiling_segmentation_2:
    'Vi aiuteremo a individuare il vostro pubblico di riferimento, rendendo la vostra strategia più efficace',
  customer_profiling_segmentation_3:
    'Sfruttate il potere della segmentazione del mercato per garantire che i vostri messaggi raggiungano sempre efficacemente i gruppi di consumatori target',
  customer_profiling_segmentation_4: 'Capire i consumatori più a fondo',
  customer_profiling_segmentation_5:
    'Trovare il miglior pubblico di riferimento per il vostro prodotto',
  customer_profiling_segmentation_6: 'Esplorare nuovi segmenti per attrarre',
  customer_profiling_segmentation_7: "Scoprire gli ostacoli all'acquisto",
  customer_profiling_segmentation_8:
    "Siamo specializzati nell'offerta di soluzioni di segmentazione personalizzate",
  customer_profiling_segmentation_9: 'Un approccio unico',
  customer_profiling_segmentation_10:
    'Provate una soluzione di segmentazione unica e personalizzata, creata appositamente per le vostre esigenze',
  customer_profiling_segmentation_11: 'Personaggi target',
  customer_profiling_segmentation_12:
    "Personaggi target creati sulla base di un'analisi di mercato approfondita e di una ricerca completa",
  customer_profiling_segmentation_13: 'Nuovi segmenti',
  customer_profiling_segmentation_14:
    'Identificate i segmenti di pubblico più preziosi e sbloccate i vantaggi del targeting personalizzato',
  customer_profiling_segmentation_15: 'Raccomandazioni',
  customer_profiling_segmentation_16:
    'Mettete in pratica i nostri consigli e sbloccate il successo, promuovete la fedeltà dei clienti, incrementate le vendite e massimizzate il vostro potenziale aziendale',
  customer_profiling_segmentation_17:
    'Esperti con più di 20 anni di esperienza forniscono una ricerca completa',
  customer_profiling_segmentation_18:
    "La ricerca qualitativa prevede la raccolta e l'analisi di dati non numerici per cogliere concetti, opinioni o esperienze.",
  customer_profiling_segmentation_19:
    "I metodi di ricerca quantitativi prevedono la misurazione oggettiva e l'analisi numerica dei dati ottenuti attraverso sondaggi, questionari e inchieste.",
  customer_profiling_segmentation_20:
    'Dopo aver raccolto i dati, gli esperti analizzano le informazioni e traggono le conclusioni per fornirvi le migliori raccomandazioni.',
  customer_profiling_segmentation_21:
    "Non ci limitiamo a prendere le misure, ma vi forniamo anche un piano d'azione con azioni chiare e graduali",
  customer_profiling_segmentation_22: 'La nostra metodologia',
  customer_profiling_segmentation_23: 'Indice di Jaccard',
  customer_profiling_segmentation_24:
    "Quando gli intervistati vengono interpellati direttamente, le loro risposte sulle preferenze dell'azienda possono non corrispondere al loro comportamento effettivo. Al posto delle domande dirette, utilizziamo l'analisi statistica di Jaccard per capire la relazione tra gli attributi dell'azienda e le scelte dei consumatori.",
  customer_profiling_segmentation_25: 'Drivers',
  customer_profiling_segmentation_26:
    'Identifichiamo i driver (fattori che influenzano un determinato risultato) e li classifichiamo in driver chiave, medi e non importanti in base alla loro importanza.',
  customer_profiling_segmentation_27: 'Segmentazione',
  customer_profiling_segmentation_28:
    'Si ottiene una segmentazione con caratteristiche chiare delle scelte dei clienti.',
  customer_profiling_segmentation_29: 'Il nostro approccio alla segmentazione',
  customer_profiling_segmentation_30: 'Demografico',
  customer_profiling_segmentation_31: 'età',
  customer_profiling_segmentation_32: 'gender',
  customer_profiling_segmentation_33: 'reddito',
  customer_profiling_segmentation_34:
    "Aiuta ad adattare prodotti, servizi e marketing a gruppi specifici, migliorando la rilevanza e l'efficacia",
  customer_profiling_segmentation_35: 'Psicografico',
  customer_profiling_segmentation_36: 'stile di vita',
  customer_profiling_segmentation_37: 'valori',
  customer_profiling_segmentation_38: 'comportamenti',
  customer_profiling_segmentation_39:
    "Consente un marketing personalizzato, connessioni più profonde con i clienti e un aumento della fedeltà e delle vendite per l'azienda",
  customer_profiling_segmentation_40: 'Comportamento',
  customer_profiling_segmentation_41: 'azioni',
  customer_profiling_segmentation_42: 'motivazione',
  customer_profiling_segmentation_43: 'frequenza di acquisto',
  customer_profiling_segmentation_44:
    "Consente un marketing mirato, una maggiore soddisfazione dei clienti, una maggiore fidelizzazione e una maggiore redditività per l'azienda",
  customer_profiling_segmentation_45: 'Geografico',
  customer_profiling_segmentation_46: 'posizione',
  customer_profiling_segmentation_47:
    " Consente un marketing mirato, prodotti su misura, una distribuzione efficiente e una maggiore penetrazione del mercato per l'azienda.",
  brands_identity_1: 'Identità e posizionamento del marchio',
  brands_identity_2:
    'Trasformate il vostro marchio con la nostra analisi approfondita, che rivela le percezioni dei consumatori e affina la vostra strategia di successo',
  brands_identity_3: 'Parlare con un esperto',
  brands_identity_4:
    "<b>Scoprite un'analisi completa</b> dell'identità del marchio",
  brands_identity_5:
    '<b>Esplorare gli insight</b> raccolti sulle percezioni dei consumatori',
  brands_identity_6:
    '<b>Comprendere i diversi</b> punti di vista del pubblico di riferimento',
  brands_identity_7:
    '<b>Ottenere informazioni sul</b> comportamento, le abitudini e le preferenze dei consumatori per prendere decisioni informate',
  brands_identity_8:
    '4Servizi esperti con oltre 20 anni di esperienza con i clienti creano soluzioni uniche che aiutano a far crescere la vostra attività',
  brands_identity_9:
    '<b>Avrete accesso a un pacchetto di ricerca dotato di tutti i dati essenziali.</b> Serve come strumento diagnostico, evidenziando le aree di forza e quelle che potrebbero richiedere attenzione',
  brands_identity_10:
    '<b>I nostri servizi forniscono alle aziende gli strumenti necessari</b> per prendere decisioni informate, affinare le strategie e guidare il successo nei rispettivi settori.',
  brands_identity_11:
    'Per migliorare la posizione del vostro marchio nel mercato competitivo',
  brands_identity_12:
    'Imprese che cercano di migliorare la propria presenza sul mercato',
  brands_identity_13: 'Le aziende che cercano di elevare la propria strategia',
  brands_identity_14: 'Identità del marchio',
  brands_identity_15: 'Posizionamento del marchio',
  brands_identity_16:
    '<b>Scoprite la percezione del vostro marchio</b> attraverso i segmenti di consumo.',
  brands_identity_17:
    '<b>Identificare le qualità attribuite</b> al vostro marchio e ai concorrenti.',
  brands_identity_18:
    '<b>Valutare i punti di forza</b> e di debolezza per un miglioramento strategico.',
  brands_identity_19:
    "<b>Valutare l'allineamento</b> della vostra immagine di marca con i valori promossi.",
  brands_identity_20:
    "<b>Valutare l'affidabilità percepita</b> del vostro marchio.",
  brands_identity_21:
    '<b>Esplorare la percezione archetipica</b> e simbolica del vostro marchio.',
  brands_identity_22:
    '<b>Identificare i punti</b> di vendita unici del marchio e i fattori di differenziazione rispetto alla concorrenza.',
  brands_identity_23:
    '<b>Individuare gli attributi chiave che influenzano</b> la probabilità che i consumatori acquistino i vostri prodotti/servizi.',
  brands_identity_24:
    '<b>Determinare come il vostro marchio è attualmente posizionato</b> nella mente dei consumatori e identificare gli aspetti da comunicare per attirare i segmenti di mercato desiderati.',
  brands_identity_25:
    'La metodologia viene selezionata individualmente in base alla richiesta del cliente',
  brands_identity_26: 'Consapevolezza e utilizzo del marchio',
  brands_identity_27:
    'La valutazione del livello del marchio implica la misurazione dei livelli di conoscenza e di consumo, che costituiscono una componente fondamentale della valutazione.',
  brands_identity_28: 'Consegna del marchio e fiducia',
  brands_identity_29:
    "Questa metrica misura l'advocacy (raccomandazione) dei clienti e la capacità costante del marchio di mantenere le promesse, creare esperienze positive per i clienti e costruire la fiducia.",
  brands_identity_30: "Prestazioni dell'immagine del marchio",
  brands_identity_31:
    "La valutazione della performance della marca implica la valutazione della sua associazione a sei valori cruciali (3 razionali e 3 emotivi), della sua distinzione all'interno della categoria e della sua capacità di infondere orgoglio nei consumatori.",
  brands_identity_32: 'Punteggio di salute del marchio',
  brands_identity_33:
    'Il Brand Health Score vi mostra una comprensione completa di come il vostro marchio si sta comportando nel panorama competitivo.',
  sociological_research_1: 'Ricerca sociologica',
  sociological_research_2:
    "Conduciamo analisi approfondite dei fenomeni e dei processi sociali, che aiutano a prendere decisioni informate con l'aiuto di risultati analitici e raccomandazioni uniche",
  sociological_research_3: 'Contattateci',
  sociological_research_4: 'I nostri clienti',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    "CATI - è un metodo di raccolta dati che utilizza un computer per condurre sondaggi al telefono. Il software CATI consente di automatizzare i processi di raccolta dati, inserimento delle risposte e gestione del sondaggio. L'uso di CATI facilita il controllo del sondaggio, consente di analizzare ed elaborare rapidamente i dati ricevuti e garantisce un elevato livello di standardizzazione del sondaggio.",
  sociological_research_7: 'СATI',
  sociological_research_8:
    "CAWI - è un metodo di raccolta dati in cui gli intervistati compilano questionari o rispondono a domande tramite un'interfaccia web, e i dati vengono raccolti ed elaborati automaticamente tramite un software specializzato. Il metodo CAWI consente indagini su larga scala, una rapida raccolta e analisi dei dati.",
  sociological_research_9: 'CAPI',
  sociological_research_10:
    "CAPI - è un metodo di raccolta dei dati per le indagini faccia a faccia in cui il ricercatore utilizza un tablet o un telefono cellulare per inserire le risposte degli intervistati alle domande, il che consente di automatizzare la raccolta dei dati, riducendo gli errori di inserimento e semplificando l'analisi dei risultati.",
  sociological_research_11:
    'I nostri esperti utilizzano una serie di strumenti',
  sociological_research_12: 'Campione',
  sociological_research_13:
    'Calcoliamo e applichiamo campioni di qualsiasi complessità',
  sociological_research_14:
    'Nel nostro lavoro utilizziamo campioni semplici, sistematici, stratificati e a grappolo',
  sociological_research_15:
    'Per le indagini faccia a faccia rappresentative a livello nazionale, utilizziamo procedure di campionamento multistadio stratificato',
  sociological_research_16:
    'La selezione degli insediamenti per regione viene effettuata in base alle dimensioni e al tipo di insediamento, il cui numero è determinato in proporzione al loro volume in ogni regione',
  sociological_research_17: 'Realizziamo',
  sociological_research_18: "Ricerca sull'opinione pubblica",
  sociological_research_19: 'Ricerca socioeconomica',
  sociological_research_20: 'Ricerca a tavolino',
  sociological_research_21: 'Valutazione della qualità dei servizi sociali',
  sociological_research_22: 'Studio di caso',
  sociological_research_23: 'Ricerca sul personale',
  sociological_research_24:
    'Ricerca su pubblici specifici (gruppi vulnerabili, compresi gli sfollati interni, militari, giovani, ecc.)',
  sociological_research_25: 'Lavoriamo con ogni cliente individualmente',
  sociological_research_26: 'I nostri clienti:',
  sociological_research_27: 'Fondazioni di beneficenza',
  sociological_research_28: 'Organizzazioni pubbliche e sindacati',
  sociological_research_29: 'Strutture statali',
  sociological_research_30: 'Il nostro approccio al lavoro',
  sociological_research_31:
    'Utilizziamo un approccio scientifico al nostro lavoro e una profonda comprensione della realtà attuale, oltre a vari metodi di ricerca per raccogliere i dati.',
  sociological_research_32:
    'Ci avvaliamo di esperti altamente specializzati con la giusta esperienza per rispondere a una richiesta specifica e disponiamo di un pool di esperti provenienti da diverse aree.',
  sociological_research_33:
    'Il nostro reparto di analisi interno analizza i dati raccolti utilizzando una serie di strumenti unici a seconda delle specificità del compito.',
  sociological_research_34:
    'Generiamo rapporti con risultati e raccomandazioni dettagliate, tenendo conto delle esigenze e delle aspettative del vostro pubblico di riferimento.',
  sociological_research_35:
    "Forniamo consulenza e supporto durante l'implementazione delle raccomandazioni, aiutandovi ad apportare cambiamenti positivi.",
  sociological_research_36: 'La nostra ricerca sulla TV',
  sociological_research_37: 'Scopri i nostri rapporti',
  sociological_research_38:
    "Il nostro approccio scientifico al lavoro garantisce ai nostri clienti dati sociologici accurati e affidabili in tutta l'Ucraina e in Europa, con errori minimi",
  sociological_research_39:
    'Lavoriamo nel rispetto di tutti i principi e gli standard etici e delle regole per la conduzione della ricerca sociologica.',
  sociological_research_40:
    "Aderiamo al Codice di etica professionale per i sociologi dell'Associazione sociologica dell'Ucraina e alla Dichiarazione di Helsinki sui principi etici per la ricerca. Membri di ESOMAR, dell'Associazione ucraina di marketing e dell'Associazione ucraina dei centri di contatto.",
  sociological_research_41: 'Campo',
  sociological_research_42: 'cruscotto online',
  sociological_research_43: 'intervistatori regolari',
  sociological_research_44: 'supervisori',
  sociological_research_45: 'Controllo qualità sul campo',
  sociological_research_46: 'Fissazione al 100% delle coordinate GPS (CAPI)',
  sociological_research_47:
    'Validazione dei questionari (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48:
    '100% di registrazione delle interviste (CAPI, CATI)',
  sociological_research_49: 'Utilizziamo software su licenza:',
  sociological_research_50:
    "SPSS  per l'analisi di dati statistici, l'elaborazione di matrici di dati",
  sociological_research_51:
    'DigSee Sure per CAPI: logica del questionario, geolocalizzazione, registrazione audio delle interviste, dimostrazione di materiali video',
  sociological_research_52:
    'SurveyMonkey per CAWI: logica del questionario, formato comodo, visualizzazione',
  sociological_research_53:
    'VoIPTime (contact center): programmazione di questionari di qualsiasi complessità, trasferimento di registrazioni audio al cliente',
  sociological_research_54: 'Campione',
  sociological_research_55:
    'Calcoliamo e applichiamo campioni di qualsiasi complessità',
  sociological_research_56:
    'Nel nostro lavoro utilizziamo campioni semplici, sistematici, stratificati e a grappolo',
  sociological_research_57:
    'Per le indagini faccia a faccia rappresentative a livello nazionale, utilizziamo procedure di campionamento multistadio stratificato',
  sociological_research_58:
    'La selezione degli insediamenti per regione viene effettuata in base alle dimensioni e al tipo di insediamento, il cui numero è determinato in proporzione al loro volume in ogni regione',
  sociological_research_59: 'Il team',
  sociological_research_60:
    "I nostri sociologi sono membri dell'Associazione dei sociologi dell'Ucraina",
  sociological_research_61:
    'Abbiamo 12 analisti a tempo pieno con esperienza in progetti quantitativi e qualitativi in vari settori.',
  sociological_research_62:
    'Abbiamo un forte team analitico di esperti con oltre 10 anni di esperienza nella ricerca. Abbiamo scienziati, dottori di ricerca, MBI.',
  sociological_research_63: 'Irina Maksimyuk',
  sociological_research_64:
    'Responsabile del dipartimento di ricerca. Oltre 15 anni di esperienza nella ricerca di marketing, nella gestione della ricerca in tutte le sue fasi, nella preparazione di rapporti analitici e di presentazioni',
  sociological_research_65: 'Andrii Biloskurskyi',
  sociological_research_66:
    "Responsabile della programmazione e dell'elaborazione dei dati. 20 anni di esperienza nel marketing, nelle ricerche sociologiche e di mercato",
  sociological_research_67: 'Elena Somova',
  sociological_research_68:
    'Specialista in programmazione Studente post-laurea in sociologia presso la DonSUU. 5 anni di esperienza in ricerche di marketing Membro del consiglio direttivo di Alleanza Democratica delle Donne, responsabile della raccolta fondi.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    "Responsabile della Divisione di Ricerca Sociologica del Dipartimento di Ricerca, ha 13 anni di esperienza in progetti sociologici e sociali a livello internazionale e nazionale.  Dottore di ricerca in Sociologia, responsabile del Comitato di ricerca dell'Associazione ucraina dei Centri di contatto dell'Ucraina.",
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Scoprite la nostra ricerca alternativa per casi unici',
  ad_hoc_3: 'Contattateci',
  ad_hoc_4: 'Il nostro approccio',
  ad_hoc_5:
    'Il nostro team di esperti analizza meticolosamente i dati raccolti, fornendovi soluzioni complesse che contribuiscono alla vostra crescita.',
  ad_hoc_6:
    'Progettiamo una ricerca unica su misura per le vostre esigenze specifiche, attingendo a una gamma diversificata di metodologie, tra cui sondaggi, interviste, focus group, analisi dei dati e molto altro.',
  ad_hoc_7: 'Perché scegliere Ad Hoc Research?',
  ad_hoc_8: 'Flessibilità',
  ad_hoc_9:
    "Non esistono due progetti di ricerca uguali. Con Ad Hoc Research, abbiamo la Flessibilità di adattare ed evolvere l'approccio unico in base alle necessità, assicurando che i vostri approfondimenti rimangano rilevanti e utilizzabili.",
  ad_hoc_10: 'Precisione',
  ad_hoc_11:
    'Adattando le nostre metodologie alle vostre esigenze specifiche, forniamo risultati accurati e mirati che forniscono informazioni preziose.',
  ad_hoc_12: "L'innovazione",
  ad_hoc_13:
    "Nel nostro processo di ricerca abbracciamo l'innovazione e la creatività, esplorando nuove tecniche e approcci per scoprire opportunità e sfide nascoste.",
  ad_hoc_14: 'Tempestività',
  ad_hoc_15:
    "La ricerca ad hoc consente un'implementazione rapida, permettendovi di accedere a informazioni critiche in modo tempestivo, per essere sempre all'avanguardia nel vostro settore.",
  ad_hoc_16: 'La nostra esperienza',
  ad_hoc_17:
    '4Servizi esperti con oltre 20 anni di ricerche di mercato e di esperienza con i clienti creano soluzioni uniche che aiutano a far crescere la vostra attività',
  ad_hoc_18:
    'Utilizziamo una serie di strumenti distinti in base alle vostre esigenze specifiche',
  ad_hoc_19: 'Nessuna metodologia di lavoro per voi?',
  ad_hoc_20: 'Richiesta di ricerca ad hoc',
  ad_hoc_21: 'Su misura per un problema specifico',
  ad_hoc_22:
    'Utilizziamo sia un mix di diverse parti delle metodologie, sia un approccio completamente nuovo',
  ad_hoc_23: 'Approccio individuale',
  ad_hoc_24:
    'Personalizziamo con cura una soluzione che si adatta direttamente al vostro problema',
  ad_hoc_25: 'Andare oltre',
  ad_hoc_26:
    'Forniamo un piano attuabile con raccomandazioni per aiutare la vostra azienda a raggiungere gli obiettivi prefissati',
  ad_hoc_27: 'Avete un problema non standard',
  ad_hoc_28: 'Nessuna delle metodologie funziona per il vostro problema',
  ad_hoc_29:
    'Si desidera richiedere diverse metodologie o parti di esse contemporaneamente',
  ad_hoc_30: 'Chi beneficia della ricerca Ad Hoc?',
  career: 'Carriera',
  four_opinion_1: 'Sondaggio da 4Service',
  four_opinion_2: 'Partecipa ai sondaggi online a pagamento e guadagna senza uscire di casa',
  four_opinion_3: 'Inizia',
  four_opinion_4: 'I nostri vantaggi',
  four_opinion_5: 'Ci danno fiducia',
  four_opinion_6: 'La nostra comunità conta oltre 90 mila consumatori in Ucraina e 100 mila in Kazakistan',
  four_opinion_7: 'Valutiamo il tuo tempo',
  four_opinion_8: 'Apprezziamo il tuo tempo, quindi per la partecipazione ai sondaggi hai la possibilità di ricevere un regalo o una ricompensa in denaro',
  four_opinion_9: 'La tua opinione ha un grande impatto',
  four_opinion_10: 'Ogni tuo commento aiuta i brand a comprendere meglio le esigenze dei propri clienti',
  four_opinion_11: 'Partecipa ai sondaggi su 4Opinion di 4Service e guadagna online o vinci un premio',
  four_opinion_12: 'Unisciti a 4Opinion',
  four_opinion_13: 'Ancora più storie interessanti sul nostro canale',
  four_opinion_14: 'Scopri di più per l”Ucraina',
  four_opinion_15: 'Scopri di più per il Kazakistan',
  four_opinion_16: 'Perché scegliere noi?',
  four_opinion_17: '4Service è un holding internazionale di ricerca sulla gestione dell’esperienza del cliente',
  four_opinion_18: 'Siamo tra le prime 10 agenzie europee per la gestione dell’esperienza del cliente',
  four_opinion_19: 'La nostra missione è migliorare il servizio clienti in tutto il mondo!',
  four_opinion_20: 'Come iniziare a guadagnare con i sondaggi online?',
  four_opinion_21: 'Registrati sul sito',
  four_opinion_22: 'Ricevi notifiche sui sondaggi disponibili',
  four_opinion_23: 'Completa i sondaggi e guadagna',
  four_opinion_24: 'Recensioni reali dei nostri partecipanti',
  four_opinion_25: 'Vitaliy Kohan',
  four_opinion_26: '“Ho completato un sondaggio e me ne sono persino dimenticato. Dopo un po’ mi ha chiamato la signora Natalia per informarmi che avevo vinto un premio. Ed ecco il mio premio: Samsung Galaxy M52 5G. Grazie mille😁”',
  four_opinion_27: 'Viktoriya Popovska',
  four_opinion_28: '“Ho ricevuto lo speaker, grazie mille per un’estrazione onesta, equa e interessante.”',
  four_opinion_29: 'Yulia Oleksandrivna',
  four_opinion_30: '“Probabilmente la cosa più incredibile che mi sia successa di recente💕 Non solo ho vinto, ma anche un oggetto davvero fantastico. Mi aspettavo di tutto, ma non che sarebbe stato così straordinario. Ancora una volta, grazie di cuore”',
  four_opinion_31: 'Solo con noi hai la possibilità di guadagnare soldi veri o vincere un premio',
  four_opinion_32: 'Segui i sondaggi attuali sul nostro canale',
  four_opinion_33: 'Vai al canale',
};
