import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { DiaryStudyProvideStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import provide from '../../../assets/images/diaryStudy/provide.svg';

export const DiaryStudyProvide = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <DiaryStudyProvideStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_11')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_12')}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={provide} alt="Diary Study Provide" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_13')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_14')}</p>
          </div>
          <div className="cards-item">
            <img src={provide} alt="Diary Study Provide" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_15')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_16')}</p>
          </div>
          <div className="cards-item">
            <img src={provide} alt="Diary Study Provide" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_17')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_18')}</p>
          </div>
        </div>
      </div>

      <div className="btn-container">
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="transparent"
          className="btn"
          hoverBackground="#000"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'diary_study_5',
          )}
        />
      </div>
    </DiaryStudyProvideStyles>
  );
};
