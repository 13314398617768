import styled from 'styled-components';
import banner from '../../../assets/images/employeeExperience/banner.png';

export const ImpactEmployeeExperienceStyles = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .container {
    padding: 50px 10px !important;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 30px;

    @media (max-width: 768px) {
      padding: 30px 10px !important;
      gap: 30px;
    }

    h2 {
      font-weight: 600;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 870px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }

    h3 {
      font-weight: 500;
      font-size: 32px;
      line-height: 100%;
      color: #ffffff;
      max-width: 870px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    .btn {
      align-self: center;
      margin-top: 20px;
    }
  }

  .circles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      align-items: center;
      padding: 100px 25px;

      @media (max-width: 991px) {
        padding: 60px 25px;
      }

      @media (max-width: 768px) {
        padding: 50px 25px;
      }

      &::before {
        position: absolute;
        content: '';
        border: 1px solid rgba(0, 0, 255, 0.4);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 510px;
        height: 510px;

        @media (max-width: 991px) {
          width: 400px;
          height: 400px;
        }

        @media (max-width: 768px) {
          width: 300px;
          height: 300px;
        }
      }

      img {
        width: auto;
      }

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 115%;
        text-align: center;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    &-arrow {
      position: relative;
    }
  }
`;
