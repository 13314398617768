import styled from 'styled-components';

export const ShoppersFourServiceStyles = styled.div`
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0 auto;
      font-weight: 600;
      font-size: 40px;
      line-height: 115%;
      color: #ffffff !important;
      text-align: start;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 30px;
      list-style-type: disc;
      padding-left: 25px;

      li {
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #ffffff;
      }
    }

    img {
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 50px;
      max-width: 570px;
    }
  }
`;
