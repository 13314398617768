import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersSupportStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { useNavigate } from 'react-router-dom';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const ShoppersSupport = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const navigate = useNavigate();

  return (
    <ShoppersSupportStyles>
      <div className="container">
        <div className="texts">
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_70')}</p>
          <h2
            dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'shoppers_71',
              ),
            }}
          />
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            navigate(`/${interfaceLanguage}/support`);
          }}
          arrowColor="#000"
          textColor="#fff"
          borderColor="transparent"
          borderOnHover="#fff"
          background="linear-gradient(2.66deg, #0855E2 9.08%, #1076C7 90.92%)"
          className="btn"
          hoverBackground="#fff"
          hoverTextColor="#000"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'shoppers_82',
          )}
        />
      </div>
    </ShoppersSupportStyles>
  );
};
