import styled from 'styled-components';

export const CatiExpertsStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 991px) {
    gap: 25px;
    padding: 40x 0;
  }

  .container {
    position: relative;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cards {
    position: relative;
    width: 100%;
    background: radial-gradient(
        377.57% 719.48% at 377.57% -349.83%,
        #ba08a8 0%,
        rgba(186, 8, 168, 0) 100%
      ),
      #ffffff;
    border-radius: 25px;
    padding: 35px 5px;

    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px 10px;
    grid-template-areas:
      '. . . . . one one . . .'
      '. . two two three three four four five five'
      '. . . . six six seven seven . .'
      '. . eight eight nine nine ten ten . .'
      'eleven eleven twelve twelve thirteen thirteen . . . .';

    &-wrapper {
      border: 1px solid #9510b2;
      padding: 20px;
      border-radius: 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      width: 1190px;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 200px;
      /* width: 100%; */
      box-sizing: border-box;
      background: #9510b2;
      border-radius: 1000px;
      position: relative;

      &:nth-child(1) {
        grid-area: one;
      }
      &:nth-child(2) {
        grid-area: two;
      }
      &:nth-child(3) {
        grid-area: three;
      }
      &:nth-child(4) {
        grid-area: four;
      }
      &:nth-child(5) {
        grid-area: five;
      }
      &:nth-child(6) {
        grid-area: six;
      }
      &:nth-child(7) {
        grid-area: seven;
      }
      &:nth-child(8) {
        grid-area: eight;
      }
      &:nth-child(9) {
        grid-area: nine;
      }
      &:nth-child(10) {
        grid-area: ten;
      }
      &:nth-child(11) {
        grid-area: eleven;
      }
      &:nth-child(12) {
        grid-area: twelve;
      }
      &:nth-child(13) {
        grid-area: thirteen;
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 125%;
        color: #ffffff;
      }
    }

    &-arrow {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%);
      z-index: 5;
    }

    &-avatar {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
    }
  }
`;
