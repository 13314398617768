import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiQualityStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import ok from '../../../assets/images/cati/ok.svg';
import quality from '../../../assets/images/cati/quality.png';

export const CatiQuality = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiQualityStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cati_37')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_38')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_39')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_40')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_41')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_42')}</p>
            <img src={quality} alt="CATI quality" className="cards-img" />
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_43')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_44')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_45')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_46')}</p>
          </div>
          <div className="cards-item">
            <img src={ok} alt="CATI ok" />
            <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_47')}</h6>
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_48')}</p>
          </div>
        </div>
      </div>
    </CatiQualityStyles>
  );
};
