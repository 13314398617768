import React, { useMemo } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { MethodologyEmployeeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

type TCard = {
  title: string;
  text: string;
}

export const MethodologyEmployeeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const cards: TCard[] = useMemo(() => ([
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_8'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_9'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_10'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_11'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_12'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_13'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_14'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_15'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_16'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_17'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_18'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_19'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_20'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_21'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_22'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_23'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_24'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_25'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_26'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_27'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_28'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_29'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_30'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_31'),
    },
  ]), [interfaceLanguage]);

  return (
    <MethodologyEmployeeExperienceStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_7')}</h2>
        <div className="cards">
          {cards.map((el) => (
            <div key={el.title} className="cards-item">
              <h4>{el.title}</h4>
              <p>{el.text}</p>
            </div>
          ))}
        </div>
      </div>
    </MethodologyEmployeeExperienceStyles>
  );
};
