import React, { useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { DiaryStudyResearchStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import bht from '../../../assets/images/diaryStudy/bht.png';
import cs from '../../../assets/images/diaryStudy/cs.png';
import us from '../../../assets/images/diaryStudy/us.png';
import ut from '../../../assets/images/diaryStudy/ut.png';
import bp from '../../../assets/images/diaryStudy/bp.png';
import cjm from '../../../assets/images/diaryStudy/cjm.png';
import bmr from '../../../assets/images/diaryStudy/bmr.png';
import { useNavigate } from 'react-router-dom';
import ArrowRight from '../../../assets/icons/ArrowRight';

type TItem = {
  img: string;
  name: string;
  link?: string;
}

const items: TItem[] = [
  {
    name: 'Brand Health Tracking',
    img: bht,
    link: 'brand-health-tracking',
  },
  {
    name: 'Customer Satisfaction',
    img: cs,
    link: 'customer-satisfaction-index',
  },
  {
    name: 'User Segmentation',
    img: us,
    link: 'customer-profiling-and-segmentation',
  },
  {
    name: 'Usability Testing',
    img: ut,
    link: 'ux-testing',
  },
  {
    name: 'Behavior Patterns',
    img: bp,
  },
  {
    name: 'Client Journey Map',
    img: cjm,
    link: 'customer-journey-map',
  },
  {
    name: 'Brand Market Research',
    img: bmr,
    link: 'market-research',
  },
];

export const DiaryStudyResearch = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const navigate = useNavigate();

  const [active, setActive] = useState<TItem>(items[0]);

  const handleClick = (link?: string) => {
    if (link) {
      navigate(`/${interfaceLanguage}/${link}`);
    }
  };

  return (
    <DiaryStudyResearchStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_35')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_36')}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="content-wrapper">
          <div className="content-links">
            {items.map((e) => (
              <div
                onClick={() => handleClick(e.link)}
                onMouseEnter={() => setActive(e)}
                className={`content-link ${e.name === active.name ? 'active' : ''}`}
              >
                <span>{e.name}</span>
                {e.name === active.name && <ArrowRight color="#0f0e0c" />}
              </div>
            ))}
          </div>
          <div className="content-img">
            <h3>{active.name}</h3>
            <img src={active.img} alt={active.name} />
          </div>
        </div>
      </div>
    </DiaryStudyResearchStyles>
  );
};
