import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiBenefitStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import benefit1 from '../../../assets/images/cati/benefit-1.svg';
import benefit2 from '../../../assets/images/cati/benefit-2.svg';
import benefit3 from '../../../assets/images/cati/benefit-3.svg';
import benefit4 from '../../../assets/images/cati/benefit-4.svg';
import benefit5 from '../../../assets/images/cati/benefit-5.svg';
import benefit6 from '../../../assets/images/cati/benefit-6.svg';
import benefit7 from '../../../assets/images/cati/benefit-7.svg';
import benefit8 from '../../../assets/images/cati/benefit-8.svg';
import benefit9 from '../../../assets/images/cati/benefit-9.svg';

export const CatiBenefit = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiBenefitStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_27')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_6')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={benefit1} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_7',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit2} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_8',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit3} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_9',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit4} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_10',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit5} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_11',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit6} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_12',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit7} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_13',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit8} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_14',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit9} alt="CATI Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_15',
              ),
            }}
            />
          </div>
        </div>
      </div>
    </CatiBenefitStyles>
  );
};
