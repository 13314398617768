import styled from 'styled-components';

export const ShoppersTeamStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;
    position: relative;

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0 auto;
      font-weight: 600;
      font-size: 40px;
      line-height: 115%;
      color: #ffffff !important;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
  }

  .utils {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .cards {
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 25px;
    justify-content: space-around;
    flex-wrap: wrap;

    .flip-container {
      width: 270px;
      height: 300px;
      perspective: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.clicked .flipper {
        transform: rotateY(180deg);

        .front {
          z-index: -1;
        }
      }

      .flipper {
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        position: relative;
        text-align: center;

        .front,
        .back {
          width: 100%;
          height: 100%;
          position: absolute;
          backface-visibility: hidden;
          border: 2px solid black;
          border-radius: 8px;
          cursor: pointer;
          padding: 25px;
          box-sizing: border-box;
        }

        .front {
          background-color: lightblue;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          &::before {
            position: absolute;
            z-index: 2;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 8px;
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
              ),
              linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.9) 100%
              );
          }

          &::after {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border-radius: 8px;
            padding: 1px;
            background: linear-gradient(
              45deg,
              #8640f9 0%,
              #0029ff 50%,
              #002050 100%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            pointer-events: none;
          }

          img {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            left: 0;
            top: 0;
          }

          &-text {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
            z-index: 5;

            h6 {
              font-weight: 600;
              font-size: 22px;
              line-height: 125%;
              color: #ffffff;
              margin: 0;
              width: 100%;
              text-align: start;
            }

            p {
              font-weight: 700;
              font-size: 12px;
              line-height: 120%;
              color: #ffffff;
              margin: 0;
              width: 100%;
              text-align: start;
            }
          }
        }

        .back {
          background: linear-gradient(2.66deg, #0855e2 9.08%, #1076c7 90.92%);
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          transform: rotateY(180deg);

          p {
            font-weight: 700;
            font-size: 12px;
            line-height: 120%;
            color: #ffffff;
            margin: 0;
            width: 100%;
            text-align: start;
          }

          &-text {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
            z-index: 5;

            h6 {
              font-weight: 600;
              font-size: 22px;
              line-height: 125%;
              color: #ffffff;
              margin: 0;
              width: 100%;
              text-align: start;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 125%;
              color: #ffffff;
              margin: 0;
              width: 100%;
              text-align: start;
            }
          }
        }
      }
    }
  }
`;
