export const nl: { [key: string]: string } = {
  subscribe_with_click: 'Abonneer met één klik',
  subscribe: 'Abonneer met één klik',
  minimum_number_characters: 'minimum aantal van 2 tekens',
  request_details: 'Aanvraagdetails',
  form_title: 'We nemen binnenkort contact met u op',
  form_required: 'Velden gemarkeerd met * zijn verplicht',
  form_name_placeholder: 'Naam',
  form_email_placeholder: 'E-mail',
  form_phone_placeholder: 'Telefoonnummer',
  form_company_placeholder: 'Bedrijf',
  form_submit: 'Verzenden',
  form_1:
    'Ik geef mijn toestemming voor de verwerking van persoonlijke gegevens volgens de',
  form_2: 'Privacybeleid',
  validation_privacy_policy: 'Accepteer het privacybeleid alstublieft',
  validation_phone: 'Telefoonnummer is niet geldig',
  validation_email: 'Ongeldig e-mailformaat',
  validation_required: 'Verplichte velden zijn niet ingevuld',
  submit_error: 'Er is een fout opgetreden bij het opslaan van het formulier',
  footer_1: 'Onze partners:',
  footer_2: 'Methoden',
  footer_3: 'Producten',
  footer_4: 'Contacten:',
  footer_5: 'Afdruk',
  footer_6: 'Privacybeleid',
  footer_7: 'Marktonderzoek',
  about_us_1: '4Service Maatschappelijke Verantwoordelijkheid',
  about_us_2:
    'Vanuit het hart en de ziel van elk teamlid. We doen ons best om een vonk van vriendelijkheid in elk van onze medewerkers te bewaren en de wereld om ons heen een beetje beter te maken',
  about_us_3: '4Service Maatschappelijke Verantwoordelijkheid',
  about_us_4:
    'Vanuit het hart en de ziel van elk teamlid. We doen ons best om een vonk van vriendelijkheid in elk van onze medewerkers te bewaren en de wereld om ons heen een beetje beter te maken',
  about_us_5: 'Service Mania bedrijfsrollenspel',
  about_us_6: 'Onze initiatieven voor kinderen en studenten',
  about_us_7: 'Liefdadigheid / Vrijwilligerswerk',
  about_us_8:
    'Weeshuis, zorginstellingen voor ouderen en vrijwilligerswerk voor het Oekraïense leger. Het meest ontroerende deel van onze activiteiten.',
  about_us_9: 'Dierenwelzijn',
  about_us_10:
    'Iedereen kan met een huisdier ons kantoor binnenlopen. We helpen dierenasiels, zamelen geld in voor voedsel en proberen soms een thuis te vinden voor dakloze dieren.',
  about_us_11: '4Service Group',
  about_us_12:
    'Is een internationaal onderzoeksbedrijf dat klantbeleving beheert.',
  about_us_13:
    'We voeren complexe projecten uit om de verkoop te verhogen en het bedrijfsleven van klantgerichte bedrijven te ontwikkelen.',
  about_us_14:
    'Is een internationale onderzoeksorganisatie met hoofdkantoor in Wenen, Oostenrijk. Het bedrijf werd opgericht in 2001 met als doel de kwaliteit van leven van zijn klanten en de mensen die met bedrijven in interactie staan te verbeteren.',
  about_us_15:
    'Dit was het begin van de geschiedenis van 4Service in Kyiv, Oekraïne als toonaangevende aanbieder van mystery shopping-diensten. Het blijft succesvol opereren als een onderzoeksbedrijf in 52 landen met nieuwe mogelijkheden voor een holistische aanpak om de gehele klantbelevingsketen te analyseren en te verbeteren.',
  about_us_16: 'geboren in de naam van service',
  about_us_17: 'eerste kantoor buiten Oekraïne',
  about_us_18: 'verhuizing van hoofdkantoor naar Wenen',
  about_us_19: 'elitair MSPA-lid, Top-10 agentschap in Europa',
  about_us_20: 'kantoren opgericht in 9 landen',
  about_us_21: 'eerste digitale projecten, Voicer',
  about_us_22: 'partners van DesignThinkers Academy',
  about_us_23: 'eerste wereldwijde CX-projecten',
  about_us_24: 'Missie',
  about_us_25:
    'Onze missie is om klantenservice over de hele wereld te verbeteren! Onze inspiratie is de kwalitatieve veranderingen die we zien in de mensen en bedrijven waarmee we werken.',
  about_us_26: '20+ jaar Klantbeleving',
  about_us_27: '95 landen',
  about_us_28:
    'We verzamelen en delen de beste ervaring in de industrie van over de hele wereld',
  about_us_29: 'Hoofdkantoor in Wenen',
  about_us_30:
    'landen. We verzamelen en delen de beste ervaring in de industrie van over de hele wereld',
  about_us_31: 'kwaliteitsbeoordelingen service',
  about_us_32: 'we staan in de top-10 Europese CX Agentschappen',
  about_us_33: 'vertegenwoordigende kantoren / hoofdkantoor in Oostenrijk',
  about_us_34: 'actieve projecten',
  about_us_35: 'Uw toekomstige werkplek',
  about_us_36: 'Onze kantoren',
  about_us_37:
    '4Service™ streeft ernaar een comfortabele werkomgeving voor zijn medewerkers te creëren. Onze medewerkers werken op locatie, op afstand of hybride, afhankelijk van de beste gemak en productiviteit van elk individu. Ruimtes voor ontspanning, gratis psychologische ondersteuning en een gastvrije omgeving. Wij zijn mens- en diervriendelijk.',
  about_us_38: 'Een team dat inspireert',
  about_us_39:
    'Ons multiculturele team zet zich in om te luisteren, feedback van onze klanten te waarderen en gezamenlijk te groeien. De klantreis met ons is net zo belangrijk voor ons bedrijf als het lanceren van nieuwe producten of het behalen van onze financiële doelen.',
  about_us_40: 'Alle',
  about_us_41: 'Management',
  about_us_42: 'Team Global',
  about_us_43: 'Producteigenaren',
  about_us_44: 'Onze partners',
  about_us_45:
    'We implementeren complexe projecten om de verkoop te verhogen en de bedrijven van klantgerichte bedrijven te ontwikkelen.',
  about_us_46:
    'is een van de toonaangevende "design driven" Professional Training Institutes, vertegenwoordigd in 25 landen, en traint, ontwikkelt en faciliteert creatieve multidisciplinaire teams en gemeenschappen om positieve verandering te stimuleren.',
  about_us_47:
    'Hun missie is om ruimte en tijd te bieden voor teams en individuen om geïnspireerd en uitgedaagd te worden, om "learning-by-doing" te versterken en creatieve probleemoplossing te bevorderen.',
  about_us_48:
    'is de vertegenwoordiger van de Mystery Shopping-industrie. De vereniging werkt regionaal wereldwijd met hoofdstukken in Amerika, Europa/Afrika en Azië/Pacific.',
  about_us_49: 'is een non-profit ledenorganisatie.',
  about_us_50:
    'ESOMAR heeft zijn hoofdkantoor in Amsterdam, Nederland. ESOMAR is sinds 1947 vertegenwoordigd in meer dan 130 landen.',
  about_us_51:
    'is een professionele gemeenschap van privédiensten en bedrijven gericht op interacties op het gebied van klantenservice.',
  about_us_52:
    'De Oekraïense Contact Center Associatie werd opgericht in 2010.',
  about_us_53:
    'bestudeert de beste wereldervaring op het gebied van onderwijs om het aan te passen en te gebruiken op de Oekraïense markt.',
  about_us_54:
    'Academy DTEK richt zich op de ontwikkeling van een klantgerichte en innovatieve cultuur.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Verkoopmanager Internationale',
  bht_1: 'Merkgezondheid',
  bht_2: 'Tracking',
  bht_3: 'Merken zijn ongelooflijk krachtig',
  bht_4: 'Ontdek hoe goed uw merk presteert in het algemeen',
  bht_5: 'Over',
  bht_6: 'Wat is Merkgezondheid Tracking?',
  bht_7:
    'Dit is een soort marktonderzoek dat met een zekere regelmaat wordt uitgevoerd (elke twee weken, elke maand, enz.) om informatie te verkrijgen over de',
  bht_8:
    'status van een merk op de markt op basis van indicatoren zoals bekendheid, vraag, imago, de kenmerken van zijn consumenten, merkgezondheid, enz.',
  bht_9: 'Koopt het merk het vaakst',
  bht_10: 'Loyaal',
  bht_11: 'Kocht het merk gedurende 3 maanden',
  bht_12: 'Repertoire / vaste klanten',
  bht_13: 'Kocht het merk gedurende 6 maanden',
  bht_14: 'Klanten op de korte termijn',
  bht_15: 'Kocht het merk gedurende een jaar',
  bht_16: 'Klanten op de lange termijn',
  bht_17:
    'Zijn zich bewust van het merk, maar hebben het nooit gebruikt/gekocht',
  bht_18: 'Bewust maar geen klanten',
  bht_19: 'Onbewust: hebben nog nooit van het merk gehoord',
  bht_20: 'Onbewust',
  bht_21: 'Profiel van merkklanten',
  bht_22:
    'Het evalueren van de consistentie van de inspanningen die op de lange termijn zijn geleverd om een merk op te bouwen',
  bht_23: 'Het meten van het niveau van klantkennis over het merk',
  bht_24:
    'Het tonen van de effectiviteit van de reclame- en informatiecampagnes',
  bht_25:
    'Het bepalen van de impact die een periode van hoge reclame heeft op de verkoop/marketingactiviteiten van de concurrenten',
  bht_26:
    'Het bieden van constante controle aan de ondernemer in perioden van voortdurende marktwijzigingen',
  bht_27: 'Welke taken kunnen worden opgelost met BHT:',
  bht_28: 'Vraag om een onderzoek',
  bht_29: 'Op te lossen taak',
  bht_30: 'Bijvoorbeeld,',
  bht_31:
    'als een bedrijf slechts één BHT-sessie per kwartaal nodig heeft tijdens de meting van marktaandelen, moet een enquête onder respondenten vaker worden uitgevoerd bij het meten van klantkennis over het merk uit reclame.',
  bht_32: 'CATI',
  bht_33: 'Face-to-Face',
  bht_53: 'Interviews',
  bht_34: 'СAWI',
  bht_35: 'Taak om op te lossen',
  bht_36: 'Door informatie te verzamelen',
  bht_37:
    'via verschillende methoden wordt merktracking meestal in drie fasen verdeeld, afhankelijk van de doelgroep en hoe moeilijk deze te bereiken is:',
  bht_38:
    'We gebruiken zowel traditionele als moderne methoden om gegevens te verzamelen:',
  bht_39:
    'We werken met twee modellen: het eerste model met een eenvoudige set indicatoren en het tweede model richt zich op complexere industrieën zoals de voedings- en dienstensector. Een complexer model omvat een diepgaand onderzoek dat tot 10 geschatte indexen bevat.',
  bht_40: 'We bieden',
  bht_41: 'controle over initiële gegevens',
  bht_42: 'We dekken',
  bht_43: 'het hele land',
  bht_44: 'We bieden een oplossing',
  bht_45:
    'voor niet-dynamische markten/markten met een beperkt aantal spelers (Brand Point Analyze)',
  bht_46: 'Waarom wij?',
  bht_47: 'Waarom voor ons kiezen?',
  bht_50: 'Slecht resultaat',
  bht_51: 'Goed resultaat',
  bht_52: 'Gemiddeld resultaat',
  cases_1:
    'Uitdaging: Hoe de klantbeleving bij het kopen van elektrische voertuigen in Hyundai-showrooms te verbeteren?',
  cases_2: 'Customer Journey Mapping',
  cases_3: 'Ideeontwikkeling',
  cases_4: 'Kwalitatief onderzoek',
  cases_5:
    'Uitdaging: Wat moet er worden veranderd in de winkels (zowel in het serviceproces, in het interieur als in merkperceptie) om de interesse van klanten in merk Oppo te wekken?',
  cases_6: 'Online CJM',
  cases_7: 'UI-testen',
  cases_8: 'Interviews',
  cases_9:
    'Uitdaging: Wat moet er worden veranderd in de winkels om de interesse van klanten in merk Oppo te wekken?',
  cases_10: 'Kwalitatief deel',
  cases_11: 'Ideeontwikkeling',
  cases_12: 'Kwantitatief deel',
  cases_13: 'Customer Journey Map',
  cases_14: 'Cases',
  cases_15: 'Alle',
  cases_16: 'Laten we samen uw geweldige case maken',
  csi_1: 'Klant',
  csi_2: 'tevredenheidsindex',
  csi_3: 'Bepaal de redenen voor',
  csi_4: 'tevredenheid en ontevredenheid van consumenten',
  csi_5: 'Onderzoek naar klanttevredenheid',
  csi_6:
    'is het bepalen van de factoren, oorzaken en motieven die de beslissing van een klant om te kopen beïnvloeden, evenals het gedrag van de doelgroep.',
  csi_7: 'Een klanttevredenheidsonderzoek',
  csi_8:
    'toont aan hoe tevreden of ontevreden een klant is met een bepaald aspect van de service of het product in het algemeen.',
  csi_9: 'Het tevredenheidsonderzoek',
  csi_10:
    'wordt uitgevoerd onder bestaande klanten van het bedrijf en gebruikers van bepaalde producten/diensten.',
  csi_11: 'Wat is CSI?',
  csi_12:
    'Klanttevredenheid meet in hoeverre de verwachtingen van de klant zijn vervuld met betrekking tot de aankoop van een product of dienst geleverd door uw bedrijf.',
  csi_13: 'Blije man',
  csi_14: 'Over',
  csi_15: 'Identificeer verborgen factoren',
  csi_16: 'die mogelijk de klanttevredenheid beïnvloeden',
  csi_17: 'Bepaal de redenen',
  csi_18: 'achter een stijging/daling in de vraag naar goederen en diensten',
  csi_19: 'Optimaliseer de',
  csi_20: 'bedrijfsprocessen van het bedrijf',
  csi_21: 'Door ze aan te passen',
  csi_22: 'aan de behoeften van de moderne consument',
  csi_23: 'Creëer een effectieve reclameboodschap',
  csi_24: 'en positioneer een product op basis van de klantvraag',
  csi_25: 'Taken om op te lossen',
  csi_26: 'Welke taken klanttevredenheidsonderzoeken kunnen oplossen',
  csi_27: 'Man met telefoon',
  csi_28: 'We bouwen een wiskundig model',
  csi_29:
    'van factoren die de klanttevredenheid/loyaliteit kunnen beïnvloeden (regressieanalyse)',
  csi_30: 'Optimaliseer de',
  csi_31: 'bedrijfsprocessen van het bedrijf',
  csi_32: 'We bouwen een berekende klanttevredenheidsindex',
  csi_33: '(CSI) en vergelijken deze met de declaratieve index',
  csi_34: 'We voeren een onderzoek uit',
  csi_35: 'voor zowel B2B- als B2C-segmenten.',
  csi_36: 'Onze vragenlijsten worden ontwikkeld door een team',
  csi_37:
    'van analisten speciaal voor de individuele projecten van onze klanten',
  csi_38: 'Waarom wij',
  csi_39: 'Waarom voor ons kiezen?',
  csi_40: 'Methoden voor het verzamelen van informatie:',
  main_1:
    'van onze klanten verbeteren hun financiële prestaties door te leren van de ervaringen van hun cliënten',
  main_2: 'СX onderzoeks- & adviesdiensten: een 360° overzicht van uw bedrijf',
  main_3:
    'Ontdek ons assortiment oplossingen en tools om uw bedrijf te ondersteunen',
  main_4: 'Producten',
  main_5:
    'Kwalitatief onderzoek is het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen',
  main_6:
    'Kwantitatieve onderzoeksmethoden zijn een objectieve meting en numerieke analyse van gegevens verzameld via enquêtes, vragenlijsten en peilingen',
  main_7:
    'Mystery shopping is een marktonderzoekstechniek om de interacties tussen een klant en een bedrijf of organisatie tijdens een vooraf gedefinieerd scenario te meten en monitoren',
  main_8:
    'Feedback Management System. Stem van uw klant in één systeem Klantfeedbackbeheersysteem',
  main_9:
    'Een AI-gestuurd content intelligence platform dat inzichten van het publiek biedt',
  main_10:
    'Prestatiebeheersoftware. Gamified applicatie om verkoop te verhogen',
  main_11:
    'Prijstoezicht is het proces van verzamelen, verwerken en analyseren van de prijzen van concurrenten',
  main_12:
    'Service design is hoe men de behoeften en wensen van mensen die een product of dienst gebruiken begrijpt door tijd met hen door te brengen',
  main_13:
    'Ons analytische team past de meest actuele en fundamentele benaderingen toe die we met veel klanten succesvol hebben bewezen',
  main_14: 'Methodologieën',
  main_15: 'Customer journey map',
  main_16:
    'Customer Journey Mapping (CJM) gaat over het definiëren van een doelgroep, het begrijpen van hun behoeften en omgeving, en vervolgens een plan maken om een boeiende ervaring te leveren',
  main_17: 'CJM',
  main_18: 'Net Promotion Score',
  main_19:
    'Net Promoter Score (NPS) is de gouden standaard van klantervaringsmetingen, gebaseerd op één vraag: Hoe waarschijnlijk is het dat u Organisatie/Product/Dienst aanbeveelt?',
  main_20: 'NPS',
  main_21: 'Brand Health Tracking',
  main_22:
    'Brand Health Tracking (BHT) onderzoek kan meten hoe uw merk presteert op bekendheid en gebruik, merkpositionering en merkprestaties',
  main_23: 'BHT',
  main_24: 'Onderzoek naar Human Resources',
  main_25:
    'Human Resource Research wordt gebruikt om HR-praktijken en prestaties te evalueren en biedt gedetailleerde analyses over de huidige ontwikkeling en het beheer',
  main_26: 'HR-onderzoek',
  main_27: 'Klanten tevredenheidsindex',
  main_28:
    'De Klanttevredenheidsindex (CSI) is een score die aangeeft hoe tevreden uw klanten zijn met de producten/diensten van het bedrijf.',
  main_29: 'CSI',
  main_30: 'UX testen',
  main_31:
    'Gebruikstest (UX) is hoe echte mensen omgaan met een website, app of ander product en hun gedrag en reacties daarop observeren',
  main_32: 'Waarom wij',
  main_33:
    'Onze missie is om bedrijven te helpen een zeer effectieve klantervaring te bieden',
  main_34: 'Focus op kwaliteit',
  main_35: 'Wij zijn kwaliteits gecontroleerd',
  main_36:
    'als het gaat om onderzoeksgegevens. We stellen alle datasets beschikbaar aan klanten',
  main_37: 'Kosteneffectief',
  main_38: 'Onze ervaring, middelen en gestroomlijnde',
  main_39: 'bedrijfsprocessen stellen ons in staat u geld en tijd te besparen',
  main_40: 'Slimme oplossingen',
  main_41: 'We gebruiken verschillende technieken',
  main_42: 'en toolkits om uw specifieke problemen op te lossen',
  main_43: 'Focus op kwaliteit',
  main_44: 'Wij kwaliteits gecontroleerd',
  main_45:
    'als het gaat om onderzoeksgegevens. We stellen alle datasets beschikbaar aan klanten',
  main_46: 'Kosteneffectief',
  main_47: 'Onze ervaring, middelen en gestroomlijnde',
  main_48: 'bedrijfsprocessen stellen ons in staat u geld en tijd te besparen',
  main_49: 'Slimme oplossingen',
  main_50: 'We gebruiken verschillende technieken',
  main_51: 'en toolkits om uw specifieke problemen op te lossen',
  main_52: 'Cash & Carry',
  main_53: 'Elektronica',
  main_54: 'Luxe/Premium',
  main_55: 'Dineren & Gastvrijheid',
  main_56: 'Detailhandel',
  main_57: 'Automotive',
  main_58: 'Mode',
  main_59: 'Banken',
  main_60: 'Reizen & Vrije tijd',
  main_61: 'Horloges & Sieraden',
  main_62: 'Huishoudelijke apparaten',
  main_63: 'Blog',
  main_64: 'Ga naar de blog om meer interessante berichten te lezen',
  main_65: 'Detailhandelsaudit',
  main_66:
    'Verzamel gedetailleerde gegevens over uw winkel of merk, analyseer groeibarrières en creëer effectieve strategieën om deze te overwinnen',
  cjm_1: 'Klant',
  cjm_2: 'Reiskaart',
  cjm_3: 'Betreed de wereld van klanten en deel in hun ervaring',
  cjm_4: 'Wat is Customer Journey Map?',
  cjm_5:
    'CJM biedt een gevisualiseerde geschiedenis van consumenteninteractie met een product, dienst, bedrijf of merk via verschillende kanalen en gedurende een bepaalde periode.',
  cjm_6:
    'Dit stelt bedrijven in staat om de ervaring van klantinteractie objectief te analyseren, eventuele obstakels op te lossen en aanbevelingen te doen voor productverbetering.',
  cjm_7: 'Bepaling van TA, segmentatie',
  cjm_8: 'Identificatie van de stadia van klantinteractie',
  cjm_9: 'Identificatie van primaire klantinteracties kanalen',
  cjm_10: 'Onderzoek naar klantervaring',
  cjm_11: 'Analyse van obstakels en hypothese over optimalisatie',
  cjm_12: 'Uitvoering en testen van hypotheses',
  cjm_13:
    'Het ontwerpen van een Customer Journey Map omvat de volgende stappen:',
  cjm_14: 'De Customer Journey Map zal laten zien:',
  cjm_15:
    'CJM biedt een gevisualiseerde geschiedenis van consumenteninteractie met een product, dienst, bedrijf of merk via verschillende kanalen en gedurende een bepaalde periode.',
  cjm_16: 'Wij zorgen voor samenwerking',
  cjm_17: 'tussen analisten en ontwerpers',
  cjm_18: 'Wij bieden een individuele',
  cjm_19: 'benadering van elke klant',
  cjm_20: 'Wij kunnen de algemene',
  cjm_21:
    'klanttevredenheid met de dienst/product verhogen en uiteindelijk hun loyaliteit aan het bedrijf vergroten',
  cjm_22: 'Wij garanderen de',
  cjm_23:
    'optimalisatie van het consumententraject en houden rekening met de bedrijfsprocessen van het bedrijf',
  cjm_24: 'Wij bieden onze klanten',
  cjm_25:
    'de mogelijkheid om gecombineerde benaderingen te gebruiken: CATI, CAWI, focusgroepen, diepgaande interviews, expertinterviews, etnografie, enz.',
  cjm_26: 'Waarom voor ons kiezen?',
  methods_hr_1: 'Onderzoek naar Human Resources',
  methods_hr_2:
    'Krijg objectieve inzichten over uw team en ontdek hoe u HR-prestaties kunt verbeteren',
  methods_hr_3: 'Enkele redenen waarom u HR-onderzoek nodig kunt hebben',
  methods_hr_4: 'van het geïnterviewde personeel',
  methods_hr_5: 'HR-projecten uitgevoerd',
  methods_hr_6: 'personeelstrainingen gehouden',
  methods_hr_7:
    'Toonaangevende Europese holding voor klant- en medewerkeronderzoek',
  methods_hr_8: '4service HR-onderzoek in cijfers',
  methods_hr_9:
    'Om problemen en zwakke punten in elk stadium van het leven van de werknemers van uw bedrijf te identificeren: zoeken/rekruteren/onboarden/carrièreontwikkeling/hercertificeringen',
  methods_hr_10:
    'Om te weten welke gelegenheden verhogingen en bonussen verdienen',
  methods_hr_11:
    'U moet ontevredenheid van werknemers voorspellen of oplossingen bieden',
  methods_hr_12:
    'Via HR-onderzoek zijn nieuwe en bestaande methoden voor de evaluatie van HR-beleid, programma’s en praktijken mogelijk',
  methods_hr_13:
    'Om de mentale gezondheid van werknemers in het bedrijf te verbeteren',
  methods_hr_14:
    'Als u de productiviteit wilt verbeteren door een doelgerichte aanpak',
  methods_hr_15:
    'Als u een internationaal en/of extern team heeft dat u moet beheren',
  methods_hr_16:
    'Om te begrijpen hoe aantrekkelijk uw werkgeversmerk is voor potentiële werknemers',
  methods_hr_17: 'Medewerkerstevredenheid',
  methods_hr_18: 'HR Branding Onderzoek',
  methods_hr_19: 'Culturele Diversiteit',
  methods_hr_20: 'Compensatie- en salarisplannen',
  methods_hr_21: 'Teamengagement en loyaliteit',
  methods_hr_22: 'Beschermde werknemersklassen',
  methods_hr_23: 'Onderzoeksgebieden',
  methods_hr_24: 'Oplossing 1',
  methods_hr_25: 'eNPS, Medewerkerstevredenheid, Medewerkerloyaliteit',
  methods_hr_26: 'Wat we meten:',
  methods_hr_27: 'Loyaliteit:',
  methods_hr_28:
    'medewerkerloyaliteit, voorspelling van toekomstig gedrag, bepaling van de weging van factoren die loyaliteit beïnvloeden',
  methods_hr_29: 'Betrokkenheid:',
  methods_hr_30:
    'betrokkenheid van medewerkers bij het werk, interesse in resultaten en prestaties',
  methods_hr_31: 'Engagement:',
  methods_hr_32:
    'identificatie van de waarden van medewerkers en bedrijfswaarden',
  methods_hr_33: 'Vertrouwen 360:',
  methods_hr_34:
    'vertrouwen in management, collega’s, ondergeschikten, vertrouwen in werkgeversstrategieën en -beleid',
  methods_hr_35: 'Tevredenheid:',
  methods_hr_36:
    'bepaling van unieke factoren voor elk bedrijf, evaluatie van tevredenheid over elk belangrijke factor die loyaliteit beïnvloedt',
  methods_hr_37: 'Oplossing 2',
  methods_hr_38: 'Werkgeversmerk',
  methods_hr_39: 'Interne werkgevers',
  methods_hr_40:
    'merkanalyse (enquête onder personeel, stakeholders, interne recruiters en HR-specialisten)',
  methods_hr_41: 'Externe werkgeversanalyse',
  methods_hr_42:
    '(analyse van concurrenten-EVP, enquête onder externe recruiters, enquête onder werkzoekenden)',
  methods_hr_43: 'Merkbekendheid en reputatie',
  methods_hr_44: 'analyse',
  methods_hr_45: 'Werkgeversaanwezigheid',
  methods_hr_47: 'Werkgeversreferentie',
  methods_hr_48: 'verzameling',
  methods_hr_49: 'Concurrentiebenchmarking',
  methods_hr_50: 'op kernfactoren zoals',
  methods_hr_51: 'Salaris,',
  methods_hr_52: 'werkomgeving, sfeer, aard van het werk',
  methods_hr_53: 'Analyse van behoeften en verwachtingen',
  methods_hr_54: 'van werkzoekenden',
  methods_hr_55: 'Diepte-interviews,',
  methods_hr_56: 'deskresearch en kwantitatief onderzoek worden gebruikt',
  methods_hr_57: 'Oplossing 3',
  methods_hr_58: 'Medewerkersreiskaart/mystery medewerker',
  methods_hr_59: 'het construeren van de zoektocht van werkzoekenden door',
  methods_hr_60:
    'de belangrijkste stadia van de zoektocht, communicatiekanalen, selectiecriteria en beoordeling van werkzoekenden te identificeren',
  methods_hr_61: 'het monitoren van alle stadia',
  methods_hr_62:
    'en communicatiekanalen met mystery job seekers (alleen een afspraak maken voor een sollicitatiegesprek, een gesprek voeren, een stage in het bedrijf)',
  methods_hr_63: 'Identificatie',
  methods_hr_64: 'van zwakke punten in de wervings- en onboardingprocessen',
  methods_hr_65: 'Aanbevelingen voor verbetering,',
  methods_hr_66: 'benchmarking met marktleiders',
  mystery_shopping_1: 'Mysterie',
  mystery_shopping_2: 'Winkelen',
  mystery_shopping_3: 'Evalueer uw service met echte klanten',
  mystery_shopping_4:
    'Controleer standaarden, beoordeel uw personeel, kom dichter bij uw klant',
  mystery_shopping_5:
    'Wij zijn Elite-lid van de professionele toonaangevende Mystery Shopping Providers Association MSPA Europe',
  mystery_shopping_6: 'ervaring',
  mystery_shopping_7: 'bezoeken per maand',
  mystery_shopping_8: 'mystery shoppers wereldwijd',
  mystery_shopping_9: 'Actieve projecten',
  mystery_shopping_10: 'kantoren, hoofdkantoor in Oostenrijk',
  mystery_shopping_11: 'Europese Mystery Shopping Providers',
  mystery_shopping_12: 'servicekwaliteitsbeoordelingen',
  mystery_shopping_13: 'Vragenlijst',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: 'Profiel van de shopper',
  mystery_shopping_16: 'Validatie en bezwaar',
  mystery_shopping_17: 'Analytics',
  mystery_shopping_18:
    'Met deze 5 stappen garandeert ons bedrijf 4Service de kwaliteit van de mystery shopper service',
  mystery_shopping_19: 'Programma',
  mystery_shopping_20:
    'De 5 elementen van het Mystery Shopping-programma van 4Service',
  mystery_shopping_21: 'Vragenlijst:',
  mystery_shopping_22: 'de kern van uw programma',
  mystery_shopping_23:
    'Dit deel van het programma is zichtbaar voor alle belanghebbenden',
  mystery_shopping_24: 'Voor topmanagers.',
  mystery_shopping_25:
    'Laat alle belangrijke stappen en details van het proces zien',
  mystery_shopping_26: 'Voor werknemers.',
  mystery_shopping_27: 'Weerspiegelen wat werkgevers van hen verwachten',
  mystery_shopping_28: 'Voor mystery shoppers.',
  mystery_shopping_29:
    'Duidelijk en gestructureerd om verwarring en vooringenomenheid te voorkomen',
  mystery_shopping_30: 'Shopmetrics-software:',
  mystery_shopping_31: 'gebruiksvriendelijk en innovatief',
  mystery_shopping_32: 'Voordelen:',
  mystery_shopping_33: 'Integreert',
  mystery_shopping_34: 'met uw bedrijfsprocessen en software',
  mystery_shopping_35: 'GDPR-gestuurd',
  mystery_shopping_36: 'Privacybeheer toolkit',
  mystery_shopping_37: 'Gedetailleerd',
  mystery_shopping_38: 'Juridisch compliancepakket',
  mystery_shopping_39: 'Rapport',
  mystery_shopping_40: 'bouwer',
  mystery_shopping_41: 'Beheer',
  mystery_shopping_42: 'van toegangsrechten',
  mystery_shopping_43: 'De meeste vragenlijsten',
  mystery_shopping_44: 'worden binnen een uur voltooid',
  mystery_shopping_45: 'Profiel van shoppers:',
  mystery_shopping_46: '200 000+ mystery shoppers wereldwijd',
  mystery_shopping_47:
    'Belangrijkste criteria: maximale nabijheid tot uw doelgroep, met eerdere ervaring met uw bedrijf of concurrenten',
  mystery_shopping_48: 'Training voor shoppers:',
  mystery_shopping_49: 'Introductietrainingstest',
  mystery_shopping_50: 'Skype/telefoongesprek',
  mystery_shopping_51: 'Evaluatietest (optioneel)',
  mystery_shopping_52: 'Validatie en bezwaren',
  mystery_shopping_53: 'Validatie',
  mystery_shopping_54: 'Systeem',
  mystery_shopping_55: '100% van de rapporten',
  mystery_shopping_56: 'wordt beoordeeld door het validatieteam',
  mystery_shopping_57: 'Extra oproepen',
  mystery_shopping_58: 'voor verduidelijking',
  mystery_shopping_59: 'Audioregistraties',
  mystery_shopping_60: 'na het bezoek om de belangrijkste problemen te noteren',
  mystery_shopping_61: 'Systeem voor preventie',
  mystery_shopping_62: 'van werknemersfraude',
  mystery_shopping_63: 'Bezwaar',
  mystery_shopping_64: 'Systeem',
  mystery_shopping_65: 'Elke beoordeling van een shopper',
  mystery_shopping_66:
    'kan direct door de werknemer op de website worden aangevochten',
  mystery_shopping_67: 'Elke bezwaar',
  mystery_shopping_68:
    'wordt overwogen en beantwoord door het lokale validatieteam',
  mystery_shopping_69: 'U kunt de statistieken zien',
  mystery_shopping_70: 'van de bezwaren',
  mystery_shopping_71: 'Analytics.',
  mystery_shopping_72:
    'De rapporten worden persoonlijk gepresenteerd en bevatten:',
  mystery_shopping_73: 'De pijnpunten van het bedrijf',
  mystery_shopping_74: 'en zorgen',
  mystery_shopping_75: 'Dynamiek',
  mystery_shopping_76: 'per divisie en sectie',
  mystery_shopping_77: 'Correlatie',
  mystery_shopping_78: 'tussen het naleven van normen en klanttevredenheid',
  mystery_shopping_79: 'Benchmarking',
  mystery_shopping_80: 'ten opzichte van concurrenten',
  mystery_shopping_81: 'Praktische',
  mystery_shopping_82: 'aanbevelingen voor serviceverbeteringen',
  mystery_shopping_83: 'Wat maakt ons anders?',
  mystery_shopping_84: 'We voeren ideatiesessies uit',
  mystery_shopping_85:
    'We raden niet alleen aan wat te doen, maar zoeken samen met u naar praktische manieren om serviceproblemen op te lossen.',
  mystery_shopping_86:
    'Ideatiesessies zijn een krachtig hulpmiddel waarmee we:',
  mystery_shopping_87:
    'Richtingen kunnen ontwikkelen voor verbetering van producten of diensten',
  mystery_shopping_88:
    'Nieuwe inkomstenstroommogelijkheden en bedrijfsstrategieën kunnen verkennen',
  mystery_shopping_89:
    'Oplossingen kunnen vinden voor complexe klantgerichte uitdagingen',
  mystery_shopping_90:
    'De pijnpunten van klanten kunnen omzetten in hoogtepunten',
  mystery_shopping_91: 'We voeren video-interviews uit',
  mystery_shopping_92:
    'We verzamelen niet alleen vragenlijsten - we krijgen ook live commentaar van shoppers',
  mystery_shopping_93: 'We doen dit om:',
  mystery_shopping_94:
    'Onze facilitators voeren diepgaande interviews en geven effectieve feedback',
  mystery_shopping_95:
    'De ervaring vanuit het perspectief van de klant te zien',
  mystery_shopping_96: 'Uw contentmarketingstrategie te verbeteren',
  mystery_shopping_97: 'Klanten gedrag te voorspellen',
  mystery_shopping_98: 'We ontwerpen Customer Journey Map',
  mystery_shopping_99:
    'Customer Journey Mapping helpt ons in de schoenen van klanten te stappen om hun behoeften en uitdagingen te begrijpen.',
  mystery_shopping_100:
    'Het creëren van een customer journey is de beste manier om:',
  mystery_shopping_101:
    'De ervaring vanuit het perspectief van de klant te zien',
  mystery_shopping_102: 'Uw contentmarketingstrategie te verbeteren',
  mystery_shopping_103: 'Klanten gedrag te voorspellen',
  mystery_shopping_104: 'Gaps in service of communicatie te identificeren',
  mystery_shopping_105: 'We bieden verschillende soorten Mystery Shopping aan',
  mystery_shopping_106: 'Soorten Mystery Shopping',
  mystery_shopping_107: 'Mystery Caller',
  mystery_shopping_108:
    'Belt uw managers en/of callcenter om de kwaliteit van de service via de telefoon te bepalen',
  mystery_shopping_109: 'Luxury Mystery Shopping',
  mystery_shopping_110:
    'Controleert service op luxe locaties door een speciale categorie mystery shoppers',
  mystery_shopping_111: 'Concurrentiebezoeken',
  mystery_shopping_112:
    'De gemakkelijkste manier om te begrijpen hoe uw concurrent opereert',
  mystery_shopping_113: 'Mystery Employee',
  mystery_shopping_114:
    'Helpt u de volledige reis van uw medewerker te begrijpen (werving, aanpassing, kantoorprocessen, enz.)',
  mystery_shopping_115: 'Motiverende bezoeken',
  mystery_shopping_116:
    'Een nuttig hulpmiddel om het aanbod van aanvullende diensten en/of producten, de verkoop van specifieke producten te stimuleren',
  mystery_shopping_117: 'Mystery Shopper Online',
  mystery_shopping_118:
    'Simuleert en meet de klantervaring in uw online winkel, website of app',
  mystery_shopping_119: 'Verkoop- en promotiecontrole',
  mystery_shopping_120:
    'Helpt te identificeren hoe promoties worden weergegeven op alle locaties en hoe effectief ze werken',
  mystery_shopping_121: 'Aankoop- en retourbezoeken',
  mystery_shopping_122:
    'Helpt het hele proces van aankoop tot retour te weerspiegelen om de klantervaring te verbeteren',
  mystery_shopping_123: '> 20 jaar',
  nps_1: 'Net Promotie',
  nps_2: 'Score',
  nps_3: 'Meet, volg en verbeter klantloyaliteit',
  nps_4: 'Zij reageren met een score van 0 tot 6.',
  nps_5:
    'Zij hebben mogelijk een slechte ervaring gehad, zijn onwaarschijnlijk opnieuw klant en kunnen anderen zelfs ontmoedigen om van u te kopen.',
  nps_6: 'Dit zijn respondenten die een score tussen 7 en 8 geven.',
  nps_7:
    'Zij zijn tevreden met uw service maar niet gelukkig genoeg om als promotors te worden beschouwd.',
  nps_8: 'Dit zijn de respondenten die uw bedrijf als een 9 of 10 beoordelen.',
  nps_9:
    'Dit betekent dat zij u zouden aanbevelen aan een vriend of collega en dus namens u pleiten.',
  nps_10: 'Wat is NPS?',
  nps_11: 'Het meet de klantperceptie op basis van één eenvoudige vraag:',
  nps_12:
    'Hoe waarschijnlijk is het dat u [Organisatie/Product/Dienst] zou aanbevelen aan een vriend of collega?',
  nps_13: 'Heeft mijn bedrijf het nodig?',
  nps_14: 'NPS is uw duurzame groei',
  nps_15: 'Begrijp de dynamiek van klantloyaliteit',
  nps_16: 'Motiveren van uw personeel',
  nps_17: 'Begrijp de relatie met verschillende producten',
  nps_18: 'Vergelijk hoe klanten u en uw concurrenten behandelen',
  nps_19: 'Wat kan ik doen met NPS?',
  nps_20: 'Voor welk type bedrijf is NPS relevant?',
  nps_21: 'Methoden voor gegevensverzameling:',
  nps_22: 'Onze aanbevelingen bij het werken met NPS',
  nps_23:
    'Dit betekent dat we richtlijnen ontwikkelen om Detractors om te zetten in promotors',
  nps_24: 'We werken met een "gesloten-lus"-methode',
  nps_25:
    'NPS is een trackingonderzoek waarin we veranderingen in klantloyaliteit volgen',
  nps_26: 'We volgen de dynamiek',
  nps_27:
    'We raden aan om altijd de concurrenten in uw niche te evalueren voor een diepgaandere analyse',
  nps_28: 'Evaluatie van concurrenten',
  price_monitoring_1: 'Prijsmonitoring',
  price_monitoring_2: 'Retailmonitoring',
  price_monitoring_3:
    'Vergroot het verkooppotentieel en optimaliseer uw prijsbeleid door monitoring van concurrentieprijzen',
  price_monitoring_4:
    '4Service biedt al meer dan 20 jaar prijsmonitoringdiensten wereldwijd aan',
  price_monitoring_5:
    'Prijzen worden binnen korte tijd gecontroleerd door ons team van gekwalificeerde interne auditors, waardoor we snel kunnen reageren op veranderingen in de prijzen van concurrenten',
  price_monitoring_6:
    'Uitdagingen die ons bedrijf kan helpen oplossen met prijsmonitoring',
  price_monitoring_7: 'Evalueren van de prijzen',
  price_monitoring_8: 'en productbeleid van concurrenten',
  price_monitoring_9: 'Promoties controleren',
  price_monitoring_10: 'en speciale aanbiedingen',
  price_monitoring_11: 'Marktomstandigheden monitoren',
  price_monitoring_12: 'en marktomgeving',
  price_monitoring_13: 'Verkooplocaties controleren en beheren',
  price_monitoring_14: 'voor speciale apparatuur en goederen',
  price_monitoring_15: 'Beheer van producten',
  price_monitoring_16: 'in de handel',
  price_monitoring_17: 'Optimaliseren van voorwaarden',
  price_monitoring_18: 'voor betere samenwerking met leveranciers en dealers',
  price_monitoring_19: 'Meten van schapruimte,',
  price_monitoring_20:
    'beoordelen van goederenpositionering, voorraadbeschikbaarheid',
  price_monitoring_21: 'Belangrijkste indicatoren en monitoringcriteria',
  price_monitoring_22:
    'Alle gegevens worden verzameld in één interface, waaronder:',
  price_monitoring_23: 'naam',
  price_monitoring_24: 'van het product en merkcategorie',
  price_monitoring_25: 'beschikbaarheid',
  price_monitoring_26: 'van promotiematerialen',
  price_monitoring_27: 'prijs',
  price_monitoring_28: 'en beschikbaarheid van eventuele promotieaanbiedingen',
  price_monitoring_29: 'schap',
  price_monitoring_30: 'grootte',
  price_monitoring_31: 'product',
  price_monitoring_32: 'weergave',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'facing',
  price_monitoring_35: 'type',
  price_monitoring_36: 'van verpakking',
  price_monitoring_37: 'alle andere',
  price_monitoring_38: 'algemene informatie',
  price_monitoring_39: 'Pre-projectanalyse:',
  price_monitoring_40: 'We bestuderen',
  price_monitoring_41:
    'het prijs- en productbeleid, de bedrijfs- en industriepraktijken van uw bedrijf',
  price_monitoring_42: 'We bieden',
  price_monitoring_43: 'snelle training, testen en certificering voor auditors',
  price_monitoring_44: 'We passen aan',
  price_monitoring_45: 'en stellen de software in',
  price_monitoring_46: 'Veldwerk:',
  price_monitoring_47: 'Bezoeken aan retail',
  price_monitoring_48: 'winkels',
  price_monitoring_49: 'Rapportage:',
  price_monitoring_50: 'Ons analytische team',
  price_monitoring_51:
    'stelt online rapporten op in de persoonlijke accounts van het bedrijf',
  price_monitoring_52: 'We bieden',
  price_monitoring_53:
    'een volledig fotoverslag, PPT-rapport, Excel-rapport op basis van type plaats, ketennaam, winkeladres',
  price_monitoring_54: 'Hoe het',
  price_monitoring_55: 'werkt',
  price_monitoring_56: 'de grootte van individuele kortingen',
  price_monitoring_57: 'kostencalculatie',
  price_monitoring_58: 'grootte van individuele bonussen',
  price_monitoring_59: 'loyaliteitsprogramma’s',
  price_monitoring_60: 'etc.',
  price_monitoring_61: 'We gebruiken gespecialiseerde software:',
  price_monitoring_62:
    'PRADATA, Shopmetrics, en bieden uitgebreide technische ondersteuning',
  price_monitoring_63: 'Snelle implementatie',
  price_monitoring_64: 'en schaalbaarheid (vanaf dag 1)',
  price_monitoring_65: 'Alle gegevens bevestigd',
  price_monitoring_66: 'door foto’s en GPS-tagging',
  price_monitoring_67: 'We hebben het benodigde aantal',
  price_monitoring_68: 'veldagenten in elk land',
  price_monitoring_69: 'We kunnen werken met grote',
  price_monitoring_70: 'hoeveelheden data',
  price_monitoring_71: 'Monitoring van individuele prijzen',
  price_monitoring_72:
    'Als u complexe producten heeft, kunnen wij u helpen uw prijsstrategie te bepalen',
  price_monitoring_73:
    'We kunnen individuele prijzen van concurrenten monitoren, bijvoorbeeld:',
  price_monitoring_74: 'Lage',
  price_monitoring_75: 'kosten',
  price_monitoring_76: 'Hoge',
  price_monitoring_77: 'efficiëntie',
  price_monitoring_78: 'Excel-rapport',
  price_monitoring_79: 'PPT-rapport',
  price_monitoring_80: 'BI-rapport',
  price_monitoring_81:
    'Gegevens over concurrentieprijzen helpen u de beste prijsbeslissingen te nemen',
  price_monitoring_82:
    'We bieden een volledig fotoverslag, PPT-rapport, Excel-rapport per type locatie',
  qualitative_research_1: 'Kwalitatief',
  qualitative_research_2: 'onderzoek',
  qualitative_research_3:
    'Verzamel, analyseer en interpreteer gegevens door observatie van acties en woorden van uw klanten',
  qualitative_research_4: 'Waarden',
  qualitative_research_5: 'Gedrag',
  qualitative_research_6: 'Achtergrond',
  qualitative_research_7: 'Verwachtingen',
  qualitative_research_8: 'Omgeving',
  qualitative_research_9: 'Angsten/Barrières',
  qualitative_research_10: 'Wat is het doel van de keuze van de klant?',
  qualitative_research_11: 'Man met telefoon',
  qualitative_research_12: 'merk',
  qualitative_research_13: 'categorie',
  qualitative_research_14: 'reclame',
  qualitative_research_15: 'product',
  qualitative_research_16:
    'De uniciteit van kwalitatieve methoden is dat ze ons in staat stellen de oorzaken en motivatie van consumentengedrag te begrijpen',
  qualitative_research_17:
    'Analyse van de houding van de consument tegenover een bepaald fenomeen',
  qualitative_research_18: 'Individuele perspectieven en ervaringen',
  qualitative_research_19:
    'Onderwerpen die niet door enquêtes kunnen worden onderzocht',
  qualitative_research_20: 'Gevoelige onderwerpen',
  qualitative_research_21: 'Moeilijk te bereiken doelgroep',
  qualitative_research_22: 'Complexe geografie',
  qualitative_research_23: 'Diepgaande interviews nuttig voor:',
  qualitative_research_24: 'Diepgaande interviews',
  qualitative_research_25:
    'Een diepgaand interview is een kwalitatieve onderzoekstechniek waarbij individuele interviews worden gehouden met respondenten om hun perspectieven op een bepaald idee, programma of situatie te verkennen. Een diepgaand interview onderzoekt individuele ervaringen / percepties / praktijken in rijke details.',
  qualitative_research_26: 'Doelen:',
  qualitative_research_27: 'Karakterisering van sociale en culturele normen',
  qualitative_research_28: 'Moeilijk te bereiken doelgroep',
  qualitative_research_29:
    'Delen en vergelijken (Morgan) onderzocht via enquêtes',
  qualitative_research_30:
    'Onderwerpen die niet door enquêtes kunnen worden onderzocht',
  qualitative_research_31: 'Diepgaande focusgroepen nuttig voor:',
  qualitative_research_32: 'Focusgroepen',
  qualitative_research_33:
    'Diepgaande verkenning van individuele bijeenkomsten, opkomende discussies, discussies tussen manifestaties onder invloed van de groepsmoderator.',
  qualitative_research_34:
    'Inzichten genereren in gedeelde ervaringen en sociale normen door middel van groepsdiscussie, narratieve data in een gerichte discussie.',
  qualitative_research_36: 'Voordelen:',
  qualitative_research_37:
    'Onderzoek naar de levensstijl en het gedrag van de consument in verschillende realistische omstandigheden (thuis, op het verkooppunt, op het werk, enz.)',
  qualitative_research_38:
    'Zoeken naar inzichten voor de ontwikkeling van nieuwe producten, verpakking, verbetering van diensten, enz.',
  qualitative_research_39: 'Diepgaand etnografisch onderzoek nuttig voor:',
  qualitative_research_40: 'Etnografisch onderzoek',
  qualitative_research_41:
    'Etnografie is een vorm van kwalitatief onderzoek waarbij je jezelf onderdompelt in een specifieke gemeenschap of organisatie om hun gedrag en interacties van dichtbij te observeren.',
  qualitative_research_42:
    'Etnografie is een flexibele onderzoeksmethode waarmee je een diep begrip krijgt van de gedeelde cultuur, conventies en sociale dynamiek van een groep. Het brengt echter ook enkele praktische en ethische uitdagingen met zich mee.',
  qualitative_research_43: 'Methoden:',
  qualitative_research_44:
    'Etnografie is een studie van de consument in reële omstandigheden en interactie met een product/dienst',
  qualitative_research_45: 'Voordelen van samenwerken met ons',
  qualitative_research_46:
    'We kunnen respondenten vinden uit elke doelgroep. En we controleren de kwaliteit van rekrutering',
  qualitative_research_47: 'Rekrutering van elke soort complexiteit',
  qualitative_research_48:
    'Onze moderatoren zijn meertalig en hebben facilitatievaardigheden',
  qualitative_research_49: 'Expert-moderatoren',
  qualitative_research_50: 'Werken online/offline',
  qualitative_research_51: 'We bieden audio/video',
  qualitative_research_52: 'opnames',
  qualitative_research_53:
    'We kunnen kwalitatief onderzoek uitvoeren op elke locatie',
  quantitative_research_1: 'Onderzoek naar klanttevredenheid',
  quantitative_research_2:
    'met een specifiek product of specifieke dienst (SCI, NPS)',
  quantitative_research_3: 'Uitvoeren van segmentatie',
  quantitative_research_4: 'enquêtes van verschillende markten',
  quantitative_research_5: 'Marktonderzoek',
  quantitative_research_6: 'NPS-beheer',
  quantitative_research_7: 'Concurrentieanalyse',
  quantitative_research_8: 'Kwantitatieve',
  quantitative_research_9: 'onderzoeken',
  quantitative_research_10:
    'Onderzoeksmethodologie die wordt gebruikt om theorieën over attitudes en gedrag van mensen te testen op basis van objectieve, numerieke en statistische gegevens',
  quantitative_research_11: 'jaren',
  quantitative_research_12: 'van CX-ervaring wereldwijd',
  quantitative_research_13: 'panel',
  quantitative_research_14: 'van respondenten',
  quantitative_research_15: 'landen',
  quantitative_research_16: 'wij voeren projecten uit over de hele wereld',
  quantitative_research_17: 'Wie wij zijn',
  quantitative_research_18:
    '4Service is een wereldwijde marketingonderzoeks- en CX-holding. Wij bieden deskundige gegevensverzamelingdiensten aan voor een breed scala aan bedrijven',
  quantitative_research_19: '4Service in cijfers',
  quantitative_research_20: 'Computerondersteund',
  quantitative_research_21: 'Web Interviewing',
  quantitative_research_22:
    'Een internetgebaseerde vragenlijsttechniek. De respondent vult een elektronische vragenlijst in zonder hulp van een interviewer. De vragenlijst kan elektronisch worden verzonden of op de website worden geplaatst.',
  quantitative_research_23: 'Computerondersteund Web Interviewing',
  quantitative_research_24: 'In de meeste gevallen',
  quantitative_research_25:
    'is deze methode het meest kosteneffectief in termen van materiële en tijdskosten',
  quantitative_research_26: 'Deze enquête biedt',
  quantitative_research_27:
    'de mogelijkheid om een moeilijk bereikbare doelgroep te interviewen',
  quantitative_research_28: 'Het biedt uitgebreide mogelijkheden',
  quantitative_research_29:
    'voor de demonstratie van video- en audiomateriaal evenals afbeeldingen',
  quantitative_research_30: 'Respondenten kunnen',
  quantitative_research_31: 'overal ter wereld zijn gevestigd',
  quantitative_research_32: 'Het stelt in staat',
  quantitative_research_33:
    'specifiek gedrag van de internetdoelgroep te onderzoeken. De belangrijkste resultaten kunnen in realtime door de klant worden bekeken via een webinterface',
  quantitative_research_34: 'Geschikt voor elk vertrouwelijk,',
  quantitative_research_35:
    'gevoelig, persoonlijk onderwerp of probleem waarover respondenten terughoudend zouden zijn om met een interviewer te praten',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37:
    'Wij hebben een eigen panel van respondenten wereldwijd',
  quantitative_research_38: 'Wij werken met uitdagende aanvragen',
  quantitative_research_39:
    'Door gebruik te maken van aanvullende tools (sociale media, advertenties) kunnen wij een specifieke doelgroep en een geavanceerd profiel van de respondent vinden',
  quantitative_research_40: 'Wij motiveren onze respondenten',
  quantitative_research_41:
    'Wij hebben een kader ontwikkeld om de voltooiing van lange enquêtes aan te moedigen door materiële incentives te bieden',
  quantitative_research_42: 'Face-to-face interviews',
  quantitative_research_43: 'uitgevoerd met een tablet',
  quantitative_research_44:
    'Dit is een van de belangrijkste methoden voor het verzamelen van kwantitatieve gegevens, waarbij de interviewer rechtstreeks communiceert met de respondent via een strikt gestructureerde vragenlijst.',
  quantitative_research_45:
    'Face-to-face interviews kunnen worden uitgevoerd op de werkplek of woonplaats van de respondent, op straat of op aangewezen verkooplocaties met behulp van een tablet',
  quantitative_research_46: 'Face-to-face interviews uitgevoerd met een tablet',
  quantitative_research_47: 'Het bepalen van het niveau',
  quantitative_research_48: 'van populariteit en merkherkenning',
  quantitative_research_49: 'Consumentensegmentatie',
  quantitative_research_50: 'op basis van hun koopgedrag',
  quantitative_research_51: 'Analyse van de effectiviteit',
  quantitative_research_52: 'van de reclamecampagne',
  quantitative_research_53: 'Meting van klanttevredenheid',
  quantitative_research_54: 'meting',
  quantitative_research_55: 'Het bepalen van de beste prijs',
  quantitative_research_56: 'voor het product of de dienst',
  quantitative_research_57: 'Analyse',
  quantitative_research_58: 'van consumentvoorkeuren',
  quantitative_research_59: 'Onderzoek',
  quantitative_research_60: 'naar hoe consumenten kopen',
  quantitative_research_61: 'Beoordeling',
  quantitative_research_62: 'van marktcapaciteit',
  quantitative_research_63: 'DigSee Software',
  quantitative_research_64:
    'Wij gebruiken speciale software met audio-opnamen en GPS',
  quantitative_research_65: 'Interviewers en supervisors',
  quantitative_research_66:
    'wij kunnen interviews afnemen in elke taal en vertalen naar de voorkeurstaal van de klant',
  quantitative_research_67: 'Steekproeven',
  quantitative_research_68:
    'De analytische afdeling ontwikkelt een systeem voor respondentrekrutering om het best mogelijke resultaat te verkrijgen',
  quantitative_research_69: 'Computerondersteund',
  quantitative_research_70: 'Telefonisch Interviewen',
  quantitative_research_71:
    'Methodologie voor het verzamelen van kwantitatieve informatie door middel van telefonische interviews met behulp van een duidelijk gestructureerde vragenlijst.',
  quantitative_research_72: 'Computerondersteund Telefonisch Interviewen',
  quantitative_research_73: 'Wij hebben onze eigen callcenters',
  quantitative_research_74:
    'Wij gebruiken speciale software met audio-opnamen en GPS',
  quantitative_research_75: 'Gekwalificeerde operators',
  quantitative_research_76:
    'Onze operators spreken verschillende talen, zijn getraind, goed getest en blijven hun vaardigheden continu verbeteren',
  quantitative_research_77: 'Databases',
  quantitative_research_78:
    'Wij werken met onze eigen databases en ook met uw klantdatabases',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Wij garanderen kwaliteit en sluiten een SLA (Service Level Agreement) af',
  quantitative_research_81: 'Doelstellingen',
  quantitative_research_82: 'Bepaling van methodologie',
  quantitative_research_83: 'Segmentatie en steekproeven',
  quantitative_research_84: 'Het definiëren van de dataverzamelingskanalen',
  quantitative_research_85:
    'Het maken van een vragenlijst die logisch is voor de respondent',
  quantitative_research_86: 'Proces van gegevensverzameling',
  quantitative_research_87: 'Kwaliteitscontrole',
  quantitative_research_88: 'Analysetechnieken',
  quantitative_research_89: 'Gegevensverwerking',
  quantitative_research_90: 'Ontwerp van de enquête',
  quantitative_research_91: 'GPS-locatietracking van interviewers',
  quantitative_research_92: 'Gegevensverificatie',
  quantitative_research_93: 'Programmeren en testen van vragenlijsten',
  quantitative_research_94: 'Gegevensvalidatie',
  quantitative_research_95:
    'Audio-opnamen waarbij wordt voldaan aan de vereisten van de AVG',
  quantitative_research_96:
    'Opleiding en competentie van onderzoeksmedewerkers',
  quantitative_research_97: 'Kwaliteitscontrole',
  quantitative_research_98: 'Factoranalyse',
  quantitative_research_99: 'Segmentatie',
  quantitative_research_100: 'Perceptuele mapping',
  quantitative_research_101: 'Correlatie',
  quantitative_research_102: 'Conversiemodel',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Clusteranalyse',
  quantitative_research_105: 'enz.',
  quantitative_research_106: 'Analytische rapporten',
  quantitative_research_107:
    'Wij gebruiken SPSS® en andere tools om analytische rapporten te maken met verschillende methodologieën',
  quantitative_research_108:
    'Welke zakelijke uitdagingen kunnen worden opgelost:',
  quantitative_research_109:
    'Welke zakelijke uitdagingen kunnen worden opgelost:',
  social_responsibility_1: '4Service Corporate Social Responsibility',
  social_responsibility_2:
    'Vanuit het hart en de ziel van elk teamlid. We proberen een vonk van vriendelijkheid bij elke medewerker te behouden en de wereld om ons heen een beetje beter te maken',
  social_responsibility_3: 'Educatieve activiteiten / Jeugdondersteuning',
  social_responsibility_4:
    'Onze outreach-initiatieven voor kinderen en studenten',
  social_responsibility_5: 'Service Mania bedrijfsstrategie-spel',
  social_responsibility_6:
    'Een belangrijk onderdeel van onze trainingsactiviteiten is het bedrijfsstrategie-spel Service Mania.',
  social_responsibility_7:
    'Service Mania daagt je uit om strategisch na te denken over hoe om te gaan met ongewone situaties.',
  social_responsibility_8: 'Onderzoekspresentatie / Diiya. Bedrijf',
  social_responsibility_9:
    'Een zakelijk en klantperspectief op de servicesituatie: een presentatie van onze 4Service onderzoeksresultaten door Victoria Skorbota, hoofd ontwikkeling bij 4Service Oekraïne',
  social_responsibility_10: 'Service Design-sessies',
  social_responsibility_11:
    'De Service Design-methodologie is een belangrijk onderdeel van 4Service. Een paar jaar geleden werden we partner van DesignThinkers Academy en we gebruiken niet alleen best practices in ons werk, maar bieden ook actief training aan de beste bedrijven',
  social_responsibility_12: 'Liefdadigheid / Vrijwilligerswerk',
  social_responsibility_13:
    'Weeshuisprojecten, zorginstellingen voor ouderen en vrijwilligerswerk voor het Oekraïense leger. Het meest ontroerende deel van onze activiteiten',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'We werken samen met het Blagomai Charity Fund en proberen feestvreugde en hulp te bieden aan degenen die dit het meest nodig hebben',
  social_responsibility_16:
    'Fondsenwerving voor weeshuizen en verzorgingstehuizen',
  social_responsibility_17:
    'Bij 4Service hebben we verschillende inzamelingsacties voor verzorgingstehuizen en weeshuizen geïnitieerd om basisbehoeften te kopen',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'De oorlog heeft veel van onze medewerkers en naasten getroffen. Elk van ons helpt waar mogelijk',
  social_responsibility_20: 'Dierenwelzijn',
  social_responsibility_21:
    'Iedereen kan met hun huisdier naar ons kantoor komen. We helpen opvangcentra, zamelen geld in voor voedsel en zoeken soms ook huizen voor dakloze dieren',
  social_responsibility_22: 'Ondersteuning van dierenopvangcentra',
  social_responsibility_23:
    'Dakloze dieren hebben net zoveel hulp nodig als alle kwetsbare leden van onze samenleving.',
  social_responsibility_24:
    'Ons bedrijf heeft het Sirius Dierenasiel (regio Kyiv, Oekraïne) onder onze hoede genomen.',
  social_responsibility_25: 'Creatieve initiatieven om zwerfdieren te helpen',
  social_responsibility_26:
    'Hoe kunnen we het urgente probleem van dierenopvangcentra onder de aandacht brengen van onze klanten en partners?',
  social_responsibility_27: 'Diervriendelijk kantoor',
  social_responsibility_28:
    'Veel van onze teamleden hebben huisdieren. We hebben een gezonde omgeving gecreëerd waar iedereen comfortabel naar kantoor kan komen met hun huisdieren',
  ux_testing_1: 'UX Testing',
  ux_testing_1_1: 'UX Testing',
  ux_testing_2:
    'Mens- en AI-gebaseerde aanpak voor het testen en verbeteren van uw websites en applicaties',
  ux_testing_3: 'Wat is UX Testing',
  ux_testing_4:
    'UX Testing helpt u te begrijpen hoe mensen omgaan met uw product, app of website',
  ux_testing_5: 'De navigatie is niet erg duidelijk',
  ux_testing_6: 'Ik heb geen idee hoe ik een bestelling kan plaatsen',
  ux_testing_7: 'Niet genoeg foto’s in de catalogus',
  ux_testing_8: 'U zult:',
  ux_testing_9: 'Ontdekken hoe u kunt verbeteren',
  ux_testing_10:
    'Problemen identificeren in het ontwerp en de interactie met de klant',
  ux_testing_11:
    'Uitvinden met welke moeilijkheden de gebruiker te maken krijgt bij interactie met de site',
  ux_testing_12: 'De klantreis observeren',
  ux_testing_13: 'Erkennen wanneer en waarom uw doelgroep de site verlaat',
  ux_testing_14:
    'Bepalen welke secties de minste en de meeste interesse en emotie oproepen',
  ux_testing_15: 'Gebruikersgerichte UX Testing',
  ux_testing_16:
    '4Service biedt u de echte menselijke inzichten die u nodig heeft om klantgerichte websites en apps te maken met behulp van kwalitatieve en kwantitatieve methoden',
  ux_testing_17:
    'Ik begrijp niet hoe ik de site moet gebruiken. Waar is het menu verborgen?',
  ux_testing_18:
    'Ik denk dat er te veel ruimte is tussen de letters, de tekst is onleesbaar',
  ux_testing_19: 'De afbeeldingen overlappen elkaar. Is dit de bedoeling?',
  ux_testing_20:
    'UX Testing helpt u te begrijpen hoe mensen omgaan met uw product, app of website',
  ux_testing_21: 'Gebruikersgerichte UX Testing-onderzoeksontwerp:',
  ux_testing_22: 'Ontdekken hoe u kunt verbeteren',
  ux_testing_23:
    'Het bepalen van het type en de methode van testen (kwalitatief/kwantitatief)',
  ux_testing_24: 'Het opstellen van hypothesen en gebruikersscenario’s',
  ux_testing_25: 'Analyse en rapportage van testresultaten',
  ux_testing_26: 'Twee oplossingen',
  ux_testing_27: 'Wij bieden twee benaderingen voor succesvolle UX Testing',
  ux_testing_28: 'Gebruikersgerichte UX Testing',
  ux_testing_29: 'AI + mensgerichte UX Testing',
  ux_testing_30: 'Onze sterke punten:',
  ux_testing_31: 'Wij kunnen het concurrentielandschap analyseren',
  ux_testing_32:
    'Wij kunnen gespecialiseerde experts betrekken om interviews te houden en deel te nemen',
  ux_testing_33:
    'Wij kunnen interviews in verschillende talen uitvoeren en naar de gewenste taal vertalen',
  ux_testing_34: 'met bestaande bruikbaarheid',
  ux_testing_35: 'metriek of normen',
  ux_testing_36: 'uw prestaties',
  ux_testing_37: 'tegen die van een concurrent',
  ux_testing_38: 'versies',
  ux_testing_39: 'van hetzelfde product',
  ux_testing_40: 'Tevredenheidsscore',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Succespercentages',
  ux_testing_43: 'Foutpercentage',
  ux_testing_44: 'Tijd voor taakafronding',
  ux_testing_45: 'Gebruikersgerichte UX Testing',
  ux_testing_46: 'Kwantitatieve methode',
  ux_testing_47:
    'We gebruiken minimaal 100 personen om aan UX Testing voor deze fase deel te nemen',
  ux_testing_48: 'Relevante vergelijking van uw website of app:',
  ux_testing_49: 'Kwantitatieve methode:',
  ux_testing_50: 'Observatie',
  ux_testing_51:
    'Tijd doorbrengen met een gebruiker of groep gebruikers en hun gedrag met het product observeren terwijl ze het in het dagelijks leven gebruiken',
  ux_testing_52: 'Diepte-interview',
  ux_testing_53:
    'Stelt u in staat de houding, overtuigingen, wensen en ervaringen van de gebruikers te leren kennen die uw site bezoeken. Wij raden aan 15-20 interviews af te nemen',
  ux_testing_54: 'Etnografisch onderzoek',
  ux_testing_55:
    'Uitgevoerd in de omgeving van respondenten waar zij het product gebruiken. Door te observeren krijg je een goed inzicht in de psychologie van de gebruiker en de emotionele uitdagingen waarmee zij worden geconfronteerd.',
  ux_testing_56: 'Gebruikersgerichte UX Testing',
  ux_testing_57: 'Kwalitatieve methode',
  ux_testing_58:
    'Deze methode helpt om de motivaties en logica van gebruikersgedrag te begrijpen',
  ux_testing_59: 'We gebruiken:',
  ux_testing_60:
    'We ontwikkelen een klantreiskaart om de resultaten van ons onderzoek te illustreren',
  ux_testing_61: '30 deelnemers (man/vrouw)',
  ux_testing_62:
    'Respondenten vooraf screenen en uitnodigen om de enquête in te vullen',
  ux_testing_63: 'Afstandsinterviews op het Wantent-platform',
  ux_testing_64: 'AI + mensgericht',
  ux_testing_65: 'AI + mensgerichte UX Testing',
  ux_testing_66:
    'Een combinatie van unieke kunstmatige intelligentie technologie van Wantent en echte gebruikers',
  ux_testing_67: 'De grote lettergrootte in de kop heeft de aandacht getrokken',
  ux_testing_68:
    "Gebruikers kijken naar de 'schreeuwende' cijfers, maar lezen de informatie ernaast niet",
  ux_testing_69:
    'UX-testing helpt u te begrijpen hoe mensen omgaan met uw product, app of website',
  ux_testing_70: 'AI + mens-gebaseerd testonderzoek ontwerp',
  ux_testing_71: 'Hoe het werkt',
  ux_testing_72:
    'Wantent is een AI-gebaseerde oplossing voor content-market fit.',
  ux_testing_73:
    'Wantent beoordeelt de efficiëntie van websites en apps door ML-technologieën toe te passen om de emotionele reacties en betrokkenheid van het publiek te analyseren.',
  ux_testing_74:
    'Om de resultaten te interpreteren, gebruiken we storytellingtechnieken, diversiteit en empathie om prestaties en groei te stimuleren.',
  ux_testing_75:
    'De aandacht en emoties van de gebruiker tijdens overgangen door secties',
  ux_testing_76:
    'Statistieken per deelnemersgroep (aandacht en focus gedurende de sessie)',
  ux_testing_77: 'Analyse van pagina-conversies',
  ux_testing_78: 'Aandacht en emoties tijdens het uitvoeren van taken',
  ux_testing_79: 'Bevindingen over de perceptie',
  ux_testing_80: 'van de website door verschillende groepen deelnemers',
  ux_testing_81: 'Analyse van de eenvoud',
  ux_testing_82:
    'van navigatie door pagina’s en de eenvoud van het vinden van belangrijke elementen',
  ux_testing_83: 'Een perceptieanalyse',
  ux_testing_84: 'van de verstrekte informatie op de website',
  ux_testing_85: 'Definitiegebieden',
  ux_testing_86:
    'voor verbetering binnen individuele pagina’s (navigatie, informatieblokken, enz.)',
  ux_testing_87: 'We analyseren in detail',
  ux_testing_88: 'Belangrijke resultaten van het onderzoek',
  ux_testing_89:
    'Aanbevelingen voor het verbeteren van de structuur van de website en individuele pagina’s',
  ux_testing_90:
    'We selecteren zorgvuldig de doelgroep voor Usability Testing.',
  ux_testing_91:
    'We voeren onderzoek uit in verschillende landen, in verschillende talen en onder verschillende gebruikersgroepen',
  ux_testing_92: 'We kunnen testen in applicaties',
  ux_testing_93:
    'en op de websites van concurrenten en bieden een vergelijkende analyse',
  ux_testing_94: 'We combineren verschillende onderzoeksmethoden.',
  ux_testing_95:
    'Onze gekwalificeerde facilitators begeleiden de deelnemer door het testproces.',
  ux_testing_96: 'We voeren testen uit',
  ux_testing_97: 'op verschillende apparaten en softwareversies',
  ux_testing_98: 'Waarom voor ons kiezen?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Een uniek partnerproject van 4Service en Wantent © Een combinatie van kunstmatige intelligentie en een mensgerichte aanpak voor contentanalyse',
  wantent_4: 'het succes meten voordat de content wordt uitgezonden',
  wantent_5: 'de risico’s van diversiteitsproblemen beperken',
  wantent_6:
    'diepgaande inzichten en aanbevelingen om de content te verbeteren',
  wantent_7: 'objectieve beslissingen over content- en marketingstrategie',
  wantent_8: 'Wantent is een AI-gebaseerde oplossing voor content-market fit.',
  wantent_9:
    'Wantent is een op kunstmatige intelligentie gebaseerde oplossing voor het matchen van content met de markt. Wantent evalueert de effectiviteit van videocontent door ML-technologieën toe te passen om emotionele reacties en betrokkenheid van het publiek te analyseren.',
  wantent_10:
    'Wantent helpt om de content-market fit te definiëren en de content efficiëntie te maximaliseren door',
  wantent_11: 'Scenario-opstelling & werving van deelnemers',
  wantent_12: 'gepersonaliseerd projectontwerp en wereldwijd publiek',
  wantent_13: 'Het verzamelen van kijkersreacties',
  wantent_14: 'gedetailleerde en duidelijke feedback van het publiek',
  wantent_15: 'Analyse van de perceptie van content',
  wantent_16: 'analyse van gedrag en emotionele reacties door AI',
  wantent_17: 'Het leveren van inzichten voor de eindafwerking',
  wantent_18: 'aanbevelingen voor verbeteringen',
  wantent_19: 'Wantent maximaliseert uw content efficiëntie',
  wantent_20: 'Advertentieconcept pretesten',
  wantent_21: 'Creatief concept testen',
  wantent_22:
    'Controleren van de consistentie van toekomstige creatie met de ontwikkelde strategie en begrip van de reacties van de doelgroep voor mogelijke aanpassingen aan de creatieve concepten/boodschappen',
  wantent_23:
    'Testen van de zichtbaarheid van de merkelementen (postproductie)',
  wantent_24:
    'Bevestiging van de effectiviteit van de advertentie-/sponsoringinvestering',
  wantent_25: 'Ad testen',
  wantent_26: 'A/B testen',
  wantent_27:
    'Vergelijken van de effectiviteit van creaties en het verhogen van betrokkenheid en aandacht tijdens de productie',
  wantent_28: 'Gelanceerde advertentie testen',
  wantent_29:
    'Het definiëren van de prestaties van berichten en het creëren van aanbevelingen voor het verbeteren van audio en visuele elementen',
  wantent_30: 'Oplossingen voor TV',
  wantent_31: 'Pilots, promo’s, nieuwe formaten, testen van TV-presentatoren',
  wantent_32:
    'Het uitvoeren van een vergelijkende analyse om de meest boeiende te identificeren en te versterken',
  wantent_33: 'TV-show en speelfilm testen',
  wantent_34:
    'Analyseren van de betrokkenheid van kijkers, aandachtsspiegels, emotionele reacties tijdens het kijken van lange content in natuurlijke omstandigheden. We vinden automatisch de pieken van het publieksverlies en afleiding momenten om de video montage te evalueren en te verbeteren',
  wantent_35: 'UI/UX testen',
  wantent_36: 'Testen van websiteprototypes en mobiele applicaties',
  wantent_37:
    'Analyse van gebruikersreacties en emoties met behulp van Wantent technologie:',
  wantent_38: 'niveau van aandacht',
  wantent_39: 'emotionele reacties',
  wantent_40: 'heatmaps van de kijkrichting van deelnemers',
  wantent_41:
    'Usability-analyse van websites en applicaties op basis van deelnemersfeedback:',
  wantent_42: 'System usability scale (SUS)',
  wantent_43: 'Klant inspanningsscore (gemak van interactie)',
  wantent_44: 'Net Promotie Score (NPS)',
  wantent_45: 'GDPR-compliance en gebruikersprivacy',
  wantent_46:
    'Wantent voldoet volledig aan de GDPR- en CCPA-beleidslijnen en voldoet aan de principes van verzameling, opslag, verwerking en bescherming van persoonlijke gegevens van deelnemers',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (Media & Entertainment)',
  wantent_49:
    'We experimenteren elke dag met talloze nieuwe contentideeën met machine learning technologieën. Wantent helpt ons risico’s en voordelen te begrijpen en te evalueren, gebruikersfeedback, gedrag en perceptie te ontdekken om causaliteit van verschillende aspecten van onze oplossingen te verkrijgen.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'mede-eigenaar TABASCO (Reclame)',
  wantent_52:
    'Wantent biedt een werkelijk unieke service die ons helpt zeer kleine details te weten te komen over wat consumenten leuk en niet leuk vinden. Er is geen manier om te bedriegen, er is geen manier om te liegen - Wantent weet altijd de waarheid, en hiermee krijgen wij als marketingexperts de onbetaalbare (hahaha - we kennen de exacte prijs en het is betaalbaar) richtlijnen om onze communicatie te verbeteren.',
  wantent_53: 'Beoordelingen',
  terms_1: 'Algemene voorwaarden',
  terms_2: 'Gebruiksvoorwaardenovereenkomst',
  terms_3:
    'Lees deze Gebruiksvoorwaardenovereenkomst (“gebruiksvoorwaarden”, “overeenkomst”) zorgvuldig door voordat u de website gebruikt',
  terms_4:
    '(“website”) beheerd door 4Service Holdings GmbH (“4Service”, “ons”, ‘wij”, “onze”).',
  terms_5:
    'Gebruiksvoorwaarden Door deze website te gebruiken, verklaart u dat u deze overeenkomst hebt gelezen en begrepen en dat u akkoord gaat met de naleving van de voorwaarden. Als u niet gebonden wilt zijn aan de voorwaarden van deze overeenkomst, wordt u geadviseerd de website dienovereenkomstig te verlaten. 4Service verleent alleen gebruik en toegang tot deze website, haar producten en diensten aan degenen die de voorwaarden hebben aanvaard.',
  terms_6: 'Privacybeleid',
  terms_7:
    'Voordat u onze website blijft gebruiken, raden wij u aan ons Privacybeleid met betrekking tot onze gegevensverzameling door gebruikers te lezen. Dit zal u helpen onze praktijken beter te begrijpen.',
  terms_8: 'Leeftijdsbeperking',
  terms_9:
    'U moet ten minste 16 (zestien) jaar oud zijn voordat u deze website kunt gebruiken. Door deze website te gebruiken, garandeert u dat u ten minste 16 (zestien) jaar oud bent en dat u zich legaal aan deze overeenkomst kunt houden. 4Service aanvaardt geen aansprakelijkheid voor verplichtingen die voortvloeien uit onjuiste weergave van leeftijd.',
  terms_10: 'Intellectueel eigendom',
  terms_11:
    'U gaat ermee akkoord dat alle materialen, producten en diensten die op deze website worden aangeboden eigendom zijn van 4Service, haar filialen, directeuren, functionarissen, werknemers, agenten, leveranciers of licentiegevers, inclusief alle auteursrechten, handelsgeheimen, handelsmerken, patenten en ander intellectueel eigendom. U gaat ook akkoord dat u het intellectuele eigendom van 4Service op geen enkele manier zult reproduceren of verspreiden, inclusief elektronische, digitale of nieuwe handelsmerkregistraties.',
  terms_12: 'Toegang tot onze website',
  terms_13:
    'Toegang tot onze website is tijdelijk toegestaan en wij behouden ons het recht voor om de dienst die wij op onze website aanbieden zonder kennisgeving in te trekken of te wijzigen. Wij zijn niet aansprakelijk als onze site om welke reden dan ook op enig moment of voor enige periode niet beschikbaar is. Van tijd tot tijd kunnen wij de toegang tot bepaalde delen van onze site, of onze hele site, beperken tot gebruikers die zich bij ons hebben geregistreerd. U bent verantwoordelijk voor het nemen van alle nodige maatregelen om toegang tot onze site te krijgen, inclusief het gebruik van apparatuur die compatibel is met onze site. U bent ook verantwoordelijk voor het ervoor zorgen dat alle personen die via uw internetverbinding toegang hebben tot onze site op de hoogte zijn van deze voorwaarden en deze naleven.',
  terms_14: 'Toepasselijk recht',
  terms_15:
    'Door deze website te bezoeken, gaat u ermee akkoord dat de wetten van de Republiek Oostenrijk, zonder rekening te houden met principes van conflictenrecht, deze voorwaarden en bepalingen of enig geschil van welke aard dan ook tussen 4Service en u zullen beheersen.',
  terms_16: 'Geschillen',
  terms_17:
    'Elk geschil dat op enige manier verband houdt met uw bezoek aan deze website wordt beslecht door de Handelsrechtbank van Wenen.',
  terms_18: 'Schadeloosstelling',
  terms_19:
    'U gaat ermee akkoord 4Service en haar filialen te vrijwaren en schadeloos te stellen tegen juridische claims en eisen die kunnen voortvloeien uit uw gebruik of misbruik van onze website. Wij behouden ons het recht voor om onze eigen juridische adviseur te kiezen.',
  terms_20: 'Opslagperiode',
  terms_21:
    'Rekening houdend met de doeleinden van verwerking, is de opslagperiode van de persoonlijke gegevens van gebruikers (opslagperiode) 24 maanden vanaf de datum waarop de toestemming voor gegevensverwerking door u naar behoren is verkregen.',
  terms_22: 'Beperking van aansprakelijkheid',
  terms_23:
    '4Service is niet aansprakelijk voor eventuele schade die u kan overkomen als gevolg van uw misbruik van onze website. 4Service behoudt zich het recht voor, zonder kennisgeving, deze overeenkomst op elk moment te bewerken, te wijzigen en te wijzigen door deze posting bij te werken. Uw voortgezet gebruik van de website nu, of na het plaatsen van eventuele wijzigingen of aanpassingen, geeft aan dat u deze wijzigingen of aanpassingen accepteert. Als een deel van deze overeenkomst onwettig, nietig of onafdwingbaar wordt verklaard, wordt dat deel als scheidbaar beschouwd en heeft dit geen invloed op de geldigheid en afdwingbaarheid van de resterende bepalingen. Mogelijk bewijs van gebruik van de website voor illegale doeleinden zal worden verstrekt aan de rechtshandhavingsautoriteiten. Deze overeenkomst is een overeenkomst tussen 4Service en de gebruiker.',
  terms_24:
    'Richt alle vragen en zorgen met betrekking tot privacy/gebruiksproblemen naar het volgende adres:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Wenen',
  terms_28: 'Oostenrijk',
  terms_29: 'Beoordeling',
  terms_30:
    'Wanneer de persoonlijke gegevens die u ons verstrekt onjuist zijn, heeft u het recht om ons te vragen deze te corrigeren (GDPR Artikel 16).',
  thank_you_1: 'Bedankt!',
  thank_you_2: 'We nemen binnen 24 uur contact met u op',
  thank_you_3:
    'P.S. Onze managers bekijken uw verzoek al om een beter aanbod samen te stellen',
  thank_you_4: 'Menu',
  thank_you_5: 'Vrouw aan het typen',
  privacy_policy_1:
    'Privacyverklaringsformulier voor betrokkenen (gebruikers van de website)',
  privacy_policy_2:
    'Dit Privacyverklaringsformulier (Privacyverklaring) is bedoeld voor natuurlijke personen (betrokkenen) van wie de persoonlijke gegevens worden verzameld door 4Service Holdings GmbH, in overeenstemming met de vereisten van de AVG in het kader van het bezoek aan de website',
  privacy_policy_3:
    '(hierna Website en Gebruikers van de Website/Gebruikers respectievelijk).',
  privacy_policy_4:
    'Het Privacyverklaringsformulier voor betrokkenen (gebruikers van de website) van 4Service Holdings GmbH is van toepassing op alle bedrijven van de 4Service Group, die onder het wettelijk gezag, onder toezicht of onder controle staan van 4Service Holdings GmbH.',
  privacy_policy_5: 'Contacten',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Bedrijf/wij/ons),',
  privacy_policy_8: 'Adres:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Wenen, Oostenrijk.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Wie zijn wij?',
  privacy_policy_13:
    'Een individu (U) die de website bezoekt met welk doel dan ook. Voor de doeleinden van dit document wordt U ook aangeduid als Gebruiker.',
  privacy_policy_14: 'Persoonsgegevens die wij van u verzamelen',
  privacy_policy_15:
    'Wanneer u onze website bezoekt, sturen wij u een privacyverklaring, waarin wij u vragen toestemming te geven voor de verwerking van de volgende informatie:',
  privacy_policy_16:
    'IP-adres, gebruikersnaam, achternaam, adres, telefoonnummer (vast of mobiel), e-mailadres, adres, bedrijfsnaam, land, e-mailadres, voornaam, achternaam, telefoonnummer, provincie, staat en postcode, gebruiksgegevens, gegevens over interactie met externe sociale netwerken of platforms, informatie over registratie en authenticatie op de website',
  privacy_policy_17: 'geografische positie.',
  privacy_policy_18:
    'In het geval dat u uw wil uitdrukt en ons dergelijke toestemming verleent, beginnen wij deze informatie van u te verwerken.',
  privacy_policy_19: 'Rechtsgrondslag (toestemming)',
  privacy_policy_20:
    'Wanneer u onze website bezoekt, sturen wij u een privacyverklaring, waarin wij u vragen toestemming te geven voor de verwerking van de volgende informatie:',
  privacy_policy_21:
    'De rechtsgrondslag voor de verwerking van de persoonsgegevens is toestemming, die wij van u zullen verkrijgen door het invullen van het toestemmingsformulier via de volgende link:',
  privacy_policy_22:
    'Als u het toestemmingsformulier invult, betekent dit dat u alle voorwaarden begrijpt en accepteert die in deze privacyverklaring zijn gespecificeerd.',
  privacy_policy_23: 'Intrekken van toestemming',
  privacy_policy_24:
    'U heeft het recht om de eerder door u verleende toestemming op elk moment in te trekken. Het intrekken van toestemming heeft geen invloed op de rechtmatigheid van de verwerking op basis van toestemming vóór de intrekking ervan. U kunt uw toestemming intrekken door ons het desbetreffende verzoek te sturen via het volgende e-mailadres withdraw@4service-group.com, waarbij het formulier voor het verzoek beschikbaar is via de volgende link:',
  privacy_policy_25: 'Intrekkingsformulier voor gebruiker',
  privacy_policy_26: 'Doeleinden van verwerking',
  privacy_policy_27:
    'Wij verwerken uw persoonsgegevens voor de volgende doeleinden:',
  privacy_policy_28:
    '– verbeteren van klantenservice (het stelt ons in staat effectiever te reageren op verzoeken van klanten); – personalisatie van gebruikerservaring (het stelt ons in staat te bepalen wie meer geïnteresseerd is in diensten); – verbeteren van de site (het stelt ons in staat de kwaliteit van producten en diensten te verbeteren, het gebruik ervan te vergemakkelijken, nieuwe diensten te ontwikkelen en onze producten en diensten te verbeteren); – communiceren met de gebruiker via nieuwsbrieven, marketing- of promotiemateriaal en andere informatie die ons nieuws, updates, informatie over diensten omvat, met vermelding van instructies over hoe u verdere e-mails kunt weigeren; – uitvoeren van statistisch en ander soort onderzoek en analyse op basis van geanonimiseerde gegevens; – gepersonaliseerde diensten aan de gebruiker aanbieden en overeenkomsten en contracten uitvoeren; – deelname van de gebruiker aan verschillende projecten die wij via de site uitvoeren, reageren op vragen die door de gebruiker via de site worden gesteld, onderzoek, onderhoud van accounts en dossiers en de promotie van diensten.',
  privacy_policy_29:
    'Partijen waaraan de persoonsgegevens kunnen worden overgedragen',
  privacy_policy_30:
    'Bij de verwerking van uw persoonsgegevens dragen wij deze over aan entiteiten die optreden als verwerkers van het bedrijf. De verwerkers van het bedrijf handelen uitsluitend op basis van de instructies van het bedrijf. De specificaties van de acties die de verwerkers van het bedrijf uitvoeren en de lijst van dergelijke verwerkers zijn te vinden in het Privacy- en Gegevensbeschermingsbeleid via de volgende link: Privacy- en Gegevensbeschermingsbeleid',
  privacy_policy_31:
    'Landen waarnaar de persoonsgegevens kunnen worden overgedragen',
  privacy_policy_32:
    'Het bedrijf draagt uw persoonsgegevens over op basis van een adequaatheidsbesluit, zoals bepaald door de AVG en de Europese Commissie. Aanvullende informatie over de overdracht van persoonsgegevens naar de VS is te vinden in het Privacy- en Gegevensbeschermingsbeleid via de volgende link: Privacy- en Gegevensbeschermingsbeleid.',
  privacy_policy_33: 'Bewaartermijn',
  privacy_policy_34:
    'Rekening houdend met de verwerkingsdoeleinden, is de bewaartermijn van de persoonsgegevens van de gebruikers (bewaartermijn) 24 maanden vanaf de datum waarop uw toestemming voor gegevensverwerking correct is verkregen.',
  privacy_policy_35: 'Recht op inzage',
  privacy_policy_36:
    'U heeft het recht om te weten of persoonsgegevens over u worden verwerkt en 2) indien ja, toegang te krijgen tot deze gegevens met vele aanvullende bepalingen zoals vermeld in AVG Artikel 15.',
  privacy_policy_37: 'Recht op rectificatie',
  privacy_policy_38:
    'Wanneer de persoonsgegevens die u ons verstrekt onjuist zijn, heeft u het recht om ons te vragen deze te corrigeren (AVG Artikel 16).',
  privacy_policy_39: 'Recht op verwijdering (recht om vergeten te worden)',
  privacy_policy_40:
    'U heeft het recht om van ons de verwijdering van uw persoonsgegevens te verkrijgen zonder onnodige vertraging en wij zijn verplicht uw persoonsgegevens zonder onnodige vertraging te verwijderen indien de in AVG Artikel 17 vermelde gronden van toepassing zijn.',
  privacy_policy_41: 'Recht op beperking van verwerking',
  privacy_policy_42:
    'U heeft het recht om de verwerking van uw persoonsgegevens te beperken met enkele uitzonderingen onder de AVG, met name vermeld in AVG Artikel 18.',
  privacy_policy_43:
    'Wij zijn verplicht u te informeren welke gegevens worden verzameld, hoe deze worden gebruikt, hoe lang ze worden bewaard en of ze met derden worden gedeeld. Deze informatie moet beknopt en in duidelijke taal worden gecommuniceerd.',
  privacy_policy_44: 'Recht op overdraagbaarheid van gegevens',
  privacy_policy_45:
    'U mag uw persoonsgegevens verkrijgen en hergebruiken voor uw eigen doeleinden bij verschillende diensten.',
  privacy_policy_46: 'Recht van bezwaar',
  privacy_policy_47:
    'U heeft het recht bezwaar te maken tegen de verwerking van persoonsgegevens die door het bedrijf worden verwerkt. Wij moeten stoppen met het verwerken van persoonsgegevens tenzij wij dwingende gerechtvaardigde gronden voor de verwerking aantonen die zwaarder wegen dan de belangen, rechten en vrijheden van de betrokkene of indien de verwerking nodig is voor de vaststelling, uitoefening of verdediging van juridische claims.',
  privacy_policy_48:
    'Recht om niet onderworpen te worden aan uitsluitend geautomatiseerde verwerking',
  privacy_policy_49:
    'U heeft het recht bezwaar te maken tegen geautomatiseerde profilering die zonder toestemming plaatsvindt. Hierbij heeft u recht op verwerking van uw persoonsgegevens met menselijke tussenkomst.',
  privacy_policy_50: 'Klachten',
  privacy_policy_51:
    'Indien u een klacht wilt indienen over hoe uw persoonsgegevens door het bedrijf (of door verwerkers, zoals hierboven beschreven) worden verwerkt, of hoe uw klacht is afgehandeld, heeft u het recht om rechtstreeks een klacht in te dienen bij de toezichthoudende autoriteit en het bedrijf.',
  privacy_policy_52: 'De contactgegevens voor elk van deze instanties zijn:',
  privacy_policy_53: 'Toezichthoudende autoriteit:',
  privacy_policy_54: 'De Oostenrijkse gegevensbeschermingsautoriteit',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Wenen',
  privacy_policy_58: 'Oostenrijk / Europa',
  privacy_policy_59: 'Bedrijf:',
  privacy_policy_60: '4Service Holdings GmbH (bedrijf/wij/ons),',
  privacy_policy_61: 'Adres: Tegetthoffstraße 7, 1010 Wenen, Oostenrijk.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Privacy- en gegevensbeschermingsbeleid',
  privacy_policy_64:
    'Lees hier meer over hoe en waarom we uw gegevens gebruiken: Privacy- en Gegevensbeschermingsbeleid',
  privacy_policy_65: 'Goedkeuring',
  privacy_policy_66:
    'Het bedrijf heeft alle interne documenten ontwikkeld om rollen binnen het personeel te definiëren met betrekking tot de verwerking van persoonsgegevens binnen het bedrijf. Met name de verantwoordelijke voor het goedkeuren en herzien van de rechtmatigheid van dit document is de algemeen directeur.',
  contacts_1: 'Voor klanten',
  contacts_2:
    'Voor allerlei soorten bedrijven, marktonderzoeksbureaus en grote bedrijven, zakenpartners en media.',
  contacts_3: 'Tegetthoffstrasse 7, Wenen, Oostenrijk',
  contacts_4: 'Voor Shoppers',
  contacts_5:
    'Lokale bewoners en reizigers, chauffeurs en studenten, professionele mystery shoppers en thuisblijvers.',
  contacts_6: 'Neem contact met ons op vanuit uw land:',
  contacts_7: 'Italië',
  contacts_8: 'VS',
  contacts_9: 'VK',
  contacts_10: 'Zwitserland',
  contacts_11: 'Nederland',
  contacts_12: 'Slowakije',
  contacts_13: 'Slovenië',
  contacts_14: 'Roemenië',
  contacts_15: 'AZ',
  contacts_16: 'Oekraïne',
  contacts_17: 'Kazachstan',
  contacts_18: 'Rest van de wereld',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX testen',
  menu_6: 'HR-onderzoek',
  menu_7: 'Kwalitatief onderzoek',
  menu_8: 'Kwantitatief onderzoek',
  menu_9: 'Mystery shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Prijsmonitoring',
  menu_14: 'Over ons',
  menu_15: 'Contacten',
  menu_16: 'Blog',
  menu_17: 'Methoden',
  menu_18: 'Producten',
  menu_19: 'Shoppers \n platform',
  menu_20: 'MVO',
  menu_21: 'Analyse van autoverkoopprijzen',
  menu_22: 'Facility Check Audit',
  menu_23: 'Service Design',
  menu_24: 'CX',
  nps_passives: 'Passieven',
  nps_detractors: 'Criticasters',
  nps_promoters: 'Promoters',
  nps_a_score: 'een score',
  area: 'Gebied',
  products_tabs_1:
    'Om maximale efficiëntie te bereiken, voeren we ook uit in complex:',
  products_tabs_2: 'Producten',
  products_tabs_3:
    'Kwalitatief onderzoek is het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen',
  products_tabs_4:
    'Kwantitatieve onderzoeksmethoden zijn objectieve metingen en numerieke analyses van gegevens verzameld via enquêtes, vragenlijsten en peilingen',
  our_clients_1: 'Onze klanten',
  preview_cases_section_1: 'Cases',
  preview_cases_section_2:
    'Ga naar de cases om meer interessante berichten te lezen',
  blog_1: 'Inspirerende contentblog',
  blog_2: 'Geen berichten beschikbaar voor uw verzoek',
  about: 'Over',
  why_us: 'Waarom wij',
  about_us: 'Over ons',
  key_features: 'Belangrijkste kenmerken',
  solutions: 'Oplossingen',
  monitoring: 'Monitoring',
  how: 'Hoe',
  how_it_works: 'Hoe het werkt',
  when_to_use: 'Wanneer te gebruiken',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Stem van de klant',
  wantent: 'Testen van videocontent',
  play4sales: 'Play4Sales',
  price_monitoring: 'Prijsmonitoring',
  service_design: 'Service Design',
  qualitative_research: 'Kwalitatief onderzoek',
  quantitative_research: 'Kwantitatief onderzoek',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automotive',
  hashtag_retayl: '#Retail',
  hashtag_all_posts: '#Alle_berichten',
  hashtag_feedback: '#feedback',
  hashtag_4Service_csr: '#4Service_MVO',
  hashtag_cases: '#Cases',
  hashtag_market_research: '#markt_onderzoek',
  more: 'Meer',
  speak_to_an_expert: 'Spreek met een expert',
  book_consultancy: 'Plan consultancy',
  read_all: 'Lees alles',
  find_out_more: 'Ontdek meer',
  address: 'Adres',
  mon_fri: 'Ma-Vr',
  phone_number: 'Telefoonnummer',
  flag: 'vlag',
  scroll_to_left: 'scroll naar links',
  online: 'online',
  tel: 'Tel',
  email: 'E-mail',
  light_it_up: 'Laat het oplichten',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Directeur bedrijfsontwikkeling',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Senior projectmanager',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Manager bedrijfsontwikkeling',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Operations Director',
  about_us_roles_1: 'Management',
  about_us_roles_2: 'Team Global',
  about_us_roles_3: 'Producteigenaren',
  ad_testing_1: 'Advertentie testen',
  ad_testing_2:
    'Een reclamecampagne is een enorme investering. Zorg ervoor dat uw advertenties krachtig zijn en maximaal rendement opleveren',
  ad_testing_3: 'slot',
  ad_testing_4: 'Sectoren die we bedienen',
  ad_testing_5:
    '4Service is een wereldwijde holding voor klantbelevingsonderzoek',
  ad_testing_6:
    '4Service is een wereldwijde holding voor markt- en klantbelevingsonderzoek. We bieden deskundige dataverzamelingsdiensten aan een grote verscheidenheid aan bedrijven',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Detailhandel',
  ad_testing_9: 'Elektronica',
  ad_testing_10: 'Luxe/Premium',
  ad_testing_11: 'Eten & Gastvrijheid',
  ad_testing_12: 'Automotive',
  ad_testing_13: 'Reizen & Vrije tijd',
  ad_testing_14: 'Mode',
  ad_testing_15: 'Banken',
  ad_testing_16: 'Horloges & Sieraden',
  ad_testing_17: 'Huishouden & Apparatuur',
  ad_testing_18: 'Esomar-lid',
  ad_testing_19:
    'We hebben onderzoek uitgevoerd naar advertenties voor verschillende bedrijven in diverse sectoren',
  ad_testing_20: 'U heeft advertentietesten nodig als u',
  ad_testing_21: 'Een nieuw product of dienst promoot',
  ad_testing_22: 'Een gevoelig onderwerp aansnijdt',
  ad_testing_23: 'Een nieuwe doelgroep of markt probeert te bereiken',
  ad_testing_24: 'Verschillende ontwerpopties bespreekt',
  ad_testing_25:
    'Een proof of concept nodig heeft voor managementteams of investeerders',
  ad_testing_26: 'U krijgt antwoorden op de vragen',
  ad_testing_27:
    'Welke advertentiekanalen zijn het meest geschikt voor deze advertenties?',
  ad_testing_28:
    'Gebruiken we de juiste visuals om de boodschap over te brengen?',
  ad_testing_29: 'Resoneren uw advertentieteksten en berichten?',
  ad_testing_30:
    'Welke advertentiekanalen zijn het meest geschikt voor deze advertenties?',
  ad_testing_31: 'Wat trekt in eerste instantie de aandacht?',
  ad_testing_32:
    'Is de plaatsing en indeling van de advertentie gemakkelijk te begrijpen?',
  ad_testing_33: 'We evalueren',
  ad_testing_34: 'Branding',
  ad_testing_35:
    'hoe uw merk is gekoppeld aan advertenties en hoeveel het zal worden onthouden',
  ad_testing_36: 'Belangrijke statistieken',
  ad_testing_37: 'Integratie',
  ad_testing_38: 'Herkenning',
  ad_testing_39: 'Pasvorm',
  ad_testing_40: 'Creatief',
  ad_testing_41: 'Hoe uw advertentie opvalt en de aandacht van mensen trekt',
  ad_testing_42: 'Belangrijke statistieken',
  ad_testing_43: 'HERKENNING',
  ad_testing_44: 'AARDIGHEID',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVATIE',
  ad_testing_47: 'Klantenervaring, emotionele betrokkenheid',
  ad_testing_48:
    'Met behulp van kunstmatige intelligentie en deskundige analisten analyseren we de echte emoties van mensen en krijgen we inzichten in hun percepties van advertenties',
  ad_testing_49: 'Beoordeling',
  ad_testing_50: 'Man',
  ad_testing_51: 'Vrouw',
  ad_testing_52: 'Hoge betrokkenheid',
  ad_testing_53: 'Aandacht',
  ad_testing_54: 'Wat vond het publiek leuk en herinnerde het zich?',
  ad_testing_55: 'Actrice',
  ad_testing_56: 'Gelijkheid',
  ad_testing_57: 'Muzikale achtergrond',
  ad_testing_58: 'Positieve emoties',
  ad_testing_59: 'Mensen van verschillende rassen en uiterlijk',
  ad_testing_60: 'Buitenopnames',
  ad_testing_61: 'Wat vond het publiek NIET leuk en herinnerde het zich?',
  ad_testing_62:
    'De stem van de spreker kwam niet overeen met wat er op het scherm gebeurde',
  ad_testing_63: 'Lage aandacht voor het product',
  ad_testing_64: 'Te dynamisch',
  ad_testing_65: 'Moeilijk te begrijpen',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Impressies',
  ad_testing_68: 'Vertoningen',
  ad_testing_69: 'Conversie',
  ad_testing_70: 'Media',
  ad_testing_71:
    'We meten de effectiviteit van een reclamecampagne in samenhang met mediagebruik, waarbij we de impact van mediainvesteringen in verschillende kanalen op de gemonitorde resultaten berekenen.',
  ad_testing_72:
    'We voeren reclameonderzoek uit in elk formaat, platform of kanaal',
  ad_testing_73: 'videocontent',
  ad_testing_74: 'buitenreclame',
  ad_testing_75: 'TV-advertenties',
  ad_testing_76: 'print',
  ad_testing_77: 'digitaal',
  ad_testing_78: 'reclamecampagnes',
  ad_testing_79: 'POS-display en reclame',
  ad_testing_80: 'animatics',
  ad_testing_81: 'Met onze hulp kunt u uw reclame testen in elke fase',
  ad_testing_82: 'Concept',
  ad_testing_83: 'welke ideeën hebben meer potentieel?',
  ad_testing_84: 'Vroege productiefase',
  ad_testing_85: 'welke animatics gaan in productie?',
  ad_testing_86: 'Productie',
  ad_testing_87: 'hoe een video te bewerken zodat deze de aandacht vasthoudt?',
  ad_testing_88: 'Pre-media',
  ad_testing_89:
    'welke communicatiekanalen werken en welk resultaat kan worden verwacht?',
  ad_testing_90: 'Post-testen van advertenties',
  ad_testing_91: 'Productie',
  ad_testing_92:
    'is mijn reclame boeiend? Neemt de effectiviteit van de creativiteit in de loop van de tijd af?',
  ad_testing_93: 'Pre-media',
  ad_testing_94:
    'hoe effectief is mijn reclame in cijfers? hoe verhoudt het zich tot de concurrentie?',
  ad_testing_95: 'Pre-testen van advertenties',
  ad_testing_96: 'Om u van hoogwaardige gegevens te voorzien, gebruiken we',
  ad_testing_97: 'Kwantitatieve onderzoeksmethodologie',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Kwalitatieve onderzoeksmethodologie',
  ad_testing_100: 'Focusgroepen',
  ad_testing_101: 'AI',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Analytische rapporten die u ontvangt',
  ad_testing_104:
    'Ons team van analisten, media-experts en marketeers stelt een op maat gemaakt rapport op met gedetailleerde aanbevelingen',
  ad_testing_age: 'leeftijd',
  ad_testing_ad: 'Advertentie',
  automotive_industry_price_analysis_1: 'Prijsanalyse in de auto-industrie',
  automotive_industry_price_analysis_2:
    'Een analyse van concurrerende prijzen kan helpen om winstverlies te voorkomen, het marktaandeel te vergroten en uw marktpositie te monitoren. Laat ons u helpen bij het kiezen van een succesvolle concurrentiestrategie!',
  automotive_industry_price_analysis_3: 'Vraag een offerte aan',
  automotive_industry_price_analysis_4: 'Onze methodologie',
  automotive_industry_price_analysis_5:
    'Stijlvolle elegante mensen in een autosalon',
  automotive_industry_price_analysis_6:
    'De Mystery Shopping-methode is de enige aanpak die een echte klantervaring nauwkeurig weerspiegelt en een geloofwaardig overzicht biedt.',
  automotive_industry_price_analysis_7:
    'We bieden precieze cijfers en betrouwbare, veelzijdige data',
  automotive_industry_price_analysis_8:
    'We hebben wereldwijde dekking in elk land ter wereld',
  automotive_industry_price_analysis_9:
    'Onze interne middelen omvatten mystery shoppers en auditors',
  automotive_industry_price_analysis_10:
    'We valideren de kwaliteit van onze data',
  automotive_industry_price_analysis_11: 'Onze voordelen:',
  automotive_industry_price_analysis_12:
    'Prijsmonitoring voor specifieke concurrerende modellen (B2B en B2C)',
  automotive_industry_price_analysis_13: 'Monitoren van het kortingsniveau',
  automotive_industry_price_analysis_14:
    'Monitoring van de voorraad bij retailers voor uw merk en concurrenten',
  automotive_industry_price_analysis_15:
    'Monitoring van e-commerceprijzen in de auto-industrie',
  automotive_industry_price_analysis_16: 'Analyse van prijscommunicatie',
  automotive_industry_price_analysis_17:
    'Bijhouden van verzendkosten van retailers',
  automotive_industry_price_analysis_18:
    'Monitoring van promoties en uitverkoop bij retailers',
  automotive_industry_price_analysis_19:
    'Bepalen van maandelijkse betalingsbedragen',
  automotive_industry_price_analysis_20: 'Berekenen van leasebetalingen',
  automotive_industry_price_analysis_21:
    'Analyseren van specifieke prijsfactoren voor elektrische voertuigen (EV’s)',
  automotive_industry_price_analysis_22:
    'Uitvoeren van directe consument versus retailer prijsvergelijkingen',
  automotive_industry_price_analysis_23:
    'Ontwikkelen van abonnementsprijsmodellen',
  automotive_industry_price_analysis_24:
    '4Service is een wereldwijd bedrijf voor klantervaring (CX) met meer dan 20 jaar ervaring',
  automotive_industry_price_analysis_25:
    'U gebruikt gegevens over de betalingsbereidheid van consumenten voor verschillende functies om uw aanbod te optimaliseren en de belangrijkste functies voor uw doelgroep te identificeren.',
  automotive_industry_price_analysis_26:
    'De prijsanalyse van 4Service in de auto-industrie omvat:',
  automotive_industry_price_analysis_27:
    'De laagste prijzen zijn niet altijd de sleutel tot concurrentievermogen voor automerken. Laat ons u helpen een langetermijnprijsstrategie te ontwikkelen',
  automotive_industry_price_analysis_28:
    'van klanten begrijpt dat de prijzen bij alle merken stijgen',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'van klanten zei dat ze nog steeds een auto zouden kopen die ze leuk vinden, zelfs als het merk hen informeert over een komende prijsverhoging,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'van mensen beschouwen leasing en maandelijkse betalingen als het meest geschikte model om een auto te kopen',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'van dealers vermeldde de mogelijkheid van prijsverhogingen tijdens het communicatie-/verkoopproces',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Kerncijfers',
  automotive_industry_price_analysis_37:
    'U zult ontdekken hoe u uw product prijst ten opzichte van andere voertuigen in de line-up.',
  automotive_industry_price_analysis_38:
    'U gebruikt gegevens over de betalingsbereidheid van consumenten voor verschillende functies om uw aanbod te optimaliseren en de belangrijkste functies voor uw doelgroep te identificeren.',
  automotive_industry_price_analysis_39:
    'van klanten zei dat ze nog steeds een auto zouden kopen die ze leuk vinden, zelfs als het merk hen informeert over een komende prijsverhoging',
  automotive_industry_price_analysis_40:
    'U zult in staat zijn om het juiste maandelijkse betalingsbedrag en leasepercentages te bepalen.',
  automotive_industry_price_analysis_41:
    'Welke voordelen voor uw merk of dealer behaalt u:',
  automotive_industry_price_analysis_42: 'U dient een aanvraag in',
  automotive_industry_price_analysis_43:
    'Onze manager neemt contact met u op om de belangrijkste doelstellingen te bepalen',
  automotive_industry_price_analysis_44: 'We voeren een marktonderzoek uit',
  automotive_industry_price_analysis_45:
    'We voorzien u regelmatig van rapporten en aanbevelingen in een online formaat',
  automotive_industry_price_analysis_46: 'Auto met uitverkoopsticker',
  automotive_industry_price_analysis_47: 'We starten het project binnen 1 dag!',
  automotive_industry_price_analysis_48:
    'We bieden een uitgebreid pakket aan rapporten, waaronder fotorapporten, PPT-rapporten en Excel-rapporten, gesegmenteerd op type locatie',
  retail_audit_1: 'VELDMARKETINGAUDIT/retail audit',
  retail_audit_2:
    'Verzamel gedetailleerde gegevens over uw winkel of merk, analyseer groeibarrières en creëer effectieve strategieën om deze te overwinnen',
  retail_audit_3: 'Vraag een offerte aan',
  retail_audit_4:
    '4Service voert ter plaatse winkelcontroles uit namens merken om de effectiviteit van hun promotiestrategieën, velduitvoeringsteams en marketingtactieken te beoordelen bij het bereiken van de beoogde doelgroep.',
  retail_audit_5:
    'We observeren nauwkeurig de uitvoering van promotiecampagnes en zorgen voor naleving van de promotierichtlijnen.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'CX-bureaus in Europa',
  retail_audit_8: 'jaar ervaring',
  retail_audit_9: 'landen dekking',
  retail_audit_10: '1,6 miljoen',
  retail_audit_11: 'evaluaties per jaar',
  retail_audit_12: 'Wat volgen we op?',
  retail_audit_13: 'Aanwezigheid van point-of-sale (POS)-materialen',
  retail_audit_14:
    'Aanwezigheid en activering van merkambassadeurs, naleving van het geplande schema',
  retail_audit_15:
    'Beoordeling van de betrokkenheid van merkambassadeurs bij promotiecampagnes',
  retail_audit_16:
    'Evaluatie van consumenteninteractie, naleving van promotierichtlijnen en merkboodschappen',
  retail_audit_17: 'Maatregelen ter preventie van fraude',
  retail_audit_18:
    'Uw bedrijf kan de resultaten van een veldmarketingaudit gebruiken om:',
  retail_audit_19: 'Naleving van budget en processen te beoordelen',
  retail_audit_20:
    'De werkelijke resultaten te vergelijken met de beoogde doelstellingen',
  retail_audit_21: 'De prestaties van marketingcampagnes te verbeteren',
  retail_audit_22: 'Strategisch plannen voor de toekomst',
  retail_audit_23: 'Mogelijkheden voor kostenreductie te identificeren',
  retail_audit_24: 'Verkopen en conversiepercentages te verbeteren',
  retail_audit_25: 'Het rendement op investering (ROI) te optimaliseren',
  retail_audit_26: 'ZE KOZEN 4SERVICE ALS HUN HANDELSINVESTERINGSPARTNER',
  retail_audit_27:
    'Een toonaangevende internationale fabrikant van tabaksproducten werkte samen met 4Service om zijn merken te verbeteren en de prestaties in de winkel te verbeteren. Onze teams verzorgden veldmarketingaudits en live rapportages van klantbelevingen.',
  retail_audit_28:
    'toename in nauwkeurigheid van planningen door het veldmarketingbureau',
  retail_audit_29: 'toename in het overbrengen van merkboodschappen',
  retail_audit_30: 'toename in BA-betrokkenheid (consumentenactivatie)',
  retail_audit_31:
    'toename in nauwkeurigheid van gegevensverzameling en naleving van GDPR',
  retail_audit_32: 'Welke waardevolle inzichten vinden we meestal:',
  retail_audit_33:
    'Een gebrek aan consistentie of zelfs conflict tussen de voorwaarden van de SLA leidt tot aanzienlijke ongeoorloofde kosten voor de klant',
  retail_audit_34:
    'Onjuiste facturering als gevolg van verwarring tussen de soorten geleverde diensten',
  retail_audit_35:
    'Veldservice die niet wordt uitgevoerd, inclusief personeel dat niet is toegewezen aan de geplande locatie | POS',
  retail_audit_36:
    'Kosten gemaakt op basis van een schatting in plaats van daadwerkelijke gegevens en niet verrekend',
  retail_audit_37:
    'Onjuiste toepassing van de promotierichtlijn met frauduleuze gegevens',
  retail_audit_38: 'Hoe werken we?',
  retail_audit_39:
    'Onze auditor bezoekt aangewezen point-of-sale (POS)-locaties op basis van het activatieplan van het bureau.',
  retail_audit_40:
    'Zij beoordelen belangrijke prestatie-indicatoren (KPI’s) zoals POS-aanwezigheid, betrokkenheid van merkambassadeurs, naleving van promotierichtlijnen en maatregelen ter voorkoming van fraude.',
  retail_audit_41: 'Validatie:',
  retail_audit_42: 'We verzamelen en consolideren de verkregen gegevens.',
  retail_audit_43: 'Een uitgebreid rapport wordt opgesteld.',
  retail_audit_44:
    'Ons validatieteam beoordeelt alle enquêtes grondig op nauwkeurigheid, inclusief naleving van de projectbrief en eventuele aanvullende opmerkingen.',
  retail_audit_45: 'Online Rapportagesysteem:',
  retail_audit_46:
    'Voltooide en gevalideerde vragenlijsten worden binnen 36 uur geüpload naar ons online portaal.',
  retail_audit_47:
    'Klanten hebben toegang tot een persoonlijk accountportaal, waarmee ze de voortgang op elk moment kunnen volgen.',
  retail_audit_48:
    'Een eindrapport met resultaten en aanbevelingen wordt verstrekt.',
  retail_audit_49: 'Spreek met een expert',
  retail_audit_50: 'Onze sterktes:',
  facility_check_audit_1: 'Faciliteit',
  facility_check_audit_2: 'Controle Audit',
  facility_check_audit_3: 'Verbeter de merkervaring in elk detail',
  facility_check_audit_4: 'Ontdek onze aanpak',
  facility_check_audit_5: 'Over',
  facility_check_audit_6: 'Wie we zijn',
  facility_check_audit_7:
    'Als een wereldwijd klantenbelevingsholding biedt 4Service een uitgebreide reeks diensten om merken te helpen uitzonderlijke ervaringen voor hun klanten te creëren en te leveren.',
  facility_check_audit_8: 'Onze Faciliteit Controle Audit-service',
  facility_check_audit_9:
    'is ontworpen om u te helpen ervoor te zorgen dat uw fysieke locaties en merkpresentatie in winkels voldoen aan uw merknormen en een positieve indruk maken op klanten.',
  facility_check_audit_10: 'Oplossingen 1',
  facility_check_audit_11: 'Faciliteit Controle Audit',
  facility_check_audit_12:
    'is een nauwgezette beoordeling die de afstemming van uw winkels of stands op de normen van uw merk evalueert. We verifiëren niet alleen de fysieke eigenschappen, maar meten ook hoe deze elementen de perceptie van uw merk door uw gewaardeerde klanten beïnvloeden.',
  facility_check_audit_13: 'Twee oplossingen',
  facility_check_audit_14: 'Twee implementatiemethoden',
  facility_check_audit_15: 'Methode',
  facility_check_audit_16: 'Faciliteit Controle Audit door Expert Auditors',
  facility_check_audit_17: 'Validatie van Faciliteit Controle',
  facility_check_audit_18: 'Rapporten',
  facility_check_audit_19:
    'U ontvangt rapporten, compleet met visueel bewijs en statistieken, toegankelijk binnen 48 uur',
  facility_check_audit_20:
    'Een goed uitgevoerde Faciliteit Controle Audit kan een aantal voordelen opleveren voor uw merk, waaronder:',
  facility_check_audit_21: 'Verbeterde klantconversie:',
  facility_check_audit_22:
    'Een positieve klantbeleving kan leiden tot meer verkoop en terugkerende klanten. Onze audits kunnen u helpen gebieden te identificeren en aan te pakken die mogelijk invloed hebben op klanttevredenheid.',
  facility_check_audit_23: 'Verbeterd merkimago:',
  facility_check_audit_24:
    'Uw fysieke locaties zijn een belangrijk contactpunt voor klanten en kunnen een aanzienlijke rol spelen in het vormgeven van uw merkimago. Onze audits kunnen u helpen ervoor te zorgen dat uw locaties consistent zijn met uw merkwaarden en een positieve indruk maken op klanten.',
  facility_check_audit_25: 'Waarom wij?',
  facility_check_audit_26: 'Waarom is een Facility Check Audit belangrijk?',
  facility_check_audit_27:
    'Een goed uitgevoerde Facility Check Audit kan een aantal voordelen bieden voor uw merk, waaronder:',
  facility_check_audit_28: 'Verbeterd merkimago:',
  facility_check_audit_29:
    'Uw fysieke locaties zijn een belangrijk contactpunt voor klanten en kunnen een aanzienlijke rol spelen in het vormgeven van uw merkimago. Onze audits kunnen u helpen ervoor te zorgen dat uw locaties consistent zijn met uw merkwaarden en een positieve indruk maken op klanten.',
  facility_check_audit_30: 'Verbeterde klantconversie:',
  facility_check_audit_31:
    'Een positieve klantervaring kan leiden tot meer verkopen en herhalingsaankopen. Onze audits kunnen u helpen gebieden te identificeren en aan te pakken die de klanttevredenheid kunnen beïnvloeden.',
  facility_check_audit_32: 'Wie heeft baat bij',
  facility_check_audit_33: 'Wie heeft een Facility Check Audit nodig?',
  facility_check_audit_34:
    'Een Facility Check Audit is een waardevolle dienst voor elk merk met fysieke locaties en fysieke aanwezigheid. Het is vooral belangrijk voor merken die willen:',
  facility_check_audit_35: 'Zorgen voor naleving van merkstandaarden:',
  facility_check_audit_36:
    'Onze audits kunnen u helpen om gebieden te identificeren waar uw locaties mogelijk afwijken van uw merkstandaarden.',
  facility_check_audit_37: 'Klantervaring verbeteren:',
  facility_check_audit_38:
    'Onze audits kunnen u helpen kansen te identificeren om de klantervaring op uw locaties te verbeteren.',
  facility_check_audit_39: 'Een sterker merk opbouwen:',
  facility_check_audit_40:
    'Onze audits kunnen u helpen ervoor te zorgen dat uw fysieke locaties een positieve indruk maken op klanten.',
  facility_check_audit_41:
    'Uw medewerker of mystery shopper maakt een foto van de locatie.',
  facility_check_audit_42:
    'Vervolgens vullen ze een vragenlijst in en voegen de foto toe.',
  facility_check_audit_43:
    'De validator ontvangt de foto en controleert deze op naleving van de standaarden.',
  facility_check_audit_44: 'Facility Check Audit door Expert Auditors',
  facility_check_audit_45: 'Voorbereidingsfase:',
  facility_check_audit_46: 'Gezamenlijke goedkeuring van het auditschema',
  facility_check_audit_47:
    'Dealers worden voorbereid en geïnformeerd over de aanstaande audit',
  facility_check_audit_48: 'Auditor Training:',
  facility_check_audit_49:
    'Onze ervaren 4Service-trainers voorzien auditors van merkrichtlijnen',
  facility_check_audit_50: 'Fase van faciliteitsaudit:',
  facility_check_audit_51:
    'Auditors, gewapend met speciale autorisatiebrieven, bezoeken locaties',
  facility_check_audit_52:
    'Grondig onderzoek van elk punt, zorgvuldig volgens de richtlijnen',
  facility_check_audit_53:
    'Visuele documentatie, naast evaluaties volgens HQ-vereisten',
  facility_check_audit_54: 'Conclusief PPT-rapport',
  facility_check_audit_55:
    'Een allesomvattend PowerPoint-rapport met bevindingen',
  facility_check_audit_56:
    'Indringende online/offline presentatie van de geauditeerde resultaten',
  facility_check_audit_57: 'Validatie van Facility Check',
  facility_check_audit_58: 'Stappen:',
  facility_check_audit_59: 'Fase van faciliteitsaudit:',
  facility_check_audit_60:
    "Mervertegenwoordigers gebruiken de 4Service-app om ter plaatse foto's te maken",
  facility_check_audit_61:
    'Naleving van strikte visuele en beschrijvende criteria',
  facility_check_audit_62: 'Validatiefase:',
  facility_check_audit_63:
    'Toegewijde validators beoordelen elk rapport nauwgezet',
  facility_check_audit_64:
    "Uitgebreide vergelijking van foto's met merkstandaarden",
  facility_check_audit_65:
    'Annotaties en inzichten geïntegreerd in het rapport voor duidelijkheid',
  facility_check_audit_66:
    'Directe communicatie met dealers voor verduidelijkingen, indien nodig',
  facility_check_audit_67: 'Rapportagefase:',
  facility_check_audit_68:
    'Rapporten, compleet met visueel bewijs en statistieken, beschikbaar binnen twee dagen',
  facility_check_audit_69: 'Neem vandaag contact met ons op',
  cx_solution_1: 'We vinden niet alleen inzichten,',
  cx_solution_2: 'We tillen uw bedrijf naar nieuwe hoogten',
  cx_solution_3:
    'Experts met meer dan 20 jaar klantgerichte ervaring ontdekken complexe oplossingen en bieden u een specifiek actieplan dat bijdraagt aan uw groei',
  cx_solution_4:
    'Ontdek een unieke reeks diensten in één toekomstbestendige oplossing',
  cx_solution_5: 'All-in-one CX-oplossing',
  cx_solution_6: 'Advertentietests',
  cx_solution_7: 'AI & Neuromarketing',
  cx_solution_8: 'Stem van de klant',
  cx_solution_9: 'Desk research',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Mystery shopping',
  cx_solution_12: 'CX-advies',
  cx_solution_13: 'Social Media Listening',
  cx_solution_14: 'Kwantitatief en kwalitatief onderzoek',
  cx_solution_15: 'Service design',
  cx_solution_16: 'Marketingstrategie',
  cx_solution_17: 'UX/UI-tests',
  cx_solution_18: 'Publieksinzichten',
  cx_solution_19: 'We zijn vereerd om bij te dragen aan uw succes',
  cx_solution_20:
    'We passen tools en diensten aan en combineren deze op basis van het doel en de doelstellingen van uw bedrijf om op maat gemaakte resultaten te leveren',
  cx_solution_21: 'Uitgebreid onderzoek',
  cx_solution_22:
    'Ondersteun uw besluitvorming en verkrijg een concurrentievoordeel in een datagerichte markt.',
  cx_solution_23:
    'Kwalitatief onderzoek is het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen',
  cx_solution_24:
    'Kwantitatieve onderzoeksmethoden zijn een objectieve meting en numerieke analyse van gegevens verzameld via peilingen, vragenlijsten en enquêtes',
  cx_solution_25:
    'We maken gebruik van een specifieke set tools op basis van uw specifieke vereisten',
  cx_solution_26: 'Tools',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Computer-Assisted Web Interviewing is een belangrijk hulpmiddel in ons arsenaal. Het stelt ons in staat om enquêtes uit te voeren en gegevens te verzamelen via online platforms met behulp van gespecialiseerde software, wat zorgt voor efficiënte en nauwkeurige dataverzameling voor de marktonderzoekbehoeften van onze klanten.',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Computer-Assisted Telephone Interviewing is een methode die we gebruiken om enquêtes te houden en waardevolle gegevens te verzamelen via de telefoon, met behulp van gespecialiseerde software om het proces te stroomlijnen en nauwkeurigheid te waarborgen in ons marktonderzoek.',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Face-to-Face is een integraal onderdeel van onze aanpak. Het bevordert directe interacties en persoonlijke betrokkenheid met klanten, wat vertrouwen, begrip en effectieve communicatie stimuleert, essentieel voor het opbouwen van duurzame relaties en zakelijk succes.',
  cx_solution_33: 'Desk research',
  cx_solution_34:
    'Desk research omvat uitgebreide gegevensverzameling en analyse. Dit dient als een fundamentele stap in ons onderzoeksproces om inzichten te verzamelen en strategische besluitvorming voor onze klanten te ondersteunen.',
  cx_solution_35: 'SWOT-analyse',
  cx_solution_36:
    'SWOT-analyse is een strategisch beoordelingsinstrument dat onze klanten helpt interne Sterktes en Zwaktes, evenals externe Kansen en Bedreigingen te identificeren. Dit biedt waardevolle inzichten om besluitvorming te informeren en effectieve bedrijfsstrategieën te ontwikkelen.',
  cx_solution_37: 'Etnografisch onderzoek',
  cx_solution_38:
    'Het bestuderen van mensen en culturen door onderdompeling en observatie in hun natuurlijke omgeving, wat vaak leidt tot diepgaande inzichten in gedrag, overtuigingen en sociale dynamiek.',
  cx_solution_39: 'Diepte-interviews',
  cx_solution_40:
    'Diepte-interviews zijn een kwalitatieve onderzoeksmethode die we gebruiken. Dit omvat grondige, één-op-één gesprekken met deelnemers om diep in te gaan op hun perspectieven, ervaringen en meningen, wat rijke inzichten en begrip biedt voor de onderzoeksdoelstellingen en besluitvorming van onze klanten.',
  cx_solution_41: 'Ideeënsessies',
  cx_solution_42:
    'Ideeënsessies zijn samenwerkingsbijeenkomsten die we faciliteren. Ze brengen diverse perspectieven en expertise samen om creatieve ideeën en oplossingen te genereren voor de uitdagingen of kansen van onze klanten, waardoor innovatie wordt gestimuleerd en strategische initiatieven worden bevorderd.',
  cx_solution_43: "Koopersona's",
  cx_solution_44:
    "Koopersona's zijn gedetailleerde profielen die we ontwikkelen. Ze vertegenwoordigen de doelklanten van onze klanten op basis van onderzoek en gegevensanalyse. Dit stelt ons in staat hun behoeften, voorkeuren en gedrag beter te begrijpen en onze strategieën en communicatie af te stemmen om effectief te engageren en te resoneren, wat uiteindelijk zakelijk succes bevordert.",
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping is een strategische dienst die we aanbieden om de interacties tussen een klant en een bedrijf te meten en te monitoren tijdens een vooraf gedefinieerd scenario.',
  cx_solution_47: 'Begeleid controleren',
  cx_solution_48:
    'Begeleid controleren is een gespecialiseerde vorm van mystery shopping waarbij onze beoordelaars klanten begeleiden gedurende hun volledige service-ervaring. Dit biedt uitgebreide inzichten in elk aspect van de klantreis en stelt onze klanten in staat een diepgaand begrip van hun servicelevering te verkrijgen en verbeteringen door te voeren om de algehele klanttevredenheid en loyaliteit te vergroten.',
  cx_solution_49: 'Feedbackverzamelingsanalyse',
  cx_solution_50:
    'Feedbackverzamelingsanalyse is een cruciaal proces dat we uitvoeren. Dit omvat het systematisch verzamelen en analyseren van klantfeedback uit verschillende kanalen, zoals enquêtes, beoordelingen en sociale media. Hiermee kunnen we waardevolle inzichten extraheren, trends identificeren en op gegevens gebaseerde aanbevelingen doen voor onze klanten om hun producten, diensten en algehele klantervaring te verbeteren.',
  cx_solution_51: 'Focusgroepen',
  cx_solution_52:
    'Focusgroepen zijn dynamische onderzoeksbijeenkomsten die we faciliteren. Ze brengen een diverse groep deelnemers samen om specifieke onderwerpen of producten diepgaand te bespreken. Hiermee kunnen we kwalitatieve inzichten verzamelen, attitudes, voorkeuren en percepties ontdekken en ideeën en concepten verkennen, wat uiteindelijk bijdraagt aan de besluitvorming en strategieontwikkeling van onze klanten.',
  cx_solution_53:
    '20 jaar ervaring in het creëren van uitzonderlijke klantervaringen',
  cx_solution_54:
    'Zorg ervoor dat uw boodschap boeit en resoneert bij uw publiek.',
  cx_solution_55:
    'We zijn toegewijd om resultaten te leveren die uw verwachtingen overtreffen.',
  cx_solution_56:
    'We ontdekken complexe oplossingen die bijdragen aan uw groei.',
  cx_solution_57: 'Verhoog uw klantervaring met onze methodologie.',
  cx_solution_58:
    'Customer Journey Mapping draait om het definiëren van een doelpubliek, het begrijpen van hun behoeften en omgeving en vervolgens een plan maken om een boeiende ervaring te bieden.',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Brand Health Tracking-onderzoek kan meten hoe uw merk presteert op bekendheid en gebruik, merkpositionering en merkprestaties.',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'De Customer Satisfaction Index is een score die aangeeft hoe tevreden uw klanten zijn met de producten/diensten van het bedrijf.',
  cx_solution_63:
    'Prijmonitoring is het proces van verzamelen, verwerken en analyseren van de prijzen van concurrenten.',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score is de gouden standaard van klantervaringsstatistieken, gebaseerd op één vraag: Hoe waarschijnlijk is het dat u Organisatie/Product/Dienst aanbeveelt?',
  cx_solution_66: 'HR-onderzoek',
  cx_solution_67:
    'Human Resource Research wordt gebruikt om HR-praktijken en prestaties te evalueren en biedt een gedetailleerde analyse van de huidige ontwikkeling en het beheer',
  cx_solution_68:
    'Usability testing is hoe echte mensen omgaan met een website, app of ander product en het observeren van hun gedrag en reacties daarop',
  cx_solution_69: 'Overige',
  cx_solution_70: 'Experts selecteren de beste methodologie voor uw bedrijf',
  cx_solution_71: 'Laten we beginnen',
  cx_solution_72: '1 - KICK-OFF MEETING',
  cx_solution_73:
    'Tijdens de kick-off meeting identificeren en richten we ons op kerntaken. Resultaat: begrip van het doel, onderzoekstaken, deadlines, methoden en verantwoordelijkheidsgebieden.',
  cx_solution_74: '2 - CONCEPTONTWIKKELING',
  cx_solution_75:
    'De 4Service-analyseafdeling aggregeert gegevens, coördineert de rapportstructuur. Vervolgens presenteren we de resultaten aan de klant, aangevuld met inzichten en aanbevelingen voor implementatie.',
  cx_solution_76: '3 - VELDWERK',
  cx_solution_77:
    'Voor het uitvoeren van het onderzoek rekruteren we deelnemers, voeren we interviews uit en doorlopen we andere basisstadia van het onderzoek. De klant ontvangt getranscribeerde audio- en videointerviews, evenals beknopte samenvattingen.',
  cx_solution_78: '4 - ANALYTICS',
  cx_solution_79:
    'De 4Service-analyseafdeling aggregeert gegevens, coördineert de rapportstructuur. Vervolgens presenteren we de resultaten aan de klant, aangevuld met inzichten en aanbevelingen voor implementatie.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81:
    'Een discussie voeren met de klant over het implementatieplan',
  cx_solution_82: 'Meer informatie over onze best practices',
  cx_solution_83:
    'De Customer Journey Map maakt een snelle analyse mogelijk van alle communicatiekanalen, identificatie van mogelijke tekortkomingen op cruciale contactpunten en een uitgebreid begrip van gebieden die kunnen afwijken van het geplande traject.',
  cx_solution_84: 'Wanneer gebruiken?',
  cx_solution_85:
    'Bouw een gepersonaliseerde reclamecampagne op basis van echte gegevens over doelgroepgroepen',
  cx_solution_86:
    'Ontdek de triggers van verschillende doelgroeppsegmenten en voldoe aan hun behoeften',
  cx_solution_87: 'Richt elke groep nauwkeurig en behaal maximale conversie',
  cx_solution_88:
    'De Accompany Checking-techniek maakt het mogelijk om de mogelijkheden van optimale manieren om uw doelen te bereiken te evalueren.',
  cx_solution_89: 'Het testen van een nieuwe website of product voor lancering',
  cx_solution_90:
    'Het controleren van ontwikkelde processen voordat veranderingen worden doorgevoerd',
  cx_solution_91:
    'Het beoordelen van de website of het product bij dalende verkoop',
  cx_solution_92:
    'Het lanceren van een nieuw product dat is afgestemd op klantvoorkeuren',
  cx_solution_93:
    'Het bestuderen van concurrerende bedrijven om een leidende positie in de markt te verzekeren',
  cx_solution_94:
    'We bieden een uitgebreide 360°-aanpak voor het creëren van een klantgericht servicemodel.',
  cx_solution_95: 'Training en ontwikkeling van personeel',
  cx_solution_96: 'Verbetering van servicenormen',
  cx_solution_97: 'Toename van klantenaantal en verkoop',
  cx_solution_98: 'Het instellen van KPI`s en motivatie voor het personeel',
  cx_solution_99: 'Ontdek onze succesverhalen',
  cx_solution_100: 'Automotive',
  cx_solution_101: 'Tabak',
  cx_solution_102: 'Retail',
  cx_solution_103: 'Kwalitatieve fase',
  cx_solution_104: 'Kwantitatieve fase (segmentatie)',
  cx_solution_105: 'Focusgroepen',
  cx_solution_106: 'Online wereldwijde publieksbereik',
  cx_solution_107: 'Mix van technieken tijdens interviews',
  cx_solution_108: 'Alleen open vragen',
  cx_solution_109: 'Respondent ondersteuning 24/7',
  cx_solution_110:
    'Behoud van belangrijke details en een realistisch beeld van het traject',
  cx_solution_111: 'Marktonderzoek',
  cx_solution_112: 'Diagnostiek van het bedrijf en concurrenten',
  cx_solution_113: 'Bijgewerkte servicemodellen en nieuwe bedrijfsprocessen',
  cx_solution_114: 'Promotie van klantgerichte service',
  cx_solution_115:
    'Implementatie van veranderingen en controle over de ontwikkeling van servicemodellen',
  usage_attitude_1: 'Gebruik en \n Houding (U&A)',
  usage_attitude_2:
    'Verkrijg uitgebreide inzichten in hoe de doelgroep \n uw merk waarneemt, gebruikt en ermee omgaat.',
  usage_attitude_3: 'Spreek met een expert',
  usage_attitude_4: 'Over',
  usage_attitude_5:
    '<b>Ontdek consumentengedrag</b> om voorkeuren en invloeden grondig bloot te leggen',
  usage_attitude_6:
    "<b>Ontdek het 'waarom'</b> achter consumentenkeuzes met onze gedetailleerde analyse",
  usage_attitude_7:
    '<b>Begrijp hoe</b> klanten omgaan met uw producten voor strategische inzichten',
  usage_attitude_8:
    '<b>Verkrijg inzichten in</b> consumentengedrag, gewoonten en voorkeuren voor weloverwogen beslissingen',
  usage_attitude_9: 'Ervaring',
  usage_attitude_10:
    '4Service-experts met meer dan 20 jaar klantgerichte ervaring creëren unieke oplossingen die uw bedrijf helpen groeien',
  usage_attitude_11: 'Contacteer ons',
  usage_attitude_12: 'Voor wie',
  usage_attitude_13: 'Wie profiteert van Brand Usage en Attitude?',
  usage_attitude_14:
    'Diverse industrieën en dienstensectoren zijn geïnteresseerd in:',
  usage_attitude_15: '<b>Herkennen</b> van klantbehoeften en pijnpunten',
  usage_attitude_16:
    '<b>Identificeren</b> van kansen om klantverbruik te stimuleren',
  usage_attitude_17:
    '<b>Ontwikkelen</b> van nieuwe productcategorieën of marketingstrategieën',
  usage_attitude_18:
    '<b>Verkrijgen</b> van een diepgaand begrip van het concurrentielandschap',
  usage_attitude_19:
    'De methode wordt individueel gekozen op basis van het verzoek van de klant',
  usage_attitude_20:
    '<b>Kwalitatief onderzoek</b> omvat het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen.',
  usage_attitude_21:
    '<b>Kwantitatieve onderzoeksmethoden</b> houden in dat gegevens objectief worden gemeten en numeriek worden geanalyseerd door middel van enquêtes, vragenlijsten en polls.',
  usage_attitude_22:
    '<b>Na het verzamelen van gegevens</b>, analyseren experts de informatie en trekken ze conclusies om u de beste aanbevelingen te bieden.',
  usage_attitude_23: 'Wie heeft het nodig',
  usage_attitude_24: 'Uw succesverhaal begint hier',
  usage_attitude_25:
    '<b>U ontvangt een onderzoek</b> met waardevolle inzichten die strategische besluitvorming mogelijk maken, zodat acties gebaseerd zijn op een diep begrip van consumentengedrag.',
  usage_attitude_26:
    '<b>Het onderzoek biedt een stevige basis voor het verfijnen</b> en optimaliseren van merkstrategieën, zodat deze beter aansluiten op consumentvoorkeuren en -attitudes.',
  usage_attitude_27: 'Oplossingen',
  usage_attitude_28: 'Ontdek tal van mogelijkheden met onze oplossingen',
  usage_attitude_29: 'Productvoorkeur binnen categorie',
  usage_attitude_30:
    'Identificeer de topvoorkeuren van consumenten binnen verschillende categorieën, zoals voedsel, dranken, kleding, elektronica en meer.',
  usage_attitude_31: 'Consumptie-/gebruiksgedrag',
  usage_attitude_32:
    'Analyseer de frequentie van consumptie of gebruik van specifieke producten/diensten, en identificeer de gelegenheden en momenten die ermee geassocieerd worden.',
  usage_attitude_33: 'Aankoopgedrag',
  usage_attitude_34:
    'Onderzoek de kanalen die klanten gebruiken voor hun aankopen en identificeer de informatiebronnen waarop zij vertrouwen.',
  usage_attitude_35: 'Consumentmotivaties en behoeften',
  usage_attitude_36:
    'Onderzoek de motivaties en barrières die het gebruik beïnvloeden. Beoordeel de factoren die van invloed zijn op beslissingen van consumenten. Verken de perceptie van de productcategorie en analyseer hoe prijs wordt waargenomen.',
  usage_attitude_37: 'Onze aanpak',
  usage_attitude_38: 'Merkgebruik en houdingonderzoek',
  usage_attitude_39: 'Informatie',
  usage_attitude_40:
    'Enquêtes uitvoeren met zowel kwantitatieve als kwalitatieve methoden',
  usage_attitude_41: 'Aanvang',
  usage_attitude_42:
    'Doelen stellen en methoden bepalen op basis van individuele verzoeken',
  usage_attitude_43: 'Analyse',
  usage_attitude_44: 'Geavanceerde technieken toegepast door onze experts',
  usage_attitude_45:
    'We gebruiken een unieke set tools op basis van uw specifieke behoeften',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Computer-Assisted Telephone Interviewing is een methode die we gebruiken om enquêtes af te nemen en waardevolle gegevens via de telefoon te verzamelen. We maken gebruik van gespecialiseerde software om het proces te stroomlijnen en de nauwkeurigheid te waarborgen in onze marktonderzoeksactiviteiten.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Computer-Assisted Web Interviewing is een essentieel instrument in ons arsenaal waarmee we enquêtes kunnen uitvoeren en gegevens kunnen verzamelen via online platforms met behulp van gespecialiseerde software. Dit zorgt voor een efficiënte en nauwkeurige gegevensverzameling voor de marktonderzoeksbehoeften van onze klanten.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Computer-Assisted Personal Interviewing is een methode die we gebruiken om gegevensverzamelingsprocessen te optimaliseren, interviews soepeler te laten verlopen en nauwkeurige en waardevolle resultaten voor uw bedrijfsbehoeften te garanderen.',
  usage_attitude_52: 'Focusgroepen',
  usage_attitude_53:
    'Focusgroepen zijn dynamische onderzoeksessies die we faciliteren, waarbij een diverse groep deelnemers bijeenkomt om specifieke onderwerpen of producten diepgaand te bespreken. Dit stelt ons in staat kwalitatieve inzichten te verzamelen, houdingen, voorkeuren en percepties te ontdekken en ideeën en concepten te verkennen. Uiteindelijk ondersteunen we hiermee de besluitvorming en strategische ontwikkeling van onze klanten.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Face-to-Face is een integraal onderdeel van onze aanpak. Het bevordert directe interacties en gepersonaliseerde betrokkenheid met klanten en consumenten, waardoor vertrouwen, begrip en effectieve communicatie worden opgebouwd die essentieel zijn voor langdurige relaties en zakelijk succes in focusgroepen.',
  usage_attitude_56: 'Etnografisch onderzoek',
  usage_attitude_57:
    'Mensen en culturen bestuderen door middel van onderdompeling en observatie in hun natuurlijke omgeving, wat vaak leidt tot diepgaande inzichten in gedrag, overtuigingen en sociale dynamiek.',
  thank_you_page:
    'Bedankt voor uw tijd! Onze manager neemt binnenkort contact met u op.',
  back_main: 'Terug naar hoofdmenu',
  opportunities_1: 'Meer informatie over onze cases',
  opportunities_2: 'Ontdek',
  opportunities_3: 'voor uzelf',
  opportunities_4: 'kansen',
  opportunities_5:
    'van internationaal zakendoen samen met onderzoeksorganisatie 4Service',
  opportunities_6:
    'Welke <span>producten</span> kunnen we gebruiken om uw bedrijf te versterken?',
  opportunities_7: 'CX',
  opportunities_8: 'Uitgebreide op maat gemaakte oplossingen voor bedrijven',
  opportunities_9: 'Alle soorten Mystery Shopping',
  opportunities_10:
    'We voeren mystery employee, mystery caller, luxury mystery shopper, automotive mystery shopper en andere typen uit.',
  opportunities_11: 'Maatwerk IT-oplossingen voor bedrijven',
  opportunities_12:
    'Eigen IT-oplossingen met AI, platforms voor feedbackanalyse en transcriptie van gesprekken',
  opportunities_13: 'Marktonderzoek',
  opportunities_14:
    'Voor alle zakelijke verzoeken (Brand Health Tracking, Segmentatie, NPS, eNPS, CJM, Product- en reclameonderzoek, etc.)',
  opportunities_15: 'Audits',
  opportunities_16:
    'We voeren diverse service-audits uit, evenals prijsmonitoring (offline, voor specifieke niches, etc.)',
  opportunities_17: 'Service Design',
  opportunities_18:
    '4Service - gecertificeerde partners van DesignThinkers Academy, biedt corporate trainingen voor teams en organiseert regelmatig workshops voor bedrijven',
  opportunities_19:
    'We delen onze ervaring, maar <span>respecteren en verstoren</span> uw bedrijfsproces niet.',
  opportunities_20:
    'We kennen de moeilijkheden die bedrijven tegenkomen tijdens hun ontwikkelingsreis, en we weten hoe we deze obstakels kunnen overwinnen dankzij internationale succesvolle ervaring. U krijgt niet alleen ervaring, maar ook middelen.',
  opportunities_21: 'We nodigen u uit voor samenwerking!',
  opportunities_22:
    'We bieden een <span>partnerschapsprogramma</span> voor uw bedrijf',
  opportunities_23:
    'Onder het internationaal erkende merk 4Service kunt u positief bijdragen aan het aantrekken van nieuwe klanten.',
  opportunities_24:
    'Onze partners ontvangen uitgebreide ondersteuning, inclusief training, marketingmaterialen en operationele hulp.',
  opportunities_25:
    'Samenwerking met ons realiseert uw plannen zonder uw huidige bedrijfsproces te verstoren.',
  opportunities_26: 'Onze <span>kantoren</span>',
  opportunities_27: 'Onze klanten',
  opportunities_28: 'We hebben meer dan 20 jaar ervaring in CX',
  opportunities_29: 'Landen gedekt',
  opportunities_30: 'duizend',
  opportunities_31: 'Unieke projecten',
  opportunities_32: 'miljoen',
  opportunities_33: 'Bezoeken door mystery shoppers',
  opportunities_34: 'staat in de TOP 10 van Europese CX-bureaus',
  opportunities_35: 'Uw kansen met 4Service',
  opportunities_36: 'Stijging van de winst',
  opportunities_37: 'U krijgt toegang tot internationale opdrachten',
  opportunities_38: 'Unieke CX-aanpak',
  opportunities_39: 'Ondersteuning van experts met 20 jaar ervaring',
  opportunities_40: 'Ondersteuning',
  opportunities_41: 'Ons team zal u ondersteunen om resultaten te behalen',
  opportunities_42: 'Verkoop',
  opportunities_43: 'We helpen u bij het opzetten van een verkoopsysteem',
  opportunities_44: 'Toegang tot software',
  opportunities_45: 'U krijgt toegang tot alle gecertificeerde IT-tools',
  opportunities_46: 'Training',
  opportunities_47:
    'We trainen niet alleen uw personeel, maar blijven ook adviesdiensten verlenen',
  opportunities_48: 'Voor wie is partnerschap geschikt?',
  opportunities_49: 'Is uw bedrijf gerelateerd aan marktonderzoek?',
  opportunities_50:
    'Of bent u van plan uzelf uit te proberen in een nieuw vakgebied',
  opportunities_51: 'Plant u om:',
  opportunities_52: 'Innovaties door te voeren',
  opportunities_53: 'Samen te werken met internationale merken',
  opportunities_54: 'Uw productportfolio uit te breiden',
  opportunities_55: 'Uw marktpositie te versterken?',
  opportunities_56: 'Uw medewerkers op te leiden',
  opportunities_57: 'Uw bedrijf op te schalen',
  opportunities_58: 'Onze middelen = Uw middelen',
  opportunities_59:
    'Gezien onze uitgebreide ervaring, zijn we bereid deze met u te delen tijdens de eerste fase van de samenwerking',
  opportunities_60: 'Data-analyse:',
  opportunities_61: 'Internationaal analytisch team',
  opportunities_62: 'Gegevensverwerkingsmethoden - SPSS, Power BI',
  opportunities_63:
    'Innovatieve methodologieën en een breed scala aan methoden',
  opportunities_64: 'Rapportage in elk gewenst formaat',
  opportunities_65: 'Software:',
  opportunities_66: 'Eigen op maat gemaakte IT-ontwikkelingen',
  opportunities_67: 'DigSee Sure voor CAPI',
  opportunities_68: 'SurveyMonkey voor CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (callcenter)',
  opportunities_71: 'Marketingondersteuning:',
  opportunities_72: 'PR-ondersteuning',
  opportunities_73: 'Ondersteuning bij promotie en het maken van content',
  opportunities_74: 'Het opzetten van leadgeneratieprocessen',
  opportunities_75: 'IT-ondersteuning:',
  opportunities_76:
    'Technische ondersteuning bij installatie, configuratie en gebruik van onze software.',
  opportunities_77: 'Hulp op afstand',
  opportunities_78: 'Beveiligingscontrole',
  opportunities_79: 'Operationele ondersteuning:',
  opportunities_80: 'Ondersteuning bij het opzetten van veldprocessen',
  opportunities_81: 'Anti-fraudesysteem',
  opportunities_82: 'Werving van elke complexiteit',
  opportunities_83: 'Operationeel beheer van het project en veldafdelingenteam',
  opportunities_84: 'Kwaliteitscontrole van werving',
  opportunities_85: 'UpWork',
  opportunities_86: 'Welke bonussen ontvangt u?',
  opportunities_87: 'Toename van het klantenbestand',
  opportunities_88: 'Versterking van het marktimago',
  opportunities_89: 'Diversificatie van risico"s op klantenverlies',
  opportunities_90: 'Optimalisatie van technologische processen',
  opportunities_91: 'Internationale ervaring',
  opportunities_92: 'Meer verkoop aan bestaande klanten',
  main_67:
    'Analyse van concurrerende prijzen optimaliseert aanbiedingen, verhoogt de winst en vergroot het marktaandeel door gebruik te maken van gegevens over de betalingsbereidheid van consumenten om belangrijke kenmerken te identificeren',
  main_68:
    'Zorgt ervoor dat uw winkels voldoen aan de merkstandaarden, waarbij fysieke kenmerken worden geëvalueerd en hun impact op de perceptie van klanten wordt geanalyseerd',
  main_69: 'CX-oplossing',
  main_70:
    'Ontdek onze unieke 360-graden CX-oplossing: we passen tools en diensten aan op basis van uw doelen om uw bedrijf te stimuleren',
  main_71: 'Spraakanalyse',
  main_72:
    'Onze geavanceerde spraakanalysetool zet gesproken taal om in tekst, waardoor bedrijven waardevolle inzichten krijgen om de klantervaring te verbeteren, prestaties te monitoren, naleving te waarborgen en groei te stimuleren',
  parthership: 'Partnerschap',
  banner_1: 'CX-advies en -diensten: een 360°-overzicht van uw bedrijf',
  banner_2: 'Blijf op de hoogte van het laatste onderzoek!',
  banner_3:
    'Ik geef toestemming voor de verwerking van persoonsgegevens volgens het privacybeleid',
  banner_4: '*Selecteer de markt',
  banner_5: 'Bedankt voor uw abonnement!',
  banner_6: 'We delen alleen de meest interessante inhoud met u!',
  banner_7: 'Verzenden',
  banner_8: 'Oekraïens',
  banner_9: 'Kazachstan',
  brand_awareness_1: 'Merkbekendheid',
  brand_awareness_2: 'en Gebruik',
  brand_awareness_3:
    'Ontgrendel het potentieel van uw merkbekendheid en gebruik voor zakelijk succes',
  brand_awareness_4: 'Praat met een expert',
  brand_awareness_5: 'Over',
  brand_awareness_6:
    '<b>Beoordeel de mate van</b> herkenning en bekendheid van uw merk',
  brand_awareness_7:
    '<b>Bepaal het niveau van merk</b> bekendheid in uw doelmarkt',
  brand_awareness_8:
    '<b>Houd een nauwlettend oog</b> op retentie- en loyaliteitspercentages',
  brand_awareness_9:
    '<b>Kies de optimale strategie</b> om de benodigde indicatoren te verbeteren',
  brand_awareness_10: 'Ontdek talloze mogelijkheden met onze oplossingen',
  brand_awareness_11: 'Merkbekendheid',
  brand_awareness_12: 'Merkgebruik',
  brand_awareness_13:
    '<b>Ontdek</b> uw merkherkenning bij consumentensegmenten',
  brand_awareness_14: '<b>Monitor</b> het bewustzijnsniveau onder uw doelgroep',
  brand_awareness_15:
    '<b>Bestudeer</b> de spontane gedachten van consumenten die op het punt staan een aankoop te doen',
  brand_awareness_16:
    '<b>Begrijp</b> de primaire bronnen die bijdragen aan uw merkbekendheid',
  brand_awareness_17:
    '<b>Overweeg</b> het aantal consumenten dat ervaring heeft met de producten of diensten van uw merk',
  brand_awareness_18:
    '<b>Identificeer</b> de consumenten die na de eerste kennismaking trouw bleven aan uw merk',
  brand_awareness_19: '<b>Ontgrendel</b> de kans dat uw merk wordt aanbevolen',
  brand_awareness_20:
    '<b>Onthul</b> het percentage consumenten dat uw merk overweegt voor toekomstig gebruik',
  brand_awareness_21: 'Wie profiteert van Merkbekendheid en Gebruik?',
  brand_awareness_22: 'Bedrijven die een nieuw product lanceren',
  brand_awareness_23: 'Bedrijven die een gevestigd merk nieuw leven inblazen',
  brand_awareness_24:
    'Elk bedrijf dat wil begrijpen en verbeteren hoe hun merk wordt herkend en gebruikt door consumenten',
  brand_awareness_25:
    'Onze methodologieën bieden waardevolle inzichten om uw merkbekendheid en gebruik te optimaliseren',
  brand_awareness_26:
    'De methodologie wordt individueel geselecteerd op basis van het verzoek van de klant',
  brand_awareness_27: 'Merkgebruik trechtermethodologie',
  brand_awareness_28:
    'Het proefpercentage geeft het aandeel van de doelgroep weer dat bekend is met het merk en de producten of diensten heeft geprobeerd.',
  brand_awareness_29:
    'Het herhalingspercentage geeft het percentage van de doelgroep weer dat bekend is met het merk en de producten of diensten heeft geprobeerd, en die de afgelopen 12 maanden aankopen bleef doen of de aanbiedingen bleef gebruiken.',
  brand_awareness_30:
    'Het retentiepercentage onthult het aandeel individuen dat consequent de producten of diensten van het merk heeft gebruikt, wat het percentage weergeeft dat deze aanbiedingen in de afgelopen 6 maanden heeft gebruikt.',
  brand_awareness_31:
    'Het retentiepercentage illustreert het percentage klanten dat, na gebruik van de producten/diensten van het merk in de afgelopen 6 maanden, trouw blijft aan het merk en deze blijft kiezen.',
  brand_awareness_32: 'Merkbekendheid methodologie',
  brand_awareness_33:
    'Top of mind (TOM) vertegenwoordigt het eerste merk dat spontaan door de respondent wordt genoemd.',
  brand_awareness_34:
    'Totale spontane bekendheid vertegenwoordigt het totaal van spontane vermeldingen, inclusief TOM.',
  brand_awareness_35:
    'Gesteunde bekendheid onthult hoe goed uw merk wordt herkend door gebruikers wanneer er een prompt wordt gegeven.',
  brand_awareness_36: 'Merkattributen prestatiekaart',
  brand_awareness_37:
    'Dit hulpmiddel beoordeelt en visualiseert het belang van verschillende merkattributen, en biedt inzichten in hoe elk bijdraagt aan de algehele prestaties van uw merk en de perceptie van consumenten.',
  brand_awareness_38:
    'Ontdek de sleutels tot het vergroten van de impact van uw merk met onze uitgebreide analyse.',
  brand_awareness_39: 'Merk perceptiekaart',
  brand_awareness_40:
    'Dit hulpmiddel biedt een visuele weergave van consumenteninzicht in een concurrerend marktlandschap. Deze benadering is cruciaal voor het duidelijk onderscheiden van verschillen tussen merken, en benadrukt cruciale marktkenmerken.',
  brand_awareness_41:
    'Het stroomlijnt de observatie en interpretatie van relaties tussen merken en deze bepalende kenmerken.',
  brand_awareness_42:
    'De nabijheid van een specifiek merk tot bepaalde kenmerken geeft een sterke associatie met deze eigenschappen aan. Evenzo geeft de nabijheid tussen twee merken hun overeenkomsten aan, wat wijst op gedeelde beelden en bijgevolg een affiliatie binnen hetzelfde marktsegment.',
  consumer_journey_map_1: 'Consumentreis Kaart',
  consumer_journey_map_2:
    'Ontdek en overwin barrières in uw bedrijf met onze Consumentreis Kaart',
  consumer_journey_map_3: 'VERTROUWD DOOR',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Ontgrendel het potentieel van CJM om de interacties tussen uw consumenten en uw merk te optimaliseren',
  consumer_journey_map_6:
    'Bepaal welke stappen de consument neemt voordat hij een product koopt',
  consumer_journey_map_7:
    'Verkrijg inzichten van uw echte klanten, niet van denkbeeldige',
  consumer_journey_map_8:
    'Krijg een uitgebreid begrip van de ervaringen van uw klanten',
  consumer_journey_map_9:
    'Identificeer gemiste kansen en vergelijk uzelf met uw concurrenten',
  consumer_journey_map_10: 'Ontdek onze CJM-aanpak',
  consumer_journey_map_11:
    'We creëren een pad van consumentinteractie met een product, dienst, bedrijf of merk via verschillende kanalen en gedurende een bepaalde tijdsperiode.',
  consumer_journey_map_12:
    'We schetsen de fasen die uw consumenten doorlopen voordat ze een aankoop voltooien, evenals de verwachtingen, emoties en motivaties van de klant in elke fase.',
  consumer_journey_map_13: 'Profiteer met ons',
  consumer_journey_map_14:
    'We bieden een team van ervaren professionals die de complexiteit van wereldwijd zakelijk succes begrijpen',
  consumer_journey_map_15:
    'We zijn toegewijd om resultaten te leveren die uw verwachtingen overtreffen',
  consumer_journey_map_16:
    'We ontdekken complexe oplossingen die bijdragen aan uw groei',
  consumer_journey_map_17:
    'We zijn gespecialiseerd in het bieden van op maat gemaakte CJM-oplossingen',
  consumer_journey_map_18: 'Boek een consult',
  consumer_journey_map_19: 'Gegevensgestuurde beslissingen',
  consumer_journey_map_20:
    'Baseer uw beslissingen op echte gegevens in plaats van aannames',
  consumer_journey_map_21: 'Optimaliseer uw CJM',
  consumer_journey_map_22:
    'Bouw sterkere relaties met klanten door in te spelen op hun behoeften en verwachtingen in elke fase van hun reis',
  consumer_journey_map_23: 'Neem de leiding',
  consumer_journey_map_24:
    'Vergelijk uzelf met uw concurrenten en blijf de markt voor',
  consumer_journey_map_25: 'Aanbevelingen',
  consumer_journey_map_26:
    'Implementeer onze CJM-aanbevelingen om succes te ontgrendelen, klantloyaliteit te bevorderen, verkoop te stimuleren en uw bedrijfsresultaat te maximaliseren',
  consumer_journey_map_27:
    'Ontdek de echte consumentreis met onze Consumentreis Kaart',
  consumer_journey_map_28:
    'Experts met meer dan 20 jaar ervaring bieden een uitgebreide analyse',
  consumer_journey_map_29:
    'Kwalitatief onderzoek omvat het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen.',
  consumer_journey_map_30:
    'Kwantitatieve onderzoeksmethoden omvatten het objectief meten en numeriek analyseren van gegevens verkregen via enquêtes, vragenlijsten en onderzoeken.',
  consumer_journey_map_31:
    'Na het verzamelen van gegevens analyseren experts de informatie en trekken ze conclusies om u de beste aanbevelingen te bieden.',
  boarding_1: 'Klantreis kaart',
  boarding_2:
    'Customer Journey Mapping (CJM) gaat over het definiëren van een doelgroep, het begrijpen van hun behoeften en omgeving, en het vervolgens creëren van een plan om een boeiende ervaring te bieden',
  boarding_3: 'Net Promotie Score',
  boarding_4:
    'Net Promoter Score (NPS) is de gouden standaard van klantervaringsstatistieken, gebaseerd op één vraag: Hoe waarschijnlijk is het dat u Organisatie/Product/Dienst zou aanbevelen?',
  boarding_5: 'Merkgezondheid Tracking',
  boarding_6:
    'Merkgezondheid Tracking (BHT) onderzoek kan meten hoe uw merk presteert op bewustzijn en gebruik, merkpositionering en merkprestaties',
  boarding_7: 'Onderzoek naar Human Resources',
  boarding_8:
    'Onderzoek naar Human Resources wordt gebruikt om HR-praktijken en prestaties te evalueren en biedt gedetailleerde analyses van de huidige ontwikkeling en management',
  boarding_9: 'UX testen',
  boarding_10:
    'Gebruiks (UX) testen gaat over hoe echte mensen omgaan met een website, app of ander product en het observeren van hun gedrag en reacties daarop',
  boarding_11: 'Klanttevredenheidsindex',
  boarding_12:
    'Klanttevredenheid meet in hoeverre de verwachtingen van de klant zijn voldaan met betrekking tot de aankoop van een product of dienst geleverd door uw bedrijf',
  boarding_13: 'Advertentie testen',
  boarding_14:
    'Een reclamecampagne is een grote investering. Zorg ervoor dat uw advertenties krachtig zijn en een maximaal rendement opleveren',
  boarding_15: 'Veldcontrole/Winkelcontrole',
  boarding_16:
    'Verzamel gedetailleerde gegevens over uw winkel of merk, analyseer groeibarrières en ontwikkel effectieve strategieën om deze te overwinnen',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Prestatiebeheer Software. Een gamified applicatie om de verkoop te verhogen',
  boarding_19: 'Voicer',
  boarding_20:
    'Feedback Management Systeem. De stem van uw klant in één systeem. Klantfeedbackbeheersysteem',
  boarding_21: 'Kwalitatief onderzoek',
  boarding_22:
    'Kwalitatief onderzoek omvat het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen',
  boarding_23: 'Kwantitatief onderzoek',
  boarding_24:
    'Kwantitatieve onderzoeksmethoden zijn een objectieve meting en numerieke analyse van gegevens verzameld via enquêtes, vragenlijsten en peilingen',
  boarding_25: 'Mystery Shopping',
  boarding_26:
    'Mystery shopping is een marktonderzoekstechniek om de interacties tussen een klant en een bedrijf of organisatie tijdens een vooraf gedefinieerd scenario te meten en te monitoren',
  boarding_27: 'Wantent',
  boarding_28:
    'Een AI-gestuurde content intelligence platform dat inzichten biedt vanuit het publiek',
  boarding_29: 'Prijsmonitoring',
  boarding_30:
    'Prijsmonitoring is het proces van verzamelen, verwerken en analyseren van de prijzen van concurrenten',
  boarding_31: 'Service design',
  boarding_32:
    'Service design is hoe u de behoeften en wensen begrijpt van de mensen die een product of dienst zullen gebruiken door tijd met hen door te brengen',
  boarding_33: 'Prijsanalyse van de auto-industrie',
  boarding_34:
    'Concurrentieprijsanalyse optimaliseert aanbiedingen, verhoogt winsten en verbetert het marktaandeel door gebruik te maken van gegevens over de betalingsbereidheid van consumenten om belangrijke functies te identificeren',
  boarding_35: 'Faciliteit Controle Audit',
  boarding_36:
    'Zorgt ervoor dat uw winkels voldoen aan merknormen door fysieke attributen en hun impact op de perceptie van klanten te evalueren',
  boarding_37: 'CX oplossing',
  boarding_38:
    'Ontdek onze unieke 360-graden CX-oplossing: we passen tools en diensten aan op basis van uw doelstellingen om uw bedrijf te stimuleren',
  boarding_39: 'Spraakanalyse',
  boarding_40:
    'Onze geavanceerde spraakanalysetool zet gesproken taal om in tekst en biedt waardevolle inzichten voor bedrijven om de klantervaring te verbeteren, prestaties te monitoren, naleving te waarborgen en groei te stimuleren',
  boarding_41: 'Gebruik en Houding',
  boarding_42:
    'Gebruik en Houding is onze benadering om te begrijpen hoe doelgroepen omgaan met uw merk door praktische gebruikspatronen en psychologische houdingen te analyseren, waardoor op maat gemaakte marketing- en productstrategieën mogelijk worden gemaakt',
  boarding_43: 'Merkbewustzijn en gebruik',
  boarding_44:
    'Merkbewustzijn en gebruik omvat het evalueren van herkenning, bekendheid, interactiefrequentie en retentie binnen uw doelmarkt om de merkzichtbaarheid en betrokkenheid te verbeteren',
  boarding_45: 'Merkidentiteit en Positionering',
  boarding_46:
    'Merkidentiteit en positionering is een uitgebreide analyse die consumentenpercepties, diverse publieksinzichten en inzichten in hoe uw merk wordt waargenomen onthult',
  boarding_47: 'Klantprofielen en segmentatie',
  boarding_48:
    'Klantprofielen en segmentatie omvat het verdelen van uw doelmarkt in verschillende groepen op basis van gedeelde kenmerken, waardoor gepersonaliseerde strategieën kunnen worden ontwikkeld die aansluiten bij specifieke doelgroepsegmenten',
  boarding_49: 'Consument Reiskaart',
  boarding_50:
    'Een Consument Reiskaart is een visuele weergave van de stadia, contactpunten, emoties en motivaties die een consument ervaart tijdens hun interactie met uw product of merk',
  boarding_51:
    'Leer meer over innovatieve technieken die onze toewijding aan het leveren van bruikbare inzichten en het stimuleren van impactvolle resultaten voor uw bedrijf ondersteunen',
  boarding_52:
    'Ontdek onze productreeks met een schat aan datagedreven intelligentie die is afgestemd op uw branche, zodat u weloverwogen beslissingen kunt nemen en voorop blijft lopen',
  boarding_53:
    'Duik diep in markttrends, consumentengedrag en concurrerende landschappen met onze uitgebreide marktonderzoeksoplossingen',
  boarding_54: 'Ad Hoc Onderzoek',
  boarding_55:
    'Ad Hoc Onderzoek is een aangepaste aanpak ontworpen om een specifiek probleem aan te pakken, met behulp van een mix van bestaande methodologieën of een nieuwe aanpak om een op maat gemaakte oplossing te creëren',
  boarding_56: 'Sociologisch Onderzoek',
  boarding_57:
    'Sociologisch Onderzoek onthult patronen van menselijk gedrag en sociale interacties, en biedt waardevolle inzichten om innovatieve strategieën te stimuleren en zinvolle connecties met doelgroepen te creëren',
  not_found_1: 'Oeps! We kunnen die pagina niet vinden.',
  not_found_2: 'Waarom kijkt u niet naar onze producten?',
  not_found_3: 'Plan een consult',
  customer_profiling_segmentation_1: 'Klantprofilering en segmentatie',
  customer_profiling_segmentation_2:
    'We helpen u uw doelgroep nauwkeurig te bepalen, waardoor uw strategie effectiever wordt',
  customer_profiling_segmentation_3:
    'Maak gebruik van de kracht van marktsegmentatie om ervoor te zorgen dat uw boodschappen altijd effectief uw doelgroepen bereiken',
  customer_profiling_segmentation_4: 'Begrijp consumenten dieper',
  customer_profiling_segmentation_5: 'Vind de beste doelgroep voor uw product',
  customer_profiling_segmentation_6:
    'Ontdek nieuwe segmenten om aan te trekken',
  customer_profiling_segmentation_7: 'Ontdek barrières voor aankoop',
  customer_profiling_segmentation_8:
    'Wij zijn gespecialiseerd in het leveren van op maat gemaakte segmentatieoplossingen',
  customer_profiling_segmentation_9: 'Unieke aanpak',
  customer_profiling_segmentation_10:
    'Ervaar een unieke, op maat gemaakte segmentatieoplossing die specifiek op uw behoeften is afgestemd',
  customer_profiling_segmentation_11: 'Doelpersonages',
  customer_profiling_segmentation_12:
    'Gemaakte doelpersonages gebaseerd op diepgaande marktanalyse en uitgebreid onderzoek',
  customer_profiling_segmentation_13: 'Nieuwe segmenten',
  customer_profiling_segmentation_14:
    'Identificeer uw meest waardevolle doelgroepen en benut de voordelen van gerichte targeting',
  customer_profiling_segmentation_15: 'Aanbevelingen',
  customer_profiling_segmentation_16:
    'Implementeer onze aanbevelingen en bereik succes, bevorder klantloyaliteit, verhoog de verkoop en maximaliseer uw bedrijfsresultaat',
  customer_profiling_segmentation_17:
    'Experts met meer dan 20 jaar ervaring bieden uitgebreid onderzoek',
  customer_profiling_segmentation_18:
    'Kwalitatief onderzoek omvat het verzamelen en analyseren van niet-numerieke gegevens om concepten, meningen of ervaringen te begrijpen.',
  customer_profiling_segmentation_19:
    'Kwantitatieve onderzoeksmethoden houden in dat gegevens die zijn verkregen via enquêtes, vragenlijsten en onderzoeken objectief worden gemeten en numeriek worden geanalyseerd.',
  customer_profiling_segmentation_20:
    'Na het verzamelen van gegevens analyseren experts de informatie en trekken conclusies om u de beste aanbevelingen te bieden.',
  customer_profiling_segmentation_21:
    'We doen niet alleen metingen, we bieden u ook een actieplan met duidelijke stapsgewijze acties',
  customer_profiling_segmentation_22: 'Onze methodologie',
  customer_profiling_segmentation_23: 'Jaccard-index',
  customer_profiling_segmentation_24:
    'Wanneer respondenten rechtstreeks worden gevraagd, komen hun antwoorden over bedrijfsvoorkeuren mogelijk niet overeen met hun werkelijke gedrag. In plaats van directe vragen maken we gebruik van statistische Jaccard-analyse om de relatie tussen bedrijfskenmerken en consumentkeuze te begrijpen.',
  customer_profiling_segmentation_25: 'Drijfveren',
  customer_profiling_segmentation_26:
    'We identificeren drijfveren (factoren die een bepaald resultaat beïnvloeden) en categoriseren deze in belangrijke, middelmatige en onbelangrijke drijfveren op basis van hun belang.',
  customer_profiling_segmentation_27: 'Segmentatie',
  customer_profiling_segmentation_28:
    'U krijgt segmentatie met duidelijke kenmerken van klantkeuzes.',
  customer_profiling_segmentation_29: 'Onze aanpak van segmentatie',
  customer_profiling_segmentation_30: 'Demografisch',
  customer_profiling_segmentation_31: 'leeftijd',
  customer_profiling_segmentation_32: 'geslacht',
  customer_profiling_segmentation_33: 'inkomen',
  customer_profiling_segmentation_34:
    'Het helpt producten, diensten en marketing af te stemmen op specifieke groepen, waardoor relevantie en effectiviteit worden verbeterd',
  customer_profiling_segmentation_35: 'Psychografisch',
  customer_profiling_segmentation_36: 'levensstijl',
  customer_profiling_segmentation_37: 'waarden',
  customer_profiling_segmentation_38: 'gedragingen',
  customer_profiling_segmentation_39:
    'Het maakt gepersonaliseerde marketing, diepere verbindingen met klanten en verhoogde loyaliteit en verkoop voor het bedrijf mogelijk',
  customer_profiling_segmentation_40: 'Gedragsmatig',
  customer_profiling_segmentation_41: 'acties',
  customer_profiling_segmentation_42: 'motivatie',
  customer_profiling_segmentation_43: 'aankoopfrequentie',
  customer_profiling_segmentation_44:
    'Het maakt gerichte marketing, verbeterde klanttevredenheid, verhoogde retentie en verbeterde winstgevendheid voor het bedrijf mogelijk',
  customer_profiling_segmentation_45: 'Geografisch',
  customer_profiling_segmentation_46: 'locatie',
  customer_profiling_segmentation_47:
    'Het maakt gerichte marketing, aangepaste producten, efficiënte distributie en verhoogde marktpenetratie voor het bedrijf mogelijk.',
  brands_identity_1: 'Merkidentiteit en positionering',
  brands_identity_2:
    'Transformeer uw merk met onze diepgaande analyse, onthul inzichten in consumentpercepties en verfijn uw strategie voor succes',
  brands_identity_3: 'Spreek met een expert',
  brands_identity_4: '<b>Ontdek een uitgebreide</b> merkidentiteitsanalyse',
  brands_identity_5:
    '<b>Verken inzichten</b> verzameld over consumentpercepties',
  brands_identity_6: '<b>Begrijp diverse</b> meningen van doelgroepen',
  brands_identity_7:
    '<b>Verkrijg inzichten in</b> consumentgedrag, gewoonten en voorkeuren voor weloverwogen beslissingen',
  brands_identity_8:
    '4Service-experts met meer dan 20 jaar ervaring in klantbeleving creëren unieke oplossingen die uw bedrijf helpen groeien',
  brands_identity_9:
    '<b>U krijgt toegang tot een onderzoekspakket met alle essentiële gegevens.</b> Het dient als een diagnostisch hulpmiddel dat sterke punten benadrukt en aandachtspunten identificeert',
  brands_identity_10:
    '<b>Onze diensten bieden bedrijven de noodzakelijke tools</b> om weloverwogen beslissingen te nemen, strategieën te verfijnen en succes te stimuleren in hun respectieve sectoren.',
  brands_identity_11:
    'Om de positie van uw merk te verbeteren in de concurrerende markt',
  brands_identity_12: 'Bedrijven die hun marktpositie willen verbeteren',
  brands_identity_13: 'Bedrijven die hun strategie willen verbeteren',
  brands_identity_14: 'Merkidentiteit',
  brands_identity_15: 'Merkpositionering',
  brands_identity_16:
    '<b>Ontdek de perceptie van uw merk</b> binnen consumentensegmenten.',
  brands_identity_17:
    '<b>Identificeer de kwaliteiten</b> die worden toegeschreven aan uw merk en concurrenten.',
  brands_identity_18:
    '<b>Evalueer sterke</b> en zwakke punten voor strategische verbeteringen.',
  brands_identity_19:
    '<b>Beoordeel de afstemming</b> van uw merkimago met de gecommuniceerde waarden.',
  brands_identity_20:
    '<b>Evalueer de waargenomen</b> betrouwbaarheid van uw merk.',
  brands_identity_21:
    '<b>Verken de archetypische</b> en symbolische perceptie van uw merk.',
  brands_identity_22:
    '<b>Identificeer de unieke verkoopargumenten</b> en onderscheidende factoren van uw merk in vergelijking met concurrenten.',
  brands_identity_23:
    '<b>Bepaal de belangrijkste kenmerken</b> die de kans op consumenten aankopen van uw producten/diensten beïnvloeden.',
  brands_identity_24:
    '<b>Bepaal hoe uw merk momenteel wordt gepositioneerd</b> in de gedachten van consumenten en identificeer aspecten om te communiceren om de gewenste marktsegmenten aan te trekken.',
  brands_identity_25:
    'De methodologie wordt individueel geselecteerd op basis van het verzoek van de klant',
  brands_identity_26: 'Merkbewustzijn en Gebruik',
  brands_identity_27:
    'Het evalueren van het merkniveau omvat het meten van bewustzijn en gebruiksniveaus, wat een fundamenteel onderdeel vormt van de beoordeling.',
  brands_identity_28: 'Merklevering en Vertrouwen',
  brands_identity_29:
    'Deze metriek meet klantenadvocatuur (aanbeveling) en het vermogen van het merk om consequent beloftes na te komen, positieve klantervaringen te creëren en vertrouwen op te bouwen.',
  brands_identity_30: 'Prestatie van Merkimago',
  brands_identity_31:
    'Het beoordelen van de prestaties van het merk omvat de evaluatie van de associatie met zes cruciale waarden (3 rationeel en 3 emotioneel), de onderscheidendheid binnen de categorie en het vermogen om trots te creëren bij consumenten.',
  brands_identity_32: 'Merkgezondheidsindex',
  brands_identity_33:
    'De Merkgezondheidsindex geeft u een uitgebreid inzicht in hoe uw merk presteert in de concurrerende omgeving.',
  sociological_research_1: 'Sociologisch onderzoek',
  sociological_research_2:
    'We voeren een diepgaande analyse uit van sociale fenomenen en processen, wat helpt bij het nemen van geïnformeerde beslissingen met behulp van unieke analytische conclusies en aanbevelingen',
  sociological_research_3: 'Neem contact met ons op',
  sociological_research_4: 'Onze klanten',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - is een dataverzamelingsmethode waarbij een computer wordt gebruikt om telefonische enquêtes uit te voeren. CATI-software automatiseert de processen van gegevensverzameling, invoer van antwoorden en beheer van enquêtes. Het gebruik van CATI vergemakkelijkt de controle over de enquête, maakt snelle analyse en verwerking van gegevens mogelijk en zorgt voor een hoog niveau van standaardisatie.',
  sociological_research_7: 'CATI',
  sociological_research_8:
    'CAWI - is een dataverzamelingsmethode waarbij respondenten enquêtes invullen of vragen beantwoorden via een webinterface, waarbij de gegevens automatisch worden verzameld en verwerkt met gespecialiseerde software. De CAWI-methode maakt grootschalige enquêtes mogelijk, en snelle verzameling en analyse van gegevens.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - is een dataverzamelingsmethode voor face-to-face enquêtes waarbij de onderzoeker een tablet of mobiele telefoon gebruikt om antwoorden van respondenten in te voeren, wat geautomatiseerde gegevensverzameling mogelijk maakt, invoerfouten vermindert en de analyse van resultaten vereenvoudigt.',
  sociological_research_11: 'Onze experts gebruiken verschillende tools',
  sociological_research_12: 'Steekproef',
  sociological_research_13:
    'We berekenen en passen steekproeven van elke complexiteit toe',
  sociological_research_14:
    'In ons werk gebruiken we eenvoudige, systematische, gestratificeerde en clustersteekproeven',
  sociological_research_15:
    'Voor landelijk representatieve face-to-face enquêtes gebruiken we gestratificeerde meertraps steekproefprocedures',
  sociological_research_16:
    'De selectie van nederzettingen per regio wordt uitgevoerd op basis van de grootte en het type nederzetting, waarvan het aantal wordt bepaald in verhouding tot hun volume in elke regio',
  sociological_research_17: 'We voeren uit',
  sociological_research_18: 'Onderzoek naar publieke opinie',
  sociological_research_19: 'Sociaal-economisch onderzoek',
  sociological_research_20: 'Desk onderzoek',
  sociological_research_21: 'Beoordeling van de kwaliteit van sociale diensten',
  sociological_research_22: 'Case study',
  sociological_research_23: 'Personeelsonderzoek',
  sociological_research_24:
    'Onderzoek naar specifieke doelgroepen (kwetsbare groepen, inclusief ontheemden, militairen, jongeren, enz.)',
  sociological_research_25: 'We werken met elke klant individueel',
  sociological_research_26: 'Onze klanten:',
  sociological_research_27: 'Liefdadigheidsorganisaties',
  sociological_research_28: 'Publieke organisaties en verenigingen',
  sociological_research_29: 'Overheidsinstellingen',
  sociological_research_30: 'Onze werkbenadering',
  sociological_research_31:
    'We gebruiken een wetenschappelijke benadering in ons werk en een diep begrip van de huidige realiteit, evenals verschillende onderzoeksmethoden om gegevens te verzamelen.',
  sociological_research_32:
    'We betrekken hooggespecialiseerde experts met de nodige ervaring om een specifiek verzoek aan te pakken en hebben een pool van experts in verschillende velden.',
  sociological_research_33:
    'Onze interne analysetak analyseert de verzamelde gegevens met behulp van een unieke reeks tools, afhankelijk van de specificiteit van de taak.',
  sociological_research_34:
    'We genereren rapporten met gedetailleerde conclusies en aanbevelingen, rekening houdend met de behoeften en verwachtingen van uw doelgroep.',
  sociological_research_35:
    'We bieden advies en ondersteuning bij de implementatie van de aanbevelingen, en helpen u positieve veranderingen door te voeren.',
  sociological_research_36: 'Ons onderzoek op tv',
  sociological_research_37: 'Bekijk onze rapporten',
  sociological_research_38:
    'Onze wetenschappelijke benadering in ons werk garandeert onze klanten nauwkeurige en betrouwbare sociologische gegevens in Oekraïne en Europa met minimale foutmarge.',
  sociological_research_39:
    'We werken in overeenstemming met alle principes en standaarden van ethiek en regels voor het uitvoeren van sociologisch onderzoek.',
  sociological_research_40:
    'We houden ons aan de Code van Professionele Ethiek voor Sociologen van de Sociologische Vereniging van Oekraïne en de Helsinki-verklaring van ethische principes voor onderzoek. Leden van ESOMAR, Oekraïense Marketing Vereniging, Oekraïense Vereniging van Contactcentra.',
  sociological_research_41: 'Veldwerk',
  sociological_research_42: 'Online panel',
  sociological_research_43: 'Reguliere interviewers',
  sociological_research_44: 'Supervisors',
  sociological_research_45: 'Kwaliteitscontrole veldwerk',
  sociological_research_46: '100% registratie van GPS-coördinaten (CAPI)',
  sociological_research_47:
    'Validatie van vragenlijsten (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% opname van interviews (CAPI, CATI)',
  sociological_research_49: 'We gebruiken gelicentieerde software:',
  sociological_research_50:
    'SPSS voor analyse van statistische gegevens, verwerking van datasets',
  sociological_research_51:
    'DigSee Sure voor CAPI: vragenlijstlogica, geolocatie, audio-opnames van interviews, weergave van videomateriaal',
  sociological_research_52:
    'SurveyMonkey voor CAWI: vragenlijstlogica, handig formaat, visualisatie',
  sociological_research_53:
    'VoIPTime (contactcentrum): programmeren van vragenlijsten van elke complexiteit, overdracht van audio-opnames aan de klant',
  sociological_research_54: 'Steekproef',
  sociological_research_55:
    'We berekenen en passen steekproeven van elke complexiteit toe',
  sociological_research_56:
    'In ons werk gebruiken we eenvoudige, systematische, gestratificeerde en clustersteekproeven',
  sociological_research_57:
    'Voor landelijk representatieve face-to-face enquêtes gebruiken we gestratificeerde meertraps steekproefprocedures',
  sociological_research_58:
    'De selectie van nederzettingen per regio wordt uitgevoerd op basis van de grootte en het type nederzetting, waarvan het aantal wordt bepaald in verhouding tot hun volume in elke regio',
  sociological_research_59: 'Team',
  sociological_research_60:
    'Onze sociologen zijn leden van de Vereniging van Sociologen van Oekraïne',
  sociological_research_61:
    'We hebben 12 fulltime analisten met ervaring in zowel kwantitatieve als kwalitatieve projecten in verschillende industrieën.',
  sociological_research_62:
    'We hebben een sterk analytisch team van professionals met meer dan 10 jaar onderzoekservaring. We hebben wetenschappers, PhD"s, MBI.',
  sociological_research_63: 'Iryna Maksymiuk',
  sociological_research_64:
    'Hoofd van de Onderzoeksafdeling. Meer dan 15 jaar ervaring in marketingonderzoek, onderzoeksbeheer in alle stadia, voorbereiding van analytische rapporten, presentaties',
  sociological_research_65: 'Andriy Biloskursky',
  sociological_research_66:
    'Hoofd van Programmering en Gegevensverwerking. 20 jaar ervaring in marketing, sociologisch en marktonderzoek',
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    'Programmeringsspecialist, promovenda sociologie aan DonSUU. 5 jaar ervaring in marketingonderzoek. Lid van het bestuur van de Democratische Alliantie van Vrouwen, Hoofd Fondsenwerving.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Hoofd van de afdeling Sociologisch Onderzoek van de Onderzoeksafdeling, heeft 13 jaar ervaring in sociologische en sociale projecten op internationaal en nationaal niveau. PhD in Sociologie, Hoofd van de Onderzoekscommissie van de Oekraïense Vereniging van Contactcentra.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Ontdek ons alternatief onderzoek voor unieke gevallen',
  ad_hoc_3: 'Neem contact met ons op',
  ad_hoc_4: 'Onze aanpak',
  ad_hoc_5:
    'Ons team van experts analyseert zorgvuldig de verzamelde gegevens en biedt u complexe oplossingen die bijdragen aan uw groei.',
  ad_hoc_6:
    'We ontwerpen een uniek onderzoek dat is afgestemd op uw specifieke eisen, met behulp van diverse methodologieën, waaronder enquêtes, interviews, focusgroepen, data-analyse en meer.',
  ad_hoc_7: 'Waarom kiezen voor Ad Hoc Onderzoek?',
  ad_hoc_8: 'Flexibiliteit',
  ad_hoc_9:
    'Geen enkel onderzoeksproject is hetzelfde. Met Ad Hoc Onderzoek hebben we de flexibiliteit om de unieke aanpak indien nodig aan te passen en te ontwikkelen, zodat uw inzichten relevant en bruikbaar blijven.',
  ad_hoc_10: 'Precisie',
  ad_hoc_11:
    'Door onze methodologieën af te stemmen op uw unieke eisen, leveren we nauwkeurige en gerichte resultaten die waardevolle inzichten bieden.',
  ad_hoc_12: 'Innovatie',
  ad_hoc_13:
    'We omarmen innovatie en creativiteit in ons onderzoeksproces en verkennen nieuwe technieken en benaderingen om verborgen kansen en uitdagingen bloot te leggen.',
  ad_hoc_14: 'Tijdigheid',
  ad_hoc_15:
    'Ad Hoc Onderzoek maakt snelle implementatie mogelijk, zodat u kritieke inzichten tijdig kunt verkrijgen en voorop blijft lopen in uw branche.',
  ad_hoc_16: 'Onze expertise',
  ad_hoc_17:
    '4Service-experts met meer dan 20 jaar ervaring in marktonderzoek en klantbeleving creëren unieke oplossingen die uw bedrijf helpen groeien.',
  ad_hoc_18:
    'We gebruiken een uniek instrumentarium gebaseerd op uw specifieke eisen.',
  ad_hoc_19: 'Werkt geen enkele methodologie voor u?',
  ad_hoc_20: 'Vraag het Ad Hoc Onderzoek aan',
  ad_hoc_21: 'Op maat gemaakt voor specifieke problemen',
  ad_hoc_22:
    'We gebruiken zowel een mix van verschillende delen van de methodologieën als een volledig nieuwe aanpak.',
  ad_hoc_23: 'Individuele aanpak',
  ad_hoc_24:
    'We passen een oplossing zorgvuldig aan, direct op uw probleem afgestemd.',
  ad_hoc_25: 'Ga verder dan verwacht',
  ad_hoc_26:
    'We bieden een uitvoerbaar plan met aanbevelingen om uw bedrijf te helpen zijn doelen te bereiken.',
  ad_hoc_27: 'U heeft een niet-standaard probleem.',
  ad_hoc_28: 'Geen van de methodologieën werkt voor uw probleem.',
  ad_hoc_29:
    'U wilt verschillende methodologieën of onderdelen daarvan tegelijkertijd aanvragen.',
  ad_hoc_30: 'Wie profiteert van Ad Hoc Onderzoek?',
  service_design_1: 'Enkele van onze klanten',
  service_design_2: 'Service Design',
  service_design_3:
    'Transformeer uw bedrijf van product- en verkoopgericht naar service- en mensgericht',
  service_design_4: 'Spreek met een expert',
  service_design_5: 'Leer gratis!',
  service_design_6:
    'We hebben praktische en nuttige informatie verzameld in onze Service Design Bibliotheek.',
  service_design_7:
    'De Bibliotheek bevat gedetailleerde beschrijvingen van elke stap, relevante tools en technieken, tips over belangrijke details en videodemonstraties.',
  service_design_8: 'Ga naar de Bibliotheek',
  service_design_9: 'Welkom bij Service Design!',
  service_design_10:
    'Service Design heeft als doel diensten te creëren en te verbeteren door aandacht te besteden aan het hele dienstverleningssysteem — mensen, processen en contactpunten — om zowel de tevredenheid van gebruikers als de operationele efficiëntie te verbeteren.',
  service_design_11:
    'Service Design is niet alleen waarom u de ene café boven de andere verkiest. Het is ook de reden waarom u steeds weer terugkomt naar deze plek en al uw vrienden erover vertelt.',
  service_design_12: 'Hoe bedrijven kunnen profiteren van Service Design',
  service_design_13: 'Gebruikerstevredenheid',
  service_design_14:
    'Creëer naadloze, gepersonaliseerde ervaringen die gebruikers verrukken.',
  service_design_15: 'Operationele efficiëntie',
  service_design_16:
    'Optimaliseer processen, verlaag kosten en verbeter de dienstverlening.',
  service_design_17: 'Merkconsistentie',
  service_design_18:
    'Zorg ervoor dat alle contactpunten in lijn zijn met de waarden van het merk.',
  service_design_19: 'Aanpassingsvermogen aan veranderingen',
  service_design_20:
    'Ontwerp diensten die kunnen meegroeien en evolueren met veranderende behoeften.',
  service_design_21: 'Loyaliteit verhogen',
  service_design_22:
    'Bouw sterkere klantrelaties op en vergroot loyaliteit door uitzonderlijke service-ervaringen.',
  service_design_23: 'Concurrentievoordeel',
  service_design_24:
    'Val op in de markt met unieke, gedenkwaardige service-ervaringen.',
  service_design_25: 'Waarom',
  service_design_26: 'Waarom kiezen voor Service Design?',
  service_design_27:
    'Het is een cultuur, methodologie en manier van denken die bedrijven wereldwijd helpt om te schakelen van product- en verkoopgericht naar het focussen op menselijke behoeften en problemen.',
  service_design_28: 'Onze diensten',
  service_design_29: 'Klantenservice Design',
  service_design_30:
    'We ontwikkelen en implementeren klantgerichte projecten om nieuwe producten te creëren of bestaande te verbeteren. We bieden ook training voor toekomstig zelfstandig werk met deze veranderingen.',
  service_design_31: 'Medewerkersservice Design',
  service_design_32:
    'Een praktische cursus om medewerkersgerichte processen, structuren en veranderingen binnen het bedrijf te ontwikkelen en te implementeren.',
  service_design_33: 'Toekomstgericht ontwerpdenken',
  service_design_34:
    'Ontwikkeling van toekomstconcepten en bedrijfsstrategie, rekening houdend met verschillende scenario"s en mogelijke innovaties.',
  service_design_35: 'Creativiteit in Bedrijf',
  service_design_36:
    'Een praktische training die deelnemers helpt creativiteit te gebruiken om specifieke problemen op te lossen en unieke marktoplossingen te ontwikkelen.',
  service_design_37: 'Onze Workshops',
  service_design_38:
    'We bieden praktische workshops met een wereldwijd erkende certificering voor onze deelnemers.',
  service_design_39:
    'Creëer producten, diensten en faciliteiten die verwachtingen overtreffen.',
  service_design_40: 'Implementeer bedrijfsprocessen die werken.',
  service_design_41:
    'Harmoniseer de relatie tussen bedrijfsmedewerkers en klanten.',
  service_design_42: 'Kalender',
  service_design_43: 'Binnenkort beschikbaar',
  service_design_44: 'Lees meer over onze eerdere evenementen.',
  service_design_45: 'Basics of Service Design',
  service_design_46:
    'Creëer producten, diensten en faciliteiten die verwachtingen overtreffen.',
  service_design_47: 'EX Service Design',
  service_design_48:
    'Creëer producten, diensten en faciliteiten die verwachtingen overtreffen.',
  service_design_49: 'Meer',
  service_design_50: 'Onze aanpak',
  service_design_51:
    '4Service is een ruggengraat voor uw mensgerichte service!',
  service_design_52:
    'We helpen bedrijven de noodzakelijke mindset te ontwikkelen en een mensgerichte interne omgeving te creëren.',
  service_design_53: 'Tools',
  service_design_54:
    'We gebruiken een uniek instrumentarium gebaseerd op uw specifieke eisen.',
  service_design_55: 'Gebruikersonderzoek',
  service_design_56:
    'Interviews, enquêtes, F2F, etnografische studies om gebruikersbehoeften en -gedragingen te begrijpen.',
  service_design_57: 'Personas',
  service_design_58:
    'Fictieve karakters die verschillende gebruikers typen vertegenwoordigen om ontwerpbeslissingen te sturen.',
  service_design_59: 'Customer Journey Maps',
  service_design_60:
    'Visuele representaties van de gebruikerservaring door de service, met identificatie van belangrijke contactpunten en pijnpunten.',
  service_design_61: 'Brainstormsessies',
  service_design_62:
    'Samenwerkingssessies met belanghebbenden om ideeën en oplossingen te genereren.',
  service_design_63: 'Prototyping',
  service_design_64:
    'Het creëren van voorlopige versies van de service om concepten te testen en verfijnen.',
  service_design_65: 'Service Blueprints',
  service_design_66:
    'Gedetailleerde diagrammen die de relaties tussen verschillende servicecomponenten en -processen laten zien.',
  service_design_67: 'Partner',
  service_design_68: '4Service & DTA',
  service_design_69:
    '4Service is de officiële exclusieve vertegenwoordiger van de Design Thinking Academy.',
  service_design_70: 'Als onderdeel van ons partnerschap:',
  service_design_71:
    '<b>bieden we praktische training</b> aan met behulp van de servicedesignmethode tijdens workshops door toonaangevende experts;',
  service_design_72:
    '<b>leiden we gecertificeerde</b> design thinking specialisten op;',
  service_design_73:
    '<b>implementeren we servicedesign</b> projecten met internationale facilitators voor veel bedrijven.',
  service_design_74:
    'We hebben Centers of Excellence voor Service Design opgericht in:',
  service_design_75: 'Oekraïne',
  service_design_76: 'Kazachstan',
  service_design_77: 'Turkije',
  service_design_78: 'Georgië',
  service_design_79: 'Azerbeidzjan',
  service_design_80: 'Ontdek meer over onze cases.',
  four_opinion_1: 'Enquête van 4Service',
  four_opinion_2: 'Doe online enquêtes voor geld en verdien zonder je huis te verlaten',
  four_opinion_3: 'Beginnen',
  four_opinion_4: 'Onze voordelen',
  four_opinion_5: 'Wij worden vertrouwd',
  four_opinion_6: 'Onze gemeenschap bestaat uit meer dan 90.000 consumenten in Oekraïne en 100.000 in Kazachstan',
  four_opinion_7: 'Wij waarderen uw tijd',
  four_opinion_8: 'Wij waarderen uw tijd, daarom krijgt u voor deelname aan enquêtes de kans om een cadeau of een geldbeloning te ontvangen',
  four_opinion_9: 'Uw mening heeft een grote impact',
  four_opinion_10: 'Elke opmerking van u helpt merken om de behoeften van hun klanten beter te begrijpen',
  four_opinion_11: 'Doe mee aan enquêtes op 4Opinion van 4Service en verdien online of win een prijs',
  four_opinion_12: 'Word lid van 4Opinion',
  four_opinion_13: 'Meer interessante verhalen op ons kanaal',
  four_opinion_14: 'Meer details voor Oekraïne',
  four_opinion_15: 'Meer details voor Kazachstan',
  four_opinion_16: 'Waarom kiezen mensen voor ons?',
  four_opinion_17: '4Service is een internationale onderzoeksorganisatie gespecialiseerd in klantbeleving',
  four_opinion_18: 'Wij behoren tot de top 10 Europese bureaus voor klantbeleving',
  four_opinion_19: 'Onze missie is om de klantenservice wereldwijd te verbeteren!',
  four_opinion_20: 'Hoe kunt u geld verdienen met online enquêtes?',
  four_opinion_21: 'Registreer op de website',
  four_opinion_22: 'Ontvang meldingen over actuele enquêtes',
  four_opinion_23: 'Vul enquêtes volledig in en verdien geld',
  four_opinion_24: 'Echte beoordelingen van onze respondenten',
  four_opinion_25: 'Vitaliy Kohan',
  four_opinion_26: '“Ik heb een enquête ingevuld en was het alweer vergeten. Na een tijdje belde mevrouw Natalia me op en vertelde dat ik een prijs had gewonnen. En hier is mijn prijs: Samsung Galaxy M52 5G. Heel erg bedankt😁”',
  four_opinion_27: 'Victoria Popovska',
  four_opinion_28: '“Ik heb de speaker ontvangen, heel erg bedankt voor de eerlijke, rechtvaardige en interessante verloting.”',
  four_opinion_29: 'Yulia Alexandrovna',
  four_opinion_30: '“Waarschijnlijk het meest ongelooflijke dat mij de laatste tijd is overkomen💕 Niet alleen heb ik gewonnen, maar ook iets echt geweldigs. Ik had van alles verwacht, maar niet dat het zo goed zou zijn. Nogmaals, enorm bedankt!”',
  four_opinion_31: 'Alleen bij ons kunt u “echt” geld verdienen of een prijs winnen',
  four_opinion_32: 'Volg de actuele enquêtes op ons kanaal',
  four_opinion_33: 'Ga naar het kanaal',
};
