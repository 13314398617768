import styled from 'styled-components';

export const ShoppersProjectsStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px 10px;
    gap: 50px;
    position: relative;

    @media (max-width: 768px) {
      padding: 30px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0 auto;
      font-weight: 600;
      font-size: 40px;
      line-height: 115%;
      color: #ffffff !important;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
  }

  .utils {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .logo-wrapper {
    width: 100%;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .logo-swiper {
      width: calc(200% + 30px);
      pointer-events: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      transition: all 0.3s ease-in-out;

      @media (max-width: 991px) {
        gap: 15px;
      }
    }
  }
`;
