import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { DiaryStudyBenefitStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import benefit1 from '../../../assets/images/diaryStudy/benefit-1.svg';
import benefit2 from '../../../assets/images/diaryStudy/benefit-2.svg';
import benefit3 from '../../../assets/images/diaryStudy/benefit-3.svg';
import benefit4 from '../../../assets/images/diaryStudy/benefit-4.svg';
import benefit5 from '../../../assets/images/diaryStudy/benefit-5.svg';
import benefit6 from '../../../assets/images/diaryStudy/benefit-6.svg';

export const DiaryStudyBenefit = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <DiaryStudyBenefitStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_27')}
        </h5>
        <div className="content">
          <h1>
            {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_28')}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={benefit1} alt="Diary Study Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'diary_study_29',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit2} alt="Diary Study Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'diary_study_30',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit3} alt="Diary Study Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'diary_study_31',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit4} alt="Diary Study Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'diary_study_32',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit5} alt="Diary Study Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'diary_study_33',
              ),
            }}
            />
          </div>
          <div className="cards-item">
            <img src={benefit6} alt="Diary Study Benefit" />
            <p dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'diary_study_34',
              ),
            }}
            />
          </div>
        </div>
      </div>
    </DiaryStudyBenefitStyles>
  );
};
