import styled from 'styled-components';

export const CatiBenefitStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 70px;

  @media (max-width: 991px) {
    gap: 30px;
    padding: 40x 0;
  }

  .cards {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 18px;
      max-width: 370px;
      width: 100%;

      img {
        max-width: 38px;
      }

      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 125%;
        text-align: center;
        color: #0f0e0c;

        b {
          font-weight: 600;
        }
      }
    }
  }
`;
