import React, { useMemo } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ImpactEmployeeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import equals from '../../../assets/images/employeeExperience/equals.svg';
import plus from '../../../assets/images/employeeExperience/plus.svg';
import impact1 from '../../../assets/images/employeeExperience/impact-1.png';
import impact2 from '../../../assets/images/employeeExperience/impact-2.png';
import impact3 from '../../../assets/images/employeeExperience/impact-3.png';

export const ImpactEmployeeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ImpactEmployeeExperienceStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_48')}</h2>
        <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_49')}</h3>

        <div className="circles">
          <div className="circles-item">
            <img src={impact1} alt="impact1" />
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_51')}
            </p>
          </div>
          <img src={plus} alt="arrow" className="circles-arrow" />
          <div className="circles-item">
            <img src={impact2} alt="impact2" />
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_52')}
            </p>
          </div>
          <img src={equals} alt="arrow" className="circles-arrow" />
          <div className="circles-item">
            <img src={impact3} alt="impact3" />
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_53')}
            </p>
          </div>
        </div>

        <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_50')}</h3>

        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'employee_experience_3',
          )}
          className="btn"
        />
      </div>
    </ImpactEmployeeExperienceStyles>
  );
};
