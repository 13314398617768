import React, { useRef } from 'react';
import { MenuStyles } from './MenuStyles';
import {
  marketResearch,
  menuMethodsItems,
  menuOtherItems,
  menuProductsItems,
  shoppersPlatformUrls,
  socialMediasMenu,
} from '../../constants';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../buttonWithArrowOnHover/ButtonWithArrowOnHover';

interface Props {
  textColor: string;
  iconsColor: string;
  active: boolean;
  backgroundColor: string;
  menuButtonRef: any;
  setActive: () => void;
  openCloseMenuButton: any;
  langMenuRef: any;
  colorTheme: 'darkTheme' | 'lightTheme' | 'greenTheme';
}

function Menu({
  textColor,
  iconsColor,
  active,
  backgroundColor,
  menuButtonRef,
  setActive,
  openCloseMenuButton,
  langMenuRef,
  colorTheme,
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const menuRef = useRef<any>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useClickOutside({ current: menuRef.current }, () => setActive(), {
    current: [
      menuButtonRef.current,
      openCloseMenuButton.current,
      langMenuRef.current,
    ],
  });

  const btnColor = (colorTheme: 'darkTheme' | 'lightTheme' | 'greenTheme') => {
    switch (colorTheme) {
      case 'darkTheme':
        return '#fff';
      case 'greenTheme':
        return '#fff';
      case 'lightTheme':
        return '#000';
      default:
        return '#fff';
    }
  };

  return (
    <MenuStyles
      textColor={textColor}
      backgroundColor={backgroundColor}
      className={active ? 'menuActive' : ''}
    >
      <div className="container">
        <div
          className={active ? 'menuActive menuContent' : 'menuContent'}
          ref={menuRef}
        >
          <div className="menuHeadWrapper">
            <div className="linksContainer">
              <div className="methodsLinksWrapper">
                <h2>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'menu_17')}
                </h2>
                <ul className="methodsLinksContainer">
                  {menuMethodsItems(interfaceLanguage).map((item, index) => (
                    <li key={item.url}>
                      <Link
                        to={`/${interfaceLanguage}${item.url}`}
                        onClick={() => setActive()}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="marketResearchLinksWrapper">
                <h2>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'footer_7')}
                </h2>
                <ul className="methodsLinksContainer">
                  {marketResearch(interfaceLanguage).map((item, index) => (
                    <li key={item.url}>
                      <Link
                        to={`/${interfaceLanguage}${item.url}`}
                        onClick={() => setActive()}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="productsLinksWrapper">
                <h2>
                  {getTranslationsByLangOrEng(interfaceLanguage, 'menu_18')}
                </h2>
                <ul className="productsLinksContainer">
                  {menuProductsItems(interfaceLanguage).map((item, index) => {
                    if (
                      (item.onlyInLanguages
                        && item.onlyInLanguages.includes(interfaceLanguage))
                      || !item.onlyInLanguages
                    ) {
                      return item.otherSiteLink ? (
                        <li key={item.url}>
                          <a
                            target="_blank"
                            href={item.url}
                            onClick={() => setActive()}
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </li>
                      ) : (
                        <li key={item.url}>
                          <Link
                            to={`/${interfaceLanguage}${item.url}`}
                            onClick={() => setActive()}
                          >
                            {item.name}
                          </Link>
                        </li>
                      );
                    }

                    return null;
                  })}
                </ul>
              </div>

              <ul className="otherLinksContainer1">
                {menuOtherItems(interfaceLanguage)
                  .map((item, index) => (
                    <li key={item.url} onClick={() => setActive()}>
                      {item.shoppersPlatform ? (
                        <a
                          key={item.name}
                          href={
                            shoppersPlatformUrls[interfaceLanguage]
                              ? shoppersPlatformUrls[interfaceLanguage]
                              : shoppersPlatformUrls.en
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      ) : item.toOtherSite ? (
                        <a
                          key={item.name}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          to={`/${interfaceLanguage}${item.url}`}
                          onClick={() => setActive()}
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
              </ul>
            </div>

            <div className="socialLinksContainer">
              <div className="menuBtns">
                <ButtonWithArrowOnHover
                  onClick={() => navigate(`/${interfaceLanguage}/shoppers`)}
                  arrowColor={btnColor(colorTheme)}
                  textColor={btnColor(colorTheme)}
                  borderColor="#ED1C2A"
                  borderOnHover="#ED1C2A"
                  background="transparent"
                  hoverBackground="#ED1C2A"
                  hoverTextColor={btnColor(colorTheme)}
                  text={getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'footer_btn_1',
                  )}
                  className="menuBtn"
                  withoutArrow
                />
                <ButtonWithArrowOnHover
                  onClick={() => navigate(`/${interfaceLanguage}/4opinion`)}
                  arrowColor={btnColor(colorTheme)}
                  textColor={btnColor(colorTheme)}
                  borderColor="#ED1C2A"
                  borderOnHover="#ED1C2A"
                  background="transparent"
                  hoverBackground="#ED1C2A"
                  hoverTextColor={btnColor(colorTheme)}
                  text={getTranslationsByLangOrEng(
                    interfaceLanguage,
                    'footer_btn_2',
                  )}
                  className="menuBtn"
                  withoutArrow
                />
              </div>
              <ul>
                {socialMediasMenu.map((socialMedia, index) => {
                  const Icon = socialMedia.icon;
                  return (
                    <li key={socialMedia.url}>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <a
                        href={socialMedia.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon color={iconsColor} circleColor={iconsColor} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="splitter" />
        </div>
      </div>
    </MenuStyles>
  );
}

export default Menu;
