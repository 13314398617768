import styled from 'styled-components';
import banner from '../../../assets/images/employeeExperience/banner.png';

export const MethodologyEmployeeExperienceStyles = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .container {
    padding: 50px 10px !important;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 768px) {
      padding: 30px 10px !important;
      gap: 30px;
    }

    h2 {
      font-weight: 400;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 800px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
  }

  .cards {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      gap: 18px;
      max-width: 370px;
      width: 100%;
      height: 420px;
      border: 2px solid rgba(0, 0, 255, 0.4);
      filter: drop-shadow(0px 0px 40px #0000ff);
      border-radius: 5px;

      @media (max-width: 768px) {
        padding: 20px;
        height: auto;
      }

      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 125%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
