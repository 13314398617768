import styled from 'styled-components';

export const ShoppersContactStyles = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 70px 10px;
    gap: 50px;
    position: relative;

    @media (max-width: 768px) {
      padding: 50px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0 auto;
      font-weight: 600;
      font-size: 40px;
      line-height: 115%;
      color: #ffffff !important;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
  }

  .contact-content {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    width: 100%;
    gap: 35px;
    align-items: center;

    .buttons {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      &-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        gap: 10px;
        background: #ffffff;
        border-radius: 1000px;

        img {
          flex-shrink: 0;
        }

        span {
          margin: 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 120%;
          text-align: center;
          color: #000000;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }

    .native {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &-item {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;

        a {
          font-weight: 600;
          font-size: 18px;
          line-height: 120%;
          text-decoration-line: underline;
          color: #1076c7;
        }
      }
    }
  }
`;
