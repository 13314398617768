export const pl: { [key: string]: string } = {
  subscribe_with_click: 'Subskrybuj jednym kliknięciem',
  subscribe: 'Subskrybuj jednym kliknięciem',
  minimum_number_characters: 'minimalna liczba 2 znaków',
  request_details: 'Szczegóły zapytania',
  form_title: 'Skontaktujemy się z Tobą wkrótce',
  form_required: 'Pola oznaczone * są obowiązkowe',
  form_name_placeholder: 'Imię i nazwisko',
  form_email_placeholder: 'E-mail',
  form_phone_placeholder: 'Numer telefonu',
  form_company_placeholder: 'Firma',
  form_submit: 'Wyślij',
  form_1: 'Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z',
  form_2: 'Polityką Prywatności',
  validation_privacy_policy: 'Proszę zaakceptować Politykę Prywatności',
  validation_phone: 'Numer telefonu jest nieprawidłowy',
  validation_email: 'Nieprawidłowy format e-maila',
  validation_required: 'Wymagane pola nie zostały wypełnione',
  submit_error: 'Wystąpił błąd podczas zapisywania formularza',
  footer_1: 'Nasi partnerzy:',
  footer_2: 'Metody',
  footer_3: 'Produkty',
  footer_4: 'Kontakty:',
  footer_5: 'Informacje prawne',
  footer_6: 'Polityka prywatności',
  footer_7: 'Badania rynku',
  about_us_1: 'Społeczna odpowiedzialność korporacyjna 4Service',
  about_us_2:
    'Z serca i duszy każdego członka naszego zespołu. Robimy wszystko, aby utrzymać iskrę dobroci w każdym naszym pracowniku i uczynić otaczający nas świat trochę lepszym',
  about_us_3: 'Społeczna odpowiedzialność korporacyjna 4Service',
  about_us_4:
    'Z serca i duszy każdego członka naszego zespołu. Robimy wszystko, aby utrzymać iskrę dobroci w każdym naszym pracowniku i uczynić otaczający nas świat trochę lepszym',
  about_us_5: 'Gra biznesowa Service Mania',
  about_us_6: 'Nasze inicjatywy skierowane do dzieci i studentów',
  about_us_7: 'Charytatywność / Wolontariat',
  about_us_8:
    'Domy dziecka, domy opieki dla osób starszych oraz wolontariat dla ukraińskiej armii. Najbardziej poruszająca część naszych działań',
  about_us_9: 'Dobrostan zwierząt',
  about_us_10:
    'Każdy może wpaść do naszego biura z pupilem. Pomagamy schroniskom, zbieramy fundusze na jedzenie, a czasem próbujemy znaleźć dom dla bezdomnych zwierząt',
  about_us_11: '4Service Group',
  about_us_12:
    'Międzynarodowa firma badawcza zajmująca się zarządzaniem doświadczeniem klienta.',
  about_us_13:
    'Realizujemy złożone projekty, aby zwiększyć sprzedaż i rozwijać biznesy firm zorientowanych na klienta.',
  about_us_14:
    'Międzynarodowy holding badawczy z siedzibą w Wiedniu, Austria. Firma została założona w 2001 roku w celu poprawy jakości życia klientów oraz osób korzystających z usług i produktów firm.',
  about_us_15:
    'Tak zaczęła się historia 4Service w Kijowie, Ukraina, jako wiodącego dostawcy usług mystery shopping. Firma nadal działa z sukcesem w 52 krajach.',
  about_us_16: 'narodzony w imię serwisu',
  about_us_17: 'pierwsze biuro poza Ukrainą',
  about_us_18: 'relokacja siedziby do Wiednia',
  about_us_19: 'członek elity MSPA, Top-10 agencji w Europie',
  about_us_20: 'zakładanie biur w 9 krajach',
  about_us_21: 'pierwsze projekty cyfrowe, Voicer',
  about_us_22: 'partnerzy DesignThinkers Academy',
  about_us_23: 'pierwsze globalne projekty CX',
  about_us_24: 'Misja',
  about_us_25:
    'Naszą misją jest poprawa obsługi klienta na całym świecie! Inspirują nas pozytywne zmiany, które widzimy u naszych klientów.',
  about_us_26: '20+ lat doświadczenia w obsłudze klienta',
  about_us_27: '95 krajów',
  about_us_28:
    'Zbieramy i dzielimy się najlepszymi doświadczeniami z branży na całym świecie',
  about_us_29: 'Główna siedziba w Wiedniu',
  about_us_30:
    'kraje. Zbieramy i dzielimy się najlepszymi doświadczeniami z branży na całym świecie',
  about_us_31: 'oceny jakości obsługi',
  about_us_32: 'jesteśmy w top-10 europejskich agencji CX',
  about_us_33: 'przedstawicielstwa / HQ w Austrii',
  about_us_34: 'aktywne projekty',
  about_us_35: 'Twoje przyszłe miejsce pracy',
  about_us_36: 'Nasze biura',
  about_us_37:
    '4Service ™ dba o tworzenie wygodnych miejsc pracy. Pracownicy pracują w modelu zdalnym, stacjonarnym lub hybrydowym.',
  about_us_38: 'Zespół, który inspiruje',
  about_us_39:
    'Nasz międzynarodowy zespół wsłuchuje się w opinie klientów i wspólnie się rozwija.',
  about_us_40: 'Wszystko',
  about_us_41: 'Zarządzanie',
  about_us_42: 'Globalny zespół',
  about_us_43: 'Właściciele produktów',
  about_us_44: 'Nasi partnerzy',
  about_us_45:
    'Realizujemy złożone projekty mające na celu zwiększenie sprzedaży i rozwój firm zorientowanych na klienta',
  about_us_46:
    'jest jednym z wiodących „design driven” Instytutów Szkoleniowych, reprezentowanym w 25 krajach, który szkoli, rozwija i wspiera kreatywne, multidyscyplinarne zespoły oraz społeczności, aby napędzać pozytywne zmiany. Design Thinking pozwala opracowywać i wdrażać innowacyjne pomysły, zmiany i rozwiązania dla skomplikowanych problemów.',
  about_us_47:
    'Ich misją jest zapewnienie przestrzeni i czasu dla zespołów i jednostek, aby mogły się inspirować i podejmować wyzwania, „uczyć się poprzez działanie” oraz wzmacniać swoje umiejętności kreatywnego rozwiązywania problemów, a także pomagać organizacjom w budowaniu odpowiedniego środowiska i sposobu myślenia, aby inicjować odważne innowacje.',
  about_us_48:
    'jest reprezentatywnym Stowarzyszeniem Handlowym dla firm działających w branży Mystery Shopping. Stowarzyszenie działa na poziomie regionalnym na całym świecie, z oddziałami w Ameryce, Europie/Afryce oraz Azji/Pacyfiku. Firmy członkowskie jednoczą się jako wspólne ciało w celu wzmocnienia branży Mystery Shopping poprzez wspólne wysiłki i działania.',
  about_us_49:
    'jest organizacją członkowską non-profit. Członkowie tworzą społeczność przekonaną o potencjale analizy danych, badań i wglądów w poprawę społeczeństw, organizacji i życia każdego człowieka.',
  about_us_50:
    'ESOMAR ma swoją siedzibę w Amsterdamie, w Holandii. ESOMAR jest obecny w ponad 130 krajach poprzez swoich członków od 1947 roku.',
  about_us_51:
    '(UCCA) to profesjonalna społeczność prywatnych ekspertów i firm, zjednoczona w celu rozwoju i interakcji w obszarze centrów kontaktowych i obsługi klienta. Filozofią UCCA jest podnoszenie kultury branży centrów kontaktowych, opartej na nowoczesnych technologiach i wysokich standardach.',
  about_us_52:
    'Ukraińskie Stowarzyszenie Centrów Kontaktowych zostało założone w 2010 roku',
  about_us_53:
    'bada najlepsze światowe doświadczenia w dziedzinie edukacji, aby dostosować je i wdrożyć na rynku ukraińskim. Dlatego Akademia DTEK współpracuje z wiodącymi międzynarodowymi szkołami biznesowymi: INSEAD, IE Business School i Uniwersytetem Stanforda.',
  about_us_54:
    'Akademia DTEK koncentruje się na rozwoju kultury zorientowanej na klienta i innowacyjnej w D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, programie cyfrowym DX School, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Międzynarodowy menedżer sprzedaży',
  bht_1: 'Zdrowie Marki',
  bht_2: 'Śledzenie',
  bht_3: 'Marki mają ogromną moc',
  bht_4: 'Dowiedz się, jak dobrze Twoja marka radzi sobie ogólnie',
  bht_5: 'O nas',
  bht_6: 'Czym jest Śledzenie Zdrowia Marki?',
  bht_7:
    'Jest to rodzaj badania rynku, które przeprowadza się z określoną regularnością (co dwa tygodnie, co miesiąc itd.) w celu uzyskania informacji o',
  bht_8:
    'statusie marki na rynku na podstawie wskaźników takich jak rozpoznawalność, popyt, wizerunek, cechy konsumentów, zdrowie marki itp.',
  bht_9: 'Kupuje markę najczęściej',
  bht_10: 'Lojalni',
  bht_11: 'Kupili markę przez 3 miesiące',
  bht_12: 'Klienci repertuarowi / regularni',
  bht_13: 'Kupili markę przez 6 miesięcy',
  bht_14: 'Klienci krótkoterminowi',
  bht_15: 'Kupili markę przez rok',
  bht_16: 'Klienci długoterminowi',
  bht_17: 'Znają markę, ale nigdy jej nie używali/kupowali',
  bht_18: 'Świadomi, ale nie klienci',
  bht_19: 'Nieświadomi: nigdy nie słyszeli o marce',
  bht_20: 'Nieświadomi',
  bht_21: 'Profil kupujących markę',
  bht_22:
    'Ocena spójności wysiłków podejmowanych w długim okresie w celu budowy marki',
  bht_23: 'Pomiar poziomu wiedzy klientów na temat marki',
  bht_24: 'Pokazywanie skuteczności kampanii reklamowych i informacyjnych',
  bht_25:
    'Określanie, jaki wpływ okres intensywnej reklamy ma na działania sprzedażowe/marketingowe konkurencji',
  bht_26:
    'Zapewnienie stałej kontroli właścicielowi firmy w okresach ciągłych zmian rynkowych',
  bht_27: 'Jakie zadania można rozwiązać za pomocą BHT:',
  bht_28: 'Złóż zapytanie o badanie',
  bht_29: 'Zadanie do rozwiązania',
  bht_30: 'Na przykład,',
  bht_31:
    'jeśli firma potrzebuje tylko jednej sesji BHT na kwartał podczas pomiaru udziału w rynku, ankieta respondentów powinna być przeprowadzana częściej podczas pomiaru wiedzy klientów o marce z reklam.',
  bht_32: 'CATI',
  bht_33: 'TwARZĄ w twarz',
  bht_53: 'Wywiady',
  bht_34: 'CAWI',
  bht_35: 'Zadanie do rozwiązania',
  bht_36: 'Poprzez zbieranie informacji',
  bht_37:
    'różnymi metodami, śledzenie marki zazwyczaj dzieli się na trzy etapy w zależności od grupy docelowej i trudności jej osiągnięcia:',
  bht_38:
    'Używamy zarówno tradycyjnych, jak i nowoczesnych metod zbierania danych:',
  bht_39:
    'Pracujemy w dwóch modelach: pierwszy z prostym zestawem wskaźników, a drugi skierowany do bardziej złożonych branż, takich jak przemysł spożywczy i usługowy. Bardziej złożony model obejmuje dogłębne badanie, które zawiera do 10 ocenianych wskaźników.',
  bht_40: 'Oferujemy',
  bht_41: 'kontrolę danych początkowych',
  bht_42: 'Obejmujemy',
  bht_43: 'cały kraj',
  bht_44: 'Zapewniamy rozwiązanie',
  bht_45:
    'dla rynków niedynamicznych/rynków z ograniczoną liczbą graczy (Brand Point Analyze)',
  bht_46: 'Dlaczego my?',
  bht_47: 'Dlaczego nas wybrać?',
  bht_50: 'Słaby wynik',
  bht_51: 'Dobry wynik',
  bht_52: 'Średni wynik',
  cases_1:
    'Wyzwanie: Jak poprawić doświadczenie klientów związane z zakupem pojazdów elektrycznych w salonach Hyundai?',
  cases_2: 'Mapowanie podróży klienta',
  cases_3: 'Generowanie pomysłów',
  cases_4: 'Badania jakościowe',
  cases_5:
    'Wyzwanie: Co należy zmienić w sklepach (zarówno w procesie obsługi, jak i w wystroju wnętrz oraz percepcji marki), aby zainteresować klientów marką Oppo?',
  cases_6: 'Online CJM',
  cases_7: 'Testy UI',
  cases_8: 'Wywiady',
  cases_9:
    'Wyzwanie: Co należy zmienić w sklepach, aby zainteresować klientów marką Oppo?',
  cases_10: 'Część jakościowa',
  cases_11: 'Generowanie pomysłów',
  cases_12: 'Część ilościowa',
  cases_13: 'Mapa podróży klienta',
  cases_14: 'Przypadki',
  cases_15: 'Wszystkie',
  cases_16: 'Stwórzmy razem Twój świetny przypadek',
  csi_1: 'Klient',
  csi_2: 'wskaźnik satysfakcji',
  csi_3: 'Określenie powodów',
  csi_4: 'satysfakcji i niezadowolenia konsumentów',
  csi_5: 'Badanie satysfakcji klientów',
  csi_6:
    'polega na określeniu czynników, przyczyn i motywów, które wpływają na decyzję klienta o zakupie, a także na zachowanie grupy docelowej.',
  csi_7: 'Badanie satysfakcji klienta',
  csi_8:
    'pokazuje, jak bardzo klient jest zadowolony lub niezadowolony z konkretnego aspektu usługi lub produktu w ogóle.',
  csi_9: 'Badanie satysfakcji',
  csi_10:
    'jest przeprowadzane wśród istniejących klientów firmy i użytkowników określonych produktów/usług.',
  csi_11: 'Czym jest CSI?',
  csi_12:
    'Satysfakcja klienta mierzy, w jakim stopniu oczekiwania klienta zostały spełnione w odniesieniu do zakupu produktu lub usługi dostarczonej przez Twoją firmę.',
  csi_13: 'Szczęśliwy człowiek',
  csi_14: 'O nas',
  csi_15: 'Identyfikacja ukrytych czynników',
  csi_16: 'które mogą wpływać na satysfakcję klientów',
  csi_17: 'Określenie przyczyn',
  csi_18: 'spadku/wzrostu popytu na towary i usługi',
  csi_19: 'Optymalizacja',
  csi_20: 'procesów biznesowych firmy',
  csi_21: 'Dostosowanie ich',
  csi_22: 'do potrzeb współczesnego konsumenta',
  csi_23: 'Tworzenie skutecznego przekazu reklamowego',
  csi_24: 'i pozycjonowanie produktu zgodnie z wymaganiami klienta',
  csi_25: 'Zadania do rozwiązania',
  csi_26: 'Jakie zadania mogą rozwiązać badania satysfakcji klientów',
  csi_27: 'Mężczyzna z telefonem',
  csi_28: 'Budujemy model matematyczny',
  csi_29:
    'czynników mogących wpływać na satysfakcję/lojalność klientów (analiza regresji)',
  csi_30: 'Optymalizacja',
  csi_31: 'procesów biznesowych firmy',
  csi_32: 'Budujemy obliczony wskaźnik satysfakcji klientów',
  csi_33: '(CSI) i porównujemy go z deklaratywnym',
  csi_34: 'Przeprowadzamy badania',
  csi_35: 'dla segmentów B2B i B2C.',
  csi_36: 'Nasze ankiety są opracowywane przez zespół',
  csi_37: 'analityków specjalnie dla indywidualnych projektów naszych klientów',
  csi_38: 'Dlaczego my',
  csi_39: 'Dlaczego nas wybrać?',
  csi_40: 'Metody zbierania informacji:',
  main_1:
    'Pomagamy naszym klientom poprawić wyniki finansowe, ucząc się z doświadczeń ich klientów',
  main_2: 'Badania CX i usługi doradcze: kompleksowy widok Twojego biznesu',
  main_3: 'Odkryj naszą ofertę rozwiązań i narzędzi wspierających Twój biznes',
  main_4: 'Produkty',
  main_5:
    'Badania jakościowe to zbieranie i analiza danych nienumerycznych w celu zrozumienia koncepcji, opinii lub doświadczeń',
  main_6:
    'Metody ilościowe to obiektywny pomiar i analiza danych liczbowych zebranych za pomocą ankiet, kwestionariuszy i badań',
  main_7:
    'Mystery shopping to technika badania rynku do pomiaru i monitorowania interakcji między klientem a firmą w ramach zdefiniowanego scenariusza',
  main_8: 'System zarządzania opiniami. Głos Twojego klienta w jednym systemie',
  main_9:
    'Platforma oparta na sztucznej inteligencji zapewniająca wgląd w dane odbiorców',
  main_10:
    'Oprogramowanie do zarządzania wynikami. Aplikacja grywalizacyjna zwiększająca sprzedaż',
  main_11:
    'Monitorowanie cen to proces zbierania, przetwarzania i analizy cen konkurencji',
  main_12:
    'Projektowanie usług to sposób na zrozumienie potrzeb i pragnień osób, które będą korzystać z produktu lub usługi, poprzez spędzanie z nimi czasu',
  main_13:
    'Nasz zespół analityczny stosuje najnowocześniejsze i sprawdzone podejścia, które okazały się skuteczne dla wielu klientów',
  main_14: 'Metodologie',
  main_15: 'Mapa podróży klienta',
  main_16:
    'Mapowanie podróży klienta (CJM) polega na definiowaniu grupy docelowej, zrozumieniu jej potrzeb i środowiska, a następnie tworzeniu planu dostarczania angażujących doświadczeń',
  main_17: 'CJM',
  main_18: 'Net Promotion Score',
  main_19:
    'Net Promoter Score (NPS) to złoty standard wskaźników doświadczenia klienta, oparty na jednym pytaniu: Jak bardzo prawdopodobne jest, że polecisz Organizację/Produkt/Usługę?',
  main_20: 'NPS',
  main_21: 'Śledzenie kondycji marki',
  main_22:
    'Badania Brand Health Tracking (BHT) mierzą, jak Twoja marka radzi sobie w zakresie świadomości i użycia, pozycjonowania i wyników marki',
  main_23: 'BHT',
  main_24: 'Badania zasobów ludzkich',
  main_25:
    'Badania HR służą do oceny praktyk i wyników w dziedzinie zasobów ludzkich, oferując szczegółową analizę bieżącego rozwoju i zarządzania',
  main_26: 'Badania HR',
  main_27: 'Wskaźnik satysfakcji klientów',
  main_28:
    'Wskaźnik satysfakcji klientów (CSI) to wynik, który pokazuje, jak bardzo klienci są zadowoleni z produktów/usług firmy.',
  main_29: 'CSI',
  main_30: 'Testy UX',
  main_31:
    'Testy użyteczności (UX) polegają na obserwowaniu, jak prawdziwi ludzie korzystają ze strony internetowej, aplikacji lub innego produktu, oraz analizowaniu ich zachowań i reakcji',
  main_32: 'Dlaczego my',
  main_33:
    'Naszą misją jest pomoc firmom w dostarczaniu wysoce efektywnych doświadczeń klienta',
  main_34: 'Skupienie na jakości',
  main_35: 'Kontrolujemy jakość',
  main_36:
    'jeśli chodzi o dane z badań. Udostępniamy wszystkie zestawy danych klientom',
  main_37: 'Ekonomiczność',
  main_38: 'Nasze doświadczenie, zasoby i usprawnione',
  main_39: 'procesy biznesowe pozwalają nam oszczędzać Twój czas i pieniądze',
  main_40: 'Inteligentne rozwiązania',
  main_41: 'Korzystamy z różnych technik',
  main_42: 'i zestawów narzędzi, aby rozwiązywać Twoje specyficzne problemy',
  main_43: 'Skupienie na jakości',
  main_44: 'Kontrolujemy jakość',
  main_45:
    'jeśli chodzi o dane z badań. Udostępniamy wszystkie zestawy danych klientom',
  main_46: 'Ekonomiczność',
  main_47: 'Nasze doświadczenie, zasoby i usprawnione',
  main_48: 'procesy biznesowe pozwalają nam oszczędzać Twój czas i pieniądze',
  main_49: 'Inteligentne rozwiązania',
  main_50: 'Korzystamy z różnych technik',
  main_51: 'i zestawów narzędzi, aby rozwiązywać Twoje specyficzne problemy',
  main_52: 'Cash & Carry',
  main_53: 'Elektronika',
  main_54: 'Luksus/Segment Premium',
  main_55: 'Gastronomia i hotelarstwo',
  main_56: 'Handel detaliczny',
  main_57: 'Motoryzacja',
  main_58: 'Moda',
  main_59: 'Banki',
  main_60: 'Podróże i rekreacja',
  main_61: 'Zegarki i biżuteria',
  main_62: 'AGD i sprzęt domowy',
  main_63: 'Blog',
  main_64: 'Odwiedź blog, aby przeczytać więcej ciekawych postów',
  main_65: 'Audyt detaliczny',
  main_66:
    'Zbieraj szczegółowe dane na temat swojego sklepu lub marki, analizuj bariery wzrostu i twórz skuteczne strategie, aby je pokonać',
  cjm_1: 'Klient',
  cjm_2: 'Mapa podróży',
  cjm_3: 'Wejdź w świat klientów i dziel się ich doświadczeniami',
  cjm_4: 'Co to jest mapa podróży klienta?',
  cjm_5:
    'Mapa podróży klienta (CJM) przedstawia zwizualizowaną historię interakcji konsumentów z produktem, usługą, firmą lub marką za pośrednictwem różnych kanałów i w określonym czasie.',
  cjm_6:
    'Umożliwia to firmom obiektywną analizę doświadczeń z interakcji z klientem, usuwanie przeszkód oraz proponowanie rekomendacji dotyczących ulepszenia produktu.',
  cjm_7: 'Określenie grupy docelowej, segmentacja',
  cjm_8: 'Identyfikacja etapów interakcji klienta',
  cjm_9: 'Identyfikacja głównych kanałów interakcji z klientem',
  cjm_10: 'Badanie doświadczeń klientów',
  cjm_11: 'Analiza barier i hipotezy dotyczące optymalizacji',
  cjm_12: 'Realizacja i testowanie hipotez',
  cjm_13: 'Tworzenie mapy podróży klienta obejmuje następujące kroki:',
  cjm_14: 'Mapa podróży klienta pokaże:',
  cjm_15:
    'CJM przedstawia zwizualizowaną historię interakcji konsumentów z produktem, usługą, firmą lub marką za pośrednictwem różnych kanałów i w określonym czasie.',
  cjm_16: 'Zapewniamy współpracę zespołową',
  cjm_17: 'między analitykami a projektantami',
  cjm_18: 'Oferujemy indywidualne',
  cjm_19: 'podejście do każdego klienta',
  cjm_20: 'Możemy zwiększyć ogólną',
  cjm_21:
    'satysfakcję klientów z usługi/produktu i ostatecznie zwiększyć ich lojalność wobec firmy',
  cjm_22: 'Gwarantujemy',
  cjm_23:
    'optymalizację ścieżki konsumenta przy uwzględnieniu procesów biznesowych firmy',
  cjm_24: 'Oferujemy naszym klientom',
  cjm_25:
    'możliwość korzystania z połączonych podejść: CATI, CAWI, grup fokusowych, wywiadów pogłębionych, wywiadów eksperckich, etnografii itp.',
  cjm_26: 'Dlaczego my?',
  methods_hr_1: 'Badania zasobów ludzkich',
  methods_hr_2:
    'Uzyskaj obiektywne informacje o swoim zespole i dowiedz się, jak poprawić wydajność HR',
  methods_hr_3: 'Kilka powodów, dla których warto przeprowadzić badania HR',
  methods_hr_4: 'ankietowanych pracowników',
  methods_hr_5: 'zrealizowanych projektów HR',
  methods_hr_6: 'przeprowadzonych sesji szkoleniowych dla pracowników',
  methods_hr_7:
    'Wiodący europejski holding w badaniach doświadczeń klientów i pracowników',
  methods_hr_8: 'Badania HR 4service w liczbach',
  methods_hr_9:
    'Identyfikacja problemów i słabości na każdym etapie życia pracowników w Twojej firmie: rekrutacja, onboarding, rozwój kariery, recertyfikacja',
  methods_hr_10: 'Aby wiedzieć, jakie sytuacje zasługują na podwyżki i premie',
  methods_hr_11:
    'Konieczność przewidywania lub rozwiązywania problemów związanych z niezadowoleniem pracowników',
  methods_hr_12:
    'Dzięki badaniom HR możliwe jest stosowanie nowych i obecnych metod oceny polityk, programów i praktyk HR',
  methods_hr_13: 'Aby poprawić zdrowie psychiczne pracowników firmy',
  methods_hr_14:
    'Jeśli chcesz poprawić produktywność poprzez podejście ukierunkowane na cele',
  methods_hr_15:
    'Jeśli masz międzynarodowy i/lub zdalny zespół i musisz nim zarządzać',
  methods_hr_16:
    'Aby zrozumieć, jak atrakcyjna jest Twoja marka pracodawcy dla potencjalnych pracowników',
  methods_hr_17: 'Satysfakcja pracowników',
  methods_hr_18: 'Badanie marki pracodawcy',
  methods_hr_19: 'Różnorodność kulturowa',
  methods_hr_20: 'Plany wynagrodzeń i premii',
  methods_hr_21: 'Zaangażowanie zespołu i lojalność',
  methods_hr_22: 'Pracownicy chronieni',
  methods_hr_23: 'Obszary badań',
  methods_hr_24: 'Rozwiązanie 1',
  methods_hr_25: 'eNPS, Satysfakcja pracowników, Lojalność pracowników',
  methods_hr_26: 'Co mierzymy:',
  methods_hr_27: 'Lojalność:',
  methods_hr_28:
    'lojalność pracowników, przewidywanie przyszłych zachowań, określenie czynników wpływających na lojalność',
  methods_hr_29: 'Zaangażowanie:',
  methods_hr_30:
    'zaangażowanie pracowników w pracę, zainteresowanie wynikami i osiągnięciami',
  methods_hr_31: 'Zaangażowanie:',
  methods_hr_32: 'identyfikacja wartości pracowników i wartości firmy',
  methods_hr_33: 'Zaufanie 360:',
  methods_hr_34:
    'zaufanie do kierownictwa, współpracowników, podwładnych, strategii i polityk pracodawcy',
  methods_hr_35: 'Satysfakcja:',
  methods_hr_36:
    'określenie unikalnych czynników dla każdej firmy, ocena satysfakcji dla każdego kluczowego czynnika wpływającego na lojalność',
  methods_hr_37: 'Rozwiązanie 2',
  methods_hr_38: 'Marka pracodawcy',
  methods_hr_39: 'Analiza wewnętrznej',
  methods_hr_40:
    'marki pracodawcy (badanie pracowników, interesariuszy, wewnętrznych rekruterów i specjalistów HR)',
  methods_hr_41: 'Analiza zewnętrzna pracodawcy',
  methods_hr_42:
    '(analiza EVP konkurencji, ankieta zewnętrznych rekruterów, badanie kandydatów do pracy)',
  methods_hr_43: 'Świadomość marki i reputacja',
  methods_hr_44: 'analiza',
  methods_hr_45: 'Obecność pracodawcy',
  methods_hr_47: 'Opinie o pracodawcy',
  methods_hr_48: 'zbieranie',
  methods_hr_49: 'Benchmarking konkurencyjny',
  methods_hr_50: 'pod kątem kluczowych czynników takich jak',
  methods_hr_51: 'Wynagrodzenie,',
  methods_hr_52: 'środowisko pracy, atmosfera, charakter pracy',
  methods_hr_53: 'Analiza potrzeb i oczekiwań',
  methods_hr_54: 'kandydatów do pracy',
  methods_hr_55: 'Stosuje się wywiady pogłębione,',
  methods_hr_56: 'badania biurowe i ilościowe',
  methods_hr_57: 'Rozwiązanie 3',
  methods_hr_58: 'Mapa podróży pracownika/tajemniczy pracownik',
  methods_hr_59: 'tworzenie mapy podróży kandydata poprzez identyfikację',
  methods_hr_60:
    'głównych etapów poszukiwań, kanałów komunikacji, kryteriów wyboru i oceny kandydatów',
  methods_hr_61: 'monitorowanie wszystkich etapów',
  methods_hr_62:
    'i kanałów komunikacji z tajemniczymi kandydatami (od umówienia rozmowy kwalifikacyjnej, przez rozmowę, po staż w firmie)',
  methods_hr_63: 'Identyfikacja',
  methods_hr_64: 'słabości w procesach rekrutacji i wdrożenia',
  methods_hr_65: 'Rekomendacje dotyczące ulepszeń,',
  methods_hr_66: 'benchmarking z liderami branży',
  mystery_shopping_1: 'Mystery',
  mystery_shopping_2: 'Shopping',
  mystery_shopping_3: 'Oceń swoją usługę z perspektywy prawdziwych klientów',
  mystery_shopping_4:
    'Sprawdź standardy, oceń swój personel, zbliż się do klienta',
  mystery_shopping_5:
    'Jesteśmy elitarnym członkiem wiodącego stowarzyszenia dostawców Mystery Shopping MSPA Europe',
  mystery_shopping_6: 'doświadczenia',
  mystery_shopping_7: 'wizyt miesięcznie',
  mystery_shopping_8: 'tajemniczych klientów na całym świecie',
  mystery_shopping_9: 'Aktywne projekty',
  mystery_shopping_10: 'biura, siedziba główna w Austrii',
  mystery_shopping_11: 'Europejscy dostawcy Mystery Shopping',
  mystery_shopping_12: 'oceny jakości usług',
  mystery_shopping_13: 'Kwestionariusz',
  mystery_shopping_14: 'Oprogramowanie',
  mystery_shopping_15: 'Profil klienta',
  mystery_shopping_16: 'Walidacja i zastrzeżenia',
  mystery_shopping_17: 'Analizy',
  mystery_shopping_18:
    'Dzięki tym 5 krokom nasza firma 4Service gwarantuje jakość usług mystery shopping',
  mystery_shopping_19: 'Program',
  mystery_shopping_20: '5 elementów programu Mystery Shopping 4Service',
  mystery_shopping_21: 'Kwestionariusz:',
  mystery_shopping_22: 'podstawa Twojego programu',
  mystery_shopping_23:
    'Ta część programu jest widoczna dla wszystkich interesariuszy',
  mystery_shopping_24: 'Dla top managerów.',
  mystery_shopping_25: 'Pokazuje wszystkie ważne kroki i szczegóły procesu',
  mystery_shopping_26: 'Dla pracowników.',
  mystery_shopping_27: 'Odwzorowuje oczekiwania pracodawców wobec nich',
  mystery_shopping_28: 'Dla tajemniczych klientów.',
  mystery_shopping_29:
    'Jasne i uporządkowane, aby uniknąć nieporozumień i stronniczości',
  mystery_shopping_30: 'Oprogramowanie Shopmetrics:',
  mystery_shopping_31: 'intuicyjne i innowacyjne',
  mystery_shopping_32: 'Korzyści:',
  mystery_shopping_33: 'Integruje się',
  mystery_shopping_34: 'z procesami i oprogramowaniem Twojej firmy',
  mystery_shopping_35: 'Zgodne z RODO',
  mystery_shopping_36: 'Narzędzie zarządzania prywatnością',
  mystery_shopping_37: 'Szczegółowe',
  mystery_shopping_38: 'Pakiety zgodności prawnej',
  mystery_shopping_39: 'Kreator',
  mystery_shopping_40: 'raportów',
  mystery_shopping_41: 'Zarządzanie',
  mystery_shopping_42: 'poziomami dostępu',
  mystery_shopping_43: 'Większość kwestionariuszy',
  mystery_shopping_44: 'jest wypełniana w ciągu godziny',
  mystery_shopping_45: 'Profil klientów:',
  mystery_shopping_46: '200 000+ tajemniczych klientów na całym świecie',
  mystery_shopping_47:
    'Główne kryteria: maksymalne zbliżenie do Twojej grupy docelowej, wcześniejsze doświadczenie z Twoją firmą lub konkurentami',
  mystery_shopping_48: 'Szkolenia klientów:',
  mystery_shopping_49: 'Wprowadzenie i test szkoleniowy',
  mystery_shopping_50: 'Skype/Rozmowa telefoniczna',
  mystery_shopping_51: 'Test oceny (opcjonalnie)',
  mystery_shopping_52: 'Walidacja i zastrzeżenia',
  mystery_shopping_53: 'Walidacja',
  mystery_shopping_54: 'System',
  mystery_shopping_55: '100% raportów',
  mystery_shopping_56: 'jest weryfikowanych przez zespół walidacyjny',
  mystery_shopping_57: 'Dodatkowe rozmowy',
  mystery_shopping_58: 'dla wyjaśnień',
  mystery_shopping_59: 'Nagrania audio',
  mystery_shopping_60: 'po wizycie, aby zanotować najważniejsze kwestie',
  mystery_shopping_61: 'System zapobiegania',
  mystery_shopping_62: 'oszustwom pracowniczym',
  mystery_shopping_63: 'Zastrzeżenia',
  mystery_shopping_64: 'System',
  mystery_shopping_65: 'Każda ocena tajemniczego klienta',
  mystery_shopping_66:
    'może być zakwestionowana przez pracownika bezpośrednio na stronie',
  mystery_shopping_67: 'Każde zastrzeżenie',
  mystery_shopping_68: 'jest rozpatrywane przez lokalny zespół walidacyjny',
  mystery_shopping_69: 'Możesz zobaczyć statystyki',
  mystery_shopping_70: 'dotyczące zastrzeżeń',
  mystery_shopping_71: 'Analizy.',
  mystery_shopping_72: 'Raporty są prezentowane osobiście i zawierają:',
  mystery_shopping_73: 'Firma',
  mystery_shopping_74: 'i obawy firmy',
  mystery_shopping_75: 'Dynamika',
  mystery_shopping_76: 'według działów i sekcji',
  mystery_shopping_77: 'Korelacja',
  mystery_shopping_78: 'między spełnianiem standardów a zadowoleniem klientów',
  mystery_shopping_79: 'Benchmarking',
  mystery_shopping_80: 'w porównaniu z konkurencją',
  mystery_shopping_81: 'Praktyczne',
  mystery_shopping_82: 'rekomendacje dotyczące ulepszenia usług',
  mystery_shopping_83: 'Co nas wyróżnia?',
  mystery_shopping_84: 'Przeprowadzamy sesje ideacyjne',
  mystery_shopping_85:
    'Nie tylko rekomendujemy, co zrobić, ale razem z Tobą szukamy praktycznych sposobów rozwiązania problemów związanych z usługami.',
  mystery_shopping_86:
    'Sesje ideacyjne to potężne narzędzie, które pomaga nam:',
  mystery_shopping_87: 'Rozwijać kierunki poprawy produktów lub usług',
  mystery_shopping_88:
    'Odkrywać nowe możliwości generowania przychodów i strategie biznesowe',
  mystery_shopping_89:
    'Znajdować rozwiązania złożonych wyzwań skoncentrowanych na klientach',
  mystery_shopping_90:
    'Przekształcać problemy klientów w pozytywne doświadczenia',
  mystery_shopping_91: 'Przeprowadzamy wywiady wideo',
  mystery_shopping_92:
    'Nie tylko zbieramy kwestionariusze - uzyskujemy również komentarze od klientów na żywo',
  mystery_shopping_93: 'Robimy to, aby:',
  mystery_shopping_94:
    'Nasi moderatorzy przeprowadzali dogłębne wywiady i dawali skuteczne informacje zwrotne',
  mystery_shopping_95: 'Zobaczyć doświadczenie z perspektywy klienta',
  mystery_shopping_96: 'Wzmacniać strategię content marketingową',
  mystery_shopping_97: 'Przewidywać zachowania klientów',
  mystery_shopping_98: 'Projektujemy Mapę Podróży Klienta',
  mystery_shopping_99:
    'Mapowanie Podróży Klienta pomaga nam wczuć się w potrzeby i wyzwania klientów.',
  mystery_shopping_100:
    'Tworzenie mapy podróży klienta to najlepszy sposób na:',
  mystery_shopping_101: 'Zobaczenie doświadczenia z perspektywy klienta',
  mystery_shopping_102: 'Wzmocnienie strategii content marketingowej',
  mystery_shopping_103: 'Przewidywanie zachowań klientów',
  mystery_shopping_104: 'Identyfikację luk w obsłudze lub komunikacji',
  mystery_shopping_105: 'Oferujemy różne rodzaje Mystery Shopping',
  mystery_shopping_106: 'Rodzaje Mystery Shopping',
  mystery_shopping_107: 'Tajemniczy rozmówca',
  mystery_shopping_108:
    'Dzwoni do Twoich managerów i/lub call center, aby ocenić jakość obsługi telefonicznej',
  mystery_shopping_109: 'Ekskluzywne mystery shopping',
  mystery_shopping_110:
    'Sprawdzanie obsługi w luksusowych lokalizacjach przez specjalną kategorię tajemniczych klientów',
  mystery_shopping_111: 'Wizyty u konkurencji',
  mystery_shopping_112:
    'Najprostszy sposób na zrozumienie, jak działa Twój konkurent',
  mystery_shopping_113: 'Tajemniczy pracownik',
  mystery_shopping_114:
    'Pomoże Ci zrozumieć całą podróż Twojego pracownika (rekrutacja, adaptacja, procesy biurowe, itp.)',
  mystery_shopping_115: 'Wizyty motywacyjne',
  mystery_shopping_116:
    'Przydatne narzędzie do stymulowania oferty dodatkowych usług i/lub produktów oraz sprzedaży określonych produktów',
  mystery_shopping_117: 'Mystery Shopper Online',
  mystery_shopping_118:
    'Symuluje i mierzy doświadczenie klienta w Twoim sklepie online, na stronie internetowej lub w aplikacji',
  mystery_shopping_119: 'Sprawdzanie promocji i sprzedaży',
  mystery_shopping_120:
    'Pomaga zidentyfikować, jak promocje są wyświetlane we wszystkich lokalizacjach i jak skutecznie działają',
  mystery_shopping_121: 'Wizyty zakupowe i zwrotne',
  mystery_shopping_122:
    'Pomaga odzwierciedlić cały proces od zakupu po zwrot w celu poprawy doświadczenia klienta',
  mystery_shopping_123: '> 20 lat',
  nps_1: 'Net Promotion',
  nps_2: 'Score',
  nps_3: 'Mierz, śledź i poprawiaj lojalność klientów',
  nps_4: 'Odpowiadają w skali od 0 do 6.',
  nps_5:
    'Mogli mieć złe doświadczenia i raczej nie dokonają ponownego zakupu, a nawet mogą odradzać innym zakupy u Ciebie.',
  nps_6: 'To respondenci, którzy ocenili Cię w skali od 7 do 8.',
  nps_7:
    'Są zadowoleni z Twojej usługi, ale nie na tyle, aby być uważani za promotorów.',
  nps_8: 'To respondenci, którzy oceniają Twój biznes na 9 lub 10.',
  nps_9:
    'Oznacza to, że poleciliby Cię znajomemu lub koledze, a więc stają się Twoimi ambasadorami.',
  nps_10: 'Czym jest NPS?',
  nps_11: 'Mierzy postrzeganie klientów na podstawie jednego prostego pytania:',
  nps_12:
    'Jak prawdopodobne jest, że polecisz [Organizację/Produkt/Usługę] znajomemu lub koledze?',
  nps_13: 'Czy moja firma tego potrzebuje?',
  nps_14: 'NPS to Twój zrównoważony wzrost',
  nps_15: 'Zrozum dynamikę lojalności klientów',
  nps_16: 'Zmotywuj swoich pracowników',
  nps_17: 'Zrozum zależność od różnych produktów',
  nps_18: 'Porównaj, jak klienci traktują Ciebie i Twoich konkurentów',
  nps_19: 'Co mogę zrobić z NPS?',
  nps_20: 'Dla jakiego rodzaju biznesu NPS jest odpowiedni?',
  nps_21: 'Metody zbierania informacji:',
  nps_22: 'Nasze rekomendacje dotyczące pracy z NPS',
  nps_23:
    'Oznacza to, że opracowujemy wytyczne, które pomagają przekształcić Detraktorów w promotorów',
  nps_24: 'Pracujemy w metodzie "zamkniętej pętli"',
  nps_25:
    'NPS to badanie śledzące, w którym monitorujemy zmiany w lojalności klientów',
  nps_26: 'Monitorujemy dynamikę',
  nps_27:
    'Zalecamy zawsze oceniać konkurentów w Twojej niszy dla bardziej dogłębnej analizy',
  nps_28: 'Ocena konkurentów',
  price_monitoring_1: 'Monitorowanie cen',
  price_monitoring_2: 'Monitorowanie rynku detalicznego',
  price_monitoring_3:
    'Zwiększ potencjał sprzedaży i zoptymalizuj swoją politykę cenową dzięki monitorowaniu cen konkurencji',
  price_monitoring_4:
    '4Service świadczy usługi monitorowania cen na całym świecie od ponad 20 lat',
  price_monitoring_5:
    'Ceny są monitorowane w krótkim czasie przez nasz zespół wykwalifikowanych audytorów, co pozwala na szybką reakcję na zmiany cen konkurencji',
  price_monitoring_6:
    'Wyzwania, które nasza firma może pomóc rozwiązać dzięki monitorowaniu cen',
  price_monitoring_7: 'Ocena cen konkurencji',
  price_monitoring_8: 'oraz polityki produktowej',
  price_monitoring_9: 'Monitorowanie promocji',
  price_monitoring_10: 'i ofert specjalnych',
  price_monitoring_11: 'Monitorowanie warunków rynkowych',
  price_monitoring_12: 'i otoczenia rynkowego',
  price_monitoring_13: 'Sprawdzanie i kontrola punktów sprzedaży',
  price_monitoring_14: 'pod kątem specjalnego sprzętu i towarów',
  price_monitoring_15: 'Zarządzanie produktem',
  price_monitoring_16: 'w obrocie handlowym',
  price_monitoring_17: 'Optymalizacja warunków',
  price_monitoring_18: 'dla lepszej współpracy z dostawcami, dealerami',
  price_monitoring_19: 'Pomiar przestrzeni półkowej,',
  price_monitoring_20: 'ocena pozycji towarów, dostępność zapasów',
  price_monitoring_21: 'Kluczowe wskaźniki i kryteria monitorowania',
  price_monitoring_22:
    'Wszystkie dane są gromadzone w jednym interfejsie, w tym:',
  price_monitoring_23: 'nazwa',
  price_monitoring_24: 'produktu i kategorii marki',
  price_monitoring_25: 'dostępność',
  price_monitoring_26: 'materiałów promocyjnych',
  price_monitoring_27: 'cena',
  price_monitoring_28: 'i dostępność jakichkolwiek ofert promocyjnych',
  price_monitoring_29: 'półka',
  price_monitoring_30: 'rozmiar',
  price_monitoring_31: 'produkt',
  price_monitoring_32: 'ekspozycja',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'facing',
  price_monitoring_35: 'rodzaj',
  price_monitoring_36: 'opakowania',
  price_monitoring_37: 'inne',
  price_monitoring_38: 'ogólne informacje',
  price_monitoring_39: 'Analiza przedprojektowa:',
  price_monitoring_40: 'Badamy',
  price_monitoring_41:
    'politykę cenową i produktową Twojej firmy, praktyki biznesowe i branżowe',
  price_monitoring_42: 'Zapewniamy',
  price_monitoring_43: 'szybkie szkolenie, testy i certyfikację audytorów',
  price_monitoring_44: 'Dostosowujemy',
  price_monitoring_45: 'i konfigurujemy oprogramowanie',
  price_monitoring_46: 'Prace terenowe:',
  price_monitoring_47: 'Wizyty w punktach sprzedaży',
  price_monitoring_48: 'detalicznej',
  price_monitoring_49: 'Raportowanie:',
  price_monitoring_50: 'Nasz zespół analityczny',
  price_monitoring_51: 'przygotowuje raporty online w kontach osobistych firmy',
  price_monitoring_52: 'Dostarczamy',
  price_monitoring_53:
    'pełny raport fotograficzny, raport PPT, raport Excel według typu lokalizacji, nazwy sieci, adresu punktu sprzedaży',
  price_monitoring_54: 'Jak to',
  price_monitoring_55: 'działa',
  price_monitoring_56: 'rozmiar indywidualnych rabatów',
  price_monitoring_57: 'kalkulacja kosztów',
  price_monitoring_58: 'rozmiar indywidualnych bonusów',
  price_monitoring_59: 'programy lojalnościowe',
  price_monitoring_60: 'itp.',
  price_monitoring_61: 'Korzystamy ze specjalistycznego oprogramowania:',
  price_monitoring_62:
    'PRADATA, Shopmetrics i oferujemy kompleksowe wsparcie techniczne',
  price_monitoring_63: 'Szybkie wdrożenie',
  price_monitoring_64: 'i skalowanie (od pierwszego dnia)',
  price_monitoring_65: 'Wszystkie dane potwierdzone',
  price_monitoring_66: 'zdjęciami i tagowaniem GPS',
  price_monitoring_67: 'Mamy wystarczającą liczbę',
  price_monitoring_68: 'agentów terenowych w każdym kraju',
  price_monitoring_69: 'Potrafimy pracować z dużymi',
  price_monitoring_70: 'ilościami danych',
  price_monitoring_71: 'Monitorowanie indywidualnych cen',
  price_monitoring_72:
    'Jeśli masz skomplikowane produkty – możemy pomóc w ustaleniu strategii cenowej',
  price_monitoring_73:
    'Możemy monitorować indywidualne ceny konkurencji, na przykład:',
  price_monitoring_74: 'Niski',
  price_monitoring_75: 'koszt',
  price_monitoring_76: 'Wysoka',
  price_monitoring_77: 'efektywność',
  price_monitoring_78: 'Raport Excel',
  price_monitoring_79: 'Raport PPT',
  price_monitoring_80: 'Raport BI',
  price_monitoring_81:
    'Dane o cenach konkurencji pomagają podejmować najlepsze decyzje cenowe',
  price_monitoring_82:
    'Dostarczamy pełny raport fotograficzny, raport PPT, raport Excel według typu lokalizacji',
  qualitative_research_1: 'Jakościowe',
  qualitative_research_2: 'badania',
  qualitative_research_3:
    'Zbieraj, analizuj i interpretuj dane poprzez obserwację działań i słów swoich klientów',
  qualitative_research_4: 'Wartości',
  qualitative_research_5: 'Zachowanie',
  qualitative_research_6: 'Tło',
  qualitative_research_7: 'Oczekiwania',
  qualitative_research_8: 'Środowisko',
  qualitative_research_9: 'Obawy/Przeszkody',
  qualitative_research_10: 'Jaki jest cel wyboru klienta?',
  qualitative_research_11: 'Mężczyzna z telefonem',
  qualitative_research_12: 'marka',
  qualitative_research_13: 'kategoria',
  qualitative_research_14: 'reklama',
  qualitative_research_15: 'produkt',
  qualitative_research_16:
    'Unikalność metod jakościowych polega na tym, że pozwalają one zrozumieć przyczyny i motywacje zachowań konsumenckich',
  qualitative_research_17:
    'Analiza nastawienia konsumenta do konkretnego zjawiska',
  qualitative_research_18: 'Indywidualne perspektywy i doświadczenia',
  qualitative_research_19: 'Tematy, które nie mogą być badane za pomocą ankiet',
  qualitative_research_20: 'Wrażliwe tematy',
  qualitative_research_21: 'Trudno dostępna grupa odbiorców',
  qualitative_research_22: 'Złożona geografia',
  qualitative_research_23: 'Dogłębne wywiady przydatne do:',
  qualitative_research_24: 'Dogłębne wywiady',
  qualitative_research_25:
    'Dogłębny wywiad to technika badań jakościowych, która polega na przeprowadzaniu indywidualnych wywiadów z respondentami w celu zbadania ich perspektyw dotyczących konkretnej idei, programu lub sytuacji. Dogłębny wywiad bada indywidualne doświadczenia / percepcje / praktyki w szczegółowy sposób.',
  qualitative_research_26: 'Cele:',
  qualitative_research_27: 'Charakterystyka norm społecznych i kulturowych',
  qualitative_research_28: 'Trudno dostępna grupa odbiorców',
  qualitative_research_29:
    'Dzielić się i porównywać (Morgan) badane za pomocą ankiet',
  qualitative_research_30: 'Tematy, które nie mogą być badane za pomocą ankiet',
  qualitative_research_31: 'Dogłębne grupy fokusowe przydatne do:',
  qualitative_research_32: 'Grupy fokusowe',
  qualitative_research_33:
    'Dogłębna eksploracja indywidualnych spotkań, rozwijających się dyskusji, rozmów między uczestnikami pod wpływem moderatora grupy.',
  qualitative_research_34:
    'Generowanie wglądów w dzielone doświadczenia i normy społeczne poprzez grupową dyskusję, dane narracyjne w skoncentrowanej rozmowie.',
  qualitative_research_36: 'Korzyści:',
  qualitative_research_37:
    'Badanie stylu życia i zachowań konsumenta w różnych rzeczywistych warunkach (w domu, w punkcie sprzedaży, w pracy, itp.)',
  qualitative_research_38:
    'Poszukiwanie wglądów do rozwoju nowych produktów, opakowań, ulepszania usług, itp.',
  qualitative_research_39: 'Dogłębne badania etnograficzne przydatne do:',
  qualitative_research_40: 'Badania etnograficzne',
  qualitative_research_41:
    'Etnografia to rodzaj badań jakościowych, które polegają na zanurzeniu się w określonej społeczności lub organizacji, aby obserwować ich zachowania i interakcje z bliska.',
  qualitative_research_42:
    'Etnografia to elastyczna metoda badawcza, która pozwala na dogłębne zrozumienie wspólnej kultury, konwencji i dynamiki społecznej danej grupy. Jednakże wiąże się również z pewnymi praktycznymi i etycznymi wyzwaniami.',
  qualitative_research_43: 'Metody:',
  qualitative_research_44:
    'Etnografia to badanie konsumenta w warunkach rzeczywistych oraz interakcja z produktem/usługą',
  qualitative_research_45: 'Korzyści ze współpracy z nami',
  qualitative_research_46:
    'Potrafimy znaleźć respondentów z dowolnej grupy docelowej. Kontrolujemy jakość rekrutacji',
  qualitative_research_47: 'Rekrutacja o dowolnym stopniu złożoności',
  qualitative_research_48:
    'Nasi moderatorzy są wielojęzyczni i posiadają umiejętności facylitacji',
  qualitative_research_49: 'Eksperci moderatorzy',
  qualitative_research_50: 'Praca online/offline',
  qualitative_research_51: 'Zapewniamy nagrania audio/wideo',
  qualitative_research_52: 'nagrania',
  qualitative_research_53:
    'Możemy przeprowadzić badania jakościowe w dowolnej lokalizacji',
  quantitative_research_1: 'Badanie satysfakcji klientów',
  quantitative_research_2: 'z konkretnego produktu lub usługi (SCI, NPS)',
  quantitative_research_3: 'Przeprowadzanie segmentacji',
  quantitative_research_4: 'ankiet na różnych rynkach',
  quantitative_research_5: 'Badania rynku',
  quantitative_research_6: 'Zarządzanie NPS',
  quantitative_research_7: 'Analiza konkurencji',
  quantitative_research_8: 'Ilościowe',
  quantitative_research_9: 'badania',
  quantitative_research_10:
    'Metodologia badawcza, która służy do testowania teorii na temat postaw i zachowań ludzi na podstawie obiektywnych, liczbowych i statystycznych danych',
  quantitative_research_11: 'lata',
  quantitative_research_12: 'doświadczenia w CX na całym świecie',
  quantitative_research_13: 'panel',
  quantitative_research_14: 'respondentów',
  quantitative_research_15: 'kraje',
  quantitative_research_16: 'prowadzimy projekty na całym świecie',
  quantitative_research_17: 'Kim jesteśmy',
  quantitative_research_18:
    '4Service to globalna firma badawcza i holding CX. Oferujemy profesjonalne usługi zbierania danych dla szerokiego wachlarza biznesów',
  quantitative_research_19: '4Service w liczbach',
  quantitative_research_20: 'Komputerowo wspomagane',
  quantitative_research_21: 'badania internetowe',
  quantitative_research_22:
    'Technika ankiety internetowej. Respondent wypełnia elektroniczny kwestionariusz bez pomocy ankietera. Kwestionariusz może być wysłany elektronicznie lub zamieszczony na stronie internetowej.',
  quantitative_research_23: 'Komputerowo wspomagane badania internetowe',
  quantitative_research_24: 'W większości przypadków',
  quantitative_research_25:
    'ta metoda jest najbardziej opłacalna pod względem kosztów materialnych i czasowych',
  quantitative_research_26: 'Ta ankieta daje',
  quantitative_research_27:
    'możliwość dotarcia do trudnej do osiągnięcia grupy odbiorców',
  quantitative_research_28: 'Oferuje szerokie możliwości',
  quantitative_research_29:
    'demonstracji materiałów wideo i audio oraz obrazów',
  quantitative_research_30: 'Respondenci mogą być',
  quantitative_research_31: 'zlokalizowani w dowolnym miejscu na świecie',
  quantitative_research_32: 'Umożliwia badanie',
  quantitative_research_33:
    'specyficznych zachowań odbiorców internetowych. Główne wyniki mogą być dostępne dla klienta w czasie rzeczywistym za pośrednictwem interfejsu internetowego',
  quantitative_research_34: 'Nadaje się do każdego poufnego,',
  quantitative_research_35:
    'wrażliwego, osobistego tematu lub kwestii, na które respondenci mogą nie chcieć odpowiedzieć w rozmowie z ankieterem',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37:
    'Posiadamy własny panel respondentów na całym świecie',
  quantitative_research_38: 'Realizujemy trudne zapytania',
  quantitative_research_39:
    'Korzystając z dodatkowych narzędzi (media społecznościowe, reklama), możemy znaleźć konkretną grupę odbiorców i zaawansowany profil respondenta',
  quantitative_research_40: 'Motywujemy naszych respondentów',
  quantitative_research_41:
    'Stworzyliśmy ramy motywacyjne do wypełniania długich ankiet, oferując materialne zachęty',
  quantitative_research_42: 'Wywiady twarzą w twarz',
  quantitative_research_43: 'przeprowadzane za pomocą tabletu',
  quantitative_research_44:
    'Jest to jedna z głównych metod zbierania danych ilościowych, podczas której ankieter komunikuje się bezpośrednio z respondentem na podstawie ściśle ustrukturyzowanego kwestionariusza.',
  quantitative_research_45:
    'Wywiady twarzą w twarz mogą być prowadzone w miejscu pracy lub zamieszkania respondentów, na ulicy lub w wyznaczonych punktach sprzedaży przy użyciu tabletu',
  quantitative_research_46:
    'Wywiady twarzą w twarz przeprowadzane za pomocą tabletu',
  quantitative_research_47: 'Określanie poziomu',
  quantitative_research_48: 'popularności i rozpoznawalności marki',
  quantitative_research_49: 'Segmentacja konsumentów',
  quantitative_research_50: 'na podstawie ich zachowań zakupowych',
  quantitative_research_51: 'Analiza skuteczności',
  quantitative_research_52: 'kampanii reklamowej',
  quantitative_research_53: 'Pomiar satysfakcji klienta',
  quantitative_research_54: 'pomiar',
  quantitative_research_55: 'Określanie najlepszej ceny',
  quantitative_research_56: 'dla produktu lub usługi',
  quantitative_research_57: 'Analiza',
  quantitative_research_58: 'preferencji konsumenckich',
  quantitative_research_59: 'Badanie',
  quantitative_research_60: 'jak konsumenci dokonują zakupu',
  quantitative_research_61: 'Ocena',
  quantitative_research_62: 'pojemności rynku',
  quantitative_research_63: 'Oprogramowanie DigSee',
  quantitative_research_64:
    'Używamy specjalnego oprogramowania z nagrywaniem audio i GPS',
  quantitative_research_65: 'Ankieterzy i nadzorcy',
  quantitative_research_66:
    'możemy przeprowadzać wywiady w dowolnym języku i tłumaczyć na preferowany język klienta',
  quantitative_research_67: 'Dobór próby',
  quantitative_research_68:
    'Dział analityczny opracowuje system rekrutacji respondentów, aby uzyskać najlepszy możliwy wynik',
  quantitative_research_69: 'Komputerowo wspomagane',
  quantitative_research_70: 'Wywiady telefoniczne',
  quantitative_research_71:
    'Metodologia zbierania informacji ilościowych poprzez wywiady telefoniczne przy użyciu jasno ustrukturyzowanego kwestionariusza.',
  quantitative_research_72: 'Komputerowo wspomagane wywiady telefoniczne',
  quantitative_research_73: 'Posiadamy własne call center',
  quantitative_research_74:
    'Używamy specjalnego oprogramowania z nagrywaniem audio i GPS',
  quantitative_research_75: 'Wykwalifikowani operatorzy',
  quantitative_research_76:
    'Nasi operatorzy mówią w różnych językach, są przeszkoleni, dobrze przetestowani i stale doskonalą swoje umiejętności',
  quantitative_research_77: 'Bazy danych',
  quantitative_research_78:
    'Pracujemy z własnymi bazami danych oraz bazami klientów',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Gwarantujemy jakość i podpisujemy SLA (Umowa o poziomie usług)',
  quantitative_research_81: 'Ustalanie celów',
  quantitative_research_82: 'Określenie metodologii',
  quantitative_research_83: 'Segmentacja i dobór próby',
  quantitative_research_84: 'Definiowanie kanałów zbierania danych',
  quantitative_research_85:
    'Tworzenie kwestionariusza logicznego dla respondenta',
  quantitative_research_86: 'Proces zbierania danych',
  quantitative_research_87: 'Zapewnienie jakości',
  quantitative_research_88: 'Techniki analizy',
  quantitative_research_89: 'Przetwarzanie danych',
  quantitative_research_90: 'Projektowanie ankiety',
  quantitative_research_91: 'Śledzenie lokalizacji GPS ankieterów',
  quantitative_research_92: 'Weryfikacja danych',
  quantitative_research_93: 'Programowanie i testowanie kwestionariusza',
  quantitative_research_94: 'Walidacja danych',
  quantitative_research_95: 'Nagrania audio zgodnie z wymaganiami RODO',
  quantitative_research_96: 'Szkolenie i kompetencje personelu badawczego',
  quantitative_research_97: 'Zapewnienie jakości',
  quantitative_research_98: 'Analiza czynnikowa',
  quantitative_research_99: 'Segmentacja',
  quantitative_research_100: 'Mapowanie percepcyjne',
  quantitative_research_101: 'Korelacja',
  quantitative_research_102: 'Model konwersji',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Analiza skupień',
  quantitative_research_105: 'itp.',
  quantitative_research_106: 'Raporty analityczne',
  quantitative_research_107:
    'Używamy SPSS® i innych narzędzi do tworzenia raportów analitycznych z wykorzystaniem różnych metodologii',
  quantitative_research_108: 'Jakie wyzwania biznesowe można rozwiązać:',
  quantitative_research_109: 'Jakie wyzwania biznesowe można rozwiązać:',
  social_responsibility_1: '4Service Społeczna Odpowiedzialność Biznesu',
  social_responsibility_2:
    'Z serca i duszy każdego członka naszego zespołu. Staramy się zachować iskierkę dobroci w każdym z naszych pracowników i uczynić świat wokół nas trochę lepszym',
  social_responsibility_3: 'Działania edukacyjne / Wsparcie młodzieży',
  social_responsibility_4: 'Nasze inicjatywy dla dzieci i studentów',
  social_responsibility_5: 'Biznesowa gra Service Mania',
  social_responsibility_6:
    'Ważną częścią naszych działań szkoleniowych jest biznesowa gra Service Mania.',
  social_responsibility_7:
    'Service Mania zachęca do strategicznego myślenia o tym, jak radzić sobie z nietypowymi sytuacjami.',
  social_responsibility_8: 'Prezentacja badań / Diiya. Biznes',
  social_responsibility_9:
    'Perspektywa biznesowa i klienta na sytuację obsługi: prezentacja wyników naszych badań 4Service autorstwa Wiktorii Skorbota, Dyrektor Rozwoju w 4Service Ukraina',
  social_responsibility_10: 'Sesje projektowania usług',
  social_responsibility_11:
    'Metodologia projektowania usług jest ważną częścią 4Service. Kilka lat temu zostaliśmy partnerem DesignThinkers Academy i nie tylko wykorzystujemy najlepsze praktyki w naszej pracy, ale także aktywnie prowadzimy szkolenia dla najlepszych firm',
  social_responsibility_12: 'Charytatywność / Wolontariat',
  social_responsibility_13:
    'Domy dziecka, domy opieki dla osób starszych i wolontariat dla ukraińskiej armii. Najbardziej wzruszająca część naszych działań',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'Współpracujemy z Fundacją Charytatywną Blagomai i staramy się wnosić radość oraz pomagać tym, którzy są w pilnej potrzebie',
  social_responsibility_16: 'Zbiórki funduszy dla domów dziecka i domów opieki',
  social_responsibility_17:
    'W 4Service zainicjowaliśmy kilka zbiórek funduszy na rzecz domów opieki i domów dziecka, aby zakupić podstawowe artykuły',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'Wojna dotknęła wielu naszych pracowników i bliskich przyjaciół. Każdy z nas pomaga, jak tylko może',
  social_responsibility_20: 'Dobrostan zwierząt',
  social_responsibility_21:
    'Każdy może przyjść do naszego biura ze swoim zwierzakiem. Wspieramy schroniska, organizujemy zbiórki na karmę i czasami szukamy domów dla bezdomnych zwierząt',
  social_responsibility_22: 'Wsparcie dla schronisk dla zwierząt',
  social_responsibility_23:
    'Bezdomne zwierzęta potrzebują pomocy tak samo, jak wszyscy wrażliwi członkowie naszego społeczeństwa.',
  social_responsibility_24:
    'Nasza firma objęła opieką schronisko dla bezdomnych zwierząt Sirius (obwód kijowski, Ukraina).',
  social_responsibility_25:
    'Kreatywne inicjatywy na rzecz pomocy bezdomnym zwierzętom',
  social_responsibility_26:
    'Jak możemy zwrócić uwagę naszych klientów i partnerów na pilny problem schronisk dla zwierząt?',
  social_responsibility_27: 'Biuro przyjazne zwierzętom',
  social_responsibility_28:
    'Wielu członków naszego zespołu ma zwierzęta. Stworzyliśmy przyjazne środowisko, w którym każdy może przyjść do biura ze swoim pupilem i czuć się komfortowo',
  ux_testing_1: 'Testowanie UX',
  ux_testing_1_1: 'Testowanie UX',
  ux_testing_2:
    'Podejście oparte na ludziach i sztucznej inteligencji do testowania i ulepszania twoich stron internetowych i aplikacji',
  ux_testing_3: 'Czym jest testowanie UX',
  ux_testing_4:
    'Testowanie UX pomaga zrozumieć, jak ludzie wchodzą w interakcję z twoim produktem, aplikacją lub stroną internetową',
  ux_testing_5: 'Nawigacja nie jest zbyt czytelna',
  ux_testing_6: 'Nie mam pojęcia, jak złożyć zamówienie',
  ux_testing_7: 'Za mało zdjęć w katalogu',
  ux_testing_8: 'Otrzymasz:',
  ux_testing_9: 'Odkryj możliwości poprawy',
  ux_testing_10: 'Zidentyfikuj problemy w projekcie i interakcji klienta',
  ux_testing_11:
    'Dowiedz się, z jakimi trudnościami użytkownik spotyka się podczas korzystania ze strony',
  ux_testing_12: 'Obserwuj podróż klienta',
  ux_testing_13:
    'Rozpoznaj, kiedy i dlaczego twoja docelowa grupa opuszcza stronę',
  ux_testing_14:
    'Określ, które sekcje budzą najmniejsze i największe zainteresowanie oraz emocje',
  ux_testing_15: 'Testowanie UX oparte na użytkownikach',
  ux_testing_16:
    '4Service dostarcza prawdziwe, ludzkie spostrzeżenia potrzebne do tworzenia stron internetowych i aplikacji skoncentrowanych na kliencie, korzystając z metod jakościowych i ilościowych',
  ux_testing_17:
    'Nie rozumiem, jak korzystać z tej strony. Gdzie jest ukryte menu?',
  ux_testing_18:
    'Uważam, że odstępy między literami są zbyt duże, tekst jest nieczytelny',
  ux_testing_19: 'Obrazy nakładają się na siebie. Czy tak to powinno wyglądać?',
  ux_testing_20:
    'Testowanie UX pomaga zrozumieć, jak ludzie wchodzą w interakcję z twoim produktem, aplikacją lub stroną internetową',
  ux_testing_21: 'Projektowanie badań UX opartych na użytkownikach:',
  ux_testing_22: 'Odkryj możliwości poprawy',
  ux_testing_23: 'Określenie typu i metody testowania (jakościowe/ilościowe)',
  ux_testing_24: 'Opracowanie hipotez i scenariuszy użytkowników',
  ux_testing_25: 'Analiza i raportowanie wyników testów',
  ux_testing_26: 'Dwa rozwiązania',
  ux_testing_27: 'Oferujemy dwa podejścia do skutecznego testowania UX',
  ux_testing_28: 'Testowanie UX oparte na użytkownikach',
  ux_testing_29: 'Testowanie UX oparte na AI + ludziach',
  ux_testing_30: 'Nasze mocne strony:',
  ux_testing_31: 'Analizujemy krajobraz konkurencji',
  ux_testing_32:
    'Włączamy ekspertów niszowych do przeprowadzania i uczestnictwa w wywiadach',
  ux_testing_33:
    'Przeprowadzamy wywiady w różnych językach i tłumaczymy je na wymagany język',
  ux_testing_34: 'z istniejącą użytecznością',
  ux_testing_35: 'metryki lub standardy',
  ux_testing_36: 'twoje wyniki',
  ux_testing_37: 'w porównaniu z konkurencją',
  ux_testing_38: 'wersje',
  ux_testing_39: 'tego samego produktu',
  ux_testing_40: 'Ocena satysfakcji',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Wskaźniki sukcesu',
  ux_testing_43: 'Wskaźnik błędów',
  ux_testing_44: 'Czas realizacji zadania',
  ux_testing_45: 'Testowanie UX oparte na użytkownikach',
  ux_testing_46: 'Metoda ilościowa',
  ux_testing_47:
    'Na tym etapie wykorzystujemy co najmniej 100 osób do udziału w testach UX',
  ux_testing_48:
    'Istotne, gdy potrzebujesz porównania swojej strony internetowej lub aplikacji:',
  ux_testing_49: 'Metoda ilościowa mierzy:',
  ux_testing_50: 'Obserwację',
  ux_testing_51:
    'Spędzanie czasu z użytkownikiem lub grupą użytkowników i obserwowanie ich zachowań podczas korzystania z produktu w codziennym życiu',
  ux_testing_52: 'Dogłębny wywiad',
  ux_testing_53:
    'Pozwala poznać postawy, przekonania, pragnienia i doświadczenia użytkowników odwiedzających twoją stronę. Zalecamy przeprowadzenie 15-20 wywiadów',
  ux_testing_54: 'Badania etnograficzne',
  ux_testing_55:
    'Przeprowadzane w środowisku respondentów, w którym będą korzystać z produktu. Obserwując, zaczynasz rozumieć psychologię użytkownika oraz emocjonalne wyzwania, przed którymi staje.',
  ux_testing_56: 'Testowanie UX oparte na użytkownikach',
  ux_testing_57: 'Metoda jakościowa',
  ux_testing_58:
    'Ta metoda pomaga zrozumieć motywacje i logikę zachowań użytkowników',
  ux_testing_59: 'Używamy:',
  ux_testing_60:
    'Tworzymy mapę podróży klienta, aby zilustrować wyniki naszych badań',
  ux_testing_61: '30 uczestników (mężczyźni/kobiety)',
  ux_testing_62:
    'Wstępna selekcja respondentów i zaproszenie ich do udziału w ankiecie',
  ux_testing_63: 'Zdalne wywiady online na platformie Wantent',
  ux_testing_64: 'AI + ludzka podstawa',
  ux_testing_65: 'Testowanie UX oparte na AI + ludziach',
  ux_testing_66:
    'Połączenie unikalnej technologii sztucznej inteligencji Wantent i prawdziwych użytkowników',
  ux_testing_67: 'Duży font w nagłówku przyciągnął uwagę',
  ux_testing_68:
    "Użytkownicy patrzą na 'mówiące głośno' liczby, ale nie czytają informacji obok nich",
  ux_testing_69:
    'Testowanie UX pomaga zrozumieć, jak ludzie wchodzą w interakcję z twoim produktem, aplikacją lub stroną internetową',
  ux_testing_70: 'Projektowanie badań AI + ludzkich testów UX',
  ux_testing_71: 'Jak to działa',
  ux_testing_72:
    'Wantent to rozwiązanie oparte na sztucznej inteligencji, dopasowane do treści rynkowych.',
  ux_testing_73:
    'Wantent ocenia efektywność stron internetowych i aplikacji, stosując technologie ML do analizy emocjonalnych reakcji i zaangażowania publiczności.',
  ux_testing_74:
    'Aby interpretować wyniki, używamy technik opowiadania historii, różnorodności i empatii, aby napędzać wydajność i wzrost.',
  ux_testing_75:
    'Uwaga i emocje użytkownika podczas przechodzenia przez sekcje',
  ux_testing_76:
    'Statystyki według grupy uczestników (uwaga i skupienie w trakcie sesji)',
  ux_testing_77: 'Analiza konwersji stron',
  ux_testing_78: 'Uwaga i emocje podczas wykonywania zadań',
  ux_testing_79: 'Wnioski z percepcji',
  ux_testing_80: 'strony internetowej przez różne grupy uczestników',
  ux_testing_81: 'Analiza łatwości',
  ux_testing_82:
    'nawigacji po stronach i łatwości znajdowania ważnych elementów',
  ux_testing_83: 'Analiza percepcji',
  ux_testing_84: 'informacji przedstawionych na stronie internetowej',
  ux_testing_85: 'Obszary do zdefiniowania',
  ux_testing_86:
    'ulepszeń w obrębie poszczególnych stron (nawigacja, bloki informacyjne, itp.)',
  ux_testing_87: 'Analizujemy szczegółowo',
  ux_testing_88: 'Kluczowe wyniki badań',
  ux_testing_89:
    'Rekomendacje dotyczące poprawy struktury strony internetowej i poszczególnych stron',
  ux_testing_90:
    'Dokładnie dobieramy docelową grupę odbiorców do testów użyteczności.',
  ux_testing_91:
    'Prowadzimy badania w różnych krajach, różnych językach i wśród różnych grup użytkowników',
  ux_testing_92: 'Możemy testować w aplikacjach',
  ux_testing_93:
    'i na stronach konkurencji oraz dostarczać analizy porównawcze',
  ux_testing_94: 'Łączymy różne metody badawcze.',
  ux_testing_95:
    'Nasi wykwalifikowani moderatorzy prowadzą uczestnika przez proces testowy.',
  ux_testing_96: 'Przeprowadzamy testy',
  ux_testing_97: 'na różnych urządzeniach i wersjach oprogramowania',
  ux_testing_98: 'Dlaczego warto wybrać nas?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Unikalny projekt partnerski 4Service i Wantent © Połączenie sztucznej inteligencji i podejścia opartego na ludziach do analizy treści',
  wantent_4: 'pomiar sukcesu przed emisją treści',
  wantent_5: 'minimalizacja ryzyka związanego z kwestiami różnorodności',
  wantent_6: 'dogłębne analizy i rekomendacje dotyczące ulepszenia treści',
  wantent_7: 'obiektywne decyzje dotyczące treści i strategii marketingowej',
  wantent_8: 'Wantent to rozwiązanie dopasowujące treść do rynku oparte na AI.',
  wantent_9:
    'Wantent to rozwiązanie oparte na sztucznej inteligencji, dopasowujące treść do rynku. Wantent ocenia skuteczność treści wideo, stosując technologie ML do analizy emocji i zaangażowania odbiorców.',
  wantent_10:
    'Wantent pomaga określić dopasowanie treści do rynku i maksymalizować jej efektywność poprzez',
  wantent_11: 'ustalanie scenariusza i rekrutację uczestników',
  wantent_12: 'spersonalizowany projekt i globalną grupę docelową',
  wantent_13: 'zbieranie reakcji widzów',
  wantent_14: 'szczegółowe i przejrzyste opinie od odbiorców',
  wantent_15: 'analiza percepcji treści',
  wantent_16: 'analiza zachowań i reakcji emocjonalnych za pomocą AI',
  wantent_17: 'przekazanie wniosków końcowych',
  wantent_18: 'rekomendacje dotyczące ulepszeń',
  wantent_19: 'Wantent maksymalizuje efektywność Twojej treści',
  wantent_20: 'Wstępne testowanie koncepcji reklamowej',
  wantent_21: 'Testowanie koncepcji kreatywnej',
  wantent_22:
    'Sprawdzenie spójności przyszłej kreacji z opracowaną strategią oraz zrozumienie reakcji grupy docelowej w celu możliwych modyfikacji komunikatów/koncepcji kreatywnych',
  wantent_23: 'Testowanie widoczności elementów marki (po produkcji)',
  wantent_24: 'Potwierdzenie skuteczności inwestycji w reklamę/sponsoring',
  wantent_25: 'Testowanie reklamy',
  wantent_26: 'Testy A/B',
  wantent_27:
    'Porównywanie skuteczności kreacji i zwiększanie zaangażowania oraz uwagi podczas produkcji',
  wantent_28: 'Testowanie uruchomionej reklamy',
  wantent_29:
    'Określanie skuteczności komunikatów i tworzenie rekomendacji dotyczących ulepszeń audio i wizualnych',
  wantent_30: 'Rozwiązania dla telewizji',
  wantent_31:
    'Testowanie pilotów, promocji, nowych formatów i prezenterów telewizyjnych',
  wantent_32:
    'Przeprowadzanie analizy porównawczej w celu identyfikacji najbardziej angażującej opcji i jej wzmocnienia',
  wantent_33: 'Testowanie programów telewizyjnych i filmów pełnometrażowych',
  wantent_34:
    'Analiza zaangażowania widzów, poziomu uwagi i reakcji emocjonalnych podczas oglądania długich treści w naturalnych warunkach. Automatycznie identyfikujemy momenty spadku uwagi i dekoncentracji w celu oceny i ulepszenia montażu wideo',
  wantent_35: 'Testy UI/UX',
  wantent_36: 'Testowanie prototypów stron internetowych i aplikacji mobilnych',
  wantent_37:
    'Analiza reakcji użytkowników i emocji przy użyciu technologii Wantent:',
  wantent_38: 'poziom uwagi',
  wantent_39: 'reakcje emocjonalne',
  wantent_40: 'mapy cieplne kierunku spojrzeń uczestników',
  wantent_41:
    'Analiza użyteczności stron internetowych i aplikacji na podstawie opinii uczestników:',
  wantent_42: 'Skala użyteczności systemu (SUS)',
  wantent_43: 'Wskaźnik wysiłku klienta (łatwość interakcji)',
  wantent_44: 'Wskaźnik Promocji Netto (NPS)',
  wantent_45: 'Zgodność z GDPR i prywatność użytkownika',
  wantent_46:
    'Wantent jest w pełni zgodny z zasadami GDPR i CCPA oraz spełnia zasady zbierania, przechowywania, przetwarzania i ochrony danych osobowych uczestników',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (Media & Entertainment)',
  wantent_49:
    'Codziennie eksperymentujemy z wieloma nowymi pomysłami na treści przy użyciu technologii uczenia maszynowego. Wantent pomaga nam zrozumieć i ocenić ryzyka i korzyści, odkryć opinie użytkowników, ich zachowania i percepcję, aby zidentyfikować przyczyny różnych aspektów naszych rozwiązań.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'współwłaściciel TABASCO (Reklama)',
  wantent_52:
    'Wantent oferuje naprawdę unikalną usługę, która pozwala nam poznać nawet najmniejsze szczegóły tego, co konsumenci lubią lub nie. Nie ma możliwości oszustwa ani kłamstwa — Wantent zawsze zna prawdę, a my jako eksperci marketingowi otrzymujemy bezcenne (hahaha — znamy dokładną cenę i jest przystępna) wskazówki, jak poprawić nasze komunikaty.',
  wantent_53: 'Recenzje',
  terms_1: 'Regulamin',
  terms_2: 'Umowa dotycząca warunków korzystania',
  terms_3:
    'Prosimy o dokładne zapoznanie się z niniejszą Umową dotyczącą warunków korzystania (“warunki korzystania”, “umowa”) przed rozpoczęciem korzystania z witryny internetowej',
  terms_4:
    '(“witryna”) obsługiwanej przez 4Service Holdings GmbH ( “4Service”, “nas”, ‘my”, “nasze”).',
  terms_5:
    'Warunki korzystania: Korzystając z tej witryny, potwierdzasz, że zapoznałeś się z niniejszą Umową i zgadzasz się przestrzegać jej warunków. Jeśli nie chcesz być związany warunkami tej Umowy, zalecamy opuszczenie witryny. 4Service udostępnia korzystanie i dostęp do tej witryny, jej produktów i usług wyłącznie tym, którzy zaakceptowali jej warunki.',
  terms_6: 'Polityka prywatności',
  terms_7:
    'Zanim zaczniesz korzystać z naszej witryny, zalecamy zapoznanie się z naszą Polityką prywatności dotyczącą zbierania danych użytkownika. Pomoże Ci to lepiej zrozumieć nasze praktyki.',
  terms_8: 'Ograniczenie wiekowe',
  terms_9:
    'Musisz mieć co najmniej 16 (szesnaście) lat, aby móc korzystać z tej witryny. Korzystając z tej witryny, oświadczasz, że masz co najmniej 16 (szesnaście) lat i możesz legalnie przestrzegać niniejszej Umowy. 4Service nie ponosi odpowiedzialności za zobowiązania związane z błędnym przedstawieniem wieku.',
  terms_10: 'Własność intelektualna',
  terms_11:
    'Zgadzasz się, że wszystkie materiały, produkty i usługi udostępnione na tej witrynie są własnością 4Service, jego podmiotów stowarzyszonych, dyrektorów, pracowników, agentów, dostawców lub licencjodawców, w tym wszystkie prawa autorskie, tajemnice handlowe, znaki towarowe, patenty oraz inne prawa własności intelektualnej. Zgadzasz się również, że nie będziesz reprodukować ani rozpowszechniać własności intelektualnej 4Service w jakikolwiek sposób, w tym w formie elektronicznej, cyfrowej ani rejestracji nowych znaków towarowych.',
  terms_12: 'Dostęp do naszej witryny',
  terms_13:
    'Dostęp do naszej strony jest udostępniany tymczasowo, a my zastrzegamy sobie prawo do wycofania lub zmiany usług bez powiadomienia. Nie ponosimy odpowiedzialności, jeśli nasza witryna będzie niedostępna w dowolnym czasie lub przez dowolny okres. Od czasu do czasu możemy ograniczać dostęp do niektórych części naszej witryny lub całej witryny dla użytkowników zarejestrowanych u nas. Odpowiadasz za zapewnienie sobie dostępu do naszej witryny, w tym za użycie kompatybilnego sprzętu. Ponosisz również odpowiedzialność za zapewnienie, że osoby uzyskujące dostęp do naszej witryny przez twoje połączenie internetowe znają te warunki i ich przestrzegają.',
  terms_14: 'Obowiązujące prawo',
  terms_15:
    'Odwiedzając tę witrynę, zgadzasz się, że prawa Republiki Austrii, bez uwzględnienia zasad konfliktu praw, będą regulować te warunki oraz wszelkie spory między 4Service a Tobą.',
  terms_16: 'Spory',
  terms_17:
    'Wszelkie spory związane z wizytą na tej witrynie będą rozstrzygane przez Sąd Handlowy w Wiedniu.',
  terms_18: 'Zwolnienie z odpowiedzialności',
  terms_19:
    'Zgadzasz się zwolnić 4Service i jego podmioty stowarzyszone z odpowiedzialności prawnej oraz bronić 4Service przed roszczeniami wynikającymi z Twojego korzystania lub nadużycia naszej witryny. Zastrzegamy sobie prawo do wyboru własnego doradcy prawnego.',
  terms_20: 'Okres przechowywania danych',
  terms_21:
    'Biorąc pod uwagę cele przetwarzania, okres przechowywania danych osobowych użytkowników wynosi 24 miesiące od daty uzyskania zgody na przetwarzanie danych.',
  terms_22: 'Ograniczenie odpowiedzialności',
  terms_23:
    '4Service nie ponosi odpowiedzialności za jakiekolwiek szkody, które mogą Cię spotkać w wyniku niewłaściwego korzystania z naszej witryny. 4Service zastrzega sobie prawo, bez powiadomienia, do edytowania, modyfikowania i zmiany tej Umowy w dowolnym momencie przez aktualizację tego wpisu. Kontynuowanie korzystania z witryny teraz lub po wprowadzeniu jakichkolwiek zmian oznacza ich akceptację. Jeśli jakakolwiek część tej umowy zostanie uznana za niezgodną z prawem, nieważną lub niewykonalną, ta część będzie uważana za rozdzielną i nie wpłynie na ważność pozostałych postanowień. Możliwe dowody wykorzystania witryny do nielegalnych celów zostaną przekazane organom ścigania. Niniejsza Umowa jest porozumieniem między 4Service a użytkownikiem.',
  terms_24:
    'Prosimy kierować wszystkie pytania dotyczące prywatności/użytkowania na następujący adres:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Wiedeń',
  terms_28: 'Austria',
  terms_29: 'Przegląd',
  terms_30:
    'Jeśli dane osobowe, które nam przekazujesz, są nieprawidłowe, masz prawo poprosić nas o ich poprawienie (artykuł 16 RODO).',
  thank_you_1: 'Dziękujemy!',
  thank_you_2: 'Odezwiemy się w ciągu 24 godzin',
  thank_you_3:
    'P.S. Nasi menedżerowie już analizują Twoje zgłoszenie, aby przygotować lepszą ofertę',
  thank_you_4: 'Menu',
  thank_you_5: 'Kobieta pisząca na klawiaturze',
  privacy_policy_1:
    'Formularz informacji o ochronie danych osobowych dla użytkowników strony internetowej',
  privacy_policy_2:
    'Niniejszy formularz informacji o ochronie danych osobowych skierowany jest do osób fizycznych (podmiotów danych), których dane osobowe są zbierane przez 4Service Holdings GmbH zgodnie z wymogami RODO w ramach korzystania z witryny internetowej',
  privacy_policy_3:
    '(dalej zwanej Stroną internetową oraz Użytkownikami Strony odpowiednio).',
  privacy_policy_4:
    'Formularz informacji o ochronie danych osobowych dla Użytkowników Strony internetowej 4Service Holdings GmbH ma zastosowanie we wszystkich spółkach Grupy 4Service, które są pod nadzorem prawnym 4Service Holdings GmbH.',
  privacy_policy_5: 'Kontakt',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Spółka/my/nas)',
  privacy_policy_8: 'Adres:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Wiedeń, Austria.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Kim jesteśmy?',
  privacy_policy_13:
    'Osoba (Ty), która odwiedza Stronę Internetową w dowolnym celu. Na potrzeby tego Dokumentu Ty będziesz również określany jako Użytkownik.',
  privacy_policy_14: 'Dane osobowe, które zbieramy od Ciebie',
  privacy_policy_15:
    'Kiedy wchodzisz na naszą Stronę Internetową, wysyłamy Ci zawiadomienie o ochronie prywatności, w którym prosimy o zgodę na przetwarzanie następujących informacji:',
  privacy_policy_16:
    'Adres IP, imię użytkownika, nazwisko, adres, numer telefonu (stacjonarny lub komórkowy), adres e-mail, adres, nazwa firmy, kraj, adres e-mail, imię, nazwisko, numer telefonu, prowincja, stan i kod pocztowy, dane dotyczące użytkowania, dane dotyczące interakcji z zewnętrznymi sieciami społecznościowymi lub platformami, informacje o rejestracji i uwierzytelnianiu na stronie internetowej',
  privacy_policy_17: 'pozycja geograficzna.',
  privacy_policy_18:
    'W przypadku wyrażenia zgody na przetwarzanie takich danych przez Ciebie, rozpoczynamy ich przetwarzanie.',
  privacy_policy_19: 'Zgodność z prawem (zgoda)',
  privacy_policy_20:
    'Kiedy wchodzisz na naszą Stronę Internetową, wysyłamy Ci zawiadomienie o ochronie prywatności, w którym prosimy o zgodę na przetwarzanie następujących informacji:',
  privacy_policy_21:
    'Podstawą prawną przetwarzania danych osobowych jest zgoda, którą zamierzamy uzyskać od Ciebie poprzez wypełnienie formularza zgody pod następującym linkiem:',
  privacy_policy_22:
    'Jeśli wypełnisz formularz zgody, oznacza to, że rozumiesz i akceptujesz wszystkie warunki określone w niniejszym Zawiadomieniu o Ochronie Prywatności.',
  privacy_policy_23: 'Wycofanie zgody',
  privacy_policy_24:
    'Masz prawo wycofać zgodę, którą wcześniej nam udzieliłeś, w dowolnym momencie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania na podstawie zgody przed jej wycofaniem. Możesz wycofać swoją zgodę, wysyłając odpowiednie zgłoszenie na następujący adres e-mail: withdraw@4service-group.com, przy czym formularz zgłoszenia jest dostępny pod następującym linkiem:',
  privacy_policy_25: 'Formularz wycofania zgody dla Użytkownika',
  privacy_policy_26: 'Cele przetwarzania',
  privacy_policy_27: 'Przetwarzamy Twoje dane osobowe w następujących celach:',
  privacy_policy_28:
    '– ulepszanie obsługi klienta (umożliwia bardziej skuteczną odpowiedź na zapytania klientów); – personalizacja doświadczenia użytkowników (pozwala określić, kto jest bardziej zainteresowany usługami); – ulepszanie Strony Internetowej (umożliwia poprawę jakości produktów i usług, ich wygodniejszego korzystania, rozwój nowych usług i produktów); – komunikacja z użytkownikami za pomocą newsletterów, materiałów marketingowych lub promocyjnych oraz innych informacji, które zawierają nasze aktualizacje i informacje o usługach z instrukcją dotyczącą rezygnacji z kolejnych e-maili; – prowadzenie badań statystycznych i innych analiz na podstawie zanonimizowanych danych; – świadczenie spersonalizowanych usług użytkownikowi i realizacja umów; – udział użytkownika w różnych projektach realizowanych przez nas poprzez Stronę, odpowiadanie na zapytania użytkownika oraz prowadzenie konta.',
  privacy_policy_29: 'Podmioty, którym mogą być przekazywane dane osobowe',
  privacy_policy_30:
    'Przetwarzając Twoje dane osobowe, przekazujemy je podmiotom działającym jako przetwarzający dla Spółki. Przetwarzający działają wyłącznie na podstawie instrukcji Spółki. Szczegóły dotyczące działań przetwarzających i lista takich podmiotów znajdują się w Polityce Prywatności i Ochrony Danych pod następującym linkiem: Privacy and Data Protection Policy',
  privacy_policy_31: 'Kraje, do których mogą być przekazywane dane osobowe',
  privacy_policy_32:
    'Spółka przekazuje Twoje dane osobowe na podstawie decyzji o adekwatności zgodnie z RODO i Komisją UE. Dodatkowe informacje dotyczące przekazywania danych osobowych do USA można znaleźć w Polityce Prywatności i Ochrony Danych pod następującym linkiem: Privacy and Data Protection Policy.',
  privacy_policy_33: 'Okres przechowywania',
  privacy_policy_34:
    'Biorąc pod uwagę cele przetwarzania, okres przechowywania danych osobowych Użytkowników wynosi 24 miesiące od daty uzyskania prawidłowej zgody na przetwarzanie danych od Ciebie.',
  privacy_policy_35: 'Prawo dostępu',
  privacy_policy_36:
    'Masz prawo wiedzieć, czy dane osobowe dotyczące Ciebie są przetwarzane, a jeśli tak, uzyskać dostęp do tych danych zgodnie z dodatkowymi warunkami określonymi w artykule 15 RODO.',
  privacy_policy_37: 'Prawo do sprostowania',
  privacy_policy_38:
    'Jeśli dane osobowe, które nam przekazałeś, są nieprawidłowe, masz prawo poprosić nas o ich poprawienie zgodnie z artykułem 16 RODO.',
  privacy_policy_39: 'Prawo do usunięcia danych („prawo do bycia zapomnianym”)',
  privacy_policy_40:
    'Masz prawo żądać od nas usunięcia Twoich danych osobowych bez zbędnej zwłoki, a my mamy obowiązek je usunąć, jeśli spełnione są warunki określone w artykule 17 RODO.',
  privacy_policy_41: 'Prawo do ograniczenia przetwarzania',
  privacy_policy_42:
    'Masz prawo ograniczyć przetwarzanie swoich danych osobowych z kilkoma wyjątkami określonymi w artykule 18 RODO.',
  privacy_policy_43:
    'Jesteśmy zobowiązani poinformować Cię, jakie dane są zbierane, w jaki sposób są wykorzystywane, jak długo będą przechowywane i czy będą udostępniane stronom trzecim. Informacje te muszą być przekazane w sposób zwięzły i prostym językiem.',
  privacy_policy_44: 'Prawo do przenoszenia danych',
  privacy_policy_45:
    'Masz prawo uzyskać i ponownie wykorzystać swoje dane osobowe dla własnych celów w różnych usługach.',
  privacy_policy_46: 'Prawo do sprzeciwu',
  privacy_policy_47:
    'Masz prawo sprzeciwić się przetwarzaniu danych osobowych przez Spółkę. Musimy zaprzestać przetwarzania danych osobowych, chyba że wykażemy uzasadnione podstawy do przetwarzania, które mają nadrzędny charakter wobec interesów, praw i wolności osoby fizycznej lub jeśli przetwarzanie jest niezbędne do ustalenia, wykonania lub obrony roszczeń prawnych.',
  privacy_policy_48:
    'Prawo do niewyrażenia zgody na decyzję opartą wyłącznie na automatycznym przetwarzaniu',
  privacy_policy_49:
    'Masz prawo sprzeciwić się wszelkiemu automatycznemu profilowaniu, które odbywa się bez zgody. Masz również prawo do przetwarzania swoich danych z udziałem człowieka.',
  privacy_policy_50: 'Skargi',
  privacy_policy_51:
    'Jeśli chcesz złożyć skargę dotyczącą przetwarzania Twoich danych osobowych przez Spółkę (lub przetwarzających opisanych powyżej) albo sposobu rozpatrzenia Twojej skargi, masz prawo zgłosić ją bezpośrednio organowi nadzorczemu i Spółce.',
  privacy_policy_52: 'Dane kontaktowe dla każdego z tych podmiotów:',
  privacy_policy_53: 'Organ nadzorczy:',
  privacy_policy_54: 'Austriacki Urząd Ochrony Danych',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Wiedeń',
  privacy_policy_58: 'Austria / Europa',
  privacy_policy_59: 'Spółka:',
  privacy_policy_60: '4Service Holdings GmbH (Spółka/my/nas),',
  privacy_policy_61: 'Adres: Tegetthoffstraße 7, 1010 Wiedeń, Austria.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Polityka prywatności i ochrona danych',
  privacy_policy_64:
    'Dowiedz się więcej o tym, jak i dlaczego wykorzystujemy Twoje dane tutaj: Privacy and Data Protection Policy.',
  privacy_policy_65: 'Zatwierdzenie',
  privacy_policy_66:
    'Spółka opracowała wszystkie wewnętrzne dokumenty, aby określić role personelu dotyczące przetwarzania danych osobowych w ramach Spółki. Za zatwierdzenie i weryfikację zgodności niniejszego dokumentu odpowiada Dyrektor Zarządzający.',
  contacts_1: 'Dla klientów',
  contacts_2:
    'Dla wszelkiego rodzaju firm, agencji badania rynku i dużych przedsiębiorstw, partnerów biznesowych oraz mediów.',
  contacts_3: 'Tegetthoffstraße 7, Wiedeń, Austria',
  contacts_4: 'Dla Tajemniczych Klientów',
  contacts_5:
    'Mieszkańców i podróżnych, kierowców i studentów, profesjonalnych tajemniczych klientów i domatorów.',
  contacts_6: 'Skontaktuj się z nami z Twojego kraju:',
  contacts_7: 'Włochy',
  contacts_8: 'USA',
  contacts_9: 'Wielka Brytania',
  contacts_10: 'Szwajcaria',
  contacts_11: 'Niderlandy',
  contacts_12: 'Słowacja',
  contacts_13: 'Słowenia',
  contacts_14: 'Rumunia',
  contacts_15: 'Azerbejdżan',
  contacts_16: 'Ukraina',
  contacts_17: 'Kazachstan',
  contacts_18: 'Reszta Świata',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX testing',
  menu_6: 'Badania HR',
  menu_7: 'Badania jakościowe',
  menu_8: 'Badania ilościowe',
  menu_9: 'Mystery shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Monitoring cen',
  menu_14: 'O nas',
  menu_15: 'Kontakt',
  menu_16: 'Blog',
  menu_17: 'Metody',
  menu_18: 'Produkty',
  menu_19: 'Platforma dla shopperów',
  menu_20: 'CSR',
  menu_21: 'Analiza cen motoryzacyjnych',
  menu_22: 'Facility Check Audit',
  menu_23: 'Projektowanie usług',
  menu_24: 'CX',
  nps_passives: 'Pasywni',
  nps_detractors: 'Krytycy',
  nps_promoters: 'Promotorzy',
  nps_a_score: 'wynik',
  area: 'Obszar',
  products_tabs_1:
    'W celu osiągnięcia maksymalnej efektywności przeprowadzamy również kompleksowe działania:',
  products_tabs_2: 'Produkty',
  products_tabs_3:
    'Badania jakościowe to zbieranie i analiza danych nienumerycznych w celu zrozumienia koncepcji, opinii lub doświadczeń',
  products_tabs_4:
    'Metody badań ilościowych to obiektywna analiza danych liczbowych zebranych w ramach ankiet, kwestionariuszy i sondaży',
  our_clients_1: 'Nasi klienci',
  preview_cases_section_1: 'Przypadki',
  preview_cases_section_2:
    'Przejdź do przypadków, aby przeczytać więcej interesujących wpisów',
  blog_1: 'Blog z inspirującymi treściami',
  blog_2: 'Brak dostępnych wpisów dla Twojego zapytania',
  about: 'O nas',
  why_us: 'Dlaczego my',
  about_us: 'O nas',
  key_features: 'Kluczowe cechy',
  solutions: 'Rozwiązania',
  monitoring: 'Monitorowanie',
  how: 'Jak',
  how_it_works: 'Jak to działa',
  when_to_use: 'Kiedy używać',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Głos klienta',
  wantent: 'Testowanie treści wideo',
  play4sales: 'Play4Sales',
  price_monitoring: 'Monitorowanie cen',
  service_design: 'Projektowanie usług',
  qualitative_research: 'Badania jakościowe',
  quantitative_research: 'Badania ilościowe',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Motoryzacja',
  hashtag_retayl: '#Retail',
  hashtag_all_posts: '#Wszystkie_wpisy',
  hashtag_feedback: '#Opinie',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Przypadki',
  hashtag_market_research: '#Badania_rynku',
  more: 'Więcej',
  speak_to_an_expert: 'Porozmawiaj z ekspertem',
  book_consultancy: 'Zarezerwuj konsultację',
  read_all: 'Przeczytaj wszystko',
  find_out_more: 'Dowiedz się więcej',
  address: 'Adres',
  mon_fri: 'Pon-Pt',
  phone_number: 'Numer telefonu',
  flag: 'flaga',
  scroll_to_left: 'przewiń w lewo',
  online: 'online',
  tel: 'Tel',
  email: 'E-mail',
  light_it_up: 'Rozświetl to',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Dyrektor ds. rozwoju biznesu',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Starszy kierownik projektów',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Manager ds. Rozwoju Biznesu',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Dyrektor Operacyjny',
  about_us_roles_1: 'Zarządzanie',
  about_us_roles_2: 'Zespół Global',
  about_us_roles_3: 'Właściciele produktów',
  ad_testing_1: 'Testowanie reklam',
  ad_testing_2:
    'Kampania reklamowa to ogromna inwestycja. Upewnij się, że Twoje reklamy są skuteczne i przynoszą maksymalne ROI',
  ad_testing_3: 'blokada',
  ad_testing_4: 'Branże, którym służymy',
  ad_testing_5: '4Service to globalny holding badań doświadczeń klientów',
  ad_testing_6:
    '4Service to globalny holding badań marketingowych i CX. Oferujemy ekspertów ds. zbierania danych dla różnych branż',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Retail',
  ad_testing_9: 'Elektronika',
  ad_testing_10: 'Luksus/Premium',
  ad_testing_11: 'Gastronomia i Hotelarstwo',
  ad_testing_12: 'Motoryzacja',
  ad_testing_13: 'Podróże i Wypoczynek',
  ad_testing_14: 'Moda',
  ad_testing_15: 'Banki',
  ad_testing_16: 'Zegarki i Biżuteria',
  ad_testing_17: 'AGD i RTV',
  ad_testing_18: 'Członek Esomar',
  ad_testing_19: 'Przeprowadziliśmy badania testowe reklam dla różnych branż',
  ad_testing_20: 'Potrzebujesz testowania reklam, jeśli:',
  ad_testing_21: 'Promujesz nowy produkt lub usługę',
  ad_testing_22: 'Dotykasz wrażliwego tematu',
  ad_testing_23: 'Próbujesz dotrzeć do nowej grupy docelowej lub rynku',
  ad_testing_24: 'Omawiasz kilka różnych opcji projektowych',
  ad_testing_25:
    'Potrzebujesz koncepcji do zatwierdzenia przez zarząd lub inwestorów',
  ad_testing_26: 'Uzyskasz odpowiedzi na pytania',
  ad_testing_27: 'Które kanały reklamowe najlepiej pasują do tych reklam?',
  ad_testing_28:
    'Czy używamy odpowiednich wizualizacji, aby przekazać komunikat?',
  ad_testing_29: 'Czy Twoje teksty i komunikaty reklamowe rezonują?',
  ad_testing_30: 'Które kanały reklamowe najlepiej pasują do tych reklam?',
  ad_testing_31: 'Co przyciąga ich uwagę w pierwszej kolejności?',
  ad_testing_32: 'Czy umiejscowienie i układ reklamy są łatwe do zrozumienia?',
  ad_testing_33: 'Oceniamy',
  ad_testing_34: 'Branding',
  ad_testing_35:
    'jak Twoja marka jest powiązana z reklamą i jak bardzo zostanie zapamiętana',
  ad_testing_36: 'Kluczowe wskaźniki',
  ad_testing_37: 'Integracja',
  ad_testing_38: 'Rozpoznawalność',
  ad_testing_39: 'Dopasowanie',
  ad_testing_40: 'Kreatywność',
  ad_testing_41: 'Jak Twoja reklama wyróżnia się i przyciąga uwagę ludzi',
  ad_testing_42: 'Kluczowe wskaźniki',
  ad_testing_43: 'ROZPOZNAWALNOŚĆ',
  ad_testing_44: 'SYMPATIA',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTYWACJA',
  ad_testing_47: 'Doświadczenie klienta, zaangażowanie emocjonalne',
  ad_testing_48:
    'Dzięki technologii sztucznej inteligencji i analizom ekspertów analizujemy prawdziwe emocje ludzi i uzyskujemy wgląd w ich percepcję reklam',
  ad_testing_49: 'Ocena',
  ad_testing_50: 'Mężczyzna',
  ad_testing_51: 'Kobieta',
  ad_testing_52: 'Wysokie zaangażowanie',
  ad_testing_53: 'Uwaga',
  ad_testing_54: 'Co spodobało się i zapadło w pamięć odbiorcom?',
  ad_testing_55: 'Aktorka',
  ad_testing_56: 'Równość',
  ad_testing_57: 'Podkład muzyczny',
  ad_testing_58: 'Pozytywne emocje',
  ad_testing_59: 'Ludzie różnych ras i wyglądu',
  ad_testing_60: 'Ujęcia plenerowe',
  ad_testing_61: 'Co nie spodobało się i nie zapadło w pamięć odbiorcom?',
  ad_testing_62: 'Głos mówcy nie pasował do tego, co działo się na ekranie',
  ad_testing_63: 'Niska uwaga na produkt',
  ad_testing_64: 'Zbyt dynamiczne',
  ad_testing_65: 'Trudne do zrozumienia',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Wrażenia',
  ad_testing_68: 'Pokazy',
  ad_testing_69: 'Konwersja',
  ad_testing_70: 'Media',
  ad_testing_71:
    'Mierzymy skuteczność kampanii reklamowej w powiązaniu z aktywnością medialną, obliczając wpływ inwestycji medialnych w różnych kanałach na monitorowane wyniki.',
  ad_testing_72:
    'Przeprowadzamy badania reklamowe w dowolnym formacie, na dowolnej platformie lub w dowolnym kanale',
  ad_testing_73: 'treści wideo',
  ad_testing_74: 'outdoor',
  ad_testing_75: 'Reklamy telewizyjne',
  ad_testing_76: 'druk',
  ad_testing_77: 'digital',
  ad_testing_78: 'kampanie reklamowe',
  ad_testing_79: 'Reklama i ekspozycje POS',
  ad_testing_80: 'animacje',
  ad_testing_81:
    'Dzięki naszej pomocy możesz testować swoją reklamę na każdym etapie',
  ad_testing_82: 'Koncepcja',
  ad_testing_83: 'które pomysły mają większy potencjał?',
  ad_testing_84: 'Produkcja na wczesnym etapie',
  ad_testing_85: 'które animacje trafią do produkcji?',
  ad_testing_86: 'Produkcja',
  ad_testing_87: 'jak zmontować wideo, aby utrzymało uwagę?',
  ad_testing_88: 'Przed media',
  ad_testing_89:
    'które kanały komunikacji będą działać i jaki można oczekiwać wynik?',
  ad_testing_90: 'Testowanie reklamy po emisji',
  ad_testing_91: 'Produkcja',
  ad_testing_92:
    'czy moja reklama jest angażująca? Czy skuteczność kreatywności maleje z czasem?',
  ad_testing_93: 'Przed media',
  ad_testing_94:
    'jak skuteczna jest moja reklama w liczbach? jak wypada w porównaniu z konkurencją?',
  ad_testing_95: 'Testowanie reklamy przed emisją',
  ad_testing_96: 'Aby dostarczyć Ci wysokiej jakości dane, stosujemy',
  ad_testing_97: 'Metodologię badań ilościowych',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Metodologię badań jakościowych',
  ad_testing_100: 'Grupy Fokusowe',
  ad_testing_101: 'AI',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Raporty analityczne, które otrzymasz',
  ad_testing_104:
    'Nasz zespół analityków, ekspertów medialnych i marketerów przygotuje i zaprezentuje Ci dopasowany raport z szczegółowymi rekomendacjami',
  ad_testing_age: 'wiek',
  ad_testing_ad: 'Reklama',
  automotive_industry_price_analysis_1: 'Analiza cen w branży motoryzacyjnej',
  automotive_industry_price_analysis_2:
    'Analiza konkurencyjnych cen może pomóc zapobiec utracie zysków, zwiększyć udział w rynku i monitorować pozycję na rynku. Pozwól nam pomóc Ci wybrać skuteczną strategię konkurencyjną!',
  automotive_industry_price_analysis_3: 'Poproś o ofertę',
  automotive_industry_price_analysis_4: 'Nasza metodologia',
  automotive_industry_price_analysis_5:
    'Stylowi, eleganccy ludzie w salonie samochodowym',
  automotive_industry_price_analysis_6:
    'Metoda Mystery Shopping jest jedynym podejściem, które dokładnie odzwierciedla rzeczywiste doświadczenie klienta i zapewnia wiarygodny przegląd.',
  automotive_industry_price_analysis_7:
    'Oferujemy precyzyjne dane liczbowe i wiarygodne, wszechstronne informacje',
  automotive_industry_price_analysis_8:
    'Mamy globalny zasięg obejmujący każdy kraj na świecie',
  automotive_industry_price_analysis_9:
    'Nasze zasoby wewnętrzne obejmują tajemniczych klientów i audytorów',
  automotive_industry_price_analysis_10: 'Weryfikujemy jakość naszych danych',
  automotive_industry_price_analysis_11: 'Nasze zalety:',
  automotive_industry_price_analysis_12:
    'Monitorowanie cen określonych modeli konkurencyjnych (B2B i B2C)',
  automotive_industry_price_analysis_13: 'Monitorowanie poziomu rabatów',
  automotive_industry_price_analysis_14:
    'Monitorowanie zapasów sprzedawców dla Twojej marki i konkurencji',
  automotive_industry_price_analysis_15:
    'Monitorowanie cen w e-commerce w branży motoryzacyjnej',
  automotive_industry_price_analysis_16: 'Analiza komunikacji cenowej',
  automotive_industry_price_analysis_17:
    'Śledzenie kosztów wysyłki sprzedawców',
  automotive_industry_price_analysis_18:
    'Monitorowanie promocji i wyprzedaży u sprzedawców',
  automotive_industry_price_analysis_19:
    'Określanie wysokości miesięcznych płatności',
  automotive_industry_price_analysis_20: 'Obliczanie rat leasingowych',
  automotive_industry_price_analysis_21:
    'Analiza specyficznych aspektów cenowych dla pojazdów elektrycznych (EV)',
  automotive_industry_price_analysis_22:
    'Prowadzenie porównań cen bezpośrednio dla konsumentów i sprzedawców',
  automotive_industry_price_analysis_23: 'Tworzenie modeli cen subskrypcyjnych',
  automotive_industry_price_analysis_24:
    '4Service to globalna firma CX z ponad 20-letnim doświadczeniem',
  automotive_industry_price_analysis_25:
    'Będziesz korzystać z danych na temat gotowości konsumentów do płacenia za różne funkcje, aby zoptymalizować swoją ofertę i zidentyfikować najważniejsze funkcje dla swoich docelowych klientów.',
  automotive_industry_price_analysis_26:
    'Analiza cen w branży motoryzacyjnej przez 4Service obejmuje:',
  automotive_industry_price_analysis_27:
    'Najniższe ceny nie zawsze są kluczem do konkurencyjności marek samochodowych. Pomóżmy Ci stworzyć długoterminową strategię cenową',
  automotive_industry_price_analysis_28:
    'klientów rozumie, że ceny rosną we wszystkich markach',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'klientów powiedziało, że nadal kupiliby samochód, który im się podoba, nawet jeśli marka poinformowałaby ich o nadchodzącym wzroście cen',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'ludzi uważa leasing i miesięczne płatności za preferowany model zakupu samochodu',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'dealerów wspomniało o możliwości podwyżek cen podczas procesu komunikacji/sprzedaży',
  automotive_industry_price_analysis_35: '47,6',
  automotive_industry_price_analysis_36: 'Kluczowe wskaźniki',
  automotive_industry_price_analysis_37:
    'Dowiesz się, jak ustalić cenę swojego produktu w odniesieniu do innych pojazdów w ofercie.',
  automotive_industry_price_analysis_38:
    'Będziesz korzystać z danych na temat gotowości konsumentów do płacenia za różne funkcje, aby zoptymalizować swoją ofertę i zidentyfikować najważniejsze funkcje dla swoich docelowych klientów.',
  automotive_industry_price_analysis_39:
    'klientów powiedziało, że nadal kupiliby samochód, który im się podoba, nawet jeśli marka poinformowałaby ich o nadchodzącym wzroście cen',
  automotive_industry_price_analysis_40:
    'Będziesz mógł określić odpowiednią wysokość płatności miesięcznych i stawek leasingowych.',
  automotive_industry_price_analysis_41:
    'Jakie korzyści dla Twojej marki lub dealera zyskasz:',
  automotive_industry_price_analysis_42: 'Składasz wniosek',
  automotive_industry_price_analysis_43:
    'Nasz menedżer kontaktuje się z Tobą w celu określenia głównych celów',
  automotive_industry_price_analysis_44: 'Przeprowadzamy badania marketingowe',
  automotive_industry_price_analysis_45:
    'Regularnie dostarczamy Ci raporty i rekomendacje w formacie online',
  automotive_industry_price_analysis_46: 'Samochód z naklejką promocyjną',
  automotive_industry_price_analysis_47: 'Uruchamiamy projekt w 1 dzień!',
  automotive_industry_price_analysis_48:
    'Oferujemy kompleksowy zestaw raportów, w tym raporty fotograficzne, raporty PPT i raporty Excel, segmentowane według rodzaju lokalizacji',
  retail_audit_1: 'AUDYT MARKETINGOWY W TERENIE/audyt detaliczny',
  retail_audit_2:
    'Zbieraj szczegółowe dane o swoim sklepie lub marce, analizuj bariery wzrostu i twórz skuteczne strategie ich przezwyciężenia',
  retail_audit_3: 'Poproś o ofertę',
  retail_audit_4:
    '4Service przeprowadza audyty sklepów na miejscu w imieniu marek w celu oceny skuteczności ich strategii promocyjnych, zespołów terenowych i taktyk marketingowych w dotarciu do docelowego rynku.',
  retail_audit_5:
    'Skrupulatnie obserwujemy realizację kampanii promocyjnych i zapewniamy zgodność z wytycznymi promocyjnymi.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'agencji CX w Europie',
  retail_audit_8: 'lat doświadczenia',
  retail_audit_9: 'zasięg w krajach',
  retail_audit_10: '1,6 miliona',
  retail_audit_11: 'evaluacje roczne',
  retail_audit_12: 'Co monitorujemy?',
  retail_audit_13: 'Obecność materiałów POS (point-of-sale)',
  retail_audit_14:
    'Obecność i aktywacja ambasadorów marki, zapewnienie zgodności z planowanym harmonogramem',
  retail_audit_15:
    'Ocena zaangażowania ambasadorów marki w kampanie promocyjne',
  retail_audit_16:
    'Ocena interakcji z klientami, zapewnienie zgodności z wytycznymi promocyjnymi i komunikacją marki',
  retail_audit_17: 'Środki zapobiegające oszustwom',
  retail_audit_18:
    'Twoja firma może wykorzystać wyniki audytu marketingowego w terenie, aby:',
  retail_audit_19: 'Ocenić zgodność z budżetem i procesami',
  retail_audit_20: 'Porównać rzeczywiste wyniki z zamierzonymi celami',
  retail_audit_21: 'Zwiększyć efektywność kampanii marketingowych',
  retail_audit_22: 'Strategicznie planować przyszłość',
  retail_audit_23: 'Zidentyfikować możliwości redukcji kosztów',
  retail_audit_24: 'Zwiększyć sprzedaż i wskaźniki konwersji',
  retail_audit_25: 'Optymalizować zwrot z inwestycji (ROI)',
  retail_audit_26: 'WYBRALI 4SERVICE JAKO PARTNERA DS. INWESTYCJI HANDLOWYCH',
  retail_audit_27:
    'Wiodący międzynarodowy producent wyrobów tytoniowych nawiązał współpracę z 4Service w celu wzmocnienia swoich marek i poprawy wyników w sklepach. Nasze zespoły zapewniły audyty marketingowe w terenie i raportowanie na żywo dotyczące doświadczeń konsumenckich.',
  retail_audit_28:
    'wzrost dokładności planowania agencji marketingowej w terenie',
  retail_audit_29: 'wzrost skuteczności przekazu marki',
  retail_audit_30:
    'wzrost zaangażowania ambasadorów marki (aktywacja konsumentów)',
  retail_audit_31: 'wzrost dokładności zbierania danych i zgodności z RODO',
  retail_audit_32: 'Jakie cenne spostrzeżenia zwykle znajdujemy:',
  retail_audit_33:
    'Brak spójności lub nawet konflikt między warunkami SLA prowadzi do znacznych niezatwierdzonych opłat dla klienta',
  retail_audit_34:
    'Nieprawidłowe naliczanie opłat z powodu pomylenia typów świadczonych usług',
  retail_audit_35:
    'Niewykonanie usług w terenie, w tym brak przypisania personelu do zaplanowanych lokalizacji | POS',
  retail_audit_36:
    'Opłaty naliczane na podstawie szacunków, a nie rzeczywistych danych, bez ich rozliczenia',
  retail_audit_37:
    'Nieprawidłowe stosowanie wytycznych promocyjnych, dostarczanie fałszywych danych',
  retail_audit_38: 'Jak pracujemy?',
  retail_audit_39:
    'Nasz audytor odwiedza wyznaczone lokalizacje punktów sprzedaży (POS) zgodnie z planem aktywacji agencji.',
  retail_audit_40:
    'Oceniamy kluczowe wskaźniki efektywności (KPI) określone w briefie, takie jak obecność POS, zaangażowanie ambasadorów marki, zgodność z wytycznymi promocyjnymi i środki zapobiegające oszustwom.',
  retail_audit_41: 'Walidacja:',
  retail_audit_42: 'Zbieramy i konsolidujemy uzyskane dane.',
  retail_audit_43: 'Tworzymy kompleksowy raport.',
  retail_audit_44:
    'Nasz zespół walidacyjny dokładnie sprawdza wszystkie ankiety pod kątem zgodności z projektem oraz dodatkowych komentarzy.',
  retail_audit_45: 'System raportowania online:',
  retail_audit_46:
    'Wypełnione i zweryfikowane kwestionariusze są przesyłane do naszego portalu internetowego w ciągu 36 godzin.',
  retail_audit_47:
    'Klienci mają dostęp do osobistego portalu, co pozwala im monitorować postęp w dowolnym momencie.',
  retail_audit_48: 'Udostępniamy końcowy raport z wynikami i zaleceniami.',
  retail_audit_49: 'Porozmawiaj z ekspertem',
  retail_audit_50: 'Nasze mocne strony:',
  facility_check_audit_1: 'Obiekt',
  facility_check_audit_2: 'Audyt kontrolny',
  facility_check_audit_3: 'Podnieś doświadczenie marki w każdym szczególe',
  facility_check_audit_4: 'Poznaj nasze podejście',
  facility_check_audit_5: 'O nas',
  facility_check_audit_6: 'Kim jesteśmy',
  facility_check_audit_7:
    'Jako globalna firma zajmująca się doświadczeniami klientów, 4Service oferuje kompleksowy pakiet usług, aby pomóc markom tworzyć i dostarczać wyjątkowe doświadczenia dla swoich klientów.',
  facility_check_audit_8: 'Nasza usługa Facility Check Audit',
  facility_check_audit_9:
    'została zaprojektowana, aby pomóc Ci upewnić się, że Twoje lokalizacje fizyczne i obecność marki w sklepach spełniają standardy marki i wywierają pozytywne wrażenie na klientach.',
  facility_check_audit_10: 'Rozwiązania 1',
  facility_check_audit_11: 'Facility Check Audit',
  facility_check_audit_12:
    'to szczegółowe badanie oceniające zgodność Twoich punktów sprzedaży lub stoisk z normami Twojej marki. Sprawdzamy nie tylko aspekty fizyczne, ale również oceniamy, jak te elementy wpływają na postrzeganie Twojej marki przez klientów.',
  facility_check_audit_13: 'Dwa rozwiązania',
  facility_check_audit_14: 'Dwie metody wdrożenia',
  facility_check_audit_15: 'Metoda',
  facility_check_audit_16: 'Facility Check Audit przez ekspertów audytorów',
  facility_check_audit_17: 'Walidacja Facility Check',
  facility_check_audit_18: 'Raporty',
  facility_check_audit_19:
    'Otrzymasz raporty zawierające wizualne dowody i statystyki dostępne w ciągu 48 godzin',
  facility_check_audit_20:
    'Dobrze przeprowadzony Facility Check Audit może przynieść wiele korzyści dla Twojej marki, w tym:',
  facility_check_audit_21: 'Zwiększenie konwersji klientów:',
  facility_check_audit_22:
    'Pozytywne doświadczenia klientów mogą prowadzić do wzrostu sprzedaży i powtarzających się transakcji. Nasze audyty mogą pomóc w identyfikacji i eliminacji obszarów, które wpływają na satysfakcję klientów.',
  facility_check_audit_23: 'Wzmacnianie wizerunku marki:',
  facility_check_audit_24:
    'Twoje lokalizacje fizyczne są kluczowym punktem styku dla klientów i mogą odgrywać znaczącą rolę w kształtowaniu wizerunku Twojej marki. Nasze audyty pomogą Ci zapewnić, że lokalizacje są zgodne z wartościami marki i wywierają pozytywne wrażenie.',
  facility_check_audit_25: 'Dlaczego my?',
  facility_check_audit_26: 'Dlaczego Facility Check Audit jest ważny?',
  facility_check_audit_27:
    'Dobrze przeprowadzony Facility Check Audit może przynieść wiele korzyści dla Twojej marki, w tym:',
  facility_check_audit_28: 'Wzmacnianie wizerunku marki:',
  facility_check_audit_29:
    'Twoje lokalizacje fizyczne są kluczowym punktem styku dla klientów i mogą odgrywać znaczącą rolę w kształtowaniu wizerunku Twojej marki. Nasze audyty pomogą Ci zapewnić, że lokalizacje są zgodne z wartościami marki i wywierają pozytywne wrażenie.',
  facility_check_audit_30: 'Zwiększenie konwersji klientów:',
  facility_check_audit_31:
    'Pozytywne doświadczenia klientów mogą prowadzić do wzrostu sprzedaży i powtarzających się transakcji. Nasze audyty mogą pomóc w identyfikacji i eliminacji obszarów, które wpływają na satysfakcję klientów.',
  facility_check_audit_32: 'Kto potrzebuje',
  facility_check_audit_33: 'Kto potrzebuje Facility Check Audit?',
  facility_check_audit_34:
    'Facility Check Audit to wartościowa usługa dla każdej marki posiadającej fizyczne lokalizacje i obecność stacjonarną. Jest szczególnie ważna dla marek, które chcą:',
  facility_check_audit_35: 'Zadbać o zgodność ze standardami marki:',
  facility_check_audit_36:
    'Nasze audyty mogą pomóc w zidentyfikowaniu obszarów, w których Twoje lokalizacje mogą odbiegać od standardów marki.',
  facility_check_audit_37: 'Poprawić doświadczenie klienta:',
  facility_check_audit_38:
    'Nasze audyty mogą pomóc w identyfikacji możliwości poprawy doświadczeń klientów w Twoich lokalizacjach.',
  facility_check_audit_39: 'Budować silniejszą markę:',
  facility_check_audit_40:
    'Nasze audyty mogą pomóc w zapewnieniu, że fizyczne lokalizacje tworzą pozytywne wrażenie na klientach.',
  facility_check_audit_41:
    'Twój pracownik lub tajemniczy klient robi zdjęcie lokalizacji.',
  facility_check_audit_42: 'Następnie wypełnia ankietę i załącza zdjęcie.',
  facility_check_audit_43:
    'Walidator otrzymuje zdjęcie i sprawdza jego zgodność ze standardami.',
  facility_check_audit_44:
    'Facility Check Audit przeprowadzany przez Ekspertów Auditorów',
  facility_check_audit_45: 'Etap Przygotowawczy:',
  facility_check_audit_46: 'Wspólna akceptacja harmonogramu audytu',
  facility_check_audit_47:
    'Dealerzy są przygotowywani i informowani o nadchodzącym audycie',
  facility_check_audit_48: 'Szkolenie Auditorów:',
  facility_check_audit_49:
    'Nasi doświadczeni trenerzy 4Service przekazują auditorom wytyczne dotyczące marki',
  facility_check_audit_50: 'Etap Audytu Obiektów:',
  facility_check_audit_51:
    'Auditorzy, posiadający specjalne listy autoryzacyjne, odwiedzają lokalizacje',
  facility_check_audit_52:
    'Skrupulatne badanie każdego punktu zgodnie z wytycznymi',
  facility_check_audit_53:
    'Dokumentacja wizualna wraz z ocenami zgodnie z wymaganiami centrali',
  facility_check_audit_54: 'Końcowy Raport PPT',
  facility_check_audit_55:
    'Wszechstronny raport PowerPoint przedstawiający ustalenia',
  facility_check_audit_56:
    'Immersyjna prezentacja wyników audytu online/offline',
  facility_check_audit_57: 'Walidacja Facility Check',
  facility_check_audit_58: 'Kroki:',
  facility_check_audit_59: 'Etap Audytu Obiektów:',
  facility_check_audit_60:
    'Przedstawiciele marki korzystają z aplikacji 4Service do robienia zdjęć na miejscu',
  facility_check_audit_61:
    'Zachowanie rygorystycznych kryteriów wizualnych i opisowych',
  facility_check_audit_62: 'Etap Walidacji:',
  facility_check_audit_63:
    'Dokładni walidatorzy starannie sprawdzają każdy raport',
  facility_check_audit_64: 'Kompleksowe porównanie zdjęć ze standardami marki',
  facility_check_audit_65:
    'Komentarze i spostrzeżenia zintegrowane z raportem dla jasności',
  facility_check_audit_66:
    'Bezpośrednia komunikacja z dealerami w celu uzyskania wyjaśnień, jeśli potrzebne',
  facility_check_audit_67: 'Etap Raportowania:',
  facility_check_audit_68:
    'Raporty, zawierające dowody wizualne i statystyki, dostępne w ciągu dwóch dni',
  facility_check_audit_69: 'Skontaktuj się z nami już dziś',
  cx_solution_1: 'Nie tylko znajdujemy informacje,',
  cx_solution_2: 'Wznosimy Twój biznes na nowe wyżyny',
  cx_solution_3:
    'Eksperci z ponad 20-letnim doświadczeniem w dziedzinie doświadczeń klienta odkrywają kompleksowe rozwiązania i dostarczają konkretny plan działania, który przyczynia się do Twojego wzrostu',
  cx_solution_4:
    'Odkryj unikalny pakiet usług w jednym przyszłościowym rozwiązaniu',
  cx_solution_5: 'Wszystko w jednym — rozwiązanie CX',
  cx_solution_6: 'Testowanie reklam',
  cx_solution_7: 'AI i Neuromarketing',
  cx_solution_8: 'Głos klienta',
  cx_solution_9: 'Badania biurkowe',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Tajemniczy klient',
  cx_solution_12: 'Konsulting CX',
  cx_solution_13: 'Monitorowanie mediów społecznościowych',
  cx_solution_14: 'Badania ilościowe i jakościowe',
  cx_solution_15: 'Projektowanie usług',
  cx_solution_16: 'Strategia marketingowa',
  cx_solution_17: 'Testowanie UX/UI',
  cx_solution_18: 'Wgląd w grupę docelową',
  cx_solution_19:
    'Jesteśmy zaszczyceni, że możemy przyczynić się do Twojego sukcesu',
  cx_solution_20:
    'Dostosowujemy i łączymy narzędzia oraz usługi w oparciu o cele Twojego biznesu, aby dostarczyć spersonalizowane wyniki',
  cx_solution_21: 'Kompleksowe badania',
  cx_solution_22:
    'Wzmocnij proces podejmowania decyzji, zapewniając przewagę konkurencyjną na rynku zorientowanym na dane.',
  cx_solution_23:
    'Badania jakościowe to zbieranie i analiza danych nienumerycznych w celu zrozumienia pojęć, opinii lub doświadczeń',
  cx_solution_24:
    'Metody badań ilościowych obejmują obiektywne pomiary i analizę danych liczbowych zebranych za pomocą ankiet, kwestionariuszy i sondaży',
  cx_solution_25:
    'Stosujemy unikalny zestaw narzędzi w oparciu o Twoje specyficzne wymagania',
  cx_solution_26: 'Narzędzia',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Komputerowo wspomagane ankiety internetowe to kluczowe narzędzie w naszym arsenale, umożliwiające przeprowadzanie ankiet i zbieranie danych za pośrednictwem platform internetowych z pomocą specjalistycznego oprogramowania, zapewniając efektywne i dokładne gromadzenie danych dla potrzeb badawczych naszych klientów',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Computer-Assisted Telephone Interviewing to metoda, którą wykorzystujemy do przeprowadzania ankiet i gromadzenia wartościowych danych przez telefon, przy użyciu specjalistycznego oprogramowania w celu usprawnienia procesu i zapewnienia dokładności w naszych działaniach badawczych',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Face-to-Face jest integralną częścią naszego podejścia, umożliwiając bezpośrednie interakcje i spersonalizowane zaangażowanie z klientami, co sprzyja budowaniu zaufania, zrozumienia i efektywnej komunikacji, kluczowych dla trwałych relacji oraz sukcesu biznesowego',
  cx_solution_33: 'Desk research',
  cx_solution_34:
    'Desk research obejmuje kompleksowe zbieranie i analizowanie danych, stanowiąc podstawowy krok w naszym procesie badawczym w celu uzyskania wglądu i wsparcia podejmowania decyzji strategicznych dla naszych klientów',
  cx_solution_35: 'SWOT-analysis',
  cx_solution_36:
    'SWOT analysis to narzędzie oceny strategicznej, które pomaga naszym klientom zidentyfikować wewnętrzne Mocne i Słabe strony, a także zewnętrzne Szanse i Zagrożenia, dostarczając cennych informacji do podejmowania decyzji i opracowywania skutecznych strategii biznesowych',
  cx_solution_37: 'Badania etnograficzne',
  cx_solution_38:
    'Badanie ludzi i kultur poprzez zanurzenie i obserwację w ich naturalnym środowisku, co często prowadzi do głębokich wniosków dotyczących zachowań, przekonań i dynamiki społecznej',
  cx_solution_39: 'Dogłębne wywiady',
  cx_solution_40:
    'Dogłębne wywiady to metoda badawcza jakościowa, którą stosujemy, polegająca na szczegółowych, indywidualnych rozmowach z uczestnikami w celu dogłębnego zrozumienia ich perspektyw, doświadczeń i opinii, dostarczając cennych wglądów i wsparcia w procesach decyzyjnych naszych klientów',
  cx_solution_41: 'Sesje ideacyjne',
  cx_solution_42:
    'Sesje ideacyjne to współpracujące sesje burzy mózgów, które prowadzimy, łącząc różnorodne perspektywy i wiedzę w celu generowania kreatywnych pomysłów i rozwiązań dla wyzwań lub możliwości naszych klientów, wspierając innowacje i realizując strategiczne inicjatywy',
  cx_solution_43: 'Buyer personas',
  cx_solution_44:
    'Buyer personas to szczegółowe profile, które opracowujemy, reprezentujące docelowych klientów naszych klientów na podstawie badań i analizy danych, co umożliwia lepsze zrozumienie ich potrzeb, preferencji i zachowań oraz dostosowanie naszych strategii i komunikacji w celu efektywnego zaangażowania i budowania relacji, co przyczynia się do sukcesu biznesowego',
  cx_solution_45: 'Mystery Shopping',
  cx_solution_46:
    'Mystery Shopping to strategiczna usługa, którą oferujemy, mierząc i monitorując interakcje między klientem a firmą podczas z góry określonego scenariusza',
  cx_solution_47: 'Sprawdzanie towarzyszące',
  cx_solution_48:
    'Sprawdzanie towarzyszące to specjalistyczna forma mystery shopping, w której nasi asesorzy towarzyszą klientom podczas całego procesu obsługi, dostarczając kompleksowych wglądów we wszystkie aspekty podróży klienta, co pozwala naszym klientom na głębokie zrozumienie jakości ich usług i podejmowanie świadomych ulepszeń w celu zwiększenia zadowolenia i lojalności klientów',
  cx_solution_49: 'Analiza zbierania opinii',
  cx_solution_50:
    'Analiza zbierania opinii to kluczowy proces, który prowadzimy, polegający na systematycznym zbieraniu i analizie opinii klientów z różnych kanałów, takich jak ankiety, recenzje i media społecznościowe, co pozwala nam wydobyć cenne wnioski, zidentyfikować trendy i opracować rekomendacje oparte na danych dla naszych klientów w celu poprawy ich produktów, usług i ogólnych doświadczeń klienta',
  cx_solution_51: 'Grupy fokusowe',
  cx_solution_52:
    'Grupy fokusowe to dynamiczne sesje badawcze, które prowadzimy, gromadząc różnorodną grupę uczestników w celu omówienia konkretnych tematów lub produktów w sposób dogłębny, co pozwala nam zebrać jakościowe wnioski, odkryć postawy, preferencje i percepcje oraz eksplorować pomysły i koncepcje, ostatecznie wspierając procesy decyzyjne i opracowywanie strategii naszych klientów',
  cx_solution_53: '20 lat tworzenia wyjątkowych doświadczeń klienta',
  cx_solution_54:
    'Upewnij się, że Twoje przesłanie przyciąga uwagę i rezonuje z odbiorcami.',
  cx_solution_55:
    'Jesteśmy oddani dostarczaniu wyników, które przekraczają Twoje oczekiwania',
  cx_solution_56:
    'Odkrywamy złożone rozwiązania, które przyczyniają się do Twojego wzrostu',
  cx_solution_57: 'Podnieś poziom obsługi klienta dzięki naszej metodologii',
  cx_solution_58:
    'Customer Journey Mapping to definiowanie grupy docelowej, zrozumienie ich potrzeb i środowiska, a następnie stworzenie planu zapewniającego angażujące doświadczenie',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Brand Health Tracking pozwala mierzyć wyniki marki w zakresie świadomości i użytkowania, pozycjonowania marki oraz wyników marki',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Indeks Satysfakcji Klienta (Customer Satisfaction Index) to wskaźnik wskazujący poziom zadowolenia klientów z produktów/usług firmy.',
  cx_solution_63:
    'Monitorowanie cen to proces zbierania, przetwarzania i analizy cen konkurencji',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score to złoty standard metryk doświadczenia klienta, oparty na jednym pytaniu: Jak bardzo prawdopodobne jest, że polecisz Organizację/Produkt/Usługę?',
  cx_solution_66: 'Badania HR',
  cx_solution_67:
    'Badania zasobów ludzkich są wykorzystywane do oceny praktyk HR i ich wyników oraz oferują szczegółową analizę rozwoju i zarządzania',
  cx_solution_68:
    'Testowanie użyteczności to proces polegający na obserwacji, jak prawdziwi ludzie wchodzą w interakcję z witryną, aplikacją lub innym produktem oraz analizie ich zachowań i reakcji',
  cx_solution_69: 'Inne',
  cx_solution_70:
    'Eksperci wybierają najlepszą metodologię dla Twojego biznesu',
  cx_solution_71: 'Zacznijmy',
  cx_solution_72: '1 - SPOTKANIE STARTOWE',
  cx_solution_73:
    'Podczas spotkania startowego identyfikujemy i koncentrujemy się na kluczowych zadaniach. Rezultat: zrozumienie celu, zadań badawczych, terminów, metod i obszarów odpowiedzialności.',
  cx_solution_74: '2 - OPRACOWANIE KONCEPCJI',
  cx_solution_75:
    'Dział analityczny 4Service agreguje dane, koordynuje strukturę raportu. Następnie przedstawiamy klientowi wyniki, uzupełnione o wnioski i rekomendacje do wdrożenia.',
  cx_solution_76: '3 - PRACE W TERENIE',
  cx_solution_77:
    'Aby przeprowadzić badania, rekrutujemy uczestników, przeprowadzamy wywiady i inne podstawowe etapy badań. Klient otrzymuje transkrypcje audio-wideo wywiadów oraz zwięzłe podsumowania.',
  cx_solution_78: '4 - ANALITYKA',
  cx_solution_79:
    'Dział analityczny 4Service agreguje dane, koordynuje strukturę raportu. Następnie przedstawiamy klientowi wyniki, uzupełnione o wnioski i rekomendacje do wdrożenia.',
  cx_solution_80: '5 - WARSZTAT',
  cx_solution_81:
    'Przeprowadzenie dyskusji z klientem dotyczącej planu wdrożenia',
  cx_solution_82: 'Dowiedz się więcej o naszych najlepszych praktykach',
  cx_solution_83:
    'Mapa Podróży Klienta umożliwia szybkie zbadanie wszystkich kanałów komunikacji, identyfikację potencjalnych niedociągnięć w kluczowych punktach kontaktu oraz kompleksowe zrozumienie obszarów, które mogą odbiegać od zaplanowanego kursu.',
  cx_solution_84: 'Kiedy to wykorzystać?',
  cx_solution_85:
    'Zbuduj spersonalizowaną kampanię reklamową opartą na rzeczywistych danych o grupach odbiorców',
  cx_solution_86:
    'Dowiedz się, jakie czynniki motywują różne segmenty docelowej grupy i zaspokój ich potrzeby',
  cx_solution_87:
    'Precyzyjnie ukierunkuj każdą grupę i osiągnij maksymalną konwersję',
  cx_solution_88:
    'Technika Sprawdzania Towarzyszącego pozwala ocenić możliwości optymalnych sposobów osiągnięcia Twoich celów.',
  cx_solution_89: 'Testowanie nowej witryny lub produktu przed uruchomieniem',
  cx_solution_90: 'Sprawdzanie opracowanych procesów przed wprowadzeniem zmian',
  cx_solution_91: 'Ocena witryny lub produktu w obliczu spadku sprzedaży',
  cx_solution_92:
    'Wprowadzenie nowego produktu dostosowanego do preferencji klientów',
  cx_solution_93:
    'Badanie konkurencyjnych firm w celu zabezpieczenia wiodącej pozycji na rynku',
  cx_solution_94:
    'Oferujemy kompleksowe podejście 360° do tworzenia modelu usług zorientowanego na klienta.',
  cx_solution_95: 'Szkolenie i rozwój personelu',
  cx_solution_96: 'Podnoszenie standardów usług',
  cx_solution_97: 'Zwiększenie przepływu klientów i sprzedaży',
  cx_solution_98: 'Ustalanie KPI i systemu motywacyjnego dla personelu',
  cx_solution_99: 'Poznaj nasze historie sukcesu',
  cx_solution_100: 'Motoryzacja',
  cx_solution_101: 'Tytoń',
  cx_solution_102: 'Handel detaliczny',
  cx_solution_103: 'Faza jakościowa',
  cx_solution_104: 'Faza ilościowa (segmentacja)',
  cx_solution_105: 'Grupy fokusowe',
  cx_solution_106: 'Zasięg globalnej publiczności online',
  cx_solution_107: 'Mix technik podczas wywiadów',
  cx_solution_108: 'Tylko pytania otwarte',
  cx_solution_109: 'Wsparcie dla respondentów 24/7',
  cx_solution_110: 'Zachowanie ważnych szczegółów i realnego obrazu ścieżki',
  cx_solution_111: 'Badania rynkowe',
  cx_solution_112: 'Diagnostyka firmy i konkurencji',
  cx_solution_113: 'Zaktualizowane modele usług i nowe procesy biznesowe',
  cx_solution_114: 'Promowanie usług zorientowanych na klienta',
  cx_solution_115: 'Wdrażanie zmian i kontrola nad rozwojem strategii usług',
  usage_attitude_1: 'Usage and \n Attitude (U&A)',
  usage_attitude_2:
    'Zdobądź kompleksowe informacje na temat tego, jak docelowa grupa odbiorców \n postrzega, wykorzystuje i angażuje się w Twoją markę.',
  usage_attitude_3: 'Porozmawiaj z ekspertem',
  usage_attitude_4: 'O nas',
  usage_attitude_5:
    '<b>Poznaj nawyki konsumentów</b>, aby dogłębnie zrozumieć ich preferencje i czynniki wpływające na ich decyzje',
  usage_attitude_6:
    "<b>Odkryj 'dlaczego'</b> stojące za wyborami konsumentów dzięki naszej szczegółowej analizie",
  usage_attitude_7:
    '<b>Zrozum, jak</b> klienci wchodzą w interakcję z Twoimi produktami dla strategicznych informacji',
  usage_attitude_8:
    '<b>Uzyskaj informacje</b> o zachowaniach, nawykach i preferencjach konsumentów dla świadomych decyzji',
  usage_attitude_9: 'Doświadczenie',
  usage_attitude_10:
    'Eksperci 4Service z ponad 20-letnim doświadczeniem w obsłudze klienta tworzą unikalne rozwiązania, które pomagają rozwijać Twój biznes',
  usage_attitude_11: 'Skontaktuj się z nami',
  usage_attitude_12: 'Dla kogo',
  usage_attitude_13: 'Kto korzysta z badania Brand Usage and Attitude?',
  usage_attitude_14: 'Różne branże i sektory usługowe są zainteresowane:',
  usage_attitude_15: '<b>Rozpoznaniem</b> potrzeb klientów i ich trudności',
  usage_attitude_16:
    '<b>Identyfikowaniem</b> możliwości zwiększenia konsumpcji przez klientów',
  usage_attitude_17:
    '<b>Rozwojem</b> nowych kategorii produktów lub strategii marketingowych',
  usage_attitude_18:
    '<b>Poznaniem</b> dogłębnego zrozumienia konkurencyjnego krajobrazu',
  usage_attitude_19:
    'Metodologia jest indywidualnie dobierana na podstawie wymagań klienta',
  usage_attitude_20:
    '<b>Badania jakościowe</b> obejmują zbieranie i analizowanie danych nienumerycznych w celu zrozumienia koncepcji, opinii lub doświadczeń.',
  usage_attitude_21:
    '<b>Badania ilościowe</b> obejmują obiektywne mierzenie i analizowanie danych liczbowych uzyskanych poprzez ankiety, kwestionariusze i sondaże.',
  usage_attitude_22:
    '<b>Po zebraniu danych</b> eksperci analizują informacje i formułują wnioski, aby dostarczyć najlepsze rekomendacje.',
  usage_attitude_23: 'Kto potrzebuje',
  usage_attitude_24: 'Twoja historia sukcesu zaczyna się tutaj',
  usage_attitude_25:
    '<b>Otrzymasz badanie</b> zawierające cenne informacje wspierające strategiczne podejmowanie decyzji, zapewniając działania oparte na głębokim zrozumieniu zachowań konsumentów.',
  usage_attitude_26:
    '<b>Badanie stanowi solidną podstawę do udoskonalania</b> i optymalizacji strategii marki, dostosowując je do preferencji i postaw konsumentów.',
  usage_attitude_27: 'Rozwiązania',
  usage_attitude_28: 'Poznaj liczne możliwości dzięki naszym rozwiązaniom',
  usage_attitude_29: 'Preferencje produktowe w danej kategorii',
  usage_attitude_30:
    'Zidentyfikuj najważniejsze preferencje konsumentów w różnych kategoriach, takich jak jedzenie, napoje, odzież, elektronika i wiele innych.',
  usage_attitude_31: 'Zachowanie konsumpcyjne / użytkowe',
  usage_attitude_32:
    'Analizuj częstotliwość konsumpcji lub użytkowania określonych produktów/usług i identyfikuj okazje i momenty związane z ich wykorzystaniem.',
  usage_attitude_33: 'Zachowanie zakupowe',
  usage_attitude_34:
    'Zbadaj kanały, z których klienci korzystają podczas zakupów, oraz źródła informacji, na których polegają.',
  usage_attitude_35: 'Motywacje i potrzeby konsumentów',
  usage_attitude_36:
    'Zbadaj motywacje i bariery wpływające na konsumpcję. Oceń czynniki wpływające na decyzje zakupowe konsumentów. Przeanalizuj postrzeganie kategorii produktów oraz jak postrzegana jest cena.',
  usage_attitude_37: 'Nasze podejście',
  usage_attitude_38: 'Badanie dotyczące użytkowania marki i postaw',
  usage_attitude_39: 'Informacja',
  usage_attitude_40:
    'Przeprowadzanie badań metodami ilościowymi i jakościowymi',
  usage_attitude_41: 'Rozpoczęcie',
  usage_attitude_42:
    'Ustalanie celów i dobieranie metod na podstawie indywidualnych potrzeb',
  usage_attitude_43: 'Analiza',
  usage_attitude_44: 'Zaawansowane techniki stosowane przez naszych ekspertów',
  usage_attitude_45:
    'Wykorzystujemy unikalny zestaw narzędzi dostosowany do Twoich specyficznych potrzeb',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Computer-Assisted Telephone Interviewing to metoda, którą stosujemy do przeprowadzania ankiet i zbierania cennych danych przez telefon, korzystając ze specjalistycznego oprogramowania w celu usprawnienia procesu i zapewnienia dokładności w naszych działaniach badawczych.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Computer-Assisted Web Interviewing to istotne narzędzie w naszym arsenale, pozwalające przeprowadzać ankiety i zbierać dane za pośrednictwem platform internetowych z pomocą specjalistycznego oprogramowania, zapewniając wydajne i precyzyjne gromadzenie danych dla potrzeb badań rynkowych naszych klientów.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Computer-Assisted Personal Interviewing to metoda, którą stosujemy, aby zoptymalizować procesy zbierania danych, uczynić wywiady bardziej płynnymi i zapewnić dokładne wyniki dostosowane do potrzeb Twojego biznesu.',
  usage_attitude_52: 'Grupy fokusowe',
  usage_attitude_53:
    'Grupy fokusowe to dynamiczne sesje badawcze, które prowadzimy, gromadząc zróżnicowaną grupę uczestników w celu dogłębnej dyskusji na temat określonych tematów lub produktów, pozwalając nam gromadzić jakościowe informacje, odkrywać postawy, preferencje i percepcje oraz eksplorować pomysły i koncepcje, co ostatecznie wspiera podejmowanie decyzji i rozwój strategii dla naszych klientów.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Face-to-Face jest integralną częścią naszego podejścia, umożliwiając bezpośrednie interakcje i spersonalizowane zaangażowanie z klientami, co sprzyja budowaniu zaufania, zrozumienia i skutecznej komunikacji niezbędnej do budowania trwałych relacji i napędzania sukcesu biznesowego w grupach fokusowych.',
  usage_attitude_56: 'Badania etnograficzne',
  usage_attitude_57:
    'Badanie ludzi i kultur poprzez immersję i obserwację w ich naturalnym środowisku, często prowadzące do głębokich wglądów w zachowania, przekonania i dynamikę społeczną.',
  thank_you_page:
    'Dziękujemy za Twój czas! Nasz menedżer wkrótce się z Tobą skontaktuje.',
  back_main: 'Powrót do głównej',
  opportunities_1: 'Dowiedz się więcej o naszych przypadkach',
  opportunities_2: 'Odkryj',
  opportunities_3: 'dla siebie',
  opportunities_4: 'możliwości',
  opportunities_5:
    'międzynarodowego biznesu razem z holdingiem badawczym 4Service',
  opportunities_6:
    'Jakimi <span>produktami</span> możemy wzmocnić Twój biznes?',
  opportunities_7: 'CX',
  opportunities_8: 'Kompleksowe, dostosowane rozwiązania dla biznesu',
  opportunities_9: 'Wszystkie rodzaje Mystery Shopping',
  opportunities_10:
    'Realizujemy mystery employee, mystery caller, luxury mystery shopper, automotive mystery shopper i inne typy',
  opportunities_11: 'Dostosowane rozwiązania IT dla biznesu',
  opportunities_12:
    'Własne rozwiązania IT z AI, platformy do analizy opinii i transkrypcji rozmów',
  opportunities_13: 'Badania rynku',
  opportunities_14:
    'Dla wszelkich zapytań biznesowych (Brand Health Tracking, Segmentacja, NPS, eNPS, CJM, Testy produktów i reklam itp.)',
  opportunities_15: 'Audyt',
  opportunities_16:
    'Przeprowadzamy różne audyty usług, a także monitoring cen (offline, dla określonych nisz itp.)',
  opportunities_17: 'Service Design',
  opportunities_18:
    '4Service - certyfikowani partnerzy DesignThinkers Academy, prowadzący szkolenia korporacyjne dla zespołów oraz regularnie organizujący warsztaty dla firm',
  opportunities_19:
    'Dzielimy się naszym doświadczeniem, ale <span>szanujemy i nie zakłócamy</span> Twojego procesu biznesowego.',
  opportunities_20:
    'Znamy trudności, z jakimi borykają się firmy na ścieżce rozwoju i wiemy, jak pokonać te bariery dzięki międzynarodowemu doświadczeniu sukcesu. Otrzymujesz nie tylko doświadczenie, ale także zasoby.',
  opportunities_21: 'Zapraszamy do współpracy!',
  opportunities_22:
    'Oferujemy <span>program partnerski</span> dla Twojego biznesu',
  opportunities_23:
    'Pod międzynarodowo rozpoznawalną marką 4Service możesz pozytywnie wpłynąć na pozyskanie nowych klientów.',
  opportunities_24:
    'Nasi partnerzy otrzymują kompleksowe wsparcie, w tym szkolenia, materiały marketingowe i pomoc operacyjną.',
  opportunities_25:
    'Współpraca z nami pozwoli Ci zrealizować plany bez zakłócania bieżących procesów biznesowych.',
  opportunities_26: 'Nasze <span>biura</span>',
  opportunities_27: 'Nasi klienci',
  opportunities_28: 'Posiadamy ponad 20 lat doświadczenia w CX',
  opportunities_29: 'Kraje objęte działalnością',
  opportunities_30: 'tysiące',
  opportunities_31: 'Unikalne projekty',
  opportunities_32: 'miliony',
  opportunities_33: 'Wizyty mystery shopperów',
  opportunities_34: 'jest w TOP 10 europejskich agencji CX',
  opportunities_35: 'Twoje możliwości z 4Service',
  opportunities_36: 'Wzrost zysku',
  opportunities_37: 'Uzyskasz dostęp do międzynarodowych zamówień',
  opportunities_38: 'Unikalne podejście CX',
  opportunities_39: 'Wsparcie ekspertów z 20-letnim doświadczeniem',
  opportunities_40: 'Wsparcie',
  opportunities_41: 'Nasz zespół wesprze Cię w osiąganiu wyników',
  opportunities_42: 'Sprzedaż',
  opportunities_43: 'Pomożemy Ci skonfigurować system sprzedaży',
  opportunities_44: 'Dostęp do oprogramowania',
  opportunities_45: 'Uzyskasz dostęp do wszystkich certyfikowanych narzędzi IT',
  opportunities_46: 'Szkolenia',
  opportunities_47:
    'Nie tylko przeszkolimy Twój personel, ale również będziemy świadczyć usługi doradcze',
  opportunities_48: 'Kto nadaje się do partnerstwa?',
  opportunities_49: 'Czy Twój biznes jest związany z badaniami marketingowymi?',
  opportunities_50: 'A może planujesz spróbować sił w nowej branży?',
  opportunities_51: 'Czy planujesz:',
  opportunities_52: 'Wdrażać innowacje',
  opportunities_53: 'Współpracować z międzynarodowymi markami',
  opportunities_54: 'Poszerzyć swoje portfolio produktów',
  opportunities_55: 'Wzmocnić swoją pozycję na rynku?',
  opportunities_56: 'Szkolenie pracowników',
  opportunities_57: 'Skalować swój biznes',
  opportunities_58: 'Nasze zasoby = Twoje zasoby',
  opportunities_59:
    'Biorąc pod uwagę nasze bogate doświadczenie, jesteśmy gotowi podzielić się nim już na pierwszym etapie współpracy',
  opportunities_60: 'Analiza danych:',
  opportunities_61: 'Międzynarodowy zespół analityczny',
  opportunities_62: 'Metody przetwarzania danych - SPSS, Power BI',
  opportunities_63: 'Innowacyjne metodologie i szeroki zakres metod',
  opportunities_64: 'Raportowanie w dowolnym formacie',
  opportunities_65: 'Oprogramowanie:',
  opportunities_66: 'Własne dostosowane rozwiązania IT',
  opportunities_67: 'DigSee Sure dla CAPI',
  opportunities_68: 'SurveyMonkey dla CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (call center)',
  opportunities_71: 'Wsparcie marketingowe:',
  opportunities_72: 'Wsparcie PR',
  opportunities_73: 'Wsparcie w promocji i tworzeniu treści',
  opportunities_74: 'Konfiguracja procesów generowania leadów',
  opportunities_75: 'Wsparcie IT:',
  opportunities_76:
    'Wsparcie techniczne przy instalacji, konfiguracji i użytkowaniu naszego oprogramowania.',
  opportunities_77: 'Zdalna pomoc',
  opportunities_78: 'Kontrola bezpieczeństwa',
  opportunities_79: 'Wsparcie operacyjne:',
  opportunities_80: 'Wsparcie przy konfiguracji procesów terenowych',
  opportunities_81: 'System antyfraudowy',
  opportunities_82: 'Rekrutacja o dowolnym stopniu złożoności',
  opportunities_83:
    'Zarządzanie operacyjne projektem i zespołem działów terenowych',
  opportunities_84: 'Kontrola jakości rekrutacji',
  opportunities_85: 'UpWork',
  opportunities_86: 'Jakie bonusy otrzymasz?',
  opportunities_87: 'Zwiększenie bazy klientów',
  opportunities_88: 'Wzmocnienie wizerunku na rynku',
  opportunities_89: 'Dywersyfikacja ryzyka utraty klientów',
  opportunities_90: 'Optymalizacja procesów technologicznych',
  opportunities_91: 'Międzynarodowe doświadczenie',
  opportunities_92: 'Więcej sprzedaży dla obecnych klientów',
  main_67:
    'Analiza cen konkurencji optymalizuje oferty, zwiększa zyski i wzmacnia udział w rynku, wykorzystując dane dotyczące gotowości konsumentów do zapłaty w celu zidentyfikowania kluczowych cech',
  main_68:
    'Zapewnia zgodność Twoich punktów sprzedaży z standardami marki, oceniając atrybuty fizyczne i ich wpływ na postrzeganie klienta',
  main_69: 'Rozwiązanie CX',
  main_70:
    'Odkryj nasze unikalne 360-stopniowe rozwiązanie CX: dostosowujemy narzędzia i usługi do Twoich celów, aby napędzać Twój biznes',
  main_71: 'Analiza mowy',
  main_72:
    'Nasze zaawansowane narzędzie do analizy mowy przekształca mowę w tekst, dostarczając nieocenionych informacji, które pomagają firmom poprawiać doświadczenia klientów, monitorować wydajność, zapewniać zgodność i wspierać rozwój',
  parthership: 'Partnerstwo',
  banner_1: 'Konsulting i usługi CX z 360° spojrzeniem na Twój biznes',
  banner_2: 'Bądź na bieżąco z najnowszymi badaniami!',
  banner_3:
    'Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z Polityką Prywatności',
  banner_4: '*Wybierz rynek',
  banner_5: 'Dziękujemy za subskrypcję!',
  banner_6: 'Podzielimy się tylko najciekawszą treścią!',
  banner_7: 'Prześlij',
  banner_8: 'Ukraiński',
  banner_9: 'Kazachstan',
  brand_awareness_1: 'Rozpoznawalność marki',
  brand_awareness_2: 'i użytkowanie',
  brand_awareness_3:
    'Wykorzystaj potencjał swojej marki dla sukcesu biznesowego',
  brand_awareness_4: 'Skontaktuj się z ekspertem',
  brand_awareness_5: 'O nas',
  brand_awareness_6:
    '<b>Oceń stopień</b> rozpoznawalności i znajomości swojej marki',
  brand_awareness_7:
    '<b>Określ poziom</b> rozpoznawalności marki na docelowym rynku',
  brand_awareness_8: '<b>Bądź na bieżąco</b> z poziomem retencji i lojalności',
  brand_awareness_9:
    '<b>Wybierz optymalną strategię</b> poprawy kluczowych wskaźników',
  brand_awareness_10: 'Odkryj liczne możliwości dzięki naszym rozwiązaniom',
  brand_awareness_11: 'Rozpoznawalność marki',
  brand_awareness_12: 'Użytkowanie marki',
  brand_awareness_13:
    '<b>Odkryj</b> rozpoznawalność swojej marki wśród segmentów konsumentów',
  brand_awareness_14:
    '<b>Monitoruj</b> poziom świadomości wśród swojej publiczności',
  brand_awareness_15:
    '<b>Badaj</b> spontaniczne myśli konsumentów przed zakupem',
  brand_awareness_16:
    '<b>Zrozum</b> główne źródła, które przyczyniają się do rozpoznawalności Twojej marki',
  brand_awareness_17:
    '<b>Rozważ</b> liczbę konsumentów, którzy mieli doświadczenie z produktami lub usługami Twojej marki',
  brand_awareness_18:
    '<b>Identyfikuj</b> konsumentów, którzy pozostali przy Twojej marce po pierwszym spróbowaniu',
  brand_awareness_19: '<b>Odkryj</b> prawdopodobieństwo polecenia Twojej marki',
  brand_awareness_20:
    '<b>Odkryj</b> procent konsumentów, którzy rozważają Twoją markę do przyszłego użytkowania',
  brand_awareness_21: 'Kto skorzysta na rozpoznawalności i użytkowaniu marki?',
  brand_awareness_22: 'Firmy wprowadzające nowy produkt',
  brand_awareness_23: 'Firmy odświeżające już istniejącą markę',
  brand_awareness_24:
    'Każda firma, która chce zrozumieć i zwiększyć sposób, w jaki jej marka jest rozpoznawana i użytkowana przez konsumentów',
  brand_awareness_25:
    'Nasze metodologie dostarczają wartościowych informacji, które pozwalają zoptymalizować rozpoznawalność i użytkowanie marki',
  brand_awareness_26:
    'Metodologia jest indywidualnie dobierana w zależności od zapytań klienta',
  brand_awareness_27: 'Metodologia lejka użytkowania marki',
  brand_awareness_28:
    'Wskaźnik prób opisuje odsetek odbiorców świadomych marki, którzy spróbowali jej produktów lub usług.',
  brand_awareness_29:
    'Wskaźnik powtórzeń wskazuje odsetek odbiorców, którzy po pierwszym kontakcie z marką kontynuowali zakupy lub korzystanie z jej oferty w ciągu ostatnich 12 miesięcy.',
  brand_awareness_30:
    'Wskaźnik retencji pokazuje odsetek osób, które stale korzystały z produktów lub usług marki, wskazując na zaangażowanie w ciągu ostatnich 6 miesięcy.',
  brand_awareness_31:
    'Wskaźnik retencji ilustruje procent klientów, którzy korzystali z produktów/usług marki w ciągu ostatnich 6 miesięcy i nadal wykazują lojalność wobec marki.',
  brand_awareness_32: 'Metodologia świadomości marki',
  brand_awareness_33:
    'Top of mind (TOM) reprezentuje pierwszą markę spontanicznie wymienioną przez respondenta.',
  brand_awareness_34:
    'Całkowita spontaniczna świadomość obejmuje wszystkie spontaniczne wymienienia, w tym TOM.',
  brand_awareness_35:
    'Aided Awareness pokazuje, jak dobrze Twoja marka jest rozpoznawana przez użytkowników po podpowiedzi.',
  brand_awareness_36: 'Mapa atrybutów marki',
  brand_awareness_37:
    'To narzędzie ocenia i wizualnie przedstawia znaczenie różnych atrybutów marki, dostarczając informacji o tym, jak każdy z nich wpływa na ogólną wydajność marki i postrzeganie konsumentów.',
  brand_awareness_38:
    'Odkryj klucze do zwiększenia wpływu swojej marki dzięki naszej kompleksowej analizie.',
  brand_awareness_39: 'Mapa percepcji marek',
  brand_awareness_40:
    'To narzędzie oferuje wizualne przedstawienie postrzegań konsumentów na konkurencyjnym rynku. To podejście jest kluczowe dla wyraźnego rozróżnienia marek, podkreślając kluczowe atrybuty rynkowe.',
  brand_awareness_41:
    'Ułatwia obserwację i interpretację relacji między markami a tymi określającymi cechami.',
  brand_awareness_42:
    'Bliskość danej marki do określonych atrybutów wskazuje na jej silne powiązanie z tymi cechami. Podobnie, bliskość między dwiema markami wskazuje na ich podobieństwa, pokazując wspólne obrazy i tym samym afiliację w tym samym segmencie rynku.',
  consumer_journey_map_1: 'Mapa podróży konsumenta',
  consumer_journey_map_2:
    'Odkryj i pokonaj bariery w swoim biznesie za pomocą naszej Mapy Podróży Konsumenta',
  consumer_journey_map_3: 'ZAUFALI NAM',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Otwórz potencjał CJM, aby zoptymalizować interakcje między Twoimi konsumentami a Twoją marką',
  consumer_journey_map_6:
    'Określ, jakie kroki podejmuje konsument przed zakupem produktu',
  consumer_journey_map_7:
    'Zyskaj wgląd w opinie prawdziwych klientów, a nie wymyślonych',
  consumer_journey_map_8:
    'Zdobądź kompleksowe zrozumienie doświadczeń swoich klientów',
  consumer_journey_map_9:
    'Zidentyfikuj niewykorzystane możliwości i porównaj z konkurencją',
  consumer_journey_map_10: 'Odkryj nasze podejście do CJM',
  consumer_journey_map_11:
    'Tworzymy ścieżkę interakcji konsumenta z produktem, usługą, firmą lub marką przez różne kanały w określonym czasie.',
  consumer_journey_map_12:
    'Określamy etapy, które pokonują Twoi konsumenci przed dokonaniem zakupu, ich oczekiwania, emocje i motywacje na każdym etapie.',
  consumer_journey_map_13: 'Zyskaj korzyści z nami',
  consumer_journey_map_14:
    'Dostarczamy zespół doświadczonych profesjonalistów, którzy rozumieją zawiłości globalnego sukcesu biznesowego',
  consumer_journey_map_15:
    'Jesteśmy zaangażowani w dostarczanie wyników, które przewyższają Twoje oczekiwania',
  consumer_journey_map_16:
    'Odkrywamy złożone rozwiązania, które przyczyniają się do Twojego wzrostu',
  consumer_journey_map_17:
    'Specjalizujemy się w dostarczaniu dostosowanych rozwiązań CJM',
  consumer_journey_map_18: 'Zarezerwuj konsultację',
  consumer_journey_map_19: 'Decyzje oparte na danych',
  consumer_journey_map_20:
    'Podejmuj decyzje na podstawie rzeczywistych danych, a nie założeń',
  consumer_journey_map_21: 'Optymalizuj swój CJM',
  consumer_journey_map_22:
    'Buduj silniejsze relacje z klientami, uwzględniając ich potrzeby i oczekiwania na każdym etapie ich podróży',
  consumer_journey_map_23: 'Prowadź drogę',
  consumer_journey_map_24: 'Porównaj się do konkurencji i wyprzedź rynek',
  consumer_journey_map_25: 'Rekomendacje',
  consumer_journey_map_26:
    'Wdrażaj nasze rekomendacje CJM, aby odblokować sukces, wspierać lojalność klientów, zwiększać sprzedaż i maksymalizować potencjał Twojego biznesu',
  consumer_journey_map_27:
    'Odkryj prawdziwą podróż konsumenta z naszą Mapą Podróży Konsumenta',
  consumer_journey_map_28:
    'Eksperci z ponad 20-letnim doświadczeniem zapewniają kompleksowe badania',
  consumer_journey_map_29:
    'Badania jakościowe polegają na zbieraniu i analizowaniu danych nienumerycznych w celu uchwycenia koncepcji, opinii lub doświadczeń.',
  consumer_journey_map_30:
    'Metody badawcze ilościowe polegają na obiektywnym pomiarze i analizie numerycznej danych uzyskanych za pomocą ankiet, kwestionariuszy i sondaży.',
  consumer_journey_map_31:
    'Po zebraniu danych eksperci analizują informacje i wyciągają wnioski, aby dostarczyć najlepsze rekomendacje.',
  boarding_1: 'Mapa podróży klienta',
  boarding_2:
    'Mapa Podróży Klienta (CJM) polega na zdefiniowaniu grupy docelowej, zrozumieniu ich potrzeb i środowiska, a następnie stworzeniu planu, który zapewni angażujące doświadczenie',
  boarding_3: 'Wskaźnik promocji netto',
  boarding_4:
    'Wskaźnik Promotora Netto (NPS) to złoty standard metryk doświadczenia klienta, oparty na jednym pytaniu: Jak prawdopodobne jest, że poleciłbyś organizację/produkt/usługę?',
  boarding_5: 'Monitorowanie zdrowia marki',
  boarding_6:
    'Badania Monitorowania Zdrowia Marki (BHT) mogą mierzyć, jak Twoja marka radzi sobie w zakresie rozpoznawalności i użytkowania, pozycjonowania marki i wydajności marki',
  boarding_7: 'Badania zasobów ludzkich',
  boarding_8:
    'Badania Zasobów Ludzkich służą do oceny praktyk HR i wydajności oraz oferują szczegółową analizę obecnego rozwoju i zarządzania',
  boarding_9: 'Testowanie UX',
  boarding_10:
    'Testowanie użyteczności (UX) polega na badaniu, jak prawdziwi ludzie wchodzą w interakcje z witryną, aplikacją lub innym produktem i obserwowaniu ich zachowań i reakcji',
  boarding_11: 'Indeks satysfakcji klienta',
  boarding_12:
    'Satysfakcja klienta mierzy stopień, w jakim oczekiwania klienta zostały spełnione w związku z zakupem produktu lub usługi dostarczonej przez Twoją firmę',
  boarding_13: 'Testowanie reklam',
  boarding_14:
    'Kampania reklamowa to ogromna inwestycja. Upewnij się, że Twoje reklamy są skuteczne i przynoszą maksymalny zwrot z inwestycji',
  boarding_15: 'Audyt terenowy/Audyt detaliczny',
  boarding_16:
    'Zbieraj szczegółowe dane o swoim sklepie lub marce, analizuj bariery wzrostu i twórz skuteczne strategie ich pokonania',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Oprogramowanie do zarządzania wydajnością. Aplikacja z elementami grywalizacji do zwiększania sprzedaży',
  boarding_19: 'Voicer',
  boarding_20:
    'System zarządzania opiniami. Głos Twojego klienta w jednym systemie. System Zarządzania Opiniami Klientów',
  boarding_21: 'Badania jakościowe',
  boarding_22:
    'Badania jakościowe polegają na zbieraniu i analizowaniu danych nienumerycznych, aby zrozumieć koncepcje, opinie lub doświadczenia',
  boarding_23: 'Badania ilościowe',
  boarding_24:
    'Metody badawcze ilościowe to obiektywne mierzenie i analiza numeryczna danych uzyskanych za pomocą ankiet, kwestionariuszy i sondaży',
  boarding_25: 'Tajemniczy klient',
  boarding_26:
    'Tajemniczy klient to technika badawcza służąca do mierzenia i monitorowania interakcji między klientem a firmą lub organizacją w określonym scenariuszu',
  boarding_27: 'Wantent',
  boarding_28:
    'Platforma zasilana sztuczną inteligencją, zapewniająca informacje od publiczności',
  boarding_29: 'Monitorowanie cen',
  boarding_30:
    'Monitorowanie cen to proces zbierania, przetwarzania i analizy cen konkurencji',
  boarding_31: 'Projektowanie usług',
  boarding_32:
    'Projektowanie usług polega na zrozumieniu potrzeb i pragnień osób, które będą korzystać z produktu lub usługi, poprzez spędzenie z nimi czasu',
  boarding_33: 'Analiza cen w przemyśle motoryzacyjnym',
  boarding_34:
    'Analiza konkurencyjnych cen optymalizuje ofertę, zwiększa zyski i poprawia udział w rynku, wykorzystując dane o gotowości konsumentów do płacenia w celu zidentyfikowania kluczowych cech',
  boarding_35: 'Audyt kontroli obiektów',
  boarding_36:
    'Zapewnia, że Twoje punkty sprzedaży detalicznej są zgodne z normami marki, oceniając cechy fizyczne i ich wpływ na postrzeganie przez klientów',
  boarding_37: 'Rozwiązanie CX',
  boarding_38:
    'Odkryj nasze unikalne rozwiązanie CX 360 stopni: dostosowujemy narzędzia i usługi w zależności od Twoich celów, aby napędzać Twój biznes',
  boarding_39: 'Analiza mowy',
  boarding_40:
    'Nasze zaawansowane narzędzie analizy mowy transkrybuje mowę na tekst, dostarczając cennych informacji dla firm, aby poprawić doświadczenia klientów, monitorować wydajność, zapewnić zgodność i napędzać wzrost',
  boarding_41: 'Użytkowanie i postawy',
  boarding_42:
    'Użytkowanie i postawy to nasze podejście do zrozumienia, jak odbiorcy angażują się w Twoją markę, analizując praktyczne wzorce użytkowania i postawy psychologiczne, umożliwiające tworzenie dopasowanych strategii marketingowych i produktowych',
  boarding_43: 'Świadomość marki i użytkowanie',
  boarding_44:
    'Świadomość marki i użytkowanie polegają na ocenie rozpoznawalności, znajomości, częstotliwości interakcji i retencji wśród docelowego rynku w celu zwiększenia widoczności marki i zaangażowania',
  boarding_45: 'Tożsamość i pozycjonowanie marki',
  boarding_46:
    'Tożsamość marki i pozycjonowanie to kompleksowa analiza odkrywająca postrzeganie konsumentów, różnorodne opinie odbiorców i wgląd w to, jak Twoja marka jest postrzegana',
  boarding_47: 'Profilowanie i segmentacja klientów',
  boarding_48:
    'Profilowanie i segmentacja klientów polega na podziale docelowego rynku na odrębne grupy na podstawie wspólnych cech, umożliwiając tworzenie spersonalizowanych strategii, które trafiają do określonych segmentów odbiorców',
  boarding_49: 'Mapa podróży konsumenta',
  boarding_50:
    'Mapa podróży konsumenta to wizualne przedstawienie etapów, punktów styku, emocji i motywacji, które konsument przeżywa podczas interakcji z Twoim produktem lub marką',
  boarding_51:
    'Poznaj innowacyjne techniki, które wspierają nasze zaangażowanie w dostarczanie działań opartych na danych i osiąganie znaczących wyników dla Twojego biznesu',
  boarding_52:
    'Zbadaj nasz zestaw produktów z bogatą inteligencją opartą na danych, dostosowaną do Twojej branży, umożliwiając podejmowanie świadomych decyzji i wyprzedzanie konkurencji',
  boarding_53:
    'Zgłębiaj trendy rynkowe, zachowania konsumentów i konkurencyjne krajobrazy z naszymi kompleksowymi rozwiązaniami badania rynku',
  boarding_54: 'Badania ad hoc',
  boarding_55:
    'Badania ad hoc to spersonalizowane podejście, zaprojektowane w celu rozwiązania konkretnego problemu, wykorzystujące kombinację istniejących metodologii lub nowego podejścia do stworzenia dostosowanego rozwiązania.',
  boarding_56: 'Badania socjologiczne',
  boarding_57:
    'Badania socjologiczne odkrywają wzorce ludzkiego zachowania i interakcji społecznych, dostarczając cennych informacji, które napędzają innowacyjne strategie i tworzą znaczące połączenia z docelowymi odbiorcami.',
  not_found_1: 'Ups! Nie możemy znaleźć tej strony.',
  not_found_2: 'Dlaczego by nie sprawdzić naszych produktów?',
  not_found_3: 'Zarezerwuj konsultację',
  customer_profiling_segmentation_1: 'Profilowanie i segmentacja klientów',
  customer_profiling_segmentation_2:
    'Pomożemy Ci określić Twoją docelową grupę odbiorców, co sprawi, że Twoja strategia będzie bardziej skuteczna',
  customer_profiling_segmentation_3:
    'Wykorzystaj moc segmentacji rynku, aby upewnić się, że Twoje komunikaty skutecznie docierają do grup konsumentów',
  customer_profiling_segmentation_4: 'Zrozum swoich konsumentów głębiej',
  customer_profiling_segmentation_5:
    'Znajdź najlepszą grupę docelową dla swojego produktu',
  customer_profiling_segmentation_6:
    'Odkryj nowe segmenty, które warto przyciągnąć',
  customer_profiling_segmentation_7: 'Odkryj bariery zakupowe',
  customer_profiling_segmentation_8:
    'Specjalizujemy się w dostarczaniu spersonalizowanych rozwiązań segmentacyjnych',
  customer_profiling_segmentation_9: 'Unikalne podejście',
  customer_profiling_segmentation_10:
    'Doświadcz unikalnego, dostosowanego rozwiązania segmentacyjnego stworzonego specjalnie pod Twoje potrzeby',
  customer_profiling_segmentation_11: 'Persony docelowe',
  customer_profiling_segmentation_12:
    'Tworzenie Person docelowych na podstawie głębokiej analizy rynku i kompleksowych badań',
  customer_profiling_segmentation_13: 'Nowe segmenty',
  customer_profiling_segmentation_14:
    'Zidentyfikuj najcenniejsze segmenty swojej publiczności i odkryj korzyści płynące z ukierunkowanego targetowania',
  customer_profiling_segmentation_15: 'Rekomendacje',
  customer_profiling_segmentation_16:
    'Wdroż nasze rekomendacje i odblokuj sukces, wspieraj lojalność klientów, zwiększaj sprzedaż i maksymalizuj potencjał swojego biznesu',
  customer_profiling_segmentation_17:
    'Eksperci z ponad 20-letnim doświadczeniem zapewniają kompleksowe badania',
  customer_profiling_segmentation_18:
    'Badania jakościowe polegają na zbieraniu i analizowaniu danych nienumerycznych, aby uchwycić koncepcje, opinie lub doświadczenia.',
  customer_profiling_segmentation_19:
    'Metody badawcze ilościowe to obiektywne mierzenie i analiza numeryczna danych uzyskanych za pomocą ankiet, kwestionariuszy i sondaży.',
  customer_profiling_segmentation_20:
    'Po zebraniu danych eksperci analizują informacje i wyciągają wnioski, aby dostarczyć najlepsze rekomendacje.',
  customer_profiling_segmentation_21:
    'Nie tylko wykonujemy pomiary, ale również dostarczamy plan działania z jasnymi krokami do wykonania',
  customer_profiling_segmentation_22: 'Nasza metodologia',
  customer_profiling_segmentation_23: 'Wskaźnik Jaccarda',
  customer_profiling_segmentation_24:
    'Kiedy respondenci są pytani bezpośrednio, ich odpowiedzi dotyczące preferencji firm mogą nie odpowiadać ich rzeczywistemu zachowaniu. Zamiast bezpośrednich pytań, wykorzystujemy statystyczną analizę Jaccarda do zrozumienia relacji między cechami firmy a wyborem konsumentów.',
  customer_profiling_segmentation_25: 'Czynniki napędzające',
  customer_profiling_segmentation_26:
    'Identyfikujemy czynniki napędzające (czynniki wpływające na określony wynik) i klasyfikujemy je na kluczowe, średnie i mniej istotne w zależności od ich znaczenia.',
  customer_profiling_segmentation_27: 'Segmentacja',
  customer_profiling_segmentation_28:
    'Uzyskujesz segmentację z jasnymi cechami wyborów klientów.',
  customer_profiling_segmentation_29: 'Nasze podejście do segmentacji',
  customer_profiling_segmentation_30: 'Demografia',
  customer_profiling_segmentation_31: 'wiek',
  customer_profiling_segmentation_32: 'płeć',
  customer_profiling_segmentation_33: 'dochód',
  customer_profiling_segmentation_34:
    'Pomaga dostosować produkty, usługi i marketing do określonych grup, poprawiając trafność i skuteczność',
  customer_profiling_segmentation_35: 'Psychograficzne',
  customer_profiling_segmentation_36: 'styl życia',
  customer_profiling_segmentation_37: 'wartości',
  customer_profiling_segmentation_38: 'zachowania',
  customer_profiling_segmentation_39:
    'Umożliwia personalizowany marketing, głębsze połączenia z klientami oraz zwiększoną lojalność i sprzedaż dla firmy',
  customer_profiling_segmentation_40: 'Behawioralne',
  customer_profiling_segmentation_41: 'działania',
  customer_profiling_segmentation_42: 'motywacja',
  customer_profiling_segmentation_43: 'częstotliwość zakupu',
  customer_profiling_segmentation_44:
    'Pozwala na ukierunkowany marketing, zwiększoną satysfakcję klientów, wyższą retencję i poprawioną rentowność dla firmy',
  customer_profiling_segmentation_45: 'Geograficzne',
  customer_profiling_segmentation_46: 'lokalizacja',
  customer_profiling_segmentation_47:
    'Umożliwia ukierunkowany marketing, dostosowane produkty, efektywną dystrybucję i zwiększoną penetrację rynku dla firmy.',
  brands_identity_1: 'Tożsamość i pozycjonowanie marki',
  brands_identity_2:
    'Przekształć swoją markę dzięki naszej dogłębnej analizie, ujawniającej wnioski na temat postrzegania konsumentów i udoskonalającej Twoją strategię sukcesu',
  brands_identity_3: 'Porozmawiaj z ekspertem',
  brands_identity_4: '<b>Odkryj kompleksową</b> analizę tożsamości marki',
  brands_identity_5:
    '<b>Poznaj wnioski</b> zebrane na temat postrzegania konsumentów',
  brands_identity_6: '<b>Zrozum różnorodne</b> poglądy grupy docelowej',
  brands_identity_7:
    '<b>Uzyskaj informacje o</b> zachowaniach, nawykach i preferencjach konsumentów, aby podejmować świadome decyzje',
  brands_identity_8:
    'Eksperci 4Service z ponad 20-letnim doświadczeniem w obsłudze klienta tworzą unikalne rozwiązania, które pomagają rozwijać Twój biznes',
  brands_identity_9:
    '<b>Otrzymasz dostęp do pakietu badawczego wyposażonego we wszystkie niezbędne dane.</b> Jest to narzędzie diagnostyczne, które podkreśla mocne strony oraz obszary wymagające uwagi',
  brands_identity_10:
    '<b>Nasze usługi dostarczają firmom niezbędnych narzędzi</b> do podejmowania świadomych decyzji, udoskonalania strategii i osiągania sukcesu w ich branżach.',
  brands_identity_11:
    'Aby wzmocnić pozycję Twojej marki na konkurencyjnym rynku',
  brands_identity_12: 'Firmy dążące do poprawy swojej obecności na rynku',
  brands_identity_13:
    'Firmy szukające sposobów na podniesienie swojej strategii',
  brands_identity_14: 'Tożsamość marki',
  brands_identity_15: 'Pozycjonowanie marki',
  brands_identity_16:
    '<b>Odkryj postrzeganie Twojej marki</b> wśród segmentów konsumentów.',
  brands_identity_17:
    '<b>Zidentyfikuj cechy przypisywane</b> Twojej marce i konkurentom.',
  brands_identity_18:
    '<b>Oceń mocne</b> i słabe strony w celu poprawy strategii.',
  brands_identity_19:
    '<b>Oceń zgodność</b> wizerunku Twojej marki z promowanymi wartościami.',
  brands_identity_20: '<b>Oceń postrzeganą</b> wiarygodność Twojej marki.',
  brands_identity_21:
    '<b>Zbadaj archetypowe</b> i symboliczne postrzeganie Twojej marki.',
  brands_identity_22:
    '<b>Zidentyfikuj unikalne cechy</b> sprzedaży Twojej marki i różnice w porównaniu do konkurentów.',
  brands_identity_23:
    '<b>Wskaż kluczowe cechy wpływające na</b> prawdopodobieństwo zakupu produktów/usług Twojej marki przez konsumentów.',
  brands_identity_24:
    '<b>Określ, jak Twoja marka jest obecnie postrzegana</b> w umysłach konsumentów i zidentyfikuj aspekty, które należy komunikować, aby przyciągnąć pożądane segmenty rynku.',
  brands_identity_25:
    'Metodologia jest indywidualnie dobierana na podstawie życzenia klienta',
  brands_identity_26: 'Świadomość i użytkowanie marki',
  brands_identity_27:
    'Ocena poziomu marki polega na mierzeniu poziomu świadomości i konsumpcji, stanowiąc podstawowy element oceny.',
  brands_identity_28: 'Dostarczenie marki i zaufanie',
  brands_identity_29:
    'Ta metryka mierzy rekomendację klientów (polecenie) oraz spójną zdolność marki do realizowania obietnic, tworzenia pozytywnych doświadczeń klientów i budowania zaufania.',
  brands_identity_30: 'Wydajność wizerunku marki',
  brands_identity_31:
    'Ocena wydajności marki polega na ocenie jej związku z sześcioma kluczowymi wartościami (3 racjonalnymi i 3 emocjonalnymi), jej wyróżniającością w kategorii oraz zdolnością do wzbudzania dumy wśród konsumentów.',
  brands_identity_32: 'Wskaźnik zdrowia marki',
  brands_identity_33:
    'Wskaźnik zdrowia marki daje Ci kompleksowe zrozumienie, jak Twoja marka radzi sobie w konkurencyjnym krajobrazie.',
  sociological_research_1: 'Badania socjologiczne',
  sociological_research_2:
    'Przeprowadzamy dogłębną analizę zjawisk społecznych i procesów, co pomaga podejmować świadome decyzje dzięki unikalnym wnioskom analitycznym i rekomendacjom',
  sociological_research_3: 'Skontaktuj się z nami',
  sociological_research_4: 'Nasi klienci',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - to metoda zbierania danych, która wykorzystuje komputer do przeprowadzania ankiet telefonicznych. Oprogramowanie CATI pozwala na automatyzację procesów zbierania danych, wprowadzania odpowiedzi i zarządzania ankietami. Korzystanie z CATI ułatwia kontrolowanie ankiety, umożliwia szybkie analizowanie i przetwarzanie danych oraz zapewnia wysoki poziom standaryzacji ankiety.',
  sociological_research_7: 'CATI',
  sociological_research_8:
    'CAWI - to metoda zbierania danych, w której respondenci wypełniają ankiety lub odpowiadają na pytania za pomocą interfejsu internetowego, a dane są automatycznie zbierane i przetwarzane za pomocą specjalistycznego oprogramowania. Metoda CAWI pozwala przeprowadzać badania na dużą skalę, szybko zbierać i analizować dane.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - to metoda zbierania danych do badań bezpośrednich, w której badacz używa tabletu lub telefonu komórkowego do wprowadzania odpowiedzi respondentów na pytania, co pozwala na automatyczne zbieranie danych, zmniejszenie błędów wprowadzania i uproszczenie analizy wyników.',
  sociological_research_11: 'Nasi eksperci korzystają z różnych narzędzi',
  sociological_research_12: 'Próba',
  sociological_research_13: 'Obliczamy i stosujemy próby o dowolnej złożoności',
  sociological_research_14:
    'W naszej pracy stosujemy próby proste, systematyczne, warstwowe i klastrowe',
  sociological_research_15:
    'Do badań twarzą w twarz, reprezentujących całe państwo, stosujemy procedury doboru próby wielostopniowej warstwowej',
  sociological_research_16:
    'Dobór osiedli według regionów jest dokonywany zgodnie z wielkością i typem osiedla, a ich liczba jest określana proporcjonalnie do ich objętości w każdym regionie',
  sociological_research_17: 'Przeprowadzamy',
  sociological_research_18: 'Badania opinii publicznej',
  sociological_research_19: 'Badania społeczno-ekonomiczne',
  sociological_research_20: 'Badania deskowe',
  sociological_research_21: 'Ocena jakości usług społecznych',
  sociological_research_22: 'Studium przypadku',
  sociological_research_23: 'Badania personelu',
  sociological_research_24:
    'Badania specyficznych grup odbiorców (grupy wrażliwe, w tym IDP, wojsko, młodzież itp.)',
  sociological_research_25: 'Pracujemy z każdym klientem indywidualnie',
  sociological_research_26: 'Nasi klienci:',
  sociological_research_27: 'Fundacje charytatywne',
  sociological_research_28: 'Organizacje publiczne i związki',
  sociological_research_29: 'Struktury państwowe',
  sociological_research_30: 'Nasze podejście do pracy',
  sociological_research_31:
    'Stosujemy podejście naukowe do naszej pracy oraz głębokie zrozumienie obecnej rzeczywistości, a także różne metody badawcze do zbierania danych.',
  sociological_research_32:
    'Zaangażowaliśmy wysoko wyspecjalizowanych ekspertów z niezbędnym doświadczeniem do realizacji określonych zleceń i dysponujemy zespołem ekspertów z różnych dziedzin.',
  sociological_research_33:
    'Nasz dział analiz w firmie analizuje zebrane dane przy użyciu unikalnego zestawu narzędzi, w zależności od specyfiki zadania.',
  sociological_research_34:
    'Generujemy raporty z szczegółowymi wnioskami i rekomendacjami, uwzględniając potrzeby i oczekiwania Twojej grupy docelowej.',
  sociological_research_35:
    'Oferujemy porady i wsparcie podczas realizacji zaleceń, pomagając wprowadzanie pozytywnych zmian.',
  sociological_research_36: 'Nasze badania w telewizji',
  sociological_research_37: 'Zapoznaj się z naszymi raportami',
  sociological_research_38:
    'Nasze podejście naukowe do pracy gwarantuje naszym klientom dokładne i wiarygodne dane socjologiczne w całej Ukrainie i Europie przy minimalnym błędzie',
  sociological_research_39:
    'Działamy zgodnie ze wszystkimi zasadami etyki i normami przeprowadzania badań socjologicznych.',
  sociological_research_40:
    'Stosujemy Kodeks Etyki Zawodowej Socjologów Stowarzyszenia Socjologów Ukrainy oraz Deklarację Helsińską Zasad Etycznych Badań. Jesteśmy członkami ESOMAR, Ukraińskiego Stowarzyszenia Marketingowego, Ogólnoukraińskiego Stowarzyszenia Centrów Kontaktowych Ukrainy.',
  sociological_research_41: 'Wykonanie',
  sociological_research_42: 'panel internetowy',
  sociological_research_43: 'regularni ankieterzy',
  sociological_research_44: 'nadzorcy',
  sociological_research_45: 'Kontrola jakości w terenie',
  sociological_research_46: '100% rejestracja współrzędnych GPS (CAPI)',
  sociological_research_47: 'Weryfikacja ankiet (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: '100% nagrywanie wywiadów (CAPI, CATI)',
  sociological_research_49: 'Korzystamy z oprogramowania licencjonowanego:',
  sociological_research_50:
    'SPSS do analizy danych statystycznych, przetwarzania zestawów danych',
  sociological_research_51:
    'DigSee Sure do CAPI: logika ankiety, geolokalizacja, nagrywanie audio wywiadów, wyświetlanie materiałów wideo',
  sociological_research_52:
    'SurveyMonkey do CAWI: logika ankiety, wygodny format, wizualizacja',
  sociological_research_53:
    'VoIPTime (centrum kontaktowe): programowanie ankiet o dowolnej złożoności, przesyłanie nagrań audio do klienta',
  sociological_research_54: 'Próba',
  sociological_research_55: 'Obliczamy i stosujemy próby o dowolnej złożoności',
  sociological_research_56:
    'W naszej pracy stosujemy próby proste, systematyczne, warstwowe i klastrowe',
  sociological_research_57:
    'Do badań twarzą w twarz, reprezentujących całe państwo, stosujemy procedury doboru próby wielostopniowej warstwowej',
  sociological_research_58:
    'Dobór osiedli według regionów jest dokonywany zgodnie z wielkością i typem osiedla, a ich liczba jest określana proporcjonalnie do ich objętości w każdym regionie',
  sociological_research_59: 'Zespół',
  sociological_research_60:
    'Nasi socjolodzy są członkami Stowarzyszenia Socjologów Ukrainy',
  sociological_research_61:
    'Mamy 12 pełnoetatowych analityków z doświadczeniem zarówno w projektach ilościowych, jak i jakościowych w różnych branżach.',
  sociological_research_62:
    'Mamy silny zespół analityków z ponad 10-letnim doświadczeniem badawczym. Mamy naukowców, doktorów, MBI.',
  sociological_research_63: 'Iryna Maksymiuk',
  sociological_research_64:
    'Szef Działu Badań. Ponad 15-letnie doświadczenie w badaniach marketingowych, zarządzaniu badaniami na wszystkich etapach, przygotowywaniu raportów analitycznych, prezentacji',
  sociological_research_65: 'Andriy Biloskursky',
  sociological_research_66:
    'Szef Programowania i Przetwarzania Danych. 20-letnie doświadczenie w badaniach marketingowych, socjologicznych i rynkowych',
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    'Specjalista ds. Programowania, doktorantka socjologii na DonSUU. 5-letnie doświadczenie w badaniach marketingowych, członkini zarządu Sojuszu Demokratycznego Kobiet, przewodnicząca ds. fundraisingu.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Szefowa Działu Badań Socjologicznych, posiada 13-letnie doświadczenie w projektach socjologicznych i społecznych na poziomie międzynarodowym i krajowym. Doktor socjologii, przewodnicząca Komitetu Badawczego Ukraińskiego Stowarzyszenia Centrów Kontaktowych Ukrainy.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Odkryj nasze alternatywne badania dla wyjątkowych przypadków',
  ad_hoc_3: 'Skontaktuj się z nami',
  ad_hoc_4: 'Nasze podejście',
  ad_hoc_5:
    'Nasz zespół ekspertów starannie analizuje zebrane dane, dostarczając Ci skomplikowane rozwiązania, które przyczyniają się do Twojego rozwoju.',
  ad_hoc_6:
    'Projektujemy unikalne badania dostosowane do Twoich specyficznych wymagań, korzystając z różnych metodologii, w tym ankiet, wywiadów, grup fokusowych, analizy danych i innych.',
  ad_hoc_7: 'Dlaczego wybrać badania ad hoc?',
  ad_hoc_8: 'Elastyczność',
  ad_hoc_9:
    'Żadne dwa projekty badawcze nie są takie same. Dzięki badaniom ad hoc mamy elastyczność, aby dostosować i rozwijać unikalne podejście w razie potrzeby, zapewniając, że Twoje spostrzeżenia pozostaną istotne i wykonalne.',
  ad_hoc_10: 'Precyzja',
  ad_hoc_11:
    'Dostosowując nasze metodologie do Twoich unikalnych wymagań, dostarczamy precyzyjne i ukierunkowane wyniki, które dają cenne spostrzeżenia.',
  ad_hoc_12: 'Innowacyjność',
  ad_hoc_13:
    'W naszej pracy badawczej przyjmujemy innowacyjność i kreatywność, badając nowe techniki i podejścia, aby odkrywać ukryte możliwości i wyzwania.',
  ad_hoc_14: 'Terminowość',
  ad_hoc_15:
    'Badania ad hoc umożliwiają szybkie wdrożenie, co pozwala na uzyskanie kluczowych spostrzeżeń w odpowiednim czasie, utrzymując Cię na czołowej pozycji w branży.',
  ad_hoc_16: 'Nasza ekspertyza',
  ad_hoc_17:
    'Eksperci 4Service z ponad 20-letnim doświadczeniem w badaniach rynku i obsłudze klienta tworzą unikalne rozwiązania, które pomagają rozwijać Twoją firmę.',
  ad_hoc_18:
    'Wykorzystujemy zestaw narzędzi dostosowanych do Twoich specyficznych wymagań.',
  ad_hoc_19: 'Czy żadna metodologia nie działa dla Ciebie?',
  ad_hoc_20: 'Złóż wniosek o badania ad hoc',
  ad_hoc_21: 'Dostosowane do konkretnego problemu',
  ad_hoc_22:
    'Używamy mieszanki różnych części metodologii, jak również całkowicie nowego podejścia.',
  ad_hoc_23: 'Indywidualne podejście',
  ad_hoc_24:
    'Starannie dostosowujemy rozwiązanie bezpośrednio do Twojego problemu.',
  ad_hoc_25: 'Przekraczamy oczekiwania',
  ad_hoc_26:
    'Dostarczamy plan działania z rekomendacjami, które pomogą Twojej firmie osiągnąć cele.',
  ad_hoc_27: 'Masz niestandardowy problem',
  ad_hoc_28: 'Żadna z metodologii nie działa w Twoim przypadku',
  ad_hoc_29: 'Chcesz zaaplikować różne metodologie lub ich części jednocześnie',
  ad_hoc_30: 'Kto korzysta z badań ad hoc?',
  service_design_1: 'Niektórzy z naszych klientów',
  service_design_2: 'Projektowanie usług',
  service_design_3:
    'Przekształć swoją firmę z orientacji na produkt i sprzedaż na orientację na usługę i ludzi',
  service_design_4: 'Skontaktuj się z ekspertem',
  service_design_5: 'Ucz się za darmo!',
  service_design_6:
    'Zebraliśmy praktyczne i użyteczne informacje w naszej Bibliotece Projektowania Usług',
  service_design_7:
    'Biblioteka zawiera szczegółowe opisy każdego etapu, odpowiednie narzędzia i techniki, wskazówki dotyczące ważnych szczegółów oraz demonstracje wideo.',
  service_design_8: 'Przejdź do biblioteki',
  service_design_9: 'Witamy w Projektowaniu Usług!',
  service_design_10:
    'Projektowanie usług ma na celu tworzenie i ulepszanie usług poprzez analizę całego ekosystemu usług – ludzi, procesów i punktów kontaktowych w celu zwiększenia satysfakcji użytkowników i efektywności operacyjnej.',
  service_design_11:
    'Projektowanie usług to nie tylko powód, dla którego preferujesz jedną kawiarnię nad inną. To także powód, dla którego wracasz do tego miejsca wielokrotnie i polecasz je wszystkim swoim znajomym.',
  service_design_12: 'Jak biznes może skorzystać z projektowania usług',
  service_design_13: 'Satysfakcja użytkowników',
  service_design_14:
    'Twórz płynne, spersonalizowane doświadczenia, które zachwycą użytkowników.',
  service_design_15: 'Efektywność operacyjna',
  service_design_16:
    'Optymalizuj procesy, redukuj koszty i popraw jakość świadczenia usług.',
  service_design_17: 'Spójność marki',
  service_design_18:
    'Zadbaj, aby wszystkie punkty kontaktowe były zgodne z wartościami marki.',
  service_design_19: 'Elastyczność w zmianach',
  service_design_20:
    'Projektuj usługi, które mogą rosnąć i ewoluować wraz ze zmieniającymi się potrzebami.',
  service_design_21: 'Zwiększenie lojalności',
  service_design_22:
    'Buduj silniejsze relacje z klientami i zwiększaj lojalność poprzez wyjątkowe doświadczenia.',
  service_design_23: 'Przewaga konkurencyjna',
  service_design_24:
    'Wyróżnij się na rynku unikalnymi, niezapomnianymi doświadczeniami usługowymi.',
  service_design_25: 'Dlaczego',
  service_design_26: 'Dlaczego warto wybrać projektowanie usług?',
  service_design_27:
    'To kultura, metodologia i sposób myślenia, który pomaga firmom na całym świecie przejść z orientacji na produkt i sprzedaż na skupienie się na potrzebach i problemach ludzi.',
  service_design_28: 'Nasze usługi',
  service_design_29: 'Projektowanie usług dla klienta',
  service_design_30:
    'Rozwijamy i wdrażamy projekty skierowane na klienta, tworząc nowe produkty lub ulepszając istniejące. Oferujemy również szkolenia do samodzielnej pracy z tymi zmianami.',
  service_design_31: 'Projektowanie usług dla pracowników',
  service_design_32:
    'Praktyczny kurs rozwijania i wdrażania procesów, struktur i zmian ukierunkowanych na pracowników w firmie.',
  service_design_33: 'Projektowanie przyszłości',
  service_design_34:
    'Opracowywanie koncepcji przyszłości i strategii firmy, uwzględniających różne scenariusze i możliwe innowacje.',
  service_design_35: 'Kreatywność w biznesie',
  service_design_36:
    'Praktyczne szkolenie, które pomaga uczestnikom wykorzystać kreatywność do rozwiązywania konkretnych problemów i rozwijania unikalnych rozwiązań rynkowych.',
  service_design_37: 'Nasze warsztaty',
  service_design_38:
    'Oferujemy praktyczne warsztaty z certyfikacją uznawaną na całym świecie dla naszych uczestników',
  service_design_39:
    'Twórz produkty, usługi i obiekty, które przekraczają oczekiwania',
  service_design_40: 'Wdrażaj procesy biznesowe, które działają',
  service_design_41: 'Harmonizuj relacje między pracownikami firmy a klientami',
  service_design_42: 'Kalendarz',
  service_design_43: 'Wkrótce',
  service_design_44: 'Dowiedz się więcej o naszych poprzednich wydarzeniach',
  service_design_45: 'Podstawy Projektowania Usług',
  service_design_46:
    'Twórz produkty, usługi i obiekty, które przekraczają oczekiwania',
  service_design_47: 'EX Projektowanie Usług',
  service_design_48:
    'Twórz produkty, usługi i obiekty, które przekraczają oczekiwania',
  service_design_49: 'Więcej',
  service_design_50: 'Nasze podejście',
  service_design_51:
    '4Service to fundament dla Twoich usług skoncentrowanych na ludziach!',
  service_design_52:
    'Pomagamy firmom rozwijać niezbędne nastawienie i tworzyć wewnętrzne środowisko skoncentrowane na ludziach.',
  service_design_53: 'Narzędzia',
  service_design_54:
    'Używamy unikalnego zestawu technik i narzędzi dostosowanych do Twoich specyficznych wymagań',
  service_design_55: 'Badania użytkowników',
  service_design_56:
    'Wywiady, ankiety, F2F, badania etnograficzne w celu zrozumienia potrzeb i zachowań użytkowników',
  service_design_57: 'Persony',
  service_design_58:
    'Fikcyjne postacie reprezentujące różne typy użytkowników, które pomagają w podejmowaniu decyzji projektowych',
  service_design_59: 'Mapy podróży klienta',
  service_design_60:
    'Wizualne przedstawienia doświadczenia użytkownika podczas korzystania z usługi, identyfikujące kluczowe punkty kontaktowe i trudności',
  service_design_61: 'Sesje burzy mózgów',
  service_design_62:
    'Wspólne sesje z interesariuszami w celu generowania pomysłów i rozwiązań.',
  service_design_63: 'Prototypowanie',
  service_design_64:
    'Tworzenie wstępnych wersji usługi w celu testowania i udoskonalania koncepcji',
  service_design_65: 'Plany usług',
  service_design_66:
    'Szczegółowe diagramy pokazujące zależności między różnymi składnikami usługi i procesami',
  service_design_67: 'Partner',
  service_design_68: '4Service & DTA',
  service_design_69:
    '4Service jest oficjalnym, wyłącznym przedstawicielem Design Thinking Academy',
  service_design_70: 'W ramach naszej współpracy:',
  service_design_71:
    '<b>oferujemy praktyczne szkolenia</b> z wykorzystaniem metody projektowania usług podczas warsztatów prowadzonych przez czołowych ekspertów;',
  service_design_72:
    '<b>szkolimy certyfikowanych</b> specjalistów design thinking;',
  service_design_73:
    '<b>wdrażamy projekty projektowania usług</b> z międzynarodowymi facilitatorami dla wielu firm.',
  service_design_74: 'Ustanowiliśmy Centra Doskonałości Projektowania Usług w:',
  service_design_75: 'Ukraina',
  service_design_76: 'Kazachstan',
  service_design_77: 'Turcja',
  service_design_78: 'Gruzja',
  service_design_79: 'Azerbejdżan',
  service_design_80: 'Dowiedz się więcej o naszych przypadkach',
  four_opinion_1: 'Ankiety od 4Service',
  four_opinion_2:
    'Wypełniaj ankiety online za pieniądze i zarabiaj bez wychodzenia z domu',
  four_opinion_3: 'Rozpocznij',
  four_opinion_4: 'Nasze zalety',
  four_opinion_5: 'Nam ufają',
  four_opinion_6:
    'Nasza społeczność liczy ponad 90 tys. konsumentów na Ukrainie i 100 tys. w Kazachstanie',
  four_opinion_7: 'Doceniamy Twój czas',
  four_opinion_8:
    'Cenimy Twój czas, dlatego za udział w ankietach masz możliwość otrzymania nagrody lub wynagrodzenia pieniężnego',
  four_opinion_9: 'Twoja opinia ma duży wpływ',
  four_opinion_10:
    'Każdy Twój komentarz pomaga markom lepiej rozumieć potrzeby swoich klientów',
  four_opinion_11:
    'Wypełniaj ankiety na 4Opinion od 4Service i zarabiaj online lub wygraj nagrodę',
  four_opinion_12: 'Dołącz do 4Opinion',
  four_opinion_13: 'Jeszcze więcej ciekawych historii na naszym kanale',
  four_opinion_14: 'Więcej informacji dla Ukrainy',
  four_opinion_15: 'Więcej informacji dla Kazachstanu',
  four_opinion_16: 'Dlaczego wybierają nas?',
  four_opinion_17:
    '4Service – międzynarodowa grupa badawcza zajmująca się zarządzaniem doświadczeniami klientów',
  four_opinion_18:
    'Jesteśmy w czołowej dziesiątce europejskich agencji zarządzania doświadczeniami klientów',
  four_opinion_19:
    'Naszą misją jest poprawa jakości obsługi klientów na całym świecie!',
  four_opinion_20: 'Jak zacząć zarabiać na ankietach online?',
  four_opinion_21: 'Zarejestruj się na stronie ',
  four_opinion_22: 'Otrzymuj powiadomienia o aktualnych ankietach',
  four_opinion_23: 'Wypełniaj ankiety do końca i zarabiaj',
  four_opinion_24: 'Prawdziwe opinie naszych respondentów',
  four_opinion_25: 'Witalij Kochan',
  four_opinion_26:
    '“Wypełniłem ankietę i nawet o tym zapomniałem. Po pewnym czasie zadzwoniła pani Natalia i poinformowała mnie, że wygrałem nagrodę. I oto moja nagroda – Samsung Galaxy M52 5G. Bardzo dziękuję😁”',
  four_opinion_27: 'Wiktoria Popowska',
  four_opinion_28:
    '“Dostałam głośnik, bardzo dziękuję za uczciwe, sprawiedliwe i ciekawe losowanie.”',
  four_opinion_29: 'Julia Aleksandrowna',
  four_opinion_30:
    '“Chyba najbardziej niesamowita rzecz, jaka mi się przydarzyła ostatnio💕 Nie dość, że wygrałam, to jeszcze naprawdę świetną rzecz. Spodziewałam się różnych rzeczy, ale że będą aż tak fajne, nawet nie mogłam sobie wyobrazić. Jeszcze raz wielkie dzięki”',
  four_opinion_31:
    'Tylko z nami masz możliwość zarabiania “żywej” gotówki lub wygrania nagrody',
  four_opinion_32: 'Śledź aktualne ankiety na naszym kanale',
  four_opinion_33: 'Przejdź do kanału',
};
