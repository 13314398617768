import React from 'react';
import { BannerStyle } from './BannerStyle';
import ButtonWithArrowOnHover from '../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useAppSelector } from '../../state/hooks';

interface Props {
  title: string;
  bgColor: string;
  btnColor?: string;
  btnHoverColor?: string;
  description: string;
  buttonKey?: string;
  image?: string;
  textColor?: string;
  link?: string;
}

function Banner({
  title,
  description,
  bgColor,
  btnColor = '#FFF',
  btnHoverColor = '#000',
  buttonKey = 'speak_to_an_expert',
  textColor = '#000',
  image,
  link,
}: Props) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BannerStyle className="banner" bgColor={bgColor} image={image}>
      <div className="container">
        <h1 style={{ color: textColor }}>{title}</h1>
      </div>

      <div className="content">
        <p style={{ color: textColor }} className="bannerDescription">{description}</p>

        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          link={link}
          arrowColor={btnHoverColor}
          textColor={btnColor}
          borderColor={btnColor}
          borderOnHover={btnColor}
          background="none"
          hoverBackground={btnColor}
          hoverTextColor={btnHoverColor}
          text={getTranslationsByLangOrEng(interfaceLanguage, buttonKey)}
        />
      </div>
    </BannerStyle>
  );
}

export default Banner;
