import styled from 'styled-components';
import support from '../../../assets/images/shoppers/support.png';

export const ShoppersSupportStyles = styled.div`
  position: relative;
  width: 100%;

  &::before {
    background: url(${support}) no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &::after {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 180px 10px;
    gap: 50px;
    position: relative;
    z-index: 5;

    @media (max-width: 1200px) {
      padding: 120px 10px;
      gap: 30px;
    }

    @media (max-width: 768px) {
      padding: 80px 10px;
      gap: 30px;
    }

    h2 {
      margin: 0 auto;
      font-weight: 600;
      font-size: 40px;
      line-height: 115%;
      color: #ffffff !important;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;
