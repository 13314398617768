import partner11 from '../../../assets/images/cati/partner-1-1.png';
import partner12 from '../../../assets/images/cati/partner-1-2.png';
import partner21 from '../../../assets/images/cati/partner-2-1.png';
import partner22 from '../../../assets/images/cati/partner-2-2.png';
import partner31 from '../../../assets/images/cati/partner-3-1.png';
import partner32 from '../../../assets/images/cati/partner-3-2.png';
import partner41 from '../../../assets/images/cati/partner-4-1.png';
import partner42 from '../../../assets/images/cati/partner-4-2.png';
import partner51 from '../../../assets/images/cati/partner-5-1.png';
import partner52 from '../../../assets/images/cati/partner-5-2.png';
import partner61 from '../../../assets/images/cati/partner-6-1.png';
import partner62 from '../../../assets/images/cati/partner-6-2.png';
import partner71 from '../../../assets/images/cati/partner-7-1.png';
import partner72 from '../../../assets/images/cati/partner-7-2.png';
import partner81 from '../../../assets/images/cati/partner-8-1.png';
import partner82 from '../../../assets/images/cati/partner-8-2.png';
import partner91 from '../../../assets/images/cati/partner-9-1.png';
import partner92 from '../../../assets/images/cati/partner-9-2.png';
import partner101 from '../../../assets/images/cati/partner-10-1.png';
import partner102 from '../../../assets/images/cati/partner-10-2.png';
import partner111 from '../../../assets/images/cati/partner-11-1.png';
import partner112 from '../../../assets/images/cati/partner-11-2.png';
import partner121 from '../../../assets/images/cati/partner-12-1.png';
import partner122 from '../../../assets/images/cati/partner-12-2.png';
import partner131 from '../../../assets/images/cati/partner-13-1.png';
import partner132 from '../../../assets/images/cati/partner-13-2.png';
import partner141 from '../../../assets/images/cati/partner-14-1.png';
import partner142 from '../../../assets/images/cati/partner-14-2.png';
import partner151 from '../../../assets/images/cati/partner-15-1.png';
import partner152 from '../../../assets/images/cati/partner-15-2.png';
import partner161 from '../../../assets/images/cati/partner-16-1.png';
import partner162 from '../../../assets/images/cati/partner-16-2.png';
import partner171 from '../../../assets/images/cati/partner-17-1.png';
import partner172 from '../../../assets/images/cati/partner-17-2.png';
import partner181 from '../../../assets/images/cati/partner-18-1.png';
import partner182 from '../../../assets/images/cati/partner-18-2.png';
import partner191 from '../../../assets/images/cati/partner-19-1.png';
import partner192 from '../../../assets/images/cati/partner-19-2.png';
import partner201 from '../../../assets/images/cati/partner-20-1.png';
import partner202 from '../../../assets/images/cati/partner-20-2.png';

export const partnersTheme: {
  static: string;
  hover: string;
  alt: string;
}[] = [
  {
    static: partner11,
    hover: partner12,
    alt: 'partner1',
  },
  {
    static: partner21,
    hover: partner22,
    alt: 'partner2',
  },
  {
    static: partner31,
    hover: partner32,
    alt: 'partner3',
  },
  {
    static: partner41,
    hover: partner42,
    alt: 'partner4',
  },
  {
    static: partner51,
    hover: partner52,
    alt: 'partner5',
  },
  {
    static: partner61,
    hover: partner62,
    alt: 'partner6',
  },
  {
    static: partner71,
    hover: partner72,
    alt: 'partner7',
  },
  {
    static: partner81,
    hover: partner82,
    alt: 'partner8',
  },
  {
    static: partner91,
    hover: partner92,
    alt: 'partner9',
  },
  {
    static: partner101,
    hover: partner102,
    alt: 'partner10',
  },
  {
    static: partner111,
    hover: partner112,
    alt: 'partner11',
  },
  {
    static: partner121,
    hover: partner122,
    alt: 'partner12',
  },
  {
    static: partner131,
    hover: partner132,
    alt: 'partner13',
  },
  {
    static: partner141,
    hover: partner142,
    alt: 'partner14',
  },
  {
    static: partner151,
    hover: partner152,
    alt: 'partner15',
  },
  {
    static: partner161,
    hover: partner162,
    alt: 'partner16',
  },
  {
    static: partner171,
    hover: partner172,
    alt: 'partner17',
  },
  {
    static: partner181,
    hover: partner182,
    alt: 'partner18',
  },
  {
    static: partner191,
    hover: partner192,
    alt: 'partner19',
  },
  {
    static: partner201,
    hover: partner202,
    alt: 'partner20',
  },
];
