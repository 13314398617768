import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { LayoutStyles } from './LayoutStyles';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { headerColorThemes, pagesWithBlackTheme } from '../../constants';
import ContactForm from '../contactForm/ContactForm';
import ArrowRightButtonIcon from '../../assets/icons/ArrowRightButtonIcon';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { useAppSelector } from '../../state/hooks';
import { SubscribeBanner } from '../subscribeBanner';
import { CVBanner } from '../careerComponents';
import { ZapierPopup } from '../zapierPopup';

export const Layout: React.FC = () => {
  const { pathname, hash } = useLocation();
  const [searchParams] = useSearchParams();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [cookies] = useCookies<string>(['cookieAccept', 'emailSubscribe', 'emailHoldSubscribe']);

  const [scrollY, setScrollY] = useState(0);
  const [scrollToTopButtonVisibility, setScrollToTopButtonVisibility] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState<boolean>(false);

  const [prevInterfaceLanguage, setPrevInterfaceLanguage] = useState(interfaceLanguage);

  const isThunks = useMemo(() => pathname.includes('thank-you'), [pathname]);
  const is404 = useMemo(() => pathname.includes('404'), [pathname]);
  const isSupport = useMemo(() => {
    const splittedPathName = pathname.split('/');

    return splittedPathName[2] === 'support';
  }, [pathname]);
  const isShoppers = useMemo(() => {
    const splittedPathName = pathname.split('/');

    return splittedPathName[2] === 'shoppers';
  }, [pathname]);
  const isCareer = useMemo(() => {
    const splittedPathName = pathname.split('/');

    return splittedPathName[2] === 'career';
  }, [pathname]);

  const theme = useMemo(() => {
    const splittedPathName = pathname.split('/');

    if (
      splittedPathName.slice(2).length === 0
      || splittedPathName[2] === ''
      || splittedPathName[2] === 'partnership'
      || splittedPathName[2] === '4opinion'
      || splittedPathName[2] === 'career'
      || splittedPathName[2] === 'shoppers'
      || splittedPathName[2] === 'support'
      || splittedPathName[2] === 'employee-experience'
    ) {
      return 'darkTheme';
    }
    if (
      (splittedPathName[2] === 'blog' && splittedPathName.length > 3)
      || (splittedPathName[2] === 'cases' && splittedPathName.length > 3)
      || (splittedPathName[2] === 'search' && splittedPathName.length > 3)
    ) {
      return 'lightTheme';
    }
    if (
      pagesWithBlackTheme.some((path) => `${splittedPathName.slice(2).join('/')}`
        .toLowerCase()
        .startsWith(path.toLowerCase()))
    ) {
      return 'darkTheme';
    }
    if (splittedPathName[2] === 'cx') {
      return 'greenTheme';
    }

    if (splittedPathName[2] === 'methodologies' || splittedPathName[2] === 'market-research' || splittedPathName[2] === 'products' || splittedPathName[2] === '404' || splittedPathName[2] === 'adHoc') {
      return 'darkTheme';
    }

    return 'lightTheme';
  }, [pathname]);

  function logit() {
    setScrollY(window.pageYOffset);
    if (scrollY > 300) {
      setScrollToTopButtonVisibility(true);
    }

    if (scrollY < 300 && scrollToTopButtonVisibility) {
      setScrollToTopButtonVisibility(false);
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', logit);
    };
  });

  useEffect(() => {
    if (prevInterfaceLanguage === interfaceLanguage) {
      window.scroll(0, 0);
    } else {
      setPrevInterfaceLanguage(interfaceLanguage);
    }

    if (scrollToTopButtonVisibility) {
      setScrollToTopButtonVisibility(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (cookies.cookieAccept === 'true') {
      ReactGA.initialize('{MY_GA_ID}');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (cookies.emailSubscribe !== 'true' && cookies.emailHoldSubscribe !== 'true') {
      setTimeout(() => {
        setShowSubscribe(true);
      }, 30000);
    }
  }, []);

  return (
    <LayoutStyles className={theme}>
      <div className="layoutContentWrapper">
        <Header
          burgerMenuColor={headerColorThemes[theme].burgerMenuColor}
          langMenuTextColor={headerColorThemes[theme].langMenuTextColor}
          logoTextColor={headerColorThemes[theme].logoTextColor}
          sloganTextColor={headerColorThemes[theme].sloganTextColor}
          backgroundColor={headerColorThemes[theme].backgroundColor}
          menuBackgroundColor={headerColorThemes[theme].menuBackgroundColor}
          menuIconsColor={headerColorThemes[theme].menuIconsColor}
          menuTextColor={headerColorThemes[theme].menuTextColor}
          backgroundColorWhenMenuActive={
            headerColorThemes[theme].backgroundColorWhenMenuActive
          }
          colorTheme={theme}
        />
        <main>
          <Outlet />
          {scrollToTopButtonVisibility && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              className="scrollButton"
              type="button"
              onClick={() => window.scrollTo(0, 0)}
            >
              <ArrowRightButtonIcon color="#FFF" width={20} height={30} />
            </button>
          )}
        </main>
        <div className="footerWrapper">
          {!isThunks && !is404 && !isSupport && !isShoppers && !isCareer && <ContactForm colorTheme={theme} />}
          {!is404 && <Footer colorTheme={theme} />}
        </div>
      </div>

      {showSubscribe && <SubscribeBanner setShowSubscribe={setShowSubscribe} />}
      <CVBanner />

      {!!searchParams.get('zappier')?.length && (
        <ZapierPopup />
      )}
    </LayoutStyles>
  );
};
