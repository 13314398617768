import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BannerEmployeeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const BannerEmployeeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <BannerEmployeeExperienceStyles>
      <div className="container">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_1')}</h1>
        <div className="description">
          <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_2')}</h3>
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#fff"
            borderColor="#fff"
            borderOnHover="#fff"
            background="transparent"
            hoverBackground="transparent"
            hoverTextColor="#fff"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'employee_experience_3',
            )}
          />
        </div>
      </div>
    </BannerEmployeeExperienceStyles>
  );
};
