import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import { ShoppersIntroStyles } from './styles';

export const ShoppersIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersIntroStyles>
      <Banner
        bgColor="linear-gradient(2.66deg, #0855E2 9.08%, #1076C7 90.92%)"
        title={`${getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_1')}\n${getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_2')}`}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'shoppers_4',
        )}
        buttonKey="shoppers_3"
        btnColor="#fff"
        btnHoverColor="#000"
        textColor="#fff"
        link="https://4serviceua.shopmetrics.com/auth/index.asp#signup"
      />
    </ShoppersIntroStyles>
  );
};
