import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { CatiContactUsStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const CatiContactUs = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CatiContactUsStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_62')}</h2>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#000"
          borderOnHover="#fff"
          background="#000"
          className="btn"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'cati_3',
          )}
        />
      </div>
    </CatiContactUsStyles>
  );
};
