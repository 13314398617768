export const pt: { [key: string]: string } = {
  subscribe_with_click: 'Assine com um clique',
  subscribe: 'Assine com um clique',
  minimum_number_characters: 'número mínimo de 2 caracteres',
  request_details: 'Solicitar detalhes',
  form_title: 'Entraremos em contato com você em breve',
  form_required: 'Campos marcados com * são obrigatórios',
  form_name_placeholder: 'Nome',
  form_email_placeholder: 'E-mail',
  form_phone_placeholder: 'Número de telefone',
  form_company_placeholder: 'Empresa',
  form_submit: 'Enviar',
  form_1:
    'Dou meu consentimento para o processamento de dados pessoais de acordo com a',
  form_2: 'Política de Privacidade',
  validation_privacy_policy: 'Por favor, aceite a Política de Privacidade',
  validation_phone: 'Número de telefone inválido',
  validation_email: 'Formato de e-mail inválido',
  validation_required: 'Campos obrigatórios não preenchidos',
  submit_error: 'Ocorreu um erro ao salvar o formulário',
  footer_1: 'Nossos parceiros:',
  footer_2: 'Métodos',
  footer_3: 'Produtos',
  footer_4: 'Contatos:',
  footer_5: 'Impressão',
  footer_6: 'Política de Privacidade',
  footer_7: 'Pesquisa de Mercado',
  about_us_1: 'Responsabilidade Social Corporativa 4Service',
  about_us_2:
    'Do coração e da alma de cada membro de nossa equipe. Fazemos o nosso melhor para manter uma faísca de bondade em cada um dos nossos funcionários e tornar o mundo ao nosso redor um pouco melhor',
  about_us_3: 'Responsabilidade Social Corporativa 4Service',
  about_us_4:
    'Do coração e da alma de cada membro de nossa equipe. Fazemos o nosso melhor para manter uma faísca de bondade em cada um dos nossos funcionários e tornar o mundo ao nosso redor um pouco melhor',
  about_us_5: 'Jogo de negócios Service Mania',
  about_us_6: 'Nossas iniciativas de divulgação para crianças e estudantes',
  about_us_7: 'Caridade / Voluntariado',
  about_us_8:
    'Orfanatos, lares para idosos e voluntariado para o exército ucraniano. A parte mais emocionante de nossas atividades',
  about_us_9: 'Bem-estar animal',
  about_us_10:
    'Qualquer pessoa pode entrar no nosso escritório com um animal de estimação. Ajudamos abrigos, arrecadamos fundos para alimentos e, às vezes, também tentamos encontrar um lar para animais sem-teto',
  about_us_11: '4Service Group',
  about_us_12:
    'É uma empresa internacional de pesquisa que gerencia a experiência do cliente.',
  about_us_13:
    'Implementamos projetos complexos para aumentar as vendas e desenvolver empresas orientadas ao cliente.',
  about_us_14:
    'É uma holding de pesquisa internacional com sede em Viena, Áustria. A empresa foi fundada em 2001 com o objetivo de melhorar a qualidade de vida de seus clientes e das pessoas que interagem com as empresas, utilizam seus serviços e consomem seus produtos. Afinal, por isso o nome “Forservice” foi escolhido para uma empresa que representa atendimento ao cliente e boa qualidade.',
  about_us_15:
    'Este foi o início da história da 4Service em Kiev, Ucrânia, como o principal fornecedor de serviços de cliente oculto. Continuou a operar com sucesso como uma empresa de pesquisa em 52 países, com novas oportunidades em uma abordagem holística para analisar e melhorar toda a cadeia de experiência do cliente',
  about_us_16: 'nascida em nome do serviço',
  about_us_17: 'primeiro escritório localizado fora da Ucrânia',
  about_us_18: 'realocação da sede para Viena',
  about_us_19: 'membro de elite do MSPA, agência Top-10 na Europa',
  about_us_20: 'estabelecimento de escritórios em 9 países',
  about_us_21: 'primeiros projetos digitais, Voicer',
  about_us_22: 'parceiros da DesignThinkers Academy',
  about_us_23: 'primeiros projetos globais de CX',
  about_us_24: 'Missão',
  about_us_25:
    'Nossa missão é melhorar o atendimento ao cliente em todo o mundo! Nossa inspiração são as mudanças qualitativas que vemos nas pessoas e empresas com as quais trabalhamos.',
  about_us_26: '20+ anos de Experiência do Cliente',
  about_us_27: '95 países',
  about_us_28:
    'Coletamos e compartilhamos a melhor experiência da indústria de todo o mundo',
  about_us_29: 'Sede em Viena',
  about_us_30:
    'países. Coletamos e compartilhamos a melhor experiência da indústria de todo o mundo',
  about_us_31: 'classificações de qualidade de serviço',
  about_us_32: 'estamos entre as 10 principais agências de CX da Europa',
  about_us_33: 'escritórios representativos / HQ na Áustria',
  about_us_34: 'projetos ativos',
  about_us_35: 'Seu futuro local de trabalho',
  about_us_36: 'Nossos escritórios',
  about_us_37:
    'A 4Service ™ se esforça para criar um ambiente de trabalho confortável para seus funcionários. Nossos colaboradores trabalham no local, remotamente ou de forma híbrida para a melhor conveniência e produtividade de cada um. Salas de relaxamento, suporte psicológico gratuito e um ambiente acolhedor. Somos amigáveis com pessoas e animais.',
  about_us_38: 'Equipe que ilumina',
  about_us_39:
    'Nossa equipe multinacional se compromete a ouvir, absorver feedback de nossos clientes e crescer em conjunto. O mapa da jornada do cliente com nossos clientes é tão importante para nosso negócio quanto lançar novos produtos ou alcançar nossas metas financeiras. Nossas atividades visam ter um impacto positivo nas comunidades ao nosso redor e fornecer soluções inovadoras e centradas nas pessoas para nossos clientes.',
  about_us_40: 'Todos',
  about_us_41: 'Gestão',
  about_us_42: 'Equipe Global',
  about_us_43: 'Proprietários de produtos',
  about_us_44: 'Nossos parceiros',
  about_us_45:
    'Implementamos projetos complexos para aumentar as vendas e desenvolver os negócios de empresas orientadas ao cliente',
  about_us_46:
    'é um dos principais Institutos de Formação Profissional “orientados para o design”, representado em 25 países e treina, desenvolve e facilita equipes e comunidades multidisciplinares criativas para impulsionar mudanças positivas. O Design Thinking permite que você desenvolva e entregue ideias inovadoras, mudanças e soluções para problemas complexos.',
  about_us_47:
    'A missão deles é fornecer espaço e tempo para que equipes e indivíduos sejam inspirados e desafiados, para “aprender fazendo” e fortalecer sua capacidade de resolver problemas criativamente e ajudar as organizações a criar o ambiente e a mentalidade necessários para impulsionar uma inovação ousada.',
  about_us_48:
    'é a Associação Comercial representativa para empresas envolvidas na indústria de Mystery Shopping. A Associação opera regionalmente em todo o mundo, com capítulos localizados na América, Europa/África e Ásia/Pacífico. As empresas associadas se unem como um corpo comum com o objetivo de fortalecer a indústria de Mystery Shopping por meio de esforços e ações combinados.',
  about_us_49:
    'é uma organização de membros sem fins lucrativos. Os membros formam uma comunidade convencida do potencial da análise de dados, pesquisa e insights para ajudar a melhorar as sociedades, organizações e a vida de todos.',
  about_us_50:
    'ESOMAR tem sede em Amsterdã, na Holanda. A ESOMAR está presente em mais de 130 países por meio de seus membros desde 1947.',
  about_us_51:
    '(UCCA) é uma comunidade profissional de especialistas e empresas privadas unidas com o objetivo de desenvolvimento e interações na área de contact center e atendimento ao cliente. A Filosofia da UCCA é elevar a cultura da indústria de contact center, baseada em tecnologias modernas e altos padrões.',
  about_us_52: 'A Associação Ucraniana de Contact Centers foi fundada em 2010',
  about_us_53:
    'estuda a melhor experiência mundial no campo da educação para adaptá-la e usá-la no mercado ucraniano. Por isso a Academy DTEK coopera com as principais escolas de negócios internacionais: INSEAD, IE Business School e Stanford University.',
  about_us_54:
    'A Academy DTEK é focada no desenvolvimento de uma cultura orientada para o cliente e inovadora na D.Client School, ID School, Executive Education: Energia da Inovação, Power of Communications, programa digital DX School, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Gerente Internacional de Vendas',
  bht_1: 'Saúde da Marca',
  bht_2: 'Rastreamento',
  bht_3: 'As marcas são incrivelmente poderosas',
  bht_4: 'Descubra quão bem sua marca está se saindo no geral',
  bht_5: 'Sobre',
  bht_6: 'O que é Rastreamento de Saúde da Marca?',
  bht_7:
    'Este é um tipo de pesquisa de mercado realizada com uma certa regularidade (a cada duas semanas, todo mês, etc.) para obter informações sobre o',
  bht_8:
    'status de uma marca no mercado com base em indicadores como destaque, demanda, imagem, características de seus consumidores, saúde da marca, etc.',
  bht_9: 'Comprar a marca com mais frequência',
  bht_10: 'Leal',
  bht_11: 'Comprou a marca por 3 meses',
  bht_12: 'Clientes do repertório / regulares',
  bht_13: 'Comprou a marca por 6 meses',
  bht_14: 'Clientes de curto prazo',
  bht_15: 'Comprou a marca por um ano',
  bht_16: 'Clientes de longo prazo',
  bht_17: 'Estão cientes da marca, mas nunca a usaram/compraram',
  bht_18: 'Ciente, mas não são clientes',
  bht_19: 'Desconhecem: nunca ouviram falar da marca',
  bht_20: 'Desconhecem',
  bht_21: 'Perfil dos compradores da marca',
  bht_22:
    'Avaliação da consistência dos esforços colocados no longo prazo para construir uma marca',
  bht_23: 'Medindo o nível de conhecimento do cliente sobre a marca',
  bht_24: 'Mostrando a eficácia das campanhas publicitárias e informativas',
  bht_25:
    'Determinando o impacto de um período de alta publicidade nas atividades de vendas/marketing dos concorrentes',
  bht_26:
    'Fornecendo controle constante ao proprietário da empresa em períodos de mudanças contínuas no mercado',
  bht_27: 'Quais tarefas podem ser resolvidas pelo BHT:',
  bht_28: 'Solicite uma pesquisa',
  bht_29: 'Tarefa a resolver',
  bht_30: 'Por exemplo,',
  bht_31:
    'se uma empresa precisar de apenas uma sessão de BHT por trimestre durante a medição das participações de mercado, uma pesquisa com os respondentes deve ser realizada com mais frequência ao medir o conhecimento da marca pelos clientes a partir da publicidade.',
  bht_32: 'CATI',
  bht_33: 'Presencial',
  bht_53: 'Entrevistas',
  bht_34: 'CAWI',
  bht_35: 'Tarefa a resolver',
  bht_36: 'Coletando informações',
  bht_37:
    'através de vários métodos, o rastreamento de marca geralmente é dividido em três estágios, dependendo do público-alvo e da dificuldade de acesso:',
  bht_38:
    'Usamos tanto métodos tradicionais quanto contemporâneos para coletar dados:',
  bht_39:
    'Trabalhamos com dois modelos: o primeiro com um conjunto simples de indicadores e o segundo voltado para indústrias mais complexas, como as indústrias alimentícias e de serviços. Um modelo mais complexo envolve um estudo aprofundado que inclui até 10 índices estimados.',
  bht_40: 'Oferecemos',
  bht_41: 'um controle de dados iniciais',
  bht_42: 'Cobrimos',
  bht_43: 'todo o país',
  bht_44: 'Oferecemos uma solução',
  bht_45:
    'para mercados não dinâmicos/mercados com número limitado de concorrentes (Brand Point Analyze)',
  bht_46: 'Por que nós?',
  bht_47: 'Por que nos escolher?',
  bht_50: 'Resultado ruim',
  bht_51: 'Bom resultado',
  bht_52: 'Resultado médio',
  cases_1:
    'Desafio: Como melhorar a experiência de compra de veículos elétricos para o cliente nas concessionárias Hyundai?',
  cases_2: 'Mapeamento da Jornada do Cliente',
  cases_3: 'Ideação',
  cases_4: 'Pesquisa qualitativa',
  cases_5:
    'Desafio: O que deve ser alterado nas lojas (tanto no processo de serviço, no interior, na percepção da marca) para atrair o interesse dos clientes pela marca Oppo?',
  cases_6: 'CJM online',
  cases_7: 'Teste de UI',
  cases_8: 'Entrevistas',
  cases_9:
    'Desafio: O que deve ser alterado nas lojas para atrair o interesse dos clientes pela marca Oppo?',
  cases_10: 'Parte qualitativa',
  cases_11: 'Ideação',
  cases_12: 'Parte quantitativa',
  cases_13: 'Mapa da Jornada do Cliente',
  cases_14: 'Casos',
  cases_15: 'Todos',
  cases_16: 'Vamos criar juntos o seu grande caso',
  csi_1: 'Cliente',
  csi_2: 'Índice de Satisfação',
  csi_3: 'Determine as razões para a',
  csi_4: 'satisfação e insatisfação dos consumidores',
  csi_5: 'Pesquisa de satisfação do cliente',
  csi_6:
    'é a determinação dos fatores, causas e motivos que influenciam a decisão de compra de um cliente, assim como o comportamento do público-alvo.',
  csi_7: 'Uma pesquisa de satisfação do cliente',
  csi_8:
    'mostra o quão satisfeito ou insatisfeito o cliente está com um aspecto específico do serviço ou com o produto em geral.',
  csi_9: 'A pesquisa de satisfação',
  csi_10:
    'é realizada entre os clientes atuais da empresa e usuários de determinados produtos/serviços.',
  csi_11: 'O que é CSI?',
  csi_12:
    'A satisfação do cliente mede o grau em que as expectativas do cliente foram atendidas em relação à compra de um produto ou serviço fornecido pela sua empresa.',
  csi_13: 'Homem feliz',
  csi_14: 'Sobre',
  csi_15: 'Identificar quaisquer fatores ocultos',
  csi_16: 'que possam afetar a satisfação do cliente',
  csi_17: 'Determinar as razões',
  csi_18:
    'por trás de qualquer aumento/diminuição na demanda por bens e serviços',
  csi_19: 'Otimizar os',
  csi_20: 'processos empresariais da empresa',
  csi_21: 'Adaptando-os',
  csi_22: 'às necessidades do consumidor moderno',
  csi_23: 'Formar uma mensagem publicitária eficaz',
  csi_24: 'e posicionar um produto de acordo com a solicitação do cliente',
  csi_25: 'Tarefas a resolver',
  csi_26: 'Quais tarefas as pesquisas de satisfação do cliente podem resolver',
  csi_27: 'Homem com telefone',
  csi_28: 'Construímos um modelo matemático',
  csi_29:
    'dos fatores que podem influenciar a satisfação/fidelidade do cliente (análise de regressão)',
  csi_30: 'Otimizar os',
  csi_31: 'processos empresariais da empresa',
  csi_32: 'Construímos um índice de satisfação do cliente calculado',
  csi_33: '(CSI) e o comparamos com o índice declarativo',
  csi_34: 'Realizamos uma pesquisa',
  csi_35: 'para os segmentos B2B e B2C.',
  csi_36: 'Nossos questionários são desenvolvidos por uma equipe',
  csi_37:
    'de analistas especialmente para os projetos individuais de nossos clientes',
  csi_38: 'Por que nós',
  csi_39: 'Por que nos escolher?',
  csi_40: 'Métodos de coleta de informações:',
  main_1:
    'de nossos clientes melhoram seu desempenho financeiro aprendendo com a experiência de seus clientes',
  main_2:
    'Serviços de pesquisa e consultoria CX: uma visão 360° do seu negócio',
  main_3:
    'Descubra nossa gama de soluções e ferramentas para apoiar seu negócio',
  main_4: 'Produtos',
  main_5:
    'Pesquisa qualitativa é a coleta e análise de dados não numéricos para entender conceitos, opiniões ou experiências',
  main_6:
    'Métodos de pesquisa quantitativa são a medição objetiva e análise numérica de dados coletados por meio de enquetes, questionários e pesquisas',
  main_7:
    'Mystery shopping é uma técnica de pesquisa de mercado para medir e monitorar as interações entre um cliente e uma empresa ou organização durante um cenário pré-definido',
  main_8:
    'Sistema de Gerenciamento de Feedback. A voz do seu cliente em um único sistema de Gestão de Feedback do Cliente',
  main_9:
    'Uma plataforma de inteligência de conteúdo impulsionada por IA que fornece insights do público',
  main_10:
    'Software de Gestão de Desempenho. Aplicativo gamificado para aumentar as vendas',
  main_11:
    'Monitoramento de preços é o processo de coleta, processamento e análise dos preços dos concorrentes',
  main_12:
    'Design de serviço é como entender as necessidades e desejos das pessoas que usarão um produto ou serviço, passando tempo com elas',
  main_13:
    'Nossa equipe de analistas aplica as abordagens mais atualizadas e fundamentais, comprovadas como bem-sucedidas com muitos clientes',
  main_14: 'Metodologias',
  main_15: 'Mapa da jornada do cliente',
  main_16:
    'Mapeamento da Jornada do Cliente (CJM) é sobre definir um público-alvo, entender suas necessidades e ambiente, e então criar um plano para oferecer uma experiência envolvente',
  main_17: 'CJM',
  main_18: 'Pontuação de Promoção Líquida',
  main_19:
    'A Pontuação de Promoção Líquida (NPS) é o padrão-ouro das métricas de experiência do cliente, baseada em uma pergunta: Quão provável é que você recomendaria a Organização/Produto/Serviço?',
  main_20: 'NPS',
  main_21: 'Rastreamento da Saúde da Marca',
  main_22:
    'A pesquisa de Rastreamento da Saúde da Marca (BHT) pode medir como sua marca está em termos de conscientização e uso, posicionamento da marca e desempenho da marca',
  main_23: 'BHT',
  main_24: 'Pesquisa de Recursos Humanos',
  main_25:
    'A Pesquisa de Recursos Humanos é usada para avaliar práticas e desempenho de RH, oferecendo uma análise detalhada sobre o desenvolvimento e gestão atuais',
  main_26: 'Pesquisa de RH',
  main_27: 'Índice de satisfação do cliente',
  main_28:
    'O Índice de Satisfação do Cliente (CSI) é uma pontuação que indica o nível de satisfação de seus clientes com os produtos/serviços da empresa.',
  main_29: 'CSI',
  main_30: 'Teste de UX',
  main_31:
    'Teste de Usabilidade (UX) é como as pessoas reais interagem com um site, aplicativo ou outro produto, observando seu comportamento e reações a ele',
  main_32: 'Por que nós',
  main_33:
    'Nossa missão é ajudar empresas a oferecer uma experiência do cliente altamente eficaz',
  main_34: 'Foco na qualidade',
  main_35: 'Somos controlados por qualidade',
  main_36:
    'quando se trata de dados de pesquisa. Tornamos todos os conjuntos de dados disponíveis para os clientes',
  main_37: 'Custo-benefício',
  main_38: 'Nossa experiência, recursos e processos empresariais simplificados',
  main_39: 'permitem que economizemos dinheiro e tempo',
  main_40: 'Soluções inteligentes',
  main_41: 'Usamos uma variedade de técnicas',
  main_42: 'e ferramentas para resolver seus problemas específicos',
  main_43: 'Foco na qualidade',
  main_44: 'Controlados por qualidade',
  main_45:
    'quando se trata de dados de pesquisa. Tornamos todos os conjuntos de dados disponíveis para os clientes',
  main_46: 'Custo-benefício',
  main_47: 'Nossa experiência, recursos e processos empresariais simplificados',
  main_48: 'permitem que economizemos dinheiro e tempo',
  main_49: 'Soluções inteligentes',
  main_50: 'Usamos uma variedade de técnicas',
  main_51: 'e ferramentas para resolver seus problemas específicos',
  main_52: 'Atacado',
  main_53: 'Eletrônicos',
  main_54: 'Luxo/Premium',
  main_55: 'Alimentação e Hospitalidade',
  main_56: 'Varejo',
  main_57: 'Automotivo',
  main_58: 'Moda',
  main_59: 'Bancos',
  main_60: 'Viagem e Lazer',
  main_61: 'Relógios e Joalheria',
  main_62: 'Casa e Eletrodomésticos',
  main_63: 'Blog',
  main_64: 'Acesse o blog para ler mais posts interessantes',
  main_65: 'Auditoria de Varejo',
  main_66:
    'Colete dados detalhados sobre sua loja ou marca, analise as barreiras de crescimento e crie estratégias eficazes para superá-las',
  cjm_1: 'Cliente',
  cjm_2: 'Mapa da jornada',
  cjm_3: 'Entre no mundo dos clientes e compartilhe da sua experiência',
  cjm_4: 'O que é o Mapa da Jornada do Cliente?',
  cjm_5:
    'O MJC fornece um histórico visualizado da interação do consumidor com um produto, serviço, empresa ou marca através de vários canais e durante um determinado período de tempo.',
  cjm_6:
    'Isso permite que as empresas analisem objetivamente a experiência de interação com o cliente, corrijam e eliminem qualquer barreira que surja e ofereçam recomendações para melhorar o produto.',
  cjm_7: 'Determinação do público-alvo, segmentação',
  cjm_8: 'Identificação das etapas de interação com o cliente',
  cjm_9: 'Identificação dos principais canais de interação com o cliente',
  cjm_10: 'Pesquisa de experiência do cliente',
  cjm_11: 'Análise de barreiras e hipóteses sobre otimização',
  cjm_12: 'Execução e teste de hipóteses',
  cjm_13:
    'O design de um Mapa da Jornada do Cliente consiste nos seguintes passos:',
  cjm_14: 'O Mapa da Jornada do Cliente demonstrará:',
  cjm_15:
    'O MJC fornece um histórico visualizado da interação do consumidor com um produto, serviço, empresa ou marca através de vários canais e durante um determinado período de tempo.',
  cjm_16: 'Garantimos trabalho em equipe',
  cjm_17: 'entre analistas e designers',
  cjm_18: 'Oferecemos uma abordagem individual',
  cjm_19: 'para cada cliente',
  cjm_20: 'Podemos aumentar a satisfação geral',
  cjm_21:
    'do cliente com o serviço/produto e, em última análise, aumentar a sua lealdade à empresa',
  cjm_22: 'Garantimos a',
  cjm_23:
    'otimização do caminho do consumidor e levamos em conta os processos empresariais da empresa',
  cjm_24: 'Oferecemos aos nossos clientes',
  cjm_25:
    'a oportunidade de usar abordagens combinadas: CATI, CAWI, grupos focais, entrevistas aprofundadas, entrevistas com especialistas, etnografia, etc.',
  cjm_26: 'Por que escolher-nos?',
  methods_hr_1: 'Pesquisa de Recursos Humanos',
  methods_hr_2:
    'Obtenha insights objetivos sobre sua equipe e descubra como melhorar o desempenho de RH',
  methods_hr_3:
    'Algumas razões pelas quais você pode precisar de uma pesquisa de RH',
  methods_hr_4: 'do pessoal entrevistado',
  methods_hr_5: 'projetos de RH implementados',
  methods_hr_6: 'sessões de treinamento de pessoal realizadas',
  methods_hr_7:
    'Holding líder europeu em pesquisa de experiência do cliente e de empregados',
  methods_hr_8: 'Pesquisas de RH 4service em números',
  methods_hr_9:
    'Para identificar problemas e fraquezas em cada estágio da vida dos empregados de sua empresa: busca/seleção/integração/desenvolvimento de carreira/recertificações',
  methods_hr_10: 'Para saber em que ocasiões são merecidos aumentos e bônus',
  methods_hr_11:
    'Você precisa prever ou fornecer soluções para a insatisfação dos empregados',
  methods_hr_12:
    'Através da pesquisa de RH são possíveis novos e atuais métodos de avaliação de políticas, programas e práticas de RH',
  methods_hr_13: 'Para melhorar a saúde mental dos empregados da empresa',
  methods_hr_14:
    'Se você deseja melhorar a produtividade através de uma abordagem orientada para objetivos',
  methods_hr_15:
    'Se você possui uma equipe internacional e/ou remota e precisa gerenciá-la',
  methods_hr_16:
    'Para entender quão atraente é sua marca empregadora para potenciais empregados',
  methods_hr_17: 'Satisfação do empregado',
  methods_hr_18: 'Pesquisa de Marca Empregadora de RH',
  methods_hr_19: 'Diversidade Cultural',
  methods_hr_20: 'Planos de Compensação e Salários',
  methods_hr_21: 'Engajamento e Lealdade da Equipe',
  methods_hr_22: 'Empregados de Classe Protegida',
  methods_hr_23: 'Áreas de pesquisa',
  methods_hr_24: 'Solução 1',
  methods_hr_25: 'eNPS, Satisfação do Empregado, Lealdade do Empregado',
  methods_hr_26: 'O que medimos:',
  methods_hr_27: 'Lealdade:',
  methods_hr_28:
    'lealdade do empregado, previsão de comportamento futuro, determinação do peso de fatores que afetam a lealdade',
  methods_hr_29: 'Envolvimento:',
  methods_hr_30:
    'envolvimento dos empregados no trabalho, interesse nos resultados e conquistas',
  methods_hr_31: 'Engajamento:',
  methods_hr_32:
    'identificação dos valores dos empregados e valores da empresa',
  methods_hr_33: 'Confiança 360:',
  methods_hr_34:
    'confiança na gestão, colegas, subordinados, confiança nas estratégias e políticas do empregador',
  methods_hr_35: 'Satisfação:',
  methods_hr_36:
    'definição dos fatores únicos para cada empresa, avaliação da satisfação em cada fator-chave que afeta a lealdade',
  methods_hr_37: 'Solução 2',
  methods_hr_38: 'Marca empregadora',
  methods_hr_39: 'Análise interna da marca',
  methods_hr_40:
    'da marca empregadora (pesquisa com pessoal, stakeholders, recrutadores internos e especialistas de RH)',
  methods_hr_41: 'Análise externa da marca empregadora',
  methods_hr_42:
    '(análise do EVP do concorrente, pesquisa com recrutadores externos, pesquisa com candidatos a emprego)',
  methods_hr_43: 'Consciência e reputação da marca',
  methods_hr_44: 'análise',
  methods_hr_45: 'Presença do empregador',
  methods_hr_47: 'Referência do empregador',
  methods_hr_48: 'coleta',
  methods_hr_49: 'Benchmarking competitivo',
  methods_hr_50: 'nos fatores chave como',
  methods_hr_51: 'Salário,',
  methods_hr_52: 'ambiente de trabalho, atmosfera, natureza do trabalho',
  methods_hr_53: 'Análise das necessidades e expectativas',
  methods_hr_54: 'dos candidatos a emprego',
  methods_hr_55: 'Entrevistas aprofundadas,',
  methods_hr_56: 'pesquisa documental e pesquisa quantitativa são utilizadas',
  methods_hr_57: 'Solução 3',
  methods_hr_58: 'Mapa da jornada do empregado/empregado mistério',
  methods_hr_59: 'construindo a jornada do candidato identificando',
  methods_hr_60:
    'as principais etapas da busca, canais de comunicação, critérios de seleção e avaliação dos candidatos',
  methods_hr_61: 'monitoramento de todas as etapas',
  methods_hr_62:
    'e canais de comunicação com candidatos misteriosos (marcação de entrevista, realização de entrevista, estágio na empresa)',
  methods_hr_63: 'Identificação',
  methods_hr_64: 'de pontos fracos nos processos de recrutamento e integração',
  methods_hr_65: 'Recomendações para melhoria,',
  methods_hr_66: 'benchmarking com líderes do setor',
  mystery_shopping_1: 'Mistério',
  mystery_shopping_2: 'Compras',
  mystery_shopping_3: 'Avalie seu serviço com clientes reais',
  mystery_shopping_4:
    'Verifique os padrões, avalie sua equipe e aproxime-se do seu cliente',
  mystery_shopping_5:
    'Somos membro de elite da associação profissional líder de Provedores de Mystery Shopping, MSPA Europe',
  mystery_shopping_6: 'de experiência',
  mystery_shopping_7: 'visitas mensais',
  mystery_shopping_8: 'mystery shoppers no mundo todo',
  mystery_shopping_9: 'Projetos ativos',
  mystery_shopping_10: 'escritórios, sede na Áustria',
  mystery_shopping_11: 'Provedores Europeus de Mystery Shopping',
  mystery_shopping_12: 'classificações de qualidade de serviço',
  mystery_shopping_13: 'Questionário',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: 'Perfil do shopper',
  mystery_shopping_16: 'Validação e objeção',
  mystery_shopping_17: 'Análises',
  mystery_shopping_18:
    'Com esses 5 passos, nossa empresa 4Service garante a qualidade do serviço de mystery shopper',
  mystery_shopping_19: 'Programa',
  mystery_shopping_20:
    'Os 5 elementos do programa de Mystery Shopping da 4Service',
  mystery_shopping_21: 'Questionário:',
  mystery_shopping_22: 'o núcleo do seu programa',
  mystery_shopping_23:
    'Esta parte do programa é visível para todas as partes interessadas',
  mystery_shopping_24: 'Para gerentes superiores.',
  mystery_shopping_25:
    'Mostra todas as etapas importantes e detalhes do processo',
  mystery_shopping_26: 'Para funcionários.',
  mystery_shopping_27: 'Reflete o que os empregadores esperam deles',
  mystery_shopping_28: 'Para mystery shoppers.',
  mystery_shopping_29: 'Claro e estruturado para evitar confusão e viés',
  mystery_shopping_30: 'Software Shopmetrics:',
  mystery_shopping_31: 'amigável e inovador',
  mystery_shopping_32: 'Benefícios:',
  mystery_shopping_33: 'Integra-se',
  mystery_shopping_34: 'com seus processos de negócios e software',
  mystery_shopping_35: 'Baseado no GDPR',
  mystery_shopping_36: 'Kit de Gerenciamento de Privacidade',
  mystery_shopping_37: 'Detalhado',
  mystery_shopping_38: 'Pacote de Conformidade Legal',
  mystery_shopping_39: 'Construtor de relatórios',
  mystery_shopping_40: 'construtor',
  mystery_shopping_41: 'Gerenciamento',
  mystery_shopping_42: 'dos níveis de acesso',
  mystery_shopping_43: 'A maioria dos questionários',
  mystery_shopping_44: 'são concluídos em uma hora',
  mystery_shopping_45: 'Perfil dos shoppers:',
  mystery_shopping_46: '200.000+ mystery shoppers no mundo todo',
  mystery_shopping_47:
    'Critério principal: máxima proximidade com seu público-alvo, com experiência prévia com sua empresa ou concorrentes',
  mystery_shopping_48: 'Treinamento dos shoppers:',
  mystery_shopping_49: 'Teste introdutório de treinamento',
  mystery_shopping_50: 'Chamada via Skype/Telefone',
  mystery_shopping_51: 'Teste de avaliação (opcional)',
  mystery_shopping_52: 'Validação e objeções',
  mystery_shopping_53: 'Validação',
  mystery_shopping_54: 'Sistema',
  mystery_shopping_55: '100% dos relatórios',
  mystery_shopping_56: 'são revisados pela equipe de validação',
  mystery_shopping_57: 'Chamadas adicionais',
  mystery_shopping_58: 'para esclarecimentos',
  mystery_shopping_59: 'Gravações de áudio',
  mystery_shopping_60:
    'após a visita para registrar as questões mais importantes',
  mystery_shopping_61: 'Sistema de prevenção de fraude',
  mystery_shopping_62: 'de funcionários',
  mystery_shopping_63: 'Sistema de objeção',
  mystery_shopping_64: 'Sistema',
  mystery_shopping_65: 'Qualquer avaliação do shopper',
  mystery_shopping_66:
    'pode ser contestada pelo funcionário diretamente no site',
  mystery_shopping_67: 'Cada objeção',
  mystery_shopping_68:
    'é considerada e respondida pela equipe de validação local',
  mystery_shopping_69: 'Você pode ver as estatísticas',
  mystery_shopping_70: 'das objeções',
  mystery_shopping_71: 'Análises.',
  mystery_shopping_72: 'O relatório é apresentado pessoalmente e contém:',
  mystery_shopping_73: 'Pontos de dor e preocupações da empresa',
  mystery_shopping_74: 'e preocupações',
  mystery_shopping_75: 'Dinâmica',
  mystery_shopping_76: 'por divisões e seções',
  mystery_shopping_77: 'Correlação',
  mystery_shopping_78:
    'entre o cumprimento dos padrões e a satisfação do cliente',
  mystery_shopping_79: 'Benchmarking',
  mystery_shopping_80: 'contra concorrentes',
  mystery_shopping_81: 'Recomendações práticas',
  mystery_shopping_82: 'sobre melhorias no serviço',
  mystery_shopping_83: 'O que nos torna diferentes?',
  mystery_shopping_84: 'Realizamos sessões de ideação',
  mystery_shopping_85:
    'Não apenas recomendamos o que fazer, mas, juntos com você, buscamos maneiras práticas de resolver problemas de serviço.',
  mystery_shopping_86:
    'As sessões de ideação são uma ferramenta poderosa que nos ajuda:',
  mystery_shopping_87:
    'A desenvolver direções para a melhoria de produtos ou serviços',
  mystery_shopping_88:
    'Explorar novas oportunidades de receita e estratégias de negócios',
  mystery_shopping_89:
    'Encontrar soluções para desafios complexos centrados no cliente',
  mystery_shopping_90: 'Transformar pontos de dor dos clientes em delícias',
  mystery_shopping_91: 'Realizamos entrevistas em vídeo',
  mystery_shopping_92:
    'Não coletamos apenas questionários - também obtemos comentários ao vivo dos shoppers',
  mystery_shopping_93: 'Fazemos isso para:',
  mystery_shopping_94:
    'Nossos facilitadores conduzirão entrevistas aprofundadas e fornecerão feedback eficaz',
  mystery_shopping_95: 'Ver a experiência da perspectiva do cliente',
  mystery_shopping_96: 'Melhorar sua estratégia de marketing de conteúdo',
  mystery_shopping_97: 'Prever o comportamento do cliente',
  mystery_shopping_98: 'Desenhamos o Mapa de Jornada do Cliente',
  mystery_shopping_99:
    'O Mapeamento da Jornada do Cliente nos ajuda a nos colocar no lugar dos clientes para entender suas necessidades e desafios.',
  mystery_shopping_100: 'Criar uma jornada do cliente é a melhor maneira de:',
  mystery_shopping_101: 'Ver a experiência da perspectiva do cliente',
  mystery_shopping_102: 'Melhorar sua estratégia de marketing de conteúdo',
  mystery_shopping_103: 'Prever o comportamento do cliente',
  mystery_shopping_104: 'Identificar lacunas no serviço ou na comunicação',
  mystery_shopping_105: 'Oferecemos diferentes tipos de Mystery Shopping',
  mystery_shopping_106: 'Tipos de Mystery Shopping',
  mystery_shopping_107: 'Cliente Misterioso por Telefone',
  mystery_shopping_108:
    'Liga para seus gerentes e/ou central de atendimento para determinar a qualidade do serviço prestado por telefone',
  mystery_shopping_109: 'Mystery Shopping de Luxo',
  mystery_shopping_110:
    'Verificação de serviço em locais de luxo por uma categoria especial de clientes misteriosos',
  mystery_shopping_111: 'Visitas à concorrência',
  mystery_shopping_112:
    'A maneira mais fácil de entender como seu concorrente opera',
  mystery_shopping_113: 'Funcionário Misterioso',
  mystery_shopping_114:
    'Ajudará você a entender toda a jornada de seu funcionário (contratação, adaptação, processos internos, etc.)',
  mystery_shopping_115: 'Visitas Motivacionais',
  mystery_shopping_116:
    'Uma ferramenta útil para estimular a oferta de serviços e/ou produtos adicionais, vendas de produtos específicos',
  mystery_shopping_117: 'Cliente Misterioso Online',
  mystery_shopping_118:
    'Simula e mede a experiência do cliente em sua loja online, site ou aplicativo',
  mystery_shopping_119: 'Verificação de Vendas e Promoções',
  mystery_shopping_120:
    'Ajuda a identificar como as promoções são exibidas em todos os locais e a eficácia delas',
  mystery_shopping_121: 'Visitas de compra e devolução',
  mystery_shopping_122:
    'Ajuda a refletir todo o processo, desde a compra até a devolução, para melhorar a experiência do cliente',
  mystery_shopping_123: '> 20 anos',
  nps_1: 'Promoção Líquida',
  nps_2: 'Pontuação',
  nps_3: 'Meça, acompanhe e melhore a lealdade do cliente',
  nps_4: 'Eles respondem com uma pontuação de 0 a 6.',
  nps_5:
    'Eles podem ter tido uma experiência ruim e é improvável que comprem de você novamente, podendo até desencorajar outros a comprarem de você.',
  nps_6: 'Estes são os entrevistados que pontuam entre 7 e 8.',
  nps_7:
    'Eles estão satisfeitos com o seu serviço, mas não felizes o suficiente para serem considerados promotores.',
  nps_8: 'Estes são os entrevistados que avaliam seu negócio como 9 ou 10.',
  nps_9:
    'Isso significa que recomendariam você a um amigo ou colega e, portanto, defendem seu nome.',
  nps_10: 'O que é o NPS?',
  nps_11: 'Mede a percepção do cliente com base em uma pergunta simples:',
  nps_12:
    'Qual é a probabilidade de você recomendar [Organização/Produto/Serviço] a um amigo ou colega?',
  nps_13: 'Minha empresa precisa disso?',
  nps_14: 'O NPS é o seu crescimento sustentável',
  nps_15: 'Compreenda a Dinâmica da Lealdade do Cliente',
  nps_16: 'Motivar sua equipe',
  nps_17: 'Entenda a relação com diferentes produtos',
  nps_18: 'Compare como os clientes tratam você e seus concorrentes',
  nps_19: 'O que posso fazer com o NPS?',
  nps_20: 'Para que tipo de negócio o NPS é relevante?',
  nps_21: 'Métodos de coleta de informações:',
  nps_22: 'Nossas recomendações ao trabalhar com o NPS',
  nps_23:
    'Isso significa que desenvolvemos diretrizes para ajudar a transformar Detratores em promotores',
  nps_24: 'Trabalhamos em um método de "ciclo fechado"',
  nps_25:
    'O NPS é uma pesquisa de acompanhamento em que monitoramos mudanças na lealdade do cliente',
  nps_26: 'Monitoramos a dinâmica',
  nps_27:
    'Oferecemos a avaliação constante dos concorrentes no seu nicho para uma análise mais aprofundada',
  nps_28: 'Avaliação dos concorrentes',
  price_monitoring_1: 'Monitoramento de Preços',
  price_monitoring_2: 'Monitoramento de Varejo',
  price_monitoring_3:
    'Aumente o potencial de vendas e otimize sua política de preços por meio do monitoramento dos preços dos concorrentes',
  price_monitoring_4:
    'A 4Service oferece serviços de monitoramento de preços em todo o mundo há mais de 20 anos',
  price_monitoring_5:
    'Os preços são monitorados em um curto período de tempo por nossa equipe de auditores internos qualificados, permitindo que respondamos rapidamente às mudanças nos preços dos concorrentes',
  price_monitoring_6:
    'Desafios que nossa empresa pode ajudar a resolver com o monitoramento de preços',
  price_monitoring_7: 'Avaliação dos preços dos concorrentes',
  price_monitoring_8: 'e políticas de produtos',
  price_monitoring_9: 'Monitoramento de promoções',
  price_monitoring_10: 'e ofertas especiais',
  price_monitoring_11: 'Monitoramento das condições do mercado',
  price_monitoring_12: 'e do ambiente de mercado',
  price_monitoring_13: 'Verificação e controle dos pontos de venda',
  price_monitoring_14: 'para equipamentos e mercadorias especiais',
  price_monitoring_15: 'Gestão de produtos',
  price_monitoring_16: 'fluxo dentro do comércio',
  price_monitoring_17: 'Otimização de condições',
  price_monitoring_18: 'para melhor colaboração com fornecedores, revendedores',
  price_monitoring_19: 'Medição do espaço em prateleiras,',
  price_monitoring_20:
    'avaliação do posicionamento dos produtos, disponibilidade de estoque',
  price_monitoring_21: 'Indicadores-chave e critérios de monitoramento',
  price_monitoring_22:
    'Todos os dados são compilados em uma interface incluindo:',
  price_monitoring_23: 'nome',
  price_monitoring_24: 'do produto e categoria de marca',
  price_monitoring_25: 'disponibilidade',
  price_monitoring_26: 'de materiais promocionais',
  price_monitoring_27: 'preço',
  price_monitoring_28: 'e disponibilidade de quaisquer ofertas promocionais',
  price_monitoring_29: 'prateleira',
  price_monitoring_30: 'tamanho',
  price_monitoring_31: 'produto',
  price_monitoring_32: 'exibição',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'faceamento',
  price_monitoring_35: 'tipo',
  price_monitoring_36: 'de embalagem',
  price_monitoring_37: 'qualquer outra',
  price_monitoring_38: 'informação geral',
  price_monitoring_39: 'Análise pré-projeto:',
  price_monitoring_40: 'Estudamos',
  price_monitoring_41:
    'a política de preços e de produtos da sua empresa, práticas comerciais e do setor',
  price_monitoring_42: 'Fornecemos',
  price_monitoring_43:
    'treinamento, testes e certificação rápidos para auditores',
  price_monitoring_44: 'Personalizamos',
  price_monitoring_45: 'e configuramos o software',
  price_monitoring_46: 'Trabalho de campo:',
  price_monitoring_47: 'Visitas a lojas de varejo',
  price_monitoring_48: 'pontos de venda',
  price_monitoring_49: 'Relatório:',
  price_monitoring_50: 'Nossa equipe de análise',
  price_monitoring_51:
    'prepara relatórios online nas contas pessoais da empresa',
  price_monitoring_52: 'Fornecemos',
  price_monitoring_53:
    'um relatório fotográfico completo, relatório em PPT, relatório em Excel, por tipo de localidade, nome da cadeia, endereço do ponto de venda',
  price_monitoring_54: 'Como',
  price_monitoring_55: 'funciona',
  price_monitoring_56: 'o tamanho dos descontos individuais',
  price_monitoring_57: 'cálculo de custos',
  price_monitoring_58: 'tamanho dos bônus individuais',
  price_monitoring_59: 'programas de fidelidade',
  price_monitoring_60: 'etc.',
  price_monitoring_61: 'Usamos software especializado:',
  price_monitoring_62:
    'PRADATA, Shopmetrics, e oferecemos suporte técnico abrangente',
  price_monitoring_63: 'Implantação rápida',
  price_monitoring_64: 'e escalonamento (a partir do primeiro dia)',
  price_monitoring_65: 'Todos os dados são confirmados',
  price_monitoring_66: 'por fotos e geolocalização',
  price_monitoring_67: 'Temos o número necessário',
  price_monitoring_68: 'de agentes de campo em qualquer país',
  price_monitoring_69: 'Podemos trabalhar com grandes',
  price_monitoring_70: 'quantidades de dados',
  price_monitoring_71: 'Monitoramento de preços individuais',
  price_monitoring_72:
    'Se você tiver produtos complexos - podemos ajudar a definir sua estratégia de preços',
  price_monitoring_73:
    'Podemos monitorar preços individuais dos concorrentes, por exemplo:',
  price_monitoring_74: 'Baixo',
  price_monitoring_75: 'custo',
  price_monitoring_76: 'Alta',
  price_monitoring_77: 'eficiência',
  price_monitoring_78: 'Relatório em Excel',
  price_monitoring_79: 'Relatório em PPT',
  price_monitoring_80: 'Relatório em BI',
  price_monitoring_81:
    'Dados de preços dos concorrentes ajudam você a tomar as melhores decisões de preços',
  price_monitoring_82:
    'Fornecemos um relatório fotográfico completo, relatório em PPT, relatório em Excel, por tipo de localidade',
  qualitative_research_1: 'Qualitativo',
  qualitative_research_2: 'pesquisas',
  qualitative_research_3:
    'Coletar, analisar e interpretar dados através da observação das ações e palavras dos seus clientes',
  qualitative_research_4: 'Valores',
  qualitative_research_5: 'Comportamento',
  qualitative_research_6: 'Antecedentes',
  qualitative_research_7: 'Expectativas',
  qualitative_research_8: 'Ambiente',
  qualitative_research_9: 'Medos/Barreiras',
  qualitative_research_10: 'Qual é o propósito da escolha do cliente?',
  qualitative_research_11: 'Homem com telefone',
  qualitative_research_12: 'marca',
  qualitative_research_13: 'categoria',
  qualitative_research_14: 'publicidade',
  qualitative_research_15: 'produto',
  qualitative_research_16:
    'A singularidade dos métodos qualitativos é que eles permitem entender as causas e motivações do comportamento do consumidor',
  qualitative_research_17:
    'Análise da atitude do consumidor em relação a um fenômeno particular',
  qualitative_research_18: 'Perspectivas e experiências individuais',
  qualitative_research_19:
    'Tópicos que não podem ser investigados através de pesquisas',
  qualitative_research_20: 'Tópicos sensíveis',
  qualitative_research_21: 'Audiência difícil de alcançar',
  qualitative_research_22: 'Geografia complexa',
  qualitative_research_23: 'Entrevistas em profundidade úteis para:',
  qualitative_research_24: 'Entrevistas em profundidade',
  qualitative_research_25:
    'Uma entrevista em profundidade é uma técnica de pesquisa qualitativa que envolve a realização de entrevistas individuais com os respondentes para explorar suas perspectivas sobre uma ideia, programa ou situação específica. Uma entrevista em profundidade explora experiências/percepções/práticas individuais em detalhes.',
  qualitative_research_26: 'Objetivos:',
  qualitative_research_27: 'Caracterizar normas sociais e culturais',
  qualitative_research_28: 'Audiência difícil de alcançar',
  qualitative_research_29:
    'Compartilhamento e comparação (Morgan) investigado através de pesquisas',
  qualitative_research_30:
    'Tópicos que não podem ser investigados através de pesquisas',
  qualitative_research_31: 'Grupos focais em profundidade úteis para:',
  qualitative_research_32: 'Grupos focais',
  qualitative_research_33:
    'Exploração profunda de reuniões individuais, discussões emergentes, discussões entre manifestações sob a influência do moderador do grupo.',
  qualitative_research_34:
    'Geração de insights sobre experiências compartilhadas e normas sociais através de discussões em grupo, dados narrativos em uma discussão focada.',
  qualitative_research_36: 'Benefícios:',
  qualitative_research_37:
    'Estudo do estilo de vida e comportamento do consumidor em diversas condições reais (em casa, no ponto de venda, no trabalho, etc.)',
  qualitative_research_38:
    'Busca de insights para o desenvolvimento de novos produtos, embalagens, melhoria de serviços, etc.',
  qualitative_research_39: 'Pesquisa etnográfica em profundidade útil para:',
  qualitative_research_40: 'Pesquisa etnográfica',
  qualitative_research_41:
    'A etnografia é um tipo de pesquisa qualitativa que envolve imergir-se em uma comunidade ou organização específica para observar de perto o comportamento e as interações.',
  qualitative_research_42:
    'A etnografia é um método de pesquisa flexível que permite uma compreensão profunda da cultura compartilhada, convenções e dinâmica social de um grupo. No entanto, também envolve alguns desafios práticos e éticos.',
  qualitative_research_43: 'Métodos:',
  qualitative_research_44:
    'A etnografia é um estudo do consumidor em condições reais e interação com um produto/serviço',
  qualitative_research_45: 'Benefícios de trabalhar conosco',
  qualitative_research_46:
    'Podemos encontrar entrevistados de qualquer público-alvo. E controlamos a qualidade do recrutamento',
  qualitative_research_47: 'Recrutamento de qualquer tipo de complexidade',
  qualitative_research_48:
    'Nossos moderadores são multilíngues e possuem habilidades de facilitação',
  qualitative_research_49: 'Moderadores especialistas',
  qualitative_research_50: 'Trabalho online/offline',
  qualitative_research_51: 'Fornecemos gravações de áudio/vídeo',
  qualitative_research_52: 'gravações',
  qualitative_research_53:
    'Podemos realizar pesquisas qualitativas em qualquer localidade',
  quantitative_research_1: 'Estudo da satisfação do cliente',
  quantitative_research_2: 'com um produto ou serviço específico (SCI, NPS)',
  quantitative_research_3: 'Realização de segmentação',
  quantitative_research_4: 'pesquisas de diferentes mercados',
  quantitative_research_5: 'Pesquisa de mercado',
  quantitative_research_6: 'Gestão de NPS',
  quantitative_research_7: 'Análise de concorrentes',
  quantitative_research_8: 'Quantitativo',
  quantitative_research_9: 'pesquisas',
  quantitative_research_10:
    'Metodologia de pesquisa que testa teorias sobre atitudes e comportamentos das pessoas com base em dados objetivos, numéricos e estatísticos',
  quantitative_research_11: 'anos',
  quantitative_research_12: 'de experiência em CX globalmente',
  quantitative_research_13: 'painel',
  quantitative_research_14: 'de respondentes',
  quantitative_research_15: 'países',
  quantitative_research_16: 'executamos projetos em todo o mundo',
  quantitative_research_17: 'Quem somos',
  quantitative_research_18:
    '4Service é um holding global de pesquisa de mercado e CX. Oferecemos serviços especializados de coleta de dados para uma grande variedade de empresas',
  quantitative_research_19: '4Service em números',
  quantitative_research_20: 'Entrevista Assistida por Computador',
  quantitative_research_21: 'Entrevista pela Web',
  quantitative_research_22:
    'Uma técnica de questionário baseada na Internet. O respondente preenche um questionário eletrônico sem a ajuda de um entrevistador. O questionário pode ser enviado eletronicamente ou postado no site.',
  quantitative_research_23: 'Entrevista Assistida por Computador pela Web',
  quantitative_research_24: 'Na maioria dos casos',
  quantitative_research_25:
    'esse método é o mais econômico em termos de custo de material e tempo',
  quantitative_research_26: 'Essa pesquisa oferece',
  quantitative_research_27:
    'uma oportunidade de entrevistar uma audiência difícil de alcançar',
  quantitative_research_28: 'Ela oferece amplas possibilidades',
  quantitative_research_29:
    'para demonstração de material em vídeo e áudio, bem como imagens',
  quantitative_research_30: 'Os respondentes podem estar',
  quantitative_research_31: 'localizados em qualquer lugar do mundo',
  quantitative_research_32: 'Permite investigar',
  quantitative_research_33:
    'o comportamento específico da audiência da Internet. Os principais resultados podem ser acessados pelo cliente em tempo real via interface web',
  quantitative_research_34:
    'Elegível para qualquer tópico ou questão confidencial,',
  quantitative_research_35:
    'sensível ou pessoal que os respondentes poderiam ter relutância em responder ao conversar com um entrevistador',
  quantitative_research_36: '150 000+ painel',
  quantitative_research_37:
    'Temos nosso próprio painel de respondentes em todo o mundo',
  quantitative_research_38: 'Trabalhamos com consultas desafiadoras',
  quantitative_research_39:
    'Utilizando ferramentas complementares (mídias sociais, publicidade), podemos encontrar um público específico e um perfil sofisticado do respondente',
  quantitative_research_40: 'Motivamos nossos respondentes',
  quantitative_research_41:
    'Desenvolvemos um framework para incentivar a conclusão de longas pesquisas oferecendo incentivos materiais',
  quantitative_research_42: 'Entrevistas presenciais',
  quantitative_research_43: 'realizadas com o uso de tablet',
  quantitative_research_44:
    'Este é um dos principais métodos de coleta de dados quantitativos, onde o entrevistador se comunica diretamente com o respondente em um questionário estritamente estruturado.',
  quantitative_research_45:
    'As entrevistas presenciais podem ser realizadas no local de trabalho ou residência do respondente, na rua ou em locais de venda designados usando um tablet',
  quantitative_research_46: 'Entrevistas presenciais realizadas com tablet',
  quantitative_research_47: 'Determinando o nível',
  quantitative_research_48: 'de popularidade e reconhecimento da marca',
  quantitative_research_49: 'Segmentação do consumidor',
  quantitative_research_50: 'com base em seu comportamento de compra',
  quantitative_research_51: 'Analisando a eficácia',
  quantitative_research_52: 'da campanha publicitária',
  quantitative_research_53: 'Medição da satisfação do cliente',
  quantitative_research_54: 'medição',
  quantitative_research_55: 'Determinando o melhor preço',
  quantitative_research_56: 'para o produto ou serviço',
  quantitative_research_57: 'Análise',
  quantitative_research_58: 'das preferências do consumidor',
  quantitative_research_59: 'Estudo',
  quantitative_research_60: 'de como os consumidores compram',
  quantitative_research_61: 'Avaliação',
  quantitative_research_62: 'da capacidade de mercado',
  quantitative_research_63: 'Software DigSee',
  quantitative_research_64:
    'Usamos software especial com gravação de áudio e GPS',
  quantitative_research_65: 'Entrevistadores e supervisores',
  quantitative_research_66:
    'podemos conduzir entrevistas em qualquer idioma e traduzir para o idioma preferido do cliente',
  quantitative_research_67: 'Amostragem',
  quantitative_research_68:
    'O departamento de análise desenvolve um sistema de recrutamento de respondentes para obter o melhor resultado possível',
  quantitative_research_69: 'Entrevista Assistida por Computador',
  quantitative_research_70: 'Entrevista por Telefone',
  quantitative_research_71:
    'Metodologia para coleta de informações quantitativas através de entrevistas telefônicas usando um questionário claramente estruturado.',
  quantitative_research_72: 'Entrevista Assistida por Computador por Telefone',
  quantitative_research_73: 'Temos nossos próprios call centers',
  quantitative_research_74:
    'Usamos software especial com gravação de áudio e GPS',
  quantitative_research_75: 'Operadores qualificados',
  quantitative_research_76:
    'Nossos operadores falam diferentes idiomas, são treinados, bem testados e continuamente aprimoram suas habilidades',
  quantitative_research_77: 'Bancos de dados',
  quantitative_research_78:
    'Trabalhamos com nossos próprios bancos de dados e com suas bases de clientes também',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Garantimos qualidade e assinamos um SLA (Acordo de Nível de Serviço)',
  quantitative_research_81: 'Definição de objetivos',
  quantitative_research_82: 'Determinação da metodologia',
  quantitative_research_83: 'Segmentação e amostragem',
  quantitative_research_84: 'Definição dos canais de coleta de dados',
  quantitative_research_85:
    'Criação de um questionário que seja lógico para o respondente',
  quantitative_research_86: 'Processo de coleta de dados',
  quantitative_research_87: 'Garantia de qualidade',
  quantitative_research_88: 'Técnicas de análise',
  quantitative_research_89: 'Processamento de dados',
  quantitative_research_90: 'Design de pesquisa',
  quantitative_research_91:
    'Rastreamento de localização GPS dos entrevistadores',
  quantitative_research_92: 'Verificação de dados',
  quantitative_research_93: 'Programação e teste do questionário',
  quantitative_research_94: 'Validação de dados',
  quantitative_research_95: 'Gravações de áudio seguindo os requisitos do GDPR',
  quantitative_research_96: 'Treinamento e competência da equipe de pesquisa',
  quantitative_research_97: 'Garantia de Qualidade',
  quantitative_research_98: 'Análise fatorial',
  quantitative_research_99: 'Segmentação',
  quantitative_research_100: 'Mapeamento perceptual',
  quantitative_research_101: 'Correlação',
  quantitative_research_102: 'Modelo de conversão',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Análise de cluster',
  quantitative_research_105: 'etc.',
  quantitative_research_106: 'Relatórios Analíticos',
  quantitative_research_107:
    'Usamos SPSS® e outras ferramentas para criar relatórios analíticos utilizando diferentes metodologias',
  quantitative_research_108: 'Quais desafios de negócios podem ser resolvidos:',
  quantitative_research_109: 'Quais desafios de negócios podem ser resolvidos:',
  social_responsibility_1: 'Responsabilidade Social Corporativa 4Service',
  social_responsibility_2:
    'Do coração e alma de cada membro de nossa equipe. Estamos tentando manter uma centelha de bondade em cada um de nossos funcionários e tornar o mundo ao nosso redor um pouco melhor',
  social_responsibility_3: 'Atividades educacionais / Apoio à juventude',
  social_responsibility_4:
    'Nossas iniciativas de extensão para crianças e estudantes',
  social_responsibility_5: 'Jogo empresarial Service Mania',
  social_responsibility_6:
    'Uma parte importante das nossas atividades de treinamento é o jogo empresarial Service Mania.',
  social_responsibility_7:
    'O Service Mania desafia você a pensar estrategicamente sobre como lidar com situações incomuns.',
  social_responsibility_8: 'Apresentação de pesquisa / Diiya. Negócios',
  social_responsibility_9:
    'Uma perspectiva empresarial e do cliente sobre a situação de serviço: uma apresentação dos resultados da nossa pesquisa 4Service por Victoria Skorbota, Chefe de Desenvolvimento na 4Service Ucrânia',
  social_responsibility_10: 'Sessões de Design de Serviço',
  social_responsibility_11:
    'A metodologia de Design de Serviço é uma parte importante da 4Service. Há alguns anos, nos tornamos parceiros da DesignThinkers Academy e não só usamos as melhores práticas em nosso trabalho, como também oferecemos treinamentos para as melhores empresas',
  social_responsibility_12: 'Caridade / Voluntariado',
  social_responsibility_13:
    'Orfanatos, casas de repouso para idosos e voluntariado para o exército ucraniano. A parte mais tocante de nossas atividades',
  social_responsibility_14: '#AjudaFácilComGrupo4Service',
  social_responsibility_15:
    'Trabalhamos em parceria com o Fundo de Caridade Blagomai e tentamos levar celebração e ajuda àqueles que estão em extrema necessidade',
  social_responsibility_16:
    'Arrecadação de fundos para orfanatos e casas de repouso',
  social_responsibility_17:
    'Nós, da 4Service, iniciamos várias campanhas para arrecadar fundos para casas de repouso e orfanatos para comprar itens básicos',
  social_responsibility_18: '#SalveUcrânia',
  social_responsibility_19:
    'A guerra afetou muitos de nossos funcionários e amigos próximos. Cada um de nós ajuda tanto quanto pode',
  social_responsibility_20: 'Bem-estar animal',
  social_responsibility_21:
    'Qualquer pessoa pode vir ao nosso escritório com seu animal de estimação. Ajudamos abrigos, arrecadamos fundos para alimentos e, às vezes, também procuramos lares para animais sem-teto',
  social_responsibility_22: 'Apoio a abrigos de animais',
  social_responsibility_23:
    'Animais desabrigados precisam de ajuda tanto quanto todos os membros vulneráveis da nossa sociedade.',
  social_responsibility_24:
    'Nossa empresa assumiu o abrigo de animais Sirius (região de Kyiv, Ucrânia) sob nossos cuidados.',
  social_responsibility_25: 'Iniciativas criativas para ajudar animais de rua',
  social_responsibility_26:
    'Como podemos trazer a questão urgente dos abrigos de animais à atenção de nossos clientes e parceiros?',
  social_responsibility_27: 'Escritório amigável para animais',
  social_responsibility_28:
    'Muitos de nossos membros da equipe têm animais de estimação. Criamos um ambiente saudável onde todos podem vir ao escritório com seus animais e se sentir confortáveis',
  ux_testing_1: 'Testes de UX',
  ux_testing_1_1: 'Testes de UX',
  ux_testing_2:
    'Abordagem baseada em humanos e IA para testar e melhorar seus sites e aplicativos',
  ux_testing_3: 'O que é teste de UX',
  ux_testing_4:
    'O teste de UX ajuda você a entender como as pessoas interagem com seu produto, aplicativo ou site',
  ux_testing_5: 'A navegação não está muito clara',
  ux_testing_6: 'Não tenho ideia de como fazer um pedido',
  ux_testing_7: 'Não há fotos suficientes no catálogo',
  ux_testing_8: 'Você irá:',
  ux_testing_9: 'Descobrir oportunidades de melhoria',
  ux_testing_10: 'Identificar problemas no design e na interação do cliente',
  ux_testing_11:
    'Descobrir as dificuldades que o usuário encontra ao interagir com o site',
  ux_testing_12: 'Observar a jornada do cliente',
  ux_testing_13: 'Reconhecer quando e por que seu público-alvo deixa o site',
  ux_testing_14:
    'Definir quais seções despertam o menor e o maior interesse e emoção',
  ux_testing_15: 'Teste de UX baseado em usuário',
  ux_testing_16:
    'A 4Service fornece insights humanos reais para criar sites e aplicativos centrados no cliente, usando métodos qualitativos e quantitativos',
  ux_testing_17: 'Não entendo como usar o site. Onde o menu está escondido?',
  ux_testing_18: 'Acho que há muito espaço entre as letras, o texto é ilegível',
  ux_testing_19: 'As imagens se sobrepõem. É assim que deve ser?',
  ux_testing_20:
    'O teste de UX ajuda você a entender como as pessoas interagem com seu produto, aplicativo ou site',
  ux_testing_21: 'Design de pesquisa de teste de UX baseado em usuário:',
  ux_testing_22: 'Descobrir oportunidades de melhoria',
  ux_testing_23:
    'Determinação do tipo e método de teste (qualitativo/quantitativo)',
  ux_testing_24: 'Elaboração de hipóteses e cenários de usuário',
  ux_testing_25: 'Análise e relatório dos resultados do teste',
  ux_testing_26: 'Duas soluções',
  ux_testing_27: 'Oferecemos duas abordagens para testes de UX bem-sucedidos',
  ux_testing_28: 'Teste de UX baseado em usuário',
  ux_testing_29: 'Teste de UX baseado em IA + humano',
  ux_testing_30: 'Nossos pontos fortes:',
  ux_testing_31: 'Podemos analisar o panorama competitivo',
  ux_testing_32:
    'Podemos trazer especialistas específicos para conduzir e participar de entrevistas',
  ux_testing_33:
    'Podemos conduzir entrevistas em diferentes idiomas e traduzi-las para o idioma necessário',
  ux_testing_34: 'com usabilidade existente',
  ux_testing_35: 'métricas ou padrões',
  ux_testing_36: 'sua performance',
  ux_testing_37: 'em relação a um concorrente',
  ux_testing_38: 'versões',
  ux_testing_39: 'do mesmo produto',
  ux_testing_40: 'Classificação de satisfação',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Taxas de sucesso',
  ux_testing_43: 'Taxa de erro',
  ux_testing_44: 'Tempo de conclusão de tarefas',
  ux_testing_45: 'Teste de UX baseado em usuário',
  ux_testing_46: 'Método quantitativo',
  ux_testing_47:
    'Usamos pelo menos 100 pessoas para participar do teste de UX nesta etapa',
  ux_testing_48:
    'Relevante quando você precisa de uma comparação do seu site ou aplicativo:',
  ux_testing_49: 'Medição do método quantitativo:',
  ux_testing_50: 'Observação',
  ux_testing_51:
    'Passar tempo com um usuário ou grupo de usuários e observar seu comportamento com o produto em seu uso diário',
  ux_testing_52: 'Entrevista aprofundada',
  ux_testing_53:
    'Permite conhecer as atitudes, crenças, desejos e experiências dos usuários que acessam seu site. Recomendamos realizar 15-20 entrevistas',
  ux_testing_54: 'Pesquisa etnográfica',
  ux_testing_55:
    'Realizada no ambiente dos entrevistados onde utilizarão o produto. Ao observar, você começa a entender a psicologia do usuário, os desafios emocionais que enfrentam.',
  ux_testing_56: 'Teste de UX baseado em usuário',
  ux_testing_57: 'Método qualitativo',
  ux_testing_58:
    'Este método ajuda a entender as motivações e lógica do comportamento dos usuários',
  ux_testing_59: 'Usamos:',
  ux_testing_60:
    'Estamos desenvolvendo um mapa de jornada do cliente para ilustrar os resultados de nossa pesquisa',
  ux_testing_61: '30 participantes (masculino/feminino)',
  ux_testing_62:
    'Pré-seleção de entrevistados e convite para participar da pesquisa',
  ux_testing_63: 'Entrevistas online remotas na plataforma Wantent',
  ux_testing_64: 'Baseado em IA + humano',
  ux_testing_65: 'Teste de UX baseado em IA + humano',
  ux_testing_66:
    'Uma combinação da tecnologia exclusiva de inteligência artificial Wantent e usuários reais',
  ux_testing_67: 'A fonte grande no título chamou a atenção',
  ux_testing_68:
    "Os usuários olham para os números 'altissonantes', mas não leem as informações ao lado deles",
  ux_testing_69:
    'O teste de UX ajuda você a entender como as pessoas interagem com seu produto, aplicativo ou site',
  ux_testing_70: 'Design de pesquisa de teste baseado em IA + humano',
  ux_testing_71: 'Como funciona',
  ux_testing_72:
    'Wantent é uma solução baseada em IA para adequação de conteúdo ao mercado.',
  ux_testing_73:
    'Wantent estima a eficiência de sites e aplicativos aplicando tecnologias de ML para analisar as reações emocionais e o engajamento da audiência.',
  ux_testing_74:
    'Para interpretar os resultados, usamos técnicas de storytelling, diversidade e empatia para impulsionar o desempenho e o crescimento.',
  ux_testing_75:
    'Atenção e emoções do usuário durante as transições entre seções',
  ux_testing_76:
    'Estatísticas por grupo de participantes (atenção e foco ao longo da sessão)',
  ux_testing_77: 'Análise das conversões de página',
  ux_testing_78: 'Atenção e emoções durante a realização de tarefas',
  ux_testing_79: 'Constatações sobre a percepção',
  ux_testing_80: 'do site por diferentes grupos de participantes',
  ux_testing_81: 'Análise da facilidade',
  ux_testing_82:
    'de navegação pelas páginas e facilidade de encontrar elementos importantes',
  ux_testing_83: 'Uma análise da percepção',
  ux_testing_84: 'das informações fornecidas no site',
  ux_testing_85: 'Definição de áreas',
  ux_testing_86:
    'de melhoria em páginas individuais (navegação, blocos de informações, etc.)',
  ux_testing_87: 'Analisamos em detalhes',
  ux_testing_88: 'Principais resultados da pesquisa',
  ux_testing_89:
    'Recomendações para melhorar a estrutura do site e das páginas individuais',
  ux_testing_90:
    'Selecionamos cuidadosamente o público-alvo para o Teste de Usabilidade.',
  ux_testing_91:
    'Conduzimos pesquisas em diferentes países, idiomas e entre diferentes grupos de usuários',
  ux_testing_92: 'Podemos testar em aplicativos',
  ux_testing_93: 'e nos sites de concorrentes e fornecer análise comparativa',
  ux_testing_94: 'Combinamos diferentes métodos de pesquisa.',
  ux_testing_95:
    'Nossos facilitadores qualificados orientam o participante no processo de teste.',
  ux_testing_96: 'Conduzimos testes',
  ux_testing_97: 'em uma variedade de dispositivos e versões de software',
  ux_testing_98: 'Por que escolher-nos?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Um projeto exclusivo de parceria entre 4Service e Wantent © Uma combinação de inteligência artificial e uma abordagem humana para análise de conteúdo',
  wantent_4: 'medindo o sucesso antes que o conteúdo seja exibido',
  wantent_5: 'mitigando os riscos de problemas de diversidade',
  wantent_6: 'percepções profundas e recomendações para melhorar o conteúdo',
  wantent_7: 'decisões objetivas sobre estratégia de conteúdo e marketing',
  wantent_8:
    'Wantent é uma solução baseada em IA para adequação de conteúdo ao mercado.',
  wantent_9:
    'Wantent é uma solução baseada em inteligência artificial para ajustar o conteúdo ao mercado. Wantent avalia a eficácia do conteúdo de vídeo aplicando tecnologias de ML para analisar a resposta emocional e o engajamento do público.',
  wantent_10:
    'Wantent ajuda a definir a adequação de conteúdo ao mercado e maximizar a eficiência do conteúdo por meio de',
  wantent_11: 'Configuração de cenário e recrutamento de participantes',
  wantent_12: 'design de projeto personalizado e público mundial',
  wantent_13: 'Coleta das reações dos espectadores',
  wantent_14: 'feedback detalhado e claro do público',
  wantent_15: 'Análise da percepção do conteúdo',
  wantent_16: 'análise do comportamento e reação emocional pela IA',
  wantent_17: 'Entrega de insights para conclusão final',
  wantent_18: 'recomendações para melhorias',
  wantent_19: 'Wantent maximiza a eficiência do seu conteúdo',
  wantent_20: 'Pré-teste de conceito de anúncio',
  wantent_21: 'Teste de conceito criativo',
  wantent_22:
    'Verificação da consistência do futuro conceito criativo com a estratégia desenvolvida e entendimento das reações do público-alvo para possíveis ajustes nos conceitos/mensagens criativas',
  wantent_23: 'Teste de visibilidade dos elementos da marca (pós-produção)',
  wantent_24:
    'Confirmação da eficácia do investimento em publicidade/patrocínio',
  wantent_25: 'Teste de anúncio',
  wantent_26: 'Teste A/B',
  wantent_27:
    'Comparação da eficácia dos criativos e aumento do engajamento e atenção durante a fase de produção',
  wantent_28: 'Teste de anúncio lançado',
  wantent_29:
    'Definição do desempenho das mensagens e criação de recomendações para melhorar áudio e visuais',
  wantent_30: 'Soluções para TV',
  wantent_31:
    'Pilotos, promoções, novos formatos, teste de apresentadores de TV',
  wantent_32:
    'Realização de análise comparativa para identificar o mais envolvente e fortalecê-lo',
  wantent_33: 'Teste de programa de TV e longa-metragem',
  wantent_34:
    'Análise do envolvimento, nível de atenção e resposta emocional dos espectadores durante a visualização de conteúdos longos em condições naturais. Encontramos automaticamente os picos de queda da audiência e momentos de distração para avaliar e melhorar a edição do vídeo',
  wantent_35: 'Teste de UI/UX',
  wantent_36: 'Teste de protótipos de sites e aplicativos móveis',
  wantent_37:
    'Análise da reação e emoções do usuário usando a tecnologia Wantent:',
  wantent_38: 'nível de atenção',
  wantent_39: 'reações emocionais',
  wantent_40: 'mapas de calor da direção do olhar dos participantes',
  wantent_41:
    'Análise de usabilidade do site e aplicativo com base no feedback dos participantes:',
  wantent_42: 'Escala de usabilidade do sistema (SUS)',
  wantent_43: 'Pontuação de Esforço do Cliente (Facilidade de Interação)',
  wantent_44: 'Pontuação de Promoção Neta (NPS)',
  wantent_45: 'Conformidade com GDPR e privacidade do usuário',
  wantent_46:
    'Wantent está em total conformidade com as políticas de GDPR e CCPA e atende aos princípios de coleta, armazenamento, processamento e proteção dos dados pessoais dos participantes',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'CDO, Reface (Mídia e Entretenimento)',
  wantent_49:
    'Experimentamos várias novas ideias de conteúdo com tecnologias de aprendizado de máquina todos os dias. Wantent nos ajuda a entender e avaliar riscos e vantagens, descobrir feedbacks, comportamento e percepção dos usuários para entender a causalidade de diferentes aspectos das nossas soluções.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'co-proprietário TABASCO (Publicidade)',
  wantent_52:
    'Wantent fornece um serviço verdadeiramente único que nos ajuda a conhecer os mínimos detalhes do que os consumidores gostam e não gostam. Não há como enganar, não há como mentir - Wantent sempre sabe a verdade, e assim, nós como especialistas em marketing, recebemos diretrizes inestimáveis (haha - conhecemos o preço exato e é acessível) sobre como melhorar nossas comunicações.',
  wantent_53: 'Avaliações',
  terms_1: 'Termos e condições',
  terms_2: 'Acordo de Termos de Uso',
  terms_3:
    'Leia este Acordo de Termos de Uso (“termos de uso”, “acordo”) cuidadosamente antes de usar o site',
  terms_4:
    '(“site”) operado pela 4Service Holdings GmbH (“4Service”, “nós”, “nosso”).',
  terms_5:
    'Condições de usoAo usar este site, você certifica que leu e revisou este Acordo e que concorda em cumprir seus termos. Se você não deseja estar vinculado aos termos deste Acordo, recomendamos sair do site. A 4Service concede o uso e o acesso a este site, seus produtos e serviços apenas para aqueles que aceitaram seus termos.',
  terms_6: 'Política de Privacidade',
  terms_7:
    'Antes de continuar a usar nosso site, aconselhamos que você leia nossa Política de Privacidade sobre a coleta de dados dos usuários. Isso ajudará a entender melhor nossas práticas.',
  terms_8: 'Restrição de idade',
  terms_9:
    'Você deve ter pelo menos 16 (dezesseis) anos de idade para usar este site. Ao usar este site, você garante que tem pelo menos 16 (dezesseis) anos e que pode legalmente aderir a este Acordo. A 4Service não assume qualquer responsabilidade por responsabilidades relacionadas à má representação de idade.',
  terms_10: 'Propriedade intelectual',
  terms_11:
    'Você concorda que todos os materiais, produtos e serviços fornecidos neste site são propriedade da 4Service, suas afiliadas, diretores, funcionários, agentes, fornecedores ou licenciadores, incluindo todos os direitos autorais, segredos comerciais, marcas registradas, patentes e outras propriedades intelectuais. Você também concorda que não reproduzirá ou redistribuirá a propriedade intelectual da 4Service de qualquer forma, incluindo registros de novas marcas comerciais eletrônicos, digitais ou.',
  terms_12: 'Acesso ao nosso site',
  terms_13:
    'O acesso ao nosso Site é permitido temporariamente, e reservamos o direito de retirar ou alterar o serviço que fornecemos em nosso site sem aviso prévio. Não seremos responsáveis se, por qualquer motivo, nosso site estiver indisponível a qualquer momento ou por qualquer período. De tempos em tempos, podemos restringir o acesso a algumas partes de nosso site, ou a todo o site, para usuários registrados. Você é responsável por fazer todos os arranjos necessários para ter acesso ao nosso site, incluindo o uso de equipamentos compatíveis com nosso site. Você também é responsável por garantir que todas as pessoas que acessam nosso site através de sua conexão com a Internet estejam cientes desses termos e os cumpram.',
  terms_14: 'Lei aplicável',
  terms_15:
    'Ao visitar este site, você concorda que as leis da República da Áustria, sem consideração aos princípios de conflito de leis, regerão estes termos e condições, ou qualquer disputa de qualquer natureza que possa surgir entre 4Service e você.',
  terms_16: 'Disputas',
  terms_17:
    'Qualquer disputa relacionada de alguma forma à sua visita a este site será arbitrada pelo Tribunal Comercial de Viena.',
  terms_18: 'Indenização',
  terms_19:
    'Você concorda em indenizar a 4Service e suas afiliadas e isentar a 4Service de reclamações e demandas legais que possam surgir do seu uso ou mau uso de nosso site. Reservamos o direito de selecionar nosso próprio advogado.',
  terms_20: 'O período de armazenamento',
  terms_21:
    'Considerando os propósitos de processamento, o período de armazenamento dos dados pessoais dos Usuários (período de armazenamento) é de 24 meses a partir da data em que o consentimento de processamento de dados é devidamente obtido de você.',
  terms_22: 'Limitação de responsabilidade',
  terms_23:
    '4Service não é responsável por quaisquer danos que possam ocorrer a você como resultado de seu uso indevido de nosso site. A 4Service reserva-se o direito, sem aviso prévio, de editar, modificar e alterar este Acordo a qualquer momento, atualizando esta postagem. Seu uso contínuo do site agora, ou após a postagem de quaisquer alterações ou modificações, indicará sua aceitação de tais alterações ou modificações. Se qualquer parte deste acordo for declarada ilegal, nula ou inexequível, essa parte será considerada separável e não afetará a validade e exequibilidade de quaisquer disposições restantes. Possíveis evidências de uso do site para fins ilegais serão fornecidas às autoridades policiais. Este Acordo é um entendimento entre a 4Service e o usuário.',
  terms_24:
    'Por favor, encaminhe todas as dúvidas e preocupações relacionadas à privacidade/uso para o seguinte endereço:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Viena',
  terms_28: 'Áustria',
  terms_29: 'Revisão',
  terms_30:
    'Quando os dados pessoais que você nos fornece não estão incorretos, você tem o direito de nos solicitar a correção (Artigo 16 do RGPD).',
  thank_you_1: 'Obrigado!',
  thank_you_2: 'Entraremos em contato com você dentro de 24 horas',
  thank_you_3:
    'P.S. Nossos gerentes já estão revisando sua solicitação para oferecer uma proposta melhor',
  thank_you_4: 'Menu',
  thank_you_5: 'Mulher digitando',
  privacy_policy_1:
    'Aviso de privacidade para titulares de dados (usuários do site)',
  privacy_policy_2:
    'Este Aviso de Privacidade (Aviso de Privacidade) destina-se a pessoas físicas (Titulares de Dados) cujos dados pessoais são coletados pela 4Service Holdings GmbH, em conformidade com os requisitos do RGPD no âmbito da visita ao site.',
  privacy_policy_3:
    '(doravante, Site e Usuários do Site/Usuários, respectivamente).',
  privacy_policy_4:
    'O Aviso de Privacidade para Titulares de Dados (Usuários do Site) da 4Service Holdings GmbH é aplicado em todas as empresas do Grupo 4Service, sob a autoridade legal, supervisão ou controle da 4Service Holdings GmbH.',
  privacy_policy_5: 'Contatos',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Empresa/nós/nosso)',
  privacy_policy_8: 'Endereço:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Viena, Áustria.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Quem somos nós?',
  privacy_policy_13:
    'Um indivíduo (Você) que visita o Site para qualquer propósito. Para fins deste Documento, Você também será identificado como Usuário.',
  privacy_policy_14: 'Dados pessoais que coletamos de você',
  privacy_policy_15:
    'Quando você acessa nosso Site, enviamos a você um aviso de privacidade, no qual pedimos que forneça seu consentimento para o processamento das seguintes informações:',
  privacy_policy_16:
    'Endereço IP, nome, sobrenome, endereço, número de telefone (fixo ou móvel), endereço de e-mail, endereço, nome da empresa, país, e-mail, primeiro nome, último nome, número de telefone, província, estado e código postal, dados de uso, dados sobre interação com redes sociais ou plataformas externas, informações sobre registro e autenticação no site',
  privacy_policy_17: 'posição geográfica.',
  privacy_policy_18:
    'Caso você manifeste sua vontade e nos dê tal consentimento, começamos a processar essas informações de você.',
  privacy_policy_19: 'Licitude (consentimento)',
  privacy_policy_20:
    'Ao acessar nosso Site, enviamos a você um aviso de privacidade, no qual pedimos que forneça seu consentimento para o processamento das seguintes informações:',
  privacy_policy_21:
    'A base legal para o processamento dos dados pessoais é o consentimento, que obteremos de você por meio do preenchimento do formulário de consentimento no seguinte link:',
  privacy_policy_22:
    'Se você preencher o formulário de consentimento, isso significa que você compreende e aceita todas as condições especificadas neste Aviso de Privacidade.',
  privacy_policy_23: 'Retirada do consentimento',
  privacy_policy_24:
    'Você tem o direito de retirar o consentimento fornecido a qualquer momento. A retirada do consentimento não afeta a licitude do processamento com base no consentimento antes de sua retirada. Você pode retirar seu consentimento enviando a solicitação apropriada para o seguinte endereço de e-mail withdraw@4service-group.com; o formulário de solicitação está disponível no seguinte link:',
  privacy_policy_25: 'Formulário de Retirada para Usuário',
  privacy_policy_26: 'Finalidades do processamento',
  privacy_policy_27:
    'Processamos seus dados pessoais para as seguintes finalidades:',
  privacy_policy_28:
    '– melhorar o atendimento ao cliente (permite uma resposta mais eficaz às solicitações dos clientes); – personalização da experiência dos Usuários (permite identificar quem está mais interessado nos serviços); – melhorar o Site (permite melhorar a qualidade dos produtos e serviços, conveniência de uso, desenvolvimento de novos Serviços e aprimoramento de nossos produtos e serviços); – comunicação com o Usuário através de newsletters, materiais de marketing ou promocionais e outras informações que incluem nossas notícias, atualizações, informações sobre serviços com instruções sobre como recusar receber e-mails subsequentes; – realizar pesquisas e análises estatísticas e outras com base em dados anonimizados; – fornecer serviços personalizados ao Usuário e executar acordos e contratos; – participação do Usuário em vários projetos implementados por nós por meio do Site, respondendo a consultas feitas pelo Usuário através do Site, pesquisa, manutenção de contas e registros e promoção de serviços.',
  privacy_policy_29:
    'Destinatários a quem os dados pessoais podem ser transferidos',
  privacy_policy_30:
    'Ao processar seus dados pessoais, transferimos seus dados para entidades que atuam como processadores da Empresa. Os processadores da Empresa agem exclusivamente com base nas instruções da Empresa. As especificidades das ações dos processadores da Empresa e a lista de tais processadores podem ser encontradas na Política de Privacidade e Proteção de Dados no seguinte link: Política de Privacidade e Proteção de Dados',
  privacy_policy_31:
    'Países para os quais os dados pessoais podem ser transferidos',
  privacy_policy_32:
    'A Empresa transfere seus dados pessoais com base em uma decisão de adequação conforme estipulado pelo RGPD e pela Comissão da UE. Informações adicionais sobre a transferência de dados pessoais para os EUA podem ser encontradas na Política de Privacidade e Proteção de Dados no seguinte link: Política de Privacidade e Proteção de Dados.',
  privacy_policy_33: 'Período de armazenamento',
  privacy_policy_34:
    'Considerando as finalidades do processamento, o período de armazenamento dos dados pessoais dos Usuários é de 24 meses a partir da data em que o consentimento de processamento de dados for devidamente obtido.',
  privacy_policy_35: 'Direito de acesso',
  privacy_policy_36:
    'Você tem o direito de saber se dados pessoais relativos a você estão sendo processados e, caso afirmativo, acessar tais dados com diversas estipulações adicionais previstas no Artigo 15 do RGPD.',
  privacy_policy_37: 'Direito de retificação',
  privacy_policy_38:
    'Quando os dados pessoais que você nos fornece não estão corretos, você tem o direito de nos solicitar a correção (Artigo 16 do RGPD).',
  privacy_policy_39: 'Direito de exclusão (direito ao esquecimento)',
  privacy_policy_40:
    'Você tem o direito de obter de nós a exclusão de seus dados pessoais sem demora injustificada, e temos a obrigação de excluir seus dados pessoais sem demora injustificada quando as razões estabelecidas no Artigo 17 do RGPD se aplicarem.',
  privacy_policy_41: 'Direito à restrição de processamento',
  privacy_policy_42:
    'Você tem o direito de limitar o processamento de seus dados pessoais com várias exceções dentro do escopo do RGPD, conforme previsto no Artigo 18 do RGPD.',
  privacy_policy_43:
    'Somos obrigados a informar quais dados estão sendo coletados, como estão sendo usados, por quanto tempo serão mantidos e se serão compartilhados com terceiros. Essas informações devem ser comunicadas de forma concisa e em linguagem clara.',
  privacy_policy_44: 'Direito à portabilidade dos dados',
  privacy_policy_45:
    'Você tem permissão para obter e reutilizar seus dados pessoais para seus próprios fins em diferentes serviços.',
  privacy_policy_46: 'Direito de oposição',
  privacy_policy_47:
    'Você tem o direito de se opor ao processamento de dados pessoais que estão sendo processados pela Empresa. Devemos interromper o processamento dos dados pessoais, a menos que demonstremos motivos legítimos imperiosos para o processamento que prevaleçam sobre os interesses, direitos e liberdades do indivíduo ou se o processamento for para o estabelecimento ou exercício de defesa de reivindicações legais.',
  privacy_policy_48:
    'Direito de não ser submetido a uma decisão baseada exclusivamente no processamento automatizado',
  privacy_policy_49:
    'Você tem o direito de se opor a qualquer perfilagem automatizada que esteja ocorrendo sem consentimento. Nesse caso, você tem o direito de que seus dados pessoais sejam processados com a participação humana.',
  privacy_policy_50: 'Reclamações',
  privacy_policy_51:
    'No caso de você desejar apresentar uma reclamação sobre como seus dados pessoais estão sendo processados pela Empresa (ou por processadores descritos acima), ou sobre como sua reclamação foi tratada, você tem o direito de apresentar uma reclamação diretamente à autoridade supervisora e à Empresa.',
  privacy_policy_52: 'Os detalhes de contato para cada uma dessas opções são:',
  privacy_policy_53: 'Autoridade supervisora:',
  privacy_policy_54: 'Autoridade Austríaca de Proteção de Dados',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Viena',
  privacy_policy_58: 'Áustria / Europa',
  privacy_policy_59: 'Empresa:',
  privacy_policy_60: '4Service Holdings GmbH (Empresa/nós/nosso)',
  privacy_policy_61: 'Endereço: Tegetthoffstraße 7, 1010 Viena, Áustria.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Política de privacidade e proteção de dados',
  privacy_policy_64:
    'Leia mais sobre como e por que usamos seus dados aqui: Política de Privacidade e Proteção de Dados',
  privacy_policy_65: 'Aprovação',
  privacy_policy_66:
    'A Empresa desenvolveu todos os documentos internos para definir as funções entre os funcionários em relação ao processamento de dados pessoais dentro da Empresa, em particular, responsável pela aprovação e revisão da legitimidade deste documento é o Diretor Administrativo.',
  contacts_1: 'Para clientes',
  contacts_2:
    'Para todos os tipos de empresas, agências de pesquisa de mercado e grandes corporações, parceiros comerciais e mídia.',
  contacts_3: 'Tegetthoffstrasse 7, Viena, Áustria',
  contacts_4: 'Para Shoppers',
  contacts_5:
    'Locais e viajantes, motoristas e estudantes, shoppers misteriosos profissionais e pessoas caseiras.',
  contacts_6: 'Entre em contato conosco de seu país:',
  contacts_7: 'Itália',
  contacts_8: 'EUA',
  contacts_9: 'Reino Unido',
  contacts_10: 'Suíça',
  contacts_11: 'Países Baixos',
  contacts_12: 'Eslováquia',
  contacts_13: 'Eslovênia',
  contacts_14: 'Romênia',
  contacts_15: 'AZ',
  contacts_16: 'Ucrânia',
  contacts_17: 'Cazaquistão',
  contacts_18: 'Resto do Mundo',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'Teste de UX',
  menu_6: 'Pesquisa de RH',
  menu_7: 'Pesquisa qualitativa',
  menu_8: 'Pesquisa quantitativa',
  menu_9: 'Mystery shopping',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Monitoramento de preços',
  menu_14: 'Sobre nós',
  menu_15: 'Contatos',
  menu_16: 'Blog',
  menu_17: 'Métodos',
  menu_18: 'Produtos',
  menu_19: 'Plataforma de Shoppers',
  menu_20: 'CSR',
  menu_21: 'Análise de preços automotiva',
  menu_22: 'Auditoria de Verificação de Instalações',
  menu_23: 'Design de Serviço',
  menu_24: 'CX',
  nps_passives: 'Passivos',
  nps_detractors: 'Detratores',
  nps_promoters: 'Promotores',
  nps_a_score: 'uma pontuação',
  area: 'Área',
  products_tabs_1:
    'Para atingir a máxima eficiência, também realizamos em conjunto:',
  products_tabs_2: 'Produtos',
  products_tabs_3:
    'A pesquisa qualitativa é a coleta e análise de dados não numéricos para entender conceitos, opiniões ou experiências',
  products_tabs_4:
    'Métodos de pesquisa quantitativa são medições objetivas e análise numérica de dados coletados através de enquetes, questionários e pesquisas',
  our_clients_1: 'Nossos clientes',
  preview_cases_section_1: 'Casos',
  preview_cases_section_2:
    'Vá para os casos para ler mais postagens interessantes',
  blog_1: 'Blog de conteúdo inspirador',
  blog_2: 'Nenhuma postagem disponível para sua solicitação',
  about: 'Sobre',
  why_us: 'Por que nós',
  about_us: 'Sobre nós',
  key_features: 'Características principais',
  solutions: 'Soluções',
  monitoring: 'Monitoramento',
  how: 'Como',
  how_it_works: 'Como funciona',
  when_to_use: 'Quando usar',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Voz do cliente',
  wantent: 'Teste de conteúdo de vídeo',
  play4sales: 'Play4Sales',
  price_monitoring: 'Monitoramento de preços',
  service_design: 'Design de serviço',
  qualitative_research: 'Pesquisa qualitativa',
  quantitative_research: 'Pesquisa quantitativa',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automotivo',
  hashtag_retail: '#Varejo',
  hashtag_all_posts: '#Todos_os_posts',
  hashtag_feedback: '#feedback',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Casos',
  hashtag_market_research: '#pesquisa_de_mercado',
  more: 'Mais',
  speak_to_an_expert: 'Fale com um especialista',
  book_consultancy: 'Agendar consultoria',
  read_all: 'Leia tudo',
  find_out_more: 'Saiba mais',
  address: 'Endereço',
  mon_fri: 'Seg-Sex',
  phone_number: 'Número de telefone',
  flag: 'bandeira',
  scroll_to_left: 'deslize para a esquerda',
  online: 'online',
  tel: 'Tel',
  email: 'E-mail',
  light_it_up: 'Acenda',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Diretora de desenvolvimento de negócios',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Gerente de projeto sênior',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Gerente de Desenvolvimento de Negócios',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Diretor de Operações',
  about_us_roles_1: 'Gestão',
  about_us_roles_2: 'Equipe Global',
  about_us_roles_3: 'Proprietários de produtos',
  ad_testing_1: 'Teste de Anúncios',
  ad_testing_2:
    'Uma campanha publicitária é um grande investimento. Certifique-se de que seus anúncios sejam eficazes e proporcionem o máximo ROI',
  ad_testing_3: 'bloqueio',
  ad_testing_4: 'Indústrias que atendemos',
  ad_testing_5:
    '4Service é um grupo global de pesquisa de experiência do cliente',
  ad_testing_6:
    '4Service é uma holding global de pesquisa de marketing e CX. Oferecemos serviços de coleta de dados especializada para uma variedade de negócios',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Varejo',
  ad_testing_9: 'Eletrônicos',
  ad_testing_10: 'Luxo/Premium',
  ad_testing_11: 'Alimentação & Hospitalidade',
  ad_testing_12: 'Automotivo',
  ad_testing_13: 'Viagens & Lazer',
  ad_testing_14: 'Moda',
  ad_testing_15: 'Bancos',
  ad_testing_16: 'Relógios & Joias',
  ad_testing_17: 'Casa & Eletrodomésticos',
  ad_testing_18: 'Membro da Esomar',
  ad_testing_19:
    'Realizamos pesquisa de Teste de Anúncios para diversos negócios em diferentes áreas',
  ad_testing_20: 'Você precisa de Teste de Anúncios se você estiver',
  ad_testing_21: 'Promovendo um novo produto ou serviço',
  ad_testing_22: 'Abordando um assunto sensível',
  ad_testing_23: 'Tentando alcançar um novo público-alvo ou mercado',
  ad_testing_24: 'Discutindo várias opções de design',
  ad_testing_25:
    'Necessitando de uma prova de conceito para equipes de gestão ou investidores',
  ad_testing_26: 'Você terá respostas para as perguntas',
  ad_testing_27:
    'Quais canais de publicidade são mais adequados para esses anúncios?',
  ad_testing_28: 'Estamos usando as imagens certas para transmitir a mensagem?',
  ad_testing_29: 'Os textos e mensagens publicitárias ressoam com o público?',
  ad_testing_30:
    'Quais canais de publicidade são mais adequados para esses anúncios?',
  ad_testing_31: 'O que chama a atenção deles inicialmente?',
  ad_testing_32: 'A colocação e o layout do anúncio são fáceis de entender?',
  ad_testing_33: 'Nós avaliamos',
  ad_testing_34: 'Branding',
  ad_testing_35:
    'como sua marca está vinculada à publicidade e quanto ela será lembrada',
  ad_testing_36: 'Métricas Principais',
  ad_testing_37: 'Integração',
  ad_testing_38: 'Reconhecimento',
  ad_testing_39: 'Adequação',
  ad_testing_40: 'Criatividade',
  ad_testing_41: 'Como seu anúncio se destaca e atrai a atenção das pessoas',
  ad_testing_42: 'Métricas Principais',
  ad_testing_43: 'RECONHECIMENTO',
  ad_testing_44: 'ACEITAÇÃO',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVAÇÃO',
  ad_testing_47: 'Experiência do cliente, engajamento emocional',
  ad_testing_48:
    'Com o uso da tecnologia de inteligência artificial e analistas especializados, analisamos as emoções reais das pessoas e obtemos insights sobre suas percepções da publicidade',
  ad_testing_49: 'Classificação',
  ad_testing_50: 'Masculino',
  ad_testing_51: 'Feminino',
  ad_testing_52: 'Alto engajamento',
  ad_testing_53: 'Atenção',
  ad_testing_54: 'O que a audiência gostou e lembrou?',
  ad_testing_55: 'Atriz',
  ad_testing_56: 'Igualdade',
  ad_testing_57: 'Trilha sonora',
  ad_testing_58: 'Emoções positivas',
  ad_testing_59: 'Pessoas de diferentes raças e aparências',
  ad_testing_60: 'Cenas externas',
  ad_testing_61: 'O que a audiência NÃO gostou e lembrou?',
  ad_testing_62:
    'A voz do narrador não combinava com o que estava acontecendo na tela',
  ad_testing_63: 'Pouca atenção ao produto',
  ad_testing_64: 'Muito dinâmico',
  ad_testing_65: 'Difícil de compreender',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Impressões',
  ad_testing_68: 'Exibições',
  ad_testing_69: 'Conversão',
  ad_testing_70: 'Mídia',
  ad_testing_71:
    'Medimos a eficácia de uma campanha publicitária em conjunto com a atividade de mídia, calculando o impacto dos investimentos de mídia em diferentes canais nos resultados monitorados.',
  ad_testing_72:
    'Realizamos pesquisas de publicidade em qualquer formato, plataforma ou canal',
  ad_testing_73: 'conteúdo de vídeo',
  ad_testing_74: 'outdoor',
  ad_testing_75: 'anúncios de TV',
  ad_testing_76: 'impresso',
  ad_testing_77: 'digital',
  ad_testing_78: 'campanhas publicitárias',
  ad_testing_79: 'display e publicidade no ponto de venda',
  ad_testing_80: 'animatics',
  ad_testing_81:
    'Com a nossa ajuda, você pode testar sua publicidade em qualquer estágio',
  ad_testing_82: 'Conceito',
  ad_testing_83: 'quais ideias têm mais potencial?',
  ad_testing_84: 'Produção em estágio inicial',
  ad_testing_85: 'quais animatics entrarão em produção?',
  ad_testing_86: 'Produção',
  ad_testing_87: 'como editar um vídeo para que ele retenha a atenção?',
  ad_testing_88: 'Pré-mídia',
  ad_testing_89:
    'quais canais de comunicação funcionarão e qual resultado pode ser esperado?',
  ad_testing_90: 'Pós-Teste de Anúncio',
  ad_testing_91: 'Produção',
  ad_testing_92:
    'minha publicidade é cativante? A eficácia da criatividade diminui com o tempo?',
  ad_testing_93: 'Pré-mídia',
  ad_testing_94:
    'quão eficaz é minha publicidade em números? como ela se compara com a concorrência?',
  ad_testing_95: 'Pré-Teste de Anúncio',
  ad_testing_96: 'Para fornecer dados de alta qualidade, usamos',
  ad_testing_97: 'Metodologia de pesquisa quantitativa',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Metodologia de pesquisa qualitativa',
  ad_testing_100: 'Grupos focais',
  ad_testing_101: 'IA',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Relatórios analíticos que você recebe',
  ad_testing_104:
    'Nossa equipe de analistas, especialistas em mídia e profissionais de marketing preparará e apresentará um relatório personalizado com recomendações detalhadas',
  ad_testing_age: 'idade',
  ad_testing_ad: 'Anúncio',
  automotive_industry_price_analysis_1:
    'Análise de Preços da Indústria Automotiva',
  automotive_industry_price_analysis_2:
    'A análise competitiva de preços pode ajudar a evitar perda de lucros, aumentar a participação de mercado e monitorar sua posição no mercado. Permita-nos ajudá-lo a selecionar uma estratégia competitiva de sucesso!',
  automotive_industry_price_analysis_3: 'Solicite um orçamento',
  automotive_industry_price_analysis_4: 'Nossa metodologia',
  automotive_industry_price_analysis_5:
    'Pessoas elegantes em um salão de carros',
  automotive_industry_price_analysis_6:
    'O método Mystery Shopping é a única abordagem que reflete com precisão a experiência real do cliente e fornece uma visão confiável.',
  automotive_industry_price_analysis_7:
    'Oferecemos dados precisos e confiáveis',
  automotive_industry_price_analysis_8:
    'Temos cobertura global em todos os países do mundo',
  automotive_industry_price_analysis_9:
    'Nossos recursos internos incluem clientes ocultos e auditores',
  automotive_industry_price_analysis_10:
    'Validamos a qualidade de nossos dados',
  automotive_industry_price_analysis_11: 'Nossos diferenciais:',
  automotive_industry_price_analysis_12:
    'Monitoramento de preços para modelos competitivos específicos (B2B e B2C)',
  automotive_industry_price_analysis_13: 'Monitoramento do nível de desconto',
  automotive_industry_price_analysis_14:
    'Monitoramento de estoque de varejistas para sua marca e concorrentes',
  automotive_industry_price_analysis_15:
    'Monitoramento de preços automotivos no e-commerce',
  automotive_industry_price_analysis_16: 'Análise de comunicação de preços',
  automotive_industry_price_analysis_17:
    'Acompanhamento das taxas de envio dos varejistas',
  automotive_industry_price_analysis_18:
    'Monitoramento de promoções e vendas em varejistas',
  automotive_industry_price_analysis_19:
    'Determinação dos valores de pagamento mensal',
  automotive_industry_price_analysis_20: 'Cálculo de pagamentos de leasing',
  automotive_industry_price_analysis_21:
    'Análise de considerações específicas de preços para veículos elétricos (EVs)',
  automotive_industry_price_analysis_22:
    'Comparação de preços direto ao consumidor versus varejista',
  automotive_industry_price_analysis_23:
    'Desenvolvimento de modelos de preços de assinatura',
  automotive_industry_price_analysis_24:
    'A 4Service é uma holding global de experiência do cliente (CX) com mais de 20 anos de experiência',
  automotive_industry_price_analysis_25:
    'Você usará dados sobre a disposição dos consumidores de pagar por diferentes recursos para otimizar sua oferta e identificar os recursos mais importantes para seus clientes-alvo.',
  automotive_industry_price_analysis_26:
    'A análise de preços da 4Service na indústria automotiva inclui:',
  automotive_industry_price_analysis_27:
    'Os preços mais baixos nem sempre são a chave para a competitividade das marcas de automóveis. Deixe-nos ajudá-lo a criar uma estratégia de preços de longo prazo',
  automotive_industry_price_analysis_28:
    'dos clientes entendem que os preços estão aumentando em todas as marcas',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'dos clientes disseram que ainda comprariam um carro que gostam, mesmo que a marca os informasse sobre um aumento iminente de preço',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'das pessoas consideram leasing e pagamentos mensais como o modelo mais preferido para comprar um carro',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'dos revendedores mencionaram a possibilidade de aumentos de preços durante o processo de comunicação/venda',
  automotive_industry_price_analysis_35: '47,6',
  automotive_industry_price_analysis_36: 'Principais números',
  automotive_industry_price_analysis_37:
    'Você aprenderá como precificar seu produto em relação a outros veículos da linha.',
  automotive_industry_price_analysis_38:
    'Você usará dados sobre a disposição dos consumidores de pagar por diferentes recursos para otimizar sua oferta e identificar os recursos mais importantes para seus clientes-alvo.',
  automotive_industry_price_analysis_39:
    'dos clientes disseram que ainda comprariam um carro que gostam, mesmo que a marca os informasse sobre um aumento iminente de preço',
  automotive_industry_price_analysis_40:
    'Você será capaz de determinar o pagamento mensal e as taxas de leasing apropriadas.',
  automotive_industry_price_analysis_41:
    'Quais benefícios para sua marca ou concessionária você ganhará:',
  automotive_industry_price_analysis_42: 'Você envia uma solicitação',
  automotive_industry_price_analysis_43:
    'Nosso gerente entra em contato com você para determinar os principais objetivos',
  automotive_industry_price_analysis_44: 'Realizamos uma pesquisa de mercado',
  automotive_industry_price_analysis_45:
    'Fornecemos regularmente relatórios e recomendações em formato online',
  automotive_industry_price_analysis_46: 'Carro com adesivo de venda',
  automotive_industry_price_analysis_47: 'Lançamos o projeto em 1 dia!',
  automotive_industry_price_analysis_48:
    'Oferecemos um conjunto abrangente de relatórios, incluindo relatórios fotográficos, PPT e Excel, segmentados pelo tipo de localidade',
  retail_audit_1: 'AUDITORIA DE MARKETING DE CAMPO/auditoria de varejo',
  retail_audit_2:
    'Colete dados detalhados sobre sua loja ou marca, analise as barreiras ao crescimento e crie estratégias eficazes para superá-las',
  retail_audit_3: 'Solicite um orçamento',
  retail_audit_4:
    'A 4Service realiza auditorias em lojas para avaliar a eficácia de suas estratégias promocionais, equipes de execução de campo e táticas de marketing para alcançar o mercado-alvo pretendido.',
  retail_audit_5:
    'Observamos meticulosamente a execução de campanhas promocionais e garantimos a conformidade com as diretrizes promocionais.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'Agências de CX na Europa',
  retail_audit_8: 'anos de experiência',
  retail_audit_9: 'cobertura de países',
  retail_audit_10: '1,6 milhão',
  retail_audit_11: 'avaliações por ano',
  retail_audit_12: 'O que monitoramos?',
  retail_audit_13: 'Presença de materiais no ponto de venda (POS)',
  retail_audit_14:
    'Presença e ativação de embaixadores da marca, garantindo conformidade com o cronograma planejado',
  retail_audit_15:
    'Avaliação do engajamento dos embaixadores da marca em campanhas de defesa',
  retail_audit_16:
    'Avaliação da interação com o consumidor, garantindo conformidade com as diretrizes promocionais e mensagens da marca',
  retail_audit_17: 'Medidas de prevenção contra fraudes',
  retail_audit_18:
    'Sua empresa pode usar os resultados de uma auditoria de marketing de campo para:',
  retail_audit_19: 'Avaliar a conformidade com o orçamento e processos',
  retail_audit_20: 'Comparar os resultados reais com os objetivos pretendidos',
  retail_audit_21: 'Aprimorar o desempenho de campanhas de marketing',
  retail_audit_22: 'Planejar estrategicamente para o futuro',
  retail_audit_23: 'Identificar oportunidades de redução de custos',
  retail_audit_24: 'Melhorar as vendas e taxas de conversão',
  retail_audit_25: 'Otimizar o retorno sobre investimento (ROI)',
  retail_audit_26:
    'ELES ESCOLHERAM A 4SERVICE COMO SEU PARCEIRO DE INVESTIMENTO COMERCIAL',
  retail_audit_27:
    'Um fabricante internacional líder de produtos de tabaco fez parceria com a 4Service para aprimorar suas marcas e melhorar o desempenho nas lojas. Nossas equipes forneceram auditorias de marketing de campo e relatórios ao vivo de experiência do consumidor.',
  retail_audit_28:
    'aumento na precisão de planejamento da agência de marketing de campo',
  retail_audit_29: 'aumento na entrega da mensagem da marca',
  retail_audit_30:
    'aumento no engajamento dos embaixadores da marca (ativação do consumidor)',
  retail_audit_31:
    'aumento na precisão da coleta de dados e conformidade com a GDPR',
  retail_audit_32: 'Que insights valiosos tendemos a encontrar:',
  retail_audit_33:
    'A falta de consistência ou até conflito entre os termos do SLA leva a cobranças significativas não aprovadas feitas ao cliente',
  retail_audit_34:
    'Cobrança incorreta de taxas devido à confusão entre os tipos de serviço prestados',
  retail_audit_35:
    'Serviço de campo não sendo executado, incluindo falta de designação de funcionários ao local planejado | POS',
  retail_audit_36:
    'Cobranças feitas com base em estimativa em vez de real e sem reconciliação',
  retail_audit_37:
    'Aplicação incorreta das diretrizes promocionais fornecendo dados fraudulentos',
  retail_audit_38: 'Como trabalhamos?',
  retail_audit_39:
    'Nosso auditor visita locais de ponto de venda (POS) designados com base no plano de ativação da agência.',
  retail_audit_40:
    'Eles avaliam os principais indicadores de desempenho (KPIs) conforme especificado no briefing, como presença de POS, engajamento dos embaixadores da marca, conformidade com diretrizes promocionais e medidas de prevenção contra fraudes.',
  retail_audit_41: 'Validação:',
  retail_audit_42: 'Reunimos e consolidamos os dados obtidos.',
  retail_audit_43: 'Um relatório abrangente é elaborado.',
  retail_audit_44:
    'Nossa equipe de validação revisa minuciosamente todas as pesquisas para garantir precisão, incluindo conformidade com o briefing do projeto e quaisquer comentários adicionais.',
  retail_audit_45: 'Sistema de Relatórios Online:',
  retail_audit_46:
    'Questionários completos e validados são carregados em nosso portal online dentro de 36 horas.',
  retail_audit_47:
    'Os clientes têm acesso a um portal de conta pessoal, permitindo que monitorem o progresso a qualquer momento.',
  retail_audit_48:
    'Um relatório final contendo resultados e recomendações é fornecido.',
  retail_audit_49: 'Fale com um especialista',
  retail_audit_50: 'Nossos pontos fortes:',
  facility_check_audit_1: 'Instalação',
  facility_check_audit_2: 'Auditoria de Verificação',
  facility_check_audit_3: 'Eleve a Experiência da Marca em Cada Detalhe',
  facility_check_audit_4: 'Explore Nossa Abordagem',
  facility_check_audit_5: 'Sobre',
  facility_check_audit_6: 'Quem somos',
  facility_check_audit_7:
    'Como holding global de experiência do cliente, a 4Service oferece uma ampla gama de serviços para ajudar marcas a criar e proporcionar experiências excepcionais para seus clientes.',
  facility_check_audit_8:
    'Nosso serviço de Auditoria de Verificação de Instalações',
  facility_check_audit_9:
    'foi projetado para ajudar você a garantir que seus locais físicos e presença da marca nas lojas estejam alinhados com seus padrões de marca e criando uma impressão positiva para os clientes.',
  facility_check_audit_10: 'Soluções 1',
  facility_check_audit_11: 'Auditoria de Verificação de Instalações',
  facility_check_audit_12:
    'é um exame meticuloso que avalia o alinhamento de seus pontos de venda ou stands com os padrões da sua marca. Não apenas verificamos os atributos físicos, mas também avaliamos como esses elementos influenciam a percepção da sua marca pelos clientes.',
  facility_check_audit_13: 'Duas soluções',
  facility_check_audit_14: 'Dois Métodos de Implementação',
  facility_check_audit_15: 'Método',
  facility_check_audit_16:
    'Auditoria de Verificação de Instalações por Auditores Especializados',
  facility_check_audit_17: 'Validação da Verificação de Instalações',
  facility_check_audit_18: 'Relatórios',
  facility_check_audit_19:
    'Você receberá relatórios, completos com evidências visuais e estatísticas, acessíveis em até 48 horas',
  facility_check_audit_20:
    'Uma Auditoria de Verificação de Instalações bem executada pode trazer vários benefícios para sua marca, incluindo:',
  facility_check_audit_21: 'Melhoria na conversão de clientes:',
  facility_check_audit_22:
    'Uma experiência positiva do cliente pode levar ao aumento de vendas e repetição de negócios. Nossas auditorias podem ajudá-lo a identificar e resolver quaisquer áreas que possam estar impactando a satisfação do cliente.',
  facility_check_audit_23: 'Imagem da marca aprimorada:',
  facility_check_audit_24:
    'Seus locais físicos são pontos de contato importantes para os clientes e podem desempenhar um papel significativo na formação da imagem da sua marca. Nossas auditorias podem ajudá-lo a garantir que seus locais estejam alinhados com os valores da marca e criando uma impressão positiva para os clientes.',
  facility_check_audit_25: 'Por que nós?',
  facility_check_audit_26:
    'Por que a Auditoria de Verificação de Instalações é importante?',
  facility_check_audit_27:
    'Uma Auditoria de Verificação de Instalações bem executada pode trazer vários benefícios para sua marca, incluindo:',
  facility_check_audit_28: 'Imagem da marca aprimorada:',
  facility_check_audit_29:
    'Seus locais físicos são pontos de contato importantes para os clientes e podem desempenhar um papel significativo na formação da imagem da sua marca. Nossas auditorias podem ajudá-lo a garantir que seus locais estejam alinhados com os valores da marca e criando uma impressão positiva para os clientes.',
  facility_check_audit_30: 'Melhoria na conversão de clientes:',
  facility_check_audit_31:
    'Uma experiência positiva do cliente pode levar ao aumento de vendas e repetição de negócios. Nossas auditorias podem ajudá-lo a identificar e resolver quaisquer áreas que possam estar impactando a satisfação do cliente.',
  facility_check_audit_32: 'Quem precisa',
  facility_check_audit_33:
    'Quem precisa de Auditoria de Verificação de Instalações?',
  facility_check_audit_34:
    'A Auditoria de Verificação de Instalações é um serviço valioso para qualquer marca com locais físicos e presença física. É especialmente importante para marcas que desejam:',
  facility_check_audit_35: 'Garantir conformidade com os padrões da marca:',
  facility_check_audit_36:
    'Nossas auditorias podem ajudá-lo a identificar áreas onde seus locais podem estar desviando dos padrões da marca.',
  facility_check_audit_37: 'Melhorar a experiência do cliente:',
  facility_check_audit_38:
    'Nossas auditorias podem ajudá-lo a identificar oportunidades para melhorar a experiência do cliente em seus locais.',
  facility_check_audit_39: 'Construir uma marca mais forte:',
  facility_check_audit_40:
    'Nossas auditorias podem ajudá-lo a garantir que seus locais físicos estejam criando uma impressão positiva para os clientes.',
  facility_check_audit_41:
    'Seu funcionário ou cliente oculto tira uma foto do local.',
  facility_check_audit_42:
    'Em seguida, eles preenchem um questionário e anexam a foto.',
  facility_check_audit_43:
    'O validador recebe a foto e verifica a conformidade com os padrões.',
  facility_check_audit_44:
    'Auditoria de Verificação de Instalações por Auditores Especializados',
  facility_check_audit_45: 'Etapa de Preparação:',
  facility_check_audit_46: 'Aprovação colaborativa do cronograma de auditoria',
  facility_check_audit_47:
    'Os revendedores são preparados e informados sobre a auditoria iminente',
  facility_check_audit_48: 'Treinamento de Auditores:',
  facility_check_audit_49:
    'Nossos experientes treinadores da 4Service capacitam os auditores com diretrizes da marca',
  facility_check_audit_50: 'Etapa da Auditoria de Instalações:',
  facility_check_audit_51:
    'Auditores, munidos de cartas de autorização especiais, visitam os locais',
  facility_check_audit_52:
    'Exame minucioso de cada ponto, seguindo rigorosamente as diretrizes',
  facility_check_audit_53:
    'Documentação visual, juntamente com avaliações em conformidade com os requisitos da matriz',
  facility_check_audit_54: 'Relatório Conclusivo em PPT',
  facility_check_audit_55:
    'Um relatório completo em PowerPoint, elucidando os resultados',
  facility_check_audit_56:
    'Apresentação imersiva online/offline dos resultados auditados',
  facility_check_audit_57: 'Validação da Verificação de Instalações',
  facility_check_audit_58: 'Etapas:',
  facility_check_audit_59: 'Etapa da Auditoria de Instalações:',
  facility_check_audit_60:
    'Representantes da marca utilizam o app da 4Service para capturar fotos in loco',
  facility_check_audit_61: 'Adesão a critérios visuais e descritivos rigorosos',
  facility_check_audit_62: 'Etapa de Validação:',
  facility_check_audit_63:
    'Validadores diligentes revisam minuciosamente cada relatório',
  facility_check_audit_64:
    'Comparação abrangente de fotos com os padrões da marca',
  facility_check_audit_65:
    'Anotações e insights integrados ao relatório para maior clareza',
  facility_check_audit_66:
    'Comunicação direta com os revendedores para esclarecimentos, se necessário',
  facility_check_audit_67: 'Fase de Relatório:',
  facility_check_audit_68:
    'Relatórios, completos com evidências visuais e estatísticas, acessíveis em até dois dias',
  facility_check_audit_69: 'Entre em contato conosco hoje',
  cx_solution_1: 'Nós não apenas encontramos insights,',
  cx_solution_2: 'Levamos o seu negócio a novos patamares',
  cx_solution_3:
    'Especialistas com mais de 20 anos de experiência em atendimento ao cliente descobrem soluções complexas e fornecem um plano de ação específico que contribui para o seu crescimento',
  cx_solution_4:
    'Descubra uma suíte única de serviços em uma solução à prova de futuro',
  cx_solution_5: 'Solução CX tudo-em-um',
  cx_solution_6: 'Teste de anúncios',
  cx_solution_7: 'IA e Neuromarketing',
  cx_solution_8: 'Voz do cliente',
  cx_solution_9: 'Pesquisa documental',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Cliente oculto',
  cx_solution_12: 'Consultoria em CX',
  cx_solution_13: 'Escuta de mídias sociais',
  cx_solution_14: 'Pesquisa quantitativa e qualitativa',
  cx_solution_15: 'Design de serviços',
  cx_solution_16: 'Estratégia de marketing',
  cx_solution_17: 'Teste UX/UI',
  cx_solution_18: 'Insights de público',
  cx_solution_19: 'Estamos honrados em contribuir para o seu sucesso',
  cx_solution_20:
    'Personalizamos e combinamos ferramentas e serviços com base nos objetivos do seu negócio para entregar resultados sob medida',
  cx_solution_21: 'Pesquisa abrangente',
  cx_solution_22:
    'Empodere sua tomada de decisão, proporcionando uma vantagem competitiva em um mercado centrado em dados.',
  cx_solution_23:
    'A pesquisa qualitativa envolve a coleta e análise de dados não numéricos para entender conceitos, opiniões ou experiências',
  cx_solution_24:
    'Métodos de pesquisa quantitativa medem e analisam dados numericamente coletados por meio de pesquisas e questionários',
  cx_solution_25:
    'Utilizamos um conjunto distinto de ferramentas com base nas suas necessidades específicas',
  cx_solution_26: 'Ferramentas',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Entrevista Web Assistida por Computador é uma ferramenta vital em nosso arsenal, permitindo realizar pesquisas e coletar dados por meio de plataformas online com software especializado, garantindo coleta de dados eficiente e precisa para as necessidades de pesquisa de mercado de nossos clientes',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Entrevista Telefônica Assistida por Computador é um método que utilizamos para realizar pesquisas e reunir dados valiosos pelo telefone, empregando software especializado para otimizar o processo e garantir precisão em nossas investigações de mercado',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Face-a-Face é parte essencial da nossa abordagem, facilitando interações diretas e personalizadas com clientes e consumidores, promovendo confiança, compreensão e comunicação eficaz para construir relações duradouras e impulsionar o sucesso empresarial',
  cx_solution_33: 'Pesquisa documental',
  cx_solution_34:
    'A pesquisa documental envolve coleta e análise abrangentes de dados, sendo um passo fundamental em nosso processo de pesquisa para obter insights e orientar a tomada de decisões estratégicas para nossos clientes',
  cx_solution_35: 'Análise SWOT',
  cx_solution_36:
    'A análise SWOT é uma ferramenta de avaliação estratégica que ajuda nossos clientes a identificar forças e fraquezas internas, bem como oportunidades e ameaças externas, fornecendo insights valiosos para informar a tomada de decisões e desenvolver estratégias empresariais eficazes',
  cx_solution_37: 'Pesquisa etnográfica',
  cx_solution_38:
    'Estudo de pessoas e culturas por meio de imersão e observação em seus ambientes naturais, frequentemente levando a insights profundos sobre comportamentos, crenças e dinâmicas sociais',
  cx_solution_39: 'Entrevistas em profundidade',
  cx_solution_40:
    'Entrevistas em profundidade são um método de pesquisa qualitativa que empregamos, envolvendo discussões detalhadas e individuais com participantes para explorar profundamente suas perspectivas, experiências e opiniões, proporcionando insights ricos e compreensão para os objetivos e tomadas de decisão de nossos clientes',
  cx_solution_41: 'Sessões de ideação',
  cx_solution_42:
    'Sessões de ideação são sessões colaborativas de brainstorming que facilitamos, reunindo diferentes perspectivas e expertise para gerar ideias criativas e soluções para os desafios ou oportunidades dos nossos clientes, promovendo inovação e impulsionando iniciativas estratégicas',
  cx_solution_43: 'Personas de comprador',
  cx_solution_44:
    'Personas de comprador são perfis detalhados que desenvolvemos, representando os clientes-alvo de nossos clientes com base em pesquisas e análise de dados, nos permitindo entender melhor suas necessidades, preferências e comportamentos, e personalizar nossas estratégias e comunicações para engajar e ressoar com eles, impulsionando o sucesso empresarial',
  cx_solution_45: 'Cliente Oculto',
  cx_solution_46:
    'Cliente Oculto é um serviço estratégico que oferecemos, medindo e monitorando as interações entre um cliente e uma empresa em um cenário pré-definido',
  cx_solution_47: 'Acompanhamento',
  cx_solution_48:
    'O acompanhamento é uma forma especializada de cliente oculto, onde nossos avaliadores acompanham os clientes durante toda a experiência de serviço, fornecendo insights abrangentes sobre cada aspecto da jornada do cliente, permitindo que nossos clientes entendam profundamente sua entrega de serviço e façam melhorias informadas para aprimorar a satisfação e lealdade do cliente',
  cx_solution_49: 'Análise de coleta de feedback',
  cx_solution_50:
    'A análise de coleta de feedback é um processo crítico que realizamos, envolvendo a coleta e análise sistemática de feedback de clientes de vários canais, como pesquisas, avaliações e redes sociais, permitindo extrair insights valiosos, identificar tendências e fazer recomendações baseadas em dados para nossos clientes melhorarem seus produtos, serviços e a experiência geral do cliente',
  cx_solution_51: 'Grupos focais',
  cx_solution_52:
    'Grupos focais são sessões dinâmicas de pesquisa que facilitamos, reunindo um grupo diverso de participantes para discutir tópicos ou produtos específicos em profundidade, permitindo-nos coletar insights qualitativos, descobrir atitudes, preferências e percepções, e explorar ideias e conceitos, informando a tomada de decisões e o desenvolvimento de estratégias de nossos clientes',
  cx_solution_53: '20 anos criando experiências excepcionais para o cliente',
  cx_solution_54: 'Garanta que sua mensagem cative e ressoe com seu público.',
  cx_solution_55:
    'Estamos dedicados a entregar resultados que superem suas expectativas',
  cx_solution_56:
    'Descobrimos soluções complexas que contribuem para o seu crescimento',
  cx_solution_57: 'Eleve sua experiência do cliente com nossa metodologia',
  cx_solution_58:
    'O Mapeamento da Jornada do Cliente envolve definir um público-alvo, entender suas necessidades e ambiente, e criar um plano para oferecer uma experiência envolvente',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'A pesquisa de Rastreamento da Saúde da Marca pode medir como sua marca está se saindo em termos de conhecimento e uso, posicionamento e desempenho',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'O Índice de Satisfação do Cliente é uma pontuação que indica o nível de satisfação dos clientes com os produtos/serviços da empresa.',
  cx_solution_63:
    'O monitoramento de preços é o processo de coleta, processamento e análise dos preços dos concorrentesм',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score é o padrão ouro de métricas de experiência do cliente, baseado em uma pergunta: Quão provável é que você recomende a Organização/Produto/Serviço?',
  cx_solution_66: 'Pesquisa de RH',
  cx_solution_67:
    'A Pesquisa de Recursos Humanos é utilizada para avaliar práticas e desempenho de RH, oferecendo uma análise detalhada sobre o desenvolvimento e gestão atuais',
  cx_solution_68:
    'O teste de usabilidade observa como pessoas reais interagem com um site, aplicativo ou outro produto, e suas reações e comportamentos',
  cx_solution_69: 'Outros',
  cx_solution_70:
    'Especialistas selecionam a melhor metodologia para o seu negócio',
  cx_solution_71: 'Vamos começar',
  cx_solution_72: '1 - REUNIÃO DE KICKOFF',
  cx_solution_73:
    'Durante a reunião de kickoff, identificamos e focamos nas tarefas principais. Resultado: entendimento do objetivo, tarefas de pesquisa, prazos, métodos e áreas de responsabilidade.',
  cx_solution_74: '2 - DESENVOLVIMENTO DO CONCEITO',
  cx_solution_75:
    'O departamento de análise da 4Service agrega dados, coordena a estrutura do relatório. Em seguida, apresentamos os resultados ao cliente, complementados com insights e recomendações para implementação.',
  cx_solution_76: '3 - TRABALHO DE CAMPO',
  cx_solution_77:
    'Para conduzir a pesquisa, recrutamos participantes, realizamos entrevistas e outras etapas básicas de pesquisa. O cliente recebe entrevistas de áudio e vídeo transcritas, bem como resumos concisos.',
  cx_solution_78: '4 - ANÁLISE',
  cx_solution_79:
    'O departamento de análise da 4Service agrega dados e coordena a estrutura do relatório. Em seguida, apresentamos os resultados ao cliente, complementados com insights e recomendações para implementação.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81:
    'Condução de uma discussão com o cliente sobre o plano de implementação',
  cx_solution_82: 'Saiba mais sobre nossas melhores práticas',
  cx_solution_83:
    'O Mapa da Jornada do Cliente permite um exame rápido de todos os canais de comunicação, identificação de possíveis falhas em pontos de contato cruciais e uma compreensão abrangente de áreas que podem se desviar do curso planejado.',
  cx_solution_84: 'Quando usá-lo?',
  cx_solution_85:
    'Construa uma campanha publicitária personalizada com base em dados reais sobre grupos de audiência',
  cx_solution_86:
    'Descubra os gatilhos de diferentes segmentos de público-alvo e satisfaça suas necessidades',
  cx_solution_87:
    'Direcione cada grupo com precisão e obtenha a máxima conversão',
  cx_solution_88:
    'A técnica de Acompanhamento permite avaliar as possibilidades de caminhos ideais para alcançar seus objetivos.',
  cx_solution_89: 'Testar um novo site ou produto antes do lançamento',
  cx_solution_90:
    'Verificação de processos desenvolvidos antes de implementar mudanças',
  cx_solution_91: 'Avaliação do site ou produto em meio à queda nas vendas',
  cx_solution_92:
    'Lançamento de um novo produto adaptado às preferências do cliente',
  cx_solution_93:
    'Estudo de empresas concorrentes para garantir uma posição de liderança no mercado',
  cx_solution_94:
    'Oferecemos uma abordagem abrangente de visão 360° para a criação de um modelo de serviço orientado para o cliente.',
  cx_solution_95: 'Treinamento e desenvolvimento de equipe',
  cx_solution_96: 'Melhoria dos padrões de serviço',
  cx_solution_97: 'Aumento do fluxo de clientes e vendas',
  cx_solution_98: 'Definição de KPIs e sistema de motivação para a equipe',
  cx_solution_99: 'Descubra nossas histórias de sucesso',
  cx_solution_100: 'Automotivo',
  cx_solution_101: 'Tabaco',
  cx_solution_102: 'Varejo',
  cx_solution_103: 'Fase qualitativa',
  cx_solution_104: 'Fase quantitativa (segmentação)',
  cx_solution_105: 'Grupos focais',
  cx_solution_106: 'Cobertura de audiência online mundial',
  cx_solution_107: 'Mistura de técnicas durante as entrevistas',
  cx_solution_108: 'Apenas perguntas abertas',
  cx_solution_109: 'Suporte ao respondente 24/7',
  cx_solution_110:
    'Preservação de detalhes importantes e uma visão real do percurso',
  cx_solution_111: 'Pesquisa de mercado',
  cx_solution_112: 'Diagnóstico da empresa e dos concorrentes',
  cx_solution_113:
    'Modelos de serviço atualizados e novos processos de negócios',
  cx_solution_114: 'Promoção de serviço orientado para o cliente',
  cx_solution_115:
    'Implementação de mudanças e controle sobre o desenvolvimento de estratégias de serviço',
  usage_attitude_1: 'Uso e \n Atitude (U&A)',
  usage_attitude_2:
    'Obtenha insights abrangentes sobre como o público-alvo \n percebe, utiliza e interage com sua marca.',
  usage_attitude_3: 'Fale com um especialista',
  usage_attitude_4: 'Sobre',
  usage_attitude_5:
    '<b>Explore os hábitos</b> dos consumidores para descobrir preferências e influências em profundidade',
  usage_attitude_6:
    "<b>Descubra o 'porquê'</b> por trás das escolhas dos consumidores com nossa análise detalhada",
  usage_attitude_7:
    '<b>Entenda como</b> os clientes interagem com seus produtos para obter insights estratégicos',
  usage_attitude_8:
    '<b>Obtenha insights sobre</b> o comportamento, hábitos e preferências dos consumidores para decisões informadas',
  usage_attitude_9: 'Experiência',
  usage_attitude_10:
    'Especialistas da 4Service com mais de 20 anos de experiência em atendimento ao cliente criam soluções exclusivas que ajudam a crescer seu negócio',
  usage_attitude_11: 'Contate-nos',
  usage_attitude_12: 'Para quem',
  usage_attitude_13: 'Quem se beneficia do Uso e Atitude da Marca?',
  usage_attitude_14:
    'Diversos setores industriais e de serviços estão interessados em:',
  usage_attitude_15:
    '<b>Reconhecer</b> as demandas e pontos críticos dos clientes',
  usage_attitude_16:
    '<b>Identificar</b> oportunidades para aumentar o consumo dos clientes',
  usage_attitude_17:
    '<b>Desenvolver</b> novas categorias de produtos ou estratégias de marketing',
  usage_attitude_18:
    '<b>Obter</b> uma compreensão profunda do cenário competitivo',
  usage_attitude_19:
    'A metodologia é selecionada individualmente com base no pedido do cliente',
  usage_attitude_20:
    '<b>A pesquisa qualitativa</b> envolve a coleta e análise de dados não numéricos para entender conceitos, opiniões ou experiências.',
  usage_attitude_21:
    '<b>Métodos de pesquisa quantitativa</b> envolvem a medição objetiva e análise numérica de dados obtidos por meio de enquetes, questionários e pesquisas.',
  usage_attitude_22:
    '<b>Após a coleta de dados</b>, especialistas analisam as informações e tiram conclusões para fornecer as melhores recomendações.',
  usage_attitude_23: 'Quem precisa',
  usage_attitude_24: 'Sua História de Sucesso Começa Aqui',
  usage_attitude_25:
    '<b>Você receberá uma pesquisa</b> com insights valiosos que capacitam a tomada de decisões estratégicas, garantindo ações baseadas em uma compreensão profunda do comportamento do consumidor.',
  usage_attitude_26:
    '<b>A pesquisa fornece uma base sólida para refinar</b> e otimizar as estratégias da marca, alinhando-as mais de perto com as preferências e atitudes dos consumidores.',
  usage_attitude_27: 'Soluções',
  usage_attitude_28: 'Explore inúmeras oportunidades com nossas soluções',
  usage_attitude_29: 'Preferência de produtos dentro da categoria',
  usage_attitude_30:
    'Identifique as principais preferências dos consumidores em várias categorias, incluindo alimentos, bebidas, roupas, eletrônicos e muito mais.',
  usage_attitude_31: 'Comportamento de consumo / uso',
  usage_attitude_32:
    'Analise a frequência de consumo ou uso de produtos/serviços específicos e identifique as ocasiões e momentos associados ao seu uso.',
  usage_attitude_33: 'Comportamento de compra',
  usage_attitude_34:
    'Examine os canais que os clientes utilizam para suas compras e identifique as fontes de informação nas quais confiam.',
  usage_attitude_35: 'Motivações e necessidades dos consumidores',
  usage_attitude_36:
    'Examine as motivações e barreiras que influenciam o consumo. Avalie os fatores que impactam as decisões de compra dos consumidores. Explore a percepção da categoria de produtos e analise como o preço é percebido.',
  usage_attitude_37: 'Nossa Abordagem',
  usage_attitude_38: 'Estudo de uso e atitude da marca',
  usage_attitude_39: 'Informação',
  usage_attitude_40:
    'Condução de pesquisas usando métodos quantitativos e qualitativos',
  usage_attitude_41: 'Começo',
  usage_attitude_42:
    'Definindo metas e determinando métodos com base nas solicitações individuais',
  usage_attitude_43: 'Análise',
  usage_attitude_44:
    'Técnicas sofisticadas empregadas por nossos especialistas',
  usage_attitude_45:
    'Utilizamos um conjunto distinto de ferramentas com base em seus requisitos específicos',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Computer-Assisted Telephone Interviewing é um método que utilizamos para realizar pesquisas e reunir dados valiosos por telefone, empregando software especializado para agilizar o processo e garantir precisão em nossos esforços de pesquisa de mercado.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Computer-Assisted Web Interviewing é uma ferramenta vital em nosso arsenal, permitindo-nos realizar pesquisas e coletar dados por meio de plataformas online com a ajuda de software especializado, garantindo coleta de dados eficiente e precisa para as necessidades de pesquisa de mercado de nossos clientes.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Computer-Assisted Personal Interviewing é um método que utilizamos para otimizar processos de coleta de dados, facilitar entrevistas e garantir resultados precisos e perspicazes para as necessidades do seu negócio.',
  usage_attitude_52: 'Grupos focais',
  usage_attitude_53:
    'Grupos focais são sessões de pesquisa dinâmicas que facilitamos, reunindo um grupo diversificado de participantes para discutir tópicos ou produtos específicos em profundidade, permitindo-nos obter insights qualitativos, descobrir atitudes, preferências e percepções, e explorar ideias e conceitos, informando assim o processo de tomada de decisões e desenvolvimento de estratégias de nossos clientes.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Face-to-Face é parte integral da nossa abordagem, facilitando interações diretas e engajamentos personalizados com clientes e consumidores, promovendo confiança, entendimento e comunicação eficaz, essenciais para construir relações duradouras e impulsionar o sucesso do negócio.',
  usage_attitude_56: 'Pesquisa Etnográfica',
  usage_attitude_57:
    'Estudo de pessoas e culturas por meio de imersão e observação em seus ambientes naturais, geralmente levando a insights profundos sobre comportamentos, crenças e dinâmicas sociais.',
  thank_you_page:
    'Obrigado pelo seu tempo! Nosso gerente entrará em contato em breve.',
  back_main: 'Voltar para a página principal',
  opportunities_1: 'Saiba mais sobre nossos casos',
  opportunities_2: 'Descubra',
  opportunities_3: 'por si mesmo',
  opportunities_4: 'oportunidades',
  opportunities_5:
    'de negócios internacionais com o grupo de pesquisa 4Service',
  opportunities_6:
    'Quais <span>produtos</span> podemos fortalecer seu negócio?',
  opportunities_7: 'CX',
  opportunities_8: 'Soluções personalizadas abrangentes para negócios',
  opportunities_9: 'Todos os tipos de Mystery Shopping',
  opportunities_10:
    'Implementamos mystery employee, mystery caller, luxury mystery shopper, automotive mystery shopper e outros tipos',
  opportunities_11: 'Soluções personalizadas de TI para negócios',
  opportunities_12:
    'Soluções próprias de TI com IA, plataformas para análise de feedback e transcrição de chamadas',
  opportunities_13: 'Pesquisa de mercado',
  opportunities_14:
    'Para qualquer solicitação de negócios (Brand Health Tracking, Segmentação, NPS, eNPS, CJM, Testes de produtos e publicidade, etc.)',
  opportunities_15: 'Auditorias',
  opportunities_16:
    'Realizamos várias auditorias de serviços, além de monitoramento de preços (offline, para nichos específicos, etc.)',
  opportunities_17: 'Design de serviços',
  opportunities_18:
    '4Service - parceiros certificados da DesignThinkers Academy, oferecendo treinamentos corporativos para equipes e realizando workshops regularmente para empresas',
  opportunities_19:
    'Compartilhamos nossa experiência, mas <span>respeitamos e não disruptamos</span> seu processo de negócios.',
  opportunities_20:
    'Sabemos das dificuldades que as empresas enfrentam em sua jornada de desenvolvimento, e sabemos como superar essas barreiras graças à nossa experiência internacional de sucesso. Você recebe não apenas experiência, mas também recursos.',
  opportunities_21: 'Convidamos você a cooperar!',
  opportunities_22:
    'Oferecemos um <span>programa de parceria</span> para o seu negócio',
  opportunities_23:
    'Sob a marca internacionalmente reconhecida 4Service, você pode influenciar positivamente a atração de novos clientes.',
  opportunities_24:
    'Nossos parceiros recebem suporte completo, incluindo treinamento, materiais de marketing e assistência operacional.',
  opportunities_25:
    'A parceria conosco realiza seus planos sem interromper o processo de negócios atual.',
  opportunities_26: 'Nossos <span>escritórios</span>',
  opportunities_27: 'Nossos clientes',
  opportunities_28: 'Temos mais de 20 anos de experiência em CX',
  opportunities_29: 'Países atendidos',
  opportunities_30: 'mil',
  opportunities_31: 'Projetos únicos',
  opportunities_32: 'milhões',
  opportunities_33: 'Visitas de mystery shoppers',
  opportunities_34: 'estamos entre as 10 melhores agências de CX da Europa',
  opportunities_35: 'Suas oportunidades com 4Service',
  opportunities_36: 'Aumento de lucro',
  opportunities_37: 'Você terá acesso a pedidos internacionais',
  opportunities_38: 'Abordagem única de CX',
  opportunities_39: 'Suporte de especialistas com 20 anos de experiência',
  opportunities_40: 'Suporte',
  opportunities_41: 'Nossa equipe irá apoiá-lo para alcançar resultados',
  opportunities_42: 'Vendas',
  opportunities_43: 'Ajudaremos a configurar um sistema de vendas',
  opportunities_44: 'Acesso a software',
  opportunities_45:
    'Você terá acesso a todas as ferramentas de TI certificadas',
  opportunities_46: 'Treinamento',
  opportunities_47:
    'Não apenas treinaremos sua equipe, mas também continuaremos a fornecer serviços de consultoria',
  opportunities_48: 'Quem é adequado para a parceria?',
  opportunities_49: 'Seu negócio está relacionado à pesquisa de marketing?',
  opportunities_50: 'Ou você está planejando tentar uma nova área',
  opportunities_51: 'Você está planejando:',
  opportunities_52: 'Implementar inovações',
  opportunities_53: 'Trabalhar com marcas internacionais',
  opportunities_54: 'Expandir seu portfólio de produtos',
  opportunities_55: 'Fortalecer sua posição no mercado?',
  opportunities_56: 'Treinar seus funcionários',
  opportunities_57: 'Expandir seu negócio',
  opportunities_58: 'Nossos recursos = Seus recursos',
  opportunities_59:
    'Dada a nossa vasta experiência, estamos prontos para compartilhá-la com você na primeira etapa da cooperação',
  opportunities_60: 'Análise de dados:',
  opportunities_61: 'Equipe de análise internacional',
  opportunities_62: 'Métodos de processamento de dados - SPSS, Power BI',
  opportunities_63: 'Metodologias inovadoras e uma ampla gama de metodologias',
  opportunities_64: 'Relatórios em qualquer formato',
  opportunities_65: 'Software:',
  opportunities_66: 'Desenvolvimentos de TI personalizados próprios',
  opportunities_67: 'DigSee Sure para CAPI',
  opportunities_68: 'SurveyMonkey para CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (call center)',
  opportunities_71: 'Suporte de marketing:',
  opportunities_72: 'Suporte de PR',
  opportunities_73: 'Apoio na promoção e criação de conteúdo',
  opportunities_74: 'Configuração de processos de geração de leads',
  opportunities_75: 'Suporte de TI:',
  opportunities_76:
    'Suporte técnico com instalação, configuração e uso de nosso software.',
  opportunities_77: 'Assistência remota',
  opportunities_78: 'Controle de segurança',
  opportunities_79: 'Suporte operacional:',
  opportunities_80: 'Apoio na configuração de processos de campo',
  opportunities_81: 'Sistema anti-fraude',
  opportunities_82: 'Recrutamento de qualquer complexidade',
  opportunities_83:
    'Gestão operacional do projeto e equipe de departamentos de campo',
  opportunities_84: 'Controle de qualidade do recrutamento',
  opportunities_85: 'UpWork',
  opportunities_86: 'Quais bônus você receberá?',
  opportunities_87: 'Aumento da base de clientes',
  opportunities_88: 'Fortalecimento da imagem no mercado',
  opportunities_89: 'Diversificação dos riscos de perda de clientes',
  opportunities_90: 'Otimização de processos tecnológicos',
  opportunities_91: 'Experiência internacional',
  opportunities_92: 'Mais vendas para os clientes atuais',
  main_67:
    'A análise de preços competitivos otimiza as ofertas, aumenta os lucros e melhora a participação no mercado aproveitando os dados de disposição de pagamento do consumidor para identificar características chave',
  main_68:
    'Garante que seus pontos de venda estejam alinhados com os padrões da marca, avaliando atributos físicos e seu impacto na percepção do cliente',
  main_69: 'Solução CX',
  main_70:
    'Descubra nossa solução única de CX 360 graus: personalizamos ferramentas e serviços com base nos seus objetivos para impulsionar seu negócio',
  main_71: 'Análise de fala',
  main_72:
    'Nossa avançada ferramenta de análise de fala transcreve a linguagem falada em texto, fornecendo insights valiosos para as empresas aprimorarem a experiência do cliente, monitorarem o desempenho, garantirem conformidade e impulsionarem o crescimento',
  parthership: 'Parceria',
  banner_1: 'Consultoria e serviços CX uma visão 360° do seu negócio',
  banner_2: 'Fique atualizado com as pesquisas mais recentes!',
  banner_3:
    'Dou meu consentimento para o processamento de dados pessoais de acordo com a Política de Privacidade',
  banner_4: '*Selecione o mercado',
  banner_5: 'Obrigado por se inscrever!',
  banner_6: 'Só partilharemos consigo os conteúdos mais interessantes!',
  banner_7: 'Enviar',
  banner_8: 'Ucraniano',
  banner_9: 'Cazaquistão',
  brand_awareness_1: 'Reconhecimento de Marca',
  brand_awareness_2: 'e Uso',
  brand_awareness_3:
    'Desbloqueie o potencial do reconhecimento e uso da sua marca para o sucesso nos negócios',
  brand_awareness_4: 'Fale com um especialista',
  brand_awareness_5: 'Sobre',
  brand_awareness_6:
    '<b>Avalie o grau de</b> reconhecimento e familiaridade da sua marca',
  brand_awareness_7:
    '<b>Determine o nível de reconhecimento da marca</b> no seu mercado-alvo',
  brand_awareness_8:
    '<b>Acompanhe de perto</b> as taxas de retenção e lealdade',
  brand_awareness_9:
    '<b>Escolha a estratégia ideal</b> para melhorar os indicadores necessários',
  brand_awareness_10: 'Explore inúmeras oportunidades com nossas soluções',
  brand_awareness_11: 'Reconhecimento de Marca',
  brand_awareness_12: 'Uso da Marca',
  brand_awareness_13:
    '<b>Descubra</b> o reconhecimento da sua marca entre os segmentos de consumidores',
  brand_awareness_14:
    '<b>Monitore</b> o nível de reconhecimento entre seu público',
  brand_awareness_15:
    '<b>Estude</b> os pensamentos espontâneos dos consumidores prestes a fazer uma compra',
  brand_awareness_16:
    '<b>Entenda</b> as principais fontes que contribuem para o reconhecimento da sua marca',
  brand_awareness_17:
    '<b>Considere</b> o número de consumidores que experimentaram os produtos ou serviços da sua marca',
  brand_awareness_18:
    '<b>Identifique</b> os consumidores que continuaram com sua marca após experimentá-la inicialmente',
  brand_awareness_19:
    '<b>Desbloqueie</b> a probabilidade de sua marca ser recomendada',
  brand_awareness_20:
    '<b>Descubra</b> a porcentagem de consumidores que consideram sua marca para uso futuro',
  brand_awareness_21: 'Quem se beneficia do Reconhecimento de Marca e Uso?',
  brand_awareness_22: 'Empresas lançando um novo produto',
  brand_awareness_23: 'Empresas revitalizando uma marca estabelecida',
  brand_awareness_24:
    'Qualquer empresa que busca entender e melhorar como sua marca é reconhecida e usada pelos consumidores',
  brand_awareness_25:
    'Nossas metodologias fornecem insights valiosos para otimizar seu reconhecimento de marca e uso',
  brand_awareness_26:
    'A metodologia é selecionada individualmente com base na solicitação do cliente',
  brand_awareness_27: 'Metodologia do funil de uso de marca',
  brand_awareness_28:
    'A taxa de teste delineia a proporção do público que conhece a marca e experimentou seus produtos ou serviços.',
  brand_awareness_29:
    'A taxa de repetição indica a porcentagem do público familiarizado com a marca e que experimentou seus produtos ou serviços, e continuou a fazer compras ou usar as ofertas nos últimos 12 meses.',
  brand_awareness_30:
    'A taxa de retenção revela a proporção de indivíduos que usaram consistentemente os produtos ou serviços da marca, indicando a porcentagem que se engajou com essas ofertas nos últimos 6 meses.',
  brand_awareness_31:
    'A taxa de retenção ilustra a porcentagem de clientes que, após usar os produtos/serviços da marca nos últimos 6 meses, continuam a escolher e demonstram lealdade à marca ao longo do tempo.',
  brand_awareness_32: 'Metodologia de reconhecimento de marca',
  brand_awareness_33:
    'Top of mind (TOM) representa a primeira marca mencionada espontaneamente pelo respondente.',
  brand_awareness_34:
    'O reconhecimento espontâneo total representa o total de menções espontâneas, incluindo TOM.',
  brand_awareness_35:
    'O reconhecimento auxiliado revela o quão bem sua marca é reconhecida pelos usuários quando é solicitada.',
  brand_awareness_36: 'Mapa de desempenho de atributos da marca',
  brand_awareness_37:
    'Essa ferramenta avalia e representa visualmente a importância de diferentes atributos da marca, fornecendo insights sobre como cada um contribui para o desempenho geral da sua marca e a percepção do consumidor.',
  brand_awareness_38:
    'Descubra as chaves para aprimorar o impacto da sua marca com nossa análise abrangente.',
  brand_awareness_39: 'Mapa perceptual de marcas',
  brand_awareness_40:
    'Essa ferramenta oferece uma representação visual das percepções dos consumidores no cenário competitivo do mercado. Essa abordagem é crucial para delinear claramente as diferenças entre as marcas, enfatizando os atributos de mercado essenciais.',
  brand_awareness_41:
    'Ela simplifica a observação e interpretação das relações entre as marcas e essas características definidoras.',
  brand_awareness_42:
    'A proximidade de uma marca específica a certos atributos significa sua forte associação com essas características. Da mesma forma, a proximidade entre duas marcas indica suas semelhanças, mostrando imagens compartilhadas e, consequentemente, uma afiliação dentro do mesmo segmento de mercado.',
  consumer_journey_map_1: 'Mapa da Jornada do Consumidor',
  consumer_journey_map_2:
    'Descubra e supere barreiras em seus negócios com o nosso Mapa da Jornada do Consumidor',
  consumer_journey_map_3: 'CONFIADO POR',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Desbloqueie o potencial do CJM para otimizar as interações entre seus consumidores e sua marca',
  consumer_journey_map_6:
    'Determine quais etapas o consumidor segue antes de comprar um produto',
  consumer_journey_map_7:
    'Obtenha insights dos seus reais clientes, não de clientes imaginários',
  consumer_journey_map_8:
    'Obtenha uma compreensão completa das experiências dos seus clientes',
  consumer_journey_map_9:
    'Identifique oportunidades perdidas e compare-se com seus concorrentes',
  consumer_journey_map_10: 'Descubra nossa abordagem do CJM',
  consumer_journey_map_11:
    'Criamos um caminho de interação do consumidor com um produto, serviço, empresa ou marca através de vários canais e durante um determinado período de tempo.',
  consumer_journey_map_12:
    'Descrevemos as etapas que seus consumidores percorrem antes de concluir uma compra, as expectativas, emoções e motivações do cliente em cada etapa.',
  consumer_journey_map_13: 'Beneficie-se conosco',
  consumer_journey_map_14:
    'Fornecemos uma equipe de profissionais experientes que entendem as complexidades do sucesso global nos negócios',
  consumer_journey_map_15:
    'Estamos dedicados a entregar resultados que superem suas expectativas',
  consumer_journey_map_16:
    'Descobrimos soluções complexas que contribuem para o seu crescimento',
  consumer_journey_map_17:
    'Somos especializados em fornecer soluções personalizadas de CJM',
  consumer_journey_map_18: 'Agende uma consulta',
  consumer_journey_map_19: 'Decisões baseadas em dados',
  consumer_journey_map_20:
    'Baseie suas decisões em dados reais, não em suposições',
  consumer_journey_map_21: 'Otimize seu CJM',
  consumer_journey_map_22:
    'Construa relações mais fortes com os clientes atendendo às suas necessidades e expectativas em cada etapa de sua jornada',
  consumer_journey_map_23: 'Lidere o caminho',
  consumer_journey_map_24:
    'Compare-se com seus concorrentes e fique à frente do mercado',
  consumer_journey_map_25: 'Recomendações',
  consumer_journey_map_26:
    'Implemente nossas recomendações de CJM para desbloquear o sucesso, fomentar a lealdade do cliente, impulsionar as vendas e maximizar o potencial do seu negócio',
  consumer_journey_map_27:
    'Descubra a verdadeira jornada do consumidor com o nosso Mapa da Jornada do Consumidor',
  consumer_journey_map_28:
    'Especialistas com mais de 20 anos de experiência oferecem uma pesquisa abrangente',
  consumer_journey_map_29:
    'A pesquisa qualitativa envolve a coleta e análise de dados não numéricos para entender conceitos, opiniões ou experiências.',
  consumer_journey_map_30:
    'Métodos de pesquisa quantitativa envolvem medir objetivamente e analisar numericamente os dados obtidos por meio de pesquisas, questionários e inquéritos.',
  consumer_journey_map_31:
    'Após coletar os dados, os especialistas analisam as informações e tiram conclusões para fornecer as melhores recomendações.',
  boarding_1: 'Mapa da jornada do cliente',
  boarding_2:
    'O Mapeamento da Jornada do Cliente (CJM) envolve definir um público-alvo, entender suas necessidades e ambiente e, em seguida, criar um plano para oferecer uma experiência envolvente',
  boarding_3: 'Pontuação de Promoção Líquida',
  boarding_4:
    'A Pontuação de Promotor Líquido (NPS) é o padrão ouro das métricas de experiência do cliente, com base em uma única pergunta: Quão provável seria recomendar a Organização/Produto/Serviço?',
  boarding_5: 'Monitoramento da Saúde da Marca',
  boarding_6:
    'A pesquisa de Monitoramento da Saúde da Marca (BHT) pode medir o desempenho da sua marca em termos de conscientização e uso, posicionamento da marca e desempenho da marca',
  boarding_7: 'Pesquisa de Recursos Humanos',
  boarding_8:
    'A pesquisa de Recursos Humanos é usada para avaliar práticas e desempenho de RH, oferecendo uma análise detalhada sobre o desenvolvimento e gestão atuais',
  boarding_9: 'Teste de UX',
  boarding_10:
    'O teste de usabilidade (UX) analisa como as pessoas reais interagem com um site, aplicativo ou outro produto, observando seu comportamento e reações',
  boarding_11: 'Índice de Satisfação do Cliente',
  boarding_12:
    'A satisfação do cliente mede a extensão em que as expectativas do cliente foram atendidas em relação à compra de um produto ou serviço fornecido pela sua empresa',
  boarding_13: 'Teste de Anúncios',
  boarding_14:
    'Uma campanha publicitária é um grande investimento. Certifique-se de que seus anúncios sejam poderosos e entreguem o máximo ROI',
  boarding_15: 'Auditoria de Campo/Auditoria de Varejo',
  boarding_16:
    'Coleta de dados detalhados sobre sua loja ou marca, análise de barreiras ao crescimento e criação de estratégias eficazes para superá-las',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Software de Gestão de Desempenho. Aplicativo gamificado para aumentar as vendas',
  boarding_19: 'Voicer',
  boarding_20:
    'Sistema de Gestão de Feedback. A voz do seu cliente em um único sistema. Sistema de Gestão de Feedback de Clientes',
  boarding_21: 'Pesquisa Qualitativa',
  boarding_22:
    'A pesquisa qualitativa coleta e analisa dados não numéricos para entender conceitos, opiniões ou experiências',
  boarding_23: 'Pesquisa Quantitativa',
  boarding_24:
    'Métodos de pesquisa quantitativa envolvem medição objetiva e análise numérica dos dados coletados por meio de pesquisas, questionários e inquéritos',
  boarding_25: 'Compras Misteriosas',
  boarding_26:
    'Compras misteriosas é uma técnica de pesquisa de mercado para medir e monitorar as interações entre um cliente e uma empresa ou organização durante um cenário pré-definido',
  boarding_27: 'Wantent',
  boarding_28:
    'Uma plataforma de inteligência de conteúdo alimentada por IA que fornece insights do público',
  boarding_29: 'Monitoramento de Preços',
  boarding_30:
    'O monitoramento de preços é o processo de coleta, processamento e análise dos preços dos concorrentes',
  boarding_31: 'Design de Serviço',
  boarding_32:
    'Design de serviço é como entender as necessidades e desejos das pessoas que usarão um produto ou serviço, passando tempo com elas',
  boarding_33: 'Análise de Preço da Indústria Automotiva',
  boarding_34:
    'A análise competitiva de preços otimiza as ofertas, aumenta os lucros e melhora a participação de mercado ao aproveitar os dados de disposição de pagamento do consumidor para identificar características-chave',
  boarding_35: 'Auditoria de Verificação de Instalação',
  boarding_36:
    'Garante que seus pontos de venda estejam alinhados com os padrões da marca, avaliando os atributos físicos e seu impacto na percepção do cliente',
  boarding_37: 'Solução CX',
  boarding_38:
    'Descubra nossa solução única de CX 360°: personalizamos ferramentas e serviços com base em seus objetivos para impulsionar o seu negócio',
  boarding_39: 'Análise de Discurso',
  boarding_40:
    'Nossa ferramenta avançada de análise de discurso transcreve a linguagem falada em texto, fornecendo insights valiosos para as empresas melhorarem a experiência do cliente, monitorarem o desempenho, garantirem a conformidade e impulsionarem o crescimento',
  boarding_41: 'Uso e Atitude',
  boarding_42:
    'Uso e Atitude é nossa abordagem para entender como o público interage com sua marca, analisando padrões práticos de uso e atitudes psicológicas, permitindo estratégias de marketing e produto personalizadas',
  boarding_43: 'Conscientização e Uso da Marca',
  boarding_44:
    'A Conscientização e o Uso da Marca envolvem avaliar o reconhecimento, a familiaridade, a frequência de interação e a retenção dentro do seu mercado-alvo para aumentar a visibilidade e o engajamento da marca',
  boarding_45: 'Identidade e Posicionamento da Marca',
  boarding_46:
    'A Identidade e o Posicionamento da Marca é uma análise abrangente que revela percepções dos consumidores, diferentes pontos de vista do público e insights sobre como sua marca é percebida',
  boarding_47: 'Perfil de Cliente e Segmentação',
  boarding_48:
    'O Perfil de Cliente e Segmentação envolve dividir seu mercado-alvo em grupos distintos com base em características compartilhadas, permitindo a criação de estratégias personalizadas que ressoem com segmentos específicos do público',
  boarding_49: 'Mapa da Jornada do Consumidor',
  boarding_50:
    'Um Mapa da Jornada do Consumidor é uma representação visual das etapas, pontos de contato, emoções e motivações vivenciadas por um consumidor durante sua interação com seu produto ou marca',
  boarding_51:
    'Saiba mais sobre técnicas inovadoras, que sustentam nosso compromisso de fornecer insights acionáveis e gerar resultados impactantes para o seu negócio',
  boarding_52:
    'Explore nossa suíte de produtos com uma riqueza de inteligência orientada por dados adaptada ao seu setor, permitindo que você tome decisões informadas e se mantenha à frente',
  boarding_53:
    'Mergulhe nas tendências de mercado, comportamento do consumidor e paisagens competitivas com nossas soluções abrangentes de pesquisa de mercado',
  boarding_54: 'Pesquisa Ad Hoc',
  boarding_55:
    'A Pesquisa Ad Hoc é uma abordagem personalizada projetada para resolver um problema específico, utilizando uma combinação de metodologias existentes ou uma nova abordagem para criar uma solução sob medida.',
  boarding_56: 'Pesquisa Sociológica',
  boarding_57:
    'A Pesquisa Sociológica revela os padrões de comportamento humano e interações sociais, fornecendo insights valiosos para impulsionar estratégias inovadoras e criar conexões significativas com públicos-alvo',
  not_found_1: 'Ops! Não conseguimos encontrar essa página.',
  not_found_2: 'Que tal conferir nossos produtos?',
  not_found_3: 'Agende uma consulta',
  customer_profiling_segmentation_1: 'Perfil e Segmentação de Clientes',
  customer_profiling_segmentation_2:
    'Nós ajudamos a identificar seu público-alvo, tornando sua estratégia mais eficaz',
  customer_profiling_segmentation_3:
    'Utilize o poder da segmentação de mercado para garantir que suas mensagens sempre alcancem seus grupos-alvo de consumidores de forma eficaz',
  customer_profiling_segmentation_4:
    'Compreenda os consumidores mais profundamente',
  customer_profiling_segmentation_5:
    'Encontre o melhor público-alvo para seu produto',
  customer_profiling_segmentation_6: 'Explore novos segmentos para atrair',
  customer_profiling_segmentation_7: 'Descubra barreiras para compra',
  customer_profiling_segmentation_8:
    'Somos especializados em fornecer soluções de segmentação personalizadas',
  customer_profiling_segmentation_9: 'Abordagem única',
  customer_profiling_segmentation_10:
    'Experimente uma solução de segmentação única e personalizada criada especificamente para suas necessidades',
  customer_profiling_segmentation_11: 'Personas Alvo',
  customer_profiling_segmentation_12:
    'Personas Alvo criadas com base em uma análise profunda de mercado e pesquisa abrangente',
  customer_profiling_segmentation_13: 'Novos segmentos',
  customer_profiling_segmentation_14:
    'Identifique seus segmentos de público mais valiosos e desbloqueie os benefícios de uma segmentação personalizada',
  customer_profiling_segmentation_15: 'Recomendações',
  customer_profiling_segmentation_16:
    'Implemente nossas recomendações e desbloqueie o sucesso, promova a lealdade do cliente, aumente as vendas e maximize o potencial de seu negócio',
  customer_profiling_segmentation_17:
    'Especialistas com mais de 20 anos de experiência fornecem uma pesquisa abrangente',
  customer_profiling_segmentation_18:
    'A pesquisa qualitativa envolve a coleta e análise de dados não numéricos para entender conceitos, opiniões ou experiências.',
  customer_profiling_segmentation_19:
    'Métodos de pesquisa quantitativa envolvem medir objetivamente e analisar numericamente os dados obtidos por meio de enquetes, questionários e pesquisas.',
  customer_profiling_segmentation_20:
    'Após coletar os dados, os especialistas analisam as informações e tiram conclusões para fornecer as melhores recomendações.',
  customer_profiling_segmentation_21:
    'Não apenas fazemos medições, também fornecemos um plano de ação com etapas claras',
  customer_profiling_segmentation_22: 'Nossa metodologia',
  customer_profiling_segmentation_23: 'Índice de Jaccard',
  customer_profiling_segmentation_24:
    'Quando os respondentes são questionados diretamente, suas respostas sobre preferências de empresas podem não corresponder ao comportamento real. Em vez de questionamentos diretos, usamos a análise estatística de Jaccard para entender a relação entre atributos da empresa e a escolha do consumidor.',
  customer_profiling_segmentation_25: 'Fatores impulsionadores',
  customer_profiling_segmentation_26:
    'Identificamos fatores (elementos que influenciam um determinado resultado) e os categorizamos em fatores chave, médios e não importantes, com base em sua importância.',
  customer_profiling_segmentation_27: 'Segmentação',
  customer_profiling_segmentation_28:
    'Você recebe segmentação com características claras das escolhas dos clientes.',
  customer_profiling_segmentation_29: 'Nossa abordagem para segmentação',
  customer_profiling_segmentation_30: 'Demográfico',
  customer_profiling_segmentation_31: 'idade',
  customer_profiling_segmentation_32: 'gênero',
  customer_profiling_segmentation_33: 'renda',
  customer_profiling_segmentation_34:
    'Isso ajuda a adaptar produtos, serviços e marketing para grupos específicos, melhorando a relevância e a eficácia',
  customer_profiling_segmentation_35: 'Psicográfico',
  customer_profiling_segmentation_36: 'estilo de vida',
  customer_profiling_segmentation_37: 'valores',
  customer_profiling_segmentation_38: 'comportamentos',
  customer_profiling_segmentation_39:
    'Isso permite marketing personalizado, conexões mais profundas com os clientes e maior lealdade e vendas para a empresa',
  customer_profiling_segmentation_40: 'Comportamental',
  customer_profiling_segmentation_41: 'ações',
  customer_profiling_segmentation_42: 'motivação',
  customer_profiling_segmentation_43: 'frequência de compra',
  customer_profiling_segmentation_44:
    'Isso permite marketing direcionado, maior satisfação do cliente, maior retenção e maior lucratividade para a empresa',
  customer_profiling_segmentation_45: 'Geográfico',
  customer_profiling_segmentation_46: 'localização',
  customer_profiling_segmentation_47:
    'Isso permite marketing direcionado, produtos personalizados, distribuição eficiente e maior penetração no mercado para a empresa.',
  brands_identity_1: 'Identidade e posicionamento da marca',
  brands_identity_2:
    'Transforme sua marca com nossa análise aprofundada, revelando insights sobre percepções dos consumidores e refinando sua estratégia para o sucesso',
  brands_identity_3: 'Fale com um especialista',
  brands_identity_4:
    '<b>Descubra uma análise abrangente</b> da identidade da marca',
  brands_identity_5:
    '<b>Explore os insights</b> reunidos sobre percepções dos consumidores',
  brands_identity_6: '<b>Entenda diversas</b> visões do público-alvo',
  brands_identity_7:
    '<b>Obtenha insights sobre</b> o comportamento, hábitos e preferências dos consumidores para decisões informadas',
  brands_identity_8:
    'Especialistas da 4Service com mais de 20 anos de experiência em atendimento ao cliente criam soluções únicas que ajudam a crescer seu negócio',
  brands_identity_9:
    '<b>Você terá acesso a um pacote de pesquisa</b> equipado com todos os dados essenciais. Ele serve como uma ferramenta diagnóstica, destacando pontos fortes e áreas que podem precisar de atenção',
  brands_identity_10:
    '<b>Nossos serviços fornecem as ferramentas necessárias</b> para que as empresas tomem decisões informadas, refinem estratégias e impulsionem o sucesso em seus respectivos setores.',
  brands_identity_11:
    'Para melhorar a posição da sua marca no mercado competitivo',
  brands_identity_12: 'Empresas que buscam melhorar sua presença no mercado',
  brands_identity_13: 'Empresas que buscam elevar sua estratégia',
  brands_identity_14: 'Identidade da Marca',
  brands_identity_15: 'Posicionamento da Marca',
  brands_identity_16:
    '<b>Descubra a percepção da sua marca</b> em diferentes segmentos de consumidores.',
  brands_identity_17:
    '<b>Identifique as qualidades atribuídas</b> à sua marca e aos concorrentes.',
  brands_identity_18:
    '<b>Avalie os pontos fortes</b> e as fraquezas para melhorias estratégicas.',
  brands_identity_19:
    '<b>Avalie o alinhamento</b> da imagem da sua marca com os valores promovidos.',
  brands_identity_20: '<b>Avalie a percepção de</b> confiança da sua marca.',
  brands_identity_21:
    '<b>Explore a percepção arquetípica</b> e simbólica da sua marca.',
  brands_identity_22:
    '<b>Identifique os pontos de venda exclusivos da sua marca</b> e diferenciais em comparação com os concorrentes.',
  brands_identity_23:
    '<b>Identifique os principais atributos que influenciam</b> a probabilidade de os consumidores comprarem seus produtos/serviços.',
  brands_identity_24:
    '<b>Determine como sua marca é posicionada</b> na mente dos consumidores e identifique aspectos a comunicar para atrair os segmentos de mercado desejados.',
  brands_identity_25:
    'A metodologia é escolhida individualmente com base na solicitação do cliente',
  brands_identity_26: 'Consciência e Uso da Marca',
  brands_identity_27:
    'Avaliar o nível da marca envolve medir os níveis de consciência e consumo, sendo um componente fundamental da avaliação.',
  brands_identity_28: 'Entrega e Confiança da Marca',
  brands_identity_29:
    'Essa métrica avalia a defesa da marca (recomendação) e a capacidade consistente da marca de cumprir suas promessas, criar experiências positivas para os clientes e construir confiança.',
  brands_identity_30: 'Desempenho da Imagem da Marca',
  brands_identity_31:
    'Avaliar o desempenho da marca envolve avaliar sua associação com seis valores cruciais (3 racionais e 3 emocionais), sua distintividade dentro da categoria e sua capacidade de gerar orgulho nos consumidores.',
  brands_identity_32: 'Pontuação de Saúde da Marca',
  brands_identity_33:
    'A Pontuação de Saúde da Marca oferece uma compreensão abrangente de como sua marca está se saindo no mercado competitivo.',
  sociological_research_1: 'Pesquisa Sociológica',
  sociological_research_2:
    'Realizamos uma análise aprofundada de fenômenos e processos sociais, o que ajuda a tomar decisões informadas com base em conclusões analíticas e recomendações únicas.',
  sociological_research_3: 'Entre em contato conosco',
  sociological_research_4: 'Nossos clientes',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    'CATI - é um método de coleta de dados que utiliza um computador para realizar pesquisas por telefone. O software CATI permite automatizar os processos de coleta de dados, inserção de respostas e gerenciamento de pesquisas. O uso do CATI facilita o controle da pesquisa, permite a análise rápida e processamento dos dados, e garante um alto nível de padronização da pesquisa.',
  sociological_research_7: 'CATI',
  sociological_research_8:
    'CAWI - é um método de coleta de dados em que os respondentes preenchem questionários de pesquisa ou respondem a perguntas por meio de uma interface web, e os dados são automaticamente coletados e processados por meio de software especializado. O método CAWI permite realizar pesquisas em grande escala, coletando e analisando dados rapidamente.',
  sociological_research_9: 'CAPI',
  sociological_research_10:
    'CAPI - é um método de coleta de dados para pesquisas presenciais em que o pesquisador usa um tablet ou celular para inserir as respostas dos entrevistados, o que permite a coleta automatizada de dados, reduzindo erros de entrada e simplificando a análise dos resultados.',
  sociological_research_11: 'Nossos especialistas usam diversas ferramentas',
  sociological_research_12: 'Amostra',
  sociological_research_13:
    'Calculamos e aplicamos amostras de qualquer complexidade',
  sociological_research_14:
    'Em nosso trabalho, utilizamos amostras simples, sistemáticas, estratificadas e em cluster',
  sociological_research_15:
    'Para pesquisas presenciais representativas nacionalmente, usamos procedimentos de amostragem estratificada em múltiplas etapas',
  sociological_research_16:
    'A seleção de assentamentos por regiões é realizada de acordo com o tamanho e tipo de assentamento, e seu número é determinado proporcionalmente ao volume em cada região',
  sociological_research_17: 'Realizamos',
  sociological_research_18: 'Pesquisa de opinião pública',
  sociological_research_19: 'Pesquisa socioeconômica',
  sociological_research_20: 'Pesquisa de mesa',
  sociological_research_21: 'Avaliação da qualidade dos serviços sociais',
  sociological_research_22: 'Estudo de caso',
  sociological_research_23: 'Pesquisa de pessoal',
  sociological_research_24:
    'Pesquisa sobre públicos específicos (grupos vulneráveis, incluindo deslocados internos, militares, jovens, etc.)',
  sociological_research_25: 'Trabalhamos com cada cliente individualmente',
  sociological_research_26: 'Nossos clientes:',
  sociological_research_27: 'Fundos de Caridade',
  sociological_research_28: 'Organizações públicas e sindicatos',
  sociological_research_29: 'Estruturas governamentais',
  sociological_research_30: 'Nossa abordagem de trabalho',
  sociological_research_31:
    'Usamos uma abordagem científica em nosso trabalho e um entendimento profundo da realidade atual, além de vários métodos de pesquisa para coleta de dados.',
  sociological_research_32:
    'Engajamos especialistas altamente especializados com a experiência necessária para atender a uma solicitação específica e temos um grupo de especialistas em diversas áreas.',
  sociological_research_33:
    'Nosso departamento de análise interno analisa os dados coletados usando um conjunto exclusivo de ferramentas, dependendo das especificidades da tarefa.',
  sociological_research_34:
    'Geramos relatórios com conclusões e recomendações detalhadas, levando em conta as necessidades e expectativas do seu público-alvo.',
  sociological_research_35:
    'Fornecemos aconselhamento e suporte durante a implementação das recomendações, ajudando você a fazer mudanças positivas.',
  sociological_research_36: 'Nossa pesquisa na TV',
  sociological_research_37: 'Confira nossos relatórios',
  sociological_research_38:
    'Nossa abordagem científica garante aos nossos clientes dados sociológicos precisos e confiáveis em toda a Ucrânia e na Europa com erro mínimo',
  sociological_research_39:
    'Trabalhamos em conformidade com todos os princípios e padrões de ética e regras para a realização de pesquisas sociológicas.',
  sociological_research_40:
    'Adotamos o Código de Ética Profissional para Sociólogos da Associação Sociológica da Ucrânia e a Declaração de Helsinque dos Princípios Éticos para Pesquisa. Membros da ESOMAR, Associação Ucraniana de Marketing, Associação Nacional de Centros de Contato da Ucrânia.',
  sociological_research_41: 'Campo',
  sociological_research_42: 'painel online',
  sociological_research_43: 'entrevistadores regulares',
  sociological_research_44: 'supervisores',
  sociological_research_45: 'Controle de qualidade de campo',
  sociological_research_46: 'Fixação de 100% das coordenadas GPS (CAPI)',
  sociological_research_47:
    'Validação de questionários (CAWI, CAPI, CATI, PAPI)',
  sociological_research_48: 'Gravação de 100% das entrevistas (CAPI, CATI)',
  sociological_research_49: 'Usamos software licenciado:',
  sociological_research_50:
    'SPSS para análise de dados estatísticos, processamento de conjuntos de dados',
  sociological_research_51:
    'DigSee Sure para CAPI: lógica de questionário, geolocalização, gravação de áudio das entrevistas, demonstração de materiais em vídeo',
  sociological_research_52:
    'SurveyMonkey para CAWI: lógica de questionário, formato conveniente, visualização',
  sociological_research_53:
    'VoIPTime (centro de contato): programação de questionários de qualquer complexidade, transferência de gravações de áudio para o cliente',
  sociological_research_54: 'Amostra',
  sociological_research_55:
    'Calculamos e aplicamos amostras de qualquer complexidade',
  sociological_research_56:
    'Em nosso trabalho, utilizamos amostras simples, sistemáticas, estratificadas e em cluster',
  sociological_research_57:
    'Para pesquisas presenciais representativas nacionalmente, usamos procedimentos de amostragem estratificada em múltiplas etapas',
  sociological_research_58:
    'A seleção de assentamentos por regiões é realizada de acordo com o tamanho e tipo de assentamento, e seu número é determinado proporcionalmente ao volume em cada região',
  sociological_research_59: 'Equipe',
  sociological_research_60:
    'Nossos sociólogos são membros da Associação dos Sociólogos da Ucrânia',
  sociological_research_61:
    'Temos 12 analistas em tempo integral com experiência em projetos quantitativos e qualitativos em várias indústrias.',
  sociological_research_62:
    'Temos uma forte equipe analítica de profissionais com mais de 10 anos de experiência em pesquisa. Temos cientistas, PhDs, MBI.',
  sociological_research_63: 'Iryna Maksymiuk',
  sociological_research_64:
    'Chefe do Departamento de Pesquisa. Mais de 15 anos de experiência em pesquisa de marketing, gestão de pesquisa em todas as etapas, preparação de relatórios analíticos, apresentações',
  sociological_research_65: 'Andriy Biloskursky',
  sociological_research_66:
    'Chefe de Programação e Processamento de Dados. 20 anos de experiência em pesquisa de marketing, sociológica e de mercado',
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    'Especialista em Programação Pós-graduanda em sociologia na DonSUU. 5 anos de experiência em pesquisa de marketing. Membro da Diretoria da Aliança Democrática das Mulheres, Chefe de Captação de Recursos.',
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    'Chefe da Divisão de Pesquisa Sociológica do Departamento de Pesquisa, tem 13 anos de experiência em projetos sociológicos e sociais em nível internacional e nacional. PhD em Sociologia, Chefe do Comitê de Pesquisa da Associação Ucraniana de Centros de Contato da Ucrânia.',
  ad_hoc_1: 'Ad Hoc',
  ad_hoc_2: 'Descubra nossa pesquisa alternativa para casos únicos',
  ad_hoc_3: 'Fale conosco',
  ad_hoc_4: 'Nossa Abordagem',
  ad_hoc_5:
    'Nossa equipe de especialistas analisa meticulosamente os dados coletados, proporcionando-lhe soluções complexas que contribuem para o seu crescimento.',
  ad_hoc_6:
    'Desenhamos uma pesquisa única, adaptada às suas necessidades específicas, utilizando uma gama diversificada de metodologias, incluindo pesquisas, entrevistas, grupos focais, análise de dados e mais.',
  ad_hoc_7: 'Por que escolher a pesquisa Ad Hoc?',
  ad_hoc_8: 'Flexibilidade',
  ad_hoc_9:
    'Nenhum projeto de pesquisa é igual ao outro. Com a pesquisa Ad Hoc, temos a flexibilidade de adaptar e evoluir a abordagem única conforme necessário, garantindo que seus insights permaneçam relevantes e acionáveis.',
  ad_hoc_10: 'Precisão',
  ad_hoc_11:
    'Ao adaptar nossas metodologias às suas necessidades exclusivas, fornecemos resultados precisos e direcionados que oferecem insights valiosos.',
  ad_hoc_12: 'Inovação',
  ad_hoc_13:
    'Abraçamos a inovação e a criatividade em nosso processo de pesquisa, explorando novas técnicas e abordagens para descobrir oportunidades e desafios ocultos.',
  ad_hoc_14: 'Pontualidade',
  ad_hoc_15:
    'A pesquisa Ad Hoc permite uma implementação rápida, permitindo que você acesse insights críticos de maneira oportuna, mantendo sua empresa à frente da concorrência.',
  ad_hoc_16: 'Nossa Expertise',
  ad_hoc_17:
    'Especialistas da 4Service com mais de 20 anos de experiência em pesquisa de mercado e experiência do cliente criam soluções únicas que ajudam a crescer o seu negócio',
  ad_hoc_18:
    'Utilizamos um conjunto distinto de ferramentas com base nas suas necessidades específicas',
  ad_hoc_19: 'Nenhuma metodologia funciona para você?',
  ad_hoc_20: 'Solicite a pesquisa Ad Hoc',
  ad_hoc_21: 'Adaptada para problemas específicos',
  ad_hoc_22:
    'Usamos uma combinação de diferentes partes das metodologias, bem como uma abordagem completamente nova',
  ad_hoc_23: 'Abordagem individualizada',
  ad_hoc_24:
    'Adaptamos cuidadosamente uma solução diretamente para o seu problema',
  ad_hoc_25: 'Ir além',
  ad_hoc_26:
    'Oferecemos um plano acionável com recomendações para ajudar sua empresa a atingir seus objetivos',
  ad_hoc_27: 'Você tem um problema não padrão',
  ad_hoc_28: 'Nenhuma das metodologias funciona para o seu problema',
  ad_hoc_29:
    'Você gostaria de solicitar diferentes metodologias ou partes delas ao mesmo tempo',
  ad_hoc_30: 'Quem se beneficia da pesquisa Ad Hoc?',
  service_design_1: 'Alguns dos nossos clientes',
  service_design_2: 'Design de Serviços',
  service_design_3:
    'Transforme o seu negócio de uma orientação para produtos e vendas para uma orientação para serviços e pessoas',
  service_design_4: 'Fale com um especialista',
  service_design_5: 'Aprenda gratuitamente!',
  service_design_6:
    'Coletamos informações práticas e úteis na nossa Biblioteca de Design de Serviços',
  service_design_7:
    'A Biblioteca contém descrições detalhadas de cada etapa, ferramentas e técnicas relevantes, dicas sobre detalhes importantes e vídeos demonstrativos.',
  service_design_8: 'Vá para a Biblioteca',
  service_design_9: 'Bem-vindo ao Design de Serviços!',
  service_design_10:
    'O Design de Serviços visa criar e melhorar serviços abordando todo o ecossistema de serviços — pessoas, processos e pontos de contato para melhorar tanto a satisfação do usuário quanto a eficiência operacional',
  service_design_11:
    'O design de serviços não é apenas o motivo de você preferir um café ao invés de outro. Também é a razão pela qual você volta a esse lugar repetidamente e conta para todos os seus amigos sobre ele.',
  service_design_12:
    'Como os negócios podem se beneficiar com o Design de Serviços',
  service_design_13: 'Satisfação do usuário',
  service_design_14:
    'Crie experiências personalizadas e sem falhas que encantem os usuários.',
  service_design_15: 'Eficiência operacional',
  service_design_16:
    'Otimize processos, reduza custos e melhore a entrega de serviços.',
  service_design_17: 'Consistência da marca',
  service_design_18:
    'Garanta que todos os pontos de contato estejam alinhados aos valores da marca.',
  service_design_19: 'Adaptabilidade às mudanças',
  service_design_20:
    'Desenhe serviços que possam crescer e evoluir com as necessidades em mudança.',
  service_design_21: 'Aumento da lealdade',
  service_design_22:
    'Construa relacionamentos mais fortes com os clientes e aumente a lealdade por meio de experiências excepcionais de serviço.',
  service_design_23: 'Vantagem competitiva',
  service_design_24:
    'Destaque-se no mercado com experiências de serviço únicas e memoráveis.',
  service_design_25: 'Por quê',
  service_design_26: 'Por que escolher o Design de Serviços?',
  service_design_27:
    'É uma cultura, metodologia e forma de pensar que ajuda empresas em todo o mundo a mudar de uma orientação para produtos e vendas para focar nas necessidades e problemas humanos.',
  service_design_28: 'Nossos serviços',
  service_design_29: 'Design de Serviço ao Cliente',
  service_design_30:
    'Desenvolvemos e implementamos projetos focados no cliente para criar novos produtos ou melhorar os existentes. Também oferecemos treinamento para o trabalho independente com essas mudanças.',
  service_design_31: 'Design de serviço para empregados',
  service_design_32:
    'Um curso prático para desenvolver e implementar processos, estruturas e mudanças voltadas para os empregados dentro da empresa.',
  service_design_33: 'Pensamento de Design do Futuro',
  service_design_34:
    'Desenvolvimento de conceitos futuros e estratégia empresarial, considerando diferentes cenários e possíveis inovações.',
  service_design_35: 'Criatividade nos Negócios',
  service_design_36:
    'Um treinamento prático que ajuda os participantes a usar a criatividade para resolver problemas específicos e desenvolver soluções de mercado exclusivas.',
  service_design_37: 'Nossos Workshops',
  service_design_38:
    'Oferecemos workshops práticos com certificação reconhecida mundialmente para nossos participantes',
  service_design_39:
    'Crie produtos, serviços e instalações que superem as expectativas',
  service_design_40: 'Implemente processos de negócios que funcionem',
  service_design_41:
    'Harmonize o relacionamento entre os empregados e os clientes da empresa',
  service_design_42: 'Calendário',
  service_design_43: 'Em breve',
  service_design_44: 'Saiba mais sobre nossos eventos anteriores',
  service_design_45: 'Fundamentos do Design de Serviços',
  service_design_46:
    'Crie produtos, serviços e instalações que superem as expectativas',
  service_design_47: 'EX Design de Serviços',
  service_design_48:
    'Crie produtos, serviços e instalações que superem as expectativas',
  service_design_49: 'Mais',
  service_design_50: 'Nossa abordagem',
  service_design_51:
    '4Service é a espinha dorsal do seu serviço centrado no ser humano!',
  service_design_52:
    'Ajudamos as empresas a desenvolver a mentalidade necessária e criar um ambiente interno centrado nas pessoas.',
  service_design_53: 'Ferramentas',
  service_design_54:
    'Utilizamos um conjunto distinto de técnicas e ferramentas com base nas suas necessidades específicas',
  service_design_55: 'Pesquisa com Usuários',
  service_design_56:
    'Entrevistas, pesquisas, F2F, estudos etnográficos para entender as necessidades e comportamentos dos usuários',
  service_design_57: 'Personas',
  service_design_58:
    'Personagens fictícios representando diferentes tipos de usuários para orientar decisões de design',
  service_design_59: 'Mapas da Jornada do Cliente',
  service_design_60:
    'Representações visuais da experiência do usuário ao longo do serviço, identificando os principais pontos de contato e pontos críticos',
  service_design_61: 'Sessões de Brainstorm',
  service_design_62:
    'Sessões colaborativas com stakeholders para gerar ideias e soluções.',
  service_design_63: 'Prototipagem',
  service_design_64:
    'Criar versões preliminares do serviço para testar e refinar conceitos',
  service_design_65: 'Mapas de Serviço',
  service_design_66:
    'Diagramas detalhados que mostram as relações entre os diferentes componentes e processos do serviço',
  service_design_67: 'Parceiro',
  service_design_68: '4Service & DTA',
  service_design_69:
    'A 4Service é o representante oficial exclusivo da Design Thinking Academy',
  service_design_70: 'Como parte de nossa parceria, nós:',
  service_design_71:
    '<b>oferecemos treinamento prático</b> usando o método de design de serviços em workshops com especialistas líderes;',
  service_design_72:
    '<b>treinamos especialistas</b> certificados em design thinking;',
  service_design_73:
    '<b>implementamos projetos de design de serviços</b> com facilitadores internacionais para muitas empresas.',
  service_design_74:
    'Estabelecemos Centros de Excelência em Design de Serviços em:',
  service_design_75: 'Ucrânia',
  service_design_76: 'Cazaquistão',
  service_design_77: 'Turquia',
  service_design_78: 'Geórgia',
  service_design_79: 'Azerbaijão',
  service_design_80: 'Saiba mais sobre nossos casos',
  four_opinion_1: 'Pesquisa da 4Service',
  four_opinion_2: 'Participe de pesquisas online por dinheiro e ganhe sem sair de casa',
  four_opinion_3: 'Começar',
  four_opinion_4: 'Nossas vantagens',
  four_opinion_5: 'Confiabilidade',
  four_opinion_6: 'Nossa comunidade conta com mais de 90 mil consumidores na Ucrânia e 100 mil no Cazaquistão',
  four_opinion_7: 'Valorizamos o seu tempo',
  four_opinion_8: 'Valorizamos o seu tempo, por isso, ao participar de pesquisas, você tem a oportunidade de receber um presente ou uma recompensa em dinheiro',
  four_opinion_9: 'A sua opinião tem um grande impacto',
  four_opinion_10: 'Cada um dos seus comentários ajuda as marcas a entender melhor as necessidades de seus clientes',
  four_opinion_11: 'Responda pesquisas no 4Opinion da 4Service e ganhe dinheiro online ou ganhe um prêmio',
  four_opinion_12: 'Junte-se ao 4Opinion',
  four_opinion_13: 'Mais histórias interessantes no nosso canal',
  four_opinion_14: 'Mais detalhes para a Ucrânia',
  four_opinion_15: 'Mais detalhes para o Cazaquistão',
  four_opinion_16: 'Por que nos escolhem?',
  four_opinion_17: '4Service é um grupo internacional de pesquisa especializado em gestão da experiência do cliente',
  four_opinion_18: 'Estamos entre as 10 principais agências europeias de gestão da experiência do cliente',
  four_opinion_19: 'Nossa missão é melhorar o atendimento ao cliente em todo o mundo!',
  four_opinion_20: 'Como começar a ganhar dinheiro com pesquisas online?',
  four_opinion_21: 'Cadastre-se no site',
  four_opinion_22: 'Receba notificações sobre pesquisas disponíveis',
  four_opinion_23: 'Responda as pesquisas até o final e ganhe dinheiro',
  four_opinion_24: 'Depoimentos reais de nossos respondentes',
  four_opinion_25: 'Vitaliy Kohan',
  four_opinion_26: '“Respondi à pesquisa e até esqueci disso. Depois de um tempo, a senhora Natalia me ligou e disse que eu tinha ganhado um prêmio. E aqui está meu prêmio: Samsung Galaxy M52 5G. Muito obrigado😁”',
  four_opinion_27: 'Victoria Popovska',
  four_opinion_28: '“Recebi a caixa de som, muito obrigada pelo sorteio honesto, justo e interessante.”',
  four_opinion_29: 'Yulia Alexandrovna',
  four_opinion_30: '“Provavelmente a coisa mais incrível que me aconteceu recentemente💕 Não só ganhei, mas também algo realmente incrível. Eu esperava qualquer coisa, mas nunca imaginei que fosse tão bom. Muito obrigada mais uma vez!”',
  four_opinion_31: 'Somente conosco você tem a oportunidade de ganhar dinheiro “real” ou um prêmio',
  four_opinion_32: 'Acompanhe as pesquisas disponíveis no nosso canal',
  four_opinion_33: 'Acessar o canal',
};
