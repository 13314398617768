import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersFourServiceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import logo from '../../../assets/images/shoppers/4service.png';

export const ShoppersFourService = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersFourServiceStyles>
      <div className="container">
        <div className="content">
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_26')}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'shoppers_27',
                ),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'shoppers_28',
                ),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'shoppers_29',
                ),
              }}
            />
          </ul>
          <ButtonWithArrowOnHover
            link="https://4serviceua.shopmetrics.com/auth/index.asp#signup"
            arrowColor="#000"
            textColor="#fff"
            borderColor="transparent"
            borderOnHover="#fff"
            background="linear-gradient(2.66deg, #0855E2 9.08%, #1076C7 90.92%)"
            className="btn"
            hoverBackground="#fff"
            hoverTextColor="#000"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'shoppers_7',
            )}
          />
        </div>
        <img src={logo} alt="4Service" />
      </div>
    </ShoppersFourServiceStyles>
  );
};
