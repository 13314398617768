import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import { DiaryStudyIntroStyles } from './styles';

export const DiaryStudyIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <DiaryStudyIntroStyles>
      <Banner
        bgColor="linear-gradient(273.29deg, #26F0A6 0%, #12BEE4 100%)"
        title={`${getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_1')}\n${getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_2')}`}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'diary_study_3',
        )}
        buttonKey="diary_study_4"
        btnColor="#000"
        btnHoverColor="#fff"
      />
    </DiaryStudyIntroStyles>
  );
};
