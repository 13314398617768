import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { StoryEmployeeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import logo from '../../../assets/images/employeeExperience/logo.png';

export const StoryEmployeeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <StoryEmployeeExperienceStyles>
      <div className="container">
        <img src={logo} alt="4Service" />
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_4')}</h2>
        <h4>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_5')}</h4>
        <h4 className="last">{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_6')}</h4>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'employee_experience_3',
          )}
          className="btn"
        />
      </div>
    </StoryEmployeeExperienceStyles>
  );
};
