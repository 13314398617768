import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { DiaryStudyAboutStyles } from './styles';
import about from '../../../assets/images/diaryStudy/about.png';

export const DiaryStudyAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <DiaryStudyAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'facility_check_audit_5')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_8')}
          </h2>
          <img src={about} alt="About" className="diary-img" />
          <p>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_9')}</p>
          <div className="gradient-block">
            {getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_10')}
          </div>
        </div>
      </div>
    </DiaryStudyAboutStyles>
  );
};
