import React from 'react';
import { ShoppersStyles } from './styles';
import { useAppSelector } from '../../state/hooks';
import {
  ShoppersApps,
  ShoppersCareer,
  ShoppersContact,
  ShoppersFAQ,
  ShoppersFourService,
  ShoppersInstruction,
  ShoppersIntro,
  ShoppersProjects,
  ShoppersSupport,
  ShoppersTeam,
} from '../../components';

export const Shoppers = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersStyles>
      <ShoppersIntro />
      <ShoppersTeam />
      <ShoppersProjects />
      <ShoppersCareer />
      <ShoppersFourService />
      <ShoppersInstruction />
      <ShoppersFAQ />
      <ShoppersSupport />
      <ShoppersApps />
      <ShoppersContact />
    </ShoppersStyles>
  );
};
