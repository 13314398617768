import styled from 'styled-components';

export const CatiQualityStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 991px) {
    gap: 25px;
    padding: 40x 0;
  }

  .cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px 30px;
    grid-template-areas:
      '. full full'
      '. . .';

    @media (max-width: 991px) {
      gap: 25px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      grid-template-areas:
        'full full'
        '. .'
        '. .';
    }

    @media (max-width: 768px) {
      gap: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 15px;
      max-width: 370px;
      height: auto;
      background: rgba(149, 16, 178, 0.05);
      border-radius: 5px;
      position: relative;

      &:nth-child(2) {
        grid-area: full;
        max-width: none;

        @media (max-width: 768px) {
          max-width: 370px;
        }
      }

      @media (max-width: 991px) {
        gap: 18px;
      }

      img {
        max-width: 38px;
      }

      h6 {
        font-weight: 600;
        font-size: 22px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;
        max-width: 370px;
        width: 100%;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;
        max-width: 370px;
        width: 100%;
      }
    }

    &-img {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: none !important;
      height: 100%;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
