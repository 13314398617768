import React, { useEffect } from 'react';
import { DiaryStudyStyles } from './styles';
import ProductsTabs from '../../components/productsTabs/ProductsTabs';
import { productsTabsData } from '../../constants';
import { useAppSelector } from '../../state/hooks';
import {
  DiaryStudyAbout,
  DiaryStudyBenefit,
  DiaryStudyExperts,
  DiaryStudyGetStarted,
  DiaryStudyIntro,
  DiaryStudyProvide,
  DiaryStudyResearch,
} from '../../components';
import OurClients from '../../components/ourClients/OurClients';

export const DiaryStudy = () => {
  useEffect(() => {}, []);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <DiaryStudyStyles>
      <DiaryStudyIntro />
      <DiaryStudyAbout />
      <DiaryStudyProvide />
      <DiaryStudyBenefit />
      <DiaryStudyExperts />
      <DiaryStudyResearch />
      <DiaryStudyGetStarted />
      <OurClients theme="white" />
      <ProductsTabs
        data={productsTabsData(interfaceLanguage)}
        theme="lightTheme"
      />
    </DiaryStudyStyles>
  );
};
