import styled from 'styled-components';

export const SelectFieldStyles = styled.div`
  label {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 8px;
    }
  }

  .select {
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #0d0d0d;
      border: 1px solid rgba(255, 255, 255, 0.05);
      height: 52px;
      position: relative;
    }

    &-value {
      display: flex;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      cursor: pointer;

      p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #ffffff;
      }

      img {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);

        &.active {
          transform: translate(0, -50%) rotate(180deg);
        }
      }
    }

    &-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 250px;
      overflow: auto;
      border-radius: 5px;
    }

    &-option {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      width: 100%;
      height: 52px;
      background: #1e1d1d;
      border: 1px solid rgba(255, 255, 255, 0.05);
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
      cursor: pointer;
    }
  }
`;
