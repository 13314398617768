import styled from 'styled-components';
import support from '../../assets/images/shoppers/support.png';

export const SupportStyles = styled.div`
  font-family: 'Montserrat';
  position: relative;
  overflow: hidden;
  width: 100%;

  &::before {
    background: url(${support}) no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 60%;

    @media (max-width: 768px) {
      height: 100%;
    }
  }

  &::after {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 60%;

    @media (max-width: 768px) {
      height: 100%;
    }
  }

  .container {
    max-width: 1190px;
    width: 100%;
    gap: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    padding: 150px 10px;
    z-index: 5;

    @media (max-width: 768px) {
      padding: 80px 10px;
      gap: 50px;
    }

    h1 {
      font-weight: 700;
      font-size: 60px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 36px;
      }
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    gap: 28px;
    background: #000000;
    border-radius: 5px;
    width: 100%;

    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 115%;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    form {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 28px;

      .utils {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &-submit {
          position: relative;
          display: flex;
          align-items: center;
          border: none;
          padding: 0 54px;
          height: 49px;
          flex-shrink: 0;

          background: #cbcbcb;
          border-radius: 100px;
          transition: 0.5s all;
          cursor: pointer;

          span {
            transition: 0.5s all;
            font-weight: 700;
          }

          svg {
            position: absolute;
            right: 0;

            opacity: 0;
            transition: 0.5s all;
          }

          &:hover {
            background-color: #fff;

            span,
            svg {
              transform: translateX(-20px);
            }

            svg {
              opacity: 1;
            }
          }
        }

        &-terms {
          display: flex;
          width: 100%;
          align-items: center;

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: #767676;
          }

          .checkboxWrapper {
            grid-gap: 24px;
          }

          .checkboxText {
            display: flex;
            align-items: center;
          }

          .customizedCheckbox {
            box-sizing: border-box;
            background: rgba(255, 255, 255, 0.23);
            border: 1px solid #ffffff;
            width: 25px;
            height: 25px;

            .checkMark {
              width: 15px;
              height: 15px;
              background: #fff;
            }
          }
        }
      }

      .fields {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 20px 30px;
        grid-template-areas:
          '. .'
          '. big'
          '. big'
          '. big';

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        &-item {
          box-sizing: border-box;
          height: 52px;
          background: #0d0d0d;
          border: 1px solid rgba(255, 255, 255, 0.05);
          border-radius: 5px;

          label {
            height: 100%;
            .inputWrap {
              height: 100%;

              .inputContainer {
                height: 100%;

                input {
                  height: 100%;
                  padding: 15px;
                }
              }
            }
          }
        }

        &-big {
          box-sizing: border-box;
          grid-area: big;
          background: #0d0d0d;
          border: 1px solid rgba(255, 255, 255, 0.05);
          border-radius: 5px;

          label {
            height: 100%;
            .inputWrap {
              height: 100%;

              .inputContainer {
                height: 100%;

                textarea {
                  height: 100%;
                  padding: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
