import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersContactStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import telegram from '../../../assets/images/shoppers/telegram.svg';

export const ShoppersContact = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersContactStyles>
      <div className="container">
        <h2
          dangerouslySetInnerHTML={{
            __html: getTranslationsByLangOrEng(
              interfaceLanguage,
              'shoppers_66',
            ),
          }}
        />
        <div className="contact-content">
          <div className="buttons">
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_67')}</p>
            <div className="buttons-wrapper">
              <a
                href="https://t.me/ua4servicesupport_bot"
                target="_blank"
                className="buttons-item"
                rel="noreferrer"
              >
                <img src={telegram} alt="Telegram UA" />
                <span>Telegram UA</span>
              </a>
              <a
                href="https://t.me/kz4servicesupport_bot"
                target="_blank"
                className="buttons-item"
                rel="noreferrer"
              >
                <img src={telegram} alt="Telegram KZ" />
                <span>Telegram KZ</span>
              </a>
            </div>
          </div>
          <div className="native">
            <div className="native-item">
              {getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_68')} <a href="tel:+0800604444">0 800 60 4444</a>
            </div>
            <div className="native-item">
              {getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_69')} <a href="mailto:ua@4service.support">ua@4service.support</a>
            </div>
          </div>
        </div>
      </div>
    </ShoppersContactStyles>
  );
};
