import React, { useEffect } from 'react';
import { EmployeeExperienceStyles } from './styles';
import {
  BannerEmployeeExperience,
  BusinessEmployeeExperience,
  ImpactEmployeeExperience,
  MethodologyEmployeeExperience,
  StoryEmployeeExperience,
  WhyEmployeeExperience,
} from '../../components';

export const EmployeeExperience = () => {
  useEffect(() => {}, []);

  return (
    <EmployeeExperienceStyles>
      <BannerEmployeeExperience />
      <BusinessEmployeeExperience />
      <WhyEmployeeExperience />
      <ImpactEmployeeExperience />
      <MethodologyEmployeeExperience />
      <StoryEmployeeExperience />
    </EmployeeExperienceStyles>
  );
};
