import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { CatiAboutStyles } from './styles';
import about from '../../../assets/images/cati/about.png';

export const CatiAbout = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiAboutStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'facility_check_audit_5')}
        </h5>
        <div className="content">
          <img src={about} alt="About" className="cati-img" />
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_5',
              ),
            }}
          />
        </div>
      </div>
    </CatiAboutStyles>
  );
};
