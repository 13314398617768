import React, { useMemo } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { WhyEmployeeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

type TCard = {
  title: string;
  text: string;
}

export const WhyEmployeeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const cards: TCard[] = useMemo(() => ([
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_33'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_34'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_35'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_36'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_37'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_38'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_39'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_40'),
    },
    {
      title: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_41'),
      text: getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_42'),
    },
  ]), [interfaceLanguage]);

  return (
    <WhyEmployeeExperienceStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_32')}</h2>
        <div className="cards">
          {cards.map((el) => (
            <div key={el.title} className="cards-item">
              <h4>{el.title}</h4>
              <p>{el.text}</p>
            </div>
          ))}
        </div>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'employee_experience_3',
          )}
          className="btn"
        />
      </div>
    </WhyEmployeeExperienceStyles>
  );
};
