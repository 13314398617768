export const uz: { [key: string]: string } = {
  subscribe_with_click: 'Bir marta bosish orqali obuna bo‘ling',
  subscribe: 'Bir marta bosish orqali obuna bo‘ling',
  minimum_number_characters: 'kamida 2 ta belgi kerak',
  request_details: 'So‘rov tafsilotlari',
  form_title: 'Tez orada siz bilan bog‘lanamiz',
  form_required: '* bilan belgilangan maydonlar majburiy',
  form_name_placeholder: 'Ism',
  form_email_placeholder: 'Email',
  form_phone_placeholder: 'Telefon raqami',
  form_company_placeholder: 'Kompaniya',
  form_submit: 'Yuborish',
  form_1: 'Men shaxsiy ma’lumotlarimni qayta ishlashga rozilik beraman',
  form_2: 'Maxfiylik siyosati',
  validation_privacy_policy: 'Iltimos, maxfiylik siyosatini qabul qiling',
  validation_phone: 'Telefon raqami noto‘g‘ri',
  validation_email: 'Email formati noto‘g‘ri',
  validation_required: 'Majburiy maydonlar to‘ldirilmagan',
  submit_error: 'Shaklni saqlashda xatolik yuz berdi',
  footer_1: 'Hamkorlarimiz:',
  footer_2: 'Usullar',
  footer_3: 'Mahsulotlar',
  footer_4: 'Aloqa:',
  footer_5: 'Imprint',
  footer_6: 'Maxfiylik siyosati',
  footer_7: 'Bozor tadqiqotlari',
  about_us_1: '4Service korporativ ijtimoiy mas’uliyati',
  about_us_2:
    'Jamoamiz har bir a’zosining yuragi va qalbidan. Biz har bir xodimimizda mehr-muruvvat uchqunini saqlab qolish va atrofimizdagi dunyoni biroz yaxshiroq qilish uchun harakat qilamiz',
  about_us_3: '4Service korporativ ijtimoiy mas’uliyati',
  about_us_4:
    'Jamoamiz har bir a’zosining yuragi va qalbidan. Biz har bir xodimimizda mehr-muruvvat uchqunini saqlab qolish va atrofimizdagi dunyoni biroz yaxshiroq qilish uchun harakat qilamiz',
  about_us_5: 'Service Mania biznes o‘yini',
  about_us_6: 'Bolalar va talabalar uchun loyihalarimiz',
  about_us_7: 'Xayriya / Ko‘ngillilik',
  about_us_8:
    'Bolalar uylariga, qariyalar uylariga yordam va Ukraina armiyasiga ko‘ngillilik. Bizning eng ta’sirli faoliyatimiz',
  about_us_9: 'Hayvonlarga g‘amxo‘rlik',
  about_us_10:
    'Ofisimizga uy hayvoningiz bilan bemalol kirishingiz mumkin. Biz boshpanalarga yordam beramiz, oziq-ovqat uchun mablag‘ yig‘amiz va ba’zan boshpanasiz hayvonlarga uy topishga harakat qilamiz',
  about_us_11: '4Service Group',
  about_us_12: 'Mijoz tajribasini boshqaruvchi xalqaro tadqiqot kompaniyasi.',
  about_us_13:
    'Biz mijozlarga yo‘naltirilgan kompaniyalarning savdosini oshirish va biznesini rivojlantirish bo‘yicha murakkab loyihalarni amalga oshiramiz.',
  about_us_14:
    'Avstriyaning Vena shahrida bosh ofisga ega xalqaro tadqiqot xoldingi. Kompaniya 2001 yilda tashkil etilgan bo‘lib, uning maqsadi mijozlari va biznes bilan o‘zaro aloqada bo‘lgan odamlarning hayot sifatini yaxshilashdir. “Forservice” nomi bejiz tanlanmagan, chunki u mijozlarga xizmat ko‘rsatish va sifatni ifodalaydi.',
  about_us_15:
    'Bu 4Service tarixining boshlanishi bo‘lib, Kiyev, Ukrainada sirli xarid qilish xizmatlarini ko‘rsatishda yetakchiga aylandi. Bugungi kunda u 52 ta mamlakatda tadqiqot kompaniyasi sifatida muvaffaqiyatli ishlashda davom etmoqda va mijoz tajribasini to‘liq tahlil qilish va yaxshilash uchun yangi imkoniyatlarni yaratmoqda',
  about_us_16: 'xizmatga bag‘ishlangan',
  about_us_17: 'Ukrainadan tashqaridagi birinchi ofis',
  about_us_18: 'Bosh ofisning Venaga ko‘chirilishi',
  about_us_19: 'MSPAning elita a’zosi, Yevropaning Top-10 agentligi',
  about_us_20: '9 ta mamlakatda ofislar ochildi',
  about_us_21: 'birinchi raqamli loyihalar, Voicer',
  about_us_22: 'DesignThinkers Academy hamkorlari',
  about_us_23: 'birinchi global CX loyihalari',
  about_us_24: 'Missiya',
  about_us_25:
    'Bizning missiyamiz – butun dunyo bo‘ylab mijozlarga xizmat ko‘rsatish sifatini yaxshilash! Bizning ilhom manbamiz – biz ishlayotgan odamlar va kompaniyalarda ko‘radigan sifat o‘zgarishlari.',
  about_us_26: '20+ yil mijoz tajribasi',
  about_us_27: '95 mamlakat',
  about_us_28:
    'Biz dunyo bo‘ylab eng yaxshi sanoat tajribasini to‘playmiz va ulashamiz',
  about_us_29: 'Bosh ofis Venada',
  about_us_30:
    'mamlakatlar. Biz dunyo bo‘ylab eng yaxshi sanoat tajribasini to‘playmiz va ulashamiz',
  about_us_31: 'xizmat sifati reytinglari',
  about_us_32: 'Biz Yevropaning Top-10 CX agentliklari orasidamiz',
  about_us_33: 'Vakolatxonalar / HQ Avstriyada',
  about_us_34: 'faol loyihalar',
  about_us_35: 'Sizning kelajakdagi ish joyingiz',
  about_us_36: 'Bizning ofislarimiz',
  about_us_37:
    '4Service ™ xodimlari uchun qulay ish muhiti yaratishga harakat qiladi. Xodimlarimiz ofisda, masofadan yoki gibrid rejimda – ularning qulayligi va samaradorligiga mos ravishda ishlaydi. Dam olish xonalari, bepul psixologik yordam va qulay muhit. Biz inson va hayvonlarga do‘stona munosabatdamiz.',
  about_us_38: 'Ilhomlantiruvchi jamoa',
  about_us_39:
    'Bizning ko‘p millatli jamoamiz mijozlarning fikrlarini tinglash va ularning fikr-mulohazalarini inobatga olishga sodiqdir. Biz mijozlarimizning tajriba xaritasini tuzishga ham shunchalik e’tibor beramiz, bu biz uchun yangi mahsulotlarni ishga tushirish yoki moliyaviy maqsadlarga erishish kabi muhimdir. Bizning faoliyatimiz jamiyatlarga ijobiy ta’sir ko‘rsatishga, innovatsiyalar yaratishga va mijozlar uchun odam markazli echimlar taqdim etishga qaratilgan.',
  about_us_40: 'Barchasi',
  about_us_41: 'Rahbariyat',
  about_us_42: 'Jamoa Global',
  about_us_43: 'Mahsulot egalari',
  about_us_44: 'Hamkorlarimiz',
  about_us_45:
    'Biz mijozlarga yo‘naltirilgan kompaniyalarning savdosini oshirish va biznesini rivojlantirish bo‘yicha murakkab loyihalarni amalga oshiramiz',
  about_us_46:
    '25 mamlakatda faoliyat yurituvchi dizayn yo‘naltirilgan yetakchi professional o‘quv institutlaridan biri. Dizayn fikrlash murakkab muammolarga innovatsion g‘oyalar va echimlarni ishlab chiqish va yetkazib berishga yordam beradi.',
  about_us_47:
    'Ularning missiyasi jamoalar va shaxslar uchun ilhomlanish, sinovdan o‘tish va ijodiy muammolarni hal qilish qobiliyatini mustahkamlash uchun sharoit yaratishdir.',
  about_us_48: 'Sirli xarid sanoatidagi kompaniyalar uchun asosiy tashkilot.',
  about_us_49:
    'Bu notijorat a’zolik tashkilotidir. A’zolar jamiyat, tashkilot va har bir inson hayotini yaxshilash uchun tahliliy ma’lumotlardan foydalanish imkoniyatiga ishonadilar.',
  about_us_50: 'ESOMAR bosh ofisi Amsterdam, Niderlandiyada joylashgan.',
  about_us_51:
    '(UCCA) – bu kontakt markazi va mijozlarga xizmat ko‘rsatish sohasida rivojlanish va hamkorlik qilish maqsadida birlashgan xususiy ekspertlar va kompaniyalar professional hamjamiyati. UCCA falsafasi zamonaviy texnologiyalar va yuqori standartlarga asoslangan holda kontakt markazi sanoati madaniyatini oshirishdan iborat.',
  about_us_52:
    'Ukraina Kontakt Markazlari Assotsiatsiyasi 2010 yilda tashkil etilgan',
  about_us_53:
    'Ukraina bozorida tatbiq etish va qo‘llash uchun ta’lim sohasidagi eng yaxshi jahon tajribalarini o‘rganadi. Shu sababli, Academy DTEK yetakchi xalqaro biznes maktablari – INSEAD, IE Business School va Stanford University bilan hamkorlik qiladi.',
  about_us_54:
    'Academy DTEK mijozga yo‘naltirilgan va innovatsion madaniyatni rivojlantirishga e’tibor qaratadi: D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School raqamli dasturi, Effective Leader: Pre-MBA, Change Energy.',
  about_us_55: 'Sergey Pashkovskiy',
  about_us_56: 'Viktoriya Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Maks Papka',
  about_us_59: 'Xalqaro savdo menejeri',
  bht_1: 'Brend salomatligi',
  bht_2: 'Monitoring',
  bht_3: 'Brendlar nihoyatda kuchli',
  bht_4: 'Brendingiz qanday natija ko‘rsatayotganini bilib oling',
  bht_5: 'Haqida',
  bht_6: 'Brend salomatligini monitoring qilish nima?',
  bht_7:
    'Bu muayyan muntazamlikda (har ikki haftada, har oy va h.k.) o‘tkaziladigan bozor tadqiqotining bir turi bo‘lib, quyidagi ma’lumotlarni olishga xizmat qiladi:',
  bht_8:
    'brendning bozordagi holati – taniqlilik, talab, imidj, iste’molchilar xususiyatlari, brend salomatligi va boshqalar kabi ko‘rsatkichlar asosida baholanadi.',
  bht_9: 'Brendni eng ko‘p xarid qiladiganlar',
  bht_10: 'Sodiq mijozlar',
  bht_11: 'Brendni 3 oy davomida xarid qilgan',
  bht_12: 'Doimiy / muntazam mijozlar',
  bht_13: 'Brendni 6 oy davomida xarid qilgan',
  bht_14: 'Qisqa muddatli mijozlar',
  bht_15: 'Brendni bir yil davomida xarid qilgan',
  bht_16: 'Uzun muddatli mijozlar',
  bht_17: 'Brendni bilishadi, ammo hech qachon ishlatmagan/yoki sotib olmagan',
  bht_18: 'Biladi, lekin mijoz emas',
  bht_19: 'Brend haqida hech qachon eshitmagan',
  bht_20: 'Behabar',
  bht_21: 'Brend xaridorlari profili',
  bht_22:
    'Brend yaratish yo‘lidagi uzoq muddatli sa’y-harakatlarning izchilligini baholash',
  bht_23: 'Mijozlarning brend haqidagi bilim darajasini o‘lchash',
  bht_24: 'Reklama va axborot kampaniyalarining samaradorligini ko‘rsatish',
  bht_25:
    'Yuqori reklamalar davri raqobatchilarning savdo/marketing faoliyatiga qanday ta’sir qilishini aniqlash',
  bht_26:
    'Bozordagi doimiy o‘zgarishlar sharoitida biznes egasi uchun uzluksiz nazoratni ta’minlash',
  bht_27: 'BHT yordamida qanday vazifalar hal etilishi mumkin:',
  bht_28: 'Tadqiqotga buyurtma bering',
  bht_29: 'Hal qilinishi lozim bo‘lgan vazifa',
  bht_30: 'Masalan,',
  bht_31:
    'agar kompaniyaga bozor ulushini o‘lchash vaqtida har chorakda faqat bitta BHT sessiyasi kerak bo‘lsa, respondentlar so‘rovi mijozlarning brendni reklama orqali bilish darajasini o‘lchashda tez-tez o‘tkazilishi kerak.',
  bht_32: 'CATI',
  bht_33: 'Yuzma-yuz suhbat',
  bht_53: 'Suhbatlar',
  bht_34: 'СAWI',
  bht_35: 'Hal qilinishi kerak bolgan vazifa',
  bht_36: 'Ma’lumotlarni yig‘ish orqali',
  bht_37:
    'turli usullar orqali brend monitoringi odatda uch bosqichga bo‘linadi, maqsadli auditoriya va ularga yetib borish qiyinligiga qarab:',
  bht_38:
    'Ma’lumot yig‘ish uchun an’anaviy va zamonaviy usullardan foydalanamiz:',
  bht_39:
    'Biz ikkita model bilan ishlaymiz: birinchisi oddiy ko‘rsatkichlar to‘plamiga ega, ikkinchisi esa oziq-ovqat va xizmat ko‘rsatish sohalari kabi murakkabroq sanoatlarga mo‘ljallangan. Murakkab model chuqur o‘rganishni o‘z ichiga oladi va 10 tagacha baholash indekslarini o‘z ichiga oladi.',
  bht_40: 'Biz taklif qilamiz',
  bht_41: 'dastlabki ma’lumotlarning nazorati',
  bht_42: 'Biz qamrab olamiz',
  bht_43: 'butun mamlakatni',
  bht_44: 'Biz yechim taqdim etamiz',
  bht_45:
    'noo‘zgarmas bozorlar / cheklangan ishtirokchilar soniga ega bozorlar uchun (Brand Point Analyze)',
  bht_46: 'Nima uchun biz?',
  bht_47: 'Nima uchun bizni tanlash kerak?',
  bht_50: 'Yomon natija',
  bht_51: 'Yaxshi natija',
  bht_52: 'O‘rtacha natija',
  cases_1:
    'Muammo: Hyundai salonlarida mijozlar uchun elektromobil xarid qilish tajribasini qanday yaxshilash mumkin?',
  cases_2: 'Mijoz yo‘li xaritasi',
  cases_3: 'Ideyalashtirish',
  cases_4: 'Sifat tadqiqotlari',
  cases_5:
    'Muammo: Oppo brendiga mijozlarni jalb qilish uchun do‘konlarda (xizmat jarayoni, interyer va brend qabul qilish bo‘yicha) nimalarni o‘zgartirish kerak?',
  cases_6: 'Onlayn CJM',
  cases_7: 'UI testlash',
  cases_8: 'Suhbatlar',
  cases_9:
    'Muammo: Oppo brendiga mijozlarni jalb qilish uchun do‘konlarda nimalarni o‘zgartirish kerak?',
  cases_10: 'Sifat qismi',
  cases_11: 'Ideyalashtirish',
  cases_12: 'Miqdoriy qism',
  cases_13: 'Mijoz yo‘li xaritasi',
  cases_14: 'Keyslar',
  cases_15: 'Barchasi',
  cases_16: 'Keling, siz bilan ajoyib keýs yaratamiz',
  csi_1: 'Mijoz',
  csi_2: 'qoniqish indeksi',
  csi_3: 'Iste’molchilarning sabablarini aniqlash',
  csi_4: 'qoniqish va norozilik',
  csi_5: 'Mijoz qoniqishini tadqiq qilish',
  csi_6:
    'mijozning mahsulot sotib olish haqidagi qaroriga ta’sir qiluvchi omillar, sabablar va motivlarni aniqlash hamda maqsadli auditoriyaning xatti-harakatlarini tushunishdir.',
  csi_7: 'Mijoz qoniqish so‘rovi',
  csi_8:
    'mijozning xizmat yoki mahsulotning muayyan jihatlaridan qanchalik qoniqqan yoki norozi ekanligini ko‘rsatadi.',
  csi_9: 'Qoniqish so‘rovi',
  csi_10:
    'kompaniyaning mavjud mijozlari va mahsulot/xizmat foydalanuvchilari o‘rtasida o‘tkaziladi.',
  csi_11: 'CSI nima?',
  csi_12:
    'Mijoz qoniqishi – bu kompaniyangiz tomonidan taqdim etilgan mahsulot yoki xizmatni sotib olish bo‘yicha mijozning kutganlari qanchalik qondirilganligini o‘lchashdir.',
  csi_13: 'Baxtli odam',
  csi_14: 'Haqida',
  csi_15:
    'Mijoz qoniqishiga ta’sir qilishi mumkin bo‘lgan yashirin omillarni aniqlash',
  csi_16: 'bilan bog‘liq bo‘lishi mumkin',
  csi_17: 'Sabablarni aniqlash',
  csi_18:
    'tovar va xizmatlarga talabning ko‘tarilishi yoki tushishining sabablarini aniqlash',
  csi_19: 'Kompaniyaning',
  csi_20: 'biznes jarayonlarini optimallashtirish',
  csi_21: 'Ularni moslashtirish',
  csi_22: 'zamonaviy iste’molchi ehtiyojlariga mos keladigan tarzda',
  csi_23: 'Samarali reklama xabarini shakllantirish',
  csi_24: 'va mahsulotni mijoz talablariga muvofiq joylashtirish',
  csi_25: 'Hal qilinishi kerak bo‘lgan vazifalar',
  csi_26: 'Mijoz qoniqish so‘rovlari qanday muammolarni hal qilishi mumkin',
  csi_27: 'Telefon ushlab turgan odam',
  csi_28: 'Biz matematik model yaratamiz',
  csi_29:
    'mijoz qoniqishi/mijoz sadoqatiga ta’sir qilishi mumkin bo‘lgan omillar (regression tahlili)',
  csi_30: 'Biz optimallashtiramiz',
  csi_31: 'kompaniyaning biznes jarayonlarini',
  csi_32: 'Biz hisoblangan mijoz qoniqish indeksini yaratamiz',
  csi_33: '(CSI) va uni deklarativ indeks bilan taqqoslaymiz',
  csi_34: 'Biz tadqiqot o‘tkazamiz',
  csi_35: 'ham B2B, ham B2C segmentlari uchun.',
  csi_36: 'Bizning so‘rovnomalarimiz maxsus',
  csi_37:
    'mijozlarimizning individual loyihalari uchun tahlilchilar jamoasi tomonidan ishlab chiqiladi',
  csi_38: 'Nima uchun biz',
  csi_39: 'Nima uchun bizni tanlash kerak?',
  csi_40: 'Ma’lumot yig‘ish usullari:',
  main_1:
    'mijozlarimiz mijozlari tajribasidan o‘rganib, o‘z moliyaviy natijalarini yaxshilaydi',
  main_2: 'СX tadqiqot va konsalting xizmatlari: biznesingizga 360° qarash',
  main_3:
    'Biznesingizni qo‘llab-quvvatlash uchun bizning yechim va vositalarimiz bilan tanishing',
  main_4: 'Mahsulotlar',
  main_5:
    'Sifat tadqiqoti – bu tushunchalar, fikrlar yoki tajribalarni tushunish uchun sonli bo‘lmagan ma’lumotlarni yig‘ish va tahlil qilish',
  main_6:
    'Miqdoriy tadqiqot usullari – so‘rovnomalar, anketalar va so‘rovlar orqali to‘plangan ma’lumotlarni ob’ektiv o‘lchash va tahlil qilish',
  main_7:
    'Sirli xarid qilish – mijoz va kompaniya yoki tashkilot o‘rtasidagi o‘zaro ta’sirni oldindan belgilangan ssenariy asosida o‘lchash va kuzatish bo‘yicha bozor tadqiqoti usuli',
  main_8:
    'Fikr-mulohaza boshqaruv tizimi. Mijozingizning ovozi bitta tizimda – mijoz fikr-mulohazalarini boshqarish tizimi',
  main_9:
    'Sun’iy intellektga asoslangan kontent tahlil platformasi auditoriyadan tushunchalar taqdim etadi',
  main_10:
    'Ish faoliyatini boshqarish dasturi. Savdoni oshirish uchun gamifikatsiyalangan ilova',
  main_11:
    'Narxlarni monitoring qilish – raqobatchilarning narxlarini to‘plash, qayta ishlash va tahlil qilish jarayoni',
  main_12:
    'Xizmat dizayni – mahsulot yoki xizmatdan foydalanadigan odamlarning ehtiyoj va istaklarini ularning hayotiga kirib tushunish usuli',
  main_13:
    'Bizning tahliliy jamoamiz eng zamonaviy va asosli yondashuvlarni qo‘llaydi, biz ularni ko‘plab mijozlar bilan muvaffaqiyatli sinab ko‘rganmiz',
  main_14: 'Metodologiyalar',
  main_15: 'Mijoz yo‘li xaritasi',
  main_16:
    'Mijoz yo‘li xaritasi (CJM) – bu maqsadli auditoriyani aniqlash, ularning ehtiyojlari va muhiti haqida tushuncha hosil qilish, so‘ng esa jozibador tajribani yetkazib berish bo‘yicha reja yaratish',
  main_17: 'CJM',
  main_18: 'Net Promotion Score',
  main_19:
    'Net Promoter Score (NPS) – mijoz tajribasini o‘lchash bo‘yicha oltin standart bo‘lib, bitta savolga asoslangan: Siz tashkilot/mahsulot/xizmatni tavsiya qilish ehtimoli qanchalik yuqori?',
  main_20: 'NPS',
  main_21: 'Brend sog‘lig‘ini kuzatish',
  main_22:
    'Brend sog‘lig‘ini kuzatish (BHT) tadqiqoti brendning tanilishi va foydalanilishi, brend joylashuvi hamda brend samaradorligini o‘lchash imkonini beradi',
  main_23: 'BHT',
  main_24: 'Inson resurslari tadqiqoti',
  main_25:
    'Inson resurslari tadqiqoti HR amaliyotlari va samaradorligini baholash uchun ishlatiladi hamda hozirgi rivojlanish va boshqaruv bo‘yicha batafsil tahlilni taqdim etadi',
  main_26: 'HR tadqiqoti',
  main_27: 'Mijoz qoniqish indeksi',
  main_28:
    'Mijoz qoniqish indeksi (CSI) kompaniyangiz mahsulot/xizmatlaridan mijozlar qanchalik mamnun ekanligini ko‘rsatadigan bahodir.',
  main_29: 'CSI',
  main_30: 'UX testlash',
  main_31:
    'Foydalanuvchanlik (UX) testlash – haqiqiy foydalanuvchilar veb-sayt, ilova yoki boshqa mahsulot bilan qanday o‘zaro ta’sir qilishini va ularning xatti-harakatlari va reaktsiyalarini kuzatish jarayoni',
  main_32: 'Nima uchun biz?',
  main_33:
    'Bizning vazifamiz – korxonalarga yuqori samarali mijoz tajribasini taqdim etishga yordam berish',
  main_34: 'Sifatga e’tibor qaratish',
  main_35: 'Biz sifat nazoratiga egamiz',
  main_36:
    'tadqiqot ma’lumotlari borasida. Biz barcha ma’lumotlar to‘plamini mijozlar uchun ochiq qilamiz',
  main_37: 'Tejamkorlik',
  main_38: 'Bizning tajribamiz, resurslarimiz va optimallashtirilgan',
  main_39: 'biznes jarayonlarimiz sizga vaqt va mablag‘ tejash imkonini beradi',
  main_40: 'Aqlli yechimlar',
  main_41: 'Biz turli usullarni ishlatamiz',
  main_42:
    'va vositalar to‘plamidan foydalanib, sizning muammolaringizni hal qilamiz',
  main_43: 'Sifatga e’tibor qaratish',
  main_44: 'Biz sifat nazoratini ta’minlaymiz',
  main_45:
    'tadqiqot ma’lumotlariga kelsak. Biz barcha ma’lumotlar to‘plamini mijozlar uchun taqdim etamiz',
  main_46: 'Tejamkorlik',
  main_47: 'Bizning tajribamiz, resurslarimiz va optimallashtirilgan',
  main_48: 'biznes jarayonlarimiz sizga vaqt va mablag‘ tejash imkonini beradi',
  main_49: 'Aqlli yechimlar',
  main_50: 'Biz turli usullarni ishlatamiz',
  main_51:
    'va vositalar to‘plamidan foydalanib, sizning muammolaringizni hal qilamiz',
  main_52: 'Cash & Carry',
  main_53: 'Elektronika',
  main_54: 'Hashamat/Premium',
  main_55: 'Oshxona va mehmondo‘stlik',
  main_56: 'Chakana savdo',
  main_57: 'Avtomobil sanoati',
  main_58: 'Moda',
  main_59: 'Banklar',
  main_60: 'Sayohat va dam olish',
  main_61: 'Soatlar va zargarlik buyumlari',
  main_62: 'Uy-ro‘zg‘or buyumlari va texnika',
  main_63: 'Blog',
  main_64: 'Ko‘proq qiziqarli postlarni o‘qish uchun blogga o‘ting',
  main_65: 'Chakana audit',
  main_66:
    'Do‘kon yoki brendingiz haqida batafsil ma’lumot to‘plang, o‘sish to‘siqlarini tahlil qiling va ularni yengib o‘tish uchun samarali strategiyalar yarating',
  cjm_1: 'Mijoz',
  cjm_2: 'Journey map',
  cjm_3: 'Mijozlar olamiga kiring va ularning tajribasini baham ko‘ring',
  cjm_4: 'Customer Journey Map nima?',
  cjm_5:
    'CJM iste’molchilarning mahsulot, xizmat, kompaniya yoki brend bilan turli kanallar orqali va muayyan vaqt davomida o‘zaro ta’sir tarixini vizual tarzda taqdim etadi.',
  cjm_6:
    'Bu kompaniyalarga mijozlarning tajribasini ob’ektiv tahlil qilish, yuzaga keladigan to‘siqlarni bartaraf etish va mahsulotni yaxshilash bo‘yicha tavsiyalar berish imkonini beradi.',
  cjm_7: 'MAni aniqlash, segmentatsiya',
  cjm_8: 'Mijozlar bilan o‘zaro ta’sir bosqichlarini aniqlash',
  cjm_9: 'Asosiy mijozlar o‘zaro ta’sir kanallarini aniqlash',
  cjm_10: 'Mijoz tajribasini o‘rganish',
  cjm_11: 'To‘siqlarni tahlil qilish va optimallashtirish bo‘yicha gipotezalar',
  cjm_12: 'Gipotezalarni bajarish va sinovdan o‘tkazish',
  cjm_13: 'Customer Journey Map yaratish quyidagi bosqichlardan iborat:',
  cjm_14: 'Customer Journey Map quyidagilarni ko‘rsatadi:',
  cjm_15:
    'CJM iste’molchilarning mahsulot, xizmat, kompaniya yoki brend bilan turli kanallar orqali va muayyan vaqt davomida o‘zaro ta’sir tarixini vizual tarzda taqdim etadi.',
  cjm_16: 'Biz jamoaviy ishni ta’minlaymiz',
  cjm_17: 'analitiklar va dizaynerlar o‘rtasida',
  cjm_18: 'Biz individual',
  cjm_19: 'yondashuvni taklif qilamiz',
  cjm_20: 'Biz umuman',
  cjm_21:
    'mijozlarning xizmat/mahsulotdan qoniqish darajasini oshirib, ularning kompaniyaga sodiqligini kuchaytirishimiz mumkin',
  cjm_22: 'Biz kafolat beramiz',
  cjm_23:
    'iste’molchi yo‘lini optimallashtirishga va kompaniyaning biznes jarayonlarini hisobga olishga',
  cjm_24: 'Biz mijozlarga taklif qilamiz',
  cjm_25:
    'kombinatsiyalangan yondashuvlardan foydalanish imkoniyatini: CATI, CAWI, fokus guruhlar, chuqur intervyular, ekspert intervyular, etnografiya va boshqalar.',
  cjm_26: 'Nima uchun bizni tanlashadi?',
  methods_hr_1: 'Kadrlar tadqiqoti',
  methods_hr_2:
    'Jamoangiz haqida ob’ektiv tushunchaga ega bo‘ling va HR samaradorligini oshirish yo‘llarini toping',
  methods_hr_3:
    'HR tadqiqotiga ehtiyoj bo‘lishi mumkin bo‘lgan sabablardan ba’zilari',
  methods_hr_4: 'intervyu o‘tkazilgan xodimlar',
  methods_hr_5: 'amalga oshirilgan HR loyihalari',
  methods_hr_6: 'o‘tkazilgan xodimlar treninglari',
  methods_hr_7:
    'Xodimlar va mijoz tajribasini o‘rganish bo‘yicha yetakchi yevropa xoldingi',
  methods_hr_8: '4service HR tadqiqotlari raqamlarda',
  methods_hr_9:
    'Kompaniyangiz xodimlari hayotining har bir bosqichida muammolar va zaif tomonlarni aniqlash uchun: izlash/yollash/moslashuv/karyera rivojlanishi/sertifikatsiyalar',
  methods_hr_10:
    'Qachon qo‘shimcha to‘lov va mukofot berish kerakligini bilish uchun',
  methods_hr_11:
    'Xodimlarning noroziligini oldindan bashorat qilish yoki hal qilish uchun',
  methods_hr_12:
    'HR tadqiqotlari orqali HR siyosati, dasturlari va amaliyotlarini baholashning yangi va mavjud usullarini qo‘llash mumkin',
  methods_hr_13: 'Kompaniya xodimlarining ruhiy salomatligini yaxshilash uchun',
  methods_hr_14:
    'Maqsadga yo‘naltirilgan yondashuv orqali mahsuldorlikni oshirishni xohlasangiz',
  methods_hr_15:
    'Agar xalqaro va/yoki masofaviy jamoangiz bo‘lsa va uni boshqarishingiz kerak bo‘lsa',
  methods_hr_16:
    'Ish beruvchi brendingizning potentsial xodimlar uchun qanchalik jozibador ekanligini tushunish uchun',
  methods_hr_17: 'Xodimlar qoniqishi',
  methods_hr_18: 'HR brending tadqiqoti',
  methods_hr_19: 'Madaniy xilma-xillik',
  methods_hr_20: 'Kompensatsiya va maosh rejalari',
  methods_hr_21: 'Jamoa ishtiroki va sodiqlik',
  methods_hr_22: 'Himoyalangan toifa xodimlari',
  methods_hr_23: 'Tadqiqot yo‘nalishlari',
  methods_hr_24: 'Yechim 1',
  methods_hr_25: 'eNPS, xodimlar qoniqishi, xodimlar sodiqligi',
  methods_hr_26: 'Nimani o‘lchaymiz:',
  methods_hr_27: 'Sodiqlik:',
  methods_hr_28:
    'xodimlarning sodiqligi, kelajakdagi xatti-harakatlarni bashorat qilish, sodiqlikka ta’sir qiluvchi omillarni aniqlash',
  methods_hr_29: 'Ishtirok etish:',
  methods_hr_30: 'ishga bo‘lgan qiziqish, natijalar va yutuqlarga qiziqish',
  methods_hr_31: 'Faollik:',
  methods_hr_32:
    'xodimlarning qadriyatlarini va kompaniya qadriyatlarini aniqlash',
  methods_hr_33: 'Ishonch 360:',
  methods_hr_34:
    'rahbariyatga, hamkasblarga, bo‘ysunuvchilarga bo‘lgan ishonch, ish beruvchi strategiyalari va siyosatlariga ishonch',
  methods_hr_35: 'Qoniqish:',
  methods_hr_36:
    'har bir kompaniya uchun noyob omillarni aniqlash, sodiqlikka ta’sir qiluvchi asosiy omillar bo‘yicha qoniqish darajasini baholash',
  methods_hr_37: 'Yechim 2',
  methods_hr_38: 'Ish beruvchi brendingi',
  methods_hr_39: 'Ichki ish beruvchi',
  methods_hr_40:
    'brend tahlili (xodimlar, manfaatdor tomonlar, ichki rekruterlar va HR mutaxassislar so‘rovi)',
  methods_hr_41: 'Tashqi ish beruvchi tahlili',
  methods_hr_42:
    '(raqobatchilar EVP tahlili, tashqi rekruterlar so‘rovi, ish qidiruvchilar so‘rovi)',
  methods_hr_43: 'Brend tanilishi va obro‘si',
  methods_hr_44: 'tahlili',
  methods_hr_45: 'Ish beruvchi ishtiroki',
  methods_hr_47: 'Ish beruvchi haqida',
  methods_hr_48: 'fikr to‘plash',
  methods_hr_49: 'Raqobatbardosh tahlil',
  methods_hr_50: 'asosiy omillar bo‘yicha',
  methods_hr_51: 'Maosh,',
  methods_hr_52: 'ish muhiti, atmosfera, ishning mohiyati',
  methods_hr_53: 'Talab va kutilmalar tahlili',
  methods_hr_54: 'ish izlovchilarning',
  methods_hr_55: 'Chuqur intervyular,',
  methods_hr_56: 'stol tadqiqoti va miqdoriy tadqiqotlar qo‘llaniladi',
  methods_hr_57: 'Yechim 3',
  methods_hr_58: 'Xodimlar yo‘li xaritasi / yashirin xodim',
  methods_hr_59: 'ish izlovchining yo‘lini qurish va aniqlash',
  methods_hr_60:
    'asosiy qidiruv bosqichlari, kommunikatsiya kanallari, tanlov mezonlari va ish izlovchilarni baholash',
  methods_hr_61: 'Barcha bosqichlarni monitoring qilish',
  methods_hr_62:
    'va yashirin ish izlovchilar bilan aloqa kanallari (faqat intervyuga yozilish, intervyu o‘tkazish, kompaniyada amaliyot o‘tash)',
  methods_hr_63: 'Kamchiliklarni aniqlash',
  methods_hr_64: 'yollash va moslashtirish jarayonlarida',
  methods_hr_65: 'Takomillashtirish bo‘yicha tavsiyalar,',
  methods_hr_66: 'soha yetakchilari bilan solishtirma tahlil',
  mystery_shopping_1: 'Yashirin',
  mystery_shopping_2: 'Xarid',
  mystery_shopping_3: 'Xizmatingizni haqiqiy mijozlar orqali baholang',
  mystery_shopping_4:
    'Standartlarni tekshiring, xodimlaringizni baholang, mijozingizga yaqinlashing',
  mystery_shopping_5:
    'Biz professional Mystery Shopping Providers MSPA Europe assotsiatsiyasining Elite a’zosi hisoblanamiz',
  mystery_shopping_6: 'tajriba yillari',
  mystery_shopping_7: 'oylik tashriflar',
  mystery_shopping_8: 'butun dunyo bo‘ylab yashirin xaridorlar',
  mystery_shopping_9: 'Faol loyihalar',
  mystery_shopping_10: 'ofislar, bosh qarorgoh Avstriyada',
  mystery_shopping_11: 'Yevropa Yashirin Xarid Assotsiatsiyasi',
  mystery_shopping_12: 'xizmat sifatini baholash',
  mystery_shopping_13: 'So‘rovnoma',
  mystery_shopping_14: 'Dasturiy ta’minot',
  mystery_shopping_15: 'Xaridor profili',
  mystery_shopping_16: 'Tasdiqlash va e’tirozlar',
  mystery_shopping_17: 'Tahlil',
  mystery_shopping_18:
    'Bu 5 bosqich orqali bizning 4Service kompaniyamiz yashirin xaridor xizmatining sifatini kafolatlaydi',
  mystery_shopping_19: 'Dastur',
  mystery_shopping_20:
    '4Service tomonidan ishlab chiqilgan Yashirin Xarid dasturining 5 elementi',
  mystery_shopping_21: 'So‘rovnoma:',
  mystery_shopping_22: 'dasturingizning asosi',
  mystery_shopping_23:
    'Ushbu dastur qismi barcha manfaatdor tomonlar tomonidan ko‘rish mumkin',
  mystery_shopping_24: 'Yuqori rahbariyat uchun.',
  mystery_shopping_25:
    'Jarayonning barcha muhim bosqichlarini va tafsilotlarini ko‘rsatish',
  mystery_shopping_26: 'Xodimlar uchun.',
  mystery_shopping_27:
    'Ish beruvchilar ulardan nimalarni kutayotganini aks ettirish',
  mystery_shopping_28: 'Yashirin xaridorlar uchun.',
  mystery_shopping_29:
    'Tushunarli va tuzilgan bo‘lib, chalkashlik va noxolislikni oldini olish uchun',
  mystery_shopping_30: 'Shopmetrics Dasturiy ta’minoti:',
  mystery_shopping_31: 'foydalanuvchi uchun qulay va innovatsion',
  mystery_shopping_32: 'Afzalliklar:',
  mystery_shopping_33: 'Integratsiyalashadi',
  mystery_shopping_34: 'biznes jarayonlaringiz va dasturiy ta’minotingiz bilan',
  mystery_shopping_35: 'GDPR-ga asoslangan',
  mystery_shopping_36: 'Shaxsiy ma’lumotlarni boshqarish vositasi',
  mystery_shopping_37: 'Batafsil',
  mystery_shopping_38: 'Qonuniy muvofiqlik paketi',
  mystery_shopping_39: 'Hisobot',
  mystery_shopping_40: 'tuzuvchi',
  mystery_shopping_41: 'Boshqarish',
  mystery_shopping_42: 'kirish darajalarini',
  mystery_shopping_43: 'Ko‘pchilik savolnomalar',
  mystery_shopping_44: 'bir soat ichida to‘ldiriladi',
  mystery_shopping_45: 'Xaridor profili:',
  mystery_shopping_46: '200 000+ butun dunyo bo‘ylab sirli xaridorlar',
  mystery_shopping_47:
    'Asosiy mezonlar: maqsadli auditoriyangizga maksimal yaqinlik, kompaniyangiz yoki raqobatchilaringiz bilan oldingi tajriba',
  mystery_shopping_48: 'Xaridorlarni o‘qitish:',
  mystery_shopping_49: 'Kirish o‘quv testlari',
  mystery_shopping_50: 'Skype/Telefon qo‘ng‘irog‘i',
  mystery_shopping_51: 'Baholash testlari (ixtiyoriy)',
  mystery_shopping_52: 'Tasdiqlash va e’tirozlar',
  mystery_shopping_53: 'Tasdiqlash',
  mystery_shopping_54: 'Tizimi',
  mystery_shopping_55: '100% hisobotlar',
  mystery_shopping_56: 'tasdiqlash guruhi tomonidan ko‘rib chiqiladi',
  mystery_shopping_57: 'Qo‘shimcha qo‘ng‘iroqlar',
  mystery_shopping_58: 'aniqlik kiritish uchun',
  mystery_shopping_59: 'Audio yozuvlar',
  mystery_shopping_60:
    'tashrifdan so‘ng eng muhim masalalarni qayd etish uchun',
  mystery_shopping_61: 'Xodimlarning firibgarligi',
  mystery_shopping_62: 'oldini olish tizimi',
  mystery_shopping_63: 'E’tiroz',
  mystery_shopping_64: 'Tizimi',
  mystery_shopping_65: 'Har qanday xaridor bahosi',
  mystery_shopping_66:
    'xodim tomonidan sayt orqali bevosita e’tiroz bildirilishi mumkin',
  mystery_shopping_67: 'Har bir e’tiroz',
  mystery_shopping_68:
    'mahalliy tasdiqlash jamoasi tomonidan ko‘rib chiqiladi va javob beriladi',
  mystery_shopping_69: 'Statistikani ko‘rishingiz mumkin',
  mystery_shopping_70: 'e’tirozlar bo‘yicha',
  mystery_shopping_71: 'Tahlil.',
  mystery_shopping_72: 'Hisobot shaxsan taqdim etiladi va o‘z ichiga oladi:',
  mystery_shopping_73: 'Kompaniyaning',
  mystery_shopping_74: 'muammoli jihatlari va tashvishlari',
  mystery_shopping_75: 'Dinamikasi',
  mystery_shopping_76: 'bo‘limlar va bo‘linmalar bo‘yicha',
  mystery_shopping_77: 'Bog‘liqlik',
  mystery_shopping_78:
    'standartlarga rioya qilish va mijozlar qoniqishi o‘rtasida',
  mystery_shopping_79: 'Taqqoslash',
  mystery_shopping_80: 'raqobatchilar bilan',
  mystery_shopping_81: 'Amaliy',
  mystery_shopping_82: 'xizmatni yaxshilash bo‘yicha tavsiyalar',
  mystery_shopping_83: 'Bizni nima farqli qiladi?',
  mystery_shopping_84: 'Biz ideatsiya sessiyalarini o‘tkazamiz',
  mystery_shopping_85:
    'Biz faqat nima qilish kerakligini tavsiya etmaymiz, balki siz bilan birgalikda xizmat muammolarini hal qilishning amaliy usullarini izlaymiz.',
  mystery_shopping_86:
    'Ideatsiya sessiyalari bizga quyidagilarga yordam beradigan kuchli vositadir:',
  mystery_shopping_87:
    'Mahsulot yoki xizmatlarni rivojlantirish yo‘nalishlarini ishlab chiqish',
  mystery_shopping_88:
    'Yangi daromad oqimlari va biznes strategiyalarini o‘rganish',
  mystery_shopping_89:
    'Mijozlarga yo‘naltirilgan murakkab muammolarga yechim topish',
  mystery_shopping_90:
    'Mijozlarning og‘riqli nuqtalarini yoqimli tajribaga aylantirish',
  mystery_shopping_91: 'Biz video intervyular o‘tkazamiz',
  mystery_shopping_92:
    'Biz faqat savolnomalarni to‘plash bilan cheklanmaymiz – xaridorlardan jonli fikr-mulohazalarni ham olamiz',
  mystery_shopping_93: 'Buni quyidagilar uchun qilamiz:',
  mystery_shopping_94:
    'Bizning fasilitatorlarimiz chuqur intervyular o‘tkazadi va samarali fikr-mulohaza taqdim etadi',
  mystery_shopping_95: 'Mijoz tajribasini ularning nuqtayi nazaridan ko‘rish',
  mystery_shopping_96: 'Kontent marketing strategiyangizni yaxshilash',
  mystery_shopping_97:
    'Mijozlarning xatti-harakatlarini oldindan bashorat qilish',
  mystery_shopping_98: 'Biz Mijoz Sayohati Xaritasini ishlab chiqamiz',
  mystery_shopping_99:
    'Mijoz Sayohati Xaritalash bizga mijozlarning ehtiyojlari va qiyinchiliklarini tushunish uchun ularning o‘rniga qo‘yishga yordam beradi.',
  mystery_shopping_100: 'Mijoz sayohatini yaratish – bu eng yaxshi usul:',
  mystery_shopping_101: 'Mijoz tajribasini ularning nuqtayi nazaridan ko‘rish',
  mystery_shopping_102: 'Kontent marketing strategiyangizni yaxshilash',
  mystery_shopping_103:
    'Mijozlarning xatti-harakatlarini oldindan bashorat qilish',
  mystery_shopping_104:
    'Xizmat yoki kommunikatsiyalardagi bo‘shliqlarni aniqlash',
  mystery_shopping_105:
    'Biz turli xil Sirli Xaridorlik turlarini taqdim etamiz',
  mystery_shopping_106: 'Sirli Xaridorlik turlari',
  mystery_shopping_107: 'Sirli qo‘ng‘iroq',
  mystery_shopping_108:
    'Menejerlaringiz va/yoki call-markazingizga qo‘ng‘iroq qilib, telefon orqali ko‘rsatilayotgan xizmat sifatini baholaydi',
  mystery_shopping_109: 'Hashamatli sirli xaridorlik',
  mystery_shopping_110:
    'Maxsus toifa sirli xaridorlari tomonidan hashamatli joylardagi xizmatni tekshirish',
  mystery_shopping_111: 'Raqobatchilarga tashriflar',
  mystery_shopping_112:
    'Raqobatchilaringiz qanday ishlashini tushunishning eng oson yo‘li',
  mystery_shopping_113: 'Sirli xodim',
  mystery_shopping_114:
    'Xodimlaringizning butun yo‘lini tushunishga yordam beradi (ishga qabul qilish, moslashuv, ofis jarayonlari va h.k.)',
  mystery_shopping_115: 'Motivatsion tashriflar',
  mystery_shopping_116:
    'Qo‘shimcha xizmatlar va/yoki mahsulotlarni taklif qilishni rag‘batlantirish uchun foydali vosita',
  mystery_shopping_117: 'Sirli xaridor onlayn',
  mystery_shopping_118:
    'Onlayn do‘koningiz, veb-saytingiz yoki ilovangizda mijoz tajribasini simulyatsiya qiladi va o‘lchaydi',
  mystery_shopping_119: 'Sotuv va aksiyalar tekshiruvi',
  mystery_shopping_120:
    'Aksiya takliflari barcha joylarda qanday aks ettirilganligini va ularning samaradorligini aniqlashga yordam beradi',
  mystery_shopping_121: 'Xarid va qaytarish tashriflari',
  mystery_shopping_122:
    'Xariddan qaytarishgacha bo‘lgan butun jarayonni aks ettirib, mijoz tajribasini yaxshilashga yordam beradi',
  mystery_shopping_123: '> 20 yil',
  nps_1: 'Net Promotion',
  nps_2: 'Ball',
  nps_3: 'Mijoz sadoqatini o‘lchash, kuzatish va yaxshilash',
  nps_4: 'Ular 0 dan 6 gacha ball bilan javob berishadi.',
  nps_5:
    'Ular yomon tajribaga ega bo‘lishlari mumkin va sizdan yana xarid qilish ehtimoli kam, hatto boshqalarni ham sizdan xarid qilmaslikka undashlari mumkin.',
  nps_6: 'Bu respondentlar 7 va 8 orasida ball qo‘yganlar.',
  nps_7:
    'Ular sizning xizmatingizdan qoniqqan, ammo targ‘ibotchilar deb hisoblanish uchun yetarlicha mamnun emaslar.',
  nps_8: 'Bu respondentlar sizning biznesingizga 9 yoki 10 ball berganlar.',
  nps_9:
    'Bu shuni anglatadiki, ular sizni do‘stlari yoki hamkasblariga tavsiya qilishadi va shu tariqa siz uchun targ‘ibot olib borishadi.',
  nps_10: 'NPS nima?',
  nps_11: 'Bu mijozlar taassurotini bitta oddiy savol asosida o‘lchaydi:',
  nps_12:
    '[Tashkilot/Mahsulot/Xizmat] ni do‘stingiz yoki hamkasbingizga tavsiya qilish ehtimolingiz qanday?',
  nps_13: 'Mening kompaniyamga bu kerakmi?',
  nps_14: 'NPS – bu barqaror o‘sish kaliti',
  nps_15: 'Mijoz sadoqatining dinamikasini tushunish',
  nps_16: 'Xodimlaringizni motivatsiya qilish',
  nps_17: 'Turli mahsulotlar bilan bog‘liqlikni tushunish',
  nps_18:
    'Mijozlaringiz siz va raqobatchilaringizga qanday munosabatda bo‘lishini solishtirish',
  nps_19: 'NPS bilan nima qila olaman?',
  nps_20: 'NPS qaysi turdagi biznes uchun dolzarb?',
  nps_21: 'Ma’lumot to‘plash usullari:',
  nps_22: 'NPS bilan ishlashda bizning tavsiyalarimiz',
  nps_23:
    'Bu shuni anglatadiki, biz e’tiroz bildirgan mijozlarni targ‘ibotchilarga aylantirishga yordam beradigan yo‘riqnomalar ishlab chiqamiz',
  nps_24: 'Biz "yopiq davra" usulida ishlaymiz',
  nps_25: 'NPS – bu mijoz sadoqati o‘zgarishlarini kuzatadigan tadqiqot',
  nps_26: 'Biz dinamikani kuzatamiz',
  nps_27:
    'Chuqurroq tahlil qilish uchun har doim sizning bozor segmentingizdagi raqobatchilarni baholashni taklif qilamiz',
  nps_28: 'Raqobatchilarni baholash',
  price_monitoring_1: 'Narxlarni Monitoring qilish',
  price_monitoring_2: 'Chakana Monitoring',
  price_monitoring_3:
    'Savdo salohiyatini oshirish va narx siyosatingizni optimallashtirish uchun raqobatchi narxlarini monitoring qilish',
  price_monitoring_4:
    '4Service 20 yildan ortiq vaqt davomida butun dunyo bo‘ylab narx monitoringi xizmatlarini taqdim etib kelmoqda',
  price_monitoring_5:
    'Narxlar malakali ichki auditorlarimiz tomonidan qisqa vaqt ichida kuzatib boriladi, bu esa raqobatchilarning narx o‘zgarishlariga tezda javob berish imkonini beradi',
  price_monitoring_6:
    'Narx monitoringi yordamida kompaniyamiz hal qila oladigan muammolar',
  price_monitoring_7: 'Raqobatchilarning narx siyosatini baholash',
  price_monitoring_8: 'va mahsulot strategiyasini tahlil qilish',
  price_monitoring_9: 'Aksiya va chegirmalarni kuzatish',
  price_monitoring_10: 'va maxsus takliflarni nazorat qilish',
  price_monitoring_11: 'Bozor sharoitlarini monitoring qilish',
  price_monitoring_12: 'va bozor muhitini baholash',
  price_monitoring_13: 'Savdo nuqtalarini tekshirish va nazorat qilish',
  price_monitoring_14: 'maxsus uskunalar va tovarlar mavjudligi bo‘yicha',
  price_monitoring_15: 'Mahsulot oqimini boshqarish',
  price_monitoring_16: 'savdo tarmog‘i bo‘ylab',
  price_monitoring_17: 'Hamkorlik shartlarini optimallashtirish',
  price_monitoring_18:
    'yetkazib beruvchilar va dilerlar bilan yanada samarali ishlash uchun',
  price_monitoring_19: 'Raf maydonini o‘lchash,',
  price_monitoring_20: 'mahsulotlarning joylashuvi va mavjudligini baholash',
  price_monitoring_21: 'Asosiy ko‘rsatkichlar va monitoring mezonlari',
  price_monitoring_22:
    'Barcha ma’lumotlar bitta interfeysda jamlanadi, jumladan:',
  price_monitoring_23: 'nomi',
  price_monitoring_24: 'mahsulot va brend toifasi',
  price_monitoring_25: 'mavjudligi',
  price_monitoring_26: 'reklama materiallarining',
  price_monitoring_27: 'narxi',
  price_monitoring_28: 'va har qanday aksiyalar mavjudligi',
  price_monitoring_29: 'raf',
  price_monitoring_30: 'o‘lchami',
  price_monitoring_31: 'mahsulot',
  price_monitoring_32: 'namoyish',
  price_monitoring_33: 'SKU',
  price_monitoring_34: 'joylashuvi',
  price_monitoring_35: 'turi',
  price_monitoring_36: 'qadoqlash shakli',
  price_monitoring_37: 'boshqa har qanday',
  price_monitoring_38: 'umumiy ma’lumot',
  price_monitoring_39: 'Loyiha oldi tahlili:',
  price_monitoring_40: 'Biz o‘rganamiz',
  price_monitoring_41:
    'sizning kompaniyangiz narx siyosati va mahsulot siyosati, biznes va soha amaliyotlarini',
  price_monitoring_42: 'Biz taqdim etamiz',
  price_monitoring_43:
    'tezkor o‘qitish, test va auditorlar uchun sertifikatlash',
  price_monitoring_44: 'Biz moslashtiramiz',
  price_monitoring_45: 'va dasturiy ta’minotni sozlaymiz',
  price_monitoring_46: 'Daladagi ish:',
  price_monitoring_47: 'Chakana savdo',
  price_monitoring_48: 'nuqtalariga tashriflar',
  price_monitoring_49: 'Hisobot:',
  price_monitoring_50: 'Bizning tahliliy jamoamiz',
  price_monitoring_51:
    'kompaniyaning shaxsiy hisoblarida onlayn hisobotlar tayyorlaydi',
  price_monitoring_52: 'Biz taqdim etamiz',
  price_monitoring_53:
    'to‘liq foto hisobot, PPT hisobot, Excel hisobot aholi punkti turi, tarmoq nomi, savdo nuqtasi manzili bo‘yicha',
  price_monitoring_54: 'Qanday',
  price_monitoring_55: 'ishlaydi',
  price_monitoring_56: 'shaxsiy chegirmalar hajmi',
  price_monitoring_57: 'xarajatlarni hisoblash',
  price_monitoring_58: 'shaxsiy bonuslar hajmi',
  price_monitoring_59: 'sodiqlik dasturlari',
  price_monitoring_60: 'va hokazo.',
  price_monitoring_61: 'Biz maxsus dasturiy ta’minotdan foydalanamiz:',
  price_monitoring_62:
    'PRADATA, Shopmetrics va keng qamrovli texnik qo‘llab-quvvatlashni taklif qilamiz',
  price_monitoring_63: 'Tezkor joriy etish',
  price_monitoring_64: 'va kengaytirish (1-kundan boshlab)',
  price_monitoring_65: 'Barcha ma’lumotlar tasdiqlangan',
  price_monitoring_66: 'fotosuratlar va GPS belgilari orqali',
  price_monitoring_67: 'Bizda yetarli miqdorda',
  price_monitoring_68: 'daladagi agentlar har qanday mamlakatda mavjud',
  price_monitoring_69: 'Biz katta hajmdagi',
  price_monitoring_70: 'ma’lumotlar bilan ishlay olamiz',
  price_monitoring_71: 'Shaxsiy narxlarni monitoring qilish',
  price_monitoring_72:
    'Agar sizda murakkab mahsulotlar bo‘lsa – biz sizga narx siyosatingizni aniqlashda yordam bera olamiz',
  price_monitoring_73:
    'Biz alohida raqobatchilar narxlarini kuzata olamiz, masalan:',
  price_monitoring_74: 'Past',
  price_monitoring_75: 'xarajat',
  price_monitoring_76: 'Yuqori',
  price_monitoring_77: 'samaradorlik',
  price_monitoring_78: 'Excel hisobot',
  price_monitoring_79: 'PPT hisobot',
  price_monitoring_80: 'BI hisobot',
  price_monitoring_81:
    'Raqobatchilar narx ma’lumotlari sizga eng yaxshi narx qarorlarini qabul qilishga yordam beradi',
  price_monitoring_82:
    'Biz to‘liq foto hisobot, PPT hisobot, Excel hisobotni aholi punkti turi bo‘yicha taqdim etamiz',
  qualitative_research_1: 'Sifatli',
  qualitative_research_2: 'tadqiqotlar',
  qualitative_research_3:
    'Mijozlaringizning harakatlari va so‘zlarini kuzatish orqali ma’lumotlarni to‘plash, tahlil qilish va talqin qilish',
  qualitative_research_4: 'Qadriyatlar',
  qualitative_research_5: 'Xulq-atvor',
  qualitative_research_6: 'Kelib chiqishi',
  qualitative_research_7: 'Kutishlar',
  qualitative_research_8: 'Muhit',
  qualitative_research_9: 'Qo‘rquvlar/Tosqınlıklar',
  qualitative_research_10: 'Mijozning tanlovi qanday maqsadga qaratilgan?',
  qualitative_research_11: 'Telefonli odam',
  qualitative_research_12: 'brend',
  qualitative_research_13: 'toifa',
  qualitative_research_14: 'reklama',
  qualitative_research_15: 'mahsulot',
  qualitative_research_16:
    'Sifatli usullarning o‘ziga xosligi shundaki, ular iste’molchilarning xulq-atvor sabablarini va motivatsiyasini tushunish imkonini beradi',
  qualitative_research_17:
    'Iste’molchilarning muayyan hodisalarga munosabatini tahlil qilish',
  qualitative_research_18: 'Shaxsiy qarashlar va tajribalar',
  qualitative_research_19: 'So‘rovlar orqali o‘rganib bo‘lmaydigan mavzular',
  qualitative_research_20: 'Nozik mavzular',
  qualitative_research_21: 'Qiyin yetib boriladigan auditoriya',
  qualitative_research_22: 'Murakkab geografiya',
  qualitative_research_23: 'Chuquroq intervyular foydalidir:',
  qualitative_research_24: 'Chuquroq intervyular',
  qualitative_research_25:
    'Chuquroq intervyu – bu sifatli tadqiqot usuli bo‘lib, u respondentlar bilan individual suhbatlar o‘tkazish orqali ularning ma’lum bir g‘oya, dastur yoki holat haqidagi fikrlarini chuqur o‘rganishni o‘z ichiga oladi. Chuquroq intervyu shaxsiy tajriba / qarashlar / amaliyotlarni batafsil ochib beradi.',
  qualitative_research_26: 'Maqsadlar:',
  qualitative_research_27: 'Ijtimoiy va madaniy normalarni tavsiflash',
  qualitative_research_28: 'Qiyin yetib boriladigan auditoriya',
  qualitative_research_29:
    'Bo‘lishish va solishtirish (Morgan) so‘rovlar orqali o‘rganilgan',
  qualitative_research_30: 'So‘rovlar orqali o‘rganib bo‘lmaydigan mavzular',
  qualitative_research_31: 'Chuquroq fokus-guruhi foydalidir:',
  qualitative_research_32: 'Fokus-guruhlar',
  qualitative_research_33:
    'Individual uchrashuvlarni chuqur o‘rganish, yuzaga kelayotgan muhokamalar, guruh moderatorining ta’siri ostidagi namoyon bo‘lishlar o‘rtasidagi bahslar.',
  qualitative_research_34:
    'Guruh muhokamasi orqali umumiy tajribalar va ijtimoiy normalarni tahlil qilish, yo‘naltirilgan suhbatdagi hikoya ma’lumotlari.',
  qualitative_research_36: 'Afzalliklar:',
  qualitative_research_37:
    'Iste’molchilarning turmush tarzi va xulq-atvorini turli real sharoitlarda (uyda, savdo nuqtasida, ish joyida va h.k.) o‘rganish',
  qualitative_research_38:
    'Yangi mahsulotlarni ishlab chiqish, qadoqlash, xizmatni yaxshilash va hokazolar uchun ichki fikrlarni izlash',
  qualitative_research_39: 'Chuquroq etnografik tadqiqot foydalidir:',
  qualitative_research_40: 'Etnografik tadqiqot',
  qualitative_research_41:
    'Etnografiya – bu ma’lum bir jamoa yoki tashkilotga kirib, ularning xulq-atvorini va o‘zaro munosabatlarini yaqindan kuzatishni o‘z ichiga olgan sifatli tadqiqot turi.',
  qualitative_research_42:
    'Etnografiya – bu moslashuvchan tadqiqot usuli bo‘lib, u guruhning umumiy madaniyati, urf-odatlari va ijtimoiy dinamikasini chuqur tushunishga yordam beradi. Biroq, u ba’zi amaliy va axloqiy muammolarni ham o‘z ichiga oladi.',
  qualitative_research_43: 'Usullar:',
  qualitative_research_44:
    'Etnografiya – bu iste’molchini real hayot sharoitida va mahsulot/xizmat bilan o‘zaro munosabatda o‘rganishdir',
  qualitative_research_45: 'Biz bilan ishlashning afzalliklari',
  qualitative_research_46:
    'Biz istalgan maqsadli auditoriyadan respondentlarni topa olamiz. Va biz yollash sifatini nazorat qilamiz',
  qualitative_research_47: 'Har qanday murakkablikdagi yollash',
  qualitative_research_48:
    'Bizning moderatorlarimiz ko‘p tilli va fasilitatsiya ko‘nikmalariga ega',
  qualitative_research_49: 'Ekspert moderatorlar',
  qualitative_research_50: 'Onlayn/oflayn ishlash',
  qualitative_research_51: 'Biz audio/video taqdim etamiz',
  qualitative_research_52: 'yozuvlar',
  qualitative_research_53:
    'Biz sifatli tadqiqotni istalgan joyda o‘tkaza olamiz',
  quantitative_research_1: 'Mijozlar qoniqishini o‘rganish',
  quantitative_research_2: 'muayyan mahsulot yoki xizmat bilan (SCI, NPS)',
  quantitative_research_3: 'Segmentatsiyani o‘tkazish',
  quantitative_research_4: 'turli bozorlarda so‘rovlar',
  quantitative_research_5: 'Bozor tadqiqoti',
  quantitative_research_6: 'NPS boshqaruvi',
  quantitative_research_7: 'Raqobatchilar tahlili',
  quantitative_research_8: 'Miqdoriy',
  quantitative_research_9: 'tadqiqotlar',
  quantitative_research_10:
    'Odamlarning munosabatlari va xulq-atvori haqidagi nazariyalarni ob’ektiv, raqamli va statistik ma’lumotlar asosida sinash uchun foydalaniladigan tadqiqot metodologiyasi',
  quantitative_research_11: 'yillar',
  quantitative_research_12: 'global CX tajribasi',
  quantitative_research_13: 'panel',
  quantitative_research_14: 'respondentlar',
  quantitative_research_15: 'mamlakatlar',
  quantitative_research_16: 'biz butun dunyo bo‘ylab loyihalar yuritamiz',
  quantitative_research_17: 'Biz kimmiz',
  quantitative_research_18:
    '4Service – global marketing tadqiqotlari va CX xoldingi. Biz turli bizneslar uchun ekspert ma’lumot to‘plash xizmatlarini taklif qilamiz',
  quantitative_research_19: '4Service raqamlarda',
  quantitative_research_20: 'Kompyuter yordamida',
  quantitative_research_21: 'Veb-intervyu',
  quantitative_research_22:
    'Internetga asoslangan so‘rovnoma usuli. Respondent intervyuer yordamisiz elektron so‘rovnomani to‘ldiradi. So‘rovnoma elektron pochta orqali yoki veb-saytda joylashtirilishi mumkin.',
  quantitative_research_23: 'Kompyuter yordamidagi veb-intervyu',
  quantitative_research_24: 'Ko‘p hollarda',
  quantitative_research_25:
    'ushbu usul moddiy va vaqt xarajatlari bo‘yicha eng samarali hisoblanadi',
  quantitative_research_26: 'Ushbu so‘rovnoma',
  quantitative_research_27:
    'etib borish qiyin bo‘lgan auditoriyani intervyu qilish imkoniyatini beradi',
  quantitative_research_28: 'Bu usul keng imkoniyatlar beradi',
  quantitative_research_29:
    'video va audio materiallar hamda rasmlarni namoyish qilish uchun',
  quantitative_research_30: 'Respondentlar',
  quantitative_research_31:
    'dunyoning istalgan joyida joylashgan bo‘lishi mumkin',
  quantitative_research_32: 'Ushbu usul',
  quantitative_research_33:
    'internet auditoriyasining maxsus xulq-atvorini o‘rganish imkonini beradi. Asosiy natijalar mijoz tomonidan real vaqt rejimida veb-interfeys orqali ko‘rilishi mumkin',
  quantitative_research_34: 'Har qanday maxfiy,',
  quantitative_research_35:
    'noqulay, shaxsiy mavzular yoki intervyuer bilan gaplashishda respondent javob berishdan qochishi mumkin bo‘lgan savollar uchun mos',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37:
    'Bizda butun dunyo bo‘ylab o‘z respondentlar panelimiz mavjud',
  quantitative_research_38: 'Biz murakkab so‘rovlarga ishlaymiz',
  quantitative_research_39:
    'Qo‘shimcha vositalar (ijtimoiy tarmoqlar, reklama) yordamida muayyan auditoriya va respondentlarning aniq profilini topa olamiz',
  quantitative_research_40: 'Biz respondentlarimizni motivatsiya qilamiz',
  quantitative_research_41:
    'Uzoq so‘rovnomalarni to‘ldirishni rag‘batlantirish uchun moddiy mukofot tizimini ishlab chiqqanmiz',
  quantitative_research_42: 'Yuzma-yuz intervyular',
  quantitative_research_43: 'planshet orqali o‘tkaziladi',
  quantitative_research_44:
    'Bu miqdoriy ma’lumotlarni yig‘ishning asosiy usullaridan biri bo‘lib, intervyuer qat’iy tuzilgan so‘rovnoma asosida respondent bilan to‘g‘ridan-to‘g‘ri muloqot qiladi.',
  quantitative_research_45:
    'Yuzma-yuz intervyular respondentning ish joyi yoki yashash joyida, ko‘chada yoki maxsus savdo nuqtalarida planshet orqali o‘tkazilishi mumkin',
  quantitative_research_46:
    'Planshet orqali o‘tkaziladigan yuzma-yuz intervyular',
  quantitative_research_47: 'Belgilash',
  quantitative_research_48: 'brend ommabopligi va tanilish darajasini',
  quantitative_research_49: 'Iste’molchilarni segmentatsiya qilish',
  quantitative_research_50: 'ularning xarid qilish xulq-atvori asosida',
  quantitative_research_51: 'Samaradorlikni tahlil qilish',
  quantitative_research_52: 'reklama kampaniyasining',
  quantitative_research_53: 'Mijozlar qoniqishini',
  quantitative_research_54: 'o‘lchash',
  quantitative_research_55: 'Eng yaxshi narxni belgilash',
  quantitative_research_56: 'mahsulot yoki xizmat uchun',
  quantitative_research_57: 'Tahlil',
  quantitative_research_58: 'iste’molchilar tanlovi bo‘yicha',
  quantitative_research_59: 'Tadqiqot',
  quantitative_research_60: 'iste’molchilar qanday xarid qilishi bo‘yicha',
  quantitative_research_61: 'Baholash',
  quantitative_research_62: 'bozor hajmini',
  quantitative_research_63: 'DigSee dasturi',
  quantitative_research_64:
    'Biz audio yozish va GPS bilan maxsus dasturlardan foydalanamiz',
  quantitative_research_65: 'Intervyuerlar va supervizorlar',
  quantitative_research_66:
    'Biz har qanday tilda intervyu o‘tkazishimiz va mijoz tanlagan tilga tarjima qilishimiz mumkin',
  quantitative_research_67: 'Namuna olish',
  quantitative_research_68:
    'Analitik bo‘lim eng yaxshi natijaga erishish uchun respondentlarni tanlash tizimini ishlab chiqadi',
  quantitative_research_69: 'Kompyuter yordamida',
  quantitative_research_70: 'Telefon intervyu',
  quantitative_research_71:
    'Aniq tuzilgan so‘rovnoma orqali telefon intervyular asosida miqdoriy ma’lumotlarni yig‘ish metodologiyasi.',
  quantitative_research_72: 'Kompyuter yordamidagi telefon intervyular',
  quantitative_research_73: 'Bizda o‘z koll-markazlarimiz mavjud',
  quantitative_research_74:
    'Biz audio yozish va GPS bilan maxsus dasturlardan foydalanamiz',
  quantitative_research_75: 'Malaka oshirilgan operatorlar',
  quantitative_research_76:
    'Bizning operatorlar turli tillarda gaplashadi, tayyorgarlikdan o‘tgan va doimiy ravishda malakasini oshirib boradi',
  quantitative_research_77: 'Ma’lumot bazalari',
  quantitative_research_78:
    'Biz o‘z ma’lumot bazalarimiz va mijozlar ma’lumotlari bilan ishlaymiz',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Biz sifatni kafolatlaymiz va SLA (Xizmat darajasi shartnomasi) imzolaymiz',
  quantitative_research_81: 'Maqsadni belgilash',
  quantitative_research_82: 'Metodologiyani aniqlash',
  quantitative_research_83: 'Segmentatsiya va namuna olish',
  quantitative_research_84: 'Ma’lumot yig‘ish kanallarini aniqlash',
  quantitative_research_85:
    'Respondent uchun mantiqan tushunarli so‘rovnoma yaratish',
  quantitative_research_86: 'Ma’lumot to‘plash jarayoni',
  quantitative_research_87: 'Sifatni ta’minlash',
  quantitative_research_88: 'Tahlil usullari',
  quantitative_research_89: 'Ma’lumotlarni qayta ishlash',
  quantitative_research_90: 'So‘rovnoma dizayni',
  quantitative_research_91: 'Intervyuerlarning GPS-lokalizatsiyasini kuzatish',
  quantitative_research_92: 'Ma’lumotlarni tekshirish',
  quantitative_research_93: 'So‘rovnoma dasturlash va sinovdan o‘tkazish',
  quantitative_research_94: 'Ma’lumotni tasdiqlash',
  quantitative_research_95: 'GDPR talablariga muvofiq audio yozish',
  quantitative_research_96: 'Tadqiqot xodimlarining malaka va kompetensiyalari',
  quantitative_research_97: 'Sifat kafolati',
  quantitative_research_98: 'Omilli tahlil',
  quantitative_research_99: 'Segmentatsiya',
  quantitative_research_100: 'Perseptiv xarita tuzish',
  quantitative_research_101: 'Korrelyatsiya',
  quantitative_research_102: 'Konversiya modeli',
  quantitative_research_103: 'Jakkard indeksi',
  quantitative_research_104: 'Klaster tahlil',
  quantitative_research_105: 'va boshqalar',
  quantitative_research_106: 'Analitik hisobotlar',
  quantitative_research_107:
    'Biz SPSS® va boshqa vositalardan foydalanib turli metodologiyalar asosida analitik hisobotlar tayyorlaymiz',
  quantitative_research_108: 'Qanday biznes muammolari hal qilinishi mumkin:',
  quantitative_research_109: 'Qanday biznes muammolari hal qilinishi mumkin:',
  social_responsibility_1: '4Service Korporativ Ijtimoiy Mas‘uliyati',
  social_responsibility_2:
    'Har bir jamoa a‘zomizning yuragi va ruhidan. Har bir xodimimizda mehr-oqibat uchqunini saqlashga va atrofimizdagi dunyoni biroz yaxshiroq qilishga harakat qilamiz',
  social_responsibility_3: 'Ta‘limiy tadbirlar / Yoshlarni qo‘llab-quvvatlash',
  social_responsibility_4:
    'Bolalar va talabalar uchun tashabbuskor tadbirlarimiz',
  social_responsibility_5: 'Service Mania biznes o‘yini',
  social_responsibility_6:
    'Bizning trening faoliyatimizning muhim qismlaridan biri Service Mania biznes o‘yinidir.',
  social_responsibility_7:
    'Service Mania sizni noodatiy vaziyatlarni strategik tahlil qilishga undaydi.',
  social_responsibility_8: 'Tadqiqot taqdimoti / Diiya. Biznes',
  social_responsibility_9:
    'Biznes va mijozlarning xizmat ko‘rsatish borasidagi qarashlari: 4Service tomonidan o‘tkazilgan tadqiqot natijalari, taqdimotchi - Viktoriya Skorbota, 4Service Ukraine Rivojlanish Bo‘limi rahbari',
  social_responsibility_10: 'Service Design sessiyalari',
  social_responsibility_11:
    'Service Design metodologiyasi 4Service kompaniyasining muhim qismidir. Bir necha yil oldin biz DesignThinkers Academy hamkoriga aylandik va nafaqat eng yaxshi amaliyotlarni qo‘llaymiz, balki eng yaxshi kompaniyalar uchun treninglar ham o‘tkazamiz',
  social_responsibility_12: 'Xayriya / Ko‘ngillilik',
  social_responsibility_13:
    'Yetimxonalar, qariyalar uylari va Ukraina armiyasiga ko‘ngillilik. Bizning eng ta’sirchan faoliyatimiz qismi',
  social_responsibility_14: '#HelpEasyWith4ServiceGroup',
  social_responsibility_15:
    'Biz Blagomai Xayriya Jamg‘armasi bilan hamkorlik qilamiz va bayram kayfiyati ulashish hamda muhtojlarga yordam berishga harakat qilamiz',
  social_responsibility_16:
    'Yetimxonalar va qariyalar uylari uchun mablag‘ yig‘ish',
  social_responsibility_17:
    'Biz, 4Service, qariyalar uylari va yetimxonalar uchun zaruriy ehtiyojlarni sotib olish maqsadida bir necha marotaba mablag‘ yig‘ish tashabbusini ko‘targanmiz',
  social_responsibility_18: '#SaveUkraine',
  social_responsibility_19:
    'Ushbu urush ko‘plab xodimlarimiz va yaqin do‘stlarimizga ta’sir ko‘rsatdi. Har birimiz imkonimiz darajasida yordam beryapmiz',
  social_responsibility_20: 'Hayvonlarni himoya qilish',
  social_responsibility_21:
    'Har kim ofisimizga o‘z uy hayvoni bilan kelishi mumkin. Biz boshpanalarga yordam beramiz, oziq-ovqat uchun mablag‘ yig‘amiz va ba’zan uy hayvonsiz qolgan jonivorlarga yangi uy izlaymiz',
  social_responsibility_22: 'Hayvonlar boshpanalarini qo‘llab-quvvatlash',
  social_responsibility_23:
    'Uy hayvonsiz qolgan jonivorlar ham jamiyatimizning zaif a’zolari kabi yordamga muhtoj.',
  social_responsibility_24:
    'Bizning kompaniyamiz Sirius Hayvonlar Boshpanasini (Kiyev viloyati, Ukraina) o‘z himoyasiga oldi.',
  social_responsibility_25:
    'Uy hayvonsiz qolgan jonivorlarga yordam berish bo‘yicha ijodiy tashabbuslar',
  social_responsibility_26:
    'Mijozlarimiz va hamkorlarimiz e’tiborini hayvonlar boshpanalari muammosiga qanday jalb qila olamiz?',
  social_responsibility_27: 'Uy hayvonlariga do‘stona ofis',
  social_responsibility_28:
    'Jamoamizning ko‘plab a’zolari uy hayvonlariga ega. Biz sog‘lom muhit yaratdik, shunda har kim o‘z uy hayvoni bilan ofisga kelib, o‘zini qulay his qilishi mumkin',
  ux_testing_1: 'UX Testlash',
  ux_testing_1_1: 'UX Testlash',
  ux_testing_2:
    'Veb-saytlaringiz va ilovalaringizni test qilish va takomillashtirish uchun inson va sun’iy intellekt yondashuvi',
  ux_testing_3: 'UX testlash nima?',
  ux_testing_4:
    'UX testlash mahsulotingiz, ilovangiz yoki veb-saytingizdan foydalanuvchilarning qanday foydalanishini tushunishga yordam beradi',
  ux_testing_5: 'Navigatsiya unchalik tushunarli emas',
  ux_testing_6: 'Buyurtma berish tartibini tushunolmadim',
  ux_testing_7: 'Katalogda yetarli miqdorda rasmlar mavjud emas',
  ux_testing_8: 'Siz: ',
  ux_testing_9: 'Taraqqiyot imkoniyatlarini kashf etasiz',
  ux_testing_10:
    'Dizayndagi va mijozlar bilan o‘zaro aloqadagi muammolarni aniqlaysiz',
  ux_testing_11:
    'Foydalanuvchilar sayt bilan muloqot qilishda duch keladigan qiyinchiliklarni aniqlaysiz',
  ux_testing_12: 'Mijozlar yo‘lini kuzatasiz',
  ux_testing_13:
    'Maqsadli auditoriyangiz saytni qachon va nima uchun tark etishini tushunasiz',
  ux_testing_14:
    'Eng kam va eng yuqori qiziqish uyg‘otadigan bo‘limlarni aniqlaysiz',
  ux_testing_15: 'Foydalanuvchilarga asoslangan UX testlash',
  ux_testing_16:
    '4Service sizga mijozga yo‘naltirilgan veb-saytlar va ilovalarni yaratish uchun zarur bo‘lgan haqiqiy inson tushunchalarini taqdim etadi',
  ux_testing_17:
    'Men saytni qanday ishlatishni tushunmadim. Menyu qayerda yashiringan?',
  ux_testing_18:
    'Mening fikrimcha, harflar orasidagi masofa juda katta, matn o‘qilishi qiyin',
  ux_testing_19:
    'Rasmlar bir-biri ustiga chiqib ketgan. Bu shunday bo‘lishi kerakmi?',
  ux_testing_20:
    'UX testlash mahsulotingiz, ilovangiz yoki veb-saytingiz bilan foydalanuvchilar qanday o‘zaro aloqa qilishini tushunishga yordam beradi',
  ux_testing_21: 'Foydalanuvchilarga asoslangan UX testlash tadqiqot dizayni:',
  ux_testing_22: 'Taraqqiyot imkoniyatlarini kashf qilish',
  ux_testing_23: 'Testlash turini va metodini aniqlash (sifat/jamoa)',
  ux_testing_24: 'Gipotezalar va foydalanuvchi ssenariylarini ishlab chiqish',
  ux_testing_25: 'Test natijalarini tahlil qilish va hisobot tayyorlash',
  ux_testing_26: 'Ikki yechim',
  ux_testing_27:
    'Biz muvaffaqiyatli UX testlash uchun ikki yondashuvni taklif qilamiz',
  ux_testing_28: 'Foydalanuvchilarga asoslangan UX testlash',
  ux_testing_29: 'AI + inson asosidagi UX testlash',
  ux_testing_30: 'Bizning kuchli tomonlarimiz:',
  ux_testing_31: 'Biz raqobat muhiti tahlilini amalga oshira olamiz',
  ux_testing_32:
    'Biz intervyularni o‘tkazish va ularda ishtirok etish uchun tor soha mutaxassislarini jalb qila olamiz',
  ux_testing_33:
    'Biz intervyularni turli tillarda o‘tkazishimiz va ularni kerakli tilga tarjima qilishimiz mumkin',
  ux_testing_34: 'mavjud foydalanish qulayligi bilan',
  ux_testing_35: 'metrikalar yoki standartlar',
  ux_testing_36: 'sizning samaradorligingiz',
  ux_testing_37: 'raqobatchiga nisbatan',
  ux_testing_38: 'versiyalar',
  ux_testing_39: 'xuddi shu mahsulotning',
  ux_testing_40: 'Qoniqish reytingi',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Muvaffaqiyat darajasi',
  ux_testing_43: 'Xatolik darajasi',
  ux_testing_44: 'Vazifani bajarish vaqti',
  ux_testing_45: 'Foydalanuvchi asosidagi UX testi',
  ux_testing_46: 'Kvantitativ usul',
  ux_testing_47:
    'Biz ushbu bosqichda UX testida ishtirok etish uchun kamida 100 kishini jalb qilamiz',
  ux_testing_48:
    'Agar siz veb-saytingiz yoki ilovangizni taqqoslashni xohlasangiz, bu usul dolzarb bo‘ladi:',
  ux_testing_49: 'Kvantitativ usul o‘lchovlari:',
  ux_testing_50: 'Kuzatuv',
  ux_testing_51:
    'Foydalanuvchi yoki foydalanuvchilar guruhi bilan vaqt o‘tkazish va ularning mahsulotdan kundalik hayotda qanday foydalanishini kuzatish',
  ux_testing_52: 'Chuqurlashtirilgan intervyu',
  ux_testing_53:
    'Bu sizga saytingizga tashrif buyuruvchilarning munosabatlari, e’tiqodlari, istaklari va tajribalarini bilib olishga yordam beradi. Biz 15-20 ta intervyu o‘tkazishni tavsiya qilamiz',
  ux_testing_54: 'Etnografik tadqiqot',
  ux_testing_55:
    'Foydalanuvchilar mahsulotdan foydalanadigan muhitda o‘tkaziladi. Kuzatish orqali siz foydalanuvchining psixologiyasi va u duch keladigan hissiy qiyinchiliklarni yaxshiroq tushunasiz',
  ux_testing_56: 'Foydalanuvchi asosidagi UX testi',
  ux_testing_57: 'Sifat usuli',
  ux_testing_58:
    'Ushbu usul foydalanuvchilarning motivatsiyasini va xulq-atvor mantiqini tushunishga yordam beradi',
  ux_testing_59: 'Biz foydalanamiz:',
  ux_testing_60:
    'Biz tadqiqot natijalarini tasvirlash uchun mijozlar sayohati xaritasini ishlab chiqmoqdamiz',
  ux_testing_61: '30 nafar ishtirokchi (erkak/ayol)',
  ux_testing_62:
    'Javob beruvchilarni oldindan tekshirish va ularni so‘rovnomada qatnashishga taklif qilish',
  ux_testing_63: 'Wantent platformasida masofaviy onlayn intervyu o‘tkazish',
  ux_testing_64: 'AI + inson asosidagi',
  ux_testing_65: 'AI + inson asosidagi UX testi',
  ux_testing_66:
    'Wantent noyob sun’iy intellekt texnologiyasi va real foydalanuvchilarning kombinatsiyasi',
  ux_testing_67: 'Sarlavhadagi katta shrift e’tiborni tortdi',
  ux_testing_68:
    "Foydalanuvchilar 'baland ovozda gapiruvchi' raqamlarga qarashadi, lekin yonidagi ma’lumotlarni o‘qimaydilar",
  ux_testing_69:
    'UX testi foydalanuvchilarning mahsulot, ilova yoki veb-sayt bilan qanday o‘zaro aloqada ekanligini tushunishga yordam beradi',
  ux_testing_70: 'AI + inson asosidagi test tadqiqot dizayni',
  ux_testing_71: 'Bu qanday ishlaydi',
  ux_testing_72:
    'Wantent - bu sun’iy intellektga asoslangan kontent-bozor mosligi yechimi',
  ux_testing_73:
    'Wantent veb-saytlar va ilovalarning samaradorligini baholaydi va tomoshabinlarning hissiy reaktsiyalari hamda jalb qilish darajasini tahlil qilish uchun ML texnologiyalarini qo‘llaydi',
  ux_testing_74:
    'Natijalarni talqin qilish uchun biz hikoya yaratish texnikasi, xilma-xillik va hamdardlikdan foydalanamiz, bu esa samaradorlik va o‘sishni rag‘batlantiradi',
  ux_testing_75:
    'Bo‘limlar orasidagi o‘tish jarayonida foydalanuvchilarning e’tibori va his-tuyg‘ulari',
  ux_testing_76:
    'Ishtirokchilar guruhi bo‘yicha statistika (butun sessiya davomida e’tibor va diqqat markazida bo‘lish)',
  ux_testing_77: 'Sahifa konversiyalarini tahlil qilish',
  ux_testing_78: 'Vazifalarni bajarish jarayonida e’tibor va his-tuyg‘ular',
  ux_testing_79:
    'Veb-saytni turli ishtirokchilar guruhlari tomonidan qabul qilish haqidagi xulosalar',
  ux_testing_80:
    'Veb-saytni turli guruhlar tomonidan qabul qilishni tahlil qilish',
  ux_testing_81: 'Osonlikni tahlil qilish',
  ux_testing_82:
    'sahifalar bo‘ylab harakatlanish va muhim elementlarni topish qulayligi',
  ux_testing_83: 'Idrok tahlili',
  ux_testing_84: 'saytda taqdim etilgan ma’lumotlarga nisbatan',
  ux_testing_85: 'Aniqlash sohalari',
  ux_testing_86:
    'alohida sahifalar doirasida yaxshilash zarurati (navigatsiya, axborot bloklari va boshqalar)',
  ux_testing_87: 'Biz batafsil tahlil qilamiz',
  ux_testing_88: 'Tadqiqotning asosiy natijalari',
  ux_testing_89:
    'Veb-sayt tuzilmasini va alohida sahifalarni yaxshilash bo‘yicha tavsiyalar',
  ux_testing_90:
    'Biz foydalanish qulayligi testlari uchun maqsadli auditoriyani sinchkovlik bilan tanlaymiz.',
  ux_testing_91:
    'Biz tadqiqotlarni turli mamlakatlarda, turli tillarda va turli foydalanuvchilar guruhlarida o‘tkazamiz',
  ux_testing_92: 'Biz ilovalarda test o‘tkaza olamiz',
  ux_testing_93:
    'va raqobatchilarning veb-saytlarida test o‘tkazib, taqqoslovchi tahlilni taqdim etamiz',
  ux_testing_94: 'Biz turli tadqiqot usullarini birlashtiramiz.',
  ux_testing_95:
    'Bizning malakali fasilitatorlarimiz ishtirokchini test jarayoni bo‘ylab yo‘naltiradi.',
  ux_testing_96: 'Biz test o‘tkazamiz',
  ux_testing_97: 'turli qurilmalar va dasturiy ta’minot versiyalarida',
  ux_testing_98: 'Nega bizni tanlash kerak?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    '4Service va Wantent’dan noyob hamkorlik loyihasi © Sun’iy intellekt va inson omiliga asoslangan yondashuvning kontent tahlili uchun kombinatsiyasi',
  wantent_4: 'kontent efirga uzatilishidan oldin muvaffaqiyatni o‘lchash',
  wantent_5: 'xilma-xillik muammolarining xavfini kamaytirish',
  wantent_6: 'kontentni yaxshilash uchun chuqur tushunchalar va tavsiyalar',
  wantent_7:
    'kontent va marketing strategiyasi bo‘yicha ob’ektiv qarorlar qabul qilish',
  wantent_8:
    'Wantent – sun’iy intellektga asoslangan kontent-bozor mosligi yechimi.',
  wantent_9:
    'Wantent – sun’iy intellektga asoslangan yechim bo‘lib, kontentni bozorga moslashtirish uchun mo‘ljallangan. Wantent video kontent samaradorligini baholash uchun ML texnologiyalaridan foydalanib, hissiy javob va auditoriya jalb darajasini tahlil qiladi.',
  wantent_10:
    'Wantent kontent-bozor mosligini aniqlash va kontent samaradorligini maksimal darajaga yetkazishga yordam beradi',
  wantent_11: 'Ssenariyni sozlash va ishtirokchilarni jalb qilish',
  wantent_12: 'shaxsiylashtirilgan loyiha dizayni va global auditoriya',
  wantent_13: 'Tomoshabinlarning reaktsiyalarini yig‘ish',
  wantent_14: 'auditoriyadan batafsil va aniq fikr-mulohazalar',
  wantent_15: 'Kontent idrokining tahlili',
  wantent_16: 'AI yordamida xatti-harakatlar va hissiy reaktsiyalar tahlili',
  wantent_17: 'Yakuniy natijalar bo‘yicha tushunchalarni yetkazish',
  wantent_18: 'yaxshilash bo‘yicha tavsiyalar',
  wantent_19:
    'Wantent sizning kontent samaradorligingizni maksimal darajaga yetkazadi',
  wantent_20: 'Reklama konsepsiyasini oldindan sinovdan o‘tkazish',
  wantent_21: 'Ijodiy konsepsiyani testdan o‘tkazish',
  wantent_22:
    'Kelajakdagi ijodiy yondashuvning ishlab chiqilgan strategiyaga mos kelishini tekshirish va ijodiy konsepsiyalar/xabarlarni moslashtirish uchun maqsadli auditoriya reaktsiyalarini tushunish',
  wantent_23:
    'Brend elementlarining ko‘rish imkoniyatini testdan o‘tkazish (post-prodakshn)',
  wantent_24:
    'Reklama/homiylik investitsiyalarining samaradorligini tasdiqlash',
  wantent_25: 'Reklama testi',
  wantent_26: 'A/B testi',
  wantent_27:
    'Ijodiy yondashuvlarning samaradorligini taqqoslash va ishlab chiqarish bosqichida jalb qilish hamda e’tiborni oshirish',
  wantent_28: 'Ishga tushirilgan reklamani testdan o‘tkazish',
  wantent_29:
    'Xabarlarning samaradorligini aniqlash va audio hamda vizual jihatlarni yaxshilash bo‘yicha tavsiyalar ishlab chiqish',
  wantent_30: 'Televizion yechimlar',
  wantent_31:
    'Pilot ko‘rsatuvlar, promo materiallar, yangi formatlar, teleboshlovchilarni testdan o‘tkazish',
  wantent_32:
    'Eng qiziqarli variantni aniqlash va uni mustahkamlash uchun taqqoslovchi tahlil o‘tkazish',
  wantent_33: 'Televizion ko‘rsatuv va badiiy filmlarni testdan o‘tkazish',
  wantent_34:
    'Tomoshabinlarning ishtiroki, e’tibor darajasi, hissiy reaktsiyalarini tabiiy sharoitlarda uzoq kontent tomosha qilish vaqtida tahlil qilish. Biz avtomatik ravishda auditoriya qiziqishining pasayish nuqtalarini va chalg‘ituvchi momentlarni aniqlaymiz, bu esa video montajni baholash va yaxshilash imkonini beradi',
  wantent_35: 'UI/UX testi',
  wantent_36: 'Veb-sayt prototiplari va mobil ilovalarni testdan o‘tkazish',
  wantent_37:
    'Wantent texnologiyasi yordamida foydalanuvchilarning reaktsiyalari va hissiyotlarini tahlil qilish:',
  wantent_38: 'e’tibor darajasi',
  wantent_39: 'hissiy reaktsiyalar',
  wantent_40: 'ishtirokchilarning nigoh yo‘nalishining issiqlik xaritalari',
  wantent_41:
    'Veb-sayt va ilovalarning foydalanish qulayligini ishtirokchilar fikriga asoslangan holda tahlil qilish:',
  wantent_42: 'Tizimdan foydalanish shkalasi (SUS)',
  wantent_43: 'Mijoz Effort Score (Oson muloqot)',
  wantent_44: 'Net Promotion Score (NPS)',
  wantent_45: 'GDPRga muvofiqlik va foydalanuvchilarning maxfiyligi',
  wantent_46:
    'Wantent GDPR va CCPA siyosatlariga to‘liq mos keladi hamda ishtirokchilarning shaxsiy ma’lumotlarini yig‘ish, saqlash, qayta ishlash va himoya qilish tamoyillariga rioya qiladi',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (Media & Entertainment)',
  wantent_49:
    'Biz har kuni mashina o‘rganish texnologiyalari yordamida yuzlab yangi kontent g‘oyalarini sinovdan o‘tkazamiz. Wantent bizga foydalanuvchilarning fikri, xatti-harakati va kontentga bo‘lgan munosabatini tushunish hamda tahlil qilishda yordam beradi, shuningdek, yechimlarimizning turli jihatlarini baholash imkonini beradi.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'TABASCO (Reklama) hammuallifi',
  wantent_52:
    'Wantent iste’molchilarning yoqtirgan va yoqtirmagan mayda detallari haqida noyob xizmatni taqdim etadi. Bu yerda aldamaslik yoki yolg‘on gapirishning imkoni yo‘q – Wantent har doim haqiqatni biladi, shuning uchun biz, marketing mutaxassislari, o‘z kommunikatsiyalarimizni yaxshilash bo‘yicha aniq va arzon tavsiyalarni olamiz.',
  wantent_53: 'Sharhlar',
  terms_1: 'Shartlar va holatlar',
  terms_2: 'Foydalanish shartnomasi',
  terms_3:
    'Iltimos, ushbu Foydalanish shartnomasini ("foydalanish shartlari", "shartnoma") veb-saytdan foydalanishdan oldin diqqat bilan o‘qing',
  terms_4:
    '("veb-sayt") 4Service Holdings GmbH ("4Service", "biz", "bizning") tomonidan boshqariladi.',
  terms_5:
    'Foydalanish shartlari: Ushbu veb-saytdan foydalangan holda, siz ushbu Shartnomani o‘qib chiqqaningizni va uning shartlariga rioya qilishga rozilik bildirganingizni tasdiqlaysiz. Agar siz ushbu Shartnomaga rioya qilishni istamasangiz, veb-saytdan chiqib ketishingiz tavsiya etiladi. 4Service faqat ushbu shartlarni qabul qilgan foydalanuvchilarga veb-saytdan foydalanish huquqini taqdim etadi.',
  terms_6: 'Maxfiylik siyosati',
  terms_7:
    'Veb-saytimizdan foydalanishni davom ettirishdan oldin, foydalanuvchilarning ma’lumotlarini to‘plash bo‘yicha Maxfiylik siyosatimizni o‘qib chiqishingizni tavsiya qilamiz. Bu bizning amaliyotlarimizni yaxshiroq tushunishingizga yordam beradi.',
  terms_8: 'Yoshga cheklov',
  terms_9:
    'Siz ushbu veb-saytdan foydalanish uchun kamida 16 (o‘n olti) yoshda bo‘lishingiz kerak. Ushbu veb-saytdan foydalanish orqali siz kamida 16 (o‘n olti) yoshda ekanligingizni va ushbu Shartnomaga qonuniy ravishda rioya qilish imkoniyatiga ega ekanligingizni tasdiqlaysiz. 4Service yosh haqida noto‘g‘ri ma’lumot berilganligi uchun javobgarlikni o‘z zimmasiga olmaydi.',
  terms_10: 'Intellektual mulk',
  terms_11:
    'Siz ushbu veb-saytda taqdim etilgan barcha materiallar, mahsulotlar va xizmatlar 4Service, uning hamkorlari, direktorlar, xodimlar, agentlar, yetkazib beruvchilar yoki litsenziarlarning mulki ekanligini tan olasiz. Shuningdek, siz 4Service’ning intellektual mulkini hech qanday shaklda nusxalamaslik yoki tarqatmaslikka rozilik bildirasiz.',
  terms_12: 'Veb-saytga kirish',
  terms_13:
    'Saytimizga kirish vaqtinchalik asosda ruxsat etiladi va biz saytimizda taqdim etiladigan xizmatlarni ogohlantirishsiz o‘zgartirish yoki to‘xtatish huquqini saqlab qolamiz. Saytimiz istalgan vaqtda yoki istalgan muddatda mavjud bo‘lmasa, biz hech qanday javobgarlikni o‘z zimmasiga olmaymiz.',
  terms_14: 'Amal qiluvchi qonun',
  terms_15:
    'Ushbu veb-saytga tashrif buyurish orqali siz Avstriya Respublikasining qonunlari ushbu shartlar va shartlarga nisbatan amal qilishini tan olasiz.',
  terms_16: 'Nizo masalalari',
  terms_17:
    'Ushbu veb-saytga tashrifingiz bilan bog‘liq har qanday nizolar Vena Savdo sudi tomonidan ko‘rib chiqiladi.',
  terms_18: 'Himoya qilish majburiyati',
  terms_19:
    'Siz 4Service va uning hamkorlarini har qanday qonuniy da’volardan himoya qilishga rozilik bildirasiz. Biz o‘z huquqiy himoyamizni tanlash huquqiga egamiz.',
  terms_20: 'Saqlash muddati',
  terms_21:
    'Foydalanuvchilarning shaxsiy ma’lumotlarini qayta ishlash muddatini inobatga olgan holda, saqlash muddati sizning roziligingiz olingan sanadan boshlab 24 oy etib belgilangan.',
  terms_22: 'Javobgarlikni cheklash',
  terms_23:
    '4Service veb-saytdan noto‘g‘ri foydalanish natijasida yuzaga keladigan har qanday zararlar uchun javobgar emas. 4Service ushbu Shartnomani istalgan vaqtda ogohlantirishsiz o‘zgartirish huquqini o‘zida saqlab qoladi. Agar ushbu shartnomaning bir qismi noqonuniy, bekor yoki majburiy bo‘lmasa, u ajratib tashlanadi va qolgan qoidalar amal qilishda davom etadi.',
  terms_24:
    'Iltimos, maxfiylik yoki foydalanish bo‘yicha barcha savollaringiz va xavotirlaringizni quyidagi manzilga yuboring:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Vena',
  terms_28: 'Avstriya',
  terms_29: 'Ko‘rib chiqish',
  terms_30:
    'Agar bizga taqdim etgan shaxsiy ma’lumotlaringiz noto‘g‘ri bo‘lsa, bizdan ularni tuzatishni so‘rashga haqlisiz (GDPR 16-moddasi).',
  thank_you_1: 'Rahmat!',
  thank_you_2: 'Biz siz bilan 24 soat ichida bog‘lanamiz',
  thank_you_3:
    'P.S. Menejerlarimiz sizning so‘rovingizni ko‘rib chiqmoqda va yaxshiroq taklif tayyorlamoqda',
  thank_you_4: 'Menyu',
  thank_you_5: 'Ayol yozmoqda',
  privacy_policy_1:
    'Veb-sayt foydalanuvchilari (ma’lumot subyektlari) uchun maxfiylik bildirishnomasi',
  privacy_policy_2:
    'Ushbu Maxfiylik Bildirishnomasi (Maxfiylik Bildirishnomasi) 4Service Holdings GmbH tomonidan GDPR talablariga muvofiq veb-saytga tashrif buyurgan jismoniy shaxslarga (Ma’lumot subyektlariga) mo‘ljallangan',
  privacy_policy_3:
    '(keyingi o‘rinlarda Veb-sayt va Veb-sayt foydalanuvchilari/Foydalanuvchilar deb yuritiladi).',
  privacy_policy_4:
    '4Service Holdings GmbH tomonidan nazorat qilinadigan yoki boshqariladigan barcha 4Service Group kompaniyalarida Veb-sayt foydalanuvchilari (Ma’lumot subyektlari) uchun Maxfiylik Bildirishnomasi qo‘llaniladi.',
  privacy_policy_5: 'Aloqa',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Kompaniya/biz/bizning)',
  privacy_policy_8: 'Manzil:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vena, Avstriya.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Biz kimmiz?',
  privacy_policy_13:
    'Siz har qanday maqsadda Veb-saytga tashrif buyuruvchi jismoniy shaxssiz. Ushbu Hujjat maqsadida Siz Foydalanuvchi sifatida ham belgilanasiz.',
  privacy_policy_14: 'Sizdan qanday shaxsiy ma’lumotlarni yig‘amiz',
  privacy_policy_15:
    'Veb-saytimizga kirganingizda, biz Sizga Maxfiylik bildirishnomasini yuboramiz va quyidagi ma’lumotlarni qayta ishlashga roziligingizni so‘raymiz:',
  privacy_policy_16:
    'IP manzil, foydalanuvchi ismi, familiyasi, manzili, telefon raqami (stasionar yoki mobil), e-mail manzili, manzil, kompaniya nomi, mamlakat, e-mail manzili, ism, familiya, telefon raqami, viloyat, shtat va pochta indeksi, foydalanish ma’lumotlari, tashqi ijtimoiy tarmoqlar yoki platformalar bilan o‘zaro aloqalar haqidagi ma’lumotlar, veb-saytda ro‘yxatdan o‘tish va autentifikatsiya qilish haqidagi ma’lumotlar',
  privacy_policy_17: 'geografik joylashuv.',
  privacy_policy_18:
    'Agar Siz bunday rozilik bersangiz, biz Sizning ma’lumotlaringizni qayta ishlashni boshlaymiz.',
  privacy_policy_19: 'Qonuniylik (rozilik)',
  privacy_policy_20:
    'Veb-saytimizga kirganingizda, biz Sizga Maxfiylik bildirishnomasini yuboramiz va quyidagi ma’lumotlarni qayta ishlashga roziligingizni so‘raymiz:',
  privacy_policy_21:
    'Shaxsiy ma’lumotlarni qayta ishlashning huquqiy asosi rozilikdir, biz buni quyidagi havola orqali rozilik formasini to‘ldirish orqali olamiz:',
  privacy_policy_22:
    'Agar Siz rozilik formasini to‘ldirsangiz, bu Siz ushbu Maxfiylik Bildirishnomasida ko‘rsatilgan barcha shartlarni tushunishingiz va qabul qilishingizni anglatadi.',
  privacy_policy_23: 'Rozilikni qaytarib olish',
  privacy_policy_24:
    'Siz avval bizga bergan roziligingizni istalgan vaqtda qaytarib olish huquqiga egasiz. Rozilikni qaytarib olish rozilik asosida oldin amalga oshirilgan qayta ishlashning qonuniyligiga ta’sir qilmaydi. Roziligingizni quyidagi e-mail manzilga so‘rov yuborish orqali qaytarib olishingiz mumkin: withdraw@4service-group.com, shu bilan birga, so‘rov shaklini quyidagi havola orqali topishingiz mumkin:',
  privacy_policy_25: 'Foydalanuvchi uchun Rozilikni Bekor Qilish Shakli',
  privacy_policy_26: 'Qayta ishlash maqsadlari',
  privacy_policy_27:
    'Biz Sizning shaxsiy ma’lumotlaringizni quyidagi maqsadlarda qayta ishlaymiz:',
  privacy_policy_28:
    '– mijozlarga xizmat ko‘rsatishni yaxshilash (bu mijozlarning so‘rovlariga yanada samarali javob berish imkonini beradi); – Foydalanuvchilarning tajribasini shaxsiylashtirish (bu kimning xizmatlarga qiziqishini aniqlashga yordam beradi); – Saytni takomillashtirish (bu mahsulotlar va xizmatlar sifatini oshirish, ularning foydalanish qulayligini yaxshilash, yangi xizmatlarni ishlab chiqish va mahsulotlarimizni yaxshilash imkonini beradi); – Foydalanuvchi bilan yangiliklar, marketing yoki reklama materiallari va boshqa axborotlar orqali muloqot qilish; – Anonim ma’lumotlar asosida statistik va boshqa tadqiqotlar va tahlillar o‘tkazish; – Foydalanuvchiga shaxsiy xizmatlarni taqdim etish va shartnomalarni bajarish; – Sayt orqali amalga oshirilayotgan turli loyihalarda Foydalanuvchining ishtirokini ta’minlash, foydalanuvchi tomonidan Sayt orqali yo‘llangan so‘rovlarga javob berish, tadqiqotlar o‘tkazish, hisoblarni yuritish va xizmatlarni targ‘ib qilish.',
  privacy_policy_29:
    'Shaxsiy ma’lumotlar o‘tkazilishi mumkin bo‘lgan subyektlar',
  privacy_policy_30:
    'Shaxsiy ma’lumotlaringizni qayta ishlash davomida biz ularni Kompaniyaning ishlov beruvchilariga o‘tkazamiz. Kompaniyaning ishlov beruvchilari faqat Kompaniyaning ko‘rsatmalariga muvofiq harakat qilishadi. Ishlov beruvchilarning aniq harakatlari va ularning ro‘yxati bilan quyidagi havola orqali tanishishingiz mumkin: Maxfiylik va Ma’lumotlarni Himoya Siyosati',
  privacy_policy_31:
    'Shaxsiy ma’lumotlar o‘tkazilishi mumkin bo‘lgan davlatlar',
  privacy_policy_32:
    'Kompaniya Sizning shaxsiy ma’lumotlaringizni GDPR va Yevropa Komissiyasi tomonidan belgilangan yetarli darajadagi qaror asosida o‘tkazadi. AQShga shaxsiy ma’lumotlar uzatilishi bo‘yicha qo‘shimcha ma’lumotni quyidagi havola orqali topishingiz mumkin: Maxfiylik va Ma’lumotlarni Himoya Siyosati.',
  privacy_policy_33: 'Saqlash muddati',
  privacy_policy_34:
    'Qayta ishlash maqsadlarini hisobga olgan holda, Foydalanuvchilarning shaxsiy ma’lumotlarini saqlash muddati Sizdan ma’lumotlarni qayta ishlashga rozilik olingan kundan boshlab 24 oy davom etadi.',
  privacy_policy_35: 'Kirish huquqi',
  privacy_policy_36:
    'Sizga tegishli bo’lgan shaxsiy ma’lumotlar qayta ishlanayotganligini bilish huquqiga egasiz va 2) agar shunday bo’lsa, GDPR 15-moddasida ko’rsatilgan qo’shimcha shartlar bilan ushbu ma’lumotlarga kirish huquqiga egasiz.',
  privacy_policy_37: 'To’g’rilash huquqi',
  privacy_policy_38:
    'Agar bizga taqdim etgan shaxsiy ma’lumotlaringiz noto’g’ri bo’lsa, siz bizdan ularni tuzatishni so’rash huquqiga egasiz (GDPR 16-moddasi).',
  privacy_policy_39: 'O’chirish huquqi (unutilish huquqi)',
  privacy_policy_40:
    'Siz shaxsiy ma’lumotlaringizni kechiktirmasdan o’chirishni talab qilish huquqiga egasiz va biz ushbu ma’lumotlarni kechiktirmasdan o’chirish majburiyatiga egamiz, agar GDPR 17-moddasida ko’rsatilgan asoslar mavjud bo’lsa.',
  privacy_policy_41: 'Qayta ishlashni cheklash huquqi',
  privacy_policy_42:
    'Siz GDPR doirasidagi ayrim istisnolar bilan shaxsiy ma’lumotlaringizni qayta ishlashni cheklash huquqiga egasiz, xususan, bu GDPR 18-moddasida ko’rsatilgan.',
  privacy_policy_43:
    'Biz sizga qanday ma’lumotlar to’planayotganligi, ular qanday ishlatilayotganligi, qancha vaqt saqlanishi va uchinchi tomonlarga uzatilish-uzatilmasligi haqida ma’lumot berishimiz shart. Ushbu ma’lumotlar qisqacha va oddiy tilda yetkazilishi kerak.',
  privacy_policy_44: 'Ma’lumotlarni ko‘chirish huquqi',
  privacy_policy_45:
    'Siz shaxsiy ma’lumotlaringizni turli xizmatlarda o‘z maqsadlaringiz uchun olish va qayta ishlatish huquqiga egasiz.',
  privacy_policy_46: 'E’tiroz bildirish huquqi',
  privacy_policy_47:
    'Siz Kompaniya tomonidan qayta ishlanayotgan shaxsiy ma’lumotlaringizni qayta ishlashga e’tiroz bildirish huquqiga egasiz. Agar biz qayta ishlash qonuniy manfaatlarga asoslanganligini isbotlay olmasak yoki huquqiy da’volarni himoya qilish maqsadida bo‘lmasa, biz shaxsiy ma’lumotlarni qayta ishlashni to‘xtatishimiz shart.',
  privacy_policy_48:
    'Faqat avtomatlashtirilgan qayta ishlash natijasida qaror qabul qilinmasligi huquqi',
  privacy_policy_49:
    'Siz avtomatlashtirilgan profilingga e’tiroz bildirish huquqiga egasiz. Shu bilan birga, sizning shaxsiy ma’lumotlaringiz inson ishtirokida qayta ishlanishi kerak.',
  privacy_policy_50: 'Shikoyatlar',
  privacy_policy_51:
    'Agar shaxsiy ma’lumotlaringiz Kompaniya (yoki yuqorida tavsiflangan qayta ishlovchilar) tomonidan qanday qayta ishlanayotganligi yoki shikoyatingiz qanday ko‘rib chiqilganligi haqida shikoyat bermoqchi bo‘lsangiz, nazorat organiga va Kompaniyaga to‘g‘ridan-to‘g‘ri shikoyat qilish huquqiga egasiz.',
  privacy_policy_52: 'Ushbu kontaktlarning tafsilotlari:',
  privacy_policy_53: 'Nazorat organi:',
  privacy_policy_54: 'Avstriya Ma’lumotlarni Himoya qilish Idorasi',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Vena',
  privacy_policy_58: 'Avstriya / Yevropa',
  privacy_policy_59: 'Kompaniya:',
  privacy_policy_60: '4Service Holdings GmbH (Kompaniya/biz/bizni),',
  privacy_policy_61: 'Manzil: Tegetthoffstraße 7, 1010 Vena, Avstriya.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Maxfiylik va ma’lumotlarni himoya qilish siyosati',
  privacy_policy_64:
    'Ma’lumotlaringizni qanday va nima uchun ishlatishimiz haqida ko’proq bu yerda o‘qing: Maxfiylik va Ma’lumotlarni Himoya Qilish Siyosati',
  privacy_policy_65: 'Tasdiqlash',
  privacy_policy_66:
    'Kompaniya shaxsiy ma’lumotlarni qayta ishlashda xodimlar o‘rtasida rollarni aniqlash uchun barcha ichki hujjatlarni ishlab chiqqan, xususan, ushbu hujjatning qonuniyligini tasdiqlash va qayta ko‘rib chiqish uchun mas’ul shaxs Bosh direktor hisoblanadi.',
  contacts_1: 'Mijozlar uchun',
  contacts_2:
    'Har qanday biznes, bozor tadqiqot agentliklari va yirik kompaniyalar, biznes hamkorlar va OAV uchun.',
  contacts_3: 'Tegetthoffstrasse 7, Vena, Avstriya',
  contacts_4: 'Xaridorlar uchun',
  contacts_5:
    'Mahalliy aholi va sayohatchilar, haydovchilar va talabalar, professional sirli xaridorlar va uyda o‘tirishni yoqtiradiganlar.',
  contacts_6: 'O‘z mamlakatingizdan biz bilan bog‘laning:',
  contacts_7: 'Italiya',
  contacts_8: 'AQSh',
  contacts_9: 'Birlashgan Qirollik',
  contacts_10: 'Shveytsariya',
  contacts_11: 'Niderlandiya',
  contacts_12: 'Slovakiya',
  contacts_13: 'Sloveniya',
  contacts_14: 'Ruminiya',
  contacts_15: 'Ozarbayjon',
  contacts_16: 'Ukraina',
  contacts_17: 'Qozog‘iston',
  contacts_18: 'Dunyoning boshqa hududlari',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'UX testi',
  menu_6: 'HR tadqiqot',
  menu_7: 'Sifatli tadqiqot',
  menu_8: 'Miqdoriy tadqiqot',
  menu_9: 'Sirli xarid',
  menu_10: 'Voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Narx monitoringi',
  menu_14: 'Biz haqimizda',
  menu_15: 'Kontaktlar',
  menu_16: 'Blog',
  menu_17: 'Metodlar',
  menu_18: 'Mahsulotlar',
  menu_19: 'Xaridorlar \n platformasi',
  menu_20: 'Korporativ ijtimoiy mas‘uliyat (CSR)',
  menu_21: 'Avtomobil narxlarini tahlil qilish',
  menu_22: 'Ob’ekt tekshiruvi auditi',
  menu_23: 'Xizmat dizayni',
  menu_24: 'Mijoz tajribasi (CX)',
  nps_passives: 'Passivlar',
  nps_detractors: 'Tanqidchilar',
  nps_promoters: 'Targ‘ibotchilar',
  nps_a_score: 'ball',
  area: 'Hudud',
  products_tabs_1:
    'Maksimal samaradorlikka erishish uchun biz quyidagilarni ham kompleks tarzda amalga oshiramiz:',
  products_tabs_2: 'Mahsulotlar',
  products_tabs_3:
    'Sifatli tadqiqot – bu tushunchalar, fikrlar yoki tajribalarni tushunish uchun raqamli bo‘lmagan ma’lumotlarni yig‘ish va tahlil qilish',
  products_tabs_4:
    'Miqdoriy tadqiqot usullari – bu so‘rovnomalar, anketalar va so‘rovlar orqali to‘plangan ma’lumotlarni ob’ektiv o‘lchash va raqamli tahlil qilish',
  our_clients_1: 'Bizning mijozlarimiz',
  preview_cases_section_1: 'Ish tajribalari',
  preview_cases_section_2:
    'Ko‘proq qiziqarli postlarni o‘qish uchun ish tajribalariga o‘ting',
  blog_1: 'Qiziqarli kontent blogi',
  blog_2: 'Sizning so‘rovingiz bo‘yicha hech qanday post mavjud emas',
  about: 'Biz haqimizda',
  why_us: 'Nega biz',
  about_us: 'Biz haqimizda',
  key_features: 'Asosiy xususiyatlar',
  solutions: 'Yechimlar',
  monitoring: 'Monitoring',
  how: 'Qanday',
  how_it_works: 'Bu qanday ishlaydi',
  when_to_use: 'Qachon ishlatish kerak',
  mystery_shopping: 'Sirli xarid',
  voicer: 'Mijoz ovozi',
  wantent: 'Video kontent testi',
  play4sales: 'Play4Sales',
  price_monitoring: 'Narx monitoringi',
  service_design: 'Xizmat dizayni',
  qualitative_research: 'Sifatli tadqiqot',
  quantitative_research: 'Miqdoriy tadqiqot',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automobilsozlik',
  hashtag_retayl: '#Chakana_savdo',
  hashtag_all_posts: '#Barcha_postlar',
  hashtag_feedback: '#Fikr_mulohoza',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#Tajriba',
  hashtag_market_research: '#Bozor_tadqiqoti',
  more: 'Ko‘proq',
  speak_to_an_expert: 'Mutaxassis bilan gaplashing',
  book_consultancy: 'Konsultatsiya buyurtma qiling',
  read_all: 'Hammasini o‘qish',
  find_out_more: 'Batafsil bilib oling',
  address: 'Manzil',
  mon_fri: 'Dushanba-Juma',
  phone_number: 'Telefon raqami',
  flag: 'Bayroq',
  scroll_to_left: 'chapga siljiting',
  online: 'onlayn',
  tel: 'Tel',
  email: 'E-mail',
  light_it_up: 'Yoritib yubor',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Biznes rivojlantirish direktori',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Katta loyiha menejeri',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Biznes rivojlantirish menejeri',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Operatsiyalar direktori',
  about_us_roles_1: 'Boshqaruv',
  about_us_roles_2: 'Jamoa Global',
  about_us_roles_3: 'Mahsulot egalari',
  ad_testing_1: 'Reklama testi',
  ad_testing_2:
    'Reklama kampaniyasi katta sarmoyadir. Reklamalaringiz kuchli ekanligiga va maksimal ROI ta’minlashiga ishonch hosil qiling',
  ad_testing_3: 'qulf',
  ad_testing_4: 'Biz xizmat ko‘rsatadigan sohalar',
  ad_testing_5: '4Service – global mijoz tajribasi tadqiqot xoldingi',
  ad_testing_6:
    '4Service – global marketing tadqiqot va CX xoldingi. Biz keng turdagi bizneslar uchun ekspert ma’lumot to‘plash xizmatlarini taqdim etamiz',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Chakana savdo',
  ad_testing_9: 'Elektronika',
  ad_testing_10: 'Hashamat/Premium',
  ad_testing_11: 'Ovqatlanish va Mehmondo‘stlik',
  ad_testing_12: 'Avtomobilsozlik',
  ad_testing_13: 'Sayohat va Dam olish',
  ad_testing_14: 'Moda',
  ad_testing_15: 'Banklar',
  ad_testing_16: 'Soatlar va Taqinchoqlar',
  ad_testing_17: 'Uy jihozlari va Maishiy texnika',
  ad_testing_18: 'Esomar a’zosi',
  ad_testing_19:
    'Biz turli sohalardagi bizneslar uchun reklama bo‘yicha Reklama Testi tadqiqotlarini o‘tkazdik',
  ad_testing_20: 'Sizga Reklama Testi kerak, agar siz',
  ad_testing_21: 'Yangi mahsulot yoki xizmatni targ‘ib qilsangiz',
  ad_testing_22: 'Nozik mavzuga teginsangiz',
  ad_testing_23:
    'Yangi maqsadli auditoriya yoki bozorda o‘z o‘rnini topishga harakat qilish',
  ad_testing_24: 'Bir nechta turli dizayn variantlarini muhokama qilish',
  ad_testing_25:
    'Boshqaruv jamoalari yoki investorlar uchun kontseptsiya isboti kerak',
  ad_testing_26: 'Siz quyidagi savollarga javob olasiz',
  ad_testing_27:
    'Qaysi reklama kanallari ushbu reklamalar uchun eng mos keladi?',
  ad_testing_28:
    'Xabarning yetkazilishi uchun to‘g‘ri vizuallarni ishlatyapmizmi?',
  ad_testing_29:
    'Sizning reklama matnlari va xabarlaringiz auditoriyaga yetib bormoqdami?',
  ad_testing_30:
    'Qaysi reklama kanallari ushbu reklamalar uchun eng mos keladi?',
  ad_testing_31: 'Ularning e’tiborini birinchi bo‘lib nima jalb qiladi?',
  ad_testing_32: 'Reklamaning joylashuvi va tartibi tushunarli bo‘ldimi?',
  ad_testing_33: 'Biz baholaymiz',
  ad_testing_34: 'Brending',
  ad_testing_35:
    'Brendingingiz reklamaga qanday bog‘langan va uni qay darajada eslab qolishadi',
  ad_testing_36: 'Asosiy metrikalar',
  ad_testing_37: 'Integratsiya',
  ad_testing_38: 'Tanishlik',
  ad_testing_39: 'Moslik',
  ad_testing_40: 'Ijodiy yondashuv',
  ad_testing_41:
    'Sizning reklamangiz qanday ajralib turadi va odamlarning e’tiborini qanday tortadi',
  ad_testing_42: 'Asosiy metrikalar',
  ad_testing_43: 'TANISHLIK',
  ad_testing_44: 'YOQISH',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVATSIYA',
  ad_testing_47: 'Mijoz tajribasi, hissiy bog‘lanish',
  ad_testing_48:
    'Sun’iy intellekt texnologiyasi va ekspert tahlilchilar yordamida biz odamlarning haqiqiy his-tuyg‘ularini tahlil qilamiz va ularning reklama haqidagi tasavvurlarini tushunib yetamiz',
  ad_testing_49: 'Reyting',
  ad_testing_50: 'Erkak',
  ad_testing_51: 'Ayol',
  ad_testing_52: 'Yuqori darajadagi jalb etish',
  ad_testing_53: 'Diqqat',
  ad_testing_54: 'Auditoriya nimani yoqtirdi va eslab qoldi?',
  ad_testing_55: 'Aktrisa',
  ad_testing_56: 'Tenglik',
  ad_testing_57: 'Musiqiy fon',
  ad_testing_58: 'Ijobiy his-tuyg‘ular',
  ad_testing_59: 'Turli irq va tashqi ko‘rinishga ega odamlar',
  ad_testing_60: 'Tashqi sahnalar',
  ad_testing_61: 'Auditoriya nimani yoqtirmadi va eslab qoldi?',
  ad_testing_62:
    'Notiqlik ovozi ekranda sodir bo‘layotgan narsalarga mos kelmadi',
  ad_testing_63: 'Mahsulotga past e’tibor',
  ad_testing_64: 'Juda dinamik',
  ad_testing_65: 'Tushunish qiyin',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Ko‘rishlar',
  ad_testing_68: 'Ko‘rsatmalar',
  ad_testing_69: 'Konversiya',
  ad_testing_70: 'OAV',
  ad_testing_71:
    'Biz reklama kampaniyasining samaradorligini media faolligi bilan birgalikda o‘lchaymiz va turli kanallardagi media investitsiyalarning natijalarga ta’sirini hisoblaymiz.',
  ad_testing_72:
    'Biz har qanday format, platforma yoki kanal bo‘yicha reklama tadqiqotlarini o‘tkazamiz',
  ad_testing_73: 'videokontent',
  ad_testing_74: 'tashqi reklama',
  ad_testing_75: 'TV reklamalar',
  ad_testing_76: 'bosma',
  ad_testing_77: 'raqamli',
  ad_testing_78: 'reklama kampaniyalari',
  ad_testing_79: 'POS displey va reklama',
  ad_testing_80: 'animatika',
  ad_testing_81:
    'Bizning yordamimiz bilan siz reklamangizni har qanday bosqichda sinovdan o‘tkazishingiz mumkin',
  ad_testing_82: 'Konseptsiya',
  ad_testing_83: 'Qaysi g‘oyalar kuchliroq imkoniyatga ega?',
  ad_testing_84: 'Dastlabki ishlab chiqarish bosqichi',
  ad_testing_85: 'Qaysi animatikalar ishlab chiqarishga o‘tadi?',
  ad_testing_86: 'Ishlab chiqarish',
  ad_testing_87:
    'Diqqatni saqlab qolish uchun videoni qanday tahrirlash kerak?',
  ad_testing_88: 'Pre-media',
  ad_testing_89:
    'Qaysi kommunikatsiya kanallari ishlaydi va qanday natija kutish mumkin?',
  ad_testing_90: 'Reklama post-testi',
  ad_testing_91: 'Ishlab chiqarish',
  ad_testing_92:
    'Reklamam jalb qiladimi? Ijodiy samaradorlik vaqt o‘tishi bilan pasayadimi?',
  ad_testing_93: 'Pre-media',
  ad_testing_94:
    'Reklamam raqamlarda qanchalik samarali? U raqobatchilar bilan qanday taqqoslanadi?',
  ad_testing_95: 'Reklama oldidan sinov',
  ad_testing_96:
    'Sizga yuqori sifatli ma’lumot taqdim etish uchun biz foydalanamiz',
  ad_testing_97: 'Kvantitativ tadqiqot metodologiyasi',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Sifatli tadqiqot metodologiyasi',
  ad_testing_100: 'Fokus guruhlar',
  ad_testing_101: 'AI',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Siz oladigan tahliliy hisobotlar',
  ad_testing_104:
    'Bizning tahlilchilar, media mutaxassislari va marketologlardan iborat jamoamiz siz uchun batafsil tavsiyalar bilan moslashtirilgan hisobot tayyorlaydi va taqdim etadi',
  ad_testing_age: 'yosh',
  ad_testing_ad: 'Reklama',
  automotive_industry_price_analysis_1: 'Avtomobil sanoati narxlari tahlili',
  automotive_industry_price_analysis_2:
    'Raqobatbardosh narx tahlili yo‘qotilgan foydani oldini olishga, bozor ulushini oshirishga va bozordagi o‘rningizni kuzatishga yordam beradi. Sizga muvaffaqiyatli raqobat strategiyasini tanlashda yordam beramiz!',
  automotive_industry_price_analysis_3: 'Narx bo‘yicha so‘rov yuboring',
  automotive_industry_price_analysis_4: 'Bizning metodologiyamiz',
  automotive_industry_price_analysis_5:
    'Zamonaviy, elegant odamlardan iborat avtomobil saloni',
  automotive_industry_price_analysis_6:
    'Mystery Shopping usuli haqiqiy mijoz tajribasini aniq aks ettiradigan va ishonchli umumiy ko‘rinish beradigan yagona yondashuvdir.',
  automotive_industry_price_analysis_7:
    'Biz aniq raqamlar va ishonchli ko‘p tomonlama ma’lumotlarni taklif qilamiz',
  automotive_industry_price_analysis_8:
    'Biz butun dunyo bo‘ylab qamrovga egamiz',
  automotive_industry_price_analysis_9:
    'Bizning ichki resurslarimiz mystery shopperlar va auditorlarni o‘z ichiga oladi',
  automotive_industry_price_analysis_10:
    'Biz ma’lumotlarimiz sifatini tasdiqlaymiz',
  automotive_industry_price_analysis_11: 'Bizning afzalliklarimiz:',
  automotive_industry_price_analysis_12:
    'Muayyan raqobatbardosh modellarning narx monitoringi (B2B va B2C)',
  automotive_industry_price_analysis_13: 'Chegirma darajasini kuzatish',
  automotive_industry_price_analysis_14:
    'Brendingiz va raqobatchilarning chakana savdo zaxiralarini kuzatish',
  automotive_industry_price_analysis_15:
    'Elektron tijorat avtomobil narxlarini monitoring qilish',
  automotive_industry_price_analysis_16: 'Narx bo‘yicha kommunikatsiya tahlili',
  automotive_industry_price_analysis_17:
    'Chakana savdo yetkazib berish to‘lovlarini kuzatish',
  automotive_industry_price_analysis_18:
    'Chakana savdo do‘konlaridagi aksiyalar va chegirmalarni kuzatish',
  automotive_industry_price_analysis_19: 'Oylik to‘lov miqdorlarini aniqlash',
  automotive_industry_price_analysis_20: 'Lizing to‘lovlarini hisoblash',
  automotive_industry_price_analysis_21:
    'Elektr transport vositalari (EV) uchun maxsus narx tahlili',
  automotive_industry_price_analysis_22:
    'Iste’molchiga to‘g‘ridan-to‘g‘ri va chakana narxlarni solishtirish',
  automotive_industry_price_analysis_23:
    'Obuna asosidagi narxlash modellari ishlab chiqish',
  automotive_industry_price_analysis_24:
    '4Service 20 yildan ortiq tajribaga ega global mijoz tajribasi (CX) xolding kompaniyasidir',
  automotive_industry_price_analysis_25:
    'Siz iste’molchilarning turli xususiyatlarga to‘lashga tayyorligi haqidagi ma’lumotlardan foydalanib, taklifingizni optimallashtirasiz va maqsadli mijozlar uchun eng muhim xususiyatlarni aniqlaysiz.',
  automotive_industry_price_analysis_26:
    '4Service tomonidan avtomobil sanoati narxlari bo‘yicha tahlil quyidagilarni o‘z ichiga oladi:',
  automotive_industry_price_analysis_27:
    'Eng past narx har doim ham avtomobil brendlari uchun raqobatbardoshlikning kaliti emas. Sizga uzoq muddatli narxlash strategiyasini yaratishda yordam beramiz',
  automotive_industry_price_analysis_28:
    'mijozlar barcha brendlar bo‘yicha narxlar oshayotganini tushunadi',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'mijozlar brend ularga kelgusi narx oshirilishi haqida xabar bersa ham, o‘zlariga yoqqan avtomobilni xarid qilishlarini aytishdi,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'odamlar orasida lizing va oylik to‘lovlarni avtomobil sotib olishning eng ma’qul modeli deb hisoblaydiganlar',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'dilerlar savdo jarayonida narx oshirilishi ehtimolini tilga oldi',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Asosiy raqamlar',
  automotive_industry_price_analysis_37:
    'Mahsulotingizni boshqa avtomobillar bilan qanday narxlashni tushunasiz.',
  automotive_industry_price_analysis_38:
    'Siz iste’molchilarning turli xususiyatlarga to‘lashga tayyorligi haqidagi ma’lumotlardan foydalanib, taklifingizni optimallashtirasiz va maqsadli mijozlar uchun eng muhim xususiyatlarni aniqlaysiz.',
  automotive_industry_price_analysis_39:
    'mijozlar brend ularga kelgusi narx oshirilishi haqida xabar bersa ham, o‘zlariga yoqqan avtomobilni xarid qilishlarini aytishdi',
  automotive_industry_price_analysis_40:
    'Siz to‘g‘ri oylik to‘lov va lizing stavkalarini aniqlay olasiz.',
  automotive_industry_price_analysis_41:
    'Brendingiz yoki dilerlik markazingiz uchun qanday foydalar olasiz:',
  automotive_industry_price_analysis_42: 'Siz ariza topshirasiz',
  automotive_industry_price_analysis_43:
    'Bizning menejerimiz siz bilan bog‘lanib, asosiy maqsadlarni aniqlaydi',
  automotive_industry_price_analysis_44: 'Biz marketing tadqiqotini o‘tkazamiz',
  automotive_industry_price_analysis_45:
    'Biz sizga onlayn formatda muntazam hisobot va tavsiyalar taqdim etamiz',
  automotive_industry_price_analysis_46: 'Chegirma yorlig‘i bo‘lgan avtomobil',
  automotive_industry_price_analysis_47:
    'Biz loyihani 1 kunda ishga tushiramiz!',
  automotive_industry_price_analysis_48:
    'Biz foto hisobotlar, PPT hisobotlar va Excel hisobotlarini o‘z ichiga olgan batafsil hisobotlar to‘plamini taklif qilamiz, ular aholi yashash joyi turi bo‘yicha segmentlangan',
  retail_audit_1: 'MAYDON MARKETING AUDITI/chakana audit',
  retail_audit_2:
    'Doʻkoningiz yoki brendingiz haqida batafsil maʼlumot toʻplang, oʻsish toʻsiqlarini tahlil qiling va ularni yengib oʻtish uchun samarali strategiyalar yarating',
  retail_audit_3: 'Narx taklifini soʻrang',
  retail_audit_4:
    '4Service brendlar nomidan doʻkon ichidagi auditlarni oʻtkazib, ularning reklama strategiyalari, dala jamoalari va marketing taktikalarining moʻljallangan bozorga yetib borishini baholaydi.',
  retail_audit_5:
    'Biz reklama kampaniyalarining bajarilishini diqqat bilan kuzatamiz va reklama koʻrsatmalariga rioya qilinishini taʼminlaymiz.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'Yevropadagi CX agentliklari',
  retail_audit_8: 'yillik tajriba',
  retail_audit_9: 'mamlakat qamrovi',
  retail_audit_10: '1,6 million',
  retail_audit_11: 'yiliga baholashlar',
  retail_audit_12: 'Nimani kuzatamiz?',
  retail_audit_13: 'Savdo nuqtalarida (POS) materiallarning mavjudligi',
  retail_audit_14:
    'Brend vakillarining mavjudligi va faolligi, rejalashtirilgan jadvalga rioya qilinishini taʼminlash',
  retail_audit_15:
    'Brend vakillarining targʻibot kampaniyalarida ishtirokini baholash',
  retail_audit_16:
    'Isteʼmolchilar bilan oʻzaro aloqani baholash, reklama koʻrsatmalari va brend xabarlariga muvofiqligini taʼminlash',
  retail_audit_17: 'Firibgarlikning oldini olish choralari',
  retail_audit_18:
    'Sizning biznesingiz dala marketing auditi natijalaridan foydalanib:',
  retail_audit_19: 'Budjet va jarayonlarga muvofiqlikni baholash',
  retail_audit_20:
    'Haqiqiy natijalarni moʻljallangan maqsadlar bilan taqqoslash',
  retail_audit_21: 'Marketing kampaniyalarining samaradorligini oshirish',
  retail_audit_22: 'Kelajak uchun strategik reja tuzish',
  retail_audit_23: 'Xarajatlarni kamaytirish imkoniyatlarini aniqlash',
  retail_audit_24: 'Sotish va konversiya darajalarini yaxshilash',
  retail_audit_25: 'Investitsiya daromadliligini (ROI) optimallashtirish',
  retail_audit_26:
    'ULAR 4SERVICEʼNI SAVDO INVESTITSIYASI BOʻYICHA SHERIK SIFATIDA TANLADILAR',
  retail_audit_27:
    'Etakchi xalqaro tamaki mahsulotlari ishlab chiqaruvchisi oʻz brendlarini rivojlantirish va doʻkon ichidagi natijalarini yaxshilash uchun 4Service bilan hamkorlik qildi. Bizning jamoalarimiz dala marketing auditi va isteʼmolchilar tajribasining jonli hisobotlarini taqdim etdilar.',
  retail_audit_28:
    'Dala marketing agentligi rejalashtirish aniqligining oshishi',
  retail_audit_29: 'Brend xabarlarini yetkazish darajasining oshishi',
  retail_audit_30:
    'Brend vakillari ishtirokining (isteʼmolchilar faollashtirilishi) oshishi',
  retail_audit_31:
    'Maʼlumotlarni yigʻish aniqligi va GDPRga muvofiqlik darajasining oshishi',
  retail_audit_32: 'Qanday qimmatli maʼlumotlarni topishimiz mumkin:',
  retail_audit_33:
    'SLA shartlari oʻrtasida mos kelmaslik yoki hatto ziddiyat mavjudligi mijoz uchun tasdiqlanmagan xarajatlarning sezilarli darajada oshishiga olib keladi',
  retail_audit_34:
    'Xizmat turlarini adashtirish natijasida toʻlovlarning notoʻgʻri hisoblanishi',
  retail_audit_35:
    'Dala xizmati bajarilmay qolishi, jumladan, xodimlarning rejalashtirilgan joyga tayinlanmasligi | POS',
  retail_audit_36:
    'Haqiqiy natijalarga emas, balki taxminiy baholarga asoslangan toʻlovlarning amalga oshirilishi va muvofiqlashtirilmasligi',
  retail_audit_37:
    'Reklama koʻrsatmalarining notoʻgʻri qoʻllanilishi va yolgʻon maʼlumotlar taqdim etilishi',
  retail_audit_38: 'Qanday ishlaymiz?',
  retail_audit_39:
    'Auditorimiz agentlikning faollashtirish rejasiga muvofiq belgilangan savdo nuqtalariga (POS) tashrif buyuradi.',
  retail_audit_40:
    'Ular kalit natijaviy koʻrsatkichlarni (KPI) baholaydilar, jumladan, POS mavjudligi, brend vakillarining ishtiroki, reklama koʻrsatmalariga rioya qilish va firibgarlikning oldini olish choralarini.',
  retail_audit_41: 'Tasdiqlash:',
  retail_audit_42: 'Biz toʻplangan maʼlumotlarni yigʻamiz va umumlashtiramiz.',
  retail_audit_43: 'Batafsil hisobot tayyorlanadi.',
  retail_audit_44:
    'Bizning tasdiqlash guruhi barcha soʻrovnomalarni loyihaga muvofiqlik va qoʻshimcha izohlar boʻyicha sinchkovlik bilan tekshiradi.',
  retail_audit_45: 'Onlayn Hisobot Tizimi:',
  retail_audit_46:
    'Tugallangan va tasdiqlangan soʻrovnomalar 36 soat ichida bizning onlayn portalimizga yuklanadi.',
  retail_audit_47:
    'Mijozlar shaxsiy hisob portaliga kirish huquqiga ega boʻlib, ular istalgan vaqtda jarayonni kuzatishi mumkin.',
  retail_audit_48:
    'Natijalar va tavsiyalarni oʻz ichiga olgan yakuniy hisobot taqdim etiladi.',
  retail_audit_49: 'Mutaxassis bilan bogʻlaning',
  retail_audit_50: 'Bizning kuchli tomonlarimiz:',
  facility_check_audit_1: 'Obʼyekt',
  facility_check_audit_2: 'Tekshiruv Auditi',
  facility_check_audit_3: 'Har bir tafsilotda brend tajribasini oshiring',
  facility_check_audit_4: 'Yondashuvimizni oʻrganing',
  facility_check_audit_5: 'Haqida',
  facility_check_audit_6: 'Biz kimmiz',
  facility_check_audit_7:
    'Global mijoz tajribasi xoldingi sifatida, 4Service brendlarga mijozlari uchun ajoyib tajribalar yaratish va yetkazib berishda yordam beradigan toʻliq xizmatlar toʻplamini taqdim etadi.',
  facility_check_audit_8: 'Bizning Obʼyekt Tekshiruv Auditi xizmati',
  facility_check_audit_9:
    'fizik joylashuvingiz va chakana savdo doʻkonlaridagi brend ishtirokingiz brend standartlaringizga javob berishini va mijozlar uchun ijobiy taassurot qoldirishini taʼminlashga yordam berish uchun moʻljallangan.',
  facility_check_audit_10: 'Yechimlar 1',
  facility_check_audit_11: 'Obʼyekt Tekshiruv Auditi',
  facility_check_audit_12:
    'bu chakana savdo nuqtalari yoki stendlari brend standartlaringizga mosligini baholaydigan puxta tekshiruvdir. Biz nafaqat fizik xususiyatlarni tekshiramiz, balki bu elementlarning qadrli mijozlaringiz tomonidan brendingiz qanday qabul qilinishiga qanday taʼsir qilishini ham baholaymiz.',
  facility_check_audit_13: 'Ikki yechim',
  facility_check_audit_14: 'Ikki amalga oshirish usuli',
  facility_check_audit_15: 'Usul',
  facility_check_audit_16:
    'Ekspert auditorlari tomonidan Obʼyekt Tekshiruv Auditi',
  facility_check_audit_17: 'Obʼyekt Tekshiruvi Tasdiqlanishi',
  facility_check_audit_18: 'Hisobotlar',
  facility_check_audit_19:
    'Siz 48 soat ichida vizual dalillar va statistikalar bilan toʻldirilgan hisobotlarga ega boʻlasiz',
  facility_check_audit_20:
    'Yaxshi bajarilgan Obʼyekt Tekshiruv Auditi brendingiz uchun bir qator foydalar taqdim etishi mumkin:',
  facility_check_audit_21: 'Mijoz konvertatsiyasini yaxshilash:',
  facility_check_audit_22:
    'Ijobiy mijoz tajribasi savdo hajmining oshishi va doimiy xaridorlarning koʻpayishiga olib kelishi mumkin. Bizning auditlarimiz mijozlar qoniqishiga taʼsir qilishi mumkin boʻlgan sohalarni aniqlash va tuzatishda yordam berishi mumkin.',
  facility_check_audit_23: 'Brend imidjini oshirish:',
  facility_check_audit_24:
    'Fizik joylashuvlaringiz mijozlar bilan muhim aloqalar nuqtasi hisoblanadi va ular brend imidjingizni shakllantirishda katta rol oʻynashi mumkin. Bizning auditlarimiz joylashuvlaringiz brend qadriyatlaringizga mos kelishini va mijozlar uchun ijobiy taassurot qoldirishini taʼminlashga yordam beradi.',
  facility_check_audit_25: 'Nega biz?',
  facility_check_audit_26: 'Nega Obʼyekt Tekshiruv Auditi muhim?',
  facility_check_audit_27:
    'Yaxshi bajarilgan Obʼyekt Tekshiruv Auditi brendingiz uchun bir qator foydalar taqdim etishi mumkin:',
  facility_check_audit_28: 'Brend imidjini oshirish:',
  facility_check_audit_29:
    'Fizik joylashuvlaringiz mijozlar bilan muhim aloqalar nuqtasi hisoblanadi va ular brend imidjingizni shakllantirishda katta rol oʻynashi mumkin. Bizning auditlarimiz joylashuvlaringiz brend qadriyatlaringizga mos kelishini va mijozlar uchun ijobiy taassurot qoldirishini taʼminlashga yordam beradi.',
  facility_check_audit_30: 'Mijoz konvertatsiyasini yaxshilash:',
  facility_check_audit_31:
    'Ijobiy mijoz tajribasi savdo hajmining oshishi va doimiy xaridorlarning koʻpayishiga olib kelishi mumkin. Bizning auditlarimiz mijozlar qoniqishiga taʼsir qilishi mumkin boʻlgan sohalarni aniqlash va tuzatishda yordam berishi mumkin.',
  facility_check_audit_32: 'Kimga kerak',
  facility_check_audit_33: 'Kimga Obʼyekt Tekshiruv Auditi kerak?',
  facility_check_audit_34:
    'Obʼyekt Tekshiruv Auditi fizik joylashuvlar va fizik ishtirok etishga ega har qanday brend uchun qimmatli xizmatdir. Bu ayniqsa quyidagi maqsadlarni koʻzlagan brendlar uchun muhimdir:',
  facility_check_audit_35: 'Brend standartlariga rioya qilishni taʼminlash:',
  facility_check_audit_36:
    'Bizning auditlarimiz joylashuvlaringiz brend standartlaringizdan chetga chiqayotgan sohalarni aniqlashga yordam berishi mumkin.',
  facility_check_audit_37: 'Mijoz tajribasini yaxshilash:',
  facility_check_audit_38:
    'Bizning auditlarimiz joylashuvlaringizda mijoz tajribasini oshirish imkoniyatlarini aniqlashga yordam berishi mumkin.',
  facility_check_audit_39: 'Kuchli brend yaratish:',
  facility_check_audit_40:
    'Bizning auditlarimiz fizik joylashuvlaringiz mijozlar uchun ijobiy taassurot qoldirishini taʼminlashga yordam berishi mumkin.',
  facility_check_audit_41:
    'Xodimingiz yoki sirli xaridoringiz joyning fotosuratini oladi.',
  facility_check_audit_42:
    'Keyin ular soʻrovnomani toʻldirib, fotosuratni qoʻshadilar.',
  facility_check_audit_43:
    'Tekshiruvchi fotosuratni qabul qilib, uni standartlarga muvofiqlik boʻyicha tekshiradi.',
  facility_check_audit_44:
    'Ekspert auditorlari tomonidan Obʼyekt Tekshiruv Auditi',
  facility_check_audit_45: 'Tayyorlov bosqichi:',
  facility_check_audit_46: 'Audit jadvalini birgalikda tasdiqlash',
  facility_check_audit_47:
    'Dilerlar oldindan tayyorlanadi va kelayotgan audit haqida xabardor qilinadi',
  facility_check_audit_48: 'Auditorlarni tayyorlash:',
  facility_check_audit_49:
    'Tajribali 4Service trenerlarimiz auditorlarni brend koʻrsatmalari bilan tanishtiradilar',
  facility_check_audit_50: 'Obʼyekt Auditi bosqichi:',
  facility_check_audit_51:
    'Auditorlar maxsus ruxsat xatlari bilan joylarga tashrif buyuradilar',
  facility_check_audit_52:
    'Har bir nuqtani koʻrsatmalarga rioya qilgan holda sinchkovlik bilan tekshirish',
  facility_check_audit_53:
    'Vizual hujjatlashtirish va bosh ofis talablariga muvofiqlikni baholash',
  facility_check_audit_54: 'Yakuniy PPT Hisobot',
  facility_check_audit_55:
    'Hisobot natijalarini batafsil tushuntirib beruvchi toʻliq PowerPoint taqdimoti',
  facility_check_audit_56:
    'Auditorlik natijalarining onlayn/oflayn immersiv taqdimoti',
  facility_check_audit_57: 'Obʼyekt Tekshiruvi Tasdiqlanishi',
  facility_check_audit_58: 'Bosqichlar:',
  facility_check_audit_59: 'Obʼyekt Auditi bosqichi:',
  facility_check_audit_60:
    'Brend vakillari 4Service ilovasidan foydalanib joyida suratga oladilar',
  facility_check_audit_61: 'Qattiq vizual va tavsifiy mezonlarga rioya qilish',
  facility_check_audit_62: 'Tasdiqlash bosqichi:',
  facility_check_audit_63:
    'Sinchkov validatorlar har bir hisobotni diqqat bilan ko‘rib chiqadilar',
  facility_check_audit_64:
    'Fotosuratlarni brend standartlari bilan to‘liq taqqoslash',
  facility_check_audit_65:
    'Tushunarlilik uchun hisobotga izohlar va tushunchalar kiritish',
  facility_check_audit_66:
    'Zarurat bo‘lsa, dilerlar bilan to‘g‘ridan-to‘g‘ri muloqot qilish',
  facility_check_audit_67: 'Hisobot bosqichi:',
  facility_check_audit_68:
    'Fotosuratlar va statistik ma’lumotlarni o‘z ichiga olgan hisobotlar, ikki kun ichida foydalanish mumkin',
  facility_check_audit_69: 'Bugun biz bilan bog‘laning',
  cx_solution_1: 'Biz faqat tushunchalarni topish bilangina cheklanmaymiz,',
  cx_solution_2: 'Biz sizning biznesingizni yangi cho‘qqilarga olib chiqamiz',
  cx_solution_3:
    '20 yildan ortiq mijozlar tajribasiga ega mutaxassislar murakkab yechimlarni aniqlaydilar va sizning o‘sishingizga hissa qo‘shadigan aniq harakatlar rejasini taqdim etadilar',
  cx_solution_4: 'Kelajakka mos yagona xizmatlar to‘plamini kashf eting',
  cx_solution_5: 'Hammasi bir joyda: CX yechimi',
  cx_solution_6: 'Reklama testlari',
  cx_solution_7: 'AI & Neyromarketing',
  cx_solution_8: 'Mijoz ovozi',
  cx_solution_9: 'Stol tadqiqotlari',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Maxfiy xaridlar',
  cx_solution_12: 'CX konsalting',
  cx_solution_13: 'Ijtimoiy tarmoqlarni tinglash',
  cx_solution_14: 'Sifat va miqdoriy tadqiqotlar',
  cx_solution_15: 'Xizmat dizayni',
  cx_solution_16: 'Marketing strategiyasi',
  cx_solution_17: 'UX/UI testlash',
  cx_solution_18: 'Auditoriya tushunchalari',
  cx_solution_19:
    'Biz sizning muvaffaqiyatingizga hissa qo‘shishdan faxrlanamiz',
  cx_solution_20:
    'Biz sizning biznes maqsadlaringiz va vazifalaringiz asosida vositalar va xizmatlarni moslashtirib, kombinatsiya qilib, aniq natijalar taqdim etamiz',
  cx_solution_21: 'Har tomonlama tadqiqot',
  cx_solution_22:
    'Ma’lumotga asoslangan bozorda raqobatbardoshlikni ta’minlash uchun qaror qabul qilish jarayonlaringizni kuchaytiring.',
  cx_solution_23:
    'Sifat tadqiqoti - bu tushunchalar, fikrlar yoki tajribalarni tushunish uchun raqamli bo‘lmagan ma’lumotlarni to‘plash va tahlil qilish jarayoni',
  сx_solution_24:
    'Miqdoriy tadqiqot usullari - so‘rovnomalar, anketalar va intervyular orqali to‘plangan ma’lumotlarni ob’ektiv o‘lchash va tahlil qilish',
  cx_solution_25:
    'Biz sizning aniq talablaringiz asosida maxsus vositalar to‘plamini qo‘llaymiz',
  cx_solution_26: 'Vositalar',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Kompyuter yordamida veb-intervyu (CAWI) - bizning asosiy vositalarimizdan biri bo‘lib, onlayn platformalar orqali so‘rovlar o‘tkazish va ma’lumotlarni maxsus dasturiy ta’minot yordamida samarali va aniq to‘plash imkonini beradi',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Kompyuter yordamida telefon intervyusi (CATI) - bu telefon orqali so‘rov o‘tkazish va qimmatli ma’lumotlarni to‘plash usuli bo‘lib, jarayonni soddalashtirish va tadqiqot natijalarining aniqligini ta’minlash uchun maxsus dasturiy ta’minotdan foydalaniladi',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Yuzma-yuz intervyu (F2F) - bizning yondashuvimizning ajralmas qismi bo‘lib, mijozlar va iste’molchilar bilan bevosita muloqotni ta’minlaydi, bu esa ishonch, tushuncha va samarali aloqa o‘rnatishga yordam beradi',
  cx_solution_33: 'Stol tadqiqoti',
  cx_solution_34:
    'Stol tadqiqoti - ma’lumotlarni keng qamrovli to‘plash va tahlil qilish jarayoni bo‘lib, bizning tadqiqotlarimizning muhim bosqichi sifatida strategik qarorlar qabul qilishga yordam beradi',
  cx_solution_35: 'SWOT-tahlil',
  cx_solution_36:
    'SWOT-tahlil - bu strategik baholash vositasi bo‘lib, mijozlarimizga ichki kuchli va zaif tomonlarini, shuningdek tashqi imkoniyat va tahdidlarni aniqlashga yordam beradi, natijada samarali biznes strategiyalarini ishlab chiqishga asos yaratadi',
  cx_solution_37: 'Etnografik tadqiqot',
  cx_solution_38:
    'Tabiiy muhitda kuzatish va tadqiq qilish orqali odamlar va madaniyatlarni o‘rganish, bu esa xatti-harakatlar, e’tiqodlar va ijtimoiy dinamikani chuqur tushunishga olib keladi',
  cx_solution_39: 'Chuqurlashtirilgan intervyular',
  cx_solution_40:
    'Chuqurlashtirilgan intervyular - bu sifat tadqiqoti usuli bo‘lib, ishtirokchilar bilan birma-bir, batafsil suhbatlar orqali ularning fikrlari, tajribalari va qarashlarini chuqur o‘rganish imkonini beradi, natijada tadqiqot maqsadlari va qaror qabul qilish jarayonlari uchun muhim tushunchalar taqdim etadi',
  cx_solution_41: 'G‘oyalar sessiyalari',
  cx_solution_42:
    'Ideatsiya sessiyalari - bu biz mijozlarimizning muammolari yoki imkoniyatlari uchun ijodiy g‘oyalar va yechimlarni yaratish maqsadida turli nuqtai nazar va tajribalarni birlashtirib, innovatsiyani rivojlantirish va strategik tashabbuslarni oldinga surish uchun o‘tkazadigan hamkorlikdagi brainstorming sessiyalaridir',
  cx_solution_43: 'Xaridor personasi',
  cx_solution_44:
    "Xaridor personasi - bu biz tadqiqot va ma'lumotlar tahliliga asoslangan holda mijozlarimizning maqsadli auditoriyasini ifodalovchi batafsil profillardir. Bu bizga ularning ehtiyojlari, afzalliklari va xatti-harakatlarini yaxshiroq tushunishga, strategiyalarimiz va kommunikatsiyalarimizni ularga samarali moslashtirishga yordam beradi va oxir-oqibat biznes muvaffaqiyatiga erishishni ta’minlaydi",
  cx_solution_45: 'Yashirin mijoz',
  cx_solution_46:
    'Yashirin mijoz - bu mijoz va kompaniya o‘rtasidagi oldindan belgilangan ssenariy doirasidagi o‘zaro aloqalarni o‘lchash va nazorat qilishga qaratilgan strategik xizmatdir',
  cx_solution_47: 'Hamroh kuzatuv',
  cx_solution_48:
    'Hamroh kuzatuv - bu maxsus yashirin mijoz shakli bo‘lib, unda bizning baholovchilarimiz butun xizmat ko‘rsatish jarayonida mijozlarga hamrohlik qiladi. Bu esa har bir mijoz tajribasining har bir jihati haqida chuqur tushuncha olish, xizmat sifati bo‘yicha aniq ma’lumotlar olish va umumiy mijoz qoniqishini oshirish uchun zarur takomillashtirishlarni amalga oshirishga yordam beradi',
  cx_solution_49: 'Fikr-mulohazalarni yig‘ish va tahlil qilish',
  cx_solution_50:
    'Fikr-mulohazalarni yig‘ish va tahlil qilish - bu biz olib boradigan muhim jarayon bo‘lib, mijozlarning fikrlarini so‘rovnomalar, sharhlar va ijtimoiy tarmoqlar kabi turli kanallardan tizimli ravishda to‘plash va tahlil qilishni o‘z ichiga oladi. Bu esa qimmatli xulosalarni chiqarish, tendensiyalarni aniqlash va mijozlarimizga mahsulotlari, xizmatlari va umumiy mijoz tajribasini yaxshilash bo‘yicha ma’lumotlarga asoslangan tavsiyalar berish imkonini beradi',
  cx_solution_51: 'Fokus guruhlar',
  cx_solution_52:
    'Fokus guruhlar - bu biz tomonidan o‘tkaziladigan dinamik tadqiqot sessiyalaridir. Ular turli ishtirokchilarni ma’lum mavzular yoki mahsulotlar bo‘yicha chuqur muhokama qilish uchun bir joyga to‘playdi. Natijada, sifatli ma’lumotlarni yig‘ish, qarashlar, afzalliklar va tasavvurlarni aniqlash hamda g‘oyalar va kontseptlarni o‘rganish orqali mijozlarimizning qaror qabul qilish va strategiya ishlab chiqish jarayonlariga hissa qo‘shadi',
  cx_solution_53: '20 yillik mijozlar uchun mukammal tajribalar yaratish',
  cx_solution_54:
    'Xabaringiz auditoriyangizga ta’sir qilishi va uni qiziqtirishi kerak.',
  cx_solution_55:
    'Biz kutilgan natijalardan yuqori natijalarga erishishga intilamiz',
  cx_solution_56:
    'Biz sizning o‘sishingizga hissa qo‘shadigan murakkab yechimlarni topamiz',
  cx_solution_57:
    'Bizning metodologiyamiz orqali mijozlar tajribasini yuksaltiring',
  cx_solution_58:
    'Mijozlar yo‘li xaritasi - bu maqsadli auditoriyani aniqlash, ularning ehtiyojlari va muhitini tushunish hamda ularga qiziqarli tajriba taqdim etish rejasini yaratishdir',
  cx_solution_59: 'BHT',
  cx_solution_60:
    'Brend salomatligini kuzatish tadqiqoti brendning xabardorlik, foydalanish, pozitsiyalash va unumdorlik bo‘yicha qanday natijalarga erishayotganini o‘lchashga yordam beradi',
  cx_solution_61: 'CSI',
  cx_solution_62:
    'Mijozlar qoniqish indeksi kompaniyaning mahsulotlari/xizmatlaridan mijozlarning qanchalik mamnun ekanligini ko‘rsatadigan ko‘rsatkichdir.',
  cx_solution_63:
    'Narxlarni kuzatish - bu raqobatchilarning narxlarini to‘plash, qayta ishlash va tahlil qilish jarayonidir',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Net Promoter Score - bu mijoz tajribasini baholash uchun ishlatiladigan oltin standart bo‘lib, bitta asosiy savolga asoslangan: Tashkilot/Mahsulot/Xizmatni tavsiya qilish ehtimolingiz qanday?',
  cx_solution_66: 'HR tadqiqotlari',
  cx_solution_67:
    'Kadrlar resurslari tadqiqoti HR amaliyotlari va samaradorligini baholash hamda hozirgi rivojlanish va boshqaruv bo‘yicha batafsil tahlillarni taqdim etish uchun ishlatiladi',
  cx_solution_68:
    'Foydalanish testlari - bu haqiqiy odamlarning veb-sayt, ilova yoki boshqa mahsulot bilan qanday o‘zaro aloqada bo‘lishini va ularning xatti-harakatlarini kuzatish jarayoni',
  cx_solution_69: 'Boshqa',
  cx_solution_70:
    'Ekspertlar sizning biznesingiz uchun eng yaxshi metodologiyani tanlaydi',
  cx_solution_71: 'Keling, boshlaymiz',
  cx_solution_72: '1 - KICK OFF MEETING',
  cx_solution_73:
    'Kickoff yig‘ilishida asosiy vazifalar aniqlanadi va ularga e’tibor qaratiladi.',
  cx_solution_74: '2 - KONSEPT ISHLAB CHIQISH',
  cx_solution_75: 'Tahlil bo‘limi ma’lumotlarni jamlaydi va hisobotni tuzadi.',
  cx_solution_76: '3 - MAYDON TADQIQOTLARI',
  cx_solution_77: 'Ishtirokchilar jalb qilinadi, intervyular o‘tkaziladi.',
  сx_solution_78: '4 - TAHLIL',
  cx_solution_79: 'Natijalar mijozga taqdim etiladi.',
  cx_solution_80: '5 - WORKSHOP',
  cx_solution_81: 'Amalga oshirish rejasini mijoz bilan muhokama qilish',
  cx_solution_82:
    'Bizning eng yaxshi tajribalarimiz haqida ko‘proq bilib oling',
  cx_solution_83:
    'Mijoz yo‘li xaritasi barcha aloqa kanallarini tezkor tekshirish imkonini beradi.',
  cx_solution_84: 'Qachon foydalanish kerak?',
  cx_solution_85: 'Shaxsiylashtirilgan reklama kampaniyasini yarating',
  cx_solution_86: 'Mijoz ehtiyojlarini qondiring',
  cx_solution_87: 'Har bir guruhni aniq mo‘ljalga oling',
  cx_solution_88:
    'Hamroh kuzatuv texnikasi maqsadlarga erishishning optimal yo‘llarini baholash imkonini beradi.',
  cx_solution_89:
    'Yangi veb-sayt yoki mahsulotni ishga tushirishdan oldin sinovdan o‘tkazish',
  cx_solution_90: 'Ishlab chiqilgan jarayonlarni tekshirish',
  cx_solution_91: 'Veb-sayt yoki mahsulotni baholash',
  cx_solution_92: 'Mijozlarga moslashtirilgan mahsulotni ishga tushirish',
  cx_solution_93: 'Raqobatchilarni o‘rganish',
  cx_solution_94: '360° xizmat modeli',
  cx_solution_95: 'Xodimlarni o‘qitish',
  cx_solution_96: 'Xizmat standartlarini yaxshilash',
  cx_solution_97: 'Savdoni oshirish',
  cx_solution_98: 'Xodimlar uchun KPI',
  cx_solution_99: 'Muvaffaqiyat hikoyalari',
  cx_solution_100: 'Avtomobilsozlik',
  cx_solution_101: 'Tamaki',
  cx_solution_102: 'Chakana savdo',
  cx_solution_103: 'Sifat bosqichi',
  cx_solution_104: 'Miqdoriy bosqich (segmentatsiya)',
  cx_solution_105: 'Fokus guruhlar',
  cx_solution_106: 'Onlayn global auditoriyani qamrab olish',
  cx_solution_107: 'Intervyular davomida texnikalar aralashmasi',
  cx_solution_108: 'Faqat ochiq savollar',
  cx_solution_109: 'Javob beruvchilarni 24/7 qo‘llab-quvvatlash',
  cx_solution_110:
    'Muhim tafsilotlarni saqlash va yo‘lning haqiqiy manzarasini aks ettirish',
  cx_solution_111: 'Bozor tadqiqoti',
  cx_solution_112: 'Kompaniya va raqobatchilar diagnostikasi',
  cx_solution_113:
    'Yangilangan xizmat ko‘rsatish modellar va yangi biznes jarayonlari',
  cx_solution_114: 'Mijozga yo‘naltirilgan xizmatni ilgari surish',
  cx_solution_115:
    'O‘zgarishlarni joriy etish va xizmat strategiyalarining rivojlanishini nazorat qilish',
  usage_attitude_1: 'Foydalanish va \n Munosabat (U&A)',
  usage_attitude_2:
    'Maqsadli auditoriyaning \n brendingizga bo‘lgan munosabati, foydalanish usullari va jalb etilishining to‘liq tushunchasini oling.',
  usage_attitude_3: 'Mutaxassis bilan gaplashing',
  usage_attitude_4: 'Haqida',
  usage_attitude_5:
    '<b>Iste’molchilarning</b> odatlarini o‘rganib, ularning xohishlari va ta’sir omillarini chuqur tushuning',
  usage_attitude_6:
    "<b>Iste’molchilar tanlovining 'nima uchun'ini</b> bizning batafsil tahlilimiz yordamida kashf eting",
  usage_attitude_7:
    '<b>Qanday qilib</b> mijozlar mahsulotlaringiz bilan o‘zaro aloqada bo‘lishini strategik tushuncha uchun anglab oling',
  usage_attitude_8:
    '<b>Iste’molchilarning xulq-atvori, odatlari va afzalliklarini</b> chuqur o‘rganib, ongli qarorlar qabul qiling',
  usage_attitude_9: 'Tajriba',
  usage_attitude_10:
    '4Service ekspertlari 20 yildan ortiq mijoz tajribasiga ega bo‘lib, biznesingiz o‘sishiga yordam beradigan noyob yechimlar yaratadi',
  usage_attitude_11: 'Biz bilan bog‘laning',
  usage_attitude_12: 'Kimlar uchun',
  usage_attitude_13: 'Brend foydalanishi va munosabati kimga foyda keltiradi?',
  usage_attitude_14:
    'Turli sohalar va xizmat ko‘rsatish sektorlarining qiziqishlari:',
  usage_attitude_15: '<b>Mijozlar talab va muammolarini</b> aniqlash',
  usage_attitude_16: '<b>Mijoz iste’molini oshirish</b> imkoniyatlarini topish',
  usage_attitude_17:
    '<b>Yangi mahsulot toifalari yoki marketing strategiyalarini</b> ishlab chiqish',
  usage_attitude_18: '<b>Raqobat muhitini</b> chuqur tushunish',
  usage_attitude_19:
    'Metodologiya mijozning so‘roviga qarab individual tanlanadi',
  usage_attitude_20:
    '<b>Sifat tadqiqotlari</b> tushunchalar, fikrlar yoki tajribalarni tushunish uchun sonli bo‘lmagan ma’lumotlarni to‘plash va tahlil qilishni o‘z ichiga oladi.',
  usage_attitude_21:
    '<b>Miqdoriy tadqiqot usullari</b> so‘rovlar, anketalar va tekshiruvlar orqali olingan ma’lumotlarni ob’ektiv o‘lchash va sonli tahlil qilishni nazarda tutadi.',
  usage_attitude_22:
    '<b>Ma’lumotlar to‘planganidan so‘ng</b>, ekspertlar tahlil olib boradi va eng yaxshi tavsiyalarni taqdim etish uchun xulosa chiqaradi.',
  usage_attitude_23: 'Kimga kerak',
  usage_attitude_24: 'Sizning muvaffaqiyat hikoyangiz shu yerda boshlanadi',
  usage_attitude_25:
    '<b>Siz tadqiqot</b> natijasida mijozlarning xulq-atvori haqida qimmatli tushunchalarga ega bo‘lasiz, bu esa strategik qarorlarni aniq asos bilan qabul qilish imkonini beradi.',
  usage_attitude_26:
    '<b>Tadqiqot brend strategiyalarini takomillashtirish</b> va ularni iste’molchi afzalliklari hamda munosabatlariga yaqinlashtirish uchun mustahkam asos yaratadi.',
  usage_attitude_27: 'Yechimlar',
  usage_attitude_28:
    'Bizning yechimlarimiz orqali ko‘plab imkoniyatlarni o‘rganing',
  usage_attitude_29: 'Mahsulot toifasidagi afzalliklar',
  usage_attitude_30:
    'Turli toifalardagi iste’molchilarning asosiy xohishlarini aniqlang, jumladan, oziq-ovqat, ichimliklar, kiyim-kechak, elektronika va boshqalar.',
  usage_attitude_31: 'Iste’mol / foydalanish xulq-atvori',
  usage_attitude_32:
    'Muayyan mahsulot/xizmatlardan foydalanish yoki iste’mol qilish chastotasini tahlil qiling, ularning ishlatilish vaqti va holatlarini aniqlang.',
  usage_attitude_33: 'Xarid qilish xulq-atvori',
  usage_attitude_34:
    'Mijozlar sotib olish uchun qaysi kanallardan foydalanishini va qaysi ma’lumot manbalariga tayanishini aniqlang.',
  usage_attitude_35: 'Iste’mol motivatsiyasi va ehtiyojlari',
  usage_attitude_36:
    'Iste’molga ta’sir qiluvchi motivatsiyalar va to‘siqlarni o‘rganing. Xarid qilish qarorlariga ta’sir qiluvchi omillarni baholang.',
  usage_attitude_37: 'Bizning yondashuvimiz',
  usage_attitude_38: 'Brend foydalanishi va munosabat tadqiqoti',
  usage_attitude_39: 'Ma’lumot',
  usage_attitude_40: 'Sifat va miqdoriy usullar yordamida so‘rovlar o‘tkazish',
  usage_attitude_41: 'Boshlash',
  usage_attitude_42:
    'Maqsadlarni belgilash va individual so‘rov asosida usullarni aniqlash',
  usage_attitude_43: 'Tahlil',
  usage_attitude_44:
    'Ekspertlarimiz tomonidan qo‘llaniladigan ilg‘or texnikalar',
  usage_attitude_45:
    'Biz sizning aniq talablaringizga asoslangan turli vositalar to‘plamini qo‘llaymiz',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Computer-Assisted Telephone Interviewing – bu biz qo‘llaydigan usul bo‘lib, u orqali so‘rovlar o‘tkazamiz va maxsus dasturiy ta’minot yordamida telefon orqali qimmatli ma’lumotlarni to‘playmiz. Bu jarayonni soddalashtirish va bozor tadqiqotlarimizning aniqligini ta’minlashga yordam beradi.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Computer-Assisted Web Interviewing – bu bizning muhim vositalarimizdan biri bo‘lib, maxsus dasturiy ta’minot yordamida onlayn platformalar orqali so‘rov o‘tkazish va ma’lumot yig‘ishga imkon beradi. Bu mijozlarimizning bozor tadqiqotlari ehtiyojlari uchun samarali va aniq ma’lumotlarni yig‘ishni ta’minlaydi.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Computer-Assisted Personal Interviewing – bu biz foydalanadigan usul bo‘lib, u ma’lumot yig‘ish jarayonini optimallashtirish, intervyularni osonlashtirish va biznesingiz ehtiyojlari uchun aniq natijalarni ta’minlashga yordam beradi.',
  usage_attitude_52: 'Focus groups',
  usage_attitude_53:
    'Focus groups – bu biz tashkil qiladigan dinamik tadqiqot sessiyalari bo‘lib, turli ishtirokchilarni ma’lum mavzular yoki mahsulotlar bo‘yicha chuqur muhokama qilish uchun bir joyga to‘playdi. Bu bizga sifatli tahlillarni yig‘ish, qarashlar, afzalliklar va idroklarni aniqlash, shuningdek, g‘oyalar va tushunchalarni o‘rganish imkonini beradi, natijada esa mijozlarimizning qaror qabul qilish va strategik rivojlanish jarayonlariga yordam beradi.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Face-to-Face – bizning yondashuvimizning ajralmas qismidir. U mijozlar va iste’molchilar bilan bevosita muloqot va shaxsiy uchrashuvlarni tashkil qilishga yordam beradi, bu esa ishonch, tushunish va uzoq muddatli munosabatlarni shakllantirish uchun muhim hisoblanadi. Bu biznes muvaffaqiyatiga erishish uchun zarur bo‘lgan samarali aloqa o‘rnatishga xizmat qiladi.',
  usage_attitude_56: 'Ethnographic Research',
  usage_attitude_57:
    'Odamlar va madaniyatlarni tabiiy muhitda kuzatish va ularga singib ketish orqali o‘rganish, ko‘pincha xatti-harakatlar, e’tiqodlar va ijtimoiy dinamikalar bo‘yicha chuqur tushunchalarga olib keladi.',
  thank_you_page:
    'Vaqtingiz uchun rahmat! Menejerimiz siz bilan tez orada bog‘lanadi.',
  back_main: 'Bosh sahifaga qaytish',
  opportunities_1: 'Bizning holatlarimiz haqida ko‘proq bilib oling',
  opportunities_2: 'Kashf eting',
  opportunities_3: 'o‘zingiz uchun',
  opportunities_4: 'imkoniyatlar',
  opportunities_5:
    'xalqaro biznes imkoniyatlari 4Service tadqiqot xoldingi bilan birga',
  opportunities_6:
    'Qaysi <span>mahsulotlar</span> bilan biznesingizni kuchaytira olamiz?',
  opportunities_7: 'CX',
  opportunities_8: 'Biznes uchun kompleks individual yechimlar',
  opportunities_9: 'Barcha turdagi Mystery Shopping',
  opportunities_10:
    'Biz mystery xodim, mystery qo‘ng‘iroq, luxury mystery shopper, avtomobil mystery shopper va boshqa turlarni joriy qilamiz',
  opportunities_11: 'Biznes uchun individual IT yechimlari',
  opportunities_12:
    'AI asosida o‘z IT yechimlarimiz, fikr-mulohazalarni tahlil qilish va qo‘ng‘iroqlarni transkripsiya qilish platformalari',
  opportunities_13: 'Bozor tadqiqoti',
  opportunities_14:
    'Har qanday biznes so‘rovlari uchun (Brand Health Tracking, Segmentatsiya, NPS, eNPS, CJM, Mahsulot va reklama testlari va boshqalar)',
  opportunities_15: 'Auditlar',
  opportunities_16:
    'Biz turli xizmat auditlarini, shuningdek, narxlarni monitoring qilishni amalga oshiramiz (offline, ma’lum bir segmentlar uchun va h.k.)',
  opportunities_17: 'Xizmat dizayni',
  opportunities_18:
    '4Service - DesignThinkers Academy sertifikatlangan hamkorlari bo‘lib, jamoalar uchun korporativ treninglar taqdim etadi va biznes uchun muntazam seminarlar o‘tkazadi',
  opportunities_19:
    'Biz tajribamizni baham ko‘ramiz, lekin <span>hurmat qilamiz va sizning biznes jarayoningizni buzmaymiz</span>.',
  opportunities_20:
    'Biz biznes o‘sish yo‘lida duch keladigan qiyinchiliklarni bilamiz va xalqaro muvaffaqiyatli tajriba orqali bu to‘siqlarni qanday yengish mumkinligini bilamiz. Siz nafaqat tajribaga, balki resurslarga ham ega bo‘lasiz.',
  opportunities_21: 'Sizni hamkorlikka taklif qilamiz!',
  opportunities_22:
    'Biz biznesingiz uchun <span>hamkorlik dasturini</span> taklif qilamiz',
  opportunities_23:
    '4Service xalqaro brendi ostida siz yangi mijozlarni jalb qilishga ijobiy ta’sir o‘tkaza olasiz.',
  opportunities_24:
    'Bizning hamkorlarimiz keng qamrovli qo‘llab-quvvatlashga ega, jumladan treninglar, marketing materiallari va operatsion yordam.',
  opportunities_25:
    'Biz bilan hamkorlik sizning hozirgi biznes jarayoningizga ta’sir qilmasdan rejalaringizni amalga oshirishga yordam beradi.',
  opportunities_26: 'Bizning <span>ofislarimiz</span>',
  opportunities_27: 'Bizning mijozlarimiz',
  opportunities_28: 'Bizda CX bo‘yicha 20 yildan ortiq tajriba bor',
  opportunities_29: 'Qamrab olingan mamlakatlar',
  opportunities_30: 'ming',
  opportunities_31: 'Noyob loyihalar',
  opportunities_32: 'million',
  opportunities_33: 'Mystery shopper tashriflari',
  opportunities_34:
    'Yevropaning eng yaxshi 10 ta CX agentligi tarkibiga kiradi',
  opportunities_35: 'Sizning 4Service bilan imkoniyatlaringiz',
  opportunities_36: 'Daromadning o‘sishi',
  opportunities_37:
    'Siz xalqaro buyurtmalarga kirish imkoniyatiga ega bo‘lasiz',
  opportunities_38: 'Noyob CX yondashuvi',
  opportunities_39:
    '20 yillik tajribaga ega mutaxassislar tomonidan qo‘llab-quvvatlash',
  opportunities_40: 'Qo‘llab-quvvatlash',
  opportunities_41:
    'Bizning jamoamiz natijaga erishishingizda sizni qo‘llab-quvvatlaydi',
  opportunities_42: 'Sotuvlar',
  opportunities_43: 'Sizga savdo tizimini yo‘lga qo‘yishda yordam beramiz',
  opportunities_44: 'Dasturiy ta’minotga kirish',
  opportunities_45:
    'Siz barcha sertifikatlangan IT vositalaridan foydalanish imkoniyatiga ega bo‘lasiz',
  opportunities_46: 'Trening',
  opportunities_47:
    'Biz nafaqat xodimlaringizni o‘qitamiz, balki maslahat xizmatlarini davom ettiramiz',
  opportunities_48: 'Kimlar hamkorlik uchun mos?',
  opportunities_49: 'Sizning biznesingiz marketing tadqiqotlariga bog‘liqmi?',
  opportunities_50: 'Yoki o‘zingizni yangi sohada sinab ko‘rmoqchimisiz?',
  opportunities_51: 'Siz rejalashtiryapsizmi:',
  opportunities_52: 'Innovatsiyalarni joriy qilish',
  opportunities_53: 'Xalqaro brendlar bilan ishlash',
  opportunities_54: 'Mahsulot portfelingizni kengaytirish',
  opportunities_55: 'Bozordagi o‘rningizni mustahkamlash?',
  opportunities_56: 'Xodimlaringizni o‘qitish',
  opportunities_57: 'Biznesingizni kengaytirish',
  opportunities_58: 'Bizning resurslarimiz = Sizning resurslaringiz',
  opportunities_59:
    'Bizning keng tajribamizni hisobga olgan holda, hamkorlikning ilk bosqichidayoq uni siz bilan baham ko‘rishga tayyormiz',
  opportunities_60: 'Ma’lumotlarni tahlil qilish:',
  opportunities_61: 'Xalqaro tahlilchilar jamoasi',
  opportunities_62: 'Ma’lumotlarni qayta ishlash usullari - SPSS, Power BI',
  opportunities_63: 'Innovatsion metodologiyalar va keng tadqiqot usullari',
  opportunities_64: 'Istalgan formatda hisobotlar',
  opportunities_65: 'Dasturiy ta’minot:',
  opportunities_66: 'O‘zimizga xos individual IT ishlanmalar',
  opportunities_67: 'DigSee Sure for CAPI',
  opportunities_68: 'SurveyMonkey for CAWI',
  opportunities_69: 'Shopmetrics',
  opportunities_70: 'VoIPTime (call center)',
  opportunities_71: 'Marketing yordami:',
  opportunities_72: 'PR yordami',
  opportunities_73: 'Targ‘ibot va kontent yaratishda qo‘llab-quvvatlash',
  opportunities_74: 'Lead generation jarayonlarini sozlash',
  opportunities_75: 'IT yordami:',
  opportunities_76:
    'Dasturiy ta’minotimizni o‘rnatish, sozlash va ishlatishda texnik yordam.',
  opportunities_77: 'Masofaviy yordam',
  opportunities_78: 'Xavfsizlik nazorati',
  opportunities_79: 'Operatsion yordam:',
  opportunities_80: 'Maydon jarayonlarini yo‘lga qo‘yishda qo‘llab-quvvatlash',
  opportunities_81: 'Anti-fraud tizimi',
  opportunities_82: 'Har qanday murakkablikdagi ishga qabul qilish',
  opportunities_83:
    'Loyiha va maydon bo‘limlari jamoasining operatsion boshqaruvi',
  opportunities_84: 'Ishga qabul sifat nazorati',
  opportunities_85: 'UpWork',
  opportunities_86: 'Qanday bonuslarga ega bo‘lasiz?',
  opportunities_87: 'Mijoz bazasining o‘sishi',
  opportunities_88: 'Bozordagi imidjning mustahkamlanishi',
  opportunities_89: 'Mijoz yo‘qotish xavflarining diversifikatsiyasi',
  opportunities_90: 'Texnologik jarayonlarni optimallashtirish',
  opportunities_91: 'Xalqaro tajriba',
  opportunities_92: 'Mavjud mijozlar bilan ko‘proq savdo qilish',
  main_67:
    'Raqobatbardosh narxlarni tahlil qilish takliflarni optimallashtiradi, foydani oshiradi va iste’molchilarning to‘lovga tayyorligi bo‘yicha ma’lumotlar orqali bozor ulushini kengaytiradi',
  main_68:
    'Sizning chakana savdo shoxobchalarining brend standartlariga mosligini ta’minlaydi, jismoniy atributlarni va mijozlarga ta’sirini baholaydi',
  main_69: 'CX yechim',
  main_70:
    'Bizning noyob 360 darajali CX yechimimizni kashf eting: biz biznesingizni rivojlantirish maqsadlaringiz asosida vositalar va xizmatlarni moslashtiramiz',
  main_71: 'Nutq tahlili',
  main_72:
    'Bizning ilg‘or nutq tahlili vositamiz og‘zaki nutqni matnga aylantiradi, biznes uchun mijoz tajribasini yaxshilash, ishlashni kuzatish, muvofiqlikni ta’minlash va o‘sishni rag‘batlantirish bo‘yicha qimmatli ma’lumotlarni taqdim etadi',
  parthership: 'Hamkorlik',
  banner_1: 'CX konsalting va xizmatlari - biznesingizga 360° ko‘rinish',
  banner_2: 'So‘nggi tadqiqotlardan xabardor bo‘ling!',
  banner_3:
    'Shaxsiy ma’lumotlarimni Maxfiylik siyosatiga muvofiq qayta ishlashga rozilik beraman',
  banner_4: '*Bozorni tanlang',
  banner_5: 'Obuna bo‘lganingiz uchun rahmat!',
  banner_6: 'Siz bilan faqat eng qiziqarli ma’lumotlarni ulashamiz!',
  banner_7: 'Yuborish',
  banner_8: 'Ukraina',
  banner_9: 'Qozog‘iston',
  brand_awareness_1: 'Brend xabardorligi',
  brand_awareness_2: 'va foydalanish',
  brand_awareness_3:
    'Brendingizning xabardorligi va foydalanish imkoniyatlarini oching va biznes muvaffaqiyatiga erishing',
  brand_awareness_4: 'Mutaxassis bilan bog‘laning',
  brand_awareness_5: 'Haqida',
  brand_awareness_6:
    '<b>Brendingizning</b> tanishligi va mashhurlik darajasini baholang',
  brand_awareness_7:
    '<b>Brend xabardorligi</b> darajasini maqsadli bozorlaringizda aniqlang',
  brand_awareness_8:
    '<b>Saqlash va sadoqat darajalarini</b> diqqat bilan kuzatib boring',
  brand_awareness_9:
    '<b>Zarur ko‘rsatkichlarni yaxshilash</b> uchun optimal strategiyani tanlang',
  brand_awareness_10:
    'Bizning yechimlarimiz bilan ko‘plab imkoniyatlarni o‘rganing',
  brand_awareness_11: 'Brend xabardorligi',
  brand_awareness_12: 'Brenddan foydalanish',
  brand_awareness_13:
    '<b>Brendingizni</b> iste’molchi segmentlari orasida tanib oling',
  brand_awareness_14:
    '<b>Auditoriyangiz orasida</b> xabardorlik darajasini kuzatib boring',
  brand_awareness_15:
    '<b>Xarid qilish</b> arafasidagi iste’molchilarning spontan fikrlarini o‘rganing',
  brand_awareness_16:
    '<b>Brend xabardorligiga</b> hissa qo‘shayotgan asosiy manbalarni tushuning',
  brand_awareness_17:
    '<b>Brendingiz mahsulotlari yoki xizmatlarini</b> tajribadan o‘tkazgan iste’molchilar sonini hisobga oling',
  brand_awareness_18:
    '<b>Brendingizni dastlab sinab ko‘rib, unga sodiq bo‘lib qolgan</b> iste’molchilarni aniqlang',
  brand_awareness_19: '<b>Brendingiz tavsiya qilinishi ehtimolini</b> oching',
  brand_awareness_20:
    '<b>Kelajakda brendingizdan foydalanishni</b> ko‘rib chiqayotgan iste’molchilar ulushini aniqlang',
  brand_awareness_21: 'Kimlar brend xabardorligi va foydalanishdan manfaatdor?',
  brand_awareness_22: 'Yangi mahsulotni ishga tushirayotgan bizneslar',
  brand_awareness_23: 'O‘rnatilgan brendni yangilayotgan bizneslar',
  brand_awareness_24:
    'Brendini qanday tanilganini va iste’molchilar tomonidan qanday ishlatilishini tushunishga harakat qilayotgan har qanday biznes',
  brand_awareness_25:
    'Bizning metodologiyalarimiz brendni tanishlik va foydalanishni optimallashtirish uchun qimmatli fikrlarni taqdim etadi',
  brand_awareness_26:
    'Metodologiya mijozning so‘rovi asosida alohida tanlanadi',
  brand_awareness_27: 'Brend foydalanish xududi metodologiyasi',
  brand_awareness_28:
    'Sinov darajasi brendni bilgan va uning mahsulotlari yoki xizmatlarini sinab ko‘rgan auditoriyaning nisbati haqida ma’lumot beradi.',
  brand_awareness_29:
    'Takrorlash darajasi brendni bilgan va uning mahsulotlari yoki xizmatlarini sinab ko‘rgan auditoriyaning o‘tgan 12 oy ichida xaridlar yoki takliflardan foydalanishda davom etgan foizini ko‘rsatadi.',
  brand_awareness_30:
    'Usish darajasi, brendning mahsulotlari yoki xizmatlarini muntazam ishlatgan shaxslarning nisbati, so‘nggi 6 oyda ushbu takliflardan foydalangan foizni ko‘rsatadi.',
  brand_awareness_31:
    'Usish darajasi, brendning mahsulotlari/xizmatlaridan so‘nggi 6 oyda foydalangan mijozlarning foizini ko‘rsatadi va bu mijozlarning vaqt o‘tishi bilan brendga bo‘lgan sadoqatini namoyish etadi.',
  brand_awareness_32: 'Brendni anglash metodologiyasi',
  brand_awareness_33:
    'Mindda birinchi bo‘lib aytib o‘tilgan brend TOM (Top of Mind) hisoblanadi.',
  brand_awareness_34:
    'Jami spontan tanishlik, TOMni ham o‘z ichiga olgan spontan aytilgan barcha eslatmalarning umumiy miqdorini anglatadi.',
  brand_awareness_35:
    'Yordamchi tanishlik brendingizning foydalanuvchilar tomonidan qanday tanilishini ko‘rsatadi.',
  brand_awareness_36: 'Brend atributlari ishlash xaritasi',
  brand_awareness_37:
    'Ushbu vosita brend atributlarining ahamiyatini baholaydi va ularning har biri brendingizning umumiy ishlashi va iste’molchi taassurotiga qanday hissa qo‘shishini ko‘rsatadi.',
  brand_awareness_38:
    'Brendingizning ta’sirini kuchaytirish uchun kalitlarni oching, bizning keng qamrovli tahlilimiz bilan.',
  brand_awareness_39: 'Brendlarning perceptual xaritasi',
  brand_awareness_40:
    'Ushbu vosita iste’molchi taassurotlarini raqobatbardosh bozor manzarasida vizual tarzda ko‘rsatadi. Ushbu yondashuv brendlar o‘rtasidagi farqlarni aniq belgilash uchun zarur bo‘lib, bozorning muhim atributlarini ta’kidlashni osonlashtiradi.',
  brand_awareness_41:
    'Bu brendlar va ushbu belgilovchi xususiyatlar o‘rtasidagi aloqalarni kuzatish va talqin qilishni osonlashtiradi.',
  brand_awareness_42:
    'Maxsus brendning ba’zi atributlarga yaqinligi uning o‘sha xususiyatlar bilan kuchli aloqasini anglatadi. Shuningdek, ikkita brend o‘rtasidagi yaqinlik ularning o‘xshashligini ko‘rsatadi, umumiy obrazlar va, shu bilan birga, bir xil bozor segmentida aloqalarni ko‘rsatadi.',
  consumer_journey_map_1: 'Iste’molchi Yo‘li Xaritası',
  consumer_journey_map_2:
    'Bizning Iste’molchi Yo‘li Xaritamiz bilan biznesingizdagi to‘siqlarni kashf eting va ulardan xalos bo‘ling',
  consumer_journey_map_3: 'ISHONCHLI',
  consumer_journey_map_4: '4Service',
  consumer_journey_map_5:
    'Iste’molchilaringiz va brendingiz o‘rtasidagi o‘zaro aloqalarni optimallashtirish uchun CJM potentsialini oching',
  consumer_journey_map_6:
    'Iste’molchi mahsulot sotib olishdan oldin qanday qadamlarni qo‘yishini aniqlang',
  consumer_journey_map_7:
    'Haqiqiy mijozlardan, tasavvur qilganlaridan emas, fikrlar oling',
  consumer_journey_map_8: 'Mijozlaringizning tajribasini to‘liq tushunib oling',
  consumer_journey_map_9:
    'O‘tkazib yuborilgan imkoniyatlarni aniqlang va raqobatchilaringizga qarshi benchmark qilish',
  consumer_journey_map_10: 'Bizning CJM yondashuvimizni kashf eting',
  consumer_journey_map_11:
    'Biz mahsulot, xizmat, kompaniya yoki brend bilan iste’molchilarning o‘zaro aloqalari yo‘lini yaratamiz, turli kanallar orqali va ma’lum vaqt davomida.',
  consumer_journey_map_12:
    'Biz mijozlarning xaridni yakunlashdan oldin o‘tgan bosqichlarini, kutganlarini, hissiyotlarini va motivatsiyalarini ko‘rsatamiz.',
  consumer_journey_map_13: 'Biz bilan foyda oling',
  consumer_journey_map_14:
    'Biz global biznes muvaffaqiyatining murakkab jihatlarini tushunadigan tajribali mutaxassislar jamoasini taqdim etamiz',
  consumer_journey_map_15:
    'Biz sizning kutganingizdan yuqori natijalar taqdim etishga sodiqmiz',
  consumer_journey_map_16:
    'Biz o‘sishingizga hissa qo‘shadigan murakkab echimlarni kashf etamiz',
  consumer_journey_map_17:
    'Biz moslashtirilgan CJM echimlarini taqdim etishda mutaxassislikka egamiz',
  consumer_journey_map_18: 'Konsultatsiya oling',
  consumer_journey_map_19: 'Ma’lumotlarga asoslangan qarorlar',
  consumer_journey_map_20:
    'Qarorlaringizni taxminlar asosida emas, balki haqiqiy ma’lumotlarga asoslang',
  consumer_journey_map_21: 'CJM-ni optimallashtiring',
  consumer_journey_map_22:
    'Iste’molchilaringizning har bir bosqichida ularning ehtiyojlari va kutganlarini qondirish orqali mijozlar bilan kuchliroq munosabatlar o‘rnating',
  consumer_journey_map_23: 'Yo‘lda yetakchilik qiling',
  consumer_journey_map_24:
    'O‘zingizni raqobatchilaringiz bilan taqqoslang va bozorga oldinga chiqing',
  consumer_journey_map_25: 'Tavsiyalar',
  consumer_journey_map_26:
    'CJM tavsiyalarimizni amalga oshirib muvaffaqiyatni oching, mijozlar sadoqatini rivojlantiring, sotuvlarni oshiring va biznes potensialingizni maksimal darajada oshiring',
  consumer_journey_map_27:
    'Bizning Iste’molchi Yo‘li Xaritamiz bilan haqiqiy iste’molchi yo‘lini kashf eting',
  consumer_journey_map_28:
    '20+ yillik tajribaga ega mutaxassislar keng qamrovli tadqiqot olib boradi',
  consumer_journey_map_29:
    'Sifatli tadqiqotlar kontseptsiya, fikr yoki tajribalarni tushunish uchun raqamli bo‘lmagan ma’lumotlarni yig‘ish va tahlil qilishni o‘z ichiga oladi.',
  consumer_journey_map_30:
    'Soni va miqdori tahlil qilinadigan ma’lumotlar yig‘ish uchun kvantitativ tadqiqot usullari qo‘llaniladi.',
  consumer_journey_map_31:
    'Ma’lumotlar to‘plangach, mutaxassislar tahlil qilib, sizga eng yaxshi tavsiyalarni taqdim etadilar.',
  boarding_1: 'Mijoz yo‘li xaritasi',
  boarding_2:
    'Mijoz Yo‘li Xaritalash (CJM) maqsadli auditoriyani aniqlash, ularning ehtiyojlari va muhitini tushunish va keyin jalb etuvchi tajribani taqdim etish uchun reja tuzish haqida',
  boarding_3: 'Net Promoter Score',
  boarding_4:
    'Net Promoter Score (NPS) mijoz tajribasi metrikalarining oltin standarti bo‘lib, bitta savolga asoslanadi: Siz tashkilot/mahsulot/xizmatni tavsiya qilish ehtimolingiz nechog‘lik?',
  boarding_5: 'Brend sog‘lig‘ini kuzatish',
  boarding_6:
    'Brend sog‘lig‘ini kuzatish (BHT) tadqiqoti brendning tanishlik va foydalanish, brendning pozitsiyasi va brendning ishlashini o‘lchaydi',
  boarding_7: 'Inson resurslari tadqiqoti',
  boarding_8:
    'Inson resurslari tadqiqoti HR amaliyotlari va ish faoliyatini baholash uchun ishlatiladi va hozirgi rivojlanish va boshqaruv haqida batafsil tahlilni taqdim etadi',
  boarding_9: 'UX testi',
  boarding_10:
    'Usability (UX) testi - bu haqiqiy odamlarning veb-sayt, ilova yoki boshqa mahsulotlar bilan qanday aloqada bo‘lishini va ularning unga bo‘lgan munosabatini kuzatish',
  boarding_11: 'Mijozlar qoniqish indeksi',
  boarding_12:
    'Mijozlar qoniqishi, mijozning mahsulot yoki xizmat sotib olishga bo‘lgan kutganlariga nisbatan qancha qoniqish hosil bo‘lganini o‘lchaydi',
  boarding_13: 'Reklama testi',
  boarding_14:
    'Reklama kampaniyasi katta sarmoya. Reklamalaringiz kuchli va maksimal ROI taqdim etishiga ishonch hosil qiling',
  boarding_15: 'Maydon auditi/Tijorat auditi',
  boarding_16:
    'Doʻkoni yoki brendingiz haqida batafsil maʼlumot toʻplang, o‘sish toʻsiqlarini tahlil qiling va ularga qarshi samarali strategiyalar yaratish',
  boarding_17: 'Play4Sales',
  boarding_18:
    'Ishlashni boshqarish dasturi. Sotuvlarni oshirish uchun gamifikatsiya qilingan ilova',
  boarding_19: 'Voicer',
  boarding_20:
    'Fikr-mulohaza boshqaruvi tizimi. Mijozingizning ovozi bir tizimda. Mijoz fikr-mulohazalarini boshqarish tizimi',
  boarding_21: 'Sifatli tadqiqot',
  boarding_22:
    'Sifatli tadqiqot — tushunchalar, fikrlar yoki tajribalarni tushunish uchun raqamli bo‘lmagan maʼlumotlarni yig‘ish va tahlil qilish',
  boarding_23: 'Miqdoriy tadqiqot',
  boarding_24:
    'Miqdoriy tadqiqot usullari — so‘rovlar, anketalar va so‘rovlar orqali yig‘ilgan maʼlumotlarni ob‘ektiv ravishda o‘lchash va raqamli tahlil qilish',
  boarding_25: 'Misteriya xarid qilish',
  boarding_26:
    'Misteriya xarid qilish — mijoz va kompaniya yoki tashkilot o‘rtasidagi o‘zaro aloqalarni o‘lchash va monitoring qilish uchun bozor tadqiqoti usuli',
  boarding_27: 'Wantent',
  boarding_28:
    'Sun‘iy intellekt bilan ishlov berilgan kontent intellekti platformasi. Auditoriyadan fikrlar olish',
  boarding_29: 'Narxlarni monitoring qilish',
  boarding_30:
    'Narxlarni monitoring qilish — raqobatchilarning narxlarini yig‘ish, qayta ishlash va tahlil qilish jarayoni',
  boarding_31: 'Xizmat dizayni',
  boarding_32:
    'Xizmat dizayni — mahsulot yoki xizmatdan foydalanuvchilarning ehtiyojlarini va xohishlarini tushunish, ular bilan vaqt o‘tkazish orqali amalga oshiriladi',
  boarding_33: 'Avtomobil sanoati narxlari tahlili',
  boarding_34:
    'Raqobatbardosh narx tahlili takliflarni optimallashtiradi, foydalarni oshiradi va bozor ulushini yaxshilaydi, iste’molchilarning to‘lashga tayyorligini tahlil qilish orqali asosiy xususiyatlarni aniqlaydi',
  boarding_35: 'Ob‘ekt tekshiruvi auditi',
  boarding_36:
    'Sizning chakana savdo nuqtalaringiz brend standartlariga mos kelishini ta’minlaydi, jismoniy xususiyatlarni va ularning mijozlarning qarashlariga ta’sirini baholaydi',
  boarding_37: 'CX yechimi',
  boarding_38:
    'Bizning noyob 360 darajali CX yechimimizni kashf eting: biz biznesingizni boshqarish uchun vositalar va xizmatlarni maqsadlaringizga moslashtiramiz',
  boarding_39: 'Nutq tahlili',
  boarding_40:
    'Bizning ilg‘or nutq tahlili vositamiz gapirilgan tilni matnga aylantiradi, bu bizneslarga mijozlar tajribasini yaxshilash, ish faoliyatini monitoring qilish, muvofiqlikni ta’minlash va o‘sishni rag‘batlantirish uchun qiymatli ma’lumotlar beradi',
  boarding_41: 'Foydalanish va munosabatlar',
  boarding_42:
    'Foydalanish va munosabatlar — auditoriyalarning brendingiz bilan qanday aloqada bo‘lishini tushunish uchun amaliy foydalanish naqshlari va psixologik munosabatlarni tahlil qilishni o‘z ichiga oladi, bu esa moslashtirilgan marketing va mahsulot strategiyalarini imkoniyat yaratadi',
  boarding_43: 'Brendni tanish va foydalanish',
  boarding_44:
    'Brendni tanish va foydalanish — tanishlik, tanishuv darajasi, o‘zaro aloqalar va ushlab qolishning baholanishini o‘z ichiga oladi, bu esa brend ko‘rinishi va jalb qilishni oshirishga yordam beradi',
  boarding_45: 'Brend identiteti va pozitsiyalashuvi',
  boarding_46:
    'Brend identiteti va pozitsiyalashuvi — iste’molchilar qarashlarini, turli auditoriya fikrlarini va brendingizni qanday tasavvur qilishlarini ochib beruvchi keng qamrovli tahlil',
  boarding_47: 'Mijozni profiling va segmentatsiya qilish',
  boarding_48:
    'Mijozni profiling va segmentatsiya qilish — maqsadli bozorni umumiy xususiyatlar asosida ajratishga yordam beradi, bu esa muayyan auditoriya segmentlari bilan resonans qiladigan moslashtirilgan strategiyalar yaratishga imkon beradi',
  boarding_49: 'Iste’molchi yo‘li xaritasi',
  boarding_50:
    'Iste’molchi yo‘li xaritasi — iste’molchining mahsulot yoki brend bilan o‘zaro aloqalarida yuzaga kelgan bosqichlar, aloqalar, his-tuyg‘ular va motivatsiyalarning vizual tasviri',
  boarding_51:
    'Bizning biznes uchun ta’sirli natijalarni taqdim etishga qaratilgan innovatsion usullarni o‘rganing',
  boarding_52:
    'Bizning mahsulotlarimizni o‘rganing, so‘nggi ma’lumotlarga asoslangan, sizning sanoatingizga moslashtirilgan qarorlar qabul qilishni ta’minlovchi ma’lumotlarni taqdim etadi',
  boarding_53:
    'Bozor tendentsiyalari, iste’molchi xulq-atvori va raqobatbardosh bozorlarni chuqur tahlil qiling, bizning keng qamrovli bozor tadqiqotlari yechimlarimiz bilan',
  boarding_54: 'Ad Hok Tadqiqot',
  boarding_55:
    'Ad Hok Tadqiqot — ma’lum bir muammoni hal qilish uchun maxsus yondoshuv, mavjud metodologiyalarni yoki yangi yondoshuvni birlashtirib, moslashtirilgan yechim yaratish',
  boarding_56: 'Sotsiologik Tadqiqot',
  boarding_57:
    'Sotsiologik Tadqiqot — inson xulq-atvorini va ijtimoiy aloqalarni ochib berib, maqsadli auditoriya bilan mazmunli aloqalarni yaratishga yordam beradigan qiymatli ma’lumotlarni taqdim etadi',
  not_found_1: 'Oops! Bu sahifani topa olmadik.',
  not_found_2: 'Nega mahsulotlarimizni ko‘rmasligingiz mumkin?',
  not_found_3: 'Konsultatsiya rejalashtirish',
  customer_profiling_segmentation_1:
    'Mijozni profiling va segmentatsiya qilish',
  customer_profiling_segmentation_2:
    'Biz sizga maqsadli auditoriyangizni aniqlashga yordam beramiz, bu esa strategiyangizni samaraliroq qiladi',
  customer_profiling_segmentation_3:
    'Bozor segmentatsiyasining kuchidan foydalaning, shunda xabarlaringiz har doim maqsadli iste’molchi guruhlariga samarali yetib boradi',
  customer_profiling_segmentation_4: 'Iste’molchilarni chuqurroq tushuning',
  customer_profiling_segmentation_5:
    'Mahsulotingiz uchun eng yaxshi maqsadli auditoriyani toping',
  customer_profiling_segmentation_6: 'Jozibador yangi segmentlarni o‘rganing',
  customer_profiling_segmentation_7: 'Sotib olish uchun to‘siqlarni aniqlang',
  customer_profiling_segmentation_8:
    'Biz moslashtirilgan segmentatsiya yechimlarini taqdim etishga ixtisoslashganmiz',
  customer_profiling_segmentation_9: 'Noyob yondoshuv',
  customer_profiling_segmentation_10:
    'Sizning ehtiyojlaringizga maxsus tayyorlangan noyob segmentatsiya yechimini tajriba qiling',
  customer_profiling_segmentation_11: 'Maqsadli personajlar',
  customer_profiling_segmentation_12:
    'Chuqur bozor tahlili va keng qamrovli tadqiqotlar asosida tayyorlangan maqsadli personajlar',
  customer_profiling_segmentation_13: 'Yangi segmentlar',
  customer_profiling_segmentation_14:
    'Eng qimmatbaho auditoriya segmentlaringizni aniqlang va moslashtirilgan maqsadli targetingning afzalliklarini oching',
  customer_profiling_segmentation_15: 'Tavsiyalar',
  customer_profiling_segmentation_16:
    'Bizning tavsiyalarimizni amalga oshiring va muvaffaqiyatga erishing, mijozlar sadoqatini oshiring, sotuvlarni ko‘paytiring va biznesingiz imkoniyatlarini maksimal darajaga keltiring',
  customer_profiling_segmentation_17:
    '20+ yillik tajribaga ega mutaxassislar keng qamrovli tadqiqot taqdim etadi',
  customer_profiling_segmentation_18:
    'Sifatli tadqiqot — tushunchalar, fikrlar yoki tajribalarni tushunish uchun raqamli bo‘lmagan maʼlumotlarni yig‘ish va tahlil qilishni o‘z ichiga oladi.',
  customer_profiling_segmentation_19:
    'Miqdoriy tadqiqot usullari — so‘rovlar, anketalar va so‘rovlar orqali yig‘ilgan maʼlumotlarni ob‘ektiv ravishda o‘lchash va raqamli tahlil qilishni ta‘minlaydi.',
  customer_profiling_segmentation_20:
    'Ma’lumotlar yig‘ilgandan so‘ng, mutaxassislar ma’lumotlarni tahlil qiladi va sizga eng yaxshi tavsiyalarni taqdim etish uchun xulosalar chiqaradi.',
  customer_profiling_segmentation_21:
    'Biz faqat o‘lchovlar olib qo‘ymaymiz, balki sizga aniq qadamlar bo‘yicha harakatlar rejasini taqdim etamiz',
  customer_profiling_segmentation_22: 'Bizning metodologiyamiz',
  customer_profiling_segmentation_23: 'Jakkard Indeksi',
  customer_profiling_segmentation_24:
    'Agar respondentlarga to‘g‘ridan-to‘g‘ri so‘ralsa, kompaniya afzalliklari haqidagi javoblar ularning haqiqiy xulq-atvori bilan mos kelmasligi mumkin. To‘g‘ridan-to‘g‘ri so‘rash o‘rniga, biz kompaniya xususiyatlari va iste’molchi tanlovlari o‘rtasidagi munosabatni tushunish uchun statistik Jakkard tahlilini qo‘llaymiz.',
  customer_profiling_segmentation_25: 'Haydovchilar',
  customer_profiling_segmentation_26:
    'Biz haydovchilarni (muayyan natijaga ta’sir qiluvchi omillar) aniqlaymiz va ularni ularning ahamiyatiga asoslanib, asosiy, o‘rta va ahamiyatsiz haydovchilarga ajratamiz.',
  customer_profiling_segmentation_27: 'Segmentatsiya',
  customer_profiling_segmentation_28:
    'Siz mijozlarning tanlovlarini aniqlashda aniq xususiyatlarga ega segmentatsiyani olasiz.',
  customer_profiling_segmentation_29: 'Bizning segmentatsiya yondoshuvimiz',
  customer_profiling_segmentation_30: 'Demografik',
  customer_profiling_segmentation_31: 'yosh',
  customer_profiling_segmentation_32: 'jins',
  customer_profiling_segmentation_33: 'daromad',
  customer_profiling_segmentation_34:
    'Bu mahsulotlar, xizmatlar va marketingni aniq guruhlarga moslashtirishga yordam beradi, ahamiyatlilik va samaradorlikni yaxshilaydi',
  customer_profiling_segmentation_35: 'Psixografik',
  customer_profiling_segmentation_36: 'hayot tarzi',
  customer_profiling_segmentation_37: 'qadriyatlar',
  customer_profiling_segmentation_38: 'xulq-atvorlar',
  customer_profiling_segmentation_39:
    'Bu shaxsiylashtirilgan marketingni amalga oshirishga, mijozlar bilan chuqurroq aloqalar o‘rnatishga va kompaniya uchun sadoqat va sotuvlarni oshirishga imkon beradi',
  customer_profiling_segmentation_40: 'Xulq-atvoriy',
  customer_profiling_segmentation_41: 'harakatlar',
  customer_profiling_segmentation_42: 'motivatsiya',
  customer_profiling_segmentation_43: 'sotib olish tezligi',
  customer_profiling_segmentation_44:
    'Bu maqsadli marketingni, mijozlarning qoniqishini oshirishni, ushlab qolishning yaxshilanishini va kompaniya uchun rentabellikni oshirishni ta’minlaydi',
  customer_profiling_segmentation_45: 'Geografik',
  customer_profiling_segmentation_46: 'joylashuv',
  customer_profiling_segmentation_47:
    'Bu maqsadli marketingni, moslashtirilgan mahsulotlarni, samarali tarqatishni va kompaniya uchun bozorni kengaytirishni ta’minlaydi.',
  brands_identity_1: 'Brend identiteti va pozitsiyalashuvi',
  brands_identity_2:
    'Brendingizni chuqur tahlil qilish bilan o‘zgartiring, iste’molchilar qarashlari bo‘yicha ma’lumotlarni ochib berib, strategiyangizni muvaffaqiyatga erishtirish uchun takomillashtiring',
  brands_identity_3: 'Mutaxassis bilan gaplashing',
  brands_identity_4: '<b>Brend identiteti tahlilini kashf eting</b>',
  brands_identity_5:
    '<b>Istiqbollarni o‘rganing</b>, iste’molchilar qarashlari bo‘yicha to‘plangan ma’lumotlar',
  brands_identity_6: '<b>Turli</b> maqsadli auditoriya qarashlarini tushuning',
  brands_identity_7:
    '<b>Brendni qanday qabul qilishini</b> tushuning, iste’molchi xulq-atvori, odatlari va afzalliklari bo‘yicha ma’lumotlarni oling',
  brands_identity_8:
    '4Service mutaxassislari 20 yildan ortiq mijoz tajribasiga ega bo‘lib, biznesingizni o‘sishiga yordam beradigan noyob yechimlar yaratadi',
  brands_identity_9:
    "<b>Sizga barcha zarur ma'lumotlar bilan jihozlangan tadqiqot paketiga kirish imkoniyati bo'ladi.</b> Bu diagnostika vositasi sifatida ishlaydi, kuchli tomonlarni va e'tibor talab qiladigan sohalarni aniqlaydi",
  brands_identity_10:
    '<b>Bizning xizmatlarimiz bizneslarga zarur vositalarni taqdim etadi</b>, qarorlar qabul qilishni, strategiyalarni takomillashtirishni va o‘z sohalarida muvaffaqiyatga erishishni ta’minlaydi',
  brands_identity_11:
    'Raqobatbardosh bozorni yaxshilash uchun brendni yuksaltirish',
  brands_identity_12: 'Bozor mavjudligini oshirishga intilayotgan bizneslar',
  brands_identity_13: 'Strategiyalarini oshirishga intilayotgan bizneslar',
  brands_identity_14: 'Brend identiteti',
  brands_identity_15: 'Brend pozitsiyalashuvi',
  brands_identity_16:
    '<b>Brendingizni oching</b> iste’molchi segmentlari o‘rtasida qanday qabul qilinishini.',
  brands_identity_17:
    '<b>Brendingizga va raqobatchilarga tegishli xususiyatlarni aniqlang</b>',
  brands_identity_18:
    '<b>Kuchli tomonlarni</b> va zaif tomonlarni baholang, strategiyani yaxshilash uchun',
  brands_identity_19:
    '<b>Brendingiz tasvirining reklama qilingan qadriyatlar bilan muvofiqligini</b> baholang',
  brands_identity_20:
    '<b>Brendingizni qabul qilishda</b> ishonchlilikni baholang',
  brands_identity_21:
    '<b>Brendingizni arxetipik</b> va simvolik qabul qilishni o‘rganing',
  brands_identity_22:
    '<b>Brendingizning</b> noyob sotish nuqtalarini va raqobatchilarga nisbatan farqlarini aniqlang',
  brands_identity_23:
    '<b>Brend xarid qilish ehtimoli</b> o‘rtasidagi asosiy atributlarni aniqlang',
  brands_identity_24:
    '<b>Brendingizning hozirgi pozitsiyasini</b> iste’molchilar ongida baholang va kerakli bozor segmentlarini jalb qilish uchun xabarlarni aniqlang',
  brands_identity_25:
    'Metodologiya mijozning iltimosiga binoan individual ravishda tanlanadi',
  brands_identity_26: 'Brend haqida xabardorlik va foydalanish',
  brands_identity_27:
    'Brend darajasini baholash xabardorlik va iste’mol darajasini o’lchashni o’z ichiga oladi, bu baholashda asosiy komponent bo’lib xizmat qiladi.',
  brands_identity_28: 'Brend yetkazib berish va ishonch',
  brands_identity_29:
    "Bu o'lchov mijozlarning tavsiyasini (tavsiya qilish) va brendning va'dalarini bajarish, ijobiy mijoz tajribalarini yaratish va ishonchni mustahkamlash qobiliyatini baholaydi.",
  brands_identity_30: 'Brend tasvirining samaradorligi',
  brands_identity_31:
    "Brendning samaradorligini baholash brendning oltita muhim qadriyat (3 ratsional va 3 emotsional) bilan bog'lanishini, uning kategoriyadagi ajralib turishini va iste'molchilar orasida g'urur uyg'otish qobiliyatini o'rganishni o'z ichiga oladi.",
  brands_identity_32: 'Brend salomatlik balli',
  brands_identity_33:
    'Brend salomatlik balli sizga brendingizning raqobatbardosh maydondagi holati haqida to‘liq tushuncha beradi.',
  sociological_research_1: 'Sotsiologik tadqiqotlar',
  sociological_research_2:
    "Biz ijtimoiy hodisalar va jarayonlarni chuqur tahlil qilamiz, bu esa noyob tahliliy xulosalar va tavsiyalar yordamida to'g'ri qarorlar qabul qilishga yordam beradi",
  sociological_research_3: "Biz bilan bog'laning",
  sociological_research_4: 'Bizning mijozlarimiz',
  sociological_research_5: 'CAWI',
  sociological_research_6:
    "CATI - bu telefon orqali so'rovlar o'tkazish uchun kompyuterni ishlatadigan ma'lumot yig'ish usuli. CATI dasturi sizga ma'lumot yig'ish, javoblarni kiritish va so'rovlarni boshqarish jarayonlarini avtomatlashtirish imkonini beradi. CATI ishlatish so'rovni boshqarishni osonlashtiradi, ma'lumotlarni tezda tahlil qilish va ishlov berishga imkon beradi va so'rovlarning yuqori darajadagi standartlashishini ta'minlaydi.",
  sociological_research_7: 'CATI',
  sociological_research_8:
    "CAWI - bu respondentlar so'rovnomalarni to'ldirishlari yoki savollarga veb-interfeys orqali javob berishlari mumkin bo'lgan ma'lumot yig'ish usuli, va ma'lumotlar maxsus dastur yordamida avtomatik ravishda yig'iladi va qayta ishlanadi. CAWI usuli keng ko'lamli so'rovlar o'tkazishga, ma'lumotlarni tezda yig'ish va tahlil qilishga imkon beradi.",
  sociological_research_9: 'CAPI',
  sociological_research_10:
    "CAPI - bu yuzma-yuz so'rovlar uchun ma'lumot yig'ish usuli bo'lib, tadqiqotchi respondentlarning savollarga javoblarini kiritish uchun planshet yoki mobil telefonni ishlatadi, bu esa ma'lumot yig'ishni avtomatlashtirish, kiritish xatolarini kamaytirish va natijalarni tahlil qilishni soddalashtiradi.",
  sociological_research_11:
    'Bizning mutaxassislarimiz turli xil vositalardan foydalanadi',
  sociological_research_12: 'Namuna',
  sociological_research_13:
    "Biz har qanday murakkablikdagi namunalarni hisoblaymiz va qo'llaymiz",
  sociological_research_14:
    'Bizning ishimizda oddiy, tizimli, qatlamli va klasterli namunalar ishlatiladi',
  sociological_research_15:
    "Milliy vakillikni ta'minlash uchun yuzma-yuz so'rovlar uchun biz qatlamli ko'p bosqichli namunalarni tanlaymiz",
  sociological_research_16:
    "Aholi punktlarini mintaqalar bo'yicha tanlash aholi punktining hajmi va turiga muvofiq amalga oshiriladi, ularning soni har bir mintaqada ularning hajmiga nisbatan belgilanishi kerak",
  sociological_research_17: 'Biz amalga oshiramiz',
  sociological_research_18: 'Jamoat fikri tadqiqotlari',
  sociological_research_19: 'Ijtimoiy-iqtisodiy tadqiqotlar',
  sociological_research_20: 'Stol tadqiqotlari',
  sociological_research_21: 'Ijtimoiy xizmatlar sifatini baholash',
  sociological_research_22: 'Case study',
  sociological_research_23: 'Kadrlar tadqiqotlari',
  sociological_research_24:
    "Maxsus auditoriyalar (yengil guruhlar, shu jumladan, IDPlar, harbiylar, yoshlar va boshqalar) bo'yicha tadqiqotlar",
  sociological_research_25: 'Biz har bir mijoz bilan alohida ishlaymiz',
  sociological_research_26: 'Bizning mijozlarimiz:',
  sociological_research_27: 'Xayriya fondlari',
  sociological_research_28: 'Jamoat tashkilotlari va ittifoqlari',
  sociological_research_29: 'Davlat tuzilmalar',
  sociological_research_30: 'Bizning ishga yondashuvimiz',
  sociological_research_31:
    "Biz ishimizda ilmiy yondashuvni va hozirgi haqiqatni chuqur tushunishni, shuningdek, ma'lumot yig'ish uchun turli tadqiqot usullarini qo'llaymiz.",
  sociological_research_32:
    "Biz aniq so'rovni hal qilish uchun zarur tajribaga ega yuqori ixtisoslashgan mutaxassislarni jalb qilamiz va turli sohalarda mutaxassislar bazasiga egamiz.",
  sociological_research_33:
    "Bizning ichki tahlil departamentimiz yig'ilgan ma'lumotlarni vazifaning xususiyatlariga qarab noyob vositalar to'plami yordamida tahlil qiladi.",
  sociological_research_34:
    "Biz ma'lumotlar to'plami va tavsiyalar bilan batafsil hisobotlarni ishlab chiqamiz, sizning maqsadli auditoriyangizning ehtiyojlari va kutganlariga e'tibor beramiz.",
  sociological_research_35:
    "Biz tavsiyalarni amalga oshirish jarayonida maslahat va yordam beramiz, ijobiy o'zgarishlar qilishga yordam beramiz.",
  sociological_research_36: 'Bizning tadqiqotlarimiz televidenieda',
  sociological_research_37: 'Bizning hisobotlarimizni tekshiring',
  sociological_research_38:
    "Bizning ishga ilmiy yondashuvimiz mijozlarimizga butun Ukraina va Yevropada aniq va ishonchli sotsiologik ma'lumotlarni minimal xatolik bilan ta'minlaydi",
  sociological_research_39:
    "Biz barcha sotsiologik tadqiqotlarni o'tkazishning etik prinsiplari va qoidalariga rioya qilamiz.",
  sociological_research_40:
    "Biz Sotsiologlar Assotsiatsiyasining Kasbiy Etika Kodeksiga va Tadqiqotlar uchun Etika Prinsiplari haqida Helsinki Deklaratsiyasiga amal qilamiz. ESOMAR a'zolari, Ukraina Marketing Assotsiatsiyasi, Ukraina Butun Ukraina Aloqa Markazlari Assotsiatsiyasi.",
  sociological_research_41: 'Field',
  sociological_research_42: 'onlayn paneli',
  sociological_research_43: 'doimiy suhbatdoshlar',
  sociological_research_44: 'supervizorlar',
  sociological_research_45: 'Field sifatini nazorat qilish',
  sociological_research_46:
    "GPS koordinatalarining 100% o'zlashtirilishi (CAPI)",
  sociological_research_47:
    "So'rovnomalarni tekshirish (CAWI, CAPI, CATI, PAPI)",
  sociological_research_48: 'Intervyularning 100% yozib olinishi (CAPI, CATI)',
  sociological_research_49: 'Biz litsenziyalangan dasturlardan foydalanamiz:',
  sociological_research_50:
    "SPSS statistik ma'lumotlarni tahlil qilish, ma'lumotlar to'plamini qayta ishlash uchun",
  sociological_research_51:
    "DigSee Sure CAPI uchun: so'rovnoma mantiqi, geolokatsiya, intervyularni audio yozib olish, video materiallarini namoyish qilish",
  sociological_research_52:
    "SurveyMonkey CAWI uchun: so'rovnoma mantiqi, qulay format, vizualizatsiya",
  sociological_research_53:
    "VoIPTime (aloqa markazi): har qanday murakkablikdagi so'rovnomalarni dasturlash, audio yozuvlarni mijozga uzatish",
  sociological_research_54: 'Namuna',
  sociological_research_55:
    "Biz har qanday murakkablikdagi namunalarni hisoblaymiz va qo'llaymiz",
  sociological_research_56:
    'Bizning ishimizda biz oddiy, tizimli, qatlamli va klasterli namunalarni ishlatamiz',
  sociological_research_57:
    "Milliy vakillikka ega yuzma-yuz so'rovlar uchun biz qatlamli ko'p bosqichli namunalar olish protseduralaridan foydalanamiz",
  sociological_research_58:
    "Yerli hududlarni tanlash joyning o'lchami va turiga mos ravishda amalga oshiriladi, ularning soni har bir hududdagi hajmiga nisbatan aniqlanadi",
  sociological_research_59: 'Jamoa',
  sociological_research_60:
    "Bizning sotsiologlarimiz Ukraina Sotsiologlar Assotsiatsiyasi a'zolaridir",
  sociological_research_61:
    "Bizda har xil sanoatlarda miqdoriy va sifatli loyihalarda tajribaga ega 12 ta to'liq vaqtli tahlilchi mavjud",
  sociological_research_62:
    'Bizda 10 yildan ortiq tadqiqot tajribasiga ega kuchli tahlilchi jamoasi mavjud. Bizda olimlar, PhD, MBI mavjud',
  sociological_research_63: 'Iryna Maksymiuk',
  sociological_research_64:
    "Tadqiqot bo'limi rahbari. Marketing tadqiqotlarida 15 yildan ortiq tajriba, barcha bosqichlardagi tadqiqotlarni boshqarish, tahliliy hisobotlar va taqdimotlar tayyorlash",
  sociological_research_65: 'Andriy Biloskursky',
  sociological_research_66:
    "Dasturlash va ma'lumotlarni qayta ishlash bo'limi rahbari. Marketing, sotsiologiya va bozor tadqiqotlarida 20 yillik tajriba",
  sociological_research_67: 'Olena Somova',
  sociological_research_68:
    "Dasturlash mutaxassisi, DonDUUda sotsiologiya bo'yicha aspirant. Marketing tadqiqotlarida 5 yillik tajriba, Demokratik Ayollar Alyansining Boshqaruv Kengashi a'zosi, Xayriya fondi raisi",
  sociological_research_69: 'Anna Padalka',
  sociological_research_70:
    "Tadqiqot bo'limining Sotsiologik Tadqiqotlar bo'limi rahbari, xalqaro va milliy darajadagi sotsiologik va ijtimoiy loyihalarda 13 yillik tajriba. Sotsiologiya fanlari bo'yicha PhD, Ukraina Kontakt Markazlari Assotsiatsiyasining Tadqiqot qo'mitasi rahbari",
  ad_hoc_1: 'Ad Hok',
  ad_hoc_2:
    'Bizning noyob holatlar uchun muqobil tadqiqotlarimizni kashf eting',
  ad_hoc_3: "Biz bilan bog'laning",
  ad_hoc_4: 'Bizning yondashuvimiz',
  ad_hoc_5:
    "Bizning mutaxassislar jamoamiz to'plangan ma'lumotlarni diqqat bilan tahlil qilib, sizga o'sishingizga yordam beradigan murakkab yechimlarni taqdim etadi",
  ad_hoc_6:
    "Biz sizning maxsus talablaringizga moslashtirilgan noyob tadqiqotni ishlab chiqamiz, shu jumladan so'rovlar, intervyular, fokus guruhlar, ma'lumotlarni tahlil qilish va boshqalar kabi turli metodologiyalardan foydalanamiz",
  ad_hoc_7: 'Nima uchun Ad Hok tadqiqotini tanlash kerak?',
  ad_hoc_8: 'Moslashuvchanlik',
  ad_hoc_9:
    "Hech ikki tadqiqot loyihasi bir xil emas. Ad Hok tadqiqoti bilan bizning metodologiyamizni kerakli tarzda moslashtirish va o'zgartirish imkoniyatimiz bor, bu sizning tushunchalaringizni dolzarb va amalga oshiriladigan qiladi",
  ad_hoc_10: 'Aniqlik',
  ad_hoc_11:
    'Bizning metodologiyalarimizni sizning maxsus talablaringizga moslashtirish orqali biz aniq va maqsadli natijalarni taqdim etamiz, bu sizga qiymatli tushunchalar beradi',
  ad_hoc_12: 'Innovatsiya',
  ad_hoc_13:
    "Biz tadqiqot jarayonimizda innovatsiya va yaratuvchanlikni qabul qilamiz, yangi texnikalar va yondashuvlarni o'rganib, yashirin imkoniyatlar va muammolarni aniqlaymiz",
  ad_hoc_14: 'Vaqtida bajarilish',
  ad_hoc_15:
    "Ad Hok tadqiqoti tezkor amalga oshirilishini ta'minlaydi, bu sizga muhim tushunchalarga vaqtida kirish imkonini beradi va sizni sohangizda oldinda qolishga yordam beradi",
  ad_hoc_16: 'Bizning mutaxassisligimiz',
  ad_hoc_17:
    '4Service mutaxassislari 20 yildan ortiq bozor tadqiqotlari va mijoz tajribasi bilan sizning biznesingizni rivojlantirishga yordam beradigan noyob yechimlarni yaratadilar',
  ad_hoc_18:
    'Biz sizning maxsus talablaringizga asoslangan turli vositalardan foydalanamiz',
  ad_hoc_19: 'Hech bir metodologiya siz uchun ishlamayaptimi?',
  ad_hoc_20: "Ad Hok Tadqiqotini so'rang",
  ad_hoc_21: 'Maxsus masala uchun moslashtirilgan',
  ad_hoc_22:
    "Biz turli metodologiyalarning aralashmasini hamda butunlay yangi yondashuvni qo'llaymiz",
  ad_hoc_23: 'Individual yondashuv',
  ad_hoc_24:
    'Biz muammoingizga bevosita moslashtirilgan yechimni ishlab chiqamiz',
  ad_hoc_25: "Kutilganidan yuqori bo'lish",
  ad_hoc_26:
    'Biz biznesingizning maqsadlariga erishishiga yordam beradigan amalga oshiriladigan rejani taqdim etamiz',
  ad_hoc_27: 'Sizda no-standart muammo bor',
  ad_hoc_28: 'Hech qanday metodologiya sizning muammoingizga ishlamayapti',
  ad_hoc_29:
    "Siz bir vaqtning o'zida turli metodologiyalar yoki ularning qismlarini so'ramoqchisiz",
  ad_hoc_30: 'Kim Ad Hok tadqiqotlaridan foyda oladi?',
  service_design_1: "Ba'zi mijozlarimiz",
  service_design_2: 'Xizmat Dizayni',
  service_design_3:
    "Biznesingizni mahsulot va sotuvlarga yo'naltirilgan yondashuvdan xizmat va insonlarga yo'naltirilgan yondashuvga o'zgartiring",
  service_design_4: 'Mutaxassis bilan gaplashing',
  service_design_5: "Bepul o'rganing!",
  service_design_6:
    "Bizning Xizmat Dizayni kutubxonamizda amaliy va foydali ma'lumotlarni to'pladik",
  service_design_7:
    "Kutubxona har bir qadamning batafsil tavsiflarini, tegishli vositalar va texnikalarni, muhim tafsilotlar bo'yicha maslahatlar va video demonstratorlarini o'z ichiga oladi",
  service_design_8: "Kutubxonaga o'ting",
  service_design_9: 'Xizmat Dizayniga xush kelibsiz!',
  service_design_10:
    'Xizmat Dizayni xizmatlarni yaratish va takomillashtirishga qaratilgan, barcha xizmat ekosistemasini - odamlar, jarayonlar va aloqa nuqtalarini yaxshilashga yordam beradi, bu esa foydalanuvchilarni qoniqtirish va operatsion samaradorlikni oshiradi',
  service_design_11:
    "Xizmat dizayni - bu faqat bir kafe boshqasidan afzal ko'rilishini tushunish emas. Bu, shuningdek, sizni qayta-qayta shu joyga olib keladigan va barcha do'stlaringizga bu haqda aytib beradigan sababdir.",
  service_design_12: 'Biznes xizmat dizaynidan qanday foyda olishi mumkin',
  service_design_13: 'Foydalanuvchi qoniqishi',
  service_design_14:
    'Foydalanuvchilarni xursand qiladigan, shaxsiylashtirilgan tajribalarni yarating',
  service_design_15: 'Operatsion samaradorlik',
  service_design_16:
    "Jarayonlarni soddalashtiring, xarajatlarni kamaytiring va xizmat ko'rsatish etkazib berishni yaxshilang",
  service_design_17: 'Brendning doimiyligi',
  service_design_18:
    "Brendning qadriyatlariga mos keladigan barcha aloqa nuqtalarini ta'minlang.",
  service_design_19: "O'zgarishlarga moslashuvchanlik",
  service_design_20:
    "O'zgaruvchan ehtiyojlarga mos keladigan xizmatlarni ishlab chiqing va rivojlantiring.",
  service_design_21: 'Sadoqatni oshirish',
  service_design_22:
    "Mijozlar bilan mustahkam aloqalar o'rnating va ajoyib xizmat tajribalari orqali sadoqatni oshiring.",
  service_design_23: 'Raqobatbardosh afzallik',
  service_design_24:
    "Bozorda o'ziga xos, esda qolarli xizmat tajribalari bilan ajralib turing.",
  service_design_25: 'Nima uchun',
  service_design_26: 'Nima uchun Xizmat Dizaynini tanlash kerak?',
  service_design_27:
    "Bu madaniyat, metodologiya va fikrlash usulidir, bu butun dunyodagi kompaniyalarga mahsulot va sotuvlarga yo'naltirilgan yondashuvdan inson ehtiyojlari va muammolariga qaratilgan yondashuvga o'tishga yordam beradi.",
  service_design_28: 'Bizning xizmatlarimiz',
  service_design_29: 'Mijoz Xizmatlari Dizayni',
  service_design_30:
    "Biz mijozlarga yo'naltirilgan loyihalarni ishlab chiqamiz va amalga oshiramiz, yangi mahsulotlar yaratish yoki mavjudlarini yaxshilash uchun. Biz shuningdek, bu o'zgarishlar bilan kelajakda mustaqil ishlash uchun treninglar taqdim etamiz.",
  service_design_31: 'Xodimlar xizmatlari dizayni',
  service_design_32:
    "Kompaniya ichida xodimlarga yo'naltirilgan jarayonlar, tuzilmalar va o'zgarishlarni ishlab chiqish va amalga oshirish uchun amaliy kurs.",
  service_design_33: "Kelajak dizaynini o'ylash",
  service_design_34:
    "Kelajak kontseptsiyalari va kompaniya strategiyasini ishlab chiqish, turli stsenariylar va mumkin bo'lgan innovatsiyalarni hisobga olgan holda.",
  service_design_35: 'Biznesda yaratuvchanlik',
  service_design_36:
    "Maxsus muammolarni hal qilish va bozorga noyob yechimlar ishlab chiqish uchun ishtirokchilarga yaratuvchanlikni qo'llashga yordam beradigan amaliy trening.",
  service_design_37: 'Bizning seminarlarimiz',
  service_design_38:
    "Biz ishtirokchilarga butun dunyoda tan olingan sertifikatga ega bo'lgan amaliy seminarlar taqdim etamiz",
  service_design_39:
    'Mahsulotlar, xizmatlar va imkoniyatlarni yarating, kutganingizdan ortiq',
  service_design_40:
    "Ishlashni ta'minlaydigan biznes jarayonlarini amalga oshiring",
  service_design_41:
    "Kompaniya xodimlari va mijozlar o'rtasidagi aloqani uyg'unlashtiring",
  service_design_42: 'Kalendar',
  service_design_43: 'Yaqin orada',
  service_design_44:
    "Bizning oldingi tadbirlarimiz haqida ko'proq ma'lumot oling",
  service_design_45: 'Service Design asoslari',
  service_design_46:
    'Mahsulotlar, xizmatlar va imkoniyatlarni yarating, kutganingizdan ortiq',
  service_design_47: 'EX Service Design',
  service_design_48:
    'Mahsulotlar, xizmatlar va imkoniyatlarni yarating, kutganingizdan ortiq',
  service_design_49: "Ko'proq",
  service_design_50: 'Bizning yondashuvimiz',
  service_design_51:
    '4Service — bu sizning inson markazli xizmatlaringizning suyagi!',
  service_design_52:
    'Biz kompaniyalarga kerakli fikrlash tarzini rivojlantirish va inson markazli ichki muhitni yaratishga yordam beramiz.',
  service_design_53: 'Vositalar',
  service_design_54:
    'Biz sizning maxsus talablaringizga asoslangan turli usullar va vositalardan foydalanamiz',
  service_design_55: 'Foydalanuvchi Tadqiqotlari',
  service_design_56:
    "Intervyular, so'rovlar, F2F, etnografik tadqiqotlar foydalanuvchi ehtiyojlari va xulq-atvorini tushunish uchun",
  service_design_57: 'Persona',
  service_design_58:
    "Turli foydalanuvchi turlarini ifodalovchi xayoliy shaxslar, dizayn qarorlarini yo'naltirish uchun",
  service_design_59: "Mijoz yo'l xaritalari",
  service_design_60:
    "Xizmat orqali foydalanuvchining tajribasining vizual tasvirlari, asosiy aloqa nuqtalari va og'riqli joylarni aniqlash",
  service_design_61: 'Brainstorm sessiyalari',
  service_design_62:
    "G'oyalar va yechimlar yaratish uchun manfaatdor tomonlar bilan hamkorlikda o'tkaziladigan sessiyalar.",
  service_design_63: 'Prototiplash',
  service_design_64:
    "Xizmatning dastlabki versiyalarini yaratish, kontseptsiyalarni sinab ko'rish va takomillashtirish",
  service_design_65: 'Xizmat blueprintlari',
  service_design_66:
    "Xizmat komponentlari va jarayonlar o'rtasidagi munosabatlarni ko'rsatadigan batafsil diagrammalar",
  service_design_67: 'Hamkor',
  service_design_68: '4Service & DTA',
  service_design_69:
    '4Service — bu Design Thinking Academy ning rasmiy eksklyuziv vakili',
  service_design_70: 'Hamkorligimiz doirasida biz:',
  service_design_71:
    "<b>amaliy treninglar taqdim etamiz</b>, xizmat dizayni usulidan foydalanib, yetakchi mutaxassislar tomonidan seminarlar o'tkazamiz;",
  service_design_72:
    '<b>sertifikatlangan</b> dizayn fikrlash mutaxassislarini tayyorlaymiz;',
  service_design_73:
    "<b>servis dizayni</b> loyihalarini xalqaro fasilitatorlar bilan ko'plab kompaniyalar uchun amalga oshiramiz.",
  service_design_74:
    'Biz Service Design Excellence markazlarini quyidagi joylarda tashkil etdik:',
  service_design_75: 'Ukraina',
  service_design_76: "Qozog'iston",
  service_design_77: 'Turkiya',
  service_design_78: 'Gruziya',
  service_design_79: 'Ozarbayjon',
  service_design_80: "Bizning ishlarimiz haqida ko'proq bilib oling",
  four_opinion_1: "4Service tomonidan so'rovnomalar",
  four_opinion_2:
    "Online so'rovnomalarni to'ldiring va uydan chiqmasdan pul ishlang",
  four_opinion_3: 'Boshlash',
  four_opinion_4: 'Bizning afzalliklarimiz',
  four_opinion_5: 'Bizga ishonishadi',
  four_opinion_6:
    "Bizning hamjamiyatimiz Ukrainada 90 mingdan ortiq va Qozog'istonda 100 ming iste'molchini o'z ichiga oladi",
  four_opinion_7: 'Vaqtingizni munosib baholaymiz',
  four_opinion_8:
    "Biz vaqtingizni qadrlaymiz, shuning uchun so'rovnomalarda ishtirok etganingiz uchun sovg'a yoki pul mukofoti olish imkoniyatiga egasiz",
  four_opinion_9: 'Sizning fikringiz muhim ahamiyatga ega',
  four_opinion_10:
    'Sizning har bir fikringiz brendlarga mijozlarining ehtiyojlarini yaxshiroq tushunishga yordam beradi',
  four_opinion_11:
    "4Service tomonidan 4Opinion platformasida so'rovnomalarni to'ldiring va onlayn daromad oling yoki sovrin yutib oling",
  four_opinion_12: "4Opinion ga qo'shilish",
  four_opinion_13: 'Bizning kanalda yanada qiziqarli hikoyalar',
  four_opinion_14: "Ukraina uchun batafsil ma'lumot",
  four_opinion_15: "Qozog'iston uchun batafsil ma'lumot",
  four_opinion_16: 'Nima uchun bizni tanlashadi?',
  four_opinion_17:
    "4Service — mijoz tajribasini boshqarish bo'yicha xalqaro tadqiqot xoldingi",
  four_opinion_18:
    "Biz mijoz tajribasini boshqarish bo'yicha Yevropaning top-10 agentliklari qatoriga kiramiz",
  four_opinion_19:
    "Bizning missiyamiz – butun dunyo bo'ylab mijozlarga xizmat ko'rsatishni yaxshilash!",
  four_opinion_20:
    "Online so'rovnomalar orqali qanday qilib pul ishlash mumkin?",
  four_opinion_21: "Saytda ro'yxatdan o'ting",
  four_opinion_22: "Dolzarb so'rovnomalar haqida bildirishnoma oling",
  four_opinion_23: "So'rovnomalarni oxirigacha to'ldiring va daromad oling",
  four_opinion_24: 'Bizning respondentlarimizdan haqiqiy fikrlar',
  four_opinion_25: 'Vitaliy Koxan',
  four_opinion_26:
    "“So'rovnomani to'ldirdim va hatto unutib qo'ydim. Bir muddat o'tgach, Natalya xonim qo'ng'iroq qilib, men sovrin yutganimni aytdi. Va mana, mening sovrinim — Samsung Galaxy M52 5G. Katta rahmat😁”",
  four_opinion_27: 'Viktoriya Popovska',
  four_opinion_28:
    '“Kolonkani oldim, halol, adolatli va qiziqarli lotereya uchun katta rahmat.”',
  four_opinion_29: 'Yuliya Aleksandrovna',
  four_opinion_30:
    "“Bu oxirgi vaqtlarda men bilan sodir bo'lgan eng ajoyib hodisa💕 Nafaqat g'olib bo'ldim, balki juda zo'r narsa yutdim. Har xil narsalarni kutgandim, lekin shunchalik ajoyib bo'lishini hatto tasavvur qilmagandim. Yana bir bor katta rahmat”",
  four_opinion_31:
    'Faqat biz bilan siz haqiqiy pul ishlash yoki sovrin yutish imkoniyatiga egasiz',
  four_opinion_32: "Bizning kanalda dolzarb so'rovnomalarni kuzatib boring",
  four_opinion_33: "Kanalga o'tish",
};
