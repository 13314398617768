import React, { useMemo } from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BusinessEmployeeExperienceStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';
import arrow from '../../../assets/images/employeeExperience/arrow.svg';

export const BusinessEmployeeExperience = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <BusinessEmployeeExperienceStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_43')}</h2>
        <h3>{getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_44')}</h3>

        <div className="circles">
          <div className="circles-item">
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_45')}
            </p>
          </div>
          <img src={arrow} alt="arrow" className="circles-arrow" />
          <div className="circles-item">
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_46')}
            </p>
          </div>
          <img src={arrow} alt="arrow" className="circles-arrow" />
          <div className="circles-item">
            <p>
              {getTranslationsByLangOrEng(interfaceLanguage, 'employee_experience_47')}
            </p>
          </div>
        </div>

        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#fff"
          borderColor="#fff"
          borderOnHover="#fff"
          background="transparent"
          hoverBackground="transparent"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'employee_experience_3',
          )}
          className="btn"
        />
      </div>
    </BusinessEmployeeExperienceStyles>
  );
};
