import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { DiaryStudyGetStartedStyles } from './styles';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const DiaryStudyGetStarted = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <DiaryStudyGetStartedStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_6')}</h2>
        <p>{getTranslationsByLangOrEng(interfaceLanguage, 'diary_study_7')}</p>
        <ButtonWithArrowOnHover
          onClick={() => {
            const elem = document.getElementById('form');
            elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }}
          arrowColor="#fff"
          textColor="#000"
          borderColor="#000"
          borderOnHover="#000"
          background="transparent"
          className="btn"
          hoverBackground="#000"
          hoverTextColor="#fff"
          text={getTranslationsByLangOrEng(
            interfaceLanguage,
            'diary_study_5',
          )}
        />
      </div>
    </DiaryStudyGetStartedStyles>
  );
};
