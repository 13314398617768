import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiRatingStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import rating1 from '../../../assets/images/cati/rating-1.png';
import rating2 from '../../../assets/images/cati/rating-2.png';
import rating3 from '../../../assets/images/cati/rating-3.png';

export const CatiRating = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiRatingStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cati_76')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_77')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <img src={rating1} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_78')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_79')}</p>
            </div>
          </div>
          <div className="cards-item">
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_80')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_81')}</p>
            </div>
            <img src={rating2} alt="" />
          </div>
          <div className="cards-item">
            <img src={rating3} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_82')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_83')}</p>
            </div>
          </div>
        </div>
      </div>
    </CatiRatingStyles>
  );
};
