import styled from 'styled-components';
import banner from '../../../assets/images/employeeExperience/banner.png';

export const WhyEmployeeExperienceStyles = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .container {
    padding: 50px 10px !important;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 50px;

    @media (max-width: 768px) {
      padding: 30px 10px !important;
      gap: 30px;
    }

    h2 {
      font-weight: 400;
      font-size: 60px;
      line-height: 115%;
      color: #ffffff;
      max-width: 800px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 32px;
      }
    }

    .btn {
      position: absolute;
      right: 0;
      bottom: 50px;

      @media (max-width: 768px) {
        position: static;
      }
    }
  }

  .cards {
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 15px 15px;
    grid-template-areas:
      'one one . . two two'
      '. three three four four .'
      '. . five five . .';

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: stretch;
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      gap: 18px;
      max-width: 370px;
      width: 100%;
      height: auto;
      border: 2px solid rgba(0, 0, 255, 0.4);
      filter: drop-shadow(0px 0px 40px #0000ff);
      border-radius: 5px;

      @media (max-width: 768px) {
        padding: 20px;
        height: auto;
      }

      &:nth-child(1) {
        grid-area: one;
      }
      &:nth-child(2) {
        grid-area: two;
      }
      &:nth-child(3) {
        grid-area: three;
      }
      &:nth-child(4) {
        grid-area: four;
      }
      &:nth-child(5) {
        grid-area: five;
      }

      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 125%;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
