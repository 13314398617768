import styled from 'styled-components';
import bg from '../../../assets/images/sociologicalResearch/intro-bg.png';

export const DiaryStudyGetStartedStyles = styled.div`
  width: 100%;
  position: relative;
  padding: 70px 0;
  background: linear-gradient(273.29deg, #26f0a6 0%, #2994bb 100%);

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .container {
    max-width: 1190px;
    width: 100%;
    padding: 0 10px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 115%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      font-size: 28px;
      text-align: center;
      width: 100%;
    }
  }
  p {
    font-weight: 500;
    font-size: 22px;
    line-height: 125%;
    text-align: center;
    color: #0f0e0c;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 16px;
    }
  }
`;
