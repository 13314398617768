import React, { CSSProperties, useRef, useState } from 'react';
import { SelectFieldStyles } from './SelectFieldStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorMessage from '../../formErrorMessage/FormErrorMessage';
import arrow from './arrow.svg';
import { useClickOutside } from '../../../hooks/useClickOutside';

interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  options: string[];
  extraBlockStyles?: CSSProperties;
  label?: string;
  required?: boolean;
  colorTheme?: 'lightTheme' | 'darkTheme' | 'greenTheme';
  className?: string;
}

export default function SelectField({
  name,
  placeholder,
  label,
  value,
  onChange,
  extraBlockStyles,
  required,
  colorTheme,
  options,
  className = '',
}: InputFieldProps) {
  const [open, setOpen] = useState<boolean>(false);

  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  useClickOutside(
    optionsListRef,
    () => {
      setOpen(false);
    },
    selectedValueFieldRef.current,
  );

  return (
    <SelectFieldStyles
      style={extraBlockStyles}
      className={`${className} ${colorTheme}`}
    >
      <label htmlFor={name}>
        {label && (
          <span>
            {label}
            {required && <span className="required"> *</span>}
          </span>
        )}
        <div className="select-wrapper">
          <div
            className="select-value"
            ref={selectedValueFieldRef}
            onClick={() => setOpen(!open)}
          >
            <p>{value}</p>
            <img src={arrow} alt="arrow" className={open ? 'active' : ''} />
          </div>
          {open && (
            <div className="select-options" ref={optionsListRef}>
              {options.map((el) => (
                <div
                  className="select-option"
                  onClick={() => {
                    onChange(el);
                    setOpen(false);
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
          )}
        </div>
      </label>
    </SelectFieldStyles>
  );
}
