import styled from 'styled-components';

export const CatiTechnologyStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 991px) {
    gap: 30px;
    padding: 40x 0;
  }

  .cards {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 60px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      gap: 16px;
      background: rgba(149, 16, 178, 0.05);
      border-radius: 5px;
      flex: 1 1;
      position: relative;

      @media (max-width: 768px) {
        padding: 15px;
        gap: 12px;
      }

      &::before {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 6px;
        border: 1px solid #9510b2;
        height: calc(100% + 30px);
        width: calc(100% + 30px);
      }

      h4 {
        font-weight: 600;
        font-size: 22px;
        line-height: 125%;
        color: #0f0e0c;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 18px;
        }

        span {
          color: #9510b2;
        }
      }

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        background: #9510b2;
        border-radius: 201px;
        margin: 0;

        font-weight: 700;
        font-size: 14px;
        line-height: 125%;
        color: #ffffff;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style-type: none;

        li {
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 125%;
          color: #0f0e0c;
          padding-left: 25px;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #9510b2;
            top: 5px;
            left: 5px;
          }
        }
      }
    }
  }
`;
