import UAIcon from '../assets/icons/UA.svg';
import ENIcon from '../assets/icons/EN.svg';
import FRIcon from '../assets/icons/FR.svg';
import ROIcon from '../assets/icons/RO.svg';
import DEIcon from '../assets/icons/DE.svg';
import KZIcon from '../assets/icons/KZ.svg';
import ITIcon from '../assets/icons/IT.svg';
import RUIcon from '../assets/icons/RU.svg';
import GEIcon from '../assets/icons/GR.svg';
import AZIcon from '../assets/icons/AZ.svg';
import ESIcon from '../assets/icons/ES.svg';
import PTIcon from '../assets/icons/PT.svg';
import NLIcon from '../assets/icons/NL.svg';
import PLIcon from '../assets/icons/PL.svg';
import UZIcon from '../assets/icons/UZ.svg';

import ILanguage from '../enteties/ILanguage';

interface ILanguages {
  en: ILanguage;
  fr: ILanguage;
  ro: ILanguage;
  de: ILanguage;
  uk: ILanguage;
  'kk-kz': ILanguage;
  it: ILanguage;
  'ru-kz': ILanguage;
  ge: ILanguage;
  az: ILanguage;
  es: ILanguage;
  pt: ILanguage;
  nl: ILanguage;
  pl: ILanguage;
  uz: ILanguage;
}

interface ILanguagesID {
  en: number;
  fr: number;
  ro: number;
  de: number;
  uk: number;
  'kk-kz': number;
  it: number;
  'ru-kz': number;
  ge: number;
  az: number;
  es: number;
  pt: number;
  nl: number;
  pl: number;
  uz: number;
}

export const LanguageIDs: ILanguagesID = {
  en: 1,
  fr: 3,
  ro: 5,
  de: 4,
  uk: 2,
  'kk-kz': 8,
  it: 7,
  'ru-kz': 6,
  ge: 9,
  az: 10,
  es: 11,
  pt: 12,
  nl: 12,
  pl: 13,
  uz: 14,
};

export const LANGUAGES: ILanguages = {
  en: {
    name: 'EN',
    code: 'en',
    icon: ENIcon,
  },
  fr: {
    name: 'FR',
    code: 'fr',
    icon: FRIcon,
  },
  ro: {
    name: 'RO',
    code: 'ro',
    icon: ROIcon,
  },
  de: {
    name: 'DE',
    code: 'de',
    icon: DEIcon,
  },
  uk: {
    name: 'UA',
    code: 'uk',
    icon: UAIcon,
  },
  'kk-kz': {
    name: 'KZ',
    code: 'kk-kz',
    icon: KZIcon,
  },
  it: {
    name: 'IT',
    code: 'it',
    icon: ITIcon,
  },
  'ru-kz': {
    name: 'KZ(RU)',
    code: 'ru-kz',
    icon: KZIcon,
  },
  ge: {
    name: 'GE',
    code: 'ge',
    icon: GEIcon,
  },
  az: {
    name: 'AZ',
    code: 'az',
    icon: AZIcon,
  },
  es: {
    name: 'ES',
    code: 'es',
    icon: ESIcon,
  },
  pt: {
    name: 'PT',
    code: 'pt',
    icon: PTIcon,
  },
  nl: {
    name: 'NL',
    code: 'nl',
    icon: NLIcon,
  },
  pl: {
    name: 'PL',
    code: 'pl',
    icon: PLIcon,
  },
  uz: {
    name: 'UZ',
    code: 'uz',
    icon: UZIcon,
  },
};

export const availableLanguages = [
  'en',
  'fr',
  'de',
  'uk',
  'ro',
  'kk-kz',
  'it',
  'ru-kz',
  'ge',
  'az',
  'es',
  'pt',
  'nl',
  'pl',
  'uz',
];

export const pageLanguages = (page: string): string[] => {
  switch (page) {
    default:
      return ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'];
  }
};
