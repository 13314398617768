export const ro: { [key: string]: string } = {
  subscribe_with_click: 'Abonați-vă cu un clic',
  subscribe: 'Subscrie cu un click',
  minimum_number_characters: 'număr minim de 5 caractere',
  request_details: 'Cere detalii',
  menu_21: 'Analiza prețurilor auto',
  form_title: 'Vă vom contacta în scurt timp',
  form_required: 'Câmpurile marcate cu * sunt obligatorii',
  form_name_placeholder: 'Nume',
  form_email_placeholder: 'E-mail',
  form_phone_placeholder: 'Număr de telefon',
  form_company_placeholder: 'Companie',
  form_submit: 'Trimite',
  form_1:
    'Îmi dau consimțământul pentru prelucrarea datelor cu caracter personal conform cu',
  form_2: 'Politica de confidențialitate',
  validation_privacy_policy:
    'Vă rugăm să acceptați Politica de confidențialitate',
  validation_phone: 'Numărul de telefon nu este valid',
  validation_email: 'format de email invalid',
  validation_required: 'Câmpurile obligatorii nu sunt completate',
  submit_error: 'A apărut o eroare la salvarea formularului',
  footer_1: 'Partenerii noștri:',
  footer_2: 'Metode',
  footer_3: 'Produse',
  footer_4: 'Contacte:',
  footer_5: 'Imprima',
  footer_6: 'Politica de confidențialitate',
  about_us_1: 'Responsabilitatea socială corporativă 4Service',
  about_us_2:
    'Din inima și sufletul fiecărui membru al echipei noastre. Ne străduim să păstrăm o scânteie de bunătate în fiecare dintre angajații noștri și să facem lumea din jurul nostru puțin mai bună.',
  about_us_3: 'Responsabilitatea socială corporativă 4Service',
  about_us_4:
    'Din inima și sufletul fiecărui membru al echipei noastre. Ne străduim să păstrăm o scânteie de bunătate în fiecare dintre angajații noștri și să facem lumea din jurul nostru puțin mai bună.',
  about_us_5: 'Jocul de afaceri Service Mania',
  about_us_6: 'Inițiativele noastre de implicare pentru copii și elevi',
  about_us_7: 'Caritate / Voluntariat',
  about_us_8:
    'Orfelinate, cămine pentru vârstnici și voluntariat în cadrul armatei ucrainene. Cea mai emoționantă parte a activităților noastre',
  about_us_9: 'Bunăstarea animalelor',
  about_us_10:
    'Oricine poate intra în biroul nostru cu un animal de companie. Dăm o mână de ajutor adăposturilor, strângem fonduri pentru hrană și, uneori, încercăm să găsim o casă pentru animalele fără adăpost',
  about_us_11: '4Service Group',
  about_us_12:
    'Este o companie internațională de cercetare care gestionează experiența clienților.',
  about_us_13:
    'Implementăm proiecte complexe pentru creșterea vânzărilor și dezvoltarea afacerilor companiilor orientate către client.',
  about_us_14:
    'Este o companie internațională de cercetare cu sediul central în Viena, Austria. Compania a fost înființată în 2001 cu scopul de a îmbunătăți calitatea vieții clienților săi și a persoanelor care interacționează cu afacerea, utilizează serviciile sale și consumă produsele sale. În fond, nu fără motiv a fost ales numele «Forservice» pentru o companie care reprezintă serviciul clienților și calitatea bună.',
  about_us_15:
    'Aceasta a fost începutul istoriei 4Service în Kiev, Ucraina, ca principal furnizor de servicii de mystery shopping. A continuat să opereze cu succes ca o companie de cercetare în 52 de țări, cu noi oportunități în abordarea holistică de analizare și îmbunătățire a întregului lanț de experiență a clienților.',
  about_us_16: 'născut în numele serviciului',
  about_us_17: 'Prima filială situată în afara Ucrainei',
  about_us_18: 'Relocarea sediului central la Viena',
  about_us_19: 'Membra de elită a MSPA, agenție de top 10 în Europa',
  about_us_20: 'stabilirea de birouri in 9 tari',
  about_us_21: 'primele proiecte digitale, Voicer',
  about_us_22: 'parteneri ai DesignThinkers Academy',
  about_us_23: 'primele proiecte globale CХ',
  about_us_24: 'Misiune',
  about_us_25:
    'Misiunea noastră este de a îmbunătăți serviciile pentru clienți în întreaga lume! Sursa noastră de inspirație o reprezintă schimbările calitative pe care le observăm la oamenii și companiile cu care lucrăm.',
  about_us_26: '20+ ani de experiență în domeniul experienței clienților',
  about_us_27: '95 de țări',
  about_us_28:
    'Colectăm și împărtășim cea mai bună experiență din industrie din întreaga lume',
  about_us_29: 'Sediul central în Viena',
  about_us_30:
    'ţări. Colectăm și împărtășim cea mai bună experiență din industrie din întreaga lume',
  about_us_31: 'Evaluarile calității serviciilor',
  about_us_32: 'suntem în top-10 agenții europene CX',
  about_us_33: 'reprezentanțe / Sediul central în Austria',
  about_us_34: 'proiecte active',
  about_us_35: 'Viitorul tău loc de muncă',
  about_us_36: 'Birourile noastre',
  about_us_37:
    '4Service ™ depune eforturi pentru a crea un spațiu de lucru confortabil pentru angajații săi. Angajații noștri lucrează la birou, de acasă sau în sistem hibrid, în funcție de cea mai mare comoditate și productivitate a fiecărui angajat. Dispunem de camere de relaxare, oferim suport psihologic gratuit și un mediu prietenos. Suntem prietenoși cu oamenii și animalele de companie',
  about_us_38: 'Echipa 4Service',
  about_us_39:
    'Echipa noastră multiculturală este angajată să asculte, să acorde atenție feedback-ului oferit de clienții noștri și să crească împreună cu ei. Harta călătoriei clienților noștri alături de noi este la fel de  importantă pentru afacerea noastră precum lansarea de noi produse sau atingerea obiectivelor financiare. Activitățile noastre sunt orientate către obținerea unui impact pozitiv asupra comunităților din jurul  nostru și livrarea de soluții inovatoare și centrate pe oameni pentru clienții noștri.',
  about_us_40: 'Toate',
  about_us_41: 'management',
  about_us_42: 'Echipa globală',
  about_us_43: 'Proprietari de produse',
  about_us_44: 'Partenerii noștri',
  about_us_45:
    'Implementam proiecte complexe pentru cresterea vanzarilor si dezvoltarea afacerilor companiilor orientate catre client',
  about_us_46:
    'Istoricul nostru arată că suntem unul dintre principalii instituți de formare profesională cu o abordare centrată pe design, prezenți în 25 de țări, unde formăm, dezvoltăm și facilităm echipe multidisciplinare și comunități creativie pentru a genera schimbări pozitive. Gândirea în design vă permite să dezvoltați și să furnizați idei, schimbări și soluții inovatoare pentru probleme complicate.',
  about_us_47:
    'Misiunea lor este de a oferi spațiu și timp pentru ca echipele și indivizii să fie inspirați și provocați, să învețe prin faptă și să își dezvolte abilitățile de rezolvare creativă a problemelor, ajutând organizațiile să construiască mediul și mentalitatea necesare pentru a iniția inovații îndrăznețe.',
  about_us_48:
    'Este Asociația de Comerț Reprezentativă pentru companiile implicate în industria Mystery Shopping-ului. Asociația operează la nivel regional înîntreaga lume, cu capitole situate în America, Europa / Africa și Asia / Pacific. Companiile membre se unesc ca un corp comun în scopul consolidării industriei Mystery Shopping prin eforturi și acțiuni combinate.',
  about_us_49:
    'Este o organizație de membri fără scop lucrativ. Membrii formează o comunitate convinsă de potențialul analizei datelor, cercetării și informațiilor de a ajuta la îmbunătățirea societății, organizațiilor și vieții fiecăruia dintre noi.',
  about_us_50:
    'ESOMAR are sediul central în Amsterdam, Țările de Jos. ESOMAR este prezentă în peste 130 de țări prin membrii săi din 1947.',
  about_us_51:
    '(UCCA) este o comunitate profesională formată din experți privați și companii unite în scopul dezvoltării și interacțiunii în domeniul centrelor de contact și al serviciilor pentru clienți. Filosofia UCCA este de a ridica cultura industriei centrelor de contact, bazată pe tehnologii moderne și standarde ridicate.',
  about_us_52:
    'Asociația Ucraineană a Centrelor de Contact a fost înființată în 2010.',
  about_us_53:
    'Academy DTEK studiază cea mai bună experiență internațională în domeniul educației pentru a o adapta și utiliza pe piața ucraineană. De aceea, Academia DTEK colaborează cu cele mai importante școli de afaceri internaționale: INSEAD, IE Business School și Universitatea Stanford.',
  about_us_54:
    'Academy DTEK este concentrată pe dezvoltarea unei culturi orientate spre clienți și inovatoare în cadrul D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, programul digital DX School, Pre-MBA: Effective Leader și Change Energy.',
  about_us_55: 'Sergey Pashkovsky',
  about_us_56: 'Viktoria Skorbota',
  about_us_57: 'Artem Fomin',
  about_us_58: 'Max Papka',
  about_us_59: 'Manager International de vanzari',
  bht_1: 'Monitorizarea Sănătății',
  bht_2: 'Brandului',
  bht_3: 'Mărcile sunt incredibil de puternice',
  bht_4: 'Descoperă cât de bine performează brandul tău în general',
  bht_5: 'Despre',
  bht_6: 'Ce este Monitorizarea Sănătății Brandului (BHT)?',
  bht_7:
    'Acesta este un tip de cercetare de piață care se realizează cu o anumită regularitate (la două săptămâni, în fiecare lună etc.) pentru a obține informații despre',
  bht_8:
    'statutul unui brand în piață pe baza unor indicatori precum notorietatea, cererea, loialitatea, imaginea, etc.',
  bht_9: 'Au cumpărat cel mai des marca',
  bht_10: 'Loial',
  bht_11: 'Au cumparat marca pentru 3 luni',
  bht_12: 'Repertoriu / clienți obișnuiți',
  bht_13: 'Au cumparat marca pentru 6 luni',
  bht_14: 'Clienți pe termen scurt',
  bht_15: 'Au cumpărat marca pentru un an',
  bht_16: 'Clienți pe termen lung',
  bht_17:
    'Sunteți la curent cu marca? dar nu am folosit-o/cumpărat-o niciodată',
  bht_18: 'Sunt la curent, dar nu sunt clienți',
  bht_19: 'Nu sunt la curent: nu au auzit niciodată de marcă',
  bht_20: 'Nu sunt la curent',
  bht_21: 'Consumul de Brand',
  bht_22:
    'Evaluarea coerenței eforturilor depuse pe termen lung pentru a construi un brand',
  bht_23: 'Măsurarea nivelului de cunoaștere a clienților despre marcă',
  bht_24: 'Arătarea eficienței campaniilor de publicitate și de informare',
  bht_25:
    'Determinarea impactului pe care o are o perioadă de publicitate ridicată asupra activităților de vânzări/marketing ale concurenților',
  bht_26:
    'Oferirea unui control constant proprietarului afacerii în perioadele de schimbări continue ale pieței',
  bht_27: 'Ce sarcini pot fi rezolvate de BHT:',
  bht_28: 'Solicitați o cercetare',
  bht_29: 'Sarcini de rezolvat',
  bht_30: 'De exemplu,',
  bht_31:
    'dacă o companie are nevoie de o singură sesiune de BHT pe trimestru atunci cand se măsoară cota de piață a brandului, un sondaj ar trebui să fie efectuat mai des atunci când se măsoară notorietatea brandului ca și impact al publicității.',
  bht_32: 'CATI',
  bht_33: 'Față în față',
  bht_53: 'Interviuri',
  bht_34: 'СAWI',
  bht_35: 'Sarcini de rezolvat',
  bht_36: 'Prin colectarea de informații',
  bht_37:
    'prin diverse metode, urmărirea mărcii este de obicei împărțită în trei etape, în funcție de publicul țintă și de cât de dificil este de atins:',
  bht_38:
    'Folosim atât metode tradiționale, cât și contemporane pentru a colecta date:',
  bht_39:
    'Lucrăm folosind două modele: primul cu un set simplu de indicatori, iar al doilea vizează industrii mai complexe, cum ar fi industria alimentară și a serviciilor. Un model mai complex presupune un studiu aprofundat care include până la 10 indici estimați.',
  bht_40: 'Noi oferim',
  bht_41: 'un control al datelor inițiale',
  bht_42: 'Acoperim',
  bht_43: 'intreaga tara',
  bht_44: 'Oferim o soluție',
  bht_45:
    'pentru piețe nedinamice/piețe cu un număr limitat de jucători (Brand Point Analyse)',
  bht_46: 'De ce noi?',
  bht_47: 'De ce ne alegeți pe noi?',
  bht_50: 'Rezultat slab',
  bht_51: 'Rezultat bun',
  bht_52: 'Rezultat mediu',
  cases_1:
    'Provocare: Cum să îmbunătățiți experiența clienților de cumpărare a vehiculelor electrice în showroom-urile Hyundai?',
  cases_2: 'Maparea călătoriei clientului',
  cases_3: 'Generare de idei',
  cases_4: 'Cercetare calitativa',
  cases_5:
    'Provocare: Ce ar trebui schimbat în magazine (atât în procesul de servire, în interior, în percepția mărcii) pentru a atrage interesul clienților pentru marca Oppo?',
  cases_6: 'CJM online',
  cases_7: 'Testarea UI',
  cases_8: 'Interviuri',
  cases_9:
    'Provocare: Ce ar trebui schimbat în magazine pentru a atrage interesul clienților pentru marca Oppo?',
  cases_10: 'Partea calitativă',
  cases_11: 'Generare de idei',
  cases_12: 'Partea cantitativă',
  cases_13: 'Harta călătoriei clientului',
  cases_14: 'Cazuri',
  cases_15: 'Toate',
  cases_16: 'Hai sa construim impreuna cazul tau de succes',
  csi_1: 'Indicele',
  csi_2: 'de Satisfacție a Clienților',
  csi_3: 'Determinați motivele pentru care sunt mulțumiți /',
  csi_4: 'nemulțumiți clienții dumneavoastră',
  csi_5: 'Cercetarea satisfacției clienților',
  csi_6:
    'ne ajută să determinăm factorii, cauzele și motivele care influențează decizia clientului de cumpărare, precum și comportamentul publicului țintă.',
  csi_7: 'Un sondaj de satisfacție a clienților',
  csi_8:
    'arată cât de mulțumit sau nemulțumit este clientul cu un anumit aspect al serviciului sau al produsului în general.',
  csi_9: 'Sondajul de satisfacție',
  csi_10:
    'se aplică în rândul clienților existenți ai companiei și utilizatorilor anumitor produse/servicii.',
  csi_11: 'Ce este CSI?',
  csi_12:
    'Indicele de satisfacție a clienților indică în ce măsură au fost satisfăcute așteptările clienților cu privire la achiziția unui produs sau serviciu furnizat de compania dumneavoastră.',
  csi_13: 'Om fericit',
  csi_14: 'Despre',
  csi_15: 'Identificați orice factori ascunși',
  csi_16: 'care pot afecta satisfacția clientului',
  csi_17: 'Determinați motivele',
  csi_18:
    'în spatele oricărei creșteri/scăderi a cererii de bunuri și servicii',
  csi_19: 'Optimizați procesele',
  csi_20: 'de afaceri ale companiei',
  csi_21: 'Adaptați',
  csi_22:
    'caracteristicile produselor / serviciilor la nevoile consumatorului modern',
  csi_23: 'Formaţi un mesaj publicitar eficient',
  csi_24: 'și poziționați un produs la cererea clientului',
  csi_25: 'Sarcini de rezolvat',
  csi_26: 'Ce sarcini pot rezolva sondajele de satisfacție a clienților',
  csi_27: 'Bărbat cu telefon',
  csi_28: 'Construim un model matematic',
  csi_29:
    'a factorilor care pot influența satisfacția/loialitatea clienților (analiza de regresie)',
  csi_30: 'Optimizați',
  csi_31: 'procesele de afaceri ale companiei',
  csi_32: 'Construim un indice calculat de satisfacție a clienților',
  csi_33: '(CSI) și îl comparăm cu unul declarativ',
  csi_34: 'Facem o cercetare',
  csi_35: 'pentru ambele segmente B2B și B2C.',
  csi_36: 'Chestionarele noastre sunt elaborate de o echipă',
  csi_37:
    'de analiști în special pentru proiectele individuale ale clienților noștri',
  csi_38: 'De ce noi',
  csi_39: 'De ce să ne alegeți pe noi?',
  csi_40: 'Metode de colectare a informațiilor:',
  main_1:
    'au reușit să-și îmbunătățească performanța financiară învățând din experiențele clienților lor',
  main_2: 'O perspectivă la 360° asupra pieței și afacerii tale',
  main_3:
    'Oferim soluții de cercetare de piață și consultanță CX pentru îmbunătățirea experienței clienților tăi',
  main_4: 'Produse',
  main_5:
    'Cercetarea calitativă este o colectare și o analiza de date non-numerice pentru a înțelege concepte, opinii sau experiențe',
  main_6:
    'Metodele de cercetare cantitativă reprezintă o măsurare obiectivă și o analiză numerică a datelor colectate prin sondaje, chestionare și anchete.',
  main_7:
    'Mystery shopping este o tehnică de cercetare a pieței pentru măsurarea și monitorizarea interacțiunilor dintre un client și o companie sau organizație în timpul unui scenariu predefinit.',
  main_8:
    'Sistem de management al feedback-ului. Vocea clientului dumneavoastră într-un singur Sistem de management al feedback-ului clienților',
  main_9:
    'O platformă de informații despre conținut alimentată de inteligență artificială, care oferă informații de la public',
  main_10:
    'Software de management al performanței. Aplicație gamificată pentru creșterea vânzărilor',
  main_11:
    'Monitorizarea prețurilor este procesul de colectare, procesare și analiză a prețurilor concurenților',
  main_12:
    'Designul produselor, serviciilor, înglobează toate etapele decizionale',
  main_13:
    'Aplicăm metode de cercetare mixte și realizăm modele de analiză centrate pe obiectivele tale de marketing și business',
  main_14: 'Metodologii',
  main_15: 'Harta călătoriei clientului',
  main_16:
    'Customer Journey Mapping (CJM) se referă la definirea unui public țintă, înțelegerea nevoilor și a mediului acestora și apoi crearea unui plan pentru a oferi o experiență captivantă',
  main_17: 'CJM',
  main_18: 'Scor Net de Promovare (NPS)',
  main_19:
    'Net Promoter Score (NPS) este standardul de aur al valorilor experienței clienților, bazat pe o întrebare: Cât de probabil ați recomanda Organizația/Produsul/Serviciul?',
  main_20: 'NPS',
  main_21: 'Monitorizarea Sănătății Brandului (BHT)',
  main_22:
    'Cercetarea de tip Brand Health Tracking (BHT) poate măsura, atât transversal cât și longitudinal, performanța mărcii dvs. în ceea ce privește gradul de conștientizare și utilizare, poziționarea și performanța mărcii.',
  main_23: 'BHT',
  main_24: 'Cercetare organizațională',
  main_25:
    'Cercetarea organizațională analizează și evaluează modul în care o companie funcționează, se dezvoltă și se adaptează în contextul lor intern și extern. Realizăm studii de notorietate și percepție a brandului companiei pe piața muncii, evaluarea climatului organizațional, satisfacția angajaților,etc. ',
  main_26: 'Cercetare organizațională',
  main_27: 'Indicele de satisfacție a clienților',
  main_28:
    'Indicele de Satisfacție a Clienților (CSI) este un scor care indică cât de mulțumiți sunt clienții față de experiența avută cu produsele / serviciile oferite de compania dumneavoastră',
  main_29: 'CSI',
  main_30: 'Testare UX',
  main_31:
    'Testarea UX este modul în care utilizatorii interacționează cu un site web, o aplicație sau un alt produs,  analizând astfel comportamentul și reacțiile acestora',
  main_32: 'De ce noi',
  main_33:
    'Misiunea noastră este de a ajuta companiile să ofere clienților o experiență extrem de eficientă',
  main_34: 'Concentrarea pe calitate',
  main_35: 'Suntem controlați din punct de vedere al calității',
  main_36:
    'când vine vorba de date de cercetare. Punem la dispoziția clienților toate seturile de date',
  main_37: 'Eficient din punct de vedere al costurilor',
  main_38: 'Experiența noastră, resursele și eficiența',
  main_39:
    'proceselor de afaceri ne permit să economisim bani și timp pentru tine',
  main_40: 'Soluții inteligente',
  main_41: 'Folosim o varietate de tehnici',
  main_42:
    'și seturi de instrumente pentru a rezolva problemele tale specifice',
  main_43: 'Concentrarea pe calitate',
  main_44: 'Noi controlăm calitatea',
  main_45:
    'când vine vorba de date de cercetare. Punem la dispoziția clienților toate seturile de date',
  main_46: 'Eficient din punct de vedere al costurilor',
  main_47: 'Experiența noastră, resursele și eficiența',
  main_48:
    'proceselor de afaceri ne permit să economisim bani și timp pentru tine',
  main_49: 'Soluții inteligente',
  main_50: 'Folosim o varietate de tehnici',
  main_51:
    'și seturi de instrumente pentru a rezolva problemele tale specifice',
  main_52: 'Cash&Carry',
  main_53: 'Electronice',
  main_54: 'Lux/Premium',
  main_55: 'Restaurante și ospitalitate',
  main_56: 'Cu amănuntul',
  main_57: 'Automobile',
  main_58: 'Modă',
  main_59: 'Bănci',
  main_60: 'Călătorii și agrement',
  main_61: 'Ceasuri și bijuterii',
  main_62: 'Uz casnic și electrocasnice',
  main_63: 'Blog',
  main_64: 'Accesați blogul pentru a citi mai multe postări interesante',
  main_65: 'Audit de retail',
  main_66:
    'Colectați date detaliate despre magazinul sau marca dvs., analizați barierele de creștere și creați strategii eficiente pentru a le depăși',
  cjm_1: 'Client',
  cjm_2: 'Harta călătoriei',
  cjm_3: 'Intră în lumea clienților și împărtășește din experiența lor',
  cjm_4: 'Ce este Harta Customer Journey (harta de călătorie a clienților)?',
  cjm_5:
    'CJM oferă un istoric vizualizat al interacțiunii consumatorului cu un produs, serviciu, companie sau marcă prin diverse canale și într-o anumită perioadă de timp.',
  cjm_6:
    'Acest lucru permite companiilor să analizeze în mod obiectiv experiența de interacțiune cu clienții, să repare și să elimine orice bariere care apar și să ofere recomandări pentru îmbunătățirea produsului.',
  cjm_7: 'Determinarea TA, segmentare',
  cjm_8: 'Identificarea etapelor de interactiune cu clientul',
  cjm_9: 'Identificarea canalelor primare de interacțiune cu clienții',
  cjm_10: 'Cercetarea experienței clienților',
  cjm_11: 'Analiza barierelor și ipotezele privind optimizarea',
  cjm_12: 'Executarea si testarea ipotezelor',
  cjm_13:
    'Proiectarea unei hărți de călătorie a clienților constă din următorii pași:',
  cjm_14: 'Harta Customer Journey va demonstra:',
  cjm_15:
    'CJM oferă un istoric vizualizat al interacțiunii consumatorului cu un produs, serviciu, companie sau marcă prin diverse canale și într-o anumită perioadă de timp.',
  cjm_16: 'Asiguram munca in echipa',
  cjm_17: 'între analiști și designeri',
  cjm_18: 'Oferim individual',
  cjm_19: 'abordare a fiecărui client',
  cjm_20: 'Putem crește în ansamblu',
  cjm_21:
    'Satisfacția clienților cu serviciul/produsul și creșterea loialității lor față de companie',
  cjm_22: 'Vă garantăm',
  cjm_23:
    'optimizarea traseului consumatorului și luarea în considerare a proceselor de afaceri ale companiei',
  cjm_24: 'Oferim clienților noștri',
  cjm_25:
    'posibilitatea de a utiliza abordări combinate: CATI, CAWI, focus grupuri, interviuri aprofundate, interviuri cu experți, etnografie etc.',
  cjm_26: 'De ce să ne alegeți pe noi?',
  methods_hr_1: 'Cercetare Organizațională',
  methods_hr_2:
    'Evaluează starea actuală a companiei tale. Identifică percepțiile angajaților cu privire la mediul de lucru, condițiile de muncă, recunoașterea, comunicarea și alte aspecte importante',
  methods_hr_3:
    'Câteva motive pentru care este posibil să aveți nevoie de cercetare organizațională',
  methods_hr_4: 'angajați intervievați',
  methods_hr_5: 'proiecte de resurse umane implementate',
  methods_hr_6: 'sesiuni de instruire a personalului',
  methods_hr_7:
    'Holding-ul de vârf european pentru experiența clienților și cercetarea angajaților',
  methods_hr_8: 'Cercetările 4service privind resursele umane în cifre',
  methods_hr_9:
    'Pentru a identifica problemele și punctele slabe în fiecare etapă a vieții angajaților companiei dvs.: căutare/recrutare/integrare/dezvoltare cariera/recertificări',
  methods_hr_10: 'Sa stii ce ocazii merita mariri si bonusuri',
  methods_hr_11:
    'Trebuie să preziceți sau să oferiți soluții pentru nemulțumirea angajaților',
  methods_hr_12:
    'Prin cercetarea HR sunt posibile metode noi și actuale de evaluare a politicilor, programelor și practicilor HR',
  methods_hr_13:
    'Pentru a îmbunătăți sănătatea mintală a angajaților companiei',
  methods_hr_14:
    'Dacă doriți să îmbunătățiți productivitatea printr-o abordare orientată spre obiective',
  methods_hr_15:
    'Dacă aveți o echipă internațională și/sau la distanță și trebuie să o gestionați',
  methods_hr_16:
    'Pentru a înțelege cât de atractiv este brandul dvs. de angajator pentru potențialii angajați',
  methods_hr_17: 'Satisfacția angajaților',
  methods_hr_18: 'Cercetare de branding organizațional',
  methods_hr_19: 'Analiza proceselor decizionale',
  methods_hr_20: 'Planuri de compensare și salarizare',
  methods_hr_21: 'Implicarea echipei și loialitatea',
  methods_hr_22: 'Evaluarea climatului organizațional',
  methods_hr_23: 'Domenii de cercetare',
  methods_hr_24: 'Solutia 1',
  methods_hr_25: 'eNPS, Satisfacția angajaților, Loialitatea angajaților',
  methods_hr_26: 'Ce măsurăm:',
  methods_hr_27: 'Loialitate:',
  methods_hr_28:
    'loialitatea angajaților, anticiparea comportamentului viitor, determinarea ponderii factorilor care afectează loialitatea',
  methods_hr_29: 'Implicare:',
  methods_hr_30:
    'implicarea angajatilor in munca, interes pentru rezultate si realizari',
  methods_hr_31: 'Angajament:',
  methods_hr_32: 'identificarea valorilor angajatilor si a valorilor companiei',
  methods_hr_33: 'Trust 360:',
  methods_hr_34:
    'încredere în management, colegi de muncă, subordonați, încredere în strategiile și politicile angajatorilor',
  methods_hr_35: 'Satisfacţie:',
  methods_hr_36:
    'definirea factorilor unici pentru fiecare companie, evaluarea satisfacției pentru fiecare factor cheie care afectează loialitatea',
  methods_hr_37: 'Solutia 2',
  methods_hr_38: 'Branding de angajator',
  methods_hr_39: 'Angajator intern',
  methods_hr_40:
    'analiza mărcii (angajați, părți interesate, recrutori interni și specialiști în resurse umane)',
  methods_hr_41: 'Analiza angajatorului extern',
  methods_hr_42:
    '(analiza EVP concurenților, sondaj de recrutare externă, sondaj de căutare de locuri de muncă)',
  methods_hr_43: 'Conștientizarea mărcii și reputația',
  methods_hr_44: 'analiză',
  methods_hr_45: 'Prezența angajatorului',
  methods_hr_47: 'Referința angajatorului',
  methods_hr_48: 'Colectie',
  methods_hr_49: 'Reper competitiv',
  methods_hr_50: 'asupra factorilor cheie precum',
  methods_hr_51: 'Salariu,',
  methods_hr_52: 'mediul de lucru, atmosfera, natura muncii',
  methods_hr_53: 'Analiza nevoilor si asteptarilor',
  methods_hr_54: 'a solicitantilor de locuri de munca',
  methods_hr_55: 'interviuri aprofundate,',
  methods_hr_56: 'se utilizează cercetarea de birou și cercetarea cantitativă',
  methods_hr_57: 'Solutia 3',
  methods_hr_58: 'Harta călătoriei angajaților/angajat misterios',
  methods_hr_59:
    'construirea călătoriei în căutarea unui loc de muncă identificând',
  methods_hr_60:
    'principalele etape ale căutării, canalele de comunicare, criteriile de selecție și evaluarea persoanelor aflate în căutarea unui loc de muncă',
  methods_hr_61: 'monitorizarea tuturor etapelor',
  methods_hr_62:
    'și canale de comunicare cu cei care caută un loc de muncă misterios (doar a face o programare pentru un interviu, a avea un interviu, un stagiu în companie)',
  methods_hr_63: 'Identificare',
  methods_hr_64: 'a punctelor slabe în procesele de recrutare și integrare',
  methods_hr_65: 'Recomandări de îmbunătățire,',
  methods_hr_66: 'evaluare comparativă cu liderii din industrie',
  mystery_shopping_1: 'Mystery',
  mystery_shopping_2: 'Shopping',
  mystery_shopping_3: 'Evaluează-ți serviciul cu clienți reali',
  mystery_shopping_4:
    'Verificați evaluarea standardelor pentru ca personalul dvs. să fie mai apropiat de client',
  mystery_shopping_5:
    'Suntem membru de elită al asociației profesionale de lideri Mystery Shopping Providers MSPA Europe',
  mystery_shopping_6: 'de experienţă',
  mystery_shopping_7: 'vizite lunare',
  mystery_shopping_8: 'cumpărători misterioși din întreaga lume',
  mystery_shopping_9: 'Proiecte active',
  mystery_shopping_10: 'birouri, sediu central în Austria',
  mystery_shopping_11: 'Furnizori europeni de servicii de Mystery Shopping',
  mystery_shopping_12: 'evaluări ale calității serviciilor',
  mystery_shopping_13: 'Chestionar',
  mystery_shopping_14: 'Software',
  mystery_shopping_15: 'Profilul cumpărătorului',
  mystery_shopping_16: 'Validare și obiecție',
  mystery_shopping_17: 'Analitice',
  mystery_shopping_18:
    'Cu acești 5 pași, compania noastră 4Service garantează calitatea serviciului mystery shopper',
  mystery_shopping_19: 'Program',
  mystery_shopping_20:
    'Cele 5 elemente ale programului Mystery Shopping de la 4Service',
  mystery_shopping_21: 'Chestionar:',
  mystery_shopping_22: 'esența programului tau',
  mystery_shopping_23:
    'Această parte a programului este vizibilă de către toate părțile interesate',
  mystery_shopping_24: 'Pentru managerii de top.',
  mystery_shopping_25: 'Afișați toți pașii importanți și detaliile procesului',
  mystery_shopping_26: 'Pentru angajati.',
  mystery_shopping_27: 'Reflectați ceea ce așteaptă angajatorii de la ei',
  mystery_shopping_28: 'Pentru cumpărătorii misterioși.',
  mystery_shopping_29:
    'Clar și structurat pentru a evita confuzia și părtinirea',
  mystery_shopping_30: 'Software Shopmetrics:',
  mystery_shopping_31: 'ușor de utilizat și inovator',
  mystery_shopping_32: 'Beneficii:',
  mystery_shopping_33: 'Se integrează',
  mystery_shopping_34: 'cu procesele și software-ul dvs. de afaceri',
  mystery_shopping_35: 'Orientare GDPR',
  mystery_shopping_36:
    'Setul de instrumente de management al confidențialității',
  mystery_shopping_37: 'Detalii despre',
  mystery_shopping_38: 'Pachetul de conformitate legală',
  mystery_shopping_39: 'Construire',
  mystery_shopping_40: 'Raport',
  mystery_shopping_41: 'Management',
  mystery_shopping_42: 'al nivelurilor de acces',
  mystery_shopping_43: 'Majoritatea chestionarelor',
  mystery_shopping_44: 'sunt finalizate într-o oră',
  mystery_shopping_45: 'Profilul cumpărătorilor:',
  mystery_shopping_46:
    'Peste 200 000 de cumpărători misterioși din întreaga lume',
  mystery_shopping_47:
    'Criterii principale: proximitate maximă față de publicul țintă, cu experiență anterioară cu compania sau concurenții',
  mystery_shopping_48: 'Instruirea cumpărătorilor:',
  mystery_shopping_49: 'Test de formare introductiva',
  mystery_shopping_50: 'Skype/Apel telefonic',
  mystery_shopping_51: 'Test de evaluare (optional)',
  mystery_shopping_52: 'Validare și obiecții',
  mystery_shopping_53: 'Validare',
  mystery_shopping_54: 'Sistem',
  mystery_shopping_55: 'Rapoartele sunt 100% ',
  mystery_shopping_56: 'revizuite de către echipa de validare',
  mystery_shopping_57: 'Apeluri suplimentare',
  mystery_shopping_58: 'pentru clarificare',
  mystery_shopping_59: 'Înregistrări audio',
  mystery_shopping_60: 'după vizită pentru a nota cele mai importante aspecte',
  mystery_shopping_61: 'Frauda angajaților',
  mystery_shopping_62: 'sistem de prevenire',
  mystery_shopping_63: 'Obiecţie',
  mystery_shopping_64: 'Sistem',
  mystery_shopping_65: 'Evaluarea oricărui cumpărător',
  mystery_shopping_66: 'poate fi obiectată de către angajat direct pe site',
  mystery_shopping_67: 'Fiecare obiecție',
  mystery_shopping_68:
    'este luată în considerare de către echipa locală de validare',
  mystery_shopping_69: 'Puteți vedea statistici',
  mystery_shopping_70: 'ale obiecțiilor',
  mystery_shopping_71: 'Analitice.',
  mystery_shopping_72: 'Raportul este prezentat personal și conține:',
  mystery_shopping_73: 'Problemele',
  mystery_shopping_74: 'și preocupările principale ale companiei',
  mystery_shopping_75: 'Dinamica',
  mystery_shopping_76: 'pe diviziuni și secțiuni',
  mystery_shopping_77: 'Corelație',
  mystery_shopping_78:
    'între îndeplinirea standardelor și satisfacția clienților',
  mystery_shopping_79: 'Reper',
  mystery_shopping_80: 'împotriva concurenților',
  mystery_shopping_81: 'Practic',
  mystery_shopping_82: 'recomandări privind îmbunătățirea serviciilor',
  mystery_shopping_83: 'Ce ne face diferiți?',
  mystery_shopping_84: 'Desfășurăm sesiuni de generare de idei',
  mystery_shopping_85:
    'Noi nu doar recomandăm ce să faci, ci împreună cu tine căutăm soluții practice pentru rezolvarea problemelor de servicii',
  mystery_shopping_86:
    'Sesiunile de generare de idei sunt o unealtă puternică care ne ajută:',
  mystery_shopping_87:
    'Sa dezvoltam direcții pentru îmbunătățirea produselor sau serviciilor',
  mystery_shopping_88:
    'Sa exploram noi oportunități de flux de venituri și strategii de afaceri',
  mystery_shopping_89:
    'Pentru a găsi soluții la provocări complexe orientate către client',
  mystery_shopping_90:
    'transformarea problemelor clientilor în experiențe plăcute',
  mystery_shopping_91: 'Realizam interviuri video',
  mystery_shopping_92:
    'Nu colectăm doar chestionare - primim și comentarii live de la cumpărători',
  mystery_shopping_93: 'Facem acest lucru pentru a:',
  mystery_shopping_94:
    'Persoanele responsabile vor efectua interviuri detaliate și vor oferi feedback eficient',
  mystery_shopping_95: 'A vedea experiența din perspectiva clienților',
  mystery_shopping_96: 'Îmbunătățiți strategia de marketing de conținut',
  mystery_shopping_97: 'Prezice comportamentul clienților',
  mystery_shopping_98: 'Noi proiectăm harta călătoriei clienților',
  mystery_shopping_99:
    'Crearea hărții călătoriei clienților ne ajută să ne punem în locul clienților și să înțelegem nevoile și provocările lor',
  mystery_shopping_100:
    'Crearea unei harti de călătorie a clienților este cea mai bună modalitate de a:',
  mystery_shopping_101: 'A vedea experiența din perspectiva clienților',
  mystery_shopping_102: 'Îmbunătăți strategia de marketing de conținut',
  mystery_shopping_103: 'Prezice comportamentul clienților',
  mystery_shopping_104: 'Identificarea problemelor în servicii sau comunicare',
  mystery_shopping_105:
    'Oferim diferite tipuri de servicii de Mystery shopping',
  mystery_shopping_106: 'Tipuri de Mystery shopping',
  mystery_shopping_107: 'Mystery call',
  mystery_shopping_108:
    'Sunăm managerii și/sau centrul de apeluri pentru a determina calitatea serviciului oferit prin telefon',
  mystery_shopping_109: 'Mystery shopping pentru vizite de lux',
  mystery_shopping_110:
    'Serviciu de verificare în locații de lux de către o categorie specială de cumpărători misterioși',
  mystery_shopping_111: 'Mystery shopping competițional',
  mystery_shopping_112:
    'Cel mai simplu mod de a înțelege cum funcționează concurentul tău',
  mystery_shopping_113: 'Mystery employee (angajat misterios)',
  mystery_shopping_114:
    'Vă va ajuta să înțelegeți întreaga călătorie a angajatului dvs. (angajare, adaptare, procese de birou etc.)',
  mystery_shopping_115: 'Vizite motivaționale',
  mystery_shopping_116:
    'Un instrument util pentru stimularea ofertei de servicii și/sau produse suplimentare, vânzări de produse specifice',
  mystery_shopping_117: 'Mystery shopping online',
  mystery_shopping_118:
    'Simulează și măsoară experiența clienților în magazinul dvs. online, site-ul sau aplicația dvs',
  mystery_shopping_119: 'Monitorizarea vânzărilor și promoțiilor',
  mystery_shopping_120:
    'Ajută la identificarea modului în care sunt afișate promoțiile în toate locațiile și cât de eficient funcționează',
  mystery_shopping_121: 'Vizite de cumpărare și retur',
  mystery_shopping_122:
    'Ajută la reflectarea întregului proces de la cumpărare până la returnare pentru a îmbunătăți experiența clientului',
  mystery_shopping_123: '> 20 ani',
  nps_1: 'Scorul Net',
  nps_2: 'de Promovare',
  nps_3: 'Măsurați, urmăriți și îmbunătățiți loialitatea clienților',
  nps_4: 'Ei răspund cu un scor de la 0 la 6.',
  nps_5:
    'Aceștia ar putea să fi avut o experiență proastă și este puțin probabil să mai cumpere de la tine și ar putea chiar să îi descurajeze pe alții să cumpere de la tine',
  nps_6: 'Aceștia sunt respondenți care au un punctaj între 7 și 8.',
  nps_7:
    'Sunt mulțumiți de serviciile dvs., dar nu sunt suficient de fericiți pentru a fi considerați promotori.',
  nps_8: 'Aceștia sunt respondenții care vă evaluează afacerea cu 9 sau 10.',
  nps_9:
    'Acest lucru înseamnă că ar recomanda compania unui prieten sau coleg, și astfel promovează compania în numele tău',
  nps_10: 'Ce este NPS?',
  nps_11: 'Măsoară percepția clienților pe baza unei întrebări simple:',
  nps_12:
    'Cât de probabil este să recomandați [Compania/Produsul/Serviciile] unui prieten sau coleg?',
  nps_13: 'Compania mea are nevoie de el?',
  nps_14: 'NPS este creșterea ta durabilă',
  nps_15: 'Înțelegeți dinamica loialității clienților',
  nps_16: 'Motivați-vă personalul',
  nps_17: 'Înțelegeți relația cu diferite produse',
  nps_18:
    'Comparați modul în care clienții vă tratează pe dvs. și pe concurenții dvs',
  nps_19: 'Ce pot face cu NPS?',
  nps_20: 'Pentru ce fel de afacere este relevant NPS-ul?',
  nps_21: 'Metode de colectare a informațiilor:',
  nps_22: 'Recomandările noastre atunci când lucrați cu NPS',
  nps_23:
    'Aceasta înseamnă că dezvoltăm linii directoare pentru a ajuta la transformarea Detractorilor în promotori',
  nps_24: 'Lucrăm cu o metodă „în buclă închisă”.',
  nps_25:
    'NPS este o cercetare de urmărire în care monitorizăm schimbările în loialitatea clienților',
  nps_26: 'Monitorizăm dinamica',
  nps_27:
    'Oferim mereu evaluarea concurenților din domeniul dvs. pentru o analiză mai detaliată',
  nps_28: 'Evaluarea concurenților',
  price_monitoring_1: 'Monitorizarea prețurilor',
  price_monitoring_2: 'Monitorizare retail',
  price_monitoring_3:
    'Creșteți potențialul de vânzări și optimizați-vă politica de prețuri prin monitorizarea prețurilor concurenților',
  price_monitoring_4:
    '4Service oferă servicii de monitorizare a prețurilor la nivel mondial de mai bine de 20 de ani',
  price_monitoring_5:
    'Prețurile sunt monitorizate într-un timp scurt de echipa noastră de auditori interni calificați, permițându-ne să răspundem rapid la modificările prețurilor concurenților',
  price_monitoring_6:
    'Provocările pe care compania noastră vă poate ajuta să le rezolvați prin monitorizarea prețurilor',
  price_monitoring_7: 'Evaluarea prețurilor practicate de concurenți',
  price_monitoring_8: 'și politici de produs',
  price_monitoring_9: 'Monitorizarea promoțiilor',
  price_monitoring_10: 'și ofertelor speciale',
  price_monitoring_11: 'Monitorizarea condițiilor de piață',
  price_monitoring_12: 'și a mediului de piață',
  price_monitoring_13: 'Verificarea și controlul punctelor de vânzare',
  price_monitoring_14: 'pentru echipamente și mărfuri speciale',
  price_monitoring_15: 'Gestionarea fluxului',
  price_monitoring_16: 'de produse în cadrul comerțului',
  price_monitoring_17: 'Condiții de optimizare',
  price_monitoring_18: 'pentru o mai bună colaborare cu furnizorii, dealerii',
  price_monitoring_19: 'Măsurarea spațiului la raft,',
  price_monitoring_20:
    'evaluarea poziționării categoriilor de produse, disponibilitatea stocului',
  price_monitoring_21: 'Indicatori cheie și criterii de monitorizare',
  price_monitoring_22:
    'Toate datele sunt integrate într-o singură interfață, care include:',
  price_monitoring_23: 'Numele',
  price_monitoring_24: 'categoriei de produse și marca',
  price_monitoring_25: 'Disponibilitate',
  price_monitoring_26: 'materialelor promoționale',
  price_monitoring_27: 'Preț',
  price_monitoring_28: 'și disponibilitatea oricăror oferte promoționale',
  price_monitoring_29: 'Mărime',
  price_monitoring_30: 'raft',
  price_monitoring_31: 'Afișare',
  price_monitoring_32: 'produs',
  price_monitoring_33: 'Orientare',
  price_monitoring_34: 'SKU',
  price_monitoring_35: 'Tip',
  price_monitoring_36: 'de ambalare',
  price_monitoring_37: 'Oricare altă',
  price_monitoring_38: 'informație generală',
  price_monitoring_39: 'Analiza pre-proiect:',
  price_monitoring_40: 'Studiem',
  price_monitoring_41:
    'politica de prețuri și produse ale companiei dvs., practicile de afaceri și din industrie',
  price_monitoring_42: 'Oferim',
  price_monitoring_43:
    'instruire promptă, testare și certificare pentru auditori',
  price_monitoring_44: 'Personalizăm',
  price_monitoring_45: 'și configuram software-ul',
  price_monitoring_46: 'Munca de teren:',
  price_monitoring_47: 'Vizite la punctele',
  price_monitoring_48: 'de vânzare ale retailerilor',
  price_monitoring_49: 'Raportare:',
  price_monitoring_50: 'Echipa noastră analitică',
  price_monitoring_51:
    'pregătește rapoarte online în conturile personale ale companiei',
  price_monitoring_52: 'Oferim',
  price_monitoring_53:
    'un reportaj foto complet, raport PPT, raport Excel în ceea ce privește tipul de localitate, numele lanțului, adresa punctului de vânzare',
  price_monitoring_54: 'Cum',
  price_monitoring_55: 'funcționează',
  price_monitoring_56: 'mărimea reducerilor individuale',
  price_monitoring_57: 'calculul costurilor',
  price_monitoring_58: 'mărimea bonusurilor individuale',
  price_monitoring_59: 'programe de loialitate',
  price_monitoring_60: 'etc.',
  price_monitoring_61: 'Folosim software specializat:',
  price_monitoring_62: 'PRADATA, Shopmetrics, și oferim suport tehnic complet',
  price_monitoring_63: 'Desfăşurare rapidă',
  price_monitoring_64: 'și scalare (din ziua 1)',
  price_monitoring_65: 'Toate datele confirmate',
  price_monitoring_66: 'prin fotografii și etichetare GPS',
  price_monitoring_67: 'Avem numărul necesar',
  price_monitoring_68: 'de agenți de teren în orice țară',
  price_monitoring_69: 'Putem lucra cu mari',
  price_monitoring_70: 'cantități de date',
  price_monitoring_71: 'Monitorizarea prețurilor individuale',
  price_monitoring_72:
    'Dacă aveți produse complexe - vă putem ajuta să vă definiți strategia de preț',
  price_monitoring_73:
    'Putem monitoriza prețurile individuale ale concurenților, de exemplu:',
  price_monitoring_74: 'Cost',
  price_monitoring_75: 'scăzut',
  price_monitoring_76: 'Eficiență',
  price_monitoring_77: 'înaltă',
  price_monitoring_78: 'raport Excel',
  price_monitoring_79: 'raport PPT',
  price_monitoring_80: 'Raport BI',
  price_monitoring_81:
    'Datele privind prețurile competitorilor vă ajută să luați cele mai bune decizii de preț',
  price_monitoring_82:
    'Oferim un raport complet foto, un raport PPT, un raport Excel în funcție de tipul de localitate.',
  qualitative_research_1: 'Cercetări',
  qualitative_research_2: 'calitative',
  qualitative_research_3:
    'Colectăm, analizăm și interpretăm date prin observarea acțiunilor și cuvintelor clienților tăi',
  qualitative_research_4: 'Valori',
  qualitative_research_5: 'Comportament',
  qualitative_research_6: 'Istoric',
  qualitative_research_7: 'Așteptări',
  qualitative_research_8: 'Mediu',
  qualitative_research_9: 'Temeri/Bariere',
  qualitative_research_10: 'Care este motivul alegerii?',
  qualitative_research_11: 'Bărbat cu telefon',
  qualitative_research_12: 'marca',
  qualitative_research_13: 'categorie',
  qualitative_research_14: 'publicitate',
  qualitative_research_15: 'produs',
  qualitative_research_16:
    'Unicitatea metodelor calitative constă în faptul că ne permit să înțelegem cauzele și motivația comportamentului consumatorilor',
  qualitative_research_17:
    'Analiza atitudinii consumatorului față de un anumit fenomen',
  qualitative_research_18: 'Perspective și experiențe individuale',
  qualitative_research_19: 'Subiecte care nu pot fi investigate prin sondaje',
  qualitative_research_20: 'Subiecte sensibile',
  qualitative_research_21: 'Public greu de atins',
  qualitative_research_22: 'Geografie complexă',
  qualitative_research_23: 'Interviuri aprofundate utile pentru:',
  qualitative_research_24: 'Interviuri în profunzime',
  qualitative_research_25:
    'Un interviu în profunzime este o tehnică de cercetare calitativă care implică realizarea de interviuri individuale cu respondenții pentru a explora perspectivele acestora asupra unei anumite idei, program sau situație. Un interviu aprofundat explorează experiențele / percepțiile / practicile individuale în detalii bogate.',
  qualitative_research_26: 'Beneficii:',
  qualitative_research_27: 'Caracterizarea normelor sociale și culturale',
  qualitative_research_28: 'Public greu de atins',
  qualitative_research_29:
    'Partajarea și compararea (Morgan) investigate prin sondaje',
  qualitative_research_30: 'Subiecte care nu pot fi investigate prin sondaje',
  qualitative_research_31: 'Focus grupuri aprofundate utile pentru:',
  qualitative_research_32: 'Focus grup',
  qualitative_research_33:
    'Explorarea profundă a întâlnirilor individuale, discuții emergente, discuții între manifestări sub influența moderatorului de grup.',
  qualitative_research_34:
    'Generarea de perspective asupra experiențelor comune și a normelor sociale prin discuții de grup, date narative într-o discuție concentrată.',
  qualitative_research_36: 'Beneficii:',
  qualitative_research_37:
    'Studierea stilului de viață și a comportamentului consumatorului în diferite condiții reale (acasă, la punctul de vânzare, la locul de muncă etc.)',
  qualitative_research_38:
    'Căutați perspective pentru dezvoltarea de noi produse, ambalaje, îmbunătățirea serviciilor etc.',
  qualitative_research_39: 'Cercetare etnografică aprofundată utilă pentru:',
  qualitative_research_40: 'Cercetare etnografică',
  qualitative_research_41:
    'Etnografia este un tip de cercetare calitativă care implică patrunderea într-o anumită comunitate sau organizație pentru a le observa comportamentul și interacțiunile de aproape.',
  qualitative_research_42:
    'Etnografia este o metodă de cercetare flexibilă care vă permite să obțineți o înțelegere profundă a culturii, convențiilor și dinamicii sociale comune a unui grup. Cu toate acestea, implică și unele provocări practice și etice.',
  qualitative_research_43: 'Metode:',
  qualitative_research_44:
    'Etnografia este un studiu al consumatorului în condiții de viață reală și interacțiune cu un produs/serviciu',
  qualitative_research_45: 'Beneficiile lucrului cu noi',
  qualitative_research_46:
    'Putem găsi respondenți din orice public țintă. Și controlăm calitatea recrutării',
  qualitative_research_47: 'Recrutare de orice fel de complexitate',
  qualitative_research_48:
    'Moderatorii noștri sunt poligloti și au abilități de facilitare',
  qualitative_research_49: 'Moderatori experți',
  qualitative_research_50: 'Lucrează online/offline',
  qualitative_research_51: 'Oferim audio/video',
  qualitative_research_52: 'înregistrări',
  qualitative_research_53:
    'Putem efectua cercetări calitative în orice localitate',
  quantitative_research_1: 'Studiul satisfacției clienților',
  quantitative_research_2: 'cu un anumit produs sau serviciu (SCI, NPS)',
  quantitative_research_3: 'Efectuarea segmentării',
  quantitative_research_4: 'sondaje ale diferitelor piețe',
  quantitative_research_5: 'Cercetare de piata',
  quantitative_research_6: 'managementul NPS',
  quantitative_research_7: 'Analiza concurenței',
  quantitative_research_8: 'Cercetări',
  quantitative_research_9: 'cantitative',
  quantitative_research_10:
    'Metodologie de cercetare care folosește pentru a testa teorii despre atitudinile și comportamentele oamenilor bazate pe date obiective, numerice și statistice',
  quantitative_research_11: 'ani',
  quantitative_research_12: 'de experiență CX la nivel mondial',
  quantitative_research_13: 'grup de respondenti',
  quantitative_research_14: 'al respondenţilor',
  quantitative_research_15: 'ţări',
  quantitative_research_16: 'derulăm proiecte în toată lumea',
  quantitative_research_17: 'Cine suntem noi',
  quantitative_research_18:
    '4Service este o companie globală de cercetare de marketing și CX. Oferim servicii de colectare a datelor pentru o varietate largă de afaceri, cu ajutorul experților noștri.',
  quantitative_research_19: 'Cifrele 4Service',
  quantitative_research_20: 'Asistat de calculator',
  quantitative_research_21: 'Interviu web',
  quantitative_research_22:
    'O tehnică de chestionare bazată pe internet. Respondentul completează un chestionar electronic fără ajutorul unui intervievator. Chestionarul poate fi trimis electronic sau postat pe site.',
  quantitative_research_23: 'Interviu web asistat de calculator',
  quantitative_research_24: 'În cele mai multe cazuri',
  quantitative_research_25:
    'această metodă este cea mai rentabilă din punct de vedere al costurilor de material și timp',
  quantitative_research_26: 'Această metodă permite',
  quantitative_research_27:
    'într-un timp scurt intervievarea unui segment de consumatori specific, respectandu-se rigoarea științifică de eșantionare',
  quantitative_research_28: 'Oferă posibilități extinse',
  quantitative_research_29:
    'pentru demonstrații materiale video și audio, precum și imagini',
  quantitative_research_30: 'Respondenții pot fi',
  quantitative_research_31: 'situati oriunde în lume',
  quantitative_research_32: 'Permite investigarea',
  quantitative_research_33:
    'comportamentului specific al audienței internetului. Principalele rezultate pot fi accesate de client în timp real printr-o interfață web',
  quantitative_research_34: 'Eligibil pentru orice confidențialitate,',
  quantitative_research_35:
    'Subiect sau problemă sensibilă și personală la care respondenții ar putea fi reticenți să răspundă când discută cu un intervievator',
  quantitative_research_36: '150 000+ panel',
  quantitative_research_37:
    'Avem propriul nostru panel de respondenți din întreaga lume',
  quantitative_research_38: 'Abordam intrebari dificile',
  quantitative_research_39:
    'Prin utilizarea unor instrumente complementare (social media, publicitate), putem găsi un public specific și un profil sofisticat al respondentului',
  quantitative_research_40: 'Ne motivăm respondenții',
  quantitative_research_41:
    'Am creat un cadru pentru a încuraja completarea chestionarelor lungi, oferind stimulente materiale',
  quantitative_research_42: 'Interviuri față în față',
  quantitative_research_43: 'realizat prin utilizarea tabletei',
  quantitative_research_44:
    'Aceasta este una dintre principalele metode de colectare a datelor cantitative, timp în care intervievatorul comunică direct cu respondentul pe un chestionar strict structurat.',
  quantitative_research_45:
    'Interviurile față în față pot fi desfășurate la locul de muncă sau de reședință al respondenților, pe stradă sau în locurile de vânzare desemnate utilizand o tabletă',
  quantitative_research_46:
    'Interviuri față în față realizate prin utilizarea tabletei',
  quantitative_research_47: 'Determinarea nivelului',
  quantitative_research_48: 'de popularitate și recunoaștere a mărcii',
  quantitative_research_49: 'Segmentarea consumatorilor',
  quantitative_research_50: 'pe baza comportamentului lor de cumpărare',
  quantitative_research_51: 'Analiza impactului',
  quantitative_research_52: 'campaniei de publicitate',
  quantitative_research_53: 'Satisfacția clientului',
  quantitative_research_54: 'măsurare',
  quantitative_research_55: 'Stabilirea celui mai bun preț',
  quantitative_research_56: 'pentru produs sau serviciu',
  quantitative_research_57: 'Analiză',
  quantitative_research_58: 'a preferințelor consumatorilor',
  quantitative_research_59: 'Studiu',
  quantitative_research_60: 'despre cum cumpără consumatorii',
  quantitative_research_61: 'Evaluare',
  quantitative_research_62: 'a capacitatii pietei',
  quantitative_research_63: 'Software-ul DigSee',
  quantitative_research_64:
    'Folosim software special cu inregistrare audio si GPS',
  quantitative_research_65: 'Intervievatori și supraveghetori',
  quantitative_research_66:
    'putem efectua interviuri în orice limbă și putem traduce în limba preferată a clientului',
  quantitative_research_67: 'Esantionare',
  quantitative_research_68:
    'Departamentul de analiză dezvoltă un sistem de recrutare a respondenților pentru a obține cel mai bun rezultat posibil',
  quantitative_research_69: 'Asistat de calculator',
  quantitative_research_70: 'Interviu telefonic',
  quantitative_research_71:
    'Metodologie de colectare a informațiilor cantitative prin interviuri telefonice folosind un chestionar clar structurat.',
  quantitative_research_72: 'Interviuri telefonice asistate de calculator',
  quantitative_research_73: 'Avem propriu nostru call-center',
  quantitative_research_74:
    'Folosim software special cu inregistrare audio si GPS',
  quantitative_research_75: 'Operatori calificați',
  quantitative_research_76:
    'Operatorii noștri vorbesc diferite limbi, sunt instruiți, bine testați și își îmbunătățesc în mod continuu abilitățile',
  quantitative_research_77: 'Baze de date',
  quantitative_research_78:
    'Lucrăm cu propriile noastre baze de date și cu bazele dvs. de clienți, de asemenea',
  quantitative_research_79: 'SLA',
  quantitative_research_80:
    'Garantăm calitatea și semnăm un SLA (Service Level Agreement)',
  quantitative_research_81: 'Stabilirea obiectivelor',
  quantitative_research_82: 'Determinarea metodologiei',
  quantitative_research_83: 'Segmentarea și eșantionarea',
  quantitative_research_84: 'Definirea canalelor de colectare a datelor',
  quantitative_research_85: 'Crearea unui chestionar logic pentru respondent',
  quantitative_research_86: 'Procesul de colectare a datelor',
  quantitative_research_87: 'Asigurarea calității',
  quantitative_research_88: 'Tehnici de analiză',
  quantitative_research_89: 'Procesarea datelor',
  quantitative_research_90: 'Designul sondajului',
  quantitative_research_91: 'Urmărirea locației prin GPS a intervievatorilor',
  quantitative_research_92: 'Verificarea datelor',
  quantitative_research_93: 'Programarea și testarea chestionarului',
  quantitative_research_94: 'Data validarii',
  quantitative_research_95: 'Înregistrări audio respectând cerințele GDPR',
  quantitative_research_96:
    'Pregătirea și competența personalului de cercetare',
  quantitative_research_97: 'Asigurarea calității',
  quantitative_research_98: 'Analiza factorilor',
  quantitative_research_99: 'Segmentarea',
  quantitative_research_100: 'Cartografierea perceptivă',
  quantitative_research_101: 'Corelație',
  quantitative_research_102: 'Model de conversie',
  quantitative_research_103: 'Jaccard',
  quantitative_research_104: 'Analiza grupului',
  quantitative_research_105: 'etc.',
  quantitative_research_106: 'Rapoarte analitice',
  quantitative_research_107:
    'Folosim SPSS® și alte instrumente pentru a construi rapoarte analitice folosind diferite metodologii',
  quantitative_research_108: 'Ce provocări de business pot fi rezolvate:',
  quantitative_research_109: 'Ce obiective de marketing pot fi atinse:',
  social_responsibility_1: 'Responsabilitatea Socială Corporativă a 4Service',
  social_responsibility_2:
    'Din inima și sufletul fiecărui membru al echipei noastre. Încercăm să păstrăm o scânteie de bunătate în fiecare dintre angajații noștri și să facem lumea din jurul nostru un pic mai bună',
  social_responsibility_3: 'Activități educaționale / Sprijin pentru tineret',
  social_responsibility_4:
    'Initiativele noastre de implicare socială pentru copii și studenți',
  social_responsibility_5: 'Service Mania - jocul de afaceri',
  social_responsibility_6:
    'O parte importantă a activităților noastre de formare este jocul de afaceri Service Mania.',
  social_responsibility_7:
    'Service Mania te provoacă să gândești strategic despre cum să gestionezi situații neobișnuite',
  social_responsibility_8: 'Prezentare cercetare / Diiya Business',
  social_responsibility_9:
    'O perspectivă a afacerilor și a clienților asupra situației serviciilor: o prezentare a rezultatelor cercetării noastre 4Service de Victoria Skorbota, șeful de dezvoltare la 4Service Ucraina',
  social_responsibility_10: 'Sesiuni de Service Design',
  social_responsibility_11:
    'Metodologia Service Design este o parte importantă a 4Service. În urmă cu câțiva ani, am devenit partener al Academiei DesignThinkers și nu numai că folosim cele mai bune practici în munca noastră, ci și oferim în mod activ formare celor mai bune companii.',
  social_responsibility_12: 'Caritate / Voluntariat',
  social_responsibility_13:
    'Orfelinate, case de îngrijire pentru persoanele în vârstă și voluntariat pentru armata ucraineană. Cea mai emoționantă parte a activităților noastre',
  social_responsibility_14: '#AjutorUsorCuGrupul4Service',
  social_responsibility_15:
    'Lucrăm în parteneriat cu Fundația de Caritate Blagomai și încercăm să aducem bucurie și ajutor celor care au nevoie',
  social_responsibility_16:
    'Strângere de fonduri pentru orfelinate și case de bătrâni',
  social_responsibility_17:
    'Noi, cei de la 4Service, am inițiat mai multe strângeri de fonduri pentru casele de bătrâni și orfelinate pentru a cumpăra produse de primă necesitate',
  social_responsibility_18: '#SalvatiUcraina',
  social_responsibility_19:
    'Războiul a afectat mulți dintre angajații noștri și prietenii apropiați. Fiecare dintre noi ajută cât putem',
  social_responsibility_20: 'Bunăstarea animalelor',
  social_responsibility_21:
    'Oricine poate veni la biroul nostru cu animalul de companie. Ajutăm adăposturi, strângem fonduri pentru hrană și, uneori, căutăm și case pentru animalele fără adăpost',
  social_responsibility_22: 'Sprijinirea adaposturilor de animale',
  social_responsibility_23:
    'Animalele fără adăpost au nevoie de ajutor la fel de mult ca toți membrii vulnerabili ai societății noastre.',
  social_responsibility_24:
    'Compania noastră a luat în grija noastră adăpostul pentru animale fără stăpân Sirius (regiunea Kiev, Ucraina).',
  social_responsibility_25:
    'Inițiative creative pentru a ajuta animalele fără stăpân',
  social_responsibility_26:
    'Cum putem aduce problema urgentă a adăposturilor de animale în atenția clienților și partenerilor noștri?',
  social_responsibility_27: 'Birou care acceptă animale de companie',
  social_responsibility_28:
    'Mulți dintre membrii echipei noastre au animale de companie. Am creat un mediu sănătos în care toată lumea poate veni la birou cu animalele lor de companie și se poate simți confortabil',
  ux_testing_1: 'Testare UX',
  ux_testing_1_1: 'Testare UX',
  ux_testing_2:
    'O abordare unitară bazată pe interacțiune umană și inteligență artificială pentru îmbunătățirea conținutului și utilizării website-urilor sau aplicațiilor',
  ux_testing_3: 'Ce este testarea UX',
  ux_testing_4:
    'Testarea UX vă ajută să înțelegeți cum interacționează oamenii cu produsul, aplicația sau site-ul dvs',
  ux_testing_5: 'Navigarea nu este foarte clara',
  ux_testing_6: 'Habar n-am cum să fac o comandă',
  ux_testing_7: 'Nu sunt suficiente fotografii în catalog',
  ux_testing_8: 'Veți:',
  ux_testing_9: 'Descoperi oportunități de îmbunătățire',
  ux_testing_10: 'Identifica probleme în design și interacțiunea cu clienții',
  ux_testing_11:
    'Aflați ce dificultăți întâmpină utilizatorul când interacționează cu site-ul',
  ux_testing_12: 'Observați călătoria clientului',
  ux_testing_13: 'Recunoașteți când și de ce publicul țintă părăsește site-ul',
  ux_testing_14:
    'Definiți care secțiuni trezesc cel mai puțin și cel mai mare interes și emoție',
  ux_testing_15: 'Testare UX bazată pe utilizator',
  ux_testing_16:
    '4Service vă oferă informațiile umane reale de care aveți nevoie pentru a crea site-uri web și aplicații centrate pe client folosind metode calitative și cantitative',
  ux_testing_17: 'Nu inteleg cum sa folosesc site-ul. Unde este ascuns meniul?',
  ux_testing_18:
    'Cred că există prea mult spațiu între litere, textul este greu de citit',
  ux_testing_19: 'Imaginile se suprapun una peste alta. Așa ar trebui să fie?',
  ux_testing_20:
    'Testarea UX vă ajută să înțelegeți cum interacționează oamenii cu produsul, aplicația sau site-ul dvs',
  ux_testing_21: 'Design de cercetare de testare UX bazat pe utilizatori:',
  ux_testing_22: 'Descoperiți oportunități de îmbunătățire',
  ux_testing_23:
    'Determinarea tipului și metodei de testare (calitativă/cantitativă)',
  ux_testing_24: 'Elaborarea de ipoteze și scenarii de utilizator',
  ux_testing_25: 'Analiza și raportarea rezultatelor testelor',
  ux_testing_26: 'Două soluții',
  ux_testing_27:
    'Oferim două abordări pentru a testa cu succes experiența utilizatorului',
  ux_testing_28: 'Testare UX bazată pe utilizator',
  ux_testing_29: 'AI + testare UX bazată pe utilizator',
  ux_testing_30: 'Punctele noastre forte:',
  ux_testing_31: 'Putem analiza peisajul competitiv',
  ux_testing_32:
    'Putem aduce experți specializați pentru a efectua și participa la interviuri',
  ux_testing_33:
    'Putem efectua interviuri în diferite limbi și le putem traduce în limba necesară',
  ux_testing_34: 'cu utilitatea existentă',
  ux_testing_35: 'metrici sau standarde',
  ux_testing_36: 'performanța ta',
  ux_testing_37: 'împotriva unui concurent',
  ux_testing_38: 'versiuni',
  ux_testing_39: 'ale aceluiasi produs',
  ux_testing_40: 'Evaluare de satisfacție',
  ux_testing_41: 'NPS',
  ux_testing_42: 'Rate de succes',
  ux_testing_43: 'Rate de eroare',
  ux_testing_44: 'Timpul de finalizare a sarcinii',
  ux_testing_45: 'Testare UX bazată pe utilizator',
  ux_testing_46: 'Metoda cantitativă',
  ux_testing_47:
    'Folosim cel puțin 100 de persoane pentru a participa la testarea UX pentru această etapă',
  ux_testing_48:
    'Relevant atunci când aveți nevoie de o comparație a site-ului sau a aplicației dvs.:',
  ux_testing_49: 'Măsurarea metodei cantitative:',
  ux_testing_50: 'Observare',
  ux_testing_51:
    'Petrecerea timpului cu un utilizator sau grup de utilizatori și observarea comportamentului acestora cu produsul pe măsură ce îl folosesc în viața de zi cu zi',
  ux_testing_52: 'Interviu aprofundat',
  ux_testing_53:
    'Vă permit să aflați atitudinile, credințele, dorințele și experiențele utilizatorilor care vin pe site-ul dvs. Vă recomandăm să efectuați 15-20 de interviuri',
  ux_testing_54: 'Cercetare etnografică',
  ux_testing_55:
    'Realizat în mediul respondenților care vor folosi produsul. Prin observare, începi să ai o bună înțelegere a psihologiei utilizatorului, a provocărilor emoționale cu care se confruntă.',
  ux_testing_56: 'Testare UX bazată pe utilizator',
  ux_testing_57: 'Metoda calitativă',
  ux_testing_58:
    'Această metodă ajută la înțelegerea motivațiilor și a logicii comportamentului utilizatorilor',
  ux_testing_59: 'Folosim:',
  ux_testing_60:
    'Elaborăm o hartă a călătoriei clienților pentru a ilustra rezultatele cercetării noastre',
  ux_testing_61: '30 de participanți (bărbați/femei)',
  ux_testing_62:
    'Respondenții examinează în prealabil și îi invită să participe la sondaj',
  ux_testing_63: 'Interviu online de la distanță pe platforma Wantent',
  ux_testing_64: 'bazat pe AI + om',
  ux_testing_65: 'AI + testare UX bazată pe experiența umană',
  ux_testing_66:
    'O combinație de tehnologie unică de inteligență artificială Wantent și utilizatori reali',
  ux_testing_67: 'Fonturile mari din titlu au atras atenția',
  ux_testing_68:
    'Utilizatorii se uită la numerele «cu voce tare», dar nu citesc informațiile de lângă ele «г»',
  ux_testing_69:
    'Testarea UX vă ajută să înțelegeți cum interacționează oamenii cu produsul, aplicația sau site-ul dvs',
  ux_testing_70: 'Design de cercetare pentru testare AI + experiență umană',
  ux_testing_71: 'Cum funcționează',
  ux_testing_72:
    'Wantent este o soluție AI bazată pe potrivirea conținutului pe piață',
  ux_testing_73:
    'Wantent estimează eficiența site-urilor web și a aplicațiilor prin aplicarea tehnologiilor ML pentru a analiza reacțiile emoționale și implicarea publicului.',
  ux_testing_74:
    'Pentru a interpreta rezultatele, folosim tehnici de povestire, diversitate și empatie, pentru a stimula performanța și creșterea.',
  ux_testing_75:
    'Atenția și emoțiile utilizatorului în timpul tranzițiilor prin secțiuni',
  ux_testing_76:
    'Statistici pe grupul de participanți (atenție și concentrare pe tot parcursul sesiunii)',
  ux_testing_77: 'Analiza conversiilor paginilor',
  ux_testing_78: 'Atenție și emoții în timpul îndeplinirii sarcinilor',
  ux_testing_79: 'Constatări asupra percepției',
  ux_testing_80: 'ale website-ului de către diferite grupuri de participanți',
  ux_testing_81: 'Analiza ușurinței',
  ux_testing_82:
    'a navigării prin pagini și ușurința găsirii elementelor importante',
  ux_testing_83: 'O analiză a percepției',
  ux_testing_84: 'a informațiilor furnizate pe site',
  ux_testing_85: 'Domenii de definire',
  ux_testing_86:
    'de îmbunătățire în cadrul paginilor individuale (navigație, blocuri de informații etc.)',
  ux_testing_87: 'Analizăm în detaliu',
  ux_testing_88: 'Rezultatele cheie ale cercetării',
  ux_testing_89:
    'Recomandări pentru îmbunătățirea structurii site-ului web și a paginilor individuale',
  ux_testing_90:
    'Selectăm cu atenție publicul țintă pentru testarea utilizabilității.',
  ux_testing_91:
    'Efectuăm cercetări în diferite țări, limbi diferite și între diferite grupuri de utilizatori',
  ux_testing_92: 'Putem testa în aplicații',
  ux_testing_93:
    'și pe site-urile web ale concurenților și oferim analize comparative',
  ux_testing_94: 'Combinăm diferite metode de cercetare.',
  ux_testing_95:
    'Moderatorii noștri calificați îndrumă participantul prin procesul de testare.',
  ux_testing_96: 'Facem teste',
  ux_testing_97: 'pe o varietate de dispozitive și versiuni de software',
  ux_testing_98: 'De ce sa ne alegeți pe noi?',
  wantent_1: '4Service x',
  wantent_2: 'Wantent',
  wantent_3:
    'Un proiect partener unic de la 4Service and Wantent © O abordare în care este combinată inteligența artificială și experiența umană pentru o analiză eficientă a conținutului media',
  wantent_4: 'măsurarea succesului înainte ca conținutul să fie difuzat',
  wantent_5: 'atenuarea riscurilor problemelor legate de diversitate',
  wantent_6:
    'perspective profunde și recomandări pentru îmbunătățirea conținutului',
  wantent_7: 'decizii obiective privind conținutul și strategia de marketing',
  wantent_8:
    'Wantent este o soluție bazată pe inteligență artificială (AI) pentru potrivirea conținutului pe piață',
  wantent_9:
    'Wantent este o soluție bazată pe inteligența artificială (AI) pentru potrivirea conținutului pe piață. Wantent evaluează eficacitatea conținutului video prin aplicarea tehnologiilor de învățare automată (ML) pentru a analiza răspunsul emoțional și angajamentul publicului',
  wantent_10:
    'Wantent ajută la definirea potrivirii conținutului pe piață și la maximizarea eficienței conținutului prin:',
  wantent_11: 'Configurarea scenariului și recrutarea participanților',
  wantent_12: 'proiecte personalizate și public țintă la nivel mondial',
  wantent_13: 'Colectarea reacțiilor spectatorilor',
  wantent_14: 'feedback detaliat și clar din partea publicului',
  wantent_15: 'Analiza percepției conținutului',
  wantent_16: 'analiza comportamentului și a reacțiilor emoționale prin AI',
  wantent_17: 'Livrarea de informații pentru încheierea finală',
  wantent_18: 'recomandări pentru îmbunătățiri',
  wantent_19: 'Wantent maximizează eficiența conținutului dvs',
  wantent_20: 'Testare preliminară a conceptului publicitar',
  wantent_21: 'Testarea conceptului creativ',
  wantent_22:
    'Verificarea coerenței viitoarei reclame cu strategia dezvoltată și înțelegerea reacțiilor publicului țintă pentru posibile ajustări ale conceptelor/mesajelor creative',
  wantent_23: 'Testarea vizibilității elementelor mărcii (post-producție)',
  wantent_24:
    'Confirmarea eficacitatii investitiei in publicitate/sponsorizare',
  wantent_25: 'Testarea reclamelor',
  wantent_26: 'Testare A/B',
  wantent_27:
    'Compararea eficacității reclamelor și creșterea angajamentului și a atenției în timpul fazei de producție',
  wantent_28: 'S-a lansat testarea reclamelor',
  wantent_29:
    'Definirea performantei mesajelor si crearea de recomandari pentru imbunatatirea audio si vizuala',
  wantent_30: 'Solutii pentru TV',
  wantent_31: 'Piloți, promoții, noi formate, testare prezentatori TV',
  wantent_32:
    'Efectuarea unei analize comparative pentru a o identifica pe cea mai captivantă și pentru a o consolida',
  wantent_33: 'Testare de emisiuni TV și lungmetraje',
  wantent_34:
    'Analizarea implicării telespectatorilor, a nivelului de atenție, a răspunsului emoțional în timpul vizionării conținutului lung în condiții naturale. Găsim automat varful culminant al audienței și momentele de distragere a atenției pentru a evalua și îmbunătăți montarea video',
  wantent_35: 'Testare UI/UX',
  wantent_36: 'Prototipuri de site-uri web și testare de aplicații mobile',
  wantent_37:
    'Analiza reacției și emoțiilor utilizatorului folosind tehnologia Wantent:',
  wantent_38: 'nivelul de atentie',
  wantent_39: 'reacții emoționale',
  wantent_40: 'hărți termice ale direcției privirii participanților',
  wantent_41:
    'Analiza de utilizare a site-ului și a aplicației pe baza feedback-ului participanților:',
  wantent_42: 'Scala de utilizare a sistemului (SUS)',
  wantent_43: 'Scorul de efort al clientului (ușurință de interacțiune)',
  wantent_44: 'Scor Net de Promovare (NPS)',
  wantent_45: 'Conformitatea GDPR și confidențialitatea utilizatorilor',
  wantent_46:
    'Wantent respectă pe deplin politicile GDPR și CCPA și respectă principiile de colectare, stocare, prelucrare și protecție a datelor cu caracter personal ale participanților.',
  wantent_47: 'Nikita Lobyntsev',
  wantent_48: 'СDO, Reface (media și divertisment)',
  wantent_49:
    'Noi experimentăm zilnic cu tone de idei noi de conținut, folosind tehnologii de învățare automată. Wantent ne ajută să înțelegem și să evaluăm riscurile și avantajele, să descoperim feedback-ul, comportamentul și percepția utilizatorilor pentru a obține o cauzalitate a diferitelor aspecte ale soluțiilor noastre.',
  wantent_50: 'Alexander Smirnov',
  wantent_51: 'coproprietar TABASCO (Publicitate)',
  wantent_52:
    'Wantent oferă un serviciu cu adevărat unic care ne ajută să cunoaștem detalii minore despre ceea ce le place sau nu le place consumatorilor. Nu există nicio modalitate de a înșela, nu există nicio modalitate de a  minți - Wantent știe întotdeauna adevărul, iar noi, ca experți în marketing, primim ghiduri neprețuite (hahaha - știm prețul exact și este accesibil) despre cum să îmbunătățim comunicările noastre.',
  wantent_53: 'Recenzii',
  terms_1: 'Termeni si conditii',
  terms_2: 'Acordul privind termenii de utilizare',
  terms_3:
    'Vă rugăm să citiți cu atenție acești Termeni de utilizare („termeni de utilizare”, „acord”) înainte de a utiliza site-ul web',
  terms_4:
    '(«site-ul web») operat de 4Service Holdings GmbH («4Service», «noi», «noi», «nostru»).  «c»',
  terms_5:
    'Condițiile de utilizarePrin utilizarea acestui site web, certificați că ați citit și revizuit acest Acord și că sunteți de acord să respectați termenii săi. Dacă nu doriți să fiți obligat de termenii acestui Acord, vă sfătuim să părăsiți site-ul corespunzător. 4Service acordă doar utilizarea și accesul la acest site web, produsele sale și serviciile sale celor care au acceptat termenii săi.',
  terms_6: 'Politica de confidențialitate',
  terms_7:
    'Înainte de a continua să utilizați site-ul nostru, vă sfătuim să citiți Politica noastră de confidențialitate cu privire la colectarea datelor noastre despre utilizatori. Vă va ajuta să înțelegeți mai bine practicile noastre.',
  terms_8: 'Restricție de vârstă',
  terms_9:
    'Trebuie să aveți cel puțin 16 (șaisprezece) ani pentru a utiliza acest site web. Prin utilizarea acestui site web, garantați că aveți cel puțin 16 (șaisprezece) ani și că puteți respecta legal acest Acord. 4Service nu își asumă responsabilitatea pentru obligațiile legate de reprezentarea eronată a vârstei.',
  terms_10: 'Proprietate intelectuală',
  terms_11:
    'Sunteți de acord că toate materialele, produsele și serviciile furnizate pe acest site web sunt proprietatea 4Service, a afiliaților, directorilor, ofițerilor, angajaților, agenților, furnizorii sau licențiatorii săi, inclusiv toate drepturile de autor, secretele comerciale, mărcile comerciale, brevetele și alte proprietăți intelectuale. . De asemenea, sunteți de acord că nu veți reproduce sau redistribui proprietatea intelectuală a 4Service în niciun fel, inclusiv înregistrări electronice, digitale sau noi mărci comerciale.',
  terms_12: 'Accesarea site-ului nostru',
  terms_13:
    'Accesul la Site-ul nostru este permis temporar și ne rezervăm dreptul de a retrage sau de a modifica serviciul pe care îl oferim pe site-ul nostru fără notificare. Nu vom fi răspunzători dacă, din orice motiv, site-ul nostru este indisponibil în orice moment sau pentru orice perioadă. Din când în când, este posibil să restricționăm accesul la unele părți ale site-ului nostru, sau la întregul nostru site, utilizatorilor care s-au înregistrat la noi. Sunteți responsabil să faceți toate aranjamentele necesare pentru a avea acces la site-ul nostru, inclusiv utilizarea echipamentelor care sunt compatibile cu site-ul nostru. De asemenea, sunteți responsabil pentru a vă asigura că toate persoanele care accesează site-ul nostru prin conexiunea dumneavoastră la internet sunt la curent cu acești termeni și că îi respectă.',
  terms_14: 'Lege aplicabilă',
  terms_15:
    'Vizitând acest site web, sunteți de acord că legile Republicii Austria, fără a ține cont de principiile legilor conflictuale, vor guverna acești termeni și condiții sau orice dispută de orice fel care ar putea apărea între 4Service și dumneavoastră.',
  terms_16: 'Conflicte',
  terms_17:
    'Orice dispută legată în orice mod de vizita dumneavoastră pe acest site web va fi arbitrată de Curtea Comercială din Viena.',
  terms_18: 'Despăgubiri',
  terms_19:
    'Sunteți de acord să despăgubiți 4Service și afiliații săi și să lăsați 4Service inofensiv față de pretențiile legale și cererile care pot apărea din utilizarea sau utilizarea necorespunzătoare a site-ului nostru. Ne rezervăm dreptul de a ne alege propriul consilier juridic.',
  terms_20: 'Perioada de depozitare',
  terms_21:
    'Ținând cont de scopurile prelucrării, perioada de stocare a datelor cu caracter personal ale Utilizatorilor (perioada de stocare) este de 24 de luni de la data la care consimțământul pentru prelucrarea datelor este obținut în mod corespunzător de la Dvs.',
  terms_22: 'Limitarea răspunderii',
  terms_23:
    '4Service nu este responsabil pentru nicio dauna care vi se poate produce ca urmare a utilizării greșite a site-ului nostru. 4Service își rezervă dreptul, fără notificare, de a edita, modifica și schimba în orice moment acest Acord prin actualizarea acestei postări. Utilizarea în continuare a site-ului web acum sau în urma postării oricăror modificări sau schimbari, va indica acceptarea de către dumneavoastră a acestor modificări sau schimbari. Dacă orice parte a acestui acord este declarată ilegală, nulă sau inaplicabilă, acea parte va fi considerată separabilă și nu va afecta valabilitatea și aplicabilitatea oricăror prevederi rămase. Eventualele dovezi ale utilizării site-ului web în scopuri ilegale vor fi furnizate autorităților de aplicare a legii. Acest acord este o înțelegere între 4Service și utilizator.',
  terms_24:
    'Vă rugăm să trimiteți toate întrebările și preocupările legate de confidențialitate/utilizare la următoarea adresă:',
  terms_25: '4Service Holdings GmbH',
  terms_26: 'Tegetthoffstrasse 7',
  terms_27: '1010 Viena',
  terms_28: 'Austria',
  terms_29: 'Revizuire',
  terms_30:
    'Atunci când datele personale pe care ni le furnizați nu sunt inexacte, aveți dreptul să ne cereți să le corectăm într-adevăr (articolul 16 GDPR).',
  thank_you_1: 'Mulțumesc!',
  thank_you_2: 'Vă vom contacta în termen de 24 de ore',
  thank_you_3:
    'P.S. Managerii noștri examinează deja solicitarea dvs. pentru a crea o ofertă mai bună',
  thank_you_4: 'Meniu',
  thank_you_5: 'Femeie tastand',
  privacy_policy_1:
    'Formular de notificare privind confidențialitatea datelor pentru persoanele vizate (utilizatorii site-ului web)',
  privacy_policy_2:
    'Acest formular de notificare privind confidențialitatea (Notificare de confidențialitate) este destinat persoanelor fizice (persoanele vizate) ale căror date cu caracter personal sunt colectate de 4Service Holdings GmbH, în conformitate cu cerințele GDPR în cadrele de vizitare a site-ului web',
  privacy_policy_3:
    '(de aici după site-ul web și, respectiv, utilizatorii site-ului web/utilizatorii).',
  privacy_policy_4:
    'Formularul de notificare privind confidențialitatea pentru persoanele vizate (utilizatorii site-ului web) ai 4Service Holdings GmbH este aplicat în toate companiile grupului 4Service, care se află sub autoritatea legală, sub supravegherea sau controlată de 4Service Holdings GmbH.',
  privacy_policy_5: 'Contacte',
  privacy_policy_6: '4Service Holdings GmbH',
  privacy_policy_7: '(Companie/noi/noi),',
  privacy_policy_8: 'Adresă:',
  privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_10: 'E-mail:',
  privacy_policy_12: 'Cine suntem noi?',
  privacy_policy_13:
    'O persoană fizică (Dvs.) care vizitează site-ul web cu orice scop. În scopul prezentului Document, veți fi indicat și ca Utilizator.',
  privacy_policy_14: 'Datele personale pe care le colectăm de la dumneavoastră',
  privacy_policy_15:
    'Când intrați pe site-ul nostru web, vă trimitem o notificare de confidențialitate, în care ne oferim să vă cerem să ne furnizați consimțământul pentru prelucrarea următoarelor informații:',
  privacy_policy_16:
    'Adresă IP, numele utilizatorului, prenume, adresă, număr de telefon (fix sau mobil), adresă de e-mail, adresă, denumire companie, țară, adresă de e-mail, nume, prenume, număr de telefon, provincie, stat și cod poștal/ Cod poștal, date de utilizare, date despre interacțiunea cu rețelele sau platformele sociale externe, informații despre înregistrare și autentificare pe site',
  privacy_policy_17: 'pozitia geografica.',
  privacy_policy_18:
    'În cazul în care vă exprimați voința și ne dați un astfel de consimțământ, începem să procesăm astfel de informații de la dvs.',
  privacy_policy_19: 'Legalitate (consimțământ)',
  privacy_policy_20:
    'Când accesați site-ul nostru web, vă trimitem o notificare de confidențialitate în care vă cerem să ne oferiți consimțământul pentru prelucrarea următoarelor informații:',
  privacy_policy_21:
    'Temeiul legal pentru prelucrarea datelor cu caracter personal este consimțământul, pe care îl vom primi de la dvs. prin completarea formularului de consimțământ de la următorul link:',
  privacy_policy_22:
    'Dacă completați formularul de consimțământ, aceasta înseamnă că înțelegeți și acceptați toate condițiile specificate în această Notificare de confidențialitate.',
  privacy_policy_23: 'Retragerea consimțământului',
  privacy_policy_24:
    'Aveți dreptul de a retrage în orice moment consimțământul pe care ni l-ați furnizat anterior. Retragerea consimțământului nu va afecta legalitatea prelucrării bazate pe consimțământ înainte de retragerea acestuia. Vă puteți retrage consimțământul trimițându-ne cererea corespunzătoare la următoarea adresă de e-mail withdraw@4service-group.com, prin prezenta, formularul de solicitare este disponibil la următorul link:',
  privacy_policy_25: 'Formular de retragere pentru utilizator',
  privacy_policy_26: 'Scopurile prelucrarii',
  privacy_policy_27:
    'Prelucrăm datele dumneavoastră cu caracter personal în următoarele scopuri:',
  privacy_policy_28:
    '– îmbunătățirea serviciilor clienților (permite un răspuns mai eficient la solicitările clienților); – personalizarea experienței Utilizatorilor (permite să se determine cine este mai interesant în servicii); – îmbunătățirea Site-ului (permite îmbunătățirea calității produselor și serviciilor, confortul utilizării acestora, dezvoltarea de noi Servicii, îmbunătățirea produselor și serviciilor noastre); – comunicați cu Utilizatorul cu buletine informative, materiale de marketing sau promoționale și alte informații care includ știri, actualizări, informații despre servicii, cu observația privind instrucțiunile despre cum să refuzați primirea de e-mailuri ulterioare; – efectuarea de cercetări și analize statistice și de altă natură pe baza datelor anonimizate; – furnizarea de servicii personalizate Utilizatorului și îndeplinirea acordurilor și contractelor; – participarea Utilizatorului la diverse proiecte implementate de noi prin intermediul Site-ului, răspunsul la întrebările adresate de Utilizator prin intermediul Site-ului, cercetarea, întreținerea conturilor și înregistrărilor și promovarea serviciilor.',
  privacy_policy_29: 'Subiecții cărora le pot fi transferate datele personale',
  privacy_policy_30:
    'În timpul procesării datelor dumneavoastră cu caracter personal, transferăm datele dumneavoastră cu caracter personal către entitățile care acționează ca procesatori ai Companiei. Procesatorii Companiei acționează exclusiv pe baza instrucțiunilor Companiei. Specificul acțiunilor pe care procesatorii Companiei le desfășoară și lista acestor procesatori pot fi găsite în Politica de confidențialitate și protecția datelor la următorul link: Politica de confidențialitate și protecție a datelor',
  privacy_policy_31: 'Țările în care datele personale pot fi transferate',
  privacy_policy_32:
    'Compania transferă datele dumneavoastră cu caracter personal pe baza unei decizii de adecvare, astfel cum este stipulată de GDPR și Comisia UE. Informații suplimentare privind transferul de date cu caracter personal către SUA pot fi găsite în Politica de confidențialitate și protecția datelor la următorul link: Politica de confidențialitate și protecție a datelor.',
  privacy_policy_33: 'Perioada de depozitare',
  privacy_policy_34:
    'Ținând cont de scopurile prelucrării, perioada de stocare a datelor cu caracter personal ale Utilizatorilor (perioada de stocare) este de 24 de luni de la data la care consimțământul pentru prelucrarea datelor este obținut în mod corespunzător de la Dvs.',
  privacy_policy_35: 'Dreptul de acces',
  privacy_policy_36:
    'Aveți dreptul de a ști dacă datele cu caracter personal care vă privesc sunt în curs de prelucrare și 2) dacă da, accesați aceste date cu o mulțime de prevederi suplimentare menționate în articolul 15 GDPR.',
  privacy_policy_37: 'Dreptul la rectificare',
  privacy_policy_38:
    'Atunci când datele personale pe care ni le furnizați nu sunt inexacte, aveți dreptul să ne cereți să le corectăm într-adevăr (articolul 16 GDPR).',
  privacy_policy_39: 'Dreptul la ștergere (dreptul de a fi uitat)',
  privacy_policy_40:
    'Aveți dreptul de a obține de la noi ștergerea datelor dumneavoastră cu caracter personal fără întârzieri nejustificate și vom avea obligația de a vă șterge datele cu caracter personal fără întârzieri nejustificate, în cazul în care se aplică motivele menționate în articolul 17 din GDPR.',
  privacy_policy_41: 'Dreptul la restricționarea prelucrării',
  privacy_policy_42:
    'Aveți dreptul de a limita prelucrarea datelor dumneavoastră cu caracter personal, cu mai multe excepții în domeniul de aplicare al GDPR, în special menționat în articolul 18 din GDPR.',
  privacy_policy_43:
    'Suntem obligați să vă informăm ce date sunt colectate, cum sunt utilizate, cât timp vor fi păstrate și dacă vor fi partajate cu terți. Aceste informații trebuie comunicate concis și într-un limbaj simplu.',
  privacy_policy_44: 'Dreptul la portabilitatea datelor',
  privacy_policy_45:
    'Vi se permite să obțineți și să reutilizați datele dumneavoastră cu caracter personal în propriile scopuri prin diferite servicii.',
  privacy_policy_46: 'Dreptul de a obiecta',
  privacy_policy_47:
    'Aveți dreptul de a vă opune prelucrării datelor cu caracter personal care sunt prelucrate de către Companie. Trebuie să încetăm prelucrarea datelor cu caracter personal cu excepția cazului în care demonstrăm temeiuri legitime convingătoare pentru prelucrare care prevalează asupra intereselor, drepturilor și libertăților persoanei sau dacă prelucrarea este pentru stabilirea sau exercitarea apărării unor pretenții legale.',
  privacy_policy_48:
    'Dreptul de a nu fi supus unei decizii bazate exclusiv pe prelucrare automată',
  privacy_policy_49:
    'Aveți dreptul de a vă opune oricărei profilări automate care are loc fără consimțământ. Prin prezenta, aveți dreptul ca datele dumneavoastră cu caracter personal să fie prelucrate cu implicarea umană.',
  privacy_policy_50: 'Reclamații',
  privacy_policy_51:
    'În cazul în care doriți să depuneți o plângere cu privire la modul în care datele dumneavoastră cu caracter personal sunt prelucrate de către Companie (sau de către procesatori, descrise mai sus) sau despre modul în care reclamația dumneavoastră a fost tratată, aveți dreptul de a depune o plângere direct la autoritatea de supraveghere și Companie.',
  privacy_policy_52: 'Detaliile pentru fiecare dintre aceste contacte sunt:',
  privacy_policy_53: 'Autoritatea de supraveghere:',
  privacy_policy_54: 'Autoritatea austriacă pentru protecția datelor',
  privacy_policy_55: 'Österreichische Datenschutzbehörde',
  privacy_policy_56: 'Wickenburggasse 8',
  privacy_policy_57: '1080 Viena',
  privacy_policy_58: 'Austria / Europa',
  privacy_policy_59: 'Companie:',
  privacy_policy_60: '4Service Holdings GmbH (Companie/noi/noi),',
  privacy_policy_61: 'Address: Tegetthoffstraße 7, 1010 Vienna, Austria.',
  privacy_policy_62: 'E-mail:',
  privacy_policy_63: 'Politica de confidențialitate și protecție a datelor',
  privacy_policy_64:
    'Citiți mai multe despre cum și de ce vă folosim datele aici: Politica de confidențialitate și protecție a datelor',
  privacy_policy_65: 'Aprobare',
  privacy_policy_66:
    'Compania a elaborat toate documentele interne pentru a defini rolurile în rândul personalului cu privire la prelucrarea datelor cu caracter personal în cadrul Companiei, în special responsabil pentru aprobarea și revizuirea legitimității acestui document este Directorul General.',
  contacts_1: 'Pentru clienti',
  contacts_2:
    'Pentru toate tipurile de afaceri, agenții de cercetare de piață și companii mari, parteneri de afaceri și mass-media.',
  contacts_3: 'Str. Lt.-col. Dumitru Papazoglu 96, București 031205',
  contacts_4: 'Pentru Cumpărători',
  contacts_5:
    'Localnici și călători, șoferi și studenți, cumpărători misterioși profesioniști și oameni de casă.',
  contacts_6: 'Contacteaza-ne din tara ta:',
  contacts_7: 'Italia',
  contacts_8: 'STATELE UNITE ALE AMERICII',
  contacts_9: 'Regatul Unit',
  contacts_10: 'Elveţia',
  contacts_11: 'Olanda',
  contacts_12: 'Slovacia',
  contacts_13: 'Slovenia',
  contacts_14: 'România',
  contacts_15: 'AZ',
  contacts_16: 'Ucraina',
  contacts_17: 'Kazahstan',
  contacts_18: 'Restul lumii',
  menu_1: 'CJM',
  menu_2: 'NPS',
  menu_3: 'CSI',
  menu_4: 'BHT',
  menu_5: 'Testare UX',
  menu_6: 'OR',
  menu_7: 'Cercetare calitativa',
  menu_8: 'Cercetare cantitativă',
  menu_9: 'Cumpărături misterioase',
  menu_10: 'voicer',
  menu_11: 'Play4Sales',
  menu_12: 'Wantent',
  menu_13: 'Monitorizarea prețurilor',
  menu_14: 'Despre noi',
  menu_15: 'Contacte',
  menu_16: 'Blog',
  menu_17: 'Metode',
  menu_18: 'Produse',
  menu_19: 'Shoppers \n platform',
  menu_20: 'CSR',
  nps_passives: 'Pasivii',
  nps_detractors: 'Detractorii',
  nps_promoters: 'Promotorii',
  nps_a_score: 'un scor',
  area: 'Zonă',
  products_tabs_1:
    'Pentru a obține o eficiență maximă, desfășurăm și în complex:',
  products_tabs_2: 'Produse',
  products_tabs_3:
    'Cercetarea calitativă este colectarea și analizarea datelor nenumerice pentru a înțelege concepte, opinii sau experiențe',
  products_tabs_4:
    'Metodele de cercetare cantitativă reprezintă o măsurare obiectivă și o analiză numerică a datelor colectate prin sondaje, chestionare și anchete.',
  our_clients_1: 'Clienții noștri',
  preview_cases_section_1: 'Cazuri',
  preview_cases_section_2:
    'Accesați cazurile pentru a citi mai multe postări interesante',
  blog_1: 'Blog cu conținut emoționant',
  blog_2: 'Nu există postări disponibile pentru cererea dvs.',
  about: 'Despre',
  why_us: 'De ce noi',
  about_us: 'Despre noi',
  key_features: 'Caracteristici cheie',
  solutions: 'Soluții',
  monitoring: 'Monitorizare',
  how: 'Cum',
  how_it_works: 'Cum funcționează',
  when_to_use: 'Când să utilizați',
  mystery_shopping: 'Mystery shopping',
  voicer: 'Vocea clientului',
  wantent: 'Testarea conținutului video',
  play4sales: 'Play4Sales',
  price_monitoring: 'Monitorizarea prețurilor',
  service_design: 'Design de servicii',
  qualitative_research: 'Cercetare calitativa',
  quantitative_research: 'Cercetare cantitativă',
  cawi: 'CAWI',
  f2f: 'F2F',
  cati: 'CATI',
  hashtag_automotive: '#Automobile',
  hashtag_retayl: '#Cu amănuntul',
  hashtag_all_posts: '#Toate_postările',
  hashtag_feedback: '#părere',
  hashtag_4Service_csr: '#4Service_CSR',
  hashtag_cases: '#cazuri',
  hashtag_market_research: '#piata_cercetare',
  more: 'Mai mult',
  speak_to_an_expert: 'Vorbește cu un expert',
  book_consultancy: 'Solicită o sesiune personalizată!',
  read_all: 'Citeste tot',
  find_out_more: 'Află mai multe',
  address: 'Adresă',
  mon_fri: 'Luni-Vineri',
  phone_number: 'Număr de telefon',
  flag: 'steag',
  scroll_to_left: 'derulați la stânga',
  online: 'online',
  tel: 'Tel',
  email: 'E-mail',
  light_it_up: 'Activează-l!',
  about_us_60: 'Alina Andreieva',
  about_us_61: 'Director de dezvoltare a afacerii',
  about_us_62: 'Julia Kravchenko',
  about_us_63: 'Manager senior de proiecte',
  about_us_64: 'Olga Aksonova',
  about_us_65: 'Manager Dezvoltare Afaceri',
  about_us_66: 'Zhelevskiy Dmitriy',
  about_us_67: 'Director de operatiuni',
  about_us_roles_1: 'management',
  about_us_roles_2: 'Echipa globală',
  about_us_roles_3: 'Proprietari de produse',
  ad_testing_1: 'Testare campanie publicitara',
  ad_testing_2:
    'O campanie de publicitate este o investiție uriașă. Asigurați-vă că campaniile dvs. publicitare sunt puternice și oferă rentabilitate maximă a investiției',
  ad_testing_3: 'Lacăt',
  ad_testing_4: 'Industriile în care activăm',
  ad_testing_5:
    '4Service este un holding global de cercetare privind experiența clienților',
  ad_testing_6:
    '4Service este cercetare globală de marketing și holding CX. Oferim servicii de colectare de date expert pentru o mare varietate de afaceri',
  ad_testing_7: 'Cash & Carry',
  ad_testing_8: 'Cu amănuntul',
  ad_testing_9: 'Electronice',
  ad_testing_10: 'Lux/Premium',
  ad_testing_11: 'Restaurante și ospitalitate',
  ad_testing_12: 'Automobile',
  ad_testing_13: 'Călătorii și agrement',
  ad_testing_14: 'Modă',
  ad_testing_15: 'Bănci',
  ad_testing_16: 'Ceasuri și bijuterii',
  ad_testing_17: 'Uz casnic și electrocasnice',
  ad_testing_18: 'Membru Esomar',
  ad_testing_19:
    'Am efectuat cercetări de testare a reclamelor cu privire la publicitate pentru diverse companii din diferite domenii',
  ad_testing_20: 'Aveți nevoie de testare campanie publicitară dacă sunteți',
  ad_testing_21: 'Promovarea unui nou produs sau serviciu',
  ad_testing_22: 'Atingerea unui subiect sensibil',
  ad_testing_23: 'Încercarea de a atinge o nouă țintă demografică sau piață',
  ad_testing_24: 'Discutarea mai multor opțiuni de design diferite',
  ad_testing_25:
    'Aveți nevoie de o dovadă de concept pentru echipele de management sau investitori',
  ad_testing_26: 'Veți primi răspunsuri la întrebări',
  ad_testing_27:
    'Ce canale de publicitate sunt cele mai potrivite pentru aceste reclame?',
  ad_testing_28: 'Folosim imaginile potrivite pentru a transmite mesajul?',
  ad_testing_29: 'Textele și mesajele dvs. publicitare rezonează?',
  ad_testing_30:
    'Ce canale de publicitate sunt cele mai potrivite pentru aceste reclame?',
  ad_testing_31: 'Ce le atrage atenția în primul rând?',
  ad_testing_32: 'Este ușor de înțeles plasarea și aspectul anunțului?',
  ad_testing_33: 'Evaluăm',
  ad_testing_34: 'Branding',
  ad_testing_35:
    'modul în care marca dvs. este legată de publicitate și cât de mult va fi retinuta',
  ad_testing_36: 'Valori cheie',
  ad_testing_37: 'Integrare',
  ad_testing_38: 'Recunoaştere',
  ad_testing_39: 'Potrivi',
  ad_testing_40: 'Creativ',
  ad_testing_41:
    'Cum iese în evidență reclama ta și cum atrage atenția oamenilor',
  ad_testing_42: 'Valori cheie',
  ad_testing_43: 'RECUNOAŞTERE',
  ad_testing_44: 'AFINITATE',
  ad_testing_45: 'MTO',
  ad_testing_46: 'MOTIVAȚIE',
  ad_testing_47: 'Experiența clientului, implicare emoțională',
  ad_testing_48:
    'Cu ajutorul tehnologiei de inteligență artificială și a analiștilor experți, analizăm emoțiile reale ale oamenilor și obținem informații despre percepțiile lor despre publicitate',
  ad_testing_49: 'Evaluare',
  ad_testing_50: 'Masculin',
  ad_testing_51: 'Feminin',
  ad_testing_52: 'Implicare mare',
  ad_testing_53: 'Atenţie',
  ad_testing_54: 'Ce i-a plăcut publicului și și-a amintit?',
  ad_testing_55: 'Actriţă',
  ad_testing_56: 'Egalitate',
  ad_testing_57: 'Fundal muzical',
  ad_testing_58: 'Emoții pozitive',
  ad_testing_59: 'Oameni de diferite rase și aspect',
  ad_testing_60: 'Fotografii în aer liber',
  ad_testing_61: 'Ce NU i-a plăcut publicului și și-a amintit?',
  ad_testing_62:
    'Vocea vorbitorului nu se potrivea cu ceea ce se întâmpla pe ecran',
  ad_testing_63: 'Atenție redusă la produs',
  ad_testing_64: 'Prea dinamic',
  ad_testing_65: 'Greu de înțeles',
  ad_testing_66: 'TRP',
  ad_testing_67: 'Impresii',
  ad_testing_68: 'Spectacole',
  ad_testing_69: 'Conversie',
  ad_testing_70: 'Mass-media',
  ad_testing_71:
    'Măsurăm eficiența unei campanii de publicitate în legătură cu activitatea media, calculând impactul investițiilor media pe diferite canale asupra rezultatelor monitorizate.',
  ad_testing_72:
    'Efectuăm cercetări de publicitate în orice format, platformă sau canal',
  ad_testing_73: 'conținut video',
  ad_testing_74: 'în aer liber',
  ad_testing_75: 'reclame TV',
  ad_testing_76: 'imprimare',
  ad_testing_77: 'digital',
  ad_testing_78: 'campanii publicitare',
  ad_testing_79: 'Afișare POS și publicitate',
  ad_testing_80: 'animatie',
  ad_testing_81:
    'Cu ajutorul nostru, vă puteți testa campania de publicitate în orice etapă',
  ad_testing_82: 'Concept',
  ad_testing_83: 'care idei au o capacitate mai puternică?',
  ad_testing_84: 'Producție în stadiu incipient',
  ad_testing_85: 'Cum pot fi produse animațiile previzualizate?',
  ad_testing_86: 'Productie',
  ad_testing_87: 'cum să editez un videoclip astfel încât să rețină atenția?',
  ad_testing_88: 'Pre-media',
  ad_testing_89:
    'ce canale de comunicare vor funcționa și ce rezultat se poate aștepta?',
  ad_testing_90: 'Post-testarea campaniei publicitare',
  ad_testing_91: 'Productie',
  ad_testing_92:
    'este reclama mea captivantă? Eficiența creativității scade în timp?',
  ad_testing_93: 'Pre-media',
  ad_testing_94:
    'cât de eficientă este campania mea publicitara în cifre? cum se compara cu concurenta?',
  ad_testing_95: 'Testare prealabilă a campaniei publicitare',
  ad_testing_96: 'Pentru a vă furniza date de înaltă calitate, utilizăm',
  ad_testing_97: 'Metodologia cercetării cantitative',
  ad_testing_98: 'CAWI',
  ad_testing_99: 'Metodologia cercetării calitative',
  ad_testing_100: 'Focus grup',
  ad_testing_101: 'AI',
  ad_testing_102: 'Wantent',
  ad_testing_103: 'Rapoartele analitice pe care le primiți',
  ad_testing_104:
    'Echipa noastră de analiști, experți media, marketeri vă va pregăti și vă va prezenta un raport personalizat cu recomandări detaliate',
  ad_testing_age: 'Vârstă',
  ad_testing_ad: 'Campanie publicitara',
  automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
  automotive_industry_price_analysis_2:
    'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
  automotive_industry_price_analysis_3: 'Request a quote',
  automotive_industry_price_analysis_4: 'Our methodology',
  automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
  automotive_industry_price_analysis_6:
    'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
  automotive_industry_price_analysis_7:
    'We offer precise figures and dependable multilateral data',
  automotive_industry_price_analysis_8:
    'We have global coverage spanning every country in the world',
  automotive_industry_price_analysis_9:
    'Our in-house resources include mystery shoppers and auditors',
  automotive_industry_price_analysis_10: 'We validate the quality of our data',
  automotive_industry_price_analysis_11: 'Our advantages:',
  automotive_industry_price_analysis_12:
    'Price monitoring for specific competitive models (B2B and B2C)',
  automotive_industry_price_analysis_13: 'Monitoring the level of discount',
  automotive_industry_price_analysis_14:
    'Monitoring retailer stock for your brand and competitors',
  automotive_industry_price_analysis_15:
    'Monitoring e-commerce automotive prices',
  automotive_industry_price_analysis_16: 'Price communication analysis',
  automotive_industry_price_analysis_17:
    'Keeping track of retailer shipping charges',
  automotive_industry_price_analysis_18:
    'Monitoring promotions and sales at retailers',
  automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
  automotive_industry_price_analysis_20: 'Calculating lease payments',
  automotive_industry_price_analysis_21:
    'Analyzing specific pricing considerations for electric vehicles (EVs)',
  automotive_industry_price_analysis_22:
    'Conducting direct-to-consumer versus retailer price comparisons',
  automotive_industry_price_analysis_23:
    'Developing subscription pricing models',
  automotive_industry_price_analysis_24:
    '4Service is a global customer experience (CX) holding company with over 20 years of experience',
  automotive_industry_price_analysis_25:
    "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
  automotive_industry_price_analysis_26:
    "4Service's analysis of prices in the automotive industry includes:",
  automotive_industry_price_analysis_27:
    "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
  automotive_industry_price_analysis_28:
    'of customers understand that prices are increasing across all brands',
  automotive_industry_price_analysis_29: '29',
  automotive_industry_price_analysis_30:
    'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
  automotive_industry_price_analysis_31: '31',
  automotive_industry_price_analysis_32:
    'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
  automotive_industry_price_analysis_33: '48',
  automotive_industry_price_analysis_34:
    'of dealers mentioned the possibility of price increases during the communication/selling process',
  automotive_industry_price_analysis_35: '47.6',
  automotive_industry_price_analysis_36: 'Key figures',
  automotive_industry_price_analysis_37:
    "You'll figure out how to price your product relative to other vehicles in the lineup.",
  automotive_industry_price_analysis_38:
    "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
  automotive_industry_price_analysis_39:
    'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
  automotive_industry_price_analysis_40:
    "You'll be able to determine the appropriate monthly payment and leasing rates.",
  automotive_industry_price_analysis_41:
    'What benefits for your brand or dealer will you gain:',
  automotive_industry_price_analysis_42: 'You submit an application',
  automotive_industry_price_analysis_43:
    'Our manager contacts you to determine the main objectives',
  automotive_industry_price_analysis_44: 'We conduct a marketing research',
  automotive_industry_price_analysis_45:
    'We regularly provide you with reports and recommendations in an online format',
  automotive_industry_price_analysis_46: 'Car with sale sticker',
  automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
  automotive_industry_price_analysis_48:
    'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
  retail_audit_1: 'AUDIT DE MARKETING DE TEREN/Audit de retail',
  retail_audit_2:
    'Colectați date detaliate despre magazinul sau brandul dvs., analizați obstacolele de creștere și creați strategii eficiente pentru a le depăși',
  retail_audit_3: 'Solicitați o ofertă',
  retail_audit_4:
    '4Service efectuează audituri de magazine la fața locului în numele brandurilor pentru a evalua eficacitatea strategiilor lor promoționale, a echipelor de executare de teren și a tacticii de marketing în atingerea pieței țintă prevăzute.',
  retail_audit_5:
    'Observăm meticulos executarea campaniilor promoționale și ne asigurăm că se respectă ghidurile promoționale.',
  retail_audit_6: 'Top 10',
  retail_audit_7: 'Agenții CX din Europa',
  retail_audit_8: 'ani de experiență',
  retail_audit_9: 'acoperire în țări',
  retail_audit_10: '1,6 milioane',
  retail_audit_11: 'evaluări pe an',
  retail_audit_12: 'Ce urmărim?',
  retail_audit_13: 'Prezența materialelor punctului de vânzare (POS)',
  retail_audit_14:
    'Prezența și activarea ambasadorilor de brand, asigurând respectarea programului planificat',
  retail_audit_15:
    'Evaluarea implicării ambasadorilor de brand în campaniile de susținere',
  retail_audit_16:
    'Evaluarea interacțiunii cu consumatorul, asigurând respectarea ghidurilor promoționale și a mesajelor de brand',
  retail_audit_17: 'Măsuri de prevenire a fraudelor',
  retail_audit_18:
    'Afacerea dvs. poate folosi rezultatele unui audit de marketing de teren pentru:',
  retail_audit_19: 'Evaluarea conformității cu bugetul și procesele',
  retail_audit_20: 'Compare actual results with intended objectives',
  retail_audit_21: 'Îmbunătățirea performanței campaniilor de marketing ',
  retail_audit_22: 'Planificarea strategică pentru viitor',
  retail_audit_23: 'Identificarea oportunităților de reducere a costurilor',
  retail_audit_24: 'Îmbunătățirea vânzărilor și ratei de conversie',
  retail_audit_25: 'Optimizarea randamentului investițiilor (ROI)',
  retail_audit_26: 'EI AU ALES 4SERVICE CA ȘI PARTENERUL LOR DE INVESTIȚII',
  retail_audit_27:
    'Un important producător internațional de produse din tutun a colaborat cu 4Service pentru a-și îmbunătăți mărcile și a-și îmbunătăți performanța în magazine. Echipele noastre au furnizat audit de marketing pe teren și raportare live a experienței consumatorilor.',
  retail_audit_28:
    'creștere în acuratețea planificării a agenției de marketing de teren',
  retail_audit_29: 'creștere în livrarea mesajului de brand',
  retail_audit_30: 'creștere în angajamentul BA (activarea consumatorului)',
  retail_audit_31:
    'creștere în acuratețea colectării datelor și a conformității GDPR',
  retail_audit_32: 'Ce insight-uri valoroase urmărim să găsim:',
  retail_audit_33:
    'Lipsa de consecvență sau chiar conflictul cu privire la termenii SLA duce la plata unor taxe semnificative către client',
  retail_audit_34:
    'Perceperea incorectă a taxelor din cauza amestecării tipurilor de servicii furnizate',
  retail_audit_35:
    'Serviciul pe teren nu este efectuat, inclusiv personalul care nu este repartizat la locația planificată | POS',
  retail_audit_36:
    'Cheltuieli efectuate pe baza unei estimări mai degrabă decât în ​​baza reală și nereconciliate',
  retail_audit_37:
    'Aplicarea incorectă a ghidului promoțional care furnizează date frauduloase',
  retail_audit_38: 'Cum lucrăm noi?',
  retail_audit_39:
    'Auditorul nostru vizitează locații desemnate ale punctelor de vânzare (POS) pe baza planului de activare al agenției.',
  retail_audit_40:
    'Aceștia evaluează indicatorii cheie de performanță (KPI) așa cum sunt subliniați în brief, cum ar fi prezența POS, implicarea ambasadorului mărcii, conformitatea cu instrucțiunile promoționale și măsurile de prevenire a fraudei.',
  retail_audit_41: 'Validare:',
  retail_audit_42: 'Colectăm și consolidăm datele obținute.',
  retail_audit_43: 'Se redactează un raport comprehensiv',
  retail_audit_44:
    'Echipa noastră de validare analizează cu atenție toate sondajele pentru acuratețe, inclusiv conformitatea cu descrierea proiectului și orice comentarii suplimentare',
  retail_audit_45: 'Sistem de raportare online:',
  retail_audit_46:
    'Chestionarele completate și validate sunt încărcate pe portalul nostru online în 36 de ore.',
  retail_audit_47:
    'Clienții au acces la portal printr-un cont personal, permițându-le să monitorizeze progresul în orice moment',
  retail_audit_48:
    'Oferim un raport final care conține rezultate și recomandări.',
  retail_audit_49: 'Discută cu un expert',
  retail_audit_50: 'Punctele noastre forte:',
  facility_check_audit_1: 'Serviciu pentru',
  facility_check_audit_2: 'verificarea auditului',
  facility_check_audit_3: 'Elevați experiența de marcă în fiecare detaliu',
  facility_check_audit_4: 'Explorați abordarea noastră',
  facility_check_audit_5: 'Despre',
  facility_check_audit_6: 'Cine suntem',
  facility_check_audit_7:
    'În calitate de holding global pentru experiența clienților, 4Service oferă o suită cuprinzătoare de servicii pentru a ajuta mărcile să creeze și să ofere experiențe excepționale pentru clienții lor.',
  facility_check_audit_8: 'Serviciul nostru de Verificare a Facilității',
  facility_check_audit_9:
    'este conceput pentru a vă ajuta să vă asigurați că locațiile fizice și prezența mărcii în magazinele cu amănuntul respectă standardele mărcii și creează o impresie pozitivă pentru clienți.',
  facility_check_audit_10: 'Soluții 1',
  facility_check_audit_11: 'Verificare Audit Facilitate',
  facility_check_audit_12:
    'este o examinare meticuloasă care evaluează alinierea punctelor dvs. de vânzare cu amănuntul sau standurilor cu standardele mărcii dvs. Nu numai că verificăm aspectele fizice, dar evaluăm și modul în care aceste elemente influențează modul în care marca dvs. este percepută de clienții dvs. valoroși.',
  facility_check_audit_13: 'Două soluții',
  facility_check_audit_14: 'Două Metode de Implementare',
  facility_check_audit_15: 'Metodă',
  facility_check_audit_16: 'Verificare Audit Facilitate de Auditori Experți',
  facility_check_audit_17: 'Validare a Verificării Facilității',
  facility_check_audit_18: 'Rapoarte',
  facility_check_audit_19:
    'Veți primi rapoarte, complete cu dovezi vizuale și statistici, accesibile în termen de 48 de ore',
  facility_check_audit_20:
    'O verificare auditată a facilității bine realizată poate oferi o serie de beneficii pentru marca dvs., inclusiv:',
  facility_check_audit_21: 'Conversie a clienților îmbunătățită:',
  facility_check_audit_22:
    'O experiență pozitivă a clienților poate duce la creșterea vânzărilor și a afacerilor repetate. Auditurile noastre vă pot ajuta să identificați și să abordați orice domenii care ar putea afecta satisfacția clienților.',
  facility_check_audit_23: 'Imaginii mărcii îmbunătățite:',
  facility_check_audit_24:
    'Locațiile fizice ale dvs. sunt un punct de contact cheie pentru clienți și pot juca un rol semnificativ în conturarea imaginii mărcii dvs. Auditurile noastre vă pot ajuta să vă asigurați că locațiile dvs. sunt consistente cu valorile mărcii dvs. și creează o impresie pozitivă pentru clienți.',
  facility_check_audit_25: 'De ce noi?',
  facility_check_audit_26:
    'De ce este importantă Verificarea Audit a Facilității?',
  facility_check_audit_27:
    'O verificare auditată a facilității bine realizată poate oferi o serie de beneficii pentru marca dvs., inclusiv:',
  facility_check_audit_28: 'Imaginii mărcii îmbunătățite:',
  facility_check_audit_29:
    'Locațiile fizice ale dvs. sunt un punct de contact cheie pentru clienți și pot juca un rol semnificativ în conturarea imaginii mărcii dvs. Auditurile noastre vă pot ajuta să vă asigurați că locațiile dvs. sunt consistente cu valorile mărcii dvs. și creează o impresie pozitivă pentru clienți.',
  facility_check_audit_30: 'Conversie a clienților îmbunătățită:',
  facility_check_audit_31:
    'O experiență pozitivă a clienților poate duce la creșterea vânzărilor și a afacerilor repetate. Auditurile noastre vă pot ajuta să identificați și să abordați orice domenii care ar putea afecta satisfacția clienților.',
  facility_check_audit_32: 'Cine are nevoie',
  facility_check_audit_33:
    'Cine are nevoie de Verificarea Audit a Facilității?',
  facility_check_audit_34:
    'Verificarea Audit a Facilității este un serviciu valoros pentru orice marcă cu locații fizice și prezență fizică. Este deosebit de important pentru mărcile care doresc să:',
  facility_check_audit_35: 'Asigurați conformitatea cu standardele mărcii:',
  facility_check_audit_36:
    'Auditurile noastre vă pot ajuta să identificați orice domenii în care locațiile dvs. pot devia de la standardele mărcii dvs.',
  facility_check_audit_37: 'Îmbunătățiți experiența clienților:',
  facility_check_audit_38:
    'Auditurile noastre vă pot ajuta să identificați oportunități de îmbunătățire a experienței clienților la locațiile dvs.',
  facility_check_audit_39: 'Construiți o marcă mai puternică:',
  facility_check_audit_40:
    'Auditurile noastre vă pot ajuta să vă asigurați că locațiile dvs. fizice creează o impresie pozitivă pentru clienți.',
  facility_check_audit_41:
    'Mystery Shopper face toate fotografiile necesare la o locație specificată.',
  facility_check_audit_42:
    "Apoi, 'Mystery Shopper' completează un chestionar și atașează fotografiile.",
  facility_check_audit_43:
    'Validatorul primește fotografiile și le verifică conformitatea cu standardele.',
  facility_check_audit_44: 'Verificare Audit a Facilității de Auditori Experți',
  facility_check_audit_45: 'Etapa de Pregătire:',
  facility_check_audit_46: 'Aprobarea colaborativă a programului de audit',
  facility_check_audit_47:
    'Dealerii sunt pregătiți și informați despre auditul viitor',
  facility_check_audit_48: 'Instruirea Auditorilor:',
  facility_check_audit_49:
    'Trainerii noștri experimentați 4Service îi împuternicesc pe auditori cu ghidurile mărcii',
  facility_check_audit_50: 'Etapa de Auditare a Facilității:',
  facility_check_audit_51:
    'Auditorii, înarmați cu scrisori de autorizare specială, vizitează locațiile',
  facility_check_audit_52:
    'Examinare meticuloasă a fiecărui punct, urmând cu atenție ghidurile',
  facility_check_audit_53:
    'Documentare vizuală, alături de evaluări împotriva cerințelor HQ',
  facility_check_audit_54: 'Raport PPT Concludent',
  facility_check_audit_55:
    'Un raport PowerPoint cuprinzător, elucidând constatările',
  facility_check_audit_56:
    'Prezentare online/offline imersivă a rezultatelor auditate',
  facility_check_audit_57: 'Validare a Verificării Facilității',
  facility_check_audit_58: 'Etape:',
  facility_check_audit_59: 'Etapa de Auditare a Facilității:',
  facility_check_audit_60:
    'Reprezentanții mărcii folosesc aplicația 4Service pentru a captura imagini pe loc',
  facility_check_audit_61:
    'Aderența la criterii vizuale și descriptive stricte',
  facility_check_audit_62: 'Etapa de Validare:',
  facility_check_audit_63:
    'Validatorii diligenti revizuiesc meticulos fiecare raport',
  facility_check_audit_64:
    'Comparație cuprinzătoare a fotografiilor cu standardele mărcii',
  facility_check_audit_65:
    'Adnotări și insight-uri integrate în raport pentru claritate',
  facility_check_audit_66:
    'Comunicare directă cu auditorii pentru clarificări, dacă este necesar',
  facility_check_audit_67: 'Fază de Raportare:',
  facility_check_audit_68:
    'Rapoarte, complete cu dovezi vizuale și statistici, accesibile în două zile',
  facility_check_audit_69: 'Contactați-ne astăzi',
  cx_solution_1: 'Nu numai că găsim insight-uri,',
  cx_solution_2: 'Ci vă ducem afacerea la noi culmi',
  cx_solution_3:
    'Experții cu peste 20 de ani de experiență în experiența clienților descoperă soluții complexe și vă furnizează un plan de acțiune specific care contribuie la creșterea dvs.',
  cx_solution_4:
    'Descoperiți o suită unică de servicii într-o soluție sigură pentru viitor',
  cx_solution_5: 'Soluție CX all-in-one',
  cx_solution_6: 'Testare publicitară',
  cx_solution_7: 'AI & Neuromarketing',
  cx_solution_8: 'Vocea clienților',
  cx_solution_9: 'Cercetare de birou',
  cx_solution_10: 'CJM',
  cx_solution_11: 'Cumpărături misterioase',
  cx_solution_12: 'Consultanță CX',
  cx_solution_13: 'Ascultarea Social Media',
  cx_solution_14: 'Cercetare cantitativă și calitativă',
  cx_solution_15: 'Design de servicii',
  cx_solution_16: 'Strategie de marketing',
  cx_solution_17: 'Testare UX/UI',
  cx_solution_18: 'Insight-uri despre audiență',
  cx_solution_19: 'Suntem onorați să contribuim la succesul dvs.',
  cx_solution_20:
    'Personalizăm și combinăm instrumente și servicii în funcție de scopul și obiectivele afacerii dvs. pentru a oferi rezultate personalizate',
  cx_solution_21: 'Cercetare cuprinzătoare',
  cx_solution_22:
    'Îmbunătățiți procesul decizional, oferind un avantaj competitiv într-un mediu de piață axat pe date.',
  cx_solution_23:
    'Cercetarea calitativă implică colectarea și analiza datelor non-numerice pentru a înțelege conceptele, opiniile sau experiențele',
  cx_solution_24:
    'Metodele de cercetare cantitativă sunt o măsurare obiectivă și o analiză numerică a datelor colectate prin sondaje, chestionare și studii',
  cx_solution_25:
    'Utilizăm un set distinct de instrumente în funcție de cerințele dvs. specifice',
  cx_solution_26: 'Instrumente',
  cx_solution_27: 'CAWI',
  cx_solution_28:
    'Interviul asistat de calculator pe web este un instrument vital în arsenalul nostru, permițându-ne să efectuăm sondaje și să colectăm date prin platforme online cu ajutorul unui software specializat, asigurând o colectare eficientă și precisă a datelor pentru nevoile de cercetare de piață ale clienților noștri',
  cx_solution_29: 'CATI',
  cx_solution_30:
    'Interviul telefonic asistat de calculator este o metodă pe care o utilizăm pentru a efectua sondaje și a aduna date valoroase prin telefon, folosind software specializat pentru a eficientiza procesul și a asigura precizia în eforturile noastre de cercetare de piață',
  cx_solution_31: 'F2F',
  cx_solution_32:
    'Față în față este integrală pentru abordarea noastră, facilitând interacțiuni directe și angajamente personalizate cu clienții și clienții, facilitând astfel încrederea, înțelegerea și comunicarea eficientă esențiale pentru construirea relațiilor durabile și pentru stimularea succesului afacerilor',
  cx_solution_33: 'Cercetare de birou',
  cx_solution_34:
    'Cercetarea de birou implică colectarea și analiza cuprinzătoare a datelor, servind ca un pas fundamental în procesul nostru de cercetare pentru a aduna insight-uri și a informa deciziile strategice pentru clienții noștri',
  cx_solution_35: 'Analiza SWOT',
  cx_solution_36:
    'Analiza SWOT este un instrument de evaluare strategică care ajută clienții noștri să identifice Punctele tari și Punctele slabe',
  cx_solution_37: 'Îmbunătățiți experiența clienților cu metodologia noastră',
  cx_solution_38:
    'Harta călătoriei clienților este despre definirea unei audiențe țintă, înțelegerea nevoilor și mediului lor, și apoi crearea unui plan pentru a oferi o experiență angajantă',
  cx_solution_39: 'BHT',
  cx_solution_40:
    'Cercetarea urmăririi sănătății mărcii poate măsura modul în care marca dvs. se descurcă în ceea ce privește conștientizarea și utilizarea, poziționarea mărcii și performanța mărcii',
  cx_solution_41: 'CSI',
  cx_solution_42:
    'Indexul de Satisfacție a Clienților este un scor care indică cât de satisfăcuți sunt clienții dvs. de produsele/serviciile companiei.',
  cx_solution_43:
    'Monitorizarea prețurilor este procesul de colectare, procesare și analiză a prețurilor pentru a înțelege modul în care acestea se schimbă și cum afectează comportamentul cumpărătorului și performanța afacerii.',
  cx_solution_44:
    'Monitorizarea și raportarea concurenței implică monitorizarea activității și performanței competitorilor pentru a înțelege mediul competitiv și a face ajustările necesare pentru a rămâne relevant și competitiv pe piață.',
  cx_solution_45:
    'Indicele de loialitate a clienților este o măsură a gradului de loialitate al clienților față de o marcă sau de un produs/serviciu al mărcii.',
  cx_solution_46:
    'Vindecați durerea punctelor de contact și oferiți soluții durabile pentru problemele clienților',
  cx_solution_47: 'Identificați și adresați prompt problemele clienților',
  cx_solution_48:
    'Valorificarea datelor pentru a identifica oportunitățile de îmbunătățire',
  cx_solution_49:
    'Asigurați-vă că fiecare punct de contact îndeplinește așteptările clienților',
  cx_solution_50: 'Identificați punctele puternice și le promovați',
  cx_solution_51:
    'Furnizăm expertiză și susținere continuă pentru a vă asigura că atingeți obiectivele dvs. de afaceri',
  cx_solution_52: 'De ce să alegeți 4Service?',
  cx_solution_53: 'Experiență vastă',
  cx_solution_54:
    'Avem o istorie bogată și diversă în furnizarea de soluții eficiente și inovatoare de experiență a clienților pentru clienții noștri din întreaga lume.',
  cx_solution_55: 'Expertiză extinsă',
  cx_solution_56:
    'Echipa noastră de specialiști are abilități și cunoștințe adânci într-o gamă largă de domenii legate de experiența clienților, permițându-ne să oferim servicii de cea mai înaltă calitate.',
  cx_solution_57: 'Abordare personalizată',
  cx_solution_58:
    'Înțelegem că fiecare afacere este unică, așa că lucrăm îndeaproape cu clienții noștri pentru a dezvolta soluții personalizate care se potrivesc nevoilor și obiectivelor lor specifice.',
  cx_solution_59: 'Focus pe rezultate',
  cx_solution_60:
    'Suntem dedicați să livrăm rezultate tangibile și măsurabile pentru clienții noștri, contribuind la succesul lor pe termen lung și la creșterea afacerii.',
  cx_solution_61:
    'Te invităm să descoperiți cum putem îmbunătăți experiența clienților pentru afacerea dvs.',
  cx_solution_62: 'Contactați-ne astăzi pentru a începe.',
  cx_solution_63:
    'Monitorizarea prețurilor este procesul de colectare, procesare și analiză a prețurilor competitorilor',
  cx_solution_64: 'NPS',
  cx_solution_65:
    'Scorul Net Promoter este standardul de aur al metricilor de experiență a clienților, bazat pe o singură întrebare: Cât de probabil este să recomandați Organizația/Produsul/Serviciul?',
  cx_solution_66: 'Cercetare HR',
  cx_solution_67:
    'Cercetarea resurselor umane este folosită pentru a evalua practicile și performanța resurselor umane și oferă o analiză detaliată asupra dezvoltării și managementului curent',
  cx_solution_68:
    'Testarea utilizabilității este modul în care oamenii reali interacționează cu un site web, o aplicație sau alt produs și observă comportamentul și reacțiile acestora față de acesta',
  cx_solution_69: 'Altele',
  cx_solution_70:
    'Experții selectează cea mai bună metodologie pentru afacerea dvs.',
  cx_solution_71: 'Să începem',
  cx_solution_72: '1 - REUNIUNEA DE LANSARE',
  cx_solution_73:
    'În timpul reuniunii de lansare, identificăm și ne concentrăm pe sarcinile cheie. Rezultatul: înțelegerea obiectivului, sarcinile de cercetare, termenele limită, metodele și domeniile de responsabilitate.',
  cx_solution_74: '2 - DEZVOLTAREA CONCEPTULUI',
  cx_solution_75:
    'Departamentul de analiză al 4Service agregă date, coordonează structura raportului. Apoi prezentăm rezultatele clientului, completate cu insight-uri și recomandări pentru implementare.',
  cx_solution_76: '3 - MUNCĂ DE TEREN',
  cx_solution_77:
    'Pentru a efectua cercetarea, recrutăm participanți, efectuăm interviuri și alte etape de cercetare de bază. Clientul primește interviuri audio-video transcrise, precum și rezumate concise.',
  cx_solution_78: '4 - ANALITICĂ',
  cx_solution_79:
    'Departamentul de analiză al 4Service agregă date, coordonează structura raportului. Apoi prezentăm rezultatele clientului, completate cu insight-uri și recomandări pentru implementare.',
  cx_solution_80: '5 - ATELIER',
  cx_solution_81:
    'Conducerea unei discuții cu clientul cu privire la planul de implementare',
  cx_solution_82: 'Aflați mai multe despre cele mai bune practici ale noastre',
  cx_solution_83:
    'Harta călătoriei clienților permite o examinare rapidă a tuturor canalelor de comunicare, identificarea eventualelor deficiențe la punctele de contact cruciale și o înțelegere cuprinzătoare a zonelor care ar putea să devieze de la cursul planificat.',
  cx_solution_84: 'Când să folosiți?',
  cx_solution_85:
    'Construiți o campanie publicitară personalizată pe baza datelor reale despre grupurile de audiență',
  cx_solution_86:
    'Aflați declanșatoarele diferitelor segmente de audiență țintă și satisfaceți-le nevoile',
  cx_solution_87: 'Vizați precis fiecare grup și obțineți conversii maxime',
  cx_solution_88:
    'Tehnica de verificare însoțitoare permite evaluarea posibilităților de moduri optime de a vă atinge obiectivele.',
  cx_solution_89: 'Testarea unui nou site web sau produs înainte de lansare',
  cx_solution_90:
    'Verificarea proceselor dezvoltate înainte de implementarea modificărilor',
  cx_solution_91:
    'Evaluarea site-ului web sau a produsului în contextul vânzărilor în scădere',
  cx_solution_92: 'Lansarea unui nou produs adaptat preferințelor clienților',
  cx_solution_93:
    'Studiul companiilor concurente pentru a asigura o poziție de vârf pe piață',
  cx_solution_94:
    'Oferim o abordare cuprinzătoare de 360° pentru crearea unui model de servicii orientat către clienți.',
  cx_solution_95: 'Instruire și dezvoltare a personalului',
  cx_solution_96: 'Îmbunătățirea standardelor de servicii',
  cx_solution_97: 'Creșterea fluxului de clienți și a vânzărilor',
  cx_solution_98:
    'Stabilirea KPI-urilor și a sistemului de motivare pentru personal',
  cx_solution_99: 'Descoperiți poveștile noastre de succes',
  cx_solution_100: 'Automobilistică',
  cx_solution_101: 'Tutun',
  cx_solution_102: 'Vânzare cu amănuntul',
  cx_solution_103: 'Fază calitativă',
  cx_solution_104: 'Fază cantitativă (segmentare)',
  cx_solution_105: 'Grupuri de discuție',
  cx_solution_106: 'Acoperirea publicului online la nivel mondial',
  cx_solution_107: 'Mix de tehnici în timpul interviurilor',
  cx_solution_108: 'Doar întrebări deschise',
  cx_solution_109: 'Suport pentru respondenți 24/7',
  cx_solution_110:
    'Păstrarea detaliilor importante și o imagine reală a parcursului',
  cx_solution_111: 'Cercetare de piață',
  cx_solution_112: 'Diagnosticarea companiei și a competitorilor',
  cx_solution_113: 'Modele de servicii actualizate și noi procese de afaceri',
  cx_solution_114: 'Promovarea serviciului orientat către client',
  cx_solution_115:
    'Implementarea schimbărilor și controlul asupra dezvoltării strategiilor de servicii',
  usage_attitude_1: 'Utilizare și Atitudine (U&A)',
  usage_attitude_2:
    'Obțineți insight-uri cuprinzătoare despre modul în care publicul țintă percepe, utilizează și interacționează cu marca.',
  usage_attitude_3: 'Vorbiți cu un expert',
  usage_attitude_4: 'Despre',
  usage_attitude_5:
    '<b>Explorați obiceiurile consumatorilor</b> pentru a descoperi preferințele și influențele în profunzime',
  usage_attitude_6:
    "<b>Descoperiți 'de ce'</b> din spatele alegerilor consumatorilor cu analiza noastră detaliată",
  usage_attitude_7:
    '<b>Înțelegeți modul în care</b> clienții interacționează cu produsele dvs. pentru insight-uri strategice',
  usage_attitude_8:
    '<b>Obțineți insight-uri despre</b> comportamentul, obiceiurile și preferințele consumatorilor pentru decizii informate',
  usage_attitude_9: 'Experiență',
  usage_attitude_10:
    'Experții 4Service cu peste 20 de ani de experiență în domeniul serviciilor creează soluții unice care ajută la creșterea afacerii dvs.',
  usage_attitude_11: 'Contactați-ne',
  usage_attitude_12: 'Pentru cine',
  usage_attitude_13:
    'Cine beneficiază de Utilizare și Atitudine față de marcă?',
  usage_attitude_14:
    'Diverse industrii și sectoare de servicii sunt interesate de:',
  usage_attitude_15:
    '<b>Recunoașterea</b> cererilor și punctelor dureroase ale clienților',
  usage_attitude_16:
    '<b>Identificarea</b> oportunităților de stimulare a consumului de către clienți',
  usage_attitude_17:
    '<b>Dezvoltarea</b> unor noi categorii de produse sau strategii de marketing',
  usage_attitude_18:
    '<b>Obținerea</b> unei înțelegeri profunde a peisajului competitiv',
  usage_attitude_19:
    'Metodologia este selectată individual în funcție de cererea clientului',
  usage_attitude_20:
    '<b>Cercetarea calitativă</b> implică colectarea și analizarea datelor non-numerice pentru a înțelege conceptele, opinii sau experiențele.',
  usage_attitude_21:
    'Metodele de <b>cercetare cantitativă</b> implică măsurarea obiectivă și analiza numerică a datelor obținute prin sondaje, chestionare și studii.',
  usage_attitude_22:
    'După colectarea datelor, experții analizează informațiile și trag concluzii pentru a vă oferi cele mai bune recomandări.',
  usage_attitude_23: 'Cine are nevoie',
  usage_attitude_24: 'Povestea dvs. de succes începe aici',
  usage_attitude_25:
    '<b>Veți primi o cercetare</b> cu insight-uri valoroase care îmbunătățesc luarea deciziilor strategice, asigurând că acțiunile se bazează pe o înțelegere profundă a comportamentului consumatorilor.',
  usage_attitude_26:
    '<b>Cercetarea oferă o bază solidă pentru rafinarea</b> și optimizarea strategiilor de marcă, aliniindu-le mai strâns cu preferințele și atitudinile consumatorilor.',
  usage_attitude_27: 'Soluții',
  usage_attitude_28: 'Explorați numeroasele oportunități cu soluțiile noastre',
  usage_attitude_29: 'Preferință de produs în cadrul categoriei',
  usage_attitude_30:
    'Identificați preferințele de top ale consumatorilor în diferite categorii, inclusiv alimente, băuturi, îmbrăcăminte, electronice și altele.',
  usage_attitude_31: 'Comportamentul de consum / utilizare',
  usage_attitude_32:
    'Analizați frecvența consumului sau utilizării anumitor produse/servicii și identificați ocaziile și momentele asociate cu utilizarea acestora.',
  usage_attitude_33: 'Comportamentul de achiziție',
  usage_attitude_34:
    'Examinați canalele pe care clienții le utilizează pentru achiziții și identificați sursele de informații de care se bazează.',
  usage_attitude_35: 'Motivațiile și nevoile consumatorilor',
  usage_attitude_36:
    'Examinați motivațiile și barierele care influențează consumul. Evaluați factorii care afectează deciziile de achiziție ale consumatorilor. Explorați percepția categoriei de produse și analizați modul în care este perceput prețul.',
  usage_attitude_37: 'Abordarea noastră',
  usage_attitude_38: 'Studiu asupra utilizării și atitudinii față de marcă',
  usage_attitude_39: 'Informații',
  usage_attitude_40:
    'Desfășurarea sondajelor folosind atât metode cantitative, cât și calitative',
  usage_attitude_41: 'Începe',
  usage_attitude_42:
    'Stabilirea obiectivelor și determinarea metodelor în funcție de solicitările individuale',
  usage_attitude_43: 'Analiză',
  usage_attitude_44: 'Tehnici sofisticate utilizate de experții noștri',
  usage_attitude_45:
    'Utilizăm un set distinct de instrumente pe baza cerințelor dvs. specifice',
  usage_attitude_46: 'СATI',
  usage_attitude_47:
    'Interviul telefonic asistat de calculator este o metodă pe care o utilizăm pentru a efectua sondaje și a colecta date valoroase prin telefon, folosind software specializat pentru a eficientiza procesul și a asigura acuratețea în eforturile noastre de cercetare de piață.',
  usage_attitude_48: 'CAWI',
  usage_attitude_49:
    'Interviul pe web asistat de calculator este o unealtă vitală în arsenalul nostru, care ne permite să efectuăm sondaje și să colectăm date prin platforme online cu ajutorul unui software specializat, asigurând colectarea eficientă și precisă a datelor pentru nevoile de cercetare de piață ale clienților noștri.',
  usage_attitude_50: 'CAPI',
  usage_attitude_51:
    'Interviul personal asistat de calculator este o metodă pe care o utilizăm pentru a optimiza procesele de colectare a datelor, a face interviurile mai fluide și a asigura rezultate precise și insightful pentru nevoile afacerii dvs.',
  usage_attitude_52: 'Grupuri de discuție',
  usage_attitude_53:
    'Grupurile de discuție sunt sesiuni dinamice de cercetare pe care le facilităm, aducând împreună un grup divers de participanți pentru a discuta în profunzime subiecte sau produse specifice, permițându-ne să obținem insight-uri calitative, să descoperim atitudini, preferințe și percepții și să explorăm idei și concepte, informând în cele din urmă procesele de luare a deciziilor și dezvoltarea strategiilor ale clienților noștri.',
  usage_attitude_54: 'F2F',
  usage_attitude_55:
    'Interfața directă este integrală pentru abordarea noastră, facilitând interacțiuni directe și angajamente personalizate cu clienții și clienții, favorizând astfel încrederea, înțelegerea și comunicarea eficientă esențiale pentru construirea relațiilor de durată și stimularea succesului afacerii focus groups.',
  usage_attitude_56: 'Cercetare etnografică',
  usage_attitude_57:
    'Studiul oamenilor și al culturilor prin imersie și observație în mediile lor naturale, ducând adesea la insight-uri profunde în comportamente, credințe și dinamici sociale.',
  parthership: 'Parteneriat',
  career: 'Carieră',
  four_opinion_1: 'Sondaj de la 4Service',
  four_opinion_2: 'Completați sondaje online pentru bani și câștigați fără a ieși din casă',
  four_opinion_3: 'Începeți',
  four_opinion_4: 'Avantajele noastre',
  four_opinion_5: 'Avem încrederea clienților',
  four_opinion_6: 'Comunitatea noastră numără peste 90.000 de consumatori în Ucraina și 100.000 în Kazahstan',
  four_opinion_7: 'Vă prețuim timpul',
  four_opinion_8: 'Apreciem timpul dumneavoastră, de aceea, pentru participarea la sondaje, aveți posibilitatea de a primi un cadou sau o recompensă în bani',
  four_opinion_9: 'Opinia dumneavoastră are un impact semnificativ',
  four_opinion_10: 'Fiecare comentariu al dumneavoastră ajută mărcile să înțeleagă mai bine nevoile clienților lor',
  four_opinion_11: 'Participați la sondaje pe 4Opinion de la 4Service și câștigați online sau obțineți un premiu',
  four_opinion_12: 'Alăturați-vă la 4Opinion',
  four_opinion_13: 'Și mai multe povești interesante pe canalul nostru',
  four_opinion_14: 'Mai multe detalii pentru Ucraina',
  four_opinion_15: 'Mai multe detalii pentru Kazahstan',
  four_opinion_16: 'De ce să ne alegeți pe noi?',
  four_opinion_17: '4Service este un holding internațional de cercetare specializat în gestionarea experienței clienților',
  four_opinion_18: 'Facem parte din top 10 agenții europene de gestionare a experienței clienților',
  four_opinion_19: 'Misiunea noastră este de a îmbunătăți serviciile pentru clienți în întreaga lume!',
  four_opinion_20: 'Cum să începeți să câștigați bani din sondaje online?',
  four_opinion_21: 'Înregistrați-vă pe site',
  four_opinion_22: 'Primiți notificări despre sondajele disponibile',
  four_opinion_23: 'Completați sondajele până la capăt și câștigați',
  four_opinion_24: 'Recenzii reale de la respondenții noștri',
  four_opinion_25: 'Vitaliy Kokhan',
  four_opinion_26: '“Am completat un sondaj și chiar am uitat de el. După un timp, doamna Nataliya m-a sunat și mi-a spus că am câștigat un premiu. Și iată-l, premiul meu Samsung Galaxy M52 5G. Vă mulțumesc mult😁”',
  four_opinion_27: 'Viktoriya Popovska',
  four_opinion_28: '“Am primit boxa, mulțumesc foarte mult pentru un concurs corect, echitabil și interesant.”',
  four_opinion_29: 'Yuliya Oleksandrivna',
  four_opinion_30: '“Probabil cel mai incredibil lucru care mi s-a întâmplat în ultima vreme💕 Nu doar că am câștigat, dar a fost și un cadou cu adevărat grozav. Mă așteptam la orice, dar nu la ceva atât de tare. Încă o dată, un mare mulțumesc!”',
  four_opinion_31: 'Doar cu noi aveți posibilitatea de a câștiga bani reali sau de a obține un premiu',
  four_opinion_32: 'Urmăriți sondajele disponibile pe canalul nostru',
  four_opinion_33: 'Accesați canalul',
};
