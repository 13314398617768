import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import Banner from '../../banner/Banner';
import { CatiIntroStyles } from './styles';

export const CatiIntro = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CatiIntroStyles>
      <Banner
        bgColor="linear-gradient(273.29deg, #571EC1 0%, #BA08A8 100%)"
        title={getTranslationsByLangOrEng(interfaceLanguage, 'cati_1')}
        description={getTranslationsByLangOrEng(
          interfaceLanguage,
          'cati_2',
        )}
        buttonKey="cati_3"
        btnColor="#fff"
        btnHoverColor="#000"
      />
    </CatiIntroStyles>
  );
};
