import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { BoardingStyles } from '../styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { TCards } from '..';
import cjm from '../../../assets/images/boarding/cjm.png';
import nps from '../../../assets/images/boarding/nps.png';
import hrr from '../../../assets/images/boarding/hrr.png';
import uxt from '../../../assets/images/boarding/uxt.png';
import adt from '../../../assets/images/boarding/adt.png';
import csi from '../../../assets/images/boarding/csi.png';
import fra from '../../../assets/images/boarding/fra.png';
import { useNavigate } from 'react-router-dom';

export const Methodologies = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const navigate = useNavigate();

  const cards = (lang: string): TCards[] => ([
    {
      bg: 'linear-gradient(91.15deg, #EB2E4B -6.99%, #6220EF 205.96%),linear-gradient(45deg, #EB2E4B 0%, #6220EF 123.24%)',
      img: cjm,
      title: getTranslationsByLangOrEng(lang, 'boarding_1'),
      text: getTranslationsByLangOrEng(lang, 'boarding_2'),
      link: 'consumer-journey-map',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
    {
      bg: 'linear-gradient(45deg, #2298FF 3.42%, #3EC290 100%)',
      img: nps,
      title: getTranslationsByLangOrEng(lang, 'boarding_3'),
      text: getTranslationsByLangOrEng(lang, 'boarding_4'),
      link: 'net-promoter-score',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
    {
      bg: 'linear-gradient(225.55deg, #8640F9 -0.98%, #0029FF 49.46%, #002050 100%)',
      img: csi,
      title: getTranslationsByLangOrEng(lang, 'boarding_11'),
      text: getTranslationsByLangOrEng(lang, 'boarding_12'),
      link: 'customer-satisfaction-index',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
    {
      bg: 'linear-gradient(220.49deg, rgba(24, 24, 27, 0.7) 0%, rgba(138, 138, 142, 0.7) 99.57%)',
      img: uxt,
      title: getTranslationsByLangOrEng(lang, 'boarding_9'),
      text: getTranslationsByLangOrEng(lang, 'boarding_10'),
      link: 'ux-testing',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
    {
      bg: 'linear-gradient(41.17deg, #FDA272 0%, #35C879 64.9%, #00A3FF 100%)',
      img: adt,
      title: getTranslationsByLangOrEng(lang, 'boarding_13'),
      text: getTranslationsByLangOrEng(lang, 'boarding_14'),
      link: 'ad-testing',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
    {
      bg: 'linear-gradient(45deg, #7622FF 0%, #B73EC2 100%)',
      img: hrr,
      title: getTranslationsByLangOrEng(lang, 'boarding_7'),
      text: getTranslationsByLangOrEng(lang, 'boarding_8'),
      link: 'hr-research',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
    {
      bg: 'linear-gradient(266.71deg, #420710 9.17%, #110003 100%)',
      img: fra,
      title: getTranslationsByLangOrEng(lang, 'boarding_15'),
      text: getTranslationsByLangOrEng(lang, 'boarding_16'),
      link: 'retail-audit',
      abroad: false,
      langs: ['en', 'fr', 'de', 'uk', 'ro', 'kk-kz', 'it', 'ru-kz', 'ge', 'az', 'es', 'pt', 'nl', 'pl', 'uz'],
    },
  ]);

  const handleOpen = (item: TCards) => {
    if (!item.abroad) {
      navigate(`/${interfaceLanguage}/${item.link}`);
    } else {
      window.open(item.link, 'blank');
    }
  };

  return (
    <BoardingStyles>
      <div className="container">
        <div className="intro">
          <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'main_14')}</h1>
          <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'boarding_51')}</h2>
        </div>
        <div className="cards">
          {cards(interfaceLanguage).map((item) => (
            <div className="card" onClick={() => handleOpen(item)}>
              <div className="card-img" style={{ background: item.bg }}>
                <img src={item.img} alt={item.title} />
              </div>
              <div className="card-text">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </BoardingStyles>
  );
};
