import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersAppsStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import android from '../../../assets/images/shoppers/android.png';
import androidIcon from '../../../assets/images/shoppers/android.svg';
import ios from '../../../assets/images/shoppers/ios.png';
import iosIcon from '../../../assets/images/shoppers/ios.svg';

export const ShoppersApps = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersAppsStyles>
      <div className="container">
        <h2
          dangerouslySetInnerHTML={{
            __html: getTranslationsByLangOrEng(
              interfaceLanguage,
              'shoppers_63',
            ),
          }}
        />
        <div className="apps-content">
          <div className="buttons">
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_64')}</p>
            <div className="buttons-wrapper">
              <a
                href="https://play.google.com/store/apps/details?id=com.fourservicegroup.fourshoppers.next&hl=ru"
                target="_blank"
                className="buttons-item"
                rel="noreferrer"
              >
                <img src={androidIcon} alt="Google Play" />
                <span>Google Play</span>
              </a>
              <a
                href="https://apps.apple.com/ua/app/4shoppers-next-mystery-shopper/id6470825356?l=ru"
                target="_blank"
                className="buttons-item"
                rel="noreferrer"
              >
                <img src={iosIcon} alt="AppStore" />
                <span>App Store</span>
              </a>
            </div>
          </div>
          <div className="line">
            {getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_65')}
          </div>
          <div className="qr-codes">
            <img src={android} alt="Google Play" className="qr-codes-item" />
            <img src={ios} alt="AppStore" className="qr-codes-item" />
          </div>
        </div>
      </div>
    </ShoppersAppsStyles>
  );
};
