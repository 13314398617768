import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiSolvesStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import ButtonWithArrowOnHover from '../../buttonWithArrowOnHover/ButtonWithArrowOnHover';

export const CatiSolves = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiSolvesStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cati_16')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_17')}
          </h2>
          <div className="cards">
            <div className="cards-item">
              <p dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cati_18',
                ),
              }}
              />
              <div className="cards-gradient" />
            </div>
            <div className="cards-item">
              <p dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cati_19',
                ),
              }}
              />
              <div className="cards-gradient" />
            </div>
            <div className="cards-item">
              <p dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cati_20',
                ),
              }}
              />
              <div className="cards-gradient" />
            </div>
            <div className="cards-item">
              <p dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cati_21',
                ),
              }}
              />
              <div className="cards-gradient" />
            </div>
            <div className="cards-item">
              <p dangerouslySetInnerHTML={{
                __html: getTranslationsByLangOrEng(
                  interfaceLanguage,
                  'cati_22',
                ),
              }}
              />
              <div className="cards-gradient" />
            </div>
          </div>
          <ButtonWithArrowOnHover
            onClick={() => {
              const elem = document.getElementById('form');
              elem!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }}
            arrowColor="#fff"
            textColor="#fff"
            borderColor="#000"
            borderOnHover="#000"
            background="#000"
            className="btn"
            hoverBackground="transparent"
            hoverTextColor="#000"
            text={getTranslationsByLangOrEng(
              interfaceLanguage,
              'cati_3',
            )}
          />
        </div>
      </div>
    </CatiSolvesStyles>
  );
};
