import styled from 'styled-components';

export const CatiPartnersStyles = styled.div`
  padding: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  .partner-quee {
    width: 100%;

    div {
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border: 1px solid #cccccc;
    width: 370px;
    height: 370px;
    border-radius: 100%;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;

    color: #000000;

    margin-left: -50px;

    img:nth-child(2) {
      display: none;
    }

    &:hover {
      img:nth-child(1) {
        display: none;
      }

      img:nth-child(2) {
        display: block;
      }
    }

    @media (max-width: 1000px) {
      height: 197px;
      width: 197px;

      margin-left: -30px;
      font-size: 21px;
    }

    @media (max-width: 600px) {
      height: 200px;
      width: 200px;

      margin-left: -30px;
      font-size: 21px;
    }
  }
`;
