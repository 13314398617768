import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../../../state/hooks';
import { ShoppersInstructionStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import instruction1 from '../../../assets/images/shoppers/instruction-1.png';
import instruction2 from '../../../assets/images/shoppers/instruction-2.png';
import instruction3 from '../../../assets/images/shoppers/instruction-3.png';
import instruction4 from '../../../assets/images/shoppers/instruction-4.png';

export const ShoppersInstruction = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ShoppersInstructionStyles>
      <div className="container">
        <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_30')}</h2>
        <div className="cards">
          <a href="https://4service-group.eu/uk/instruktsiya-do-audiozapisu/" target="_blank" className="cards-item" rel="noreferrer">
            <img src={instruction1} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_31')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_9')}</p>
            </div>
          </a>
          <a href="https://4service-group.eu/uk/yak-zrobiti-audiozapis-dzvinka/" target="_blank" className="cards-item" rel="noreferrer">
            <img src={instruction2} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_32')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_9')}</p>
            </div>
          </a>
          <a href="https://4service-group.eu/uk/ne-vdayetsya-prikripiti-audiofajl-do-anketi-audiofajl-velikogo-rozmiru/" target="_blank" className="cards-item" rel="noreferrer">
            <img src={instruction3} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_33')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_9')}</p>
            </div>
          </a>
          <a href="https://4service-group.eu/uk/kodeks-profesijnih-standartiv-ta-etiki-mspa-dlya-tayemnih-pokuptsiv/" target="_blank" className="cards-item" rel="noreferrer">
            <img src={instruction4} alt="" />
            <div className="cards-text">
              <h6>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_34')}</h6>
              <p>{getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_9')}</p>
            </div>
          </a>
        </div>
      </div>
    </ShoppersInstructionStyles>
  );
};
