import React from 'react';
import { useAppSelector } from '../../../state/hooks';
import { CatiTechnologyStyles } from './styles';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';

export const CatiTechnology = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <CatiTechnologyStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'cati_63')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'cati_64')}
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="cards">
          <div className="cards-item">
            <h4 dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_66',
              ),
            }}
            />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_65')}</p>
            <ul>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_67')}</li>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_68')}</li>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_69')}</li>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_70')}</li>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_71')}</li>
            </ul>
          </div>
          <div className="cards-item">
            <h4 dangerouslySetInnerHTML={{
              __html: getTranslationsByLangOrEng(
                interfaceLanguage,
                'cati_72',
              ),
            }}
            />
            <p>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_65')}</p>
            <ul>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_73')}</li>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_74')}</li>
              <li>{getTranslationsByLangOrEng(interfaceLanguage, 'cati_75')}</li>
            </ul>
          </div>
        </div>
      </div>
    </CatiTechnologyStyles>
  );
};
