import React, { FC, useRef, useState } from 'react';
import { useAppSelector } from '../../../state/hooks';
import getTranslationsByLangOrEng from '../../../utils/getTranslationsByLangOrLang';
import { ShoppersFAQStyles } from './styles';
import plus from '../../../assets/images/shoppers/plus.svg';
import minus from '../../../assets/images/shoppers/minus.svg';

interface FAQAnswer {
  type: 'p' | 'ul';
  contentKey?: string;
  items?: string[];
}

interface FAQData {
  key: string;
  answers: FAQAnswer[];
}

const faqData: FAQData[] = [
  {
    key: 'shoppers_37',
    answers: [
      { type: 'p', contentKey: 'shoppers_43' },
      { type: 'p', contentKey: 'shoppers_44' },
      { type: 'p', contentKey: 'shoppers_45' },
    ],
  },
  {
    key: 'shoppers_38',
    answers: [
      { type: 'ul', items: ['shoppers_46', 'shoppers_47', 'shoppers_48', 'shoppers_49'] },
    ],
  },
  {
    key: 'shoppers_39',
    answers: [
      { type: 'p', contentKey: 'shoppers_50' },
      { type: 'ul', items: ['shoppers_51', 'shoppers_52'] },
    ],
  },
  {
    key: 'shoppers_40',
    answers: [
      { type: 'p', contentKey: 'shoppers_53' },
    ],
  },
  {
    key: 'shoppers_41',
    answers: [
      { type: 'p', contentKey: 'shoppers_54' },
      { type: 'p', contentKey: 'shoppers_55' },
    ],
  },
  {
    key: 'shoppers_42',
    answers: [
      { type: 'p', contentKey: 'shoppers_56' },
      { type: 'p', contentKey: 'shoppers_57' },
      { type: 'p', contentKey: 'shoppers_58' },
      { type: 'p', contentKey: 'shoppers_59' },
      { type: 'p', contentKey: 'shoppers_60' },
      { type: 'p', contentKey: 'shoppers_61' },
      { type: 'p', contentKey: 'shoppers_62' },
    ],
  },
];

interface FAQItemProps {
  question: string;
  answers: FAQAnswer[];
}

const FAQItem: FC<FAQItemProps> = ({ question, answers }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <div className="list-item" onClick={() => setIsOpen(!isOpen)}>
      <div className="question">
        <p>{question}</p>
        <img src={isOpen ? minus : plus} alt="toggle" />
      </div>
      <div
        className="answer"
        style={{
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px',
          opacity: isOpen ? 1 : 0,
          marginTop: isOpen ? '30px' : '0',
        }}
        ref={contentRef}
      >
        {answers.map(({ type, contentKey, items }, index) => {
          if (type === 'ul' && items) {
            return (
              <ul key={contentKey}>
                {items.map((itemKey, i) => (
                  <li key={itemKey}>{getTranslationsByLangOrEng(interfaceLanguage, itemKey)}</li>
                ))}
              </ul>
            );
          }
          return (
            <p key={contentKey} dangerouslySetInnerHTML={{ __html: contentKey ? getTranslationsByLangOrEng(interfaceLanguage, contentKey) : '' }} />
          );
        })}
      </div>
    </div>
  );
};

export const ShoppersFAQ = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <ShoppersFAQStyles>
      <div className="container">
        <h5>
          {getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_35')}
        </h5>
        <div className="content">
          <h2>
            {getTranslationsByLangOrEng(interfaceLanguage, 'shoppers_36')}
          </h2>
          <div className="list">
            {faqData.map(({ key, answers }) => (
              <FAQItem
                key={key}
                question={getTranslationsByLangOrEng(interfaceLanguage, key)}
                answers={answers}
              />
            ))}
          </div>
        </div>
      </div>
    </ShoppersFAQStyles>
  );
};
