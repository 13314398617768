import styled from 'styled-components';

export const DiaryStudyAboutStyles = styled.div`
  padding: 70px 0;

  @media (max-width: 991px) {
    padding: 40x 0;
  }

  .diary-img {
    width: 100%;
    object-fit: cover;
  }

  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    color: #0f0e0c;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  .gradient-block {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
    background: #fff;
    font-weight: 600;
    font-size: 28px;
    line-height: 125%;
    color: #0f0e0c;

    @media (max-width: 991px) {
      font-size: 18px;
      padding: 18px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      z-index: -1;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: linear-gradient(45deg, #26f0a6, #2994bb);
      z-index: -1;
    }
  }
`;
