import styled from 'styled-components';

export const ShoppersFAQStyles = styled.div`
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30x 0;
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-item {
      box-sizing: border-box;
      padding: 30px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      flex-direction: column;
      width: 100%;
      cursor: pointer;

      img {
        flex-shrink: 0;
      }

      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: #ffffff;

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }

      .question {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }

      .answer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: hidden;
        transition: max-height 0.3s ease, opacity 0.3s ease;

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          color: #ffffff;
          margin: 0;

          @media (max-width: 991px) {
            font-size: 14px;
          }
        }

        ul {
          padding-left: 25px;
          list-style-type: disc;
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #ffffff;
            margin: 0;

            @media (max-width: 991px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
