import styled from 'styled-components';

export const CatiRatingStyles = styled.div`
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 991px) {
    gap: 30px;
    padding: 40x 0;
  }

  .cards {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;

    @media (max-width: 768px) {
      gap: 15px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      max-width: 350px;

      img {
        border-radius: 5px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
          order: 1;
        }
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 0 8px;

      @media (max-width: 768px) {
        order: 2;
      }

      h6 {
        margin: 0;
        font-weight: 600;
        font-size: 22px;
        line-height: 125%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        color: #0f0e0c;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
`;
