import styled from 'styled-components';

export const CatiAboutStyles = styled.div`
  padding: 70px 0;

  @media (max-width: 991px) {
    padding: 40x 0;
  }

  .cati-img {
    width: 100%;
    object-fit: cover;
  }

  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 125%;
    color: #0f0e0c;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
`;
